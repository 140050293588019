import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GetCmpSetUpPopUp } from '../../utils/slices/CompanySetUpPopUpSlice'
import PLDataGrid from '../../PlusComponent/PLDataGrid'
import GlobalClass from '../../utils/GlobalClass'

const CS_ECommerceOperator = (props) => {
    const { obj, setEditSend, value } = props
    const ECommerceOpeData = useSelector((state) => state.CompanySetupPopUp?.CmpSetUpPopUpData)
    const { isLoading } = useSelector((state) => state.CompanySetupPopUp)
    const P_G02 = useSelector((state) => state.CompanySetupPopUp?.CmpSetUpPopUpData?.P_G02)
    const P_G02DT = useSelector((state) => state.CompanySetupPopUp?.CmpSetUpPopUpData?.P_G02DT)
    const dispatch = useDispatch()

    useEffect(() => {
        if (obj) {
            dispatch(GetCmpSetUpPopUp(obj))
        }
    }, [obj])

    return (
        <div style={{ width: "100%" }}>
            {
                isLoading ? GlobalClass.RenderLoader("dots") :
                    ECommerceOpeData &&
                    <PLDataGrid
                    autoFocus={true}
                    openOnFocus={true}
                        dropdownPosition={true}
                        // width={"500px"}
                        dispexpr={"G02F02"}
                        valexpr={"G02F01"}
                        TmData={P_G02}
                        data={P_G02DT?.jData}
                        setEdit={(e) => {
                            // console.log('e=======>',e)
                            setEditSend(e.G02F01)
                        }}
                        value={value}
                    />
            }
        </div>
    )
}

export default CS_ECommerceOperator