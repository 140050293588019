// import * as SelectionBtn from "../FunctionsCall/FormatButton/"

export const TMD_RowSel = (itemOBJ) => {
    const OBJ = itemOBJ;
    console.log('executeFunction TMD_RowSel ===>', OBJ);
    OBJ.setFormatBtn(JSON.stringify(OBJ))
}

export const TMD_Filter = (itemOBJ) => {
    const OBJ = itemOBJ
    OBJ.setFormatBtn(JSON.stringify(OBJ))
}

export const TMD_AddExtra = (itemOBJ) => {
    const OBJ = itemOBJ;
    OBJ.setFormatBtn(JSON.stringify(OBJ))
}

export const TMD_MHeadFoot = (itemOBJ) => {
    const OBJ = itemOBJ;
    OBJ.setFormatBtn(JSON.stringify(OBJ))
}

export const TMD_Width = (itemOBJ) => {
    const OBJ = itemOBJ;
    OBJ.setFormatBtn(JSON.stringify(OBJ))
}

export const TMD_ToggleOpt = (itemOBJ) => {
    const OBJ = itemOBJ
    OBJ.setFormatBtn(JSON.stringify(OBJ))
}

export const TMD_ChangeSeq = (itemOBJ) => {
    const OBJ = itemOBJ
    OBJ.setFormatBtn(JSON.stringify(OBJ))
}

export const TMD_Header = (itemOBJ) => {
    const OBJ = itemOBJ
    OBJ.setFormatBtn(JSON.stringify(OBJ))
}

export const TMD_IncDecSeq = (itemOBJ) => {
    const OBJ = itemOBJ
    OBJ.setFormatBtn(JSON.stringify(OBJ))
}

export const TMD_InsSeq = (itemOBJ) => {
    const OBJ = itemOBJ
    OBJ.setFormatBtn(JSON.stringify(OBJ))
}

export const TMD_AdvOpt = (itemOBJ) => {
    const OBJ = itemOBJ
    OBJ.setFormatBtn(JSON.stringify(OBJ))
}

export const TMD_StartEdit = (itemOBJ) => {
    const OBJ = itemOBJ
    OBJ.setFormatBtn(JSON.stringify(OBJ))
}
