import React from "react";
import { Button, Center, Grid, Group, Paper, Text } from "@mantine/core";
import PLComboBox from "../../../PlusComponent/PLComboBox";
import QueryGenerator from "../../AdvanceSetup/Equation/QueryGenerator";
import PLTextBox from "../../../PlusComponent/PLTextBox";

export default function VouchValidExpForm() {
    const VouchValidationForm = () => {
        return (
          <>
            <Grid.Col
              span={12}
              style={{
                display: "flex",
                flexDirection: "row",
                // alignItems: "center",
              }}
            >
              <Grid.Col span={3}>
                <Text style={{ fontSize: 12 }}>Message</Text>
              </Grid.Col>
              <Grid.Col span={9}>
                <PLTextBox autoFocus={true}/>
              </Grid.Col>
            </Grid.Col>
            <Grid.Col
              span={12}
              style={{
                display: "flex",
                flexDirection: "row",
                // alignItems: "center",
              }}
            >
              <Grid.Col span={3}>
                <Text style={{ fontSize: 12 }}>Valid Type</Text>
              </Grid.Col>
              <Grid.Col span={7}>
                <PLComboBox />
              </Grid.Col>
            </Grid.Col>
          </>
        );
      };
  return (
    <Paper shadow="md" radius="md" p="xs" withBorder>
    <Grid gutter={4}>
      <Grid.Col
        span={12}
        style={{
          display: "flex",
          flexDirection: "row",
          // alignItems: "center",
        }}
      >
        <Grid.Col span={3}>
          <Text style={{ fontSize: 12 }}>Expression</Text>
        </Grid.Col>
        <Grid.Col span={9}>
          <QueryGenerator />
        </Grid.Col>
      </Grid.Col>
   
      {VouchValidationForm()}
 

      <Grid.Col span={12}>
        <Center>
          <Group>
            <Button size="xs">OK</Button>
            <Button size="xs">Cancel</Button>
          </Group>
        </Center>
      </Grid.Col>
    </Grid>
  </Paper>
  )
}
