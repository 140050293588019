import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import gensetting from "../gensetting";
import GlobalClass from "../GlobalClass";
import axios, { AxiosError } from "axios";
import { modals } from "@mantine/modals";
import Store from "../store";
import { GetVouchNumData } from "../slices/VoucherNumSlice";

export const GetWhatsAppData = createAsyncThunk(
  "WhatsApp/GetWhatsApp",
  async (obj) => {
    console.log("=>Obj GetWhatsAppData", obj);
    try {
      if (obj.p0 == "D") {
        GlobalClass.Notify(
          "info",
          "Deleting",
          "Please wait while we process your data"
        );
      }
      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: obj?.p0 !== "A" ? obj?.p0 : "A",
        cCode: obj?.id !== "" ? obj?.id : "",
        "Yrno":localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
      });
      const response = await axios.get(
        GlobalClass.ApiUrl + GlobalClass.GetWhatsApp + "?pa=" + param
      );
      // console.log("GetVouchNumData response=>>", response.data.data);
      if (response?.data?.status == "SUCCESS") {
        if (obj.p0 == "D") {
          // modals.closeAll();
          GlobalClass.Notify("success", "Deleted", "Deleted successfully");
          Store.dispatch(GetVouchNumData({ RepId: "P_WAPP", FmtID: true }));
        }
        return response?.data?.data;
      } else {
        GlobalClass.Notify(
          "error",
          response?.data?.status,
          response?.data?.message
        );
        // return { error: response?.data?.message };
      }
      // return response.data.data;
    } catch (error) {
      // console.error(error);
      GlobalClass.Notify("error", "Error", `${error?.message}`);
      return { error };
    }
  }
);
export const GetEMAILData = createAsyncThunk(
  "EMAILData/GetEMAIL",
  async (obj) => {
    console.log("=>Obj GetEMAIL", obj);
    try {
      if (obj.p0 == "D") {
        GlobalClass.Notify(
          "info",
          "Deleting",
          "Please wait while we process your data"
        );
      }
      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: obj?.p0 !== "A" ? obj?.p0 : "A",
        cCode: obj?.id !== "" ? obj?.id : "",
        "Yrno":localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
      });
      const response = await axios.get(
        GlobalClass.ApiUrl + GlobalClass.GetEMAIL + "?pa=" + param
      );
      // console.log("GetVouchNumData response=>>", response.data.data);
      if (response?.data?.status == "SUCCESS") {
        if (obj.p0 == "D") {
          // modals.closeAll();
          GlobalClass.Notify("success", "Deleted", "Deleted successfully");
          Store.dispatch(GetVouchNumData({ RepId: "P_EMAIL", FmtID: true }));
        }
        return response?.data?.data;
      } else {
        GlobalClass.Notify(
          "error",
          response?.data?.status,
          response?.data?.message
        );
        // return { error: response?.data?.message };
      }
      // return response.data.data;
    } catch (error) {
      // console.error(error);
      GlobalClass.Notify("error", "Error", `${error?.message}`);
      return { error };
    }
  }
);
export const GetSMSData = createAsyncThunk("SMSData/GetSMS", async (obj) => {
  console.log("=>Obj GetSMSData", obj);
  try {
    if (obj.p0 == "D") {
      GlobalClass.Notify(
        "info",
        "Deleting",
        "Please wait while we process your data"
      );
    }
    let param = JSON.stringify({
      CorpID: gensetting.getcorpid(),
      cUser: gensetting.getcuser(),
      cPass: gensetting.getcpass(),
      CmpNo: gensetting.getcmpno(),
      cAction: obj?.p0 !== "A" ? obj?.p0 : "A",
      cCode: obj?.id !== "" ? obj?.id : "",
      "Yrno":localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
    });
    const response = await axios.get(
      GlobalClass.ApiUrl + GlobalClass.GetSMS + "?pa=" + param
    );
    // console.log("GetVouchNumData response=>>", response.data.data);
    if (response?.data?.status == "SUCCESS") {
      if (obj.p0 == "D") {
        // modals.closeAll();
        GlobalClass.Notify("success", "Deleted", "Deleted successfully");
        Store.dispatch(GetVouchNumData({ RepId: "P_SMS", FmtID: true }));
      }
      return response?.data?.data;
    } else {
      GlobalClass.Notify(
        "error",
        response?.data?.status,
        response?.data?.message
      );
      // return { error: response?.data?.message };
    }
    // return response.data.data;
  } catch (error) {
    // console.error(error);
    GlobalClass.Notify("error", "Error", `${error?.message}`);
    return { error };
  }
});
export const PostEMAILdata = createAsyncThunk(
  "PostEMAIL/PostEMAILdata",
  async (requestData) => {
    console.log(requestData, "requestData-PostEMAILdata")
    try {
      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: "A",
        cCode: "",
        "Yrno":localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
        // cSData:requestData,
      });
      const response = await fetch(
        GlobalClass.ApiUrl + GlobalClass.PostEMAIL + "?pa=" + param,
        requestData
      );
      console.log(response?.data, "PostEMAILdata");
      if (response?.data?.status == "FAIL") {
        // console.log(response?.data?.status, "response?.data?.status");
        GlobalClass.Notify(
          "error",
          response?.data?.status,
          response?.data?.message
        );
        return response?.data?.status;
      } else {
        return response?.data?.status;
      }
    } catch (error) {
    // console.error(error);
    GlobalClass.Notify("error", "Error", `${error?.message}`);
    return { error };
  }
  }
);
export const PostWhatsApp = createAsyncThunk(
  "PostWhatsApp/PostWhatsAppdata",
  async (requestData) => {
    console.log(requestData, "requestData-PostWhatsAppdata")
    try {
      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: "A",
        cCode: "",
        "Yrno":localStorage.getItem("YrNo")?.replace(/[\\"]/g, ''),
        // cSData:requestData,
      });
      const response = await fetch(
        GlobalClass.ApiUrl + GlobalClass.PostWhatsApp + "?pa=" + param,
        requestData
      );
      console.log(response?.data, "PostWhatsAppdata");
      if (response?.data?.status == "FAIL") {
        // console.log(response?.data?.status, "response?.data?.status");
        GlobalClass.Notify(
          "error",
          response?.data?.status,
          response?.data?.message
        );
        return response?.data?.status;
      } else {
        return response?.data?.status;
      }
    } catch (error) {
      console.log("error", error);
      return error;
    }
  }
);
export const PostSMS = createAsyncThunk(
  "PostSMS/PostSMSdata",
  async (requestData) => {
    console.log(requestData, "requestData-PostSMS")
    try {
      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: "A",
        cCode: "",
        "Yrno":localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
        // cSData:requestData,
      });
      const response = await fetch(
        GlobalClass.ApiUrl + GlobalClass.PostSMS + "?pa=" + param,
        requestData
      );
      console.log(response?.data, "PostSMS");
      if (response?.data?.status == "FAIL") {
        // console.log(response?.data?.status, "response?.data?.status");
        GlobalClass.Notify(
          "error",
          response?.data?.status,
          response?.data?.message
        );
        return response?.data?.status;
      } else {
        return response?.data?.status;
      }
    } catch (error) {
      console.log("error", error);
      return error;
    }
  }
);
export const GenererateSMS = createAsyncThunk(
  "SMS/GenerateSMSData",
  async (requestData) => {
    console.log("requestData",requestData)
    try {
      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: requestData?.p0,
        cCode: requestData?.id,
        cOPara: requestData?.page,
        cSData : requestData?.BtnPnlObj?.BtnPnlObj?.name?.split(" ")[0]?.slice(0,4)+"_",
        "Yrno":localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
      });
      const response = await axios.get(
        GlobalClass.ApiUrl + GlobalClass.GetSMS + "?pa=" + param,
        requestData
      );
        return response?.data?.data;
    } catch (error) {
      return error;
    }
  }
)
const MessageBtnSlice = createSlice({
  name: "MessageBtnS",
  initialState: {
    msgData: {
      whatsapp: {},
      email: {},
      sms: {},
    },
    // fulfillFlag:"",
    isLoading: false,
    hasError: false,
    ErrorMsg: "",
    isLoadingp: false,
    hasErrorp: false,
    ErrorMsgp: "",
    successMsg: "",
    generateData: {
      whatsapp: {},
      email: {},
      sms: {},
    },
  },
  reducers: {
    RefreshSmsBtn: (state, action) => {
      console.log("RefreshSmsBtn.payload", action.payload)
      // state.msgData.sms = action.payload
      state.msgData = action.payload;
    },
    RefreshEmailBtn: (state, action) => {
      // state.msgData.email = action.payload
      state.msgData = action.payload;
    },
    RefreshWapBtn: (state, action) => {
      // state.msgData.whatsapp = action.payload
      state.msgData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetWhatsAppData.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(GetWhatsAppData.fulfilled, (state, action) => {
        // console.log("VoucherNumTemp action.payload=>", action.payload);
        if (!action.payload?.error) {
          state.msgData.whatsapp = action?.payload;
          state.isLoading = false;
          state.hasError = false;
        } else {
          state.msgData.whatsapp = [];
          state.isLoading = false;
          state.hasError = true;
          state.ErrorMsg = action?.payload?.error;
        }
      })
      .addCase(GetWhatsAppData.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(GetEMAILData.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(GetEMAILData.fulfilled, (state, action) => {
        // console.log("VoucherNumTemp action.payload=>", action.payload);
        if (!action.payload?.error) {
          state.msgData.email = action?.payload;
          state.isLoading = false;
          state.hasError = false;
        } else {
          state.msgData.email = [];
          state.isLoading = false;
          state.hasError = true;
          state.ErrorMsg = action?.payload?.error;
        }
      })
      .addCase(GetEMAILData.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(GetSMSData.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(GetSMSData.fulfilled, (state, action) => {
        console.log("VoucherNumTemp action.payload=>", action.payload);
        if (!action.payload?.error) {
          state.msgData.sms = action?.payload;
          state.isLoading = false;
          state.hasError = false;
        } else {
          state.msgData.sms = [];
          state.isLoading = false;
          state.hasError = true;
          state.ErrorMsg = action?.payload?.error;
        }
      })
      .addCase(GetSMSData.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(GenererateSMS.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(GenererateSMS.fulfilled, (state, action) => {
        if (!action.payload?.error) {
          state.generateData.sms = action?.payload;
          state.isLoading = false;
          state.hasError = false;
        } else {
          state.generateData.sms = [];
          state.isLoading = false;
          state.hasError = true;
          state.ErrorMsg = action?.payload?.error;
        }
      })
      .addCase(GenererateSMS.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      });
    // .addCase(PostEMAILdata.pending, (state, action) => {
    //   console.log(action, "pending");
    //   state.isLoadingp = true;
    //   state.hasErrorp = false;
    // })
    // .addCase(PostEMAILdata.fulfilled, (state, action) => {
    //   console.log(action?.payload, "action?.payload?.error");
    //   if (action?.payload?.status == "FAIL") {
    //     console.log(action, "fulfilled");
    //     state.ErrorMsgp = action.payload;
    //     state.isLoadingp = false;
    //     state.hasErrorp = true;
    //   } else if (action.payload instanceof AxiosError) {
    //     const error = action.payload;
    //     console.error("Network error occurred:", error.message);

    //     state.ErrorMsgp = error;
    //     state.isLoadingp = false;
    //     state.hasErrorp = true;
    //   } else {
    //     console.log(action, 'action?.payload-')
    //     // state.fulfillFlag = action.meta.requestStatus;
    //     state.isLoadingp = false;
    //     state.hasErrorp = false;
    //   }
    // })
    // .addCase(PostEMAILdata.rejected, (state, action) => {
    //   console.log(action, "rejected");
    //   state.hasErrorp = true;
    //   state.isLoadingp = false;
    // });
  },
});

export const { RefreshSmsBtn, RefreshEmailBtn, RefreshWapBtn } =
  MessageBtnSlice.actions;

export default MessageBtnSlice.reducer;
