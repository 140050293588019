import { Button, Center, Grid, Group, Text } from '@mantine/core'
import React, { useEffect, useState } from 'react'
import PLComboBox from '../PlusComponent/PLComboBox'
import PLTextBox from '../PlusComponent/PLTextBox'
import GlobalClass from '../utils/GlobalClass'
import gensetting from '../utils/gensetting'

const PrinterForm = (props) => {
    const { DrawerObj, setDrawerObj, rowObj, cAction, selectedRow, setRowSelection } = props
    // console.log("printForm_props", props)
    const [printObj, setPrintObj] = useState({
        companyNm: "",
        title: "",
        choice: "",
        ipv4address: "",
        qrcode: "",
        format: "",
        type: "",
        charperline: "",
        ipaddress: "",
        portaddress: "",
        shareNm: "",
        cashdrawer: ""
    })
    const [cmpList, setCmpList] = useState([])
    // console.log("cmpList", cmpList)
    const printingChoice = [
        {
            DisplayMember: "Browser Popup Print",
            ValueMember: "bpp"
        },
        {
            DisplayMember: "Direct Print",
            ValueMember: "dp"
        }
    ]
    const printFormat = [
        {
            DisplayMember: "No Auto KOT Print",
            ValueMember: "KOT"
        },
        {
            DisplayMember: "Thermal 56mm",
            ValueMember: "T56"
        },
        {
            DisplayMember: "Thermal 80mm",
            ValueMember: "T88"
        }
    ]
    const QRCode = [
        {
            DisplayMember: "Enable",
            ValueMember: "Y"
        },
        {
            DisplayMember: "Disable",
            ValueMember: "N"
        },
    ]
    const printerType = [
        {
            DisplayMember: "Network Printer",
            ValueMember: "np"
        },
        {
            DisplayMember: "Windows Printer",
            ValueMember: "wp"
        },
    ]
    const cashDrawer = [
        {
            DisplayMember: "Yes",
            ValueMember: "Y"
        },
        {
            DisplayMember: "No",
            ValueMember: "N"
        }
    ]

    useEffect(() => {
        let CmpListArr = localStorage.getItem("authUser");
        if (CmpListArr) {
            CmpListArr = JSON.parse(CmpListArr);
            if (CmpListArr.cmpList) {
                let obj = {
                    ...CmpListArr,
                    cmpList: CmpListArr?.cmpList.map(({ cmpNo, cmpName, cmpGrp }) => ({
                        cmpNo,
                        cmpName: `${cmpNo} - ${cmpName}`,
                        cmpGrp,
                    }))
                }
                setCmpList(obj?.cmpList)
            }
        }
    }, []);

    useEffect(() => {
        if (rowObj) {
            setPrintObj(rowObj)
        }
    }, [rowObj])

    const submitPrinter = () => {
        if (cAction === "E") {
            if (rowObj === printObj) {
                return setDrawerObj({ ...DrawerObj, open: false })
            }
            const storedData = JSON.parse(localStorage.getItem("Printer")) || [];
            let currentCmpData=storedData.filter((f) => f.companyNm === gensetting.getcmpno())
            // console.log("FilterData",FilterData)
            let otherCmpData = storedData.filter((f) => f.companyNm !== gensetting.getcmpno())
            // console.log("otherCmpData",otherCmpData)
            const index = currentCmpData.findIndex((item, i) => i === selectedRow.index);
            console.log("index", index)
            if (index !== -1) {
                currentCmpData[index] = printObj;

                const updatedStoredData = [...currentCmpData, ...otherCmpData]

                localStorage.setItem("Printer", JSON.stringify(updatedStoredData));
                setRowSelection({})
            }
            GlobalClass.Notify("success", "Success", "Edited successfully")
        } else {
            const currentData = JSON.parse(localStorage.getItem("Printer")) || [];
            let newObj = { ...printObj }
            newObj = { ...newObj, companyNm: gensetting.getcmpno() }
            console.log("newObj",newObj)
            currentData.push(newObj)
            localStorage.setItem("Printer", JSON.stringify(currentData))
            setPrintObj({
                companyNm: "",
                title: "",
                choice: "",
                ipv4address: "",
                qrcode: "",
                format: "",
                type: "",
                charperline: "",
                ipaddress: "",
                portaddress: "",
                shareNm: "",
                cashdrawer: ""
            })
            GlobalClass.Notify("success", "Success", "Added successfully")
        }
        console.log("printObj", printObj)
        setDrawerObj({ ...DrawerObj, open: false })
    }

    return (
        <>
            {!cmpList ? GlobalClass.RenderLoader("dots") :
                <Grid gutter={4}>
                    <Grid.Col span={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        {/* <Grid.Col span={2}>
                            <Text size={12}>Company Name</Text>
                        </Grid.Col>
                        <Grid.Col span={4}>
                            {cmpList && <PLComboBox
                                data={cmpList}
                                dispexpr="cmpName"
                                valexpr="cmpNo"
                                value={printObj?.companyNm}
                                setEdit={(e) => {
                                    setPrintObj({ ...printObj, companyNm: e })
                                }}
                            />}
                        </Grid.Col> */}
                        <Grid.Col span={2}>
                            <Text size={12}>Title</Text>
                        </Grid.Col>
                        <Grid.Col span={10}>
                            <PLTextBox
                                value={printObj.title}
                                setEdit={(e) => {
                                    setPrintObj({ ...printObj, title: e.target.value })
                                }}
                            />
                        </Grid.Col>
                    </Grid.Col>
                    <Grid.Col span={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Grid.Col span={2}>
                            <Text size={12}>Printing Choice</Text>
                        </Grid.Col>
                        <Grid.Col span={4}>
                            <PLComboBox
                                data={printingChoice}
                                dispexpr="DisplayMember"
                                valexpr="ValueMember"
                                value={printObj?.choice}
                                setEdit={(e) => {
                                    setPrintObj({ ...printObj, choice: e })
                                }}
                            />
                        </Grid.Col>
                        {printObj?.choice === "dp" ?
                            <>
                                <Grid.Col span={2}>
                                    <Text align='center' size={12}>IPV4 Address</Text>
                                </Grid.Col>
                                <Grid.Col span={4}>
                                    <PLTextBox
                                        value={printObj.ipv4address}
                                        setEdit={(e) => {
                                            setPrintObj({ ...printObj, ipv4address: e.target.value })
                                        }}
                                    />
                                </Grid.Col>
                            </> : <>
                                <Grid.Col span={2}>
                                    <Text align='center' size={12}>Print Format</Text>
                                </Grid.Col>
                                <Grid.Col span={4}>
                                    <PLComboBox
                                        data={printFormat}
                                        dispexpr="DisplayMember"
                                        valexpr="ValueMember"
                                        value={printObj.format}
                                        setEdit={(e) => {
                                            setPrintObj({ ...printObj, format: e })
                                        }}
                                    />
                                </Grid.Col>
                            </>
                        }
                    </Grid.Col>
                    <Grid.Col span={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Grid.Col span={2}>
                            <Text size={12}>Invoice QR Status</Text>
                        </Grid.Col>
                        <Grid.Col span={4}>
                            <PLComboBox
                                data={QRCode}
                                dispexpr="DisplayMember"
                                valexpr="ValueMember"
                                value={printObj.qrcode}
                                setEdit={(e) => {
                                    setPrintObj({ ...printObj, qrcode: e })
                                }}
                            />
                        </Grid.Col>
                        {printObj?.choice === "dp" ?
                            <>
                                <Grid.Col span={2}>
                                    <Text align='center' size={12}>Printer Type</Text>
                                </Grid.Col>
                                <Grid.Col span={4}>
                                    <PLComboBox
                                        data={printerType}
                                        dispexpr="DisplayMember"
                                        valexpr="ValueMember"
                                        value={printObj.type}
                                        setEdit={(e) => {
                                            setPrintObj({ ...printObj, type: e })
                                        }}
                                    />
                                </Grid.Col>
                            </> :
                            <>
                                <Grid.Col span={6}>
                                </Grid.Col>
                            </>
                        }
                    </Grid.Col>
                    {
                        printObj?.choice === "dp" &&
                        <>
                            <Grid.Col span={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Grid.Col span={2}>
                                    <Text size={12}>Characters Per Line</Text>
                                </Grid.Col>
                                <Grid.Col span={4}>
                                    <PLTextBox
                                        value={printObj.charperline}
                                        setEdit={(e) => {
                                            setPrintObj({ ...printObj, charperline: e.target.value })
                                        }}
                                    />
                                </Grid.Col>
                                {printObj.type === "np" ?
                                    <>
                                        <Grid.Col span={2}>
                                            <Text align='center' size={12}>Printer IP Adddress</Text>
                                        </Grid.Col>
                                        <Grid.Col span={4}>
                                            <PLTextBox
                                                value={printObj.ipaddress}
                                                setEdit={(e) => {
                                                    setPrintObj({ ...printObj, ipaddress: e.target.value })
                                                }}
                                            />
                                        </Grid.Col>
                                    </> :
                                    <>
                                        <Grid.Col span={2}>
                                            <Text align='center' size={12}>Share Name</Text>
                                        </Grid.Col>
                                        <Grid.Col span={4}>
                                            <PLTextBox
                                                value={printObj.shareNm}
                                                setEdit={(e) => {
                                                    setPrintObj({ ...printObj, shareNm: e.target.value })
                                                }}
                                            />
                                        </Grid.Col>
                                    </>
                                }
                            </Grid.Col>
                            {
                                printObj.type === "np" &&
                                <Grid.Col span={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <Grid.Col span={2}>
                                        <Text size={12}>Printer Port Address</Text>
                                    </Grid.Col>
                                    <Grid.Col span={4}>
                                        <PLTextBox
                                            value={printObj.portaddress}
                                            setEdit={(e) => {
                                                setPrintObj({ ...printObj, portaddress: e.target.value })
                                            }}
                                        />
                                    </Grid.Col>
                                    <Grid.Col span={2}>
                                    </Grid.Col>
                                    <Grid.Col span={4}>
                                    </Grid.Col>
                                </Grid.Col>
                            }
                            <Grid.Col span={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Grid.Col span={2}>
                                    <Text size={12}>Open Cash Drawer When Printing Invoice</Text>
                                </Grid.Col>
                                <Grid.Col span={10}>
                                    <PLComboBox
                                        data={cashDrawer}
                                        dispexpr="DisplayMember"
                                        valexpr="ValueMember"
                                        value={printObj.cashdrawer}
                                        setEdit={(e) => {
                                            setPrintObj({ ...printObj, cashdrawer: e })
                                        }}
                                    />
                                </Grid.Col>
                            </Grid.Col>
                        </>
                    }
                    <Grid.Col span={12}>
                        <Center>
                            <Group>
                                <Button
                                    size='xs'
                                    onClick={submitPrinter}
                                >Submit</Button>
                                <Button
                                    size='xs'
                                    onClick={() => {
                                        setDrawerObj({ ...DrawerObj, open: false })
                                    }}
                                >Cancel</Button>
                            </Group>
                        </Center>
                    </Grid.Col>
                </Grid>
            }
        </>
    )
}

export default PrinterForm