import { Button, Grid, Group, Text } from "@mantine/core";
import PLTextBox from "../../PlusComponent/PLTextBox";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { DataSaveLoading, HSNCodeAPI, PurchaseUnitAPI, SaleUnitAPI } from "../../utils/slices/ProductList";

import gensetting from "../../utils/gensetting";
import GlobalClass from "../../utils/GlobalClass";
import { ModalDelete } from "../../utils/slices/ModalSlice";

export function SaleUnitForm(props) {

    console.log("props", props);

    const { OBJ } = props

    const dispatch = useDispatch();
    const { SalePurchaseData } = useSelector(store => store?.ProductList);
    const { HSNCodeIsLoading, HSNCodeHasError, HSNCodeErrorMsg, SaveLoading } = useSelector(store => store?.ProductList);

    const [state, setState] = useState();

    useEffect(() => {
        if (OBJ?.cSTID == "P_UNTS") {
            dispatch(SaleUnitAPI(OBJ))
        }else if (OBJ?.cSTID == "P_UNTP") {
            dispatch(PurchaseUnitAPI(OBJ))
        }
    }, [OBJ])

    useEffect(() => {
        setState(SalePurchaseData)
    }, [SalePurchaseData])

    console.log("state", state);

    useEffect(() => {
        if (HSNCodeHasError && !HSNCodeIsLoading) {
            GlobalClass.Notify("error", "Error: " + HSNCodeErrorMsg.code, HSNCodeErrorMsg.message);
        }
    }, [HSNCodeHasError]);

    const saveBtn = () => {

        console.log("props", props);
        console.log("props OBJ", OBJ);

        if (state?.FIELD01) {
            dispatch(DataSaveLoading({[OBJ?.cSTID]:true}))
            GlobalClass.Notify("info", OBJ.p0 == "A" ? "Adding" : "Editing", "Please wait while we process your data");

            var data = {
                "CorpID": gensetting.getcorpid(),
                "cUser": gensetting.getcuser(),
                "cPass": gensetting.getcpass(),
                "CmpNo": gensetting.getcmpno(),
                "cAction": OBJ.p0 == "E" ? "E" : "A",
                "cCode": "",
                // "cOPara": "",
                "cSData": JSON.stringify(state)
            };

            let param =
                JSON.stringify({
                    "CorpID": gensetting.getcorpid(),
                    "cUser": gensetting.getcuser(),
                    "cPass": gensetting.getcpass(),
                    "CmpNo": gensetting.getcmpno(),
                    "cAction": OBJ.p0 == "E" ? "E" : "A",
                    "cCode": "",
                    // "cOPara": "",
                });

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data)
            };

            // if (OBJ.p0 == "E" && (JSON.stringify(state?.M21) === JSON.stringify(dataObj))) {
            //     Promise.resolve(dispatch(DataSaveLoading(false))).then(() => dispatch(ModalDelete(props.index)))
            //     GlobalClass.Notify("success", "Edited", "Edited successfully");

            //     let PaginationObj = Store.getState().DataSaveLoadingSlice.PaginationObj
            //     dispatch(GetAccountList({
            //         id: "00930001",
            //         name: "Product List",
            //         p0: "MT",
            //         p1: "T_M21",
            //         p2: "",
            //         p3: "",
            //         type: "A",
            //         pagination: PaginationObj["00930001"]
            //     }))

            // }
            // else {


            if (OBJ?.cSTID == "P_UNTS") {
                fetch(GlobalClass.ApiUrl + GlobalClass.PostM35SU + "?pa=" + param, requestOptions)
                    .then(response => response.json())
                    .then((data) => {
                        if (data.status === 'SUCCESS') {
                            Promise.resolve(dispatch(DataSaveLoading({[OBJ?.cSTID]:false}))).then(() => dispatch(ModalDelete(props.index)))
                            GlobalClass.Notify("success", OBJ.p0 == "A" ? "Added" : "Edited", OBJ.p0 == "A" ? "Added successfully" : "Edited successfully");

                            // let PaginationObj = Store.getState().DataSaveLoadingSlice.PaginationObj
                            // dispatch(GetAccountList({
                            //     id: "00930001",
                            //     name: "Product List",
                            //     p0: "MT",
                            //     p1: "T_M21",
                            //     p2: "",
                            //     p3: "",
                            //     type: "A",
                            //     pagination: PaginationObj["00930001"]
                            // }))
                        }
                        else if (data.status === 'FAIL') {
                            Promise.resolve(dispatch(DataSaveLoading({[OBJ?.cSTID]:false})))
                            GlobalClass.Notify("error", data?.status, data?.message);
                        }
                    })
                    .catch((e) => {
                        dispatch(DataSaveLoading({[OBJ?.cSTID]:false}));
                        GlobalClass.Notify("error", "Error", `${e?.message}`);
                    });
            }
            else if (OBJ?.cSTID == "P_UNTP") {
                fetch(GlobalClass.ApiUrl + GlobalClass.PostM35PU + "?pa=" + param, requestOptions)
                    .then(response => response.json())
                    .then((data) => {
                        if (data.status === 'SUCCESS') {
                            Promise.resolve(dispatch(DataSaveLoading({[OBJ?.cSTID]:false}))).then(() => dispatch(ModalDelete(props.index)))
                            GlobalClass.Notify("success", OBJ.p0 == "A" ? "Added" : "Edited", OBJ.p0 == "A" ? "Added successfully" : "Edited successfully");

                            // let PaginationObj = Store.getState().DataSaveLoadingSlice.PaginationObj
                            // dispatch(GetAccountList({
                            //     id: "00930001",
                            //     name: "Product List",
                            //     p0: "MT",
                            //     p1: "T_M21",
                            //     p2: "",
                            //     p3: "",
                            //     type: "A",
                            //     pagination: PaginationObj["00930001"]
                            // }))
                        }
                        else if (data.status === 'FAIL') {
                            Promise.resolve(dispatch(DataSaveLoading({[OBJ?.cSTID]:false})))
                            GlobalClass.Notify("error", data?.status, data?.message);
                        }
                    })
                    .catch((e) => {
                        dispatch(DataSaveLoading({[OBJ?.cSTID]:false}));
                        GlobalClass.Notify("error", "Error", `${e?.message}`);
                    });
            }


            // }
        }
        else {
            GlobalClass.Notify("warning", "Validation error", "Empty Code is not allowed")
        }
    }

    console.log("HSNCodeIsLoading", HSNCodeIsLoading, SaveLoading);

    return (
        <div >
            {
                HSNCodeIsLoading || SaveLoading[OBJ?.cSTID] ? GlobalClass.RenderLoader("dots") :
                    <>
                        <Grid gutter={4}>
                            <Grid.Col span={12} style={{ display: 'flex', padding: 0, }}>
                                <Grid.Col span={2}>
                                    <Text style={{ fontSize: 12, }} >Unit Name</Text>
                                </Grid.Col>
                                <Grid.Col span={4}>
                                    <PLTextBox value={state?.FIELD01}
                                        setEdit={(e) => {
                                            setState({ ...state, FIELD01: e.target.value })
                                        }}
                                    />
                                </Grid.Col>
                            </Grid.Col>
                            <Grid.Col span={12} style={{ display: 'flex', padding: 0 }}>
                                <Grid.Col span={2}>
                                    <Text style={{ fontSize: 12 }}>Description</Text>
                                </Grid.Col>
                                <Grid.Col span={7}>
                                    <PLTextBox value={state?.FIELD03}
                                        setEdit={(e) => {
                                            setState({ ...state, FIELD03: e.target.value })
                                        }}
                                    // onKeyDown={(e) => {
                                    //     GlobalClass.formNextInput(e, userFieldRef);
                                    // }}
                                    />
                                </Grid.Col>
                            </Grid.Col>
                        </Grid>
                        <Group spacing={'xs'} position="center" display={'flex'} mt={10}>
                            <Button
                                size='sm' style={{ width: 100 }}
                                onClick={() => saveBtn()}
                            >
                                Ok
                            </Button>
                        </Group>
                    </>
            }
        </div>
    )
}