import React, { useState, useEffect, useRef } from "react";
import { Grid, Text, Center, Button, Skeleton } from "@mantine/core";
import { modals } from "@mantine/modals";
import { notifications } from "@mantine/notifications";
import PLTextBox from "../../PlusComponent/PLTextBox";
import { useDispatch, useSelector } from "react-redux";
import { GetAreaList } from "../../utils/slices/AreaListSlice";
import GlobalClass from "../../utils/GlobalClass";
import gensetting from "../../utils/gensetting";
import { GetAccountList } from "../../utils/slices/AccountListSlice";
import { ModalDelete } from "../../utils/slices/ModalSlice";
import Store from "../../utils/store";
import { DataSaveLoading } from "../../utils/slices/DataSaveLoadingSlice";

const AreaListForm = (props) => {
  console.log("AreaListForm props=>",props);
  const dispatch = useDispatch();
  const formRef = useRef();
  const { OBJ } = props;
  const [group, setGroup] = useState("");
  const [dataEdited, setDataEdited] = useState();
  const areaList = useSelector(
    (state) => state?.AreaListGroup?.AreaListData?.data?.data
  );
  const SaveLoading = useSelector(
    (state) => state?.DataSaveLoadingSlice?.SaveLoading
  );
  const isLoading = useSelector((state) => state?.AreaListGroup?.isLoading);
  const hasError = useSelector((state)=>state?.areaList?.hasError);
  const ErrorMsg = useSelector((state)=>state?.areaList?.ErrorMsg);

  useEffect(() => {
    if (OBJ.p0 == "E") {
      dispatch(GetAreaList({ cCode: OBJ.id, cAction: OBJ.p0 }));
    } else {
      dispatch(GetAreaList());
    }
  }, []);

  // console.log("areaList", areaList);

  useEffect(() => {
    if (areaList) {
      setGroup(areaList);
      setDataEdited(areaList);
    }
  }, [areaList]);

  useEffect(() => {
    
    const formElement = formRef.current;
    if (formElement) {
      formElement.addEventListener('keypress', (e)=>GlobalClass.formNextInput(e,formRef));
    }
    return () => {
      if (formElement) {
        formElement.removeEventListener('keypress', (e)=>GlobalClass.formNextInput(e,formRef));
      }
    };
  }, [formRef]);

  const handleOkButton = () => {
    if (group !== dataEdited || OBJ?.p0=="A") {
      dispatch(DataSaveLoading({[OBJ?.cSTID]:true}));
      if (group.FIELD02.trim() === "") {
        GlobalClass.Notify(
          "warning",
          "Validation error",
          "Please fill out all the required fields"
        );
        dispatch(DataSaveLoading({[OBJ?.cSTID]:false}));
      } else {
        GlobalClass.Notify(
          "info",
          OBJ.p0 == "A" ? "Adding" : "Editing",
          "Please wait while we process your data"
        );
        var data = {
          CorpID: gensetting.getcorpid(),
          cUser: gensetting.getcuser(),
          cPass: gensetting.getcpass(),
          CmpNo: gensetting.getcmpno(),
          cAction: props?.OBJ?.p0,
          cCode: props?.OBJ?.p0=="A" ? "" : group?.FIELD01,
          cSData: JSON.stringify(group),
          YrNo:localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
        };
        let param = JSON.stringify({
          CorpID: gensetting.getcorpid(),
          cUser: gensetting.getcuser(),
          cPass: gensetting.getcpass(),
          CmpNo: gensetting.getcmpno(),
          cAction: props?.OBJ?.p0,
          cCode: props?.OBJ?.p0=="A" ? "" : group?.FIELD01,
          cSData: JSON.stringify(group),
        });

        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        };
        fetch(
          GlobalClass.ApiUrl + GlobalClass.PostM31CT + "?pa=" + param,
          requestOptions
        )
          .then((response) => response.json())
          .then((data) => {
            // console.log("api data", data);
            // console.log("api data status", data?.status);
            if (data.status === "SUCCESS") {
              // modals.closeAll();
              // props.ModalFunctionClose()
              // dispatch(ModalDelete(props.index))

              GlobalClass.Notify(
                "success",
                OBJ.p0 == "A" ? "Added" : "Edited",
                OBJ.p0 == "A" ? "Added successfully" : "Edited successfully"
              );
              let PaginationObj =
                Store.getState().DataSaveLoadingSlice.PaginationObj;
              Promise.resolve(dispatch(DataSaveLoading({[OBJ?.cSTID]:false})))
                // .then(() => dispatch(ModalDelete(props.index)))
                .then(() => {
                  if (props?.OBJ?.p0 == "A" && !props?.OBJ.page.startsWith("P_")) {
                    dispatch(GetAreaList());
                  } else {
                    dispatch(ModalDelete(props.index));
                  }
                })
                .then(() =>{
                  // dispatch(
                  //   GetAccountList({
                  //     id: "01350123",
                  //     name: "Area List",
                  //     p0: "MT",
                  //     p1: "T_M31A",
                  //     p2: "",
                  //     p3: "",
                  //     type: "A",
                  //     pagination: PaginationObj["01350123"],
                  //   }))
                  dispatch(GetAccountList({...OBJ?.BtnPnlObj ,pagination: PaginationObj[OBJ?.cSTID]}))
                });
            } else {
              GlobalClass.Notify("error", data?.status, data?.message);
              dispatch(DataSaveLoading({[OBJ?.cSTID]:false}));
            }
          })
          .catch((e) => {
            // console.log("inside catch", e);
            GlobalClass.Notify("error", "Error", `${e?.message}`);
            dispatch(DataSaveLoading({[OBJ?.cSTID]:false}));
          });
      }
    } else {
      dispatch(ModalDelete(props.index));
      return;
    }
  };
  return (
    <div ref={formRef}>
      {hasError ? <>
          <Text>{ErrorMsg?.message}</Text>
          <Button onClick={() => {
            if (props?.OBJ) {
              if (OBJ.p0 == "E") {
                dispatch(GetAreaList({ cCode: OBJ.id, cAction: OBJ.p0 }));
              } else {
                dispatch(GetAreaList());
              }
            }
          }}>Retry</Button>
        </> :SaveLoading[OBJ?.cSTID] ? (
        GlobalClass.RenderLoader("dots")
      ) : isLoading ? (
        GlobalClass.RenderLoader("dots")
      ) : (
      <Grid gutter={4}>
          <Grid.Col
            span={12}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: "0",
            }}
          >
            <Grid.Col span={3}>
              <Text style={{ fontSize: "12px" }}>Area Name</Text>
            </Grid.Col>
            <Grid.Col span={9}>
              <PLTextBox
                 onKeyDown={(e)=>{
                  GlobalClass.formNextInput(e,formRef);
                }}
                autoFocus = {true}
                value={group?.FIELD02}
                setEdit={(e) => {
                  setGroup({ ...group, FIELD02: e.target.value });
                }}
              />
            </Grid.Col>
          </Grid.Col>
          <Grid.Col>
            <Center>
              <Button size="xs" style={{ width: "80px", height:"30px"}} mt={2}
              onKeyDown={(e)=>{
                if(e.key =="Enter"){
                  e.preventDefault();
                  handleOkButton();
                }
              }}
              onClick={handleOkButton}>
                Ok
              </Button>
            </Center>
          </Grid.Col>
        </Grid>
      )}
    </div>
  );
};

export default AreaListForm;
