import React, { useEffect, useState } from 'react'
import PLDataGrid from '../../PlusComponent/PLDataGrid'
import { useDispatch, useSelector } from 'react-redux'
import { GetCmpSetUpPopUp } from '../../utils/slices/CompanySetUpPopUpSlice'
import GlobalClass from '../../utils/GlobalClass'

const BranchDetail = (props) => {
    console.log('props===>>>', props)
    const { obj, setEditSend, value } = props
    // console.log('setEditSend==>>', setEditSend)
    // console.log('value===>>', value)
    const BranchDetailData = useSelector((state) => state.CompanySetupPopUp?.CmpSetUpPopUpData)
    const { isLoading } = useSelector((state) => state.CompanySetupPopUp)
    const P_BRC = useSelector((state) => state.CompanySetupPopUp?.CmpSetUpPopUpData?.P_BRC)
    const P_BRCDT = useSelector((state) => state.CompanySetupPopUp?.CmpSetUpPopUpData?.P_BRCDT)
    const dispatch = useDispatch()

    useEffect(() => {
        if (obj) {
            dispatch(GetCmpSetUpPopUp(obj))
        }
    }, [obj])

    return (
        <div style={{ width: "100%" }}>
            {
                isLoading ? GlobalClass.RenderLoader("dots") :
                    BranchDetailData &&
                    <PLDataGrid
                    autoFocus={true}
                    openOnFocus={true}
                        dropdownPosition={true}
                        // width={"500px"}
                        dispexpr={"FIELD03"}
                        // isMulti={true}
                        TmData={P_BRC}
                        data={P_BRCDT?.jData}
                        value={value}
                        setEdit={(e) => {
                            setEditSend(e.FIELD01)
                        }}
                    />
            }
        </div>
    )
}

export default BranchDetail