import React from "react";
import { ModalFunction } from "../../../utils/slices/ModalSlice";
import { Text } from "@mantine/core";
import TransactionSalesForm from "../../../Transaction/SaleEntry/TransactionSalesForm";
import Store from "../../../utils/store";
import OpeningStockAddForm from "../../../Transaction/StockEntry/OpeningStockForm";
import TransactionForm from "../../../FunctionsCall/Transaction/TransactionForm";
import { GetInvTypeDropDown } from "../../../utils/TransactionSlices/TSalesSlices";
import JournalForm from "../../../Transaction/JournalEntry/Journal/JournalForm";
import BillFormChallanModal from "../../../Transaction/JobWorkOut/JobWorkOutBill/BillFormChallanModal";
import CreditDebitNoteForm from "../../../Transaction/StockEntry/CreditDebitNote/CreditDebitNoteForm";
import JobWorkOpeningForm from "../../../Transaction/JobWorkOpening/JobWorkOpeningForm";
import StockJournalAddForm from "../../../Transaction/StockEntry/StockJournalAddForm";

export function TM_ZINOUT(props) {

  const OBJ = JSON.parse(props);
  console.log("RenderFormProps =>", OBJ);
  // console.log(OBJ?.DataGridRow?.T41PVT, "AccLedger", OBJ);
  const Obj = { ...OBJ, id: (OBJ?.p0 == "A" ? "" : OBJ?.id?.trim()), p0: OBJ?.p0 };
  const T41PVT = OBJ?.DataGridRow?.T41PVT;

  // let PageObjectcCode = {
  //   "T41VLISTBPBP": "BPBP",
  //   "T41VLISTCPCP": "CPCP",
  //   "T41VLISTBRBR": "BRBR",
  //   "T41VLISTCRCR": "CRCR",
  //   "T41VLISTSSSS": "SSSS",
  //   "02230213": "SSWS", //unknown
  //   "02270213": "SRWR", //unknown
  //   "T41VLISTSRSR": "SRSR",
  //   "T41VLISTHSHS": "HSHS",
  //   "T41VLISTOSOS": "OSOS",
  //   "S!": "SSSS",
  //   "T41VLISTQTQT": "QTQT",
  //   "T41VLISTPPPP": "PPPP",
  //   "T41VLISTPRPR": "PRPR",
  //   "T41VLISTHPHP": "HPHP",
  //   "P!": "PPPP",
  //   "T41VLISTOPOP": "OPOP",
  //   "T41VLISTBCBC": "BCBC",
  //   "TXNVOULISTIN": "ININ", // Transaction > Production
  //   "QP": "ININ", // Transaction > Quick Production
  //   "TXNVOULISTJA": "JAJA",
  //   "TXNVOULISTHC": "HCHC",
  //   "TXNVOULISTHD": "HDHD",
  //   "TXNVOULISTHO": "HOHO",
  //   "TXNVOULISTIJ": "IJIJ",
  //   "T41VOULISTTS": "TSTS",
  //   "TXNVOULISTOJ": "OJOJ",
  //   "TXNVOULISTJI": "JIJI",
  //   "TXNVOULISTJO": "JOJO",
  //   "TXNVOULISTJJ": "JJJJ",
  //   "TXNVOULISTJX": "JXJX",
  //   "TXNVOULISTJW": "JWJW",
  //   "TXNVOULISTJY": "JYJY",
  //   "TXNVOULISTJZ": "JZJZ",
  //   "J1": "JZJZ", //jobwork in > bill from Challan
  //   "J!": "JJ", //jobwork out > bill from Challan
  //   "TXNVOULISTJC": "JXJX",
  //   "TXNVOULISTJD": "JYJY",
  //   "TXNVOULISTSJ": "JJJJ",
  //   "J2": "JJJJ", // subjobwork > bill from Challan
  //   "TXN_GSTLSTNC": "NCNC",
  //   "TXNVOULISTEG": "JRJR",
  //   "TXN_GSTLSTND": "NDND",
  //   "TXNVOULISTIG": "JRJR",
  //   "TXNVOULISTLD": "LDLD"
  // }

  if (T41PVT === "SS" || T41PVT === "SR" || T41PVT === "PP") {
    let newObj = {
      ...Obj,
      page:
        T41PVT === "SS"
          ? "02210213"
          : T41PVT === "SR"
            ? "02250213"
            : "02530249",
      cSTID: T41PVT === "SS"
        ? "T41VLISTSSSS"
        : T41PVT === "SR"
          ? "T41VLISTSRSR"
          : "T41VLISTPPPP",
    };

    Store.dispatch(
      ModalFunction({
        MTitle: <Text fw={700}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "95%",
        // position:'right',
        MBody: (i) => <TransactionSalesForm obj={newObj} index={i} />,
        MClose: true,
        onclose: () => { },
        onClickCloseButton: () => {
          Store.dispatch(GetInvTypeDropDown(null));
        },
      })
    );
  } else if (T41PVT === "BR" || T41PVT === "BP") {
    Store.dispatch(
      ModalFunction({
        onClickCloseButton: () => {
          // Deval (For Company Setup)
          Store.dispatch(GetInvTypeDropDown(null));
        },
        onclose: (e) => { },
        MTitle: <Text fw={700}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "95%",
        position: "right",
        MBody: (i) => <TransactionForm OBJ={Obj} index={i} />,
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        },
      })
    );
  } else if (T41PVT === "CR" || T41PVT === "CP") {
    let newObj = {
      ...Obj,
      page: T41PVT === "CR" ? "04830462" : "04800462",
      cSTID: T41PVT === "CR" ? "T41VLISTCRCR" : "T41VLISTCPCP"
    };
    Store.dispatch(
      ModalFunction({
        onClickCloseButton: () => {
          // Deval (For Company Setup)
          Store.dispatch(GetInvTypeDropDown(null));
        },
        onclose: (e) => { },
        MTitle: <Text fw={700}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "95%",
        position: "right",
        MBody: (i) => <TransactionForm OBJ={newObj} index={i} />,
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        },
      })
    );
  } else if (T41PVT === "JR" || T41PVT === "ND" || T41PVT === "NC" || T41PVT === "BC") {
    console.log(OBJ, "OBJ-")
    let newObj = {
      ...Obj,
      page: T41PVT === "JR"
        ? "05130489"
        : T41PVT === "ND"
          ? "05100489"
          : T41PVT === "BC"
            ? "05040489"
            : "05070489",
      cSTID: T41PVT === "JR"
        ? "T41VLISTJRJR"
        : T41PVT === "ND"
          ? "T41VLISTJRND"
          : T41PVT === "BC"
            ? "T41VLISTJRBC"
            : "T41VLISTJRNC"
    };
    console.log(newObj, "newObj-");
    Store.dispatch(
      ModalFunction({
        onClickCloseButton: () => { },
        onclose: (e) => { },
        MTitle: <Text fw={700}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "95%",
        position: "right",
        MBody: (i) => <JournalForm obj={newObj} index={i} />,
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        },
      })
    );
  } else if (T41PVT === "OA") {
    // Store.dispatch(
    //     ModalFunction({
    //       onclose: () => {},
    //       MTitle: <Text fw={500}>{OBJ?.text}</Text>,
    //       MAction: true,
    //       MSize: "xl",
    //       position:"right",
    //       MBody: (i) => <OpeningStockAddForm obj={Obj} index={i} />,
    //       MClose: true,
    //       Overlay: {
    //         backgroundOpacity: 0.8,
    //         blur: 5,
    //       },
    //     })
    //   );
  } else if (T41PVT === "JZ" || T41PVT === "JJ") {
    let newObj = {
      ...Obj,
      page: T41PVT === "JZ" ? "04320417" : "04110399",
      cSTID: T41PVT === "JZ" ? "TXNVOULISTJZ" : "TXNVOULISTJJ"
    };
    console.log(T41PVT === "JJ", "T41PVT ===");
    Store.dispatch(
      ModalFunction({
        onClickCloseButton: () => {
          Store.dispatch(GetInvTypeDropDown(null));
        },
        onclose: () => { },
        MTitle: <Text fw={500}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "95%",
        position: "right",
        MBody: (i) => <BillFormChallanModal obj={newObj} index={i} />,
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        },
      })
    );
  } else if (T41PVT === "HC" || T41PVT === "HD") {
    let newObj = {
      ...Obj,
      page: T41PVT === "HC" ? "03510342" : "03540342",
      page: T41PVT === "HC" ? "TXNVOULISTHC" : "TXNVOULISTHD"

    };

    Store.dispatch(
      ModalFunction({
        onClickCloseButton: () => {
          Store.dispatch(GetInvTypeDropDown(null));
        },
        onclose: () => { },
        MTitle: <Text fw={500}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "95%",
        position: "right",
        MBody: (i) => <CreditDebitNoteForm obj={newObj} index={i} />,
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        },
      })
    );
  } else if (T41PVT === "HO") {
    // 03960002
    let newObj = {
      ...Obj,
      page: "03450342",
      cSTID: "TXNVOULISTHO"
    };

    console.log("newObj", newObj);
    Store.dispatch(
      ModalFunction({
        onClickCloseButton: () => {
          Store.dispatch(GetInvTypeDropDown(null));
        },
        onclose: () => { },
        MTitle: <Text fw={500}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "95%",
        position: "right",
        MBody: (i) => <OpeningStockAddForm obj={newObj} index={i} />,
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        },
      })
    );
  } else if (T41PVT === "IJ") {
    let newObj = {
      ...Obj,
      page: "03450342",
      cSTID: "TXNVOULISTIJ"
    };

    console.log("newObj", newObj);
    Store.dispatch(
      ModalFunction({
        onClickCloseButton: () => {
          Store.dispatch(GetInvTypeDropDown(null));
        },
        onclose: () => { },
        MTitle: <Text fw={500}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "95%",
        position: "right",
        MBody: (i) => <StockJournalAddForm obj={newObj} index={i} />,
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        },
      })
    );
  }
}
