import React, { useEffect, useRef, useState } from "react";
import { Button, Center, Drawer, Grid, Modal, Skeleton, Text } from "@mantine/core";
import PLTextBox from "../../PlusComponent/PLTextBox";
import GlobalClass from "../../utils/GlobalClass";
import gensetting from "../../utils/gensetting";
import { useDispatch, useSelector } from "react-redux";
import {
  ProductCategoryAPi,
  ProductGroupApi,
} from "../../utils/slices/ProductGroup";
import { GetAccountList } from "../../utils/slices/AccountListSlice";
import { ModalDelete } from "../../utils/slices/ModalSlice";
import Store from "../../utils/store";
import { DataSaveLoading } from "../../utils/slices/DataSaveLoadingSlice";
import ProductUserField from "./ProductUserField";

export function ProductGroupForm(props) {

  console.log("ProductGroupForm Props", props);
  const { OBJ } = props;

  const dispatch = useDispatch();
  const [state, setState] = useState({});
  const [dataObj, setDataObj] = useState();
  const [UFM32, setUFM32] = useState([]);
  const ProductGroupData = useSelector((store) => store?.ProductGroup?.ProductGroupData);
  const ProductCategoryData = useSelector((store) => store?.ProductGroup?.ProductCategoryData);
  const isLoading = useSelector((store) => store?.ProductGroup?.isLoading);
  const SaveLoading = useSelector((store) => store?.DataSaveLoadingSlice?.SaveLoading);
  const hasError = useSelector((state) => state?.ProductGroup?.hasError);
  const ErrorMsg = useSelector((state) => state?.ProductGroup?.ErrorMsg);

  const formRef = useRef(null)
  const UserFieldRef = useRef(null);
  const btnref =  useRef(null);


  let dra = {
    title: "",
    size: "md",
    body: "",
    open: false,
  };
  const [DrawerObj, setDrawerObj] = useState(dra);

  useEffect(() => {
    if (OBJ?.cSTID === "T_M32G" || OBJ?.page === "P_M32G") {
      dispatch(ProductGroupApi(OBJ));
    }
    if (OBJ?.cSTID === "T_M32C" || OBJ?.page === "P_M32C") {
      dispatch(ProductCategoryAPi(OBJ));
    }
  }, [OBJ]);

  useEffect(() => {
    if (hasError && !isLoading) {
      GlobalClass.Notify("error", "Error: " + ErrorMsg.code, ErrorMsg.message);
    }
  }, [hasError]);

  useEffect(() => {
    if (OBJ?.cSTID === "T_M32G" || OBJ?.page === "P_M32G") {
      setState(ProductGroupData?.M32);
      setDataObj(ProductGroupData?.M32)
      setUFM32(ProductGroupData?.UFM32)
    }
    if (OBJ?.cSTID === "T_M32C" || OBJ?.page === "P_M32C") {
      setState(ProductCategoryData?.M32);
      setDataObj(ProductCategoryData?.M32);
      setUFM32(ProductCategoryData?.UFM32)
    }
  }, [OBJ.page, ProductGroupData, ProductCategoryData]);

  useEffect(() => {
    console.log(state, "state data");
    console.log(dataObj);
  }, [state]);

  const groupBtn = () => {
    if (state?.FIELD02?.trim() == "") {
      GlobalClass.Notify( "warning","Validation error","Please fill out all the required fields");
    }
    else if (state.FIELD02 !== "") {

      var data = {
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: OBJ.p0,
        cCode: "",
        cOPara: "",
        cSData: JSON.stringify({ ...state, FIELD10: "G" }),
        YrNo:localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
      };

      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: "A",
        cCode: "",
        cOPara: "",
      });

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      };

      console.log(JSON.stringify(state) === JSON.stringify(dataObj));


      if (OBJ.p0 == "E" && (JSON.stringify(state) === JSON.stringify(dataObj))) {
        Promise.resolve(dispatch(DataSaveLoading({[OBJ?.cSTID]:false}))).then(() => dispatch(ModalDelete(props.index)));
        // GlobalClass.Notify("success", "Edited", "Edited successfully");

        // let PaginationObj = Store.getState().DataSaveLoadingSlice.PaginationObj;
        // dispatch(GetAccountList({
        //   id: "01260123",
        //   name: "Product Group",
        //   p0: "MT",
        //   p1: "T_M32G",
        //   p2: "",
        //   p3: "",
        //   type: "A",
        //   pagination: PaginationObj["01260123"],
        // }));
      }
      else {
        dispatch(DataSaveLoading({[OBJ?.cSTID]:true}));
        GlobalClass.Notify("info", OBJ.p0 == "A" ? "Adding" : "Editing", "Please wait while we process your data");

        fetch(GlobalClass.ApiUrl + GlobalClass.PostM32 + "?pa=" + param, requestOptions)
          .then((response) => response.json())
          .then((data) => {
            if (data.status === "SUCCESS") {
              Promise.resolve(dispatch(DataSaveLoading({[OBJ?.cSTID]:false}))).then(() => dispatch(ModalDelete(props.index)));
              GlobalClass.Notify("success", OBJ.p0 == "A" ? "Added" : "Edited", OBJ.p0 == "A" ? "Added successfully" : "Edited successfully");

              let PaginationObj = Store.getState().DataSaveLoadingSlice.PaginationObj;
              // dispatch(GetAccountList({
              //   id: "01260123",
              //   name: "Product Group",
              //   p0: "MT",
              //   p1: "T_M32G",
              //   p2: "",
              //   p3: "",
              //   type: "A",
              //   pagination: PaginationObj["01260123"],
              // }));
              dispatch(GetAccountList({...OBJ?.BtnPnlObj ,pagination: PaginationObj[OBJ?.cSTID]}))
            }
            else if (data.status === "FAIL") {
              Promise.resolve(dispatch(DataSaveLoading({[OBJ?.cSTID]:false})));
              GlobalClass.Notify("error", data?.status, data?.message);
            }
          })
          .catch((e) => {
            Promise.resolve(dispatch(DataSaveLoading({[OBJ?.cSTID]:false})));
            GlobalClass.Notify("error", "Error", `${e?.message}`);
          });
      }
    }
  };

  const categoryBtn = () => {
    if (state.FIELD02.trim() == "") {
      GlobalClass.Notify("warning", "Validation error", "Please fill out all the required fields");
    }
    else if (state.FIELD02 !== "") {

      var data = {
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: OBJ.p0,
        cCode: "",
        cOPara: "",
        cSData: JSON.stringify({ ...state }),
        YrNo:localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
      };

      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: "A",
        cCode: "",
        cOPara: "",
      });

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      };


      if (OBJ.p0 == "E" && (JSON.stringify(state) === JSON.stringify(dataObj))) {
        Promise.resolve(dispatch(DataSaveLoading({[OBJ?.cSTID]:false}))).then(() => dispatch(ModalDelete(props.index)));
        // GlobalClass.Notify("success", "Edited", "Edited successfully");

        // let PaginationObj = Store.getState().DataSaveLoadingSlice.PaginationObj;
        // dispatch(GetAccountList({
        //   id: "01290123",
        //   name: "Product Category",
        //   p0: "MT",
        //   p1: "T_M32C",
        //   p2: "",
        //   p3: "",
        //   type: "A",
        //   pagination: PaginationObj["01290123"],
        // }));
      }
      else {
        dispatch(DataSaveLoading({[OBJ?.cSTID]:true}));
        GlobalClass.Notify("info", OBJ.p0 == "A" ? "Adding" : "Editing", "Please wait while we process your data");

        fetch(GlobalClass.ApiUrl + GlobalClass.PostM32 + "?pa=" + param, requestOptions)
          .then((response) => response.json())
          .then((data) => {
            if (data.status === "SUCCESS") {
              Promise.resolve(dispatch(DataSaveLoading({[OBJ?.cSTID]:false}))).then(() => dispatch(ModalDelete(props.index)));
              GlobalClass.Notify("success", OBJ.p0 == "A" ? "Added" : "Edited", OBJ.p0 == "A" ? "Added successfully" : "Edited successfully");

              let PaginationObj = Store.getState().DataSaveLoadingSlice.PaginationObj;
              // dispatch(GetAccountList({
              //   id: "01290123",
              //   name: "Product Category",
              //   p0: "MT",
              //   p1: "T_M32C",
              //   p2: "",
              //   p3: "",
              //   type: "A",
              //   pagination: PaginationObj["01290123"],
              // }));
              dispatch(GetAccountList({...OBJ?.BtnPnlObj ,pagination: PaginationObj[OBJ?.cSTID]}))
            } else if (data.status === "FAIL") {
              Promise.resolve(dispatch(DataSaveLoading({[OBJ?.cSTID]:false})));
              GlobalClass.Notify("error", data?.status, data?.message);
            }
          })
          .catch((e) => {
            GlobalClass.Notify("error", "Error", `${e.message}`);

            Promise.resolve(dispatch(DataSaveLoading({[OBJ?.cSTID]:false})));
          });
      }
    }
  };

  useEffect(() => {
    const formElement = formRef.current;
    if (formElement) {
      formElement.addEventListener("keypress", (e) =>
        GlobalClass.formNextInput(e, formRef)
      );
    }
    return () => {
      if (formElement) {
        formElement.removeEventListener("keypress", (e) =>
          GlobalClass.formNextInput(e, formRef)
        );
      }
    };
  }, [formRef]);

  useEffect(() => {
    const formElement = UserFieldRef.current;
    if (formElement) {
      formElement.addEventListener('keypress', (e) => GlobalClass.formNextInput(e, UserFieldRef));
    }

    return () => {
      if (formElement) {
        formElement.removeEventListener('keypress', (e) => GlobalClass.formNextInput(e, UserFieldRef));
      }
    };
  }, [UserFieldRef])

  return (
    <div ref={formRef}>
      <Modal
        opened={DrawerObj?.open}
        // onClose={close}
        withCloseButton={() => { }}
        fullScreen={false} //
        // overlayProps={{ opacity: 0.5, blur: 4 }}
        closeButtonProps={{
          onClick: () => {
            setDrawerObj(dra);
          },
        }}
        title={DrawerObj.title}
        size={DrawerObj.size}
        // centered={.Centered}
        closeOnClickOutside={true}
        // overlayProps={m.Overlay}
        position={DrawerObj?.position ?? "bottom"}
        onClose={
          typeof DrawerObj?.onclose == "function"
            ? DrawerObj?.onclose
            : () => {
              setDrawerObj(dra);
            }
        }
      // scrollAreaComponent={ScrollArea.Autosize}
      >
        {DrawerObj.body}
      </Modal>
      {
        hasError ?
          <>
            <Text>{ErrorMsg?.message}</Text>
            <Button
              onClick={() => {
                if (OBJ) {
                  if (OBJ?.cSTID === "T_M32G" || OBJ?.page === "P_M32G") {
                    dispatch(ProductGroupApi(OBJ));
                  }
                  if (OBJ?.cSTID === "T_M32C" || OBJ?.page === "P_M32C") {
                    dispatch(ProductCategoryAPi(OBJ));
                  }
                }
              }}
            >
              Retry
            </Button>
          </>
          :
          isLoading || SaveLoading[OBJ?.cSTID] == true ? (GlobalClass.RenderLoader("dots")) : (
            <Grid gutter={4}>
              <Grid.Col
                span={12}
                style={{ display: "flex", flexDirection: "row", padding: 0 }}
              >
                <Grid.Col span={2}>
                  <Text style={{ fontSize: 12 }}>
                    {OBJ?.cSTID === "T_M32G"|| OBJ?.page === "P_M32G" ? "Group" : "Category"}
                  </Text>
                </Grid.Col>
                <Grid.Col span={10}>
                  <PLTextBox
                    autoFocus={true}
                    name="Select"
                    value={state?.FIELD02}
                    setEdit={(e) => {
                      setState({ ...state, FIELD02: e.target.value });
                    }}
                  />
                </Grid.Col>
              </Grid.Col>

              <Grid.Col
                span={12}
                style={{ display: "flex", flexDirection: "row", padding: 0 }}
              >
                <Grid.Col span={2}>
                  <Text style={{ fontSize: 12 }}>Short Name</Text>
                </Grid.Col>
                <Grid.Col span={10}>
                  <PLTextBox
                    nmaxlength={10}
                    name="Name"
                    value={state?.FIELD03}
                    setEdit={(e) => {
                      setState({ ...state, FIELD03: e.target.value });
                    }}
                    onKeyDown={(e) => {
                      if (e.key == "Tab" || e.key == "Enter") {
                        UFM32 && UFM32.length > 0 &&
                          setDrawerObj({
                            ...dra,
                            title: <Text fw={700}>User Field</Text>,
                            body: (
                              <>

                                <ProductUserField
                                  UFM32={UFM32} state={state} setState={setState} OBJ={OBJ}
                                  DrawerOBJ={DrawerObj}
                                  setDrawerOBJ={(e) => {
                                    setDrawerObj(e);
                                  }}
                                  onOkClicked={() => {
                                    const btnElement = document.getElementById('okbtn');
                                    console.log("btnElement", btnElement);
                                    btnElement && btnElement?.current?.focus()
                                  }

                                  }

                                />


                              </>
                            ),
                            open: true,
                            size: "sm",
                            position: "right",
                          });
                      }
                    }
                    }
                  />
                </Grid.Col>
              </Grid.Col>

              <Grid.Col>
                <Center>
                  <Button size="sm" style={{ height: "30px", width: "100px" }} ref={btnref}
                    onClick={OBJ?.cSTID === "T_M32G" || OBJ?.page === "P_M32G" ? groupBtn : categoryBtn}
                    onKeyDown={(e) => {
                      if (e.key == "Tab" || e.key == "Enter") {
                        if (OBJ?.cSTID === "T_M32G" || OBJ?.page === "P_M32G") {
                          groupBtn()
                        }
                        else {
                          categoryBtn()
                        }
                      }
                    }}
                  >
                    Ok
                  </Button>
                </Center>
              </Grid.Col>
            </Grid>
          )
      }
    </div>
  );
}
