import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { GetLeadEntryData } from '../../utils/slices/LeadEntrySlice';
import { Button, Center, Checkbox, Grid, Group, Paper, Text } from '@mantine/core';
import PLTextBox from '../../PlusComponent/PLTextBox';
import PLComboBox from '../../PlusComponent/PLComboBox';
import PLDataGrid from '../../PlusComponent/PLDataGrid';
import PLTextarea from '../../PlusComponent/PLTextarea';
import PLNumberBox from '../../PlusComponent/PLNumberBox';
import GlobalClass from '../../utils/GlobalClass';
import PLDateBox from '../../PlusComponent/PLDateBox';
import gensetting from '../../utils/gensetting';
import Store from '../../utils/store';
import { DataSaveLoading } from '../../utils/slices/DataSaveLoadingSlice';
import { ModalDelete } from '../../utils/slices/ModalSlice';
import { GetAccountList } from '../../utils/slices/AccountListSlice';

const LeadEntryForm = (props) => {
  const { obj } = props;
  console.log("lead entry props", obj)
  const getUserId = JSON.parse(localStorage.getItem("authUser"));
  console.log("getUserId", getUserId)
  const { isLoading, hasError, ErrorMsg } = useSelector((state) => state.LeadEntry)
  const LeadEntryData = useSelector((state) => state.LeadEntry?.LeadEntryData)
  console.log("leadentryData", LeadEntryData)
  const SaveLoading = useSelector(
    (state) => state?.DataSaveLoadingSlice?.SaveLoading
  );
  const { M02, P_M15, P_M17, P_M21, P_M31T, P_M81, P_PLSTATE } = LeadEntryData || []
  const dispatch = useDispatch()
  const [M02Obj, setM02Obj] = useState({})
  const [userId, setUserId] = useState(null)
  const formRef = useRef(null)
  const stateRef = useRef(null)
  const typeRef = useRef(null)
  const cityRef = useRef(null)
  const productRef = useRef(null)
  const statusRef = useRef(null)

  // useEffect(() => {
  //   if (getUserId) {
  //     setUserId(JSON.parse(getUserId))
  //   }
  // }, [getUserId])

  useEffect(() => {
    const formElement = formRef.current;
    console.log("formRef", formRef);

    if (formElement) {
      formElement.addEventListener('keypress', (e) => GlobalClass.formNextInput(e, formRef));
    }

    return () => {
      if (formElement) {
        formElement.removeEventListener('keypress', (e) => GlobalClass.formNextInput(e, formRef));
      }
    };
  }, [formRef])

  useEffect(() => {
    if (hasError && !isLoading) {
      GlobalClass.Notify("error", "Error: " + ErrorMsg.code, ErrorMsg.message);
      // dispatch(ModalDelete(props.index))
    }
  }, [hasError]);

  useEffect(() => {
    if (M02) {
      setM02Obj(M02)
    }
  }, [M02])

  useEffect(() => {
    if (obj.p0 == "E" || obj.p0 == "F" || obj.p0 == "S") {
      dispatch(GetLeadEntryData({ action: "E", code: obj.id }))
    } else {
      dispatch(GetLeadEntryData())
    }
  }, [obj])

  const separate = (e) => {
    var paraArr = e?.split("~C~");
    if (paraArr?.length == 1) {
      paraArr[1] = paraArr[0];
    }
    console.log("paraArr", paraArr)
    return paraArr;
  };

  const HandleSubmit = () => {
    // dispatch(DataSaveLoading(true));
    GlobalClass.Notify(
      "info",
      obj.p0 == "A" ? "Adding" : "Editing",
      "Please wait while we process your data"
    )
    let JData = { M02: { ...M02Obj, FIELD70: getUserId?.UserId } }
    console.log("jData", JData)
    var data = {
      CorpID: gensetting.getcorpid(),
      cUser: gensetting.getcuser(),
      cPass: gensetting.getcpass(),
      CmpNo: gensetting.getcmpno(),
      cAction: obj?.p0 == "F" || obj?.p0 == "S" ? "E" : obj?.p0,
      cCode:
        obj?.p0 == "E" || obj?.p0 == "F" || obj?.p0 == "S"
          ? obj?.id
          : "",
      cSData: JSON.stringify(JData),
      YrNo:localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
    };
    let param = JSON.stringify({
      CorpID: gensetting.getcorpid(),
      cUser: gensetting.getcuser(),
      cPass: gensetting.getcpass(),
      CmpNo: gensetting.getcmpno(),
      cAction: obj?.p0 == "F" || obj?.p0 == "S" ? "E" : obj?.p0,
      cCode: obj?.p0 == "E" || obj?.p0 == "F" || obj?.p0 == "S"
        ? obj?.id
        : "",
    });
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    fetch(
      GlobalClass.ApiUrl + GlobalClass.PostM02Lead + "?pa=" + param,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == "SUCCESS") {
          let PaginationObj =
            Store.getState().DataSaveLoadingSlice.PaginationObj;
          Promise.resolve(dispatch(DataSaveLoading({[obj?.cSTID]:false})))
            .then(() => {
              if (obj.p0 == "A") {
                dispatch(GetLeadEntryData())
              } else {
                dispatch(ModalDelete(props.index))
              }
            })
            .then(() =>{
              // dispatch(
              //   GetAccountList({
              //     id: "20570001",
              //     name: "Lead Entry",
              //     p0: "MT",
              //     p1: "P_M02L",
              //     p2: "",
              //     p3: "",
              //     type: "A",
              //     pagination: PaginationObj["20570001"],
              //   })
              // )
            dispatch(GetAccountList({...obj?.BtnPnlObj,pagination: PaginationObj[obj?.cSTID]}))
            }).then(() =>
              GlobalClass.Notify(
                "success",
                obj.p0 == "A" ? "Added" : "Edited",
                obj.p0 == "A" ? "Added successfully" : "Edited successfully"
              )
            );
        } else {
          dispatch(DataSaveLoading({[obj?.cSTID]:false}))
          GlobalClass.Notify("error", data?.status, data?.message)
        }
      })
      .catch((e) => {
        Promise.resolve(
          GlobalClass.Notify(
            "error",
            "Error",
            `${e?.message}`
          )).then(() => dispatch(DataSaveLoading({[obj?.cSTID]:false})));
      });
  }

  return (
    <div ref={formRef}>
      {
        hasError ? <>
          <Text>{ErrorMsg?.message}</Text>
          <Button onClick={() => {
            if (obj && obj.id != "") {
              if (obj.p0 === "E") {
                dispatch(GetLeadEntryData({ action: obj.p0, code: obj.id }));
              } else {
                dispatch(GetLeadEntryData());
              }
            }
          }}>Retry</Button>
        </> :
          SaveLoading[obj?.cSTID] == true ? (
            GlobalClass.RenderLoader("dots")
          ) :
            isLoading ? GlobalClass.RenderLoader("dots") : <>
              {
                obj.p0 == "F" ? <>
                  <Grid gutter={4}>
                    <Grid.Col span={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <Grid.Col span={3}>
                        <Text size={12}>User Name :</Text>
                      </Grid.Col>
                      <Grid.Col span={9}>
                        <PLDataGrid
                          value={{ value: M02Obj?.FIELD69, label: M02Obj?.M81F02 }}
                          // p1={separate(P_M81)[0]}
                          TmData={P_M81}
                          setEdit={(e) => {
                            setM02Obj({ ...M02Obj, FIELD69: e.FIELD01, M81F02: e.FIELD02 })
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                    <Grid.Col span={12}>
                      <Center>
                        <Button
                          onClick={() => HandleSubmit()}
                        >OK</Button>
                      </Center>
                    </Grid.Col>
                  </Grid>
                </> :
                  obj.p0 == "S" ? <>
                    <Grid gutter={4}>
                      <Grid.Col span={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Grid.Col span={3}>
                          <Text size={12}>Status :</Text>
                        </Grid.Col>
                        <Grid.Col span={9}>
                          <PLDataGrid
                            value={{ value: M02Obj?.FIELD69, label: M02Obj?.M15F02 }}
                            TmData={P_M15}
                            setEdit={(e) => {
                              Promise.resolve(setM02Obj({ ...M02Obj, FIELD65: e.FIELD01, M15F02: e.FIELD02 }))
                                .then(() => document.getElementById("statusOk").focus())
                            }}
                          />
                        </Grid.Col>
                      </Grid.Col>
                      <Grid.Col span={12}>
                        <Center>
                          <Button
                            id='statusOk'
                            onClick={() => HandleSubmit()}
                          >OK</Button>
                        </Center>
                      </Grid.Col>
                    </Grid>
                  </> :
                    LeadEntryData && (
                      <Paper shadow="md" radius="md" p="xs" withBorder>
                        <Grid gutter={4}>
                          <Grid.Col
                            span={12}
                            style={{ padding: 0, display: "flex", justifyContent: "center", alignItems: "center" }}
                          >
                            <Grid.Col span={3}>
                              <Text size={12}>Party :</Text>
                            </Grid.Col>
                            <Grid.Col span={9}>
                              <PLTextBox
                                autoFocus={true}
                                value={M02Obj?.FIELD61}
                                setEdit={(e) => {
                                  setM02Obj({ ...M02Obj, FIELD61: e.target.value })
                                }}
                              />
                            </Grid.Col>
                          </Grid.Col>
                          <Grid.Col
                            span={12}
                            style={{ padding: 0, display: "flex", justifyContent: "center", alignItems: "center" }}
                          >
                            <Grid.Col span={3}>
                              <Text size={12}>Contact Person :</Text>
                            </Grid.Col>
                            <Grid.Col span={9}>
                              <PLTextBox
                                value={M02Obj?.FIELD50}
                                setEdit={(e) => {
                                  setM02Obj({ ...M02Obj, FIELD50: e.target.value })
                                }}
                              />
                            </Grid.Col>
                          </Grid.Col>
                          <Grid.Col
                            span={12}
                            style={{ padding: 0, display: "flex", justifyContent: "center", alignItems: "center" }}
                          >
                            <Grid.Col span={3}>
                              <Text size={12}>Mobile :</Text>
                            </Grid.Col>
                            <Grid.Col span={3}>
                              <PLTextBox
                                value={M02Obj?.FIELD35}
                                setEdit={(e) => {
                                  setM02Obj({ ...M02Obj, FIELD35: e.target.value })
                                }}
                              />
                            </Grid.Col>
                            <Grid.Col span={3}>
                              <Text size={12} style={{ textAlign: "center" }}>Phone No :</Text>
                            </Grid.Col>
                            <Grid.Col span={3}>
                              <PLTextBox
                                value={M02Obj?.FIELD21}
                                setEdit={(e) => {
                                  setM02Obj({ ...M02Obj, FIELD21: e.target.value })
                                }}
                              />
                            </Grid.Col>
                          </Grid.Col>
                          <Grid.Col
                            span={12}
                            style={{ padding: 0, display: "flex", justifyContent: "center", alignItems: "center" }}
                          >
                            <Grid.Col span={3}>
                              <Text size={12}>Email :</Text>
                            </Grid.Col>
                            <Grid.Col span={9}>
                              <PLTextBox
                                value={M02Obj?.FIELD43}
                                setEdit={(e) => {
                                  setM02Obj({ ...M02Obj, FIELD43: e.target.value })
                                }}
                                onKeyDown={(e) => {
                                  if (e.key == "Enter" || e.key == "Tab") {
                                    cityRef?.current?.handlePopoverToggle()
                                  }
                                }}
                              />
                            </Grid.Col>
                          </Grid.Col>
                          <Grid.Col
                            span={12}
                            style={{ padding: 0, display: "flex", justifyContent: "center", alignItems: "center" }}
                          >
                            <Grid.Col span={3}>
                              <Text size={12}>City :</Text>
                            </Grid.Col>
                            <Grid.Col span={3}>
                              <PLDataGrid
                                // p1={separate(P_M31T)[0]}
                                // p0={separate(P_M31T)[1]}
                                ref={cityRef}
                                TmData={P_M31T}
                                width={"500px"}
                                value={{ value: M02Obj?.FIELD08, label: M02Obj?.FIELD05 }}
                                setEdit={(e) => {
                                  console.log("city", e)
                                  Promise.resolve(setM02Obj({ ...M02Obj, FIELD08: e?.FIELD01, FIELD05: e?.FIELD02 }))
                                    .then(() => stateRef.current?.handlePopoverToggle())
                                }}
                              />
                            </Grid.Col>

                            <Grid.Col span={3}>
                              <Text size={12} style={{ textAlign: "center" }}>State :</Text>
                            </Grid.Col>
                            <Grid.Col span={3}>
                              <PLDataGrid
                                // p1={separate(P_PLSTATE)[0]}
                                // p0={separate(P_PLSTATE)[1]}
                                ref={stateRef}
                                width={"500px"}
                                TmData={P_PLSTATE}
                                value={{ value: M02Obj?.FIELD12, label: M02Obj?.FIELD13 }}
                                valexpr={"PLSF01"}
                                dispexpr={"PLSF02"}
                                setEdit={(e) => {
                                  Promise.resolve(setM02Obj({ ...M02Obj, FIELD12: e?.PLSF01, FIELD13: e?.PLSF02 }))
                                    .then(() => document.getElementById("address1").focus())
                                }}
                              />
                            </Grid.Col>
                          </Grid.Col>
                          <Grid.Col
                            span={12}
                            style={{ padding: 0, display: "flex", justifyContent: "center", alignItems: "center" }}
                          >
                            <Grid.Col span={3}>
                              <Text size={12}>Address 1 :</Text>
                            </Grid.Col>
                            <Grid.Col span={9}>
                              <PLTextBox
                                id="address1"
                                value={M02Obj?.FIELD02}
                                setEdit={(e) => {
                                  setM02Obj({ ...M02Obj, FIELD02: e.target.value })
                                }}
                              />
                            </Grid.Col>
                          </Grid.Col>
                          <Grid.Col
                            span={12}
                            style={{ padding: 0, display: "flex", justifyContent: "center", alignItems: "center" }}
                          >
                            <Grid.Col span={3}>
                              <Text size={12}>Address 2 :</Text>
                            </Grid.Col>
                            <Grid.Col span={9}>
                              <PLTextBox
                                value={M02Obj?.FIELD03}
                                setEdit={(e) => {
                                  setM02Obj({ ...M02Obj, FIELD03: e.target.value })
                                }}
                              />
                            </Grid.Col>
                          </Grid.Col>
                          <Grid.Col
                            span={12}
                            style={{ padding: 0, display: "flex", justifyContent: "center", alignItems: "center" }}
                          >
                            <Grid.Col span={3}>
                              <Text size={12}>Address 3 :</Text>
                            </Grid.Col>
                            <Grid.Col span={9}>
                              <PLTextBox
                                value={M02Obj?.FIELD04}
                                setEdit={(e) => {
                                  setM02Obj({ ...M02Obj, FIELD04: e.target.value })
                                }}
                                onKeyDown={(e) => {
                                  if (e.key == "Enter" || e.key == "Tab") {
                                    return typeRef.current?.handlePopoverToggle()
                                  }
                                }}
                              />
                            </Grid.Col>
                          </Grid.Col>
                          {/* <Grid.Col
                            span={12}
                            style={{ padding: 0, display: "flex", justifyContent: "center", alignItems: "center" }}
                          >
                            <Grid.Col span={3}>
                              <Text size={12}>Type :</Text>
                            </Grid.Col>
                            <Grid.Col span={9}>
                              <PLDataGrid
                                ref={typeRef}
                                TmData={P_M17}
                                value={{ value: M02Obj?.FIELD66, label: M02Obj?.M17F02 }}
                                setEdit={(e) => {
                                  Promise.resolve(setM02Obj({ ...M02Obj, FIELD66: e?.FIELD01, M17F02: e?.FIELD02 }))
                                    .then(() => document.getElementById("remarks").focus())
                                }}
                              />
                            </Grid.Col>
                          </Grid.Col> */}
                          <Grid.Col
                            span={12}
                            style={{ padding: 0, display: "flex", justifyContent: "center", alignItems: "center" }}
                          >
                            <Grid.Col span={3}>
                              <Text size={12}>Remarks :</Text>
                            </Grid.Col>
                            <Grid.Col span={9}>
                              <PLTextarea
                                id="remarks"
                                minRows={2}
                                value={M02Obj?.FIELD15}
                                onChange={(e) => {
                                  // console.log(e)
                                  setM02Obj({
                                    ...M02Obj,
                                    FIELD15: e.target.value,
                                  });
                                }}
                                onKeyDown={(e) => {
                                  if (e.key == "Enter" || e.key == "Tab") {
                                    productRef.current?.handlePopoverToggle()
                                  }
                                }}
                              />
                            </Grid.Col>
                          </Grid.Col>
                          {/* <Grid.Col
                            span={12}
                            style={{ padding: 0, display: "flex", justifyContent: "center", alignItems: "center" }}
                          >
                            <Grid.Col span={3}>
                              <Text size={12}>Product :</Text>
                            </Grid.Col>
                            <Grid.Col span={9}>
                              <PLDataGrid
                                ref={productRef}
                                TmData={P_M21}
                                value={{ value: M02Obj?.FIELD67, label: M02Obj?.M21F02 }}
                                // p1={separate(P_M21)[0]}
                                // p0={separate(P_M21)[1]}
                                isMulti={true}
                                setEdit={(e) => {
                                  let selectedRow = ""
                                  let NameVal = ""
                                  if (e?.length > 0) {
                                    selectedRow = e?.map((item) => item?.original?.FIELD01).join();
                                    NameVal = e?.map((v) => v?.original?.FIELD02).join()
                                    console.log("selectedRow", selectedRow)
                                  }
                                  Promise.resolve(setM02Obj({ ...M02Obj, FIELD67: selectedRow, M21F02: NameVal }))
                                    .then(() => document.getElementById("rate")?.focus())
                                }}
                              />
                            </Grid.Col>
                          </Grid.Col>
                          <Grid.Col
                            span={12}
                            style={{ padding: 0, display: "flex", justifyContent: "space-between", alignItems: "center" }}
                          >
                            <Grid.Col span={3}>
                              <Text size={12}>Rate :</Text>
                            </Grid.Col>
                            <Grid.Col span={3}>
                              <PLNumberBox
                                id="rate"
                                value={M02Obj?.FIELD68}
                                setEdit={(e) => {
                                  setM02Obj({ ...M02Obj, FIELD68: e })
                                }}
                                onKeyDown={(e) => {
                                  if (e.key == "Enter" || e.key == "Tab") {
                                    statusRef.current?.handlePopoverToggle()
                                  }
                                }}
                              />
                            </Grid.Col>
                            <Grid.Col span={3}>
                              <Text size={12} style={{ textAlign: "center" }}>Status :</Text>
                            </Grid.Col>
                            <Grid.Col span={3}>
                              <PLDataGrid
                                ref={statusRef}
                                width={"500px"}
                                TmData={P_M15}
                                value={{ value: M02Obj?.FIELD65, label: M02Obj?.M15F02 }}
                                // p1={separate(P_M15)[0]}
                                // p0={separate(P_M15)[1]}
                                setEdit={(e) => {
                                  Promise.resolve(setM02Obj({ ...M02Obj, FIELD65: e?.FIELD01, M15F02: e?.FIELD02 }))
                                    .then(() => {
                                      if (obj.p0 == "A") {
                                        document.getElementById("existCust").focus()
                                      } else {
                                        document.getElementById("createdAt").focus()
                                      }
                                    })
                                }}
                              />
                            </Grid.Col>
                          </Grid.Col> */}
                          {
                            obj?.p0 == "E" &&
                            <Grid.Col
                              span={12}
                              style={{ padding: 0, display: "flex", justifyContent: "space-between", alignItems: "center" }}
                            >
                              <Grid.Col span={3}>
                                <Text size={12}>Created At :</Text>
                              </Grid.Col>
                              <Grid.Col
                                span={3}
                              >
                                <PLDateBox
                                  name="createdAt"
                                  dispformat="DD/MM/YYYY"
                                  defaultval={M02Obj?.FIELD84}
                                  setEdit={(e) => {
                                    console.log("DATEPICKER=>", e)
                                    let year = e.toLocaleString("en-IN", {
                                      year: "numeric",
                                    });
                                    let month = e.toLocaleString("en-IN", {
                                      month: "2-digit",
                                    });
                                    let day = e.toLocaleString("en-IN", {
                                      day: "2-digit",
                                    });

                                    let StringDate = year + month + day;
                                    if (StringDate?.length == 8) {
                                      Promise.resolve(setM02Obj({ ...M02Obj, FIELD84: StringDate }))
                                        .then(() => document.getElementById("updatedAt").focus())
                                    }
                                  }}
                                />
                              </Grid.Col>
                              <Grid.Col span={3}>
                                <Text size={12} style={{ textAlign: "center" }}>Updated At :</Text>
                              </Grid.Col>
                              <Grid.Col span={3}>
                                <PLDateBox
                                  name="updatedAt"
                                  dispformat="DD/MM/YYYY"
                                  defaultval={M02Obj?.FIELD85}
                                  setEdit={(e) => {
                                    console.log("DATEPICKER=>", e)
                                    let year = e.toLocaleString("en-IN", {
                                      year: "numeric",
                                    });
                                    let month = e.toLocaleString("en-IN", {
                                      month: "2-digit",
                                    });
                                    let day = e.toLocaleString("en-IN", {
                                      day: "2-digit",
                                    });

                                    let StringDate = year + month + day;
                                    if (StringDate?.length == 8) {
                                      Promise.resolve(setM02Obj({ ...M02Obj, FIELD85: StringDate }))
                                        .then(() => document.getElementById("existCust").focus())
                                    }
                                  }}
                                />
                              </Grid.Col>
                            </Grid.Col>
                          }

                          {/* <Grid.Col span={12} style={{ padding: 0, display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Grid.Col span={3} >
                              <Text size={12}>Existing Customer?</Text>
                            </Grid.Col>
                            <Grid.Col span={9}>
                              <Checkbox
                                id='existCust'
                              />
                            </Grid.Col>
                          </Grid.Col> */}

                          <Grid.Col span={12}>
                            <Group
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Button
                                style={{ height: "30px", width: "100px" }}
                                size="sm"
                                id="okbutton"
                                onKeyDown={(e) => {
                                  if (e.key == "Enter") {
                                    HandleSubmit()
                                  }
                                }}
                                onClick={() => HandleSubmit()}
                              >OK</Button>
                              <Button
                                style={{ height: "30px", width: "100px" }}
                                size="sm"
                                onKeyDown={(e) => {
                                  if (e.key == "Enter") {
                                    dispatch(ModalDelete(props.index))
                                  }
                                }}
                                onClick={() => dispatch(ModalDelete(props.index))}
                              >Cancel</Button>
                            </Group>
                          </Grid.Col>
                        </Grid>
                      </Paper>
                    )
              }
            </>
      }
    </div>
  )
}

export default LeadEntryForm