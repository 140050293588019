import { modals } from '@mantine/modals';
import { createColumnHelper } from '@tanstack/react-table';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { DeleteFinYear, GetUserList, SetPostFinYearNull, SetYearAndDateData } from '../../utils/slices/UserListSlice';
import { ClearTabs } from '../../utils/slices/TabList';
import { GetLanguageData } from '../../utils/slices/LanguageSlice';
import { getCurrentDate, getSortingField } from '../../utils/slices/AccountListSlice';
import { Button, Group, Modal, Text } from '@mantine/core';
import GlobalClass from '../../utils/GlobalClass';
import Store from '../../utils/store';
import { ModalDelete, ModalFunction } from '../../utils/slices/ModalSlice';
import NewFinYearForm from '../../Utility/YearEnd/NewFinYearForm';
import UpdateBalanceForm from '../../Utility/YearEnd/UpdateBalanceForm';
import useWindowDimensions from '../../utils/UseWindowDimensions';
export const YearAndDateTable = (props) => {

  const { obj, index, SelectCmpRow } = props

  console.log('YearAndDateTable props=>', props);

  const { height } = useWindowDimensions();

  const YearTableRef = useRef();

  const GetYearDateData = useSelector((state) => state.CompanySetup?.GetYearDateData?.YearList);
  const apiSuccess = useSelector((state) => state?.Language.apiSuccess);
  const { DelFinRes, DelFinYearhasError, DelFinYearErrorMsg, DelFinYearisLoading } = useSelector((state) => state?.UserList)
  // const GetGeneral = useSelector((state) => state.Language.General);

  const [columns, setColumns] = useState([]);
  const [Data, setData] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 25,
  });

  let dra = {
    title: "",
    size: "md",
    body: "",
    open: false,
    onClickCloseBtn: null
  }

  const [DrawerObj, setDrawerObj] = useState(dra);

  const dispatch = useDispatch();

  // const columnHelper = createColumnHelper();

  console.log('GetYearDateData=>', GetYearDateData);
  useEffect(() => {
    let yearData = [...(GetYearDateData || [])]
    setData(yearData?.sort((a, b) => parseFloat(a.YearNo) - parseFloat(b.YearNo)))
  }, [GetYearDateData])


  useEffect(() => {
    var cols = [];
    if (GetYearDateData && GetYearDateData.length > 0) {
      Object.keys(GetYearDateData[0]).map((key, ind) => {
        cols.push({
          accessorKey: key,
          size: "auto",
          header: obj?.id == "YD" ? (key == "YearNo" ? "No." : key == "strtDate" ? "From Date" : key == "endDate" ? "To Date" : "") : (key == "strtDate" ? "From" : key == "endDate" ? "To Date" : ""),
          Cell: ({ row, cell }) => {
            let val = cell.getValue();
            let newVal;
            if (key == "strtDate") {
              newVal = val?.slice(6, 8) + "/" + val?.slice(4, 6) + "/" + val?.slice(0, 4)
              return <div
                style={{ outline: "none" }}
                tabIndex={0}
                onKeyDown={(e) => {
                  console.log("onKeyDownCell=>", e)
                  if (e?.key == "Enter") {
                    if (obj?.id != "YD") {
                      if (sessionStorage.getItem("year") == row.getValue("YearNo")) {
                      } else {
                        sessionStorage.setItem("year", row.getValue("YearNo"));
                        dispatch(ClearTabs(''))
                      }

                      Promise.resolve(SendSelectedRow(row)).then(() => {
                        modals.closeAll()
                      })
                    }
                    setRowSelection((prev) => ({
                      [row?.id]: true,
                    }))
                  }
                }}>{newVal}</div>
            } else if (key == "endDate") {
              newVal = val?.slice(6, 8) + "/" + val?.slice(4, 6) + "/" + val?.slice(0, 4)
              return <div
                style={{ outline: "none" }}
                tabIndex={0}
                onKeyDown={(e) => {
                  console.log("onKeyDownCell=>", e)
                  if (e?.key == "Enter") {
                    if (obj?.id != "YD") {
                      if (sessionStorage.getItem("year") == row.getValue("YearNo")) {
                      } else {
                        sessionStorage.setItem("year", row.getValue("YearNo"));
                        dispatch(ClearTabs(''))
                      }

                      Promise.resolve(SendSelectedRow(row)).then(() => {
                        modals.closeAll()
                      })
                    }
                    setRowSelection((prev) => ({
                      [row?.id]: true,
                    }))
                  }
                }}>{newVal}</div>
            } else if (key == "YearNo") {
              newVal = parseInt(val)
              return <div
                style={{ outline: "none" }}
                tabIndex={0}
                onKeyDown={(e) => {
                  console.log("onKeyDownCell=>", e)
                  if (e?.key == "Enter") {
                    if (obj?.id != "YD") {
                      if (sessionStorage.getItem("year") == row.getValue("YearNo")) {
                      } else {
                        sessionStorage.setItem("year", row.getValue("YearNo"));
                        dispatch(ClearTabs(''))
                      }

                      Promise.resolve(SendSelectedRow(row)).then(() => {
                        modals.closeAll()
                      })
                    }
                    setRowSelection((prev) => ({
                      [row?.id]: true,
                    }))
                  }
                }}>{newVal}</div>
            }
            return <div
              style={{ outline: "none" }}
              tabIndex={0}
              onKeyDown={(e) => {
                console.log("onKeyDownCell=>", e)
                if (e?.key == "Enter") {
                  if (obj?.id != "YD") {
                    if (sessionStorage.getItem("year") == row.getValue("YearNo")) {
                    } else {
                      sessionStorage.setItem("year", row.getValue("YearNo"));
                      dispatch(ClearTabs(''))
                    }

                    Promise.resolve(SendSelectedRow(row)).then(() => {
                      modals.closeAll()
                    })
                  }
                  setRowSelection((prev) => ({
                    [row?.id]: true,
                  }))
                }
              }}>{val}</div>

          },

        })
      })
    }
    setColumns(cols);
    console.log('GetYearDateData columns=>', cols);
  }, [GetYearDateData])

  useEffect(() => {
    if (selectedRowData != null) {
      console.log("selectedRowData", selectedRowData)
      new Promise((resolve) => {
        dispatch(SetYearAndDateData(selectedRowData))
        resolve()
      }).then(() => {
        if (SelectCmpRow) {
          if (SelectCmpRow?.cmpNo == localStorage.getItem("cmp")) {
            if (parseInt(selectedRowData?.YearNo) == localStorage.getItem("YrNo")) {
              dispatch(ModalDelete(props?.index))
              props?.yearIndex?.close()
            } else {
              localStorage.setItem("YrNo", parseInt(selectedRowData?.YearNo))
              window.location.reload()
            }
          } else {
            localStorage.setItem("cmp", SelectCmpRow?.cmpNo)
            localStorage.setItem("YrNo", parseInt(selectedRowData?.YearNo))
            window.location.reload()
          }
        }else{
          if (parseInt(selectedRowData?.YearNo) == localStorage.getItem("YrNo")) {
            dispatch(ModalDelete(props?.index))
          } else {
            localStorage.setItem("YrNo", parseInt(selectedRowData?.YearNo))
            window.location.reload()
          }
        }
      })

      // .then(() => {
      //   localStorage.setItem("YrNo", selectedRowData?.YearNo?.slice(0, 2))
      //   if (SelectCmpRow) {
      //     console.log("SelectCmpRow", SelectCmpRow);
      //     localStorage.setItem("cmp", SelectCmpRow?.cmpNo)
      //   }
      // }).then(() => {
      //   window.location.reload()
      // })

      // Promise.resolve(dispatch(SetYearAndDateData(selectedRowData))).then(() => {
      //   localStorage.setItem("YrNo", selectedRowData?.YearNo?.slice(0, 2))
      // }).then(() => {
      //   if (localStorage.getItem("YrNo") != selectedRowData?.YearNo?.slice(0, 2)) {
      //     window.location.reload();
      //   }
      // })

      // dispatch(GetLanguageData({"YrNo":selectedRowData?.YearNo?.slice(0,2)}));
    };
  }, [selectedRowData])

  useEffect(() => {
    if (DelFinYearhasError && DelFinRes && DelFinYearErrorMsg) {

      // DelFinRes, DelFinYearhasError, DelFinYearErrorMsg, DelFinYearisLoading

      // console.log('DelFinReshasErrorErrorMsgisLoading =>', [{
      //   "DelFinRes": DelFinRes,
      //   "DelFinYearhasError": DelFinYearhasError,
      //   "DelFinYearErrorMsg": DelFinYearErrorMsg,
      //   "DelFinYearisLoading": DelFinYearisLoading
      // }]);

      GlobalClass.Notify("error", "Error: " + DelFinRes, DelFinYearErrorMsg);
    }

  }, [DelFinRes, DelFinYearhasError, DelFinYearErrorMsg])


  const SendSelectedRow = (row) => {
    // console.log(GetYearDateData,"SendSelectedRow GetYearDateData")
    // console.log("SendSelectedRow e",row)
    GetYearDateData.map((d, i) => {
      if (d?.YearNo == row?.original?.YearNo) {
        setSelectedRowData(d)
      }
    })
  }
  // useEffect(() => {
  //   console.log("data", Data);
  //   if (localStorage.getItem("YrNo") && Data && Data.length > 0) {
  //     let selectedYear = Data.find((d) => d.YearNo.split(".")[0] == localStorage.getItem("YrNo"))
  //     console.log("selectedYear", selectedYear);
  //     setRowSelection({ [selectedYear?.YearNo]: true })
  //   }
  // }, [Data])

  useEffect(() => {
    if (Data && Data.length > 0) {
      let Obj = {};
      if (SelectCmpRow?.cmpNo) {
        if (localStorage.getItem("cmp") == SelectCmpRow?.cmpNo) {
          let selectedYear = Data.filter((d) => parseInt(d.YearNo) == localStorage.getItem("YrNo"))
          Obj = { [selectedYear?.[0]?.YearNo]: true }
        } else {
          let FindlastRow = Data?.filter((d, i) => parseInt(i) == parseInt(Data.length - 1))
          Obj = { [FindlastRow?.[0]?.YearNo]: true }
        }
      } else {
        if (localStorage.getItem("YrNo") != "") {
          let selectedYear = Data.filter((d) => parseInt(d.YearNo) == localStorage.getItem("YrNo"))
          Obj = { [selectedYear?.[0]?.YearNo]: true }
        } else {
          let FindlastRow = Data?.filter((d, i) => parseInt(i) == parseInt(Data.length - 1))
          Obj = { [FindlastRow?.[0]?.YearNo]: true }
        }
      }
      setRowSelection(Obj)
      setTimeout(() => {
        if (YearTableRef?.current) {
          // console.log("YearTableRef?.current?.focus()", YearTableRef?.current);
          let table = YearTableRef?.current;
          const rows = table.querySelectorAll('tr');
          let findInd = Data?.findIndex((d, i) => (parseInt(d?.YearNo) == Object.keys(Obj)[0]))
          // console.log("findInd==>", findInd);
          rows[findInd]?.focus()
        }
      }, 500)
    }
  }, [Data, SelectCmpRow])

  console.log("FindlastRow == rowSelection", rowSelection);

  const tabled = useMantineReactTable({
    data: Data,
    columns,
    // enableRowSelection: false, //enable some features
    enableTopToolbar: false,
    enableColumnActions: false,
    enableSorting: false,
    enableMultiRowSelection: false,
    enablePagination: false,
    getRowId: (data) => data.YearNo,
    onPaginationChange: setPagination,
    mantineTableProps: { withColumnBorders: true },
    mantineTableBodyProps: {
      ref: YearTableRef,
    },
    mantineTableContainerProps: {
      // sx: { height: 450 }
      sx: { height: height * 0.6 }
    },
    initialState: {
      columnVisibility: { YearNo: obj?.id == "YD" },
      pagination
    },
    state: {
      density: "5px",
      rowSelection,
      pagination
    },
    mantineTableBodyRowProps: ({ row }) => ({
      tabIndex: 0,
      onKeyUp: (e) => handleRowKeyDown(e, row),
      onClick: () => {
        // console.log('mantineTableBodyRowProps',row);
        setRowSelection((prev) => ({
          [row?.id]: !prev[row?.id],
        }));
      },
      onDoubleClick: () => {
        console.log("row", row);
        // if (obj?.id != "12961281") {
        if (obj?.id != "YD") {
          if (sessionStorage.getItem("year") == row.getValue("YearNo")) {
            // modals.closeAll();
            // setCmpLoading(false);
            // cmpAction.close();
          } else {
            sessionStorage.setItem("year", row.getValue("YearNo"));
            // setSelected(row.getValue("YearNo"));
            // dispatch(SetCmpNumber(row.getValue("YearNo")))
            // setCmpLoading(true);
            // modals.closeAll();
            dispatch(ClearTabs(''))
          }

          Promise.resolve(SendSelectedRow(row)).then(() => {
            modals.closeAll()
          })
        }
        // setRowSelection((prev) => ({
        //   [row.id]: !prev[row.id],
        // }));
        setRowSelection((prev) => ({
          [row?.id]: true,
        }))
      },
      onKeyDown: (e) => {
        console.log("onKeyDown==>", e);
        if (e?.key == "Enter") {
          if (obj?.id != "YD") {
            if (sessionStorage.getItem("year") == row.getValue("YearNo")) {
            } else {
              sessionStorage.setItem("year", row.getValue("YearNo"));
              dispatch(ClearTabs(''))
            }

            Promise.resolve(SendSelectedRow(row)).then(() => {
              modals.closeAll()
            })
          }
          setRowSelection((prev) => ({
            [row?.id]: true,
          }))
        }
      },
      selected: rowSelection[row.id],
      sx: { cursor: 'pointer' },
      // height: 30
    }),
    renderBottomToolbar: () => (
      <Group
        style={{
          marginTop: "5px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {obj?.id != "YD" && <> <Button variant="outline" onClick={() => {
          let OBJ = {
            "name": "New Fin. Year",
            "type": "A",
            "p0": ""
          }


          let yearRow = tabled?.getSelectedRowModel()?.flatRows[0]?.original
          yearRow = { ...yearRow, YearNo: String(parseInt(yearRow?.YearNo)) }

          console.log("yearRowyearRow", yearRow)

          Store.dispatch(
            ModalFunction({
              MTitle: <Text fw={700}>{OBJ?.name}</Text>,
              MAction: true,
              MSize: "50%",
              fullScreen: false,
              MBody: (i) => <NewFinYearForm obj={OBJ} index={i} CmpRow={SelectCmpRow} yearRow={yearRow} />,
              MClose: true,
              onclose: () => { },
              onClickCloseButton: () => {
                Store.dispatch(SetPostFinYearNull())
              }
            })
          );

        }} compact>
          New Year
        </Button>

          <Button variant="outline" onClick={() => { }} compact>
            Default
          </Button>

          <Button variant="outline" onClick={() => {
            let OBJ = {
              "name": "Update Balance",
              "type": "A",
              "p0": ""
            }

            let yearRow = tabled?.getSelectedRowModel()?.flatRows[0]?.original
            yearRow = { ...yearRow, YearNo: String(parseInt(yearRow?.YearNo)) }

            Store.dispatch(
              ModalFunction({
                MTitle: <Text fw={700}>{OBJ?.name}</Text>,
                MAction: true,
                MSize: "40%",
                fullScreen: false,
                MBody: (i) => <UpdateBalanceForm obj={OBJ} index={i} CmpRow={SelectCmpRow} yearRow={yearRow} />,
                MClose: true,
                onclose: () => { },
                onClickCloseButton: () => {
                  Store.dispatch(SetPostFinYearNull())
                }
              })
            );
          }} compact>
            Update Balance
          </Button>
        </>
        }

        {obj?.id == "YD" && <Button
          variant="outline"
          onClick={() => {
            if (tabled.getSelectedRowModel().flatRows?.length > 0) {
              handleDeleteYear()
            }
          }}
          compact>
          Delete
        </Button>}
      </Group>
    )
  })

  const handleRowKeyDown = (e, row) => {

    // console.log("handleRowKeyDown parameter =>", [{
    //   "e": e,
    //   "row": row,
    //   "TranTableRef.current": YearTableRef.current
    // }])

    const table = YearTableRef.current;
    const rows = table.querySelectorAll('tr');
    const currentRowIndex = Array.from(rows).indexOf(e.target);
    console.log("TABKEY", [{
      "rows": rows,
      currentRowIndex: currentRowIndex
    }]);
    switch (e?.key) {
      case 'ArrowDown':
        // e.preventDefault();
        if (currentRowIndex < rows.length - 1) {
          e.preventDefault();
          rows[currentRowIndex + 1].focus();
          // rows[currentRowIndex + 1].scrollIntoView({ behavior: "smooth",block: "start"})
          let FindIndex = Data?.filter((d, i) => i == (currentRowIndex + 1))
          console.log("FindIndexUp =>", FindIndex)
          setRowSelection({ [FindIndex?.[0]?.YearNo]: true })

        } else if (
          tabled?.getPageCount() && tabled?.getPageCount() > 1 &&
          pagination?.pageIndex < tabled?.getPageCount() - 1
        ) {
          // Navigate to the next page
          tabled.nextPage()
          // setPagination((prev) => ({
          //   ...prev,
          //   pageIndex: prev.pageIndex + 1,
          // }));
          // rows[0].scrollIntoView({ behavior: "smooth",block: "start"})
          // rows[0].focus();
        }

        break;
      case 'ArrowUp':

        if (currentRowIndex > 0) {
          e.preventDefault();
          rows[currentRowIndex - 1].focus();
          // rows[currentRowIndex - 1].scrollIntoView({ behavior: "smooth",block: "end"})
          let FindIndex = Data?.filter((d, i) => i == (currentRowIndex - 1))
          setRowSelection({ [FindIndex?.[0]?.YearNo]: true })
        } else if (pagination.pageIndex > 0) {
          // Navigate to the previous page
          tabled.previousPage()
          // setPagination((prev) => ({
          //   ...prev,
          //   pageIndex: prev.pageIndex - 1,
          // }));
          // rows[0].scrollIntoView({ behavior: "smooth",block: "start"})
          // rows[0].focus();
        }

        break;
      case "Enter":
        break;
      default:
        break;
    }

  }

  const handleDeleteYear = () => {
    let GetSelectedRow = tabled.getSelectedRowModel().flatRows[0].original;
    console.log("GetSelectedRow =>", GetSelectedRow);
    dispatch(DeleteFinYear({
      Type: parseInt(GetSelectedRow?.YearNo),
      From: GetSelectedRow?.strtDate,
      To: GetSelectedRow?.endDate
    }))

  }

  // console.log('rowSelection==>', rowSelection);
  // console.log('selectedRowData==>', selectedRowData)

  return (<>
    <Modal
      zIndex={205}
      opened={DrawerObj?.open}
      // onClose={close}
      withCloseButton={true}

      fullScreen={false}//
      // overlayProps={{ opacity: 0.5, blur: 4 }}
      closeButtonProps={{
        onClick: (e) => {
          if (typeof DrawerObj?.onClickCloseButton == "function") {
            DrawerObj?.onClickCloseButton();
          }
          else {
            setDrawerObj(dra);
          }
        },
      }}
      title={DrawerObj?.title}
      size={DrawerObj?.size}
      // centered={.Centered}
      closeOnClickOutside={true}
      // overlayProps={m.Overlay}
      position={DrawerObj?.position ?? 'bottom'}
      onClose={typeof DrawerObj?.onclose == "function" ? DrawerObj?.onclose : () => { setDrawerObj(dra); }}
    // scrollAreaComponent={ScrollArea.Autosize}
    >
      {DrawerObj.body}
    </Modal>
    {
      GetYearDateData && GetYearDateData?.length > 0 &&
      <MantineReactTable table={tabled} />
    }
  </>)
}

{/* <Group pt={5} spacing={"xs"} position="center">
      {
        obj?.id == "YD" && <Button
          style={{ height: "30px", width: "100px" }}
          size="sm"
          onClick={() => {
            if (tabled.getSelectedRowModel().flatRows?.length > 0) {
              handleDeleteYear()
            }
          }}
        >
          Delete
        </Button>
      }
    </Group> */}

