import { Drawer, Modal, ScrollArea } from "@mantine/core";
import { modals } from "@mantine/modals";
import React, { useEffect } from "react";
import Store from "../utils/store";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { ModalDelete } from "../utils/slices/ModalSlice";

import GlobalClass from "../utils/GlobalClass";

export const ModalBox = () => {
  const [opened, { open, close }] = useDisclosure(false);
  const modalAttr = Store.getState().ModalSlice.ModalAttr;
  const TabList = Store.getState().TabList.value;
  const ModalFunction = () => {
    const media = useMediaQuery('(min-width: 62em)');
    let modalShow;

    if (modalAttr.length > 0) {

      modalShow = modalAttr.map((n, i) => {
        if (n.tab && false) {
          const uniqueId = `${Date.now()}-${Math.random().toString(36).slice(2, 11)}`;

          var obj = {
            id: "modalForm" + uniqueId,
            modalid: i,
            name: "modalForm" + uniqueId,
            p0: n,
            fromModal: true,
          }
          // Store.dispatch(TabAdd(obj));
          GlobalClass.addTab(obj, () => (<div>{n.MBody("modalForm" + uniqueId)}</div>))
          Store.dispatch(ModalDelete(i));
        } else {
          // console.log("ModalAttrCondition 2");
          let m = {...n,tab:false};
          return (
            <Modal
              onKeyDown={(e) => {
                // console.log("ModalonKeyDown =>",e);
                if (e.key == "Escape") {
                  // if (window.confirm("Exit Without Save?")) {
                  //   Store.dispatch(ModalDelete(i));
                  //   if (typeof m?.onClickCloseButton == "function") {
                  //     m.onClickCloseButton();
                  //   }
                  // }

                  GlobalClass.Confirm(
                    "red",
                    "Exit Without Save?",
                    "Your changes have not been saved. Do you really want to exit?",
                    () => {
                      Store.dispatch(ModalDelete(i));
                      if (typeof m?.onClickCloseButton == "function") {
                        m.onClickCloseButton();
                      }
                    },
                    () => { },
                    "Cancel",
                    "Confirm"
                )
                }
              }}
             // zIndex={(parseInt(205)+parseInt(i))} // Rathod kishan
              opened={m.MAction}
              trapFocus
              withinPortal
              target={"#modalDiv"}
              scrollAreaComponent={ScrollArea.Autosize}
              lockScroll={false}
              // onClose={close}
              withCloseButton={m.MClose}
              fullScreen={m.fullScreen}//
              // overlayProps={{ opacity: 0.5, blur: 4 }}
              closeButtonProps={{
                onClick: () => {
                  Store.dispatch(ModalDelete(i));
                  if (typeof m?.onClickCloseButton == "function") {
                    m.onClickCloseButton();
                  }
                },
              }}
              title={m.MTitle}
              size={media ? m.MSize : "100%"}
              centered={m.MCentered}
              closeOnClickOutside={m.closeOnClickOutside ?? false}
              overlayProps={m.Overlay}
              keepMounted={m.keepMounted ?? true}
              position={m?.position ?? 'right'}
              closeOnEscape={m.closeOnEscape ?? false}
              styles={m?.Styles}
              onClose={typeof m?.onclose == "function" ? m?.onclose : ""}
            // scrollAreaComponent={ScrollArea.Autosize}
            >
              {m.MBody(i)}
            </Modal>
          );
        }
      });
    }
    return modalShow;
  };
  {/* <Modal
            zIndex={205}
            opened={m.MAction}
            withCloseButton={m.MClose}
            fullScreen={true}//m.fullScreen
            // style={{overflowY:'unset !important'}} //Kishanbhai
            // styles={{content:{overflowY:"unset !important"}}}
            closeButtonProps={{
              onClick: () => {
                Store.dispatch(ModalDelete(i));
                if (typeof m?.onClickCloseButton == "function") {
                  m.onClickCloseButton();
                }
              },
            }}
            title={m.MTitle}
            size={m.MSize}
            centered={m.MCentered}
            closeOnClickOutside={m.closeOnClickOutside}
            overlayProps={m.Overlay}
            onClose={typeof m?.onclose == "function" ? m?.onclose : ""}
            // scrollAreaComponent={ScrollArea.Autosize}
          >
            {m.MBody(i)}
          </Modal> */}
  return (
    <>
      {/* <Modal
        zIndex={205}
        opened={modalAttr.MAction}
        withCloseButton={modalAttr.MClose}
        title={modalAttr.MTitle}
        size={modalAttr.MSize}
        centered={modalAttr.MCentered}
        closeOnClickOutside={modalAttr.closeOnClickOutside}
        overlayProps={modalAttr.Overlay}
        onClose={modalAttr.onclose}
      >
        {modalAttr.MBody()}
      </Modal> */}

      {ModalFunction()}
    </>
  );
};
