import { Alert, Button, Grid, Group, Text } from "@mantine/core";
import React, { useEffect, useRef, useState } from "react";
import PLDataGrid from "../../PlusComponent/PLDataGrid";
import PLComboBox from "../../PlusComponent/PLComboBox";
import { useDispatch, useSelector } from "react-redux";
import { ProductListAPi } from "../../utils/slices/ProductList";
import {
  GetAccountList,
  GetTMFormat,
  getGroupDetails,
  getAreaDetails,
  getCityDetails,
  getAccountDetails,
  getCategoryDetails,
  getProductDetails,
  getcWhereForMaster,
} from "../../utils/slices/AccountListSlice";
import { GetAccountListForPrint } from "../../utils/PrintSlice/PrintSlice";
import Store from "../../utils/store";
import GlobalClass from "../../utils/GlobalClass";
import { ModalDelete } from "../../utils/slices/ModalSlice";
import { IconInfoCircle } from "@tabler/icons-react";
import M01FormSlice, { GetM01List } from "../../utils/slices/M01FormSlice";

const MasterFilterForm = (props) => {
  console.log("props =>", props);

  const pid = props?.data?.BtnPnlObj?.id;

  const dispatch = useDispatch();

  const M01FormData = useSelector((state) => state?.M01FormSlice?.M01FormData);
  const ProductListData = useSelector(
    (store) => store?.ProductList?.ProductListData
  );
  const GetGeneral = useSelector((state) => state.Language.General);
  const resultNames = useSelector((state) => state?.PrintList?.PrintData[pid]);
  // const isLoading = useSelector((state) => state?.PrintList?.isLoading);
  const accountListData = useSelector(
    (state) => state?.AccountList?.AccountListData?.[`master_${pid}`]
  );
  // console.log("accountListData",accountListData);
  const isLoading = useSelector(
    (state) => state?.AccountList?.isLoading?.[`master_${pid}`]
  );

  const groupDetails = useSelector(
    (state) => state?.AccountList?.groupDetails?.[pid]
  );
  // const selectedOfGroupStore = useSelector((state)=>state?.AccountList?.groupDetails?.[pid]?.selectedOfGroup);
  // const isGroupSelectedStore = useSelector((state)=>state?.AccountList?.groupDetails?.[pid]?.isGroupSelected);
  // const groupData1Store = useSelector((state)=>state?.AccountList?.groupDetails?.[pid]?.groupData1);
  // const groupData2Store = useSelector((state)=>state?.AccountList?.groupDetails?.[pid]?.groupData2);
  const categoryDetails = useSelector(
    (state) => state?.AccountList?.categoryDetails?.[pid]
  );
  // const selectedOfCategoryStore = useSelector((state)=>state);
  // const isCategorySelectedStore = useSelector((state)=>state);
  // const categoryData1Store = useSelector((state)=>state);
  // const categoryData2Store = useSelector((state)=>state);
  const cityDetails = useSelector(
    (state) => state?.AccountList?.cityDetails?.[pid]
  );
  // const selectedOfCityStore = useSelector((state)=>state?.AccountList?.cityDetails?.[pid]?.selectedOfCity);
  // const isCitySelectedStore = useSelector((state)=>state?.AccountList?.cityDetails?.[pid]?.isCitySelected);
  // const cityData1Store = useSelector((state)=>state?.AccountList?.cityDetails?.[pid]?.cityData1);
  // const cityData2Store = useSelector((state)=>state?.AccountList?.cityDetails?.[pid]?.cityData2);
  const areaDetails = useSelector(
    (state) => state?.AccountList?.areaDetails?.[pid]
  );
  // const selectedOfAreaStore = useSelector((state)=>state?.AccountList?.areaDetails?.[pid]?.selectedOfArea);
  // const isAreaSelectedStore = useSelector((state)=>state?.AccountList?.areaDetails?.[pid]?.isAreaSelected);
  // const areaData1Store = useSelector((state)=>state?.AccountList?.areaDetails?.[pid]?.areaData1);
  // const areaData2Store = useSelector((state)=>state?.AccountList?.areaDetails?.[pid]?.areaData2);
  const accountDetails = useSelector(
    (state) => state?.AccountList?.accountDetails?.[pid]
  );
  // const isAccountSelectedStore = useSelector((state)=>state);
  // const accountSelectedStore = useSelector((state)=>state);
  // const accountData1Store = useSelector((state)=>state);
  const productDetails = useSelector(
    (state) => state?.AccountList?.productDetails?.[pid]
  );

  // console.log("groupDetails?.groupSelectedStore",groupDetails?.groupSelectedStore);
  // console.log("selectedOfGroupStore",selectedOfGroupStore);
  // console.log("isGroupSelectedStore",isGroupSelectedStore)

  // console.log("groupDetails",groupDetails)

  // const [masterFields, setMasterFields] = useState({
  //     ProductGroup:"A",
  //     ProductCategory:"A",
  //     Product:"A"
  // })
  const [groupSelected, setGroupSelected] = useState(false);
  const [categorySelected, setCategorySelected] = useState(false);
  const [productSelected, setProductSelected] = useState(false);
  const [groupData, setGroupData] = useState([]);
  const [groupData1, setGroupData1] = useState([]);
  const [groupData2, setGroupData2] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [categoryData1, setCategoryData1] = useState([]);
  const [categoryData2, setCategoryData2] = useState([]);
  const [isGroupSelected, setIsGroupSelected] = useState(false);
  const [isCategorySelected, setIsCategorySelected] = useState(false);
  const [productData, setProductData] = useState("");
  const [selectedProductNames, setSelectedProductNames] = useState([]);
  const [waitForFilter, setWaitForFilter] = useState(true);
  const [isProductSelected, setIsProductSelected] = useState(false);
  // const [isLoading1, setIsLoading1] = useState(false);
  const [selectedOfGroup, setSelectedOfGroup] = useState("A");
  const [selectedOfArea, setSelectedOfArea] = useState("A");
  const [selectedOfCity, setSelectedOfCity] = useState("A");
  const [selectedOfAccount, setSelectedOfAccount] = useState("A");
  const [selectedOfCategory, setSelectedOfCategory] = useState("A");
  const [selectedOfProduct, setSelectedOfProduct] = useState("A");
  const [isAreaSelected, setIsAreaSelected] = useState(false);
  const [isCitySelected, setIsCitySelected] = useState(false);

  const [isAccountSelected, setIsAccountSelected] = useState(false);
  const [areaSelected, setAreaSelected] = useState(false);
  const [citySelected, setCitySelected] = useState(false);
  const [accountSelected, setAccountSelected] = useState(false);
  const [areaData, setAreaData] = useState([]);
  const [areaData1, setAreaData1] = useState([]);
  const [areaData2, setAreaData2] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [cityData1, setCityData1] = useState([]);
  const [cityData2, setCityData2] = useState([]);
  const [accountData, setAccountData] = useState("");
  const [accountData1, setAccountData1] = useState("");
  const [accountData2, setAccountData2] = useState([]);
  const [productData1, setProductData1] = useState([]);
  const [productData2, setProductData2] = useState([]);

  const groupRef = useRef();
  const categoryRef = useRef();
  const produuctRef = useRef();
  const cityRef = useRef();
  const areaRef = useRef();
  const accountRef = useRef();

  useEffect(() => {
    if (groupDetails?.groupSelected) {
      setGroupSelected(groupDetails?.groupSelected);
    }
    if (groupDetails?.selectedOfGroup) {
      setSelectedOfGroup(groupDetails?.selectedOfGroup);
    }
    if (groupDetails?.isGroupSelected) {
      setIsGroupSelected(groupDetails?.isGroupSelected);
    }
    if (groupDetails?.groupData1) {
      setGroupData1(groupDetails?.groupData1);
    }
    if (groupDetails?.groupData2) {
      setGroupData2(groupDetails?.groupData2);
    }
  }, [groupDetails]);

  useEffect(() => {
    if (cityDetails?.citySelected) {
      setCitySelected(cityDetails?.citySelected);
    }
    if (cityDetails?.selectedOfCity) {
      setSelectedOfCity(cityDetails?.selectedOfCity);
    }
    if (cityDetails?.isCitySelected) {
      setIsCitySelected(cityDetails?.isCitySelected);
    }
    if (cityDetails?.cityData1) {
      setCityData1(cityDetails?.cityData1);
    }
    if (cityDetails?.cityData2) {
      setCityData2(cityDetails?.cityData2);
    }
  }, [cityDetails]);

  useEffect(() => {
    if (areaDetails?.areaSelected) {
      setAreaSelected(areaDetails?.areaSelected);
    }
    if (areaDetails?.selectedOfArea) {
      setSelectedOfArea(areaDetails?.selectedOfArea);
    }
    if (areaDetails?.isAreaSelected) {
      setIsAreaSelected(areaDetails?.isAreaSelected);
    }
    if (areaDetails?.areaData1) {
      setAreaData1(areaDetails?.areaData1);
    }
    if (areaDetails?.areaData2) {
      setAreaData2(areaDetails?.areaData2);
    }
  }, [areaDetails]);

  useEffect(() => {
    if (accountDetails?.accountSelected) {
      setAccountSelected(accountDetails?.accountSelected);
    }
    if (accountDetails?.selectedOfAccount) {
      setSelectedOfAccount(accountDetails?.selectedOfAccount);
    }
    if (accountDetails?.isAccountSelected) {
      setIsAccountSelected(accountDetails?.isAccountSelected);
    }
    if (accountDetails?.accountData1) {
      setAccountData1(accountDetails?.accountData1);
    }
    if (accountDetails?.accountData) {
      setAccountData(accountDetails?.accountData);
    }
  }, [accountDetails]);

  useEffect(() => {
    if (categoryDetails?.categorySelected) {
      setCategorySelected(categoryDetails?.categorySelected);
    }
    if (categoryDetails?.selectedOfCity) {
      setSelectedOfCategory(categoryDetails?.selectedOfCategory);
    }
    if (categoryDetails?.isCategorySelected) {
      setIsCategorySelected(categoryDetails?.isCategorySelected);
    }
    if (categoryDetails?.categoryData1) {
      setCategoryData1(categoryDetails?.categoryData1);
    }
    if (categoryDetails?.categoryData2) {
      setCategoryData2(categoryDetails?.categoryData2);
    }
  }, [categoryDetails]);

  useEffect(() => {
    if (productDetails?.productSelected) {
      setProductSelected(productDetails?.productSelected);
    }
    if (productDetails?.selectedOfProduct) {
      setSelectedOfProduct(productDetails?.selectedOfProduct);
    }
    if (productDetails?.isProductSelected) {
      setIsProductSelected(productDetails?.isProductSelected);
    }
    if (productDetails?.productData1) {
      setProductData1(productDetails?.productData1);
    }
    if (productDetails?.productData) {
      setProductData(productDetails?.productData);
    }
  }, [productDetails]);

  useEffect(() => {
    if (accountSelected || productSelected) {
      let newobj = { ...props?.data?.BtnPnlObj, id: `master_${pid}` };
      dispatch(
        GetAccountList({
          ...newobj,
          StrtDt: GetGeneral?.dYSDate,
          EndDt: GetGeneral?.dYEDate,
          cWhere: getcWhere(),
        })
      );
    }
  }, [accountSelected, productSelected]);

  useEffect(() => {
    setWaitForFilter(isLoading);
  }, [isLoading]);

  // useEffect(() => {
  //     Promise.resolve(
  //         dispatch(GetTMFormat({ ...props?.data?.BtnPnlObj, StrtDt: GetGeneral?.dYSDate, EndDt: GetGeneral?.dYEDate, })))
  //         .then(() => {
  //             dispatch(GetAccountListForPrint(props?.data?.BtnPnlObj));
  //         })
  //         .then(() => {
  //             if(props?.data?.cSTID == "T_M21"){
  //                 dispatch(ProductListAPi(props?.data?.BtnPnlObj))
  //             }else if(props?.data?.cSTID == "T_M01"){
  //                 dispatch(GetM01List({ OBJ: "A" }));
  //             }
  //         })

  //     // .then(() => {
  //     //     if (!isLoading) {
  //     //         dispatch(GetPrintEntData())
  //     //     }
  //     // });
  // }, []);

  useEffect(() => {
    // var newobj = { ...props?.data?.BtnPnlObj, id: `master_${pid}` }
    // Promise.resolve(
    //   dispatch(
    //     GetTMFormat({
    //       ...newobj,
    //       StrtDt: GetGeneral?.dYSDate,
    //       EndDt: GetGeneral?.dYEDate,
    //     })
    //   )
    // ).then(() => {
    // dispatch(GetAccountListForPrint(props?.data?.BtnPnlObj));
    if (pid == "T_M01") {
      dispatch(GetM01List({ OBJ: "A" }));
    } else if (pid == "T_M21") {
      dispatch(ProductListAPi(props?.data?.BtnPnlObj));
    }
    // })
  }, []);

  // useEffect(() => {
  //     if (isLoading == true) {
  //         setIsLoading1(false);
  //     }
  // }, [isLoading]);

  // useEffect(() => {
  // setGroupData(ProductListData?.P_M32GDT?.jData);
  // setCategoryData(ProductListData?.P_M32CDT?.jData);
  // setProductData(resultNames);
  // setSelectedProductNames(resultNames);
  // }, [ProductListData]);

  // useEffect(() => {
  //     if (productSelected == true) {
  //         let resData = [];

  //         Promise.resolve((resData = Store.getState()?.PrintList?.PrintData[pid]))
  //             .then(() => {

  //                 console.log("resData", resData);

  //                 if (isGroupSelected) {
  //                     let filteredArray = [];

  //                     for (let i = 0; i < groupData?.length; i++) {
  //                         for (let j = 0; j < resData?.length; j++) {
  //                             if (groupData[i]?.FIELD01 == resData[j]?.FIELD11) {
  //                                 filteredArray?.push(resData[j]);
  //                             }
  //                         }
  //                     }
  //                     resData = filteredArray;

  //                 }
  //                 if (isCategorySelected) {
  //                     let filteredArray = [];

  //                     for (let i = 0; i < categoryData?.length; i++) {
  //                         for (let j = 0; j < resData?.length; j++) {
  //                             if (categoryData[i]?.FIELD01 == resData[j]?.FIELD12) {
  //                                 filteredArray?.push(resData[j]);
  //                             }
  //                         }
  //                     }
  //                     resData = filteredArray;
  //                 }
  //                 setWaitForFilter(false);
  //                 setSelectedProductNames(
  //                     resData?.length == 0 ? isCategorySelected || isGroupSelected ? resData : resultNames : resData
  //                 );
  //             });
  //     }
  // }, [productSelected])

  const getcWhereForOk = () => {
    // console.log("getcWhereForOk")
    const formatInClause = (data) => {
      if (data) {
        return `IN ('${data.split(",").join("', '")}')`;
      }
      return "";
    };

    let str = "";
    str =
      pid == "T_M01"
        ? `M01.FIELD01 ${formatInClause(accountData1)}`
        : `M21.FIELD01 ${formatInClause(productData1)}`;

    return str;
  };

  const getcWhere = () => {
    // console.log("areaData1", areaData1);

    // console.log("getcWhere")
    // Helper function to convert a comma-separated string into the desired format
    const formatInClause = (data) => {
      if (data) {
        return `IN ('${data.split(",").join("', '")}')`;
      }
      return "";
    };
    // AND ${M01FormData?.P_M01I?.split("~C~")[2]}
    let str = "";
    str =
      pid == "T_M01"
        ? isGroupSelected && isAreaSelected && isCitySelected
          ? `M01.FIELD05 ${formatInClause(
              groupData1
            )} AND M01.FIELD18 ${formatInClause(
              areaData1
            )} AND M01.FIELD16 ${formatInClause(cityData1)} `
          : !isGroupSelected && isAreaSelected && isCitySelected
          ? `M01.FIELD18 ${formatInClause(
              areaData1
            )} AND M01.FIELD16 ${formatInClause(cityData1)} `
          : isGroupSelected && !isAreaSelected && isCitySelected
          ? `M01.FIELD05 ${formatInClause(
              groupData1
            )} AND M01.FIELD16 ${formatInClause(cityData1)} `
          : isGroupSelected && isAreaSelected && !isCitySelected
          ? `M01.FIELD05 ${formatInClause(
              groupData1
            )} AND M01.FIELD18 ${formatInClause(areaData1)} `
          : !isGroupSelected && !isAreaSelected && isCitySelected
          ? `M01.FIELD16 ${formatInClause(cityData1)} `
          : !isGroupSelected && isAreaSelected && !isCitySelected
          ? `M01.FIELD18 ${formatInClause(areaData1)} `
          : isGroupSelected && !isAreaSelected && !isCitySelected
          ? `M01.FIELD05 ${formatInClause(groupData1)} `
          : ``
        : isGroupSelected && isCategorySelected
        ? `M21.FIELD11 ${formatInClause(
            groupData1
          )} AND M21.FIELD12 ${formatInClause(categoryData1)} `
        : isGroupSelected && !isCategorySelected
        ? `M21.FIELD11 ${formatInClause(groupData1)}`
        : !isGroupSelected && isCategorySelected
        ? `M21.FIELD12 ${formatInClause(categoryData1)}`
        : ``;

    return str;
  };

  const handleInput = (e) => {
    let inputField;
    switch (e) {
      case "product":
        inputField = (
          <>
            <Grid.Col
              span={6}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Grid.Col span={4}>
                <Text style={{ fontSize: 12 }}>Group Name</Text>
              </Grid.Col>
              <Grid.Col span={8}>
                <PLComboBox
                  copno={"AS"}
                  value={selectedOfGroup}
                  dispexpr={"DisplayMember"}
                  valexpr={"ValueMember"}
                  setEdit={(e) => {
                    // console.log("masterFields?.ProductGroup", e);
                    setSelectedOfGroup(e?.value);
                    setIsGroupSelected(false);
                    e?.value == "S"
                      ? setGroupSelected(true)
                      : setGroupSelected(false);
                    if (e?.value == "S") {
                      groupRef.current.handlePopoverToggle();
                    }
                  }}
                />
              </Grid.Col>
            </Grid.Col>
            <Grid.Col
              span={6}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Grid.Col span={4}>
                <Text style={{ fontSize: 12 }}>Category Name</Text>
              </Grid.Col>
              <Grid.Col span={8}>
                <PLComboBox
                  copno={"AS"}
                  value={selectedOfCategory}
                  dispexpr={"DisplayMember"}
                  valexpr={"ValueMember"}
                  setEdit={(e) => {
                    // console.log("masterFields?.ProductCategory", e);
                    setSelectedOfCategory(e?.value);
                    setIsCategorySelected(false);
                    e?.value == "S"
                      ? setCategorySelected(true)
                      : setCategorySelected(false);
                    if (e?.value == "S") {
                      categoryRef?.current?.handlePopoverToggle();
                    }
                  }}
                />
              </Grid.Col>
            </Grid.Col>

            <Grid.Col
              span={6}
              style={{ display: "flex", alignItems: "center" }}
            >
              {groupSelected == true && (
                <Grid.Col style={{ display: "flex", flexDirection: "row" }}>
                  <Grid.Col span={4}>
                    <Text style={{ fontSize: 12 }}>Select Group Names</Text>
                  </Grid.Col>
                  <Grid.Col span={8}>
                    <PLDataGrid
                      ref={groupRef}
                      width={"500"}
                      isMulti={true}
                      TmData={ProductListData?.P_M32G}
                      // data={ProductListData?.P_M32GDT?.jData}
                      value={{ value: groupData1, label: groupData2 }}
                      setEdit={(e) => {
                        let group = e?.map((v) => v?.original);
                        setGroupData(group);
                        let val2 = [];
                        let val1 = [];
                        if (e?.length > 0) {
                          e?.map((m, i) => {
                            val2.push(m?.original?.FIELD02);
                            val1.push(m?.original?.FIELD01);
                          });
                          setSelectedOfProduct("A");
                          setProductData("");
                          setProductSelected(false);

                          setIsGroupSelected(true);
                        } else {
                          setIsGroupSelected(false);
                        }

                        setGroupData1(val1?.join(","));
                        setGroupData2(val2?.join(","));
                      }}
                    />
                  </Grid.Col>
                </Grid.Col>
              )}
            </Grid.Col>
            <Grid.Col
              span={6}
              style={{ display: "flex", alignItems: "center" }}
            >
              {categorySelected == true && (
                <Grid.Col style={{ display: "flex", flexDirection: "row" }}>
                  <Grid.Col span={4}>
                    <Text style={{ fontSize: 12 }}>Select Category Names</Text>
                  </Grid.Col>
                  <Grid.Col span={8}>
                    <PLDataGrid
                      ref={categoryRef}
                      width={"500"}
                      isMulti={true}
                      TmData={ProductListData?.P_M32C}
                      // data={ProductListData?.P_M32CDT?.jData}
                      value={{ value: categoryData1, label: categoryData2 }}
                      setEdit={(e) => {
                        let category = e?.map((v) => v?.original);
                        setCategoryData(category);
                        let val2 = [];
                        let val1 = [];
                        if (e?.length > 0) {
                          e?.map((m, i) => {
                            val2.push(m?.original?.FIELD02);
                            val1.push(m?.original?.FIELD01);
                          });

                          setSelectedOfProduct("A");
                          setProductData("");
                          setProductSelected(false);

                          setIsCategorySelected(true);
                        } else {
                          setIsCategorySelected(false);
                        }
                        setCategoryData1(val1?.join(","));
                        setCategoryData2(val2?.join(","));
                      }}
                    />
                  </Grid.Col>
                </Grid.Col>
              )}
            </Grid.Col>

            <Grid.Col
              span={6}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Grid.Col span={4}>
                <Text style={{ fontSize: 12 }}>Product</Text>
              </Grid.Col>
              <Grid.Col span={8}>
                <PLComboBox
                  copno={"AS"}
                  value={selectedOfProduct}
                  dispexpr={"DisplayMember"}
                  valexpr={"ValueMember"}
                  setEdit={(e) => {
                    // console.log("masterFields?.Product", e);
                    setSelectedOfProduct(e?.value);
                    setIsProductSelected(false);
                    if (e?.value == "S") {
                      setProductSelected(true);
                      produuctRef?.current?.handlePopoverToggle();
                    } else {
                      setProductSelected(false);
                    }
                  }}
                />
              </Grid.Col>
            </Grid.Col>

            <Grid.Col span={6}></Grid.Col>

            <Grid.Col
              span={6}
              style={{ display: "flex", alignItems: "center" }}
            >
              {productSelected == true ? (
                <Grid.Col style={{ display: "flex", flexDirection: "row" }}>
                  <Grid.Col span={4}>
                    <Text style={{ fontSize: 12 }}>Select Product Names</Text>
                  </Grid.Col>
                  <Grid.Col span={8}>
                    <PLDataGrid
                      ref={produuctRef}
                      pid={"P_M21"}
                      data={accountListData}
                      isLoading={waitForFilter}
                      // data={selectedAccNames}
                      // cWhere={getcWhere()}
                      value={productData}
                      TmData={"P_M21"}
                      width={"500"}
                      setEdit={(e) => {
                        let product = e
                          ?.map((v) => v?.original?.FIELD01)
                          ?.join();
                        setProductData(product);
                        let val2 = [];
                        let val1 = [];
                        if (e?.length > 0) {
                          e?.map((m, i) => {
                            val2.push(m?.original?.FIELD02);
                            val1.push(m?.original?.FIELD01);
                          });
                          setIsProductSelected(true);
                        } else {
                          setIsProductSelected(false);
                        }
                        setProductData1(val1?.join(","));
                        setProductData2(val2?.join(","));
                        setWaitForFilter(false);
                      }}
                      dropdownPosition={"bottom"}
                      isMulti={true}
                    />
                  </Grid.Col>
                </Grid.Col>
              ) : (
                ""
              )}
            </Grid.Col>
          </>
        );
        break;

      case "account":
        inputField = (
          <>
            <Grid.Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                padding: 0,
                maxHeight: "47px",
              }}
            >
              <Grid.Col
                span={6}
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: 0,
                }}
              >
                <Grid.Col style={{ display: "flex", flexDirection: "row" }}>
                  <Grid.Col span={3}>
                    <Text style={{ fontSize: 12 }}>Group Name</Text>
                  </Grid.Col>
                  <Grid.Col span={9}>
                    <PLComboBox
                      copno={"AS"}
                      value={selectedOfGroup}
                      dispexpr={"DisplayMember"}
                      valexpr={"ValueMember"}
                      setEdit={(e) => {
                        setSelectedOfGroup(e?.value);
                        setIsGroupSelected(false);
                        e?.value == "S"
                          ? setGroupSelected(true)
                          : setGroupSelected(false);
                        if (e?.value == "S") {
                          groupRef?.current?.handlePopoverToggle();
                        }
                      }}
                    />
                  </Grid.Col>
                </Grid.Col>
              </Grid.Col>
              <Grid.Col
                span={6}
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: 0,
                }}
              >
                <Grid.Col span={3}>
                  <Text style={{ fontSize: 12 }}>Area Name</Text>
                </Grid.Col>
                <Grid.Col span={9}>
                  <PLComboBox
                    copno={"AS"}
                    value={selectedOfArea}
                    dispexpr={"DisplayMember"}
                    valexpr={"ValueMember"}
                    setEdit={(e) => {
                      setSelectedOfArea(e?.value);
                      setIsAreaSelected(false);
                      e?.value == "S"
                        ? setAreaSelected(true)
                        : setAreaSelected(false);
                      if (e?.value == "S") {
                        areaRef?.current?.handlePopoverToggle();
                      }
                      // ).then(()=>{
                      //   dispatch(getAreaDetails({"id": pid,"selectedOfArea":e?.value,"areaSelected":e?.value=="S" ? true : false}));
                      // }).then((
                      //   dispatch(getIsAreaSelected({"id": pid,"isAreaSelected":false}))
                      // ))
                    }}
                  />
                </Grid.Col>
              </Grid.Col>
            </Grid.Col>
            <Grid.Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                padding: 0,
              }}
            >
              <Grid.Col
                span={6}
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: 0,
                }}
              >
                {groupSelected == true ? (
                  <Grid.Col style={{ display: "flex", flexDirection: "row" }}>
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>Select Group Names</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      {/* {console.log("groupData1Store",groupData1Store,"groupData2Store",groupData2Store)} */}
                      <PLDataGrid
                        ref={groupRef}
                        width={"500"}
                        value={{ value: groupData1, label: groupData2 }}
                        setEdit={(e) => {
                          let group = e?.map((v) => v?.original);
                          setGroupData(group);
                          let val2 = [];
                          let val1 = [];
                          if (e?.length > 0) {
                            e?.map((m, i) => {
                              val2.push(m?.original?.FIELD02);
                              val1.push(m?.original?.FIELD01);
                            });
                            setSelectedOfAccount("A");
                            setAccountData("");
                            setAccountSelected(false);
                            setIsGroupSelected(true);
                          } else {
                            setIsGroupSelected(false);
                          }

                          setGroupData1(val1?.join(","));
                          setGroupData2(val2?.join(","));
                        }}
                        isMulti={true}
                        type={"Group_Name"}
                        TmData={M01FormData?.P_M11E}
                        // data={M01FormData?.P_M11EDT?.jData}
                      />
                    </Grid.Col>
                  </Grid.Col>
                ) : (
                  ""
                )}
              </Grid.Col>
              <Grid.Col
                span={6}
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: 0,
                }}
              >
                {areaSelected == true ? (
                  <Grid.Col style={{ display: "flex", flexDirection: "row" }}>
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>Select Area Names</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLDataGrid
                        ref={areaRef}
                        width={"500"}
                        value={{ value: areaData1, label: areaData2 }}
                        setEdit={(e) => {
                          let area = e?.map((v) => v?.original);
                          setAreaData(area);
                          let val2 = [];
                          let val1 = [];
                          if (e?.length > 0) {
                            e?.map((m, i) => {
                              val2.push(m?.original?.FIELD02);
                              val1.push(m?.original?.FIELD01);
                            });
                            setSelectedOfAccount("A");
                            setAccountData("");
                            setAccountSelected(false);
                            setIsAreaSelected(true);
                          } else {
                            setIsAreaSelected(false);
                          }

                          setAreaData1(val1?.join(","));
                          setAreaData2(val2?.join(","));
                        }}
                        isMulti={true}
                        TmData={M01FormData?.P_M31A}
                        // data={M01FormData?.P_M31ADT?.jData}
                      />
                    </Grid.Col>
                  </Grid.Col>
                ) : (
                  ""
                )}
              </Grid.Col>
            </Grid.Col>
            <Grid.Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                padding: 0,
                maxHeight: "47px",
              }}
            >
              <Grid.Col
                span={6}
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: 0,
                }}
              >
                <Grid.Col span={3}>
                  <Text style={{ fontSize: 12 }}>City Name</Text>
                </Grid.Col>
                <Grid.Col span={9}>
                  <PLComboBox
                    copno={"AS"}
                    value={selectedOfCity}
                    dispexpr={"DisplayMember"}
                    valexpr={"ValueMember"}
                    setEdit={(e) => {
                      setSelectedOfCity(e?.value);
                      setIsCitySelected(false);
                      e?.value == "S"
                        ? setCitySelected(true)
                        : setCitySelected(false);
                      if (e?.value == "S") {
                        console.log("cityRef?.current", cityRef?.current);
                        cityRef?.current?.handlePopoverToggle();
                      }
                    }}
                  />
                </Grid.Col>
              </Grid.Col>
              <Grid.Col
                span={6}
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: 0,
                }}
              >
                <Grid.Col span={3}>
                  <Text style={{ fontSize: 12 }}>Account Name</Text>
                </Grid.Col>
                <Grid.Col span={9}>
                  <PLComboBox
                    copno={"AS"}
                    value={selectedOfAccount}
                    dispexpr={"DisplayMember"}
                    valexpr={"ValueMember"}
                    setEdit={(e) => {
                      setSelectedOfAccount(e?.value);
                      setIsAccountSelected(false);
                      if (e?.value == "S") {
                        setAccountSelected(true);
                        accountRef?.current?.handlePopoverToggle();
                      } else {
                        setAccountSelected(false);
                      }
                    }}
                  />
                </Grid.Col>
              </Grid.Col>
            </Grid.Col>
            <Grid.Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                padding: 0,
              }}
            >
              <Grid.Col
                span={6}
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: 0,
                }}
              >
                {citySelected == true ? (
                  <Grid.Col style={{ display: "flex", flexDirection: "row" }}>
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>Select City Names</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLDataGrid
                        ref={cityRef}
                        width={"500"}
                        value={{ value: cityData1, label: cityData2 }}
                        setEdit={(e) => {
                          let city = e?.map((v) => v?.original);
                          setCityData(city);
                          let val2 = [];
                          let val1 = [];
                          if (e?.length > 0) {
                            e?.map((m, i) => {
                              val2.push(m?.original?.FIELD02);
                              val1.push(m?.original?.FIELD01);
                            });

                            setIsCitySelected(true);
                            setSelectedOfAccount("A");
                            setAccountData("");
                            setAccountSelected(false);
                          } else {
                            setIsCitySelected(false);
                          }

                          setCityData1(val1?.join(","));
                          setCityData2(val2?.join(","));
                        }}
                        isMulti={true}
                        TmData={M01FormData?.P_M31T}
                        // data={M01FormData?.P_M31TDT?.jData}
                      />
                    </Grid.Col>
                  </Grid.Col>
                ) : (
                  ""
                )}
              </Grid.Col>
              <Grid.Col
                span={6}
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: 0,
                }}
              >
                {accountSelected == true ? (
                  <Grid.Col style={{ display: "flex", flexDirection: "row" }}>
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>Select Account Names</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLDataGrid
                        ref={accountRef}
                        data={accountListData}
                        pid={"P_M01"}
                        dispexpr="FIELD02"
                        // cWhere={getcWhere()}
                        value={accountData}
                        isLoading={waitForFilter}
                        TmData={"P_M01"}
                        width={"500"}
                        // masterFormFilter={pid}
                        setEdit={(e) => {
                          let account = e
                            ?.map((v) => v?.original?.FIELD01)
                            ?.join();

                          setAccountData(account);
                          let val2 = [];
                          let val1 = [];
                          if (e?.length > 0) {
                            e?.map((m, i) => {
                              val2.push(m?.original?.FIELD02);
                              val1.push(m?.original?.FIELD01);
                            });

                            setIsAccountSelected(true);
                          } else {
                            setIsAccountSelected(false);
                          }
                          setAccountData1(val1?.join(","));
                          setAccountData2(val2?.join(","));
                          setWaitForFilter(false);
                        }}
                        dropdownPosition={"bottom"}
                        isMulti={true}
                      />
                    </Grid.Col>
                  </Grid.Col>
                ) : (
                  ""
                )}
              </Grid.Col>
            </Grid.Col>
          </>
        );
        break;

      default:
        inputField = (
          <>
            <Grid.Col span={12}>
              <Alert
                variant="light"
                color="blue"
                title="Not Available"
                icon={<IconInfoCircle />}
              >
                Some Error!!!
              </Alert>
            </Grid.Col>
          </>
        );
        break;
    }

    return inputField;
  };

  const handleOk = async () => {
    // let resData = [];
    // setIsLoading1(true);
    // Promise.resolve(productSelected == false &&
    //     Promise.resolve((resData = Store.getState()?.PrintList?.PrintData[pid]))
    //         .then(() => {
    //             if (isGroupSelected) {
    //                 let filteredArray = [];

    //                 for (let i = 0; i < groupData?.length; i++) {
    //                     for (let j = 0; j < resData?.length; j++) {
    //                         if (groupData[i]?.FIELD01 == resData[j]?.FIELD11) {
    //                             filteredArray?.push(resData[j]);
    //                         }
    //                     }
    //                 }
    //                 resData = filteredArray;

    //             }
    //             if (isCategorySelected) {
    //                 let filteredArray = [];

    //                 for (let i = 0; i < categoryData?.length; i++) {
    //                     for (let j = 0; j < resData?.length; j++) {
    //                         if (categoryData[i]?.FIELD01 == resData[j]?.FIELD12) {
    //                             filteredArray?.push(resData[j]);
    //                         }
    //                     }
    //                 }
    //                 resData = filteredArray;
    //             }
    //             setSelectedProductNames(
    //                 resData?.length == 0
    //                     ? isCategorySelected ||
    //                         isGroupSelected
    //                         ? resData
    //                         : resultNames
    //                     : resData
    //             );
    //         })
    // )
    Promise.resolve(
      dispatch(
        getGroupDetails({
          id: pid,
          selectedOfGroup: selectedOfGroup,
          groupSelected: groupSelected,
          isGroupSelected: isGroupSelected,
          groupData1: groupData1,
          groupData2: groupData2,
        })
      )
    )
      .then(() => {
        pid == "T_M01" &&
          dispatch(
            getAreaDetails({
              id: pid,
              selectedOfArea: selectedOfArea,
              areaSelected: areaSelected,
              isAreaSelected: isAreaSelected,
              areaData1: areaData1,
              areaData2: areaData2,
            })
          );
      })
      .then(() => {
        pid == "T_M01" &&
          dispatch(
            getCityDetails({
              id: pid,
              selectedOfCity: selectedOfCity,
              citySelected: citySelected,
              isCitySelected: isCitySelected,
              cityData1: cityData1,
              cityData2: cityData2,
            })
          );
      })
      .then(() => {
        pid == "T_M01" &&
          dispatch(
            getAccountDetails({
              id: pid,
              selectedOfAccount: selectedOfAccount,
              accountSelected: accountSelected,
              isAccountSelected: isAccountSelected,
              accountData1: accountData1,
              accountData: accountData,
            })
          );
      })
      .then(() => {
        pid == "T_M21" &&
          dispatch(
            getCategoryDetails({
              id: pid,
              selectedOfCategory: selectedOfCategory,
              categorySelected: categorySelected,
              isCategorySelected: isCategorySelected,
              categoryData1: categoryData1,
              categoryData2: categoryData2,
            })
          );
      })
      .then(() => {
        pid == "T_M21" &&
          dispatch(
            getProductDetails({
              id: pid,
              selectedOfProduct: selectedOfProduct,
              productSelected: productSelected,
              isProductSelected: isProductSelected,
              productData1: productData1,
              productData: productData,
            })
          );
      })

      // dispatch(getIsGroupSelected({"id": pid,"isGroupSelected":isGroupSelected}))

      // dispatch(getGroupData({"id":pid,"groupData1":groupData1,"groupData2":groupData2}))

      // dispatch(getGroupDetails({"id": pid,"selectedOfGroup":selectedOfGroup,"groupSelected":groupSelected}));

      // dispatch(getIsGroupSelected({"id": pid,"isGroupSelected":isGroupSelected}))

      // dispatch(getGroupData({"id":pid,"groupData1":groupData1,"groupData2":groupData2}))

      // dispatch(getGroupDetails({"id": pid,"selectedOfGroup":selectedOfGroup,"groupSelected":groupSelected}));

      // dispatch(getIsGroupSelected({"id": pid,"isGroupSelected":isGroupSelected}))

      // dispatch(getGroupData({"id":pid,"groupData1":groupData1,"groupData2":groupData2}))

      .then(() => {
        dispatch(ModalDelete(props?.index));
      })
      .then(() => {
        dispatch(
          getcWhereForMaster({
            id: pid,
            cWhere:
              isAccountSelected || isProductSelected
                ? getcWhereForOk()
                : getcWhere(),
          })
        );
      })
      .then(() => {
        var newobj = { ...props?.data?.BtnPnlObj };
        dispatch(
          GetAccountList({
            ...newobj,
            StrtDt: GetGeneral?.dYSDate,
            EndDt: GetGeneral?.dYEDate,
          })
        );
      });
    // dispatch(ModalDelete(props?.index))

    // const productArray = isProductSelected ? productData : selectedProductNames;

    // const field01Array = productArray.map(item => item.FIELD01);

    // const queryParts = field01Array.map(field01 => {
    //     return `M21.FIELD01 LIKE '%${field01}%'`;
    // });

    // const cWhereQuery = queryParts.join(' OR ');

    // console.log("queryParts",queryParts);

    // dispatch(GetAccountList({ ...props?.data?.BtnPnlObj, cWhere : cWhereQuery }))
  };

  return (
    <>
      {
        <Grid gutter={4}>
          <Grid.Col span={12} style={{ display: "flex", flexWrap: "wrap" }}>
            {handleInput(
              props?.data?.cSTID == "T_M21"
                ? "product"
                : "T_M01"
                ? "account"
                : ""
            )}
          </Grid.Col>
          <Grid.Col span={12}>
            <Group spacing={"xs"} position="center">
              <Button
                style={{ height: "30px", width: "100px" }}
                size="sm"
                onClick={handleOk}
              >
                Ok
              </Button>
            </Group>
          </Grid.Col>
        </Grid>
      }
    </>
  );
};

export default MasterFilterForm;
