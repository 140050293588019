import { ActionIcon, Button, Divider, Grid, Modal, Text, TextInput } from '@mantine/core'
import React, { useEffect, useRef, useState } from 'react'
import PLTextarea from '../../PlusComponent/PLTextarea'
import PLDataGrid from '../../PlusComponent/PLDataGrid'
import PLDateBox from '../../PlusComponent/PLDateBox'
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table'
import { useDispatch, useSelector } from 'react-redux'
import { GetFollowUpData } from '../../utils/TransactionSlices/TransactionLeadEntrySlice'
import GlobalClass from '../../utils/GlobalClass'
import { createColumnHelper } from '@tanstack/react-table'
import { DataSaveLoading } from '../../utils/slices/DataSaveLoadingSlice'
import gensetting from '../../utils/gensetting'
import { GetAccountList } from '../../utils/slices/AccountListSlice'
import Store from '../../utils/store'
import useWindowDimensions from '../../utils/UseWindowDimensions'
import { TimeInput } from '@mantine/dates'
import { IconClock } from '@tabler/icons-react'
import { ModalDelete } from '../../utils/slices/ModalSlice'

const FollowUpEntry = (props) => {
    console.log("props followup", props)
    const { obj } = props
    const ref = useRef()
    const { height, width } = useWindowDimensions();
    const { isLoading, hasError, ErrorMsg } = useSelector(
        (state) => state.transactionLeadEntry
    );
    const SaveLoading = useSelector(
        (state) => state?.DataSaveLoadingSlice?.SaveLoading
    );
    const followUpEntry = useSelector((state) => state.transactionLeadEntry?.followUpData)
    console.log("followUpEntry", followUpEntry)
    const T07 = useSelector((state) => state.transactionLeadEntry?.followUpData?.T07)
    console.log("T07=>", T07)
    let dra = {
        title: "",
        size: "md",
        body: "",
        open: false,
        onClickCloseBtn: null
    }
    const [DrawerObj, setDrawerObj] = useState(dra);
    const [columns, setColumns] = useState([])
    const [T07Data, setT07Data] = useState([])
    const [T07Obj, setT07Obj] = useState({
        FIELD01: "",
        FIELD02: "",
        FIELD03: "",
        FIELD04: "",
        FIELD05: ""
    })
    const [statusUpdate, setStatusUpdate] = useState(false)
    const [statusValue, setStatusValue] = useState({
        value: "",
        label: ""
    })

    const dispatch = useDispatch()
    const columnHelper = createColumnHelper()

    useEffect(() => {
        if (obj) {
            dispatch(GetFollowUpData(obj))
        }
    }, [obj])

    useEffect(() => {
        if (hasError && !isLoading) {
            GlobalClass.Notify("error", "Error: " + ErrorMsg.code, ErrorMsg.message);
        }
    }, [hasError]);

    useEffect(() => {
        let cols = [];
        cols.push(
            columnHelper.accessor("FIELD02", {
                header: "Date",
                size: 50,
                Cell: ({ cell, row }) => {
                    let dateString = T07Data[row?.id]?.FIELD02
                    if (dateString) {
                        let year = dateString.slice(0, 4);
                        let month = dateString.slice(4, 6);
                        let day = dateString.slice(6, 8);

                        let formattedDate = `${day.padStart(2, '0')}/${month.padStart(2, '0')}/${year}`;
                        // console.log("formateDate", formattedDate)
                        return formattedDate
                    }
                }
            })
        )
        cols.push(
            columnHelper.accessor("FIELD05",{
                header:"Time",
                size:12
                // cell
            })
        )
        cols.push(
            columnHelper.accessor("FIELD04", {
                header: "History",
                size:500,
                Cell: ({ cell, row }) => {
                    let val = cell.getValue()
                    return val
                }
            })
        )
        setColumns(cols)
    }, [T07Data, T07])

    useEffect(() => {
        if (T07) {
            let filterData = T07?.filter((v) => v.FIELD04 != null)
            setT07Data(filterData)
        }
    }, [T07])

    useEffect(() => {
        if (obj?.DataGridRow?.FIELD60) {
            setStatusValue({ ...statusValue, value: obj?.DataGridRow?.FIELD60, label: obj?.DataGridRow?.SAFIELD02 })
        }
    }, [obj?.DataGridRow])

    const handleSubmit = () => {
        dispatch(DataSaveLoading({[obj?.cSTID]:true}));
        GlobalClass.Notify(
            "info",
            "Adding",
            "Please wait while we process your data"
        );
        let filterData = T07?.filter((v) => v.FIELD04 !== null)
        console.log("filterData", filterData)

        const currentDate = new Date();

        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const day = currentDate.getDate().toString().padStart(2, '0');

        const formattedDate = `${year}${month}${day}`;
        var jData = { T07: [...filterData, { ...T07Obj, FIELD02: formattedDate }] }
        console.log("jData", jData)
        var data = {
            CorpID: gensetting.getcorpid(),
            cUser: gensetting.getcuser(),
            cPass: gensetting.getcpass(),
            CmpNo: gensetting.getcmpno(),
            cAction: "A",
            cCode: obj.id,
            cSData: JSON.stringify(jData),
            YrNo:localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
        };
        let param = JSON.stringify({
            CorpID: gensetting.getcorpid(),
            cUser: gensetting.getcuser(),
            cPass: gensetting.getcpass(),
            CmpNo: gensetting.getcmpno(),
            cAction: "A",
            cCode: obj.id,
        });
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        };
        fetch(
            GlobalClass.ApiUrl + GlobalClass.PostFollowupData + "?pa=" + param,
            requestOptions
        )
            .then((response) => response.json())
            .then((data) => {
                // console.log('api data', data);
                // console.log('api data status', data?.status);
                if (data.status == "SUCCESS") {
                    Promise.resolve(dispatch(DataSaveLoading({[obj?.cSTID]:false})))
                        .then(() =>
                            GlobalClass.Notify(
                                "success",
                                "Added",
                                "Added successfully"
                            )
                        )
                        .then(() => {
                            dispatch(GetFollowUpData(obj))
                        })
                        .then(() => {
                            setT07Obj({
                                FIELD01: "",
                                FIELD02: "",
                                FIELD03: "",
                                FIELD04: "",
                                FIELD05: ""
                            })
                        })
                } else {
                    Promise.resolve(dispatch(DataSaveLoading({[obj?.cSTID]:false}))).then(() =>
                        console.log('test'),
                        GlobalClass.Notify("error", data?.status, data?.message)
                    );
                }
            })
            .catch((e) => {
                Promise.resolve(
                    GlobalClass.Notify("error", "Error", `${e.message}`)
                ).then(() => dispatch(DataSaveLoading({[obj?.cSTID]:false})));
            });

        if (statusUpdate == true) {
            var data = {
                CorpID: gensetting.getcorpid(),
                cUser: gensetting.getcuser(),
                cPass: gensetting.getcpass(),
                CmpNo: gensetting.getcmpno(),
                cAction: "S",
                cCode: obj?.id,
                cOPara: statusValue?.value,
                YrNo:localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
            };
            let param = JSON.stringify({
                CorpID: gensetting.getcorpid(),
                cUser: gensetting.getcuser(),
                cPass: gensetting.getcpass(),
                CmpNo: gensetting.getcmpno(),
                cAction: "S",
                cCode: obj?.id,
                cOPara: statusValue?.value,
            });
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(data),
            };
            fetch(
                GlobalClass.ApiUrl + GlobalClass.PostLeadData + "?pa=" + param,
                requestOptions
            )
                .then((response) => response.json())
                .then((data) => {
                    // console.log('api data', data);
                    // console.log('api data status', data?.status);
                    if (data.status == "SUCCESS") {
                        Promise.resolve(
                            GlobalClass.Notify(
                                "success",
                                "successfull"
                            ))
                            .then(() => {
                                let PaginationObj = Store.getState().DataSaveLoadingSlice.PaginationObj;
                                Promise.resolve(Store.dispatch(DataSaveLoading({[obj?.cSTID]:false})))
                                    .then(() => Store.dispatch(ModalDelete(props.index)))
                                    .then(() => {
                                        Store.dispatch(
                                            GetAccountList({
                                                id: "20600002",
                                                name: "Lead Entry",
                                                p0: "MT",
                                                p1: "TXNVOULISTLD",
                                                p2: "TFORM0000001",
                                                p3: "",
                                                type: "A",
                                                pagination: PaginationObj["20600002"],
                                            })
                                        );
                                    })
                            })
                    } else {
                        Promise.resolve(Store.dispatch(DataSaveLoading({[obj?.cSTID]:false}))).then(() =>
                            console.log('test'),
                            GlobalClass.Notify("error", data?.status, data?.message)
                        );
                    }
                })
                .catch((e) => {
                    Promise.resolve(
                        GlobalClass.Notify("error", "Error", `${e.message}`)
                    ).then(() => Store.dispatch(DataSaveLoading({[obj?.cSTID]:false})));
                });
        }
    }

    const table = useMantineReactTable({
        data: T07Data ?? [],
        columns,
        enableTopToolbar: false,
        mantineTableProps: { withColumnBorders: true },
        mantineTableContainerProps: {
            sx: { height: height * 0.2 }
        },
        // defaultColumn: { // Deval
        //     minSize: 0, //allow columns to get smaller than default
        //     maxSize: 500, //allow columns to get larger than default
        //     size: 1, //make columns wider by default
        //   },
        enableHiding: false,
        positionActionsColumn: "last",
        positionToolbarAlertBanner: "none",
        paginationDisplayMode: "default",
        enablePagination: true,
        enableStickyHeader: true,
        enableStickyFooter: true,
        enableFilters: false,
        enableSorting: false,
        enableColumnActions: false,
        initialState: {
            density: "0px",
        },
        state: {
            density: "0px"
        },
    })

    return (
        <>
            {
                hasError ? <>
                    <Text>{ErrorMsg?.message}</Text>
                    <Button onClick={() => {
                        if (obj) {
                            dispatch(GetFollowUpData(obj))
                        }
                    }}>Retry</Button>
                </> :
                    SaveLoading[obj?.cSTID] ? GlobalClass.RenderLoader("dots") :
                        <>
                            {isLoading ? GlobalClass.RenderLoader("dots") :
                                <>
                                    <Modal
                                        opened={DrawerObj?.open}
                                        // onClose={close}
                                        withCloseButton={true}

                                        fullScreen={false}//
                                        // overlayProps={{ opacity: 0.5, blur: 4 }}
                                        closeButtonProps={{
                                            onClick: (e) => {
                                                if (typeof DrawerObj?.onClickCloseButton == "function") {
                                                    DrawerObj?.onClickCloseButton();
                                                }
                                                else {
                                                    setDrawerObj(dra);
                                                }
                                            },
                                        }}
                                        title={DrawerObj?.title}
                                        size={DrawerObj?.size}
                                        // centered={.Centered}
                                        closeOnClickOutside={true}
                                        // overlayProps={m.Overlay}
                                        position={DrawerObj?.position ?? 'bottom'}
                                        onClose={typeof DrawerObj?.onclose == "function" ? DrawerObj?.onclose : () => { setDrawerObj(dra); }}
                                    // scrollAreaComponent={ScrollArea.Autosize}
                                    >
                                        {DrawerObj.body}
                                    </Modal>
                                    <Grid gutter={4}>
                                        <Grid.Col span={12}>
                                            <MantineReactTable table={table} />
                                        </Grid.Col>
                                        <Grid.Col span={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <Grid.Col span={2}>
                                                <Text size={12}>Remark:</Text>
                                            </Grid.Col>
                                            <Grid.Col span={10}>
                                                <PLTextarea
                                                    minRows={2}
                                                    value={T07Obj?.FIELD04}
                                                    onChange={(e) => {
                                                        // //console.log(e)
                                                        setT07Obj({ ...T07Obj, FIELD04: e.target.value });
                                                    }}
                                                />
                                            </Grid.Col>
                                        </Grid.Col>
                                        <Grid.Col span={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <Grid.Col span={2}>
                                                <Text size={12}>Status :</Text>
                                            </Grid.Col>
                                            <Grid.Col span={10}>
                                                <PLDataGrid
                                                    TmData="P_M15"
                                                    value={{ value: statusValue?.value, label: statusValue?.label }}
                                                    setEdit={(e) => {
                                                        console.log("Status value", e)
                                                        setStatusValue({ ...statusValue, value: e?.FIELD01, label: e?.FIELD02 })
                                                        setStatusUpdate(true)
                                                    }}
                                                />
                                            </Grid.Col>
                                        </Grid.Col>
                                        <Grid.Col span={12}>
                                            <Divider
                                                my="xs"
                                                label="FollowUp"
                                                labelPosition="center"
                                                labelProps={{ component: "strong" }}
                                            />
                                        </Grid.Col>
                                        <Grid.Col span={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <Grid.Col span={2}>
                                                <Text size={12}>Date:</Text>
                                            </Grid.Col>
                                            <Grid.Col span={4}>
                                                <PLDateBox
                                                    dispformat="DD/MM/YYYY"
                                                    defaultval={T07Obj?.FIELD03}
                                                    setEdit={(e) => {
                                                        console.log("DATEPICKER=>", e)
                                                        let year = e.toLocaleString("en-IN", {
                                                            year: "numeric",
                                                        });
                                                        let month = e.toLocaleString("en-IN", {
                                                            month: "2-digit",
                                                        });
                                                        let day = e.toLocaleString("en-IN", {
                                                            day: "2-digit",
                                                        });

                                                        let StringDate = year + month + day;
                                                        if (StringDate?.length == 8) {
                                                            setT07Obj({ ...T07Obj, FIELD03: StringDate });
                                                        }
                                                    }}
                                                />
                                            </Grid.Col>
                                            <Grid.Col span={2}>
                                                <Text size={12} align='center'>Time:</Text>
                                            </Grid.Col>
                                            <Grid.Col span={4}>
                                                <TimeInput
                                                    //   icon={<IconClock size="1rem" stroke={1.5} />} 
                                                    maw={400}
                                                    mx="auto"
                                                    ref={ref}
                                                    rightSection={
                                                        <ActionIcon onClick={() => ref.current.showPicker()}>
                                                          <IconClock size="1rem" stroke={1.5} />
                                                        </ActionIcon>
                                                      }
                                                    onChange={(e) => {
                                                        console.log("time", e)
                                                        setT07Obj({ ...T07Obj, FIELD05: (e.target.value).trim() })
                                                    }}
                                                />
                                            </Grid.Col>
                                        </Grid.Col>
                                        <Grid.Col span={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <Button
                                                onClick={handleSubmit}
                                            >OK</Button>
                                        </Grid.Col>
                                    </Grid>
                                </>
                            }
                        </>

            }
        </>
    )
}

export default FollowUpEntry