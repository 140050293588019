import { useMantineReactTable } from 'mantine-react-table'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { TransactionApi } from '../../../utils/TransactionSlices/Transaction';
import { createColumnHelper } from "@tanstack/react-table";
import { GenererateSMS } from '../../../utils/TransactionSlices/MessageBtnSlice';

const GenerateBtn = (props) => {

// console.log("props in gen==>",props?.obj);

const dispatch = useDispatch();

const [columns,setColumns]=useState([]);
const generateData = useSelector((state)=>state?.MessageBtnData?.generateData?.sms);
console.log("generateData",generateData?.props?.obj?.page);

// const T41 = useSelector((state) => state.Transaction?.TransactionData?.T41);
// console.log("T41",T41);

// const [columns,setColumns]=useState([]);

useEffect(()=>{
    dispatch(GenererateSMS({ ...props?.obj}));
},[])


const columnHelper = createColumnHelper();

useEffect(() => {
  let column = [];
  column.push(
    columnHelper.accessor("FIELD02", {
      header: "Party Name",
      enableColumnFilter: false,
      enableSorting: false,
      size: "300",
    })
  );
  column.push(
    columnHelper.accessor("FIELD11", {
      header: "Mobile No",
      enableColumnFilter: false,
      enableSorting: false,
      size: "300",
    })
  );
  column.push(
    columnHelper.accessor("FIELD15", {
      header: "Message",
      enableColumnFilter: false,
      enableSorting: false,
      size: "200",
    })
  );

  setColumns(column);
}, [generateData]);




    // const table = useMantineReactTable({
    //       data:data ?? [],
    //       columns:columns ?? [],

    // })



  return (
    <div>GenerateBtn</div>
  )
}

export default GenerateBtn