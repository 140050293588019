import { Button, Card, Grid, Paper, Text } from "@mantine/core";
import React, { useEffect, useRef, useState } from "react";
import PLTextBox from "../../PlusComponent/PLTextBox";
import PLNumberBox from "../../PlusComponent/PLNumberBox";
import { useDispatch, useSelector } from "react-redux";
import { GetM32List } from "../../utils/slices/M32LocationFormSlice";
import GlobalClass from "../../utils/GlobalClass";
import gensetting from "../../utils/gensetting";
import { GetAccountList } from "../../utils/slices/AccountListSlice";
import { ModalDelete } from "../../utils/slices/ModalSlice";
import Store from "../../utils/store";
import { DataSaveLoading } from "../../utils/slices/DataSaveLoadingSlice";

const LocationListForm = (props) => {
  console.log('LocationListForm props=>', props)
  const { obj } = props;
  const M32LocationFormData = useSelector(
    (state) => state.M32LocationForm.M32LocationFormData
  );
  const { isLoading, hasError, ErrorMsg } = useSelector(
    (state) => state.M32LocationForm
  );
  const SaveLoading = useSelector(
    (state) => state?.DataSaveLoadingSlice?.SaveLoading
  );
  const dispatch = useDispatch();

  const [locData, setLocData] = useState({});
  const formRef = useRef();

  //   useEffect(() => {
  // console.log('locData', locData)
  // console.log('M32LocationFormData', M32LocationFormData)
  //   },[locData, M32LocationFormData])

  useEffect(() => {
    if (hasError && !isLoading) {
      GlobalClass.Notify("error", "Error: " + ErrorMsg.code, ErrorMsg.message);
    }
  }, [hasError]);

  useEffect(() => {
    if (obj.p0 === "E") {
      dispatch(GetM32List({ action: obj.p0, code: obj.id }));
    } else {
      dispatch(GetM32List());
    }
  }, [obj]);

  useEffect(() => {
    if (M32LocationFormData) {
      setLocData({ ...M32LocationFormData, FIELD10: "" });
    }
  }, [M32LocationFormData]);

  const handleSubmit = (e) => {
    // e.preventDefault();
    // if (obj.p0 == "E" && ((JSON.stringify(locData) && JSON.stringify(locData.FIELD10 == "")) === (JSON.stringify(M32LocationFormData) && JSON.stringify(M32LocationFormData.FIELD10 == "L")))) {
    //   Promise.resolve(dispatch(DataSaveLoading(false))).then(() => dispatch(ModalDelete(props.index)));
    //   return;
    // }
    dispatch(DataSaveLoading({[obj?.cSTID]:true}));
    GlobalClass.Notify(
      "info",
      obj.p0 == "A" ? "Adding" : "Editing",
      "Please wait while we process your data"
    );
    let jData = { ...locData, FIELD10: "L" };
    var data = {
      CorpID: gensetting.getcorpid(),
      cUser: gensetting.getcuser(),
      cPass: gensetting.getcpass(),
      CmpNo: gensetting.getcmpno(),
      cAction: "A",
      cCode: "",
      cSData: JSON.stringify(jData),
      YrNo:localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
    };
    let param = JSON.stringify({
      CorpID: gensetting.getcorpid(),
      cUser: gensetting.getcuser(),
      cPass: gensetting.getcpass(),
      CmpNo: gensetting.getcmpno(),
      cAction: "A",
      cCode: "",
    });
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    fetch(
      GlobalClass.ApiUrl + GlobalClass.PostM32Ent + "?pa=" + param,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        // console.log('api data', data);
        // console.log('api data status', data?.status);
        if (data.status === "SUCCESS") {
          let PaginationObj =
            Store.getState().DataSaveLoadingSlice.PaginationObj;
          Promise.resolve(dispatch(DataSaveLoading({[obj?.cSTID]:false})))
            // .then(() => dispatch(ModalDelete(props.index)))
            .then(() => {
              if (obj.p0 == "A") {
                // console.log("LocationListProps=>", props);
                if (obj?.cSTID == "P_M32L") {
                  dispatch(ModalDelete(props.index))
                } else {
                  dispatch(GetM32List())
                }
              } else {
                dispatch(ModalDelete(props.index))
              }
            })
            .then(() => {
              // dispatch(
              //   GetAccountList({
              //     id: "01310123",
              //     name: "Location List",
              //     p0: "MT",
              //     p1: "T_M32L",
              //     p2: "",
              //     p3: "",
              //     type: "A",
              //     pagination: PaginationObj["01310123"],
              //   })
              // )
              dispatch(GetAccountList({ ...obj?.BtnPnlObj, pagination: PaginationObj[obj?.cSTID] }))
            })
            .then(() =>
              GlobalClass.Notify(
                "success",
                obj.p0 == "A" ? "Added" : "Edited",
                obj.p0 == "A" ? "Added successfully" : "Edited successfully"
              )
            );
        } else {
          dispatch(DataSaveLoading({[obj?.cSTID]:false}))
          GlobalClass.Notify(
            "error",
            data?.status,
            data?.message
          )
        }
      })
      .catch((e) => {
        Promise.resolve(
          GlobalClass.Notify(
            "error",
            "Error",
            `${e?.message}`
          )).then(() => dispatch(DataSaveLoading({[obj?.cSTID]:false})));
      });
  };

  useEffect(() => {

    const formElement = formRef.current;
    if (formElement) {
      console.log('formelement run')
      formElement.addEventListener('keypress', (e) => GlobalClass.formNextInput(e, formRef));
    }
    return () => {
      if (formElement) {
        console.log('formelement run')
        formElement.removeEventListener('keypress', (e) => GlobalClass.formNextInput(e, formRef));
      }
    };
  }, [formRef]);

  return (
    <>
      {
        hasError ? <>
          <Text>{ErrorMsg?.message}</Text>
          <Button onClick={() => {
            if (obj && obj.id != "") {
              if (obj.p0 === "E") {
                dispatch(GetM32List({ action: obj.p0, code: obj.id }));
              } else {
                dispatch(GetM32List());
              }
            }
          }}>Retry</Button>
        </> :
          SaveLoading[obj?.cSTID] == true ? (
            GlobalClass.RenderLoader("dots")
          ) : (
            <>
              {isLoading
                ? GlobalClass.RenderLoader("dots")
                : M32LocationFormData && (
                  <div ref={formRef}>
                    <Paper shadow="md" radius="md" p="xs" withBorder>
                      <Grid gutter={4}>
                        <Grid.Col
                          span={12}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            padding: 0,
                          }}
                        >
                          <Grid.Col span={2}>
                            <Text style={{ fontSize: 12 }}>Location</Text>
                          </Grid.Col>
                          <Grid.Col span={10}>
                            <PLTextBox
                              onKeyDown={(e) => {
                                GlobalClass.formNextInput(e, formRef);
                              }}
                              autoFocus={true}
                              setEdit={(e) => {
                                setLocData({ ...locData, FIELD02: e.target.value });
                              }}
                              value={locData.FIELD02}
                            />
                          </Grid.Col>
                        </Grid.Col>
                        <Grid.Col
                          span={12}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            padding: 0,
                          }}
                        >
                          <Grid.Col span={2}>
                            <Text style={{ fontSize: 12 }}>Address</Text>
                          </Grid.Col>
                          <Grid.Col span={10}>
                            <div style={{ paddingBottom: ".50rem" }}>
                              <PLTextBox
                                onKeyDown={(e) => {
                                  GlobalClass.formNextInput(e, formRef);
                                }}
                                setEdit={(e) => {
                                  setLocData({
                                    ...locData,
                                    FIELD11: e.target.value,
                                  });
                                }}
                                value={locData.FIELD11}
                              />
                            </div>
                            <div style={{ paddingBottom: ".50rem" }}>
                              <PLTextBox
                                onKeyDown={(e) => {
                                  GlobalClass.formNextInput(e, formRef);
                                }}
                                setEdit={(e) => {
                                  setLocData({
                                    ...locData,
                                    FIELD12: e.target.value,
                                  });
                                }}
                                value={locData.FIELD12}
                              />
                            </div>
                            <div style={{ paddingBottom: ".50rem" }}>
                              <PLTextBox
                                onKeyDown={(e) => {
                                  GlobalClass.formNextInput(e, formRef);
                                }}
                                setEdit={(e) => {
                                  setLocData({
                                    ...locData,
                                    FIELD13: e.target.value,
                                  });
                                }}
                                value={locData.FIELD13}
                              />
                            </div>
                          </Grid.Col>
                        </Grid.Col>
                        <Grid.Col
                          span={12}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            padding: 0,
                          }}
                        >
                          <Grid.Col span={2}>
                            <Text style={{ fontSize: 12 }}>City</Text>
                          </Grid.Col>
                          <Grid.Col span={10}>
                            <PLTextBox
                              onKeyDown={(e) => {
                                GlobalClass.formNextInput(e, formRef);
                              }}
                              setEdit={(e) =>
                                setLocData({ ...locData, FIELD14: e.target.value })
                              }
                              value={locData.FIELD14}
                            />
                          </Grid.Col>
                        </Grid.Col>
                        <Grid.Col
                          span={12}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            padding: 0,
                          }}
                        >
                          <Grid.Col span={2}>
                            <Text style={{ fontSize: 12 }}>PinCode</Text>
                          </Grid.Col>
                          <Grid.Col span={10}>
                            <PLNumberBox
                              onKeyDown={(e) => {
                                GlobalClass.formNextInput(e, formRef);
                              }}
                              setEdit={(e) =>
                                setLocData({ ...locData, FIELD15: e })
                              }
                              value={locData.FIELD15}
                            />
                          </Grid.Col>
                        </Grid.Col>
                      </Grid>
                      <Grid gutter={4}>
                        <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center' }}>
                          <Button onClick={handleSubmit}>
                            {obj.p0 === "E" ? "EDIT" : "ADD"}
                          </Button>
                        </Grid.Col>
                      </Grid>
                    </Paper>
                  </div>
                )}
            </>
          )}
    </>
  );
};

export default LocationListForm;
