import { createColumnHelper } from '@tanstack/react-table';
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { GetUserSecurity } from '../../../utils/slices/UserSecuritySlice';
import GlobalClass from '../../../utils/GlobalClass';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import { Button, Checkbox, Grid, Group, Text } from '@mantine/core';
import PLDataGrid from '../../../PlusComponent/PLDataGrid';
import PLComboBox from '../../../PlusComponent/PLComboBox';
import { DataSaveLoading } from '../../../utils/slices/DataSaveLoadingSlice';
import gensetting from '../../../utils/gensetting';
import { ModalDelete } from '../../../utils/slices/ModalSlice';
import Store from '../../../utils/store';
import { GetAccountList } from '../../../utils/slices/AccountListSlice';
import useWindowDimensions from '../../../utils/UseWindowDimensions';
import DataGrid from '../../../components/DataGrid';
import UserSecurityGrid from '../../../components/UserSecurityGrid';

const UserSecurityNew = (props) => {
  // console.log("props", props);

  const dispatch = useDispatch();
  const columnHelper = createColumnHelper();
  const [columns, setColumns] = useState([]);
  const [columns2, setColumns2] = useState([]);
  const [rowSelection, setRowSelection] = useState({ 0: "0" });
  const [rowSelection1, setRowSelection1] = useState({})
  const [selectedRow, setSelectedRow] = useState({});
  console.log("selectedRow", selectedRow)
  const [groupedData, setGroupedData] = useState([]);
  const [userData, setUserData] = useState();
  const [data, setData] = useState([])
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const { height, width } = useWindowDimensions();

  const UserName = useSelector(
    (store) => store?.UserSecurity?.UserSecurityData?.P_M81DT
  );
  const P_M81 = useSelector((state) => state.UserSecurity?.UserSecurityData?.P_M81)
  // console.log("UserName", UserName)
  const menuData = useSelector(
    (store) => store?.UserSecurity?.UserSecurityData?.P_MENU
  );
  // console.log("menuData", menuData);
  const isLoading = useSelector((store) => store?.UserSecurity?.isLoading);
  const hasError = useSelector((store) => store?.UserSecurity?.hasError);
  const ErrorMsg = useSelector((store) => store?.UserSecurity?.ErrorMsg);

  const SaveLoading = useSelector(
    (state) => state?.DataSaveLoadingSlice?.SaveLoading
  );

  useEffect(() => {
    if (hasError && !isLoading) {
      GlobalClass.Notify("error", "Error: " + ErrorMsg.code, ErrorMsg.message);
    }
  }, [hasError]);

  useEffect(() => {
    dispatch(GetUserSecurity());
  }, []);

  useEffect(() => {
    if (menuData) {
      setUserData(menuData)
    }
  }, [menuData])

  const separate = (data) => {
    var val = data.split("~C~")
    return val
  }

  // useEffect(() => {
  //   console.log('selectedRow', selectedRow)
  // console.log("groupedData", groupedData);
  //   console.log('menuData', menuData)
  // console.log('userData', userData)
  //   console.log("data", data)
  //   // console.log('pagination', pagination)
  //   // console.log('rowSelection1', rowSelection1)
  //   // console.log('rowSelection', rowSelection)
  // }, [groupedData, selectedRow, menuData, pagination, rowSelection1, rowSelection, userData, data])

  // useEffect(() => {
  //   if (UserName) {
  //     let col = []
  //     col.push(
  //       columnHelper.accessor("FIELD02", {
  //         header: "Username",
  //         enableColumnFilter: false,
  //         enableSorting: false,
  //         enableEditing: false,
  //       })
  //     );
  //     setColumns(col);
  //   }
  // }, [UserName])

  const flattenHierarchy = (data) => {
    const result = [];

    const traverse = (nodes) => {
      nodes.forEach(node => {
        // Create a shallow copy of the node, excluding the `children` property
        const { children, ...rest } = node;
        result.push(rest);

        // Recursively process children if they exist
        if (children && children.length > 0) {
          traverse(children);
        }
      });
    };

    traverse(data);
    return result;
  };

  // Usage
  const flattened = flattenHierarchy(groupedData);
  console.log("flattened", flattened);


  const groupByHierarchy = (data) => {
    // Create a map to keep track of objects by their IDs
    // console.log('data in function', data)
    const map = new Map();

    // Initialize the map with each item
    data?.forEach((item) => {
      if (item?.S01F00 !== "S") {
        map.set(item.S01F02, { ...item, children: [] });
      }
    });
    // console.log('map', map)

    // Initialize an array to hold the root items
    const roots = [];

    // Iterate through the map and link each item to its parent
    map.forEach((item) => {
      if (item.S01F03 && map.has(item.S01F03)) {
        map.get(item.S01F03).children.push(item);
      } else {
        roots.push(item);
      }
    });
    // console.log('roots', roots)
    return roots;
  }

  const handleEdit = (columnName, val, Cell, row) => {

    // setGroupedData(
    //   groupedData?.map((item, i) => {
    //     return {
    //       ...item, children: item?.children?.map((child, j) => {
    //         if (child?.S01F02 == row?.id) {
    //           return { ...child, [columnName]: val === "Y" ? "N" : "Y" }
    //         }
    //         return child
    //       })
    //     }
    //   })
    // )
    // table2.setEditingCell(false);

    function updateNestedChildren(data, rowId, columnName, newValue) {
      return data?.map(item => {
        // If item has children, recursively update them
        const updatedChildren = item.children
          ? updateNestedChildren(item.children, rowId, columnName, newValue)
          : [];

        // Check if current item needs to be updated
        const updatedItem = item.S01F02 === rowId
          ? { ...item, [columnName]: newValue }
          : item;

        return {
          ...updatedItem,
          children: updatedChildren,
        };
      });
    }

    // Compute the new value
    const newValue = val === "Y" ? "N" : "Y";

    const updatedData = updateNestedChildren(groupedData, row.id, columnName, newValue);
    console.log("updatedData", updatedData)

    // Update the groupedData
    setGroupedData(updatedData);
    table2.setEditingCell(false);
  }

  useEffect(() => {
    if (userData && selectedRow) {
      setGroupedData(groupByHierarchy(userData?.[selectedRow?.original?.FIELD01]));
    }

  }, [userData, selectedRow]);

  // useEffect(() => {
  //   console.log("UserName", UserName);
  //   if (UserName && !selectedRow) {
  //     setSelectedRow(UserName?.at(0))
  //   }
  // }, [UserName]);

  let col = [];

  useEffect(() => {

    col.push(
      columnHelper.accessor("S01F04E", {
        header: "Menu",
        enableColumnFilter: false,
        enableSorting: false,
        enableEditing: false,
      })
    );
    col.push(
      columnHelper.accessor("FLD_A", {
        header: "Add",
        enableColumnFilter: false,
        enableSorting: false,
        enableEditing: true,
        mantineTableBodyCellProps: {
          align: "center",
        },
        mantineTableHeadCellProps: {
          align: "center",
        },
        Edit: ({ cell, row, event }) => {
          console.log('FLD_A row', row)
          console.log('FLD_A cell', cell)
          let val = cell.getValue();
          if (row?.original?.S01F00 !== "P") {
            handleEdit("FLD_A", val, cell, row);
          } else {
            table2.setEditingCell(false)
          }
        },
        Cell: ({ cell, row }) => {
          let val = cell.getValue();
          if (row?.original?.S01F00 !== "P") {
            if (val == "Y") {
              return <>&#10004;</>
            } else {
              return <>&#10006;</>;
            }
          } else {
            return;
          }
        },
      })
    );
    col.push(
      columnHelper.accessor("FLD_E", {
        header: "Edit",
        enableColumnFilter: false,
        enableSorting: false,
        mantineTableBodyCellProps: {
          align: "center",
        },
        mantineTableHeadCellProps: {
          align: "center",
        },
        Edit: ({ cell, row }) => {
          let val = cell.getValue();
          if (row?.S01F00 !== "P") {
            console.log('Add Call')
            handleEdit("FLD_E", val, cell, row);
          } else {
            table2.setEditingCell(false)
          }
        },
        Cell: ({ cell, row }) => {
          let val = cell.getValue();
          if (row?.original?.S01F00 !== "P") {
            if (val == "Y") {
              return <>&#10004;</>
            } else {
              return <>&#10006;</>;
            }
          } else {
            return;
          }
        },
      })
    );
    col.push(
      columnHelper.accessor("FLD_D", {
        header: "Delete",
        enableColumnFilter: false,
        enableSorting: false,
        mantineTableBodyCellProps: {
          align: "center",
        },
        mantineTableHeadCellProps: {
          align: "center",
        },
        Edit: ({ cell, row, event }) => {
          let val = cell.getValue();
          if (row?.original?.S01F00 !== "P") {
            handleEdit("FLD_D", val, cell, row);
          } else {
            table2.setEditingCell(false)
          }
        },
        Cell: ({ cell }) => {
          if (cell.getValue() == "Y") {
            return <>&#10004;</>;
          } else if (cell.getValue() == "N") {
            return <>&#10006;</>;
          }
        },
      })
    );
    col.push(
      columnHelper.accessor("FLD_V", {
        header: "View",
        enableColumnFilter: false,
        enableSorting: false,
        mantineTableBodyCellProps: {
          align: "center",
        },
        mantineTableHeadCellProps: {
          align: "center",
        },
        Edit: ({ cell, row, event }) => {
          let val = cell.getValue();
          if (row?.original?.S01F00 !== "P") {
            handleEdit("FLD_V", val, cell, row);
          } else {
            table2.setEditingCell(false)
          }
        },
        Cell: ({ cell }) => {
          if (cell.getValue() == "Y") {
            return <>&#10004;</>;
          } else if (cell.getValue() == "N") {
            return <>&#10006;</>;
          }
        },
      })
    );
    col.push(
      columnHelper.accessor("FLD_P", {
        header: "Print",
        enableColumnFilter: false,
        enableSorting: false,
        mantineTableBodyCellProps: {
          align: "center",
        },
        mantineTableHeadCellProps: {
          align: "center",
        },
        Edit: ({ cell, row, event }) => {
          let val = cell.getValue();
          if (row?.original?.S01F00 !== "P") {
            handleEdit("FLD_P", val, cell, row);
          } else {
            table2.setEditingCell(false)
          }
        },
        Cell: ({ cell }) => {
          if (cell.getValue() == "Y") {
            return <>&#10004;</>;
          } else if (cell.getValue() == "N") {
            return <>&#10006;</>;
          }
        },
      })
    );
    col.push(
      columnHelper.accessor("FLD_U", {
        header: "UserField",
        enableColumnFilter: false,
        enableSorting: false,
        mantineTableBodyCellProps: {
          align: "center",
        },
        mantineTableHeadCellProps: {
          align: "center",
        },
        Edit: ({ cell, row, event }) => {
          let val = cell.getValue();
          if (row?.original?.S01F00 !== "P") {
            handleEdit("FLD_U", val, cell, row);
          } else {
            table2.setEditingCell(false)
          }
        },
        Cell: ({ cell }) => {
          if (cell.getValue() == "Y") {
            return <>&#10004;</>;
          } else if (cell.getValue() == "N") {
            return <>&#10006;</>;
          }
        },
      })
    );
    setColumns2(col);
  }, [groupedData]);

  const handleSaveData = () => {
    dispatch(DataSaveLoading({["UserSecurity"]:true}));
    GlobalClass.Notify(
      "info",
      "Editing",
      "Please wait while we process your data"
    );
    let JData = { "P_MENU": {} };
    if (selectedRow?.leafRows && selectedRow?.leafRows?.length > 0) {
     
        JData.P_MENU[selectedRow?.groupingColumnId] = flattened;

    } else {
      JData.P_MENU[selectedRow?.id] = flattened
    }
    console.log('jData', JData)

    var data = {
      CorpID: gensetting.getcorpid(),
      cUser: gensetting.getcuser(),
      cPass: gensetting.getcpass(),
      CmpNo: gensetting.getcmpno(),
      cAction: "A",
      cCode: "",
      cOPara: "",
      cSData: JSON.stringify(JData),
      YrNo:localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
    };
    let param = JSON.stringify({
      CorpID: gensetting.getcorpid(),
      cUser: gensetting.getcuser(),
      cPass: gensetting.getcpass(),
      CmpNo: gensetting.getcmpno(),
      cAction: "A",
      cOPara: "",
      cCode: "",
    });
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    fetch(
      GlobalClass.ApiUrl + GlobalClass.PostUserSec + "?pa=" + param,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        // console.log('api data', data);
        // console.log('api data status', data?.status);
        if (data.status == "SUCCESS") {
          let PaginationObj =
            Store.getState().DataSaveLoadingSlice.PaginationObj;
          Promise.resolve(dispatch(DataSaveLoading({["UserSecurity"]:false})))
            .then(() =>
              dispatch(GetUserSecurity(props?.OBJ)))
            .then(() =>
              GlobalClass.Notify(
                "success",
                "Edited",
                "Edited successfully"
              )
            );
        } else {
          Promise.resolve(dispatch(DataSaveLoading({["UserSecurity"]:false}))).then(() =>
            GlobalClass.Notify("error", data?.status, data?.message)
          );
        }
      })
      .catch((e) => {
        Promise.resolve(
          GlobalClass.Notify("error", "Error", `${e.message}`)
        ).then(() => dispatch(DataSaveLoading({["UserSecurity"]:false})));
      });
  }

  const handleResetData = () => {
    setGroupedData(groupByHierarchy(userData?.[selectedRow?.original?.FIELD01]))
  }

  const table = useMantineReactTable({
    columns,
    data: UserName ? UserName : [],
    // enableFilters: true,
    enableColumnFilters: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableTopToolbar: false,
    positionToolbarAlertBanner: "none",
    mantineTableProps: { withColumnBorders: true },
    state: {
      rowSelection,
      density: "1.5px",
    },
    mantineTableContainerProps: {
      sx: { height: height * 0.6 },
    },
    enableBottomToolbar: false,
    enableHiding: false,
    enableColumnActions: false,
    enablePagination: false,
    enableStickyHeader: true,
    enableMultiRowSelection: false,
    mantineTableBodyRowProps: ({ row, staticRowIndex }) => ({
      onClick: () => {
        setRowSelection(() => ({
          [row.id]: [row.id],
        }));
        setSelectedRow(row?.original);
      },
      selected: rowSelection[row.id],
      sx: { cursor: "pointer" },
    }),
  });

  const table2 = useMantineReactTable({
    columns: columns2 ?? [],
    data: groupedData ?? [],
    mantineTableProps: { withColumnBorders: true },
    getRowId: (row) => row?.S01F02,
    enableTopToolbar: false,
    enableColumnActions: false,
    enableExpanding: true,
    enableSorting: true,
    enablePagination: false,
    enableStickyHeader: true,
    defaultColumn: {
      minSize: 0, //allow columns to get smaller than default
      maxSize: 15, //allow columns to get larger than default
      size: 1, //make columns wider by default
    },
    mantineTableContainerProps: {
      sx: { height: height * 0.74 },
    },
    renderBottomToolbar: () => (<Group
      spacing={"xs"}
      position="center"
      display={"flex"}

    >
      <Button compact variant="subtle"

        onClick={() => {
          if (selectedRowVal !== undefined && selectedRowVal?.S01F00 !== "P") {
            let Val = selectedRowVal?.FLD_A
            console.log('Val onClick', Val)
            handleEdit("FLD_A", Val, null, Row)
          } else {
            return;
          }
        }}
      >
        Add
      </Button>
      <Button compact variant="subtle"

        onClick={() => {
          if (selectedRowVal !== undefined && selectedRowVal?.S01F00 !== "P") {
            let Val = selectedRowVal?.FLD_E
            handleEdit("FLD_E", Val, null, Row)
          } else {
            return;
          }
        }}
      >
        Edit
      </Button>
      <Button compact variant="subtle"

        onClick={() => {
          if (selectedRowVal !== undefined && selectedRowVal?.S01F00 !== "P") {
            let Val = selectedRowVal?.FLD_D
            handleEdit("FLD_D", Val, null, Row)
          } else {
            return;
          }
        }}
      >
        Delete
      </Button>
      <Button compact variant="subtle"

        onClick={() => {
          if (selectedRowVal !== undefined && selectedRowVal?.S01F00 !== "P") {
            let Val = selectedRowVal?.FLD_V
            handleEdit("FLD_V", Val, null, Row)
          } else {
            return;
          }
        }}
      >
        View
      </Button>
      <Button compact variant="subtle"

        onClick={() => {
          if (selectedRowVal !== undefined && selectedRowVal?.S01F00 !== "P") {
            let Val = selectedRowVal?.FLD_P
            handleEdit("FLD_P", Val, null, Row)
          } else {
            return;
          }
        }}
      >
        Print
      </Button>
      <Button compact variant="subtle"

        onClick={() => {
          if (selectedRowVal !== undefined && selectedRowVal?.S01F00 !== "P") {
            let Val = selectedRowVal?.FLD_U
            handleEdit("FLD_U", Val, null, Row)
          } else {
            return;
          }
        }}
      >
        U.Fld
      </Button>

      <Button compact variant="filled"
        style={{ justifyContent: 'flex-end' }}
        onClick={() => handleSaveData()}
      >
        Save
      </Button>
      <Button compact variant="light"

        onClick={() => handleResetData()}
      >
        Reset
      </Button>
    </Group>),
    mantineTableBodyRowProps: ({ row, staticRowIndex }) => ({
      onClick: () => {
        setRowSelection1(() => ({
          [row.id]: [row.id],
        }));
      },
      selected: rowSelection1[row.id],
      sx: { cursor: "pointer" },
    }),
    state: {
      density: "1px",
      rowSelection: rowSelection1
    },
    // onPaginationChange: setPagination,
    getSubRows: (originalRow) => originalRow?.children,
    enableEditing: true,
    editDisplayMode: "cell",
  });

  let selectedRowVal = table2.getSelectedRowModel().flatRows[0]?.original
  let Row = table2.getSelectedRowModel().flatRows[0]
  // console.log('selectedRowVal', selectedRowVal)
  // console.log('table2 row',Row)

  return (
    <>
      {
        hasError ? <>
          <Text>{ErrorMsg?.message}</Text>
          <Button onClick={() => {
            // if(props.OBJ) {
            dispatch(GetUserSecurity());
            // }
          }}>Retry</Button>
        </> :
          SaveLoading["UserSecurity"] ? GlobalClass.RenderLoader("dots") :
            isLoading ? GlobalClass.RenderLoader("dots") :
              <>
                <Grid gutter={4}>
                  <Grid.Col span={4}>
                    {/* <MantineReactTable table={table} /> */}
                    {UserName && <UserSecurityGrid
                      ValueEdit={(e) => {
                        console.log("ValueEdit", e);
                        if (e.parent != selectedRow) {
                          setSelectedRow(e.parent);
                        }
                      }}
                      selectedData={{ [selectedRow?.id]: selectedRow?.id }}
                      selectionId={"FIELD01"}
                      setPopOver={() => { }}
                      obj={{
                        name: "",
                        type: "A",
                        id: separate(P_M81)[0],
                        p0: separate(P_M81)[0],
                        p1: separate(P_M81)[0],
                        p2: separate(P_M81)[1],
                      }}
                      data={UserName}
                      form={false}
                      viewOnly={true}
                      isMultiGroup={true}
                      groupSelection={true}
                      // setPopOver={()=>{}}
                      isButton={false}
                    // btnName="Print"
                    />}

                  </Grid.Col>
                  <Grid.Col span={8} style={{ flexDirection: "row" }}>
                    <MantineReactTable table={table2} />
                  </Grid.Col>
                </Grid>
              </>
      }
    </>
  )
}

export default UserSecurityNew
