import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import GlobalClass from '../../utils/GlobalClass';
import gensetting from '../../utils/gensetting';
import Store from '../../utils/store';
import { ModalDelete } from '../../utils/slices/ModalSlice';
import { Progress } from '@mantine/core';

const Indexing = (props) => {
    const [isIndexLoading, setIsIndexLoading] = useState(false)
    const [progress, setProgress] = useState(0)

    useEffect(() => {
        if (isIndexLoading) {
            let i = 0;
            const intv = setInterval(() => {
                i += 10;
                setProgress(i);

                if (i >= 100) {
                    clearInterval(intv);
                }
            }, 50);
        }
    }, [isIndexLoading])

    useEffect(() => {
        // ReIndexFun()
        setIsIndexLoading(true)
        var data = {
            CorpID: gensetting.getcorpid(),
            cUser: gensetting.getcuser(),
            cPass: gensetting.getcpass(),
            CmpNo: gensetting.getcmpno(),
            YrNo:localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
        }
        let param = JSON.stringify({
            CorpID: gensetting.getcorpid(),
            cUser: gensetting.getcuser(),
            cPass: gensetting.getcpass(),
            CmpNo: gensetting.getcmpno()
        });
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        };
        fetch(
            GlobalClass.ApiUrl + GlobalClass.DoIndex + "?pa=" + param,
            requestOptions
        )
            .then((response) => response.json())
            .then((data) => {
                console.log("data", data)
                if (data.status == "SUCCESS") {
                    setIsIndexLoading(false)
                    Store.dispatch(ModalDelete(props.index))
                    GlobalClass.Notify("success", "Success", "Successfull")
                } else {
                    setIsIndexLoading(false);
                    GlobalClass.Notify("error", data?.status, data?.message)
                }
            })
            .catch((error) => {
                setIsIndexLoading(false);
                GlobalClass.Notify("error", "Error", error.message);
            });
    }, [])

    return (
        <>
            {
                // isIndexLoading ? GlobalClass.RenderLoader("dots") : ""
                isIndexLoading ? <Progress value={progress} animate size={'md'} /> : ""
            }
        </>
    )
}

export default Indexing