import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GetTDS_TCSDetail } from '../../utils/slices/CompanySetUpPopUpSlice'
import { Button, Divider, Grid, Paper, Text } from '@mantine/core'
import PLDataGrid from '../../PlusComponent/PLDataGrid'
import PLTextBox from '../../PlusComponent/PLTextBox'
import PLNumberBox from '../../PlusComponent/PLNumberBox'
import PLComboBox from '../../PlusComponent/PLComboBox'
import { DataSaveLoading } from '../../utils/slices/DataSaveLoadingSlice'
import GlobalClass from '../../utils/GlobalClass'
import gensetting from '../../utils/gensetting'
import { ModalDelete } from '../../utils/slices/ModalSlice'

const TDS_TCSDetails = (props) => {
    const isLoading = useSelector((state) => state.CompanySetupPopUp?.isLoading)
    const SaveLoading = useSelector(
        (state) => state?.DataSaveLoadingSlice?.SaveLoading
    );
    const TDS_TCSDetailData = useSelector((state) => state.CompanySetupPopUp?.TDS_TCSDetailData)
    console.log('TDS_TCSDetailData=>>', TDS_TCSDetailData)
    const DTYPE1 = useSelector((state) => state.CompanySetupPopUp?.TDS_TCSDetailData?.DTYPE1)
    const P_M53DT = useSelector((state) => state.CompanySetupPopUp?.TDS_TCSDetailData?.P_M53DT)
    const P_M53DTDT = useSelector((state) => state.CompanySetupPopUp?.TDS_TCSDetailData?.P_M53DTDT)
    const oTD = useSelector((state) => state.CompanySetupPopUp?.TDS_TCSDetailData?.oTD)
    const dispatch = useDispatch()

    const [otd, setOtd] = useState({
        "FIELD01": "",
        "FIELD02": "",
        "FIELD07": "",
        "FIELD13": "",
        "FLDUNQ": 0,
        "FLDAED": "",
        "FLDBRC": "",
        "DeducterType": "",
        "DeducterCategory": "",
        "AssessmentRange": "",
        "IncometaxWard/Circle": "",
        "Name": "",
        "Address1": "",
        "Address2": "",
        "City": "",
        "Pincode": "",
        "ResponsiblePerson": "",
        "Son/DoughterOf": "",
        "Designation": "",
        "RPAddress1": "",
        "RPAddress2": "",
        "RPAddress3": "",
        "RPAddress4": "",
        "RPCity": "",
        "RPPincode": "",
        "State": "",
        "PhoneNo": "",
        "Mobile": "",
        "EMail": ""
    })


    useEffect(() => {
        dispatch(GetTDS_TCSDetail())
    }, [])

    const handleSaveData = () => {
        console.log('otd==>', otd)
        dispatch(DataSaveLoading({["TdsTcsDetail"]:true}));
        GlobalClass.Notify("info", "Adding", "Please wait while we process your data")
        let jData = otd
        var data = {
            CorpID: gensetting.getcorpid(),
            cUser: gensetting.getcuser(),
            cPass: gensetting.getcpass(),
            CmpNo: gensetting.getcmpno(),
            cAction: "A",
            cCode: "",
            cSData: JSON.stringify(jData),
            YrNo:localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
        };
        let param = JSON.stringify({
            CorpID: gensetting.getcorpid(),
            cUser: gensetting.getcuser(),
            cPass: gensetting.getcpass(),
            CmpNo: gensetting.getcmpno(),
            cAction: "A",
            cCode: "",
        });
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        };
        fetch(
            GlobalClass.ApiUrl + GlobalClass.PostF02TDS_TCSDetails + "?pa=" + param,
            requestOptions
        )
            .then((response) => response.json())
            .then((data) => {
                // console.log('api data', data);
                // console.log('api data status', data?.status);
                if (data.status === "SUCCESS") {
                    // let PaginationObj =
                    //   Store.getState().DataSaveLoadingSlice.PaginationObj;
                    Promise.resolve(dispatch(DataSaveLoading({["TdsTcsDetail"]:false})))
                        .then(() => dispatch(ModalDelete(props.index)))
                        .then(() =>
                            GlobalClass.Notify(
                                "success",
                                "Added",
                                "Added Successfully"
                                // obj.p0 == "A" ? "Added" : "Edited",
                                // obj.p0 == "A" ? "Added successfully" : "Edited successfully"
                            )
                        );
                } else {
                    Promise.response(dispatch(DataSaveLoading({["TdsTcsDetail"]:false}))).then(() =>
                        GlobalClass.Notify(
                            "error",
                            data?.status,
                            data?.message
                        )
                    );
                }
            })
            .catch((e) => {
                Promise.resolve(
                    GlobalClass.Notify(
                        "error",
                        "Error",
                        `${e?.message}`
                    )).then(() => dispatch(DataSaveLoading({["TdsTcsDetail"]:false})));
            });
    }

    return (
        <>
            {
                SaveLoading["TdsTcsDetail"] == true ?
                    (
                        GlobalClass.RenderLoader("dots")
                    ) :
                    (
                        <>
                            {
                                isLoading == true ? GlobalClass.RenderLoader("dots") :
                                    TDS_TCSDetailData &&
                                    <Paper shadow="md" radius="md" p="xs" withBorder>

                                        <Grid gutter={4}>
                                            <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <Grid.Col span={2}>
                                                    <Text size={12}>Deducter Type</Text>
                                                </Grid.Col>
                                                <Grid.Col span={10}>
                                                    <PLComboBox
                                                        initiallyOpened={true}
                                                        autoFocus={true}
                                                        dispexpr={"DisplayMember"}
                                                        valexpr={"ValueMember"}
                                                        data={DTYPE1}
                                                        value={otd?.DeducterType}
                                                        setEdit={(e) => {
                                                            setOtd({ ...otd, DeducterType: e })
                                                        }}
                                                    />
                                                </Grid.Col>
                                            </Grid.Col>
                                        </Grid>

                                        <Grid gutter={4}>
                                            <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <Grid.Col span={2}>
                                                    <Text size={12}>Deducter Category</Text>
                                                </Grid.Col>
                                                <Grid.Col span={10}>
                                                    <PLDataGrid
                                                        TmData={P_M53DT}
                                                        data={P_M53DTDT?.jData}
                                                        value={otd?.DeducterCategory}
                                                        setEdit={(e) => {
                                                            setOtd({ ...otd, DeducterCategory: e?.FIELD01 })
                                                        }}
                                                    />
                                                </Grid.Col>
                                            </Grid.Col>
                                        </Grid>

                                        <Grid gutter={4}>
                                            <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <Grid.Col span={2}>
                                                    <Text size={12}>Assessment Range</Text>
                                                </Grid.Col>
                                                <Grid.Col span={10}>
                                                    <PLTextBox
                                                        value={otd?.AssessmentRange}
                                                        setEdit={(e) => {
                                                            setOtd({ ...otd, AssessmentRange: e.target.value })
                                                        }}
                                                    />
                                                </Grid.Col>
                                            </Grid.Col>
                                        </Grid>

                                        <Divider my="md" color='#A9A9A9' />

                                        <Grid gutter={4}>
                                            <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <Grid.Col span={2}>
                                                    <Text size={12}>Income Tax Ward/Circle</Text>
                                                </Grid.Col>
                                                <Grid.Col span={10}>
                                                    <PLTextBox
                                                        value={otd?.["IncometaxWard/Circle"]}
                                                        setEdit={(e) => {
                                                            setOtd({ ...otd, "IncometaxWard/Circle": e.target.value })
                                                        }}
                                                    />
                                                </Grid.Col>
                                            </Grid.Col>
                                        </Grid>

                                        <Grid gutter={4}>
                                            <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <Grid.Col span={2}>
                                                    <Text size={12}>Name</Text>
                                                </Grid.Col>
                                                <Grid.Col span={10}>
                                                    <PLTextBox
                                                        value={otd?.Name}
                                                        setEdit={(e) => {
                                                            setOtd({ ...otd, Name: e.target.value })
                                                        }}
                                                    />
                                                </Grid.Col>
                                            </Grid.Col>
                                        </Grid>

                                        <Grid gutter={4}>
                                            <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <Grid.Col span={2}>
                                                    <Text size={12}>Address-1</Text>
                                                </Grid.Col>
                                                <Grid.Col span={10}>
                                                    <PLTextBox
                                                        value={otd?.Address1}
                                                        setEdit={(e) => {
                                                            setOtd({ ...otd, Address1: e.target.value })
                                                        }}
                                                    />
                                                </Grid.Col>
                                            </Grid.Col>
                                        </Grid>

                                        <Grid gutter={4}>
                                            <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <Grid.Col span={2}>
                                                    <Text size={12}>Address-2</Text>
                                                </Grid.Col>
                                                <Grid.Col span={10}>
                                                    <PLTextBox
                                                        value={otd?.Address2}
                                                        setEdit={(e) => {
                                                            setOtd({ ...otd, Address2: e.target.value })
                                                        }}
                                                    />
                                                </Grid.Col>
                                            </Grid.Col>
                                        </Grid>

                                        <Grid gutter={4}>
                                            <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <Grid.Col span={2}>
                                                    <Text size={12}>City</Text>
                                                </Grid.Col>
                                                <Grid.Col span={4}>
                                                    <PLTextBox
                                                        value={otd?.City}
                                                        setEdit={(e) => {
                                                            setOtd({ ...otd, City: e.target.value })
                                                        }}
                                                    />
                                                </Grid.Col>
                                                <Grid.Col span={2}>
                                                    <Text size={12} mx={40}>Pincode</Text>
                                                </Grid.Col>
                                                <Grid.Col span={4}>
                                                    <PLNumberBox
                                                        value={otd?.Pincode}
                                                        setEdit={(e) => {
                                                            setOtd({ ...otd, Pincode: e })
                                                        }}
                                                    />
                                                </Grid.Col>
                                            </Grid.Col>
                                        </Grid>

                                        <Divider my="md" color='#A9A9A9' />

                                        <Grid gutter={4}>
                                            <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <Grid.Col span={2}>
                                                    <Text size={12}>Responsible Person</Text>
                                                </Grid.Col>
                                                <Grid.Col span={10}>
                                                    <PLTextBox
                                                        value={otd?.ResponsiblePerson}
                                                        setEdit={(e) => {
                                                            setOtd({ ...otd, ResponsiblePerson: e.target.value })
                                                        }}
                                                    />
                                                </Grid.Col>
                                            </Grid.Col>
                                        </Grid>

                                        <Grid gutter={4}>
                                            <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <Grid.Col span={2}>
                                                    <Text size={12}>Son/Daughter Of</Text>
                                                </Grid.Col>
                                                <Grid.Col span={10}>
                                                    <PLTextBox
                                                        value={otd?.["Son/DoughterOf"]}
                                                        setEdit={(e) => {
                                                            setOtd({ ...otd, "Son/DoughterOf": e.target.value })
                                                        }}
                                                    />
                                                </Grid.Col>
                                            </Grid.Col>
                                        </Grid>

                                        <Grid gutter={4}>
                                            <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <Grid.Col span={2}>
                                                    <Text size={12}>Designation</Text>
                                                </Grid.Col>
                                                <Grid.Col span={10}>
                                                    <PLTextBox
                                                        value={otd?.Designation}
                                                        setEdit={(e) => {
                                                            setOtd({ ...otd, Designation: e.target.value })
                                                        }}
                                                    />
                                                </Grid.Col>
                                            </Grid.Col>
                                        </Grid>

                                        <Grid gutter={4}>
                                            <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <Grid.Col span={2}>
                                                    <Text size={12}>Address-1</Text>
                                                </Grid.Col>
                                                <Grid.Col span={10}>
                                                    <PLTextBox
                                                        value={otd?.RPAddress1}
                                                        setEdit={(e) => {
                                                            setOtd({ ...otd, RPAddress1: e.target.value })
                                                        }}
                                                    />
                                                </Grid.Col>
                                            </Grid.Col>
                                        </Grid>

                                        <Grid gutter={4}>
                                            <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <Grid.Col span={2}>
                                                    <Text size={12}>Address-2</Text>
                                                </Grid.Col>
                                                <Grid.Col span={10}>
                                                    <PLTextBox
                                                        value={otd?.RPAddress2}
                                                        setEdit={(e) => {
                                                            setOtd({ ...otd, RPAddress2: e.target.value })
                                                        }}
                                                    />
                                                </Grid.Col>
                                            </Grid.Col>
                                        </Grid>

                                        <Grid gutter={4}>
                                            <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <Grid.Col span={2}>
                                                    <Text size={12}>Address-3</Text>
                                                </Grid.Col>
                                                <Grid.Col span={10}>
                                                    <PLTextBox
                                                        value={otd?.RPAddress3}
                                                        setEdit={(e) => {
                                                            setOtd({ ...otd, RPAddress3: e.target.value })
                                                        }}
                                                    />
                                                </Grid.Col>
                                            </Grid.Col>
                                        </Grid>

                                        <Grid gutter={4}>
                                            <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <Grid.Col span={2}>
                                                    <Text size={12}>Address-4</Text>
                                                </Grid.Col>
                                                <Grid.Col span={10}>
                                                    <PLTextBox
                                                        value={otd?.RPAddress4}
                                                        setEdit={(e) => {
                                                            setOtd({ ...otd, RPAddress4: e.target.value })
                                                        }}
                                                    />
                                                </Grid.Col>
                                            </Grid.Col>
                                        </Grid>

                                        <Grid gutter={4}>
                                            <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <Grid.Col span={2}>
                                                    <Text size={12}>City</Text>
                                                </Grid.Col>
                                                <Grid.Col span={4}>
                                                    <PLTextBox
                                                        value={otd?.RPCity}
                                                        setEdit={(e) => {
                                                            setOtd({ ...otd, RPCity: e.target.value })
                                                        }}
                                                    />
                                                </Grid.Col>
                                                <Grid.Col span={2}>
                                                    <Text size={12} mx={40}>Pincode</Text>
                                                </Grid.Col>
                                                <Grid.Col span={4}>
                                                    <PLNumberBox
                                                        value={otd?.RPPincode}
                                                        setEdit={(e) => {
                                                            setOtd({ ...otd, RPPincode: e })
                                                        }}
                                                    />
                                                </Grid.Col>
                                            </Grid.Col>
                                        </Grid>

                                        <Grid gutter={4}>
                                            <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <Grid.Col span={2}>
                                                    <Text size={12}>State</Text>
                                                </Grid.Col>
                                                <Grid.Col span={10}>
                                                    <PLTextBox
                                                        value={otd?.State}
                                                        setEdit={(e) => {
                                                            setOtd({ ...otd, State: e.target.value })
                                                        }}
                                                    />
                                                </Grid.Col>
                                            </Grid.Col>
                                        </Grid>

                                        <Grid gutter={4}>
                                            <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <Grid.Col span={2}>
                                                    <Text size={12}>Phone No</Text>
                                                </Grid.Col>

                                                <Grid.Col span={4}>
                                                    <PLNumberBox
                                                        value={otd?.PhoneNo}
                                                        setEdit={(e) => {
                                                            setOtd({ ...otd, PhoneNo: e })
                                                        }}
                                                    />
                                                </Grid.Col>
                                                <Grid.Col span={2}>
                                                    <Text size={12} mx={40}>Mobile</Text>
                                                </Grid.Col>
                                                <Grid.Col span={4}>
                                                    <PLNumberBox
                                                        value={otd?.Mobile}
                                                        setEdit={(e) => {
                                                            setOtd({ ...otd, Address2: e })
                                                        }}
                                                    />
                                                </Grid.Col>
                                            </Grid.Col>
                                        </Grid>

                                        <Grid gutter={4}>
                                            <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <Grid.Col span={2}>
                                                    <Text size={12}>E-Mail</Text>
                                                </Grid.Col>
                                                <Grid.Col span={10}>
                                                    <PLTextBox
                                                        value={otd?.EMail}
                                                        setEdit={(e) => {
                                                            setOtd({ ...otd, EMail: e.target.value })
                                                        }}
                                                    />
                                                </Grid.Col>
                                            </Grid.Col>
                                        </Grid>

                                        <Grid gutter={4}>
                                            <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <Button onClick={() => handleSaveData()}>OK</Button>
                                            </Grid.Col>
                                        </Grid>

                                    </Paper>
                            }
                        </>
                    )
            }
        </>
    )
}

export default TDS_TCSDetails