import { Box, Button, Center, Divider, Grid, Text } from "@mantine/core";
import PLComboBox from "../../../../PlusComponent/PLComboBox";
import PLNumberBox from "../../../../PlusComponent/PLNumberBox";
import { useEffect, useState } from "react";

export default function PrintingOption(props) {
  const { DrawerOBJ, setDrawerOBJ, COPF73, COP1, COPPPR, F14DICObj, setF14DICObj } = props;
  const [printingObj, setPrintingObj] = useState(null)
  console.log("printingObj", printingObj)
  useEffect(() => {
    if (F14DICObj) {
      setPrintingObj(F14DICObj)
    }
  }, [F14DICObj])

  const savePrint = () => {
    setF14DICObj(printingObj)
    setDrawerOBJ({ ...DrawerOBJ, open: false })
  }
  return (
    <>
      <Box>
        <Grid gutter={3}>
          <Grid.Col
            span={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Grid.Col span={4}>
              <Text style={{ fontSize: 12 }}>Company Name On</Text>
            </Grid.Col>
            <Grid.Col span={4}>
              <PLComboBox
                data={COPF73}
                dispexpr="COPF04"
                valexpr="COPF01"
                value={printingObj?.S62_CNM}
                setEdit={(e) => {
                  //console.log(e)
                  setPrintingObj({ ...printingObj, S62_CNM: e });
                }}
              />
            </Grid.Col>
          </Grid.Col>

          <Grid.Col
            span={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Grid.Col span={4}>
              <Text style={{ fontSize: 12 }}>Report Header On</Text>
            </Grid.Col>
            <Grid.Col span={4}>
              <PLComboBox
                data={COPF73}
                dispexpr="COPF04"
                valexpr="COPF01"
                value={printingObj?.S62_HNM}
                setEdit={(e) => {
                  //console.log(e)
                  setPrintingObj({ ...printingObj, S62_HNM: e });
                }}
              />
            </Grid.Col>
          </Grid.Col>

          <Grid.Col
            span={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Grid.Col span={4}>
              <Text style={{ fontSize: 12 }}>Footer On</Text>
            </Grid.Col>
            <Grid.Col span={4}>
              <PLComboBox
                data={COPF73}
                dispexpr="COPF04"
                valexpr="COPF01"
                value={printingObj?.S62_FNM}
                setEdit={(e) => {
                  //console.log(e)
                  setPrintingObj({ ...printingObj, S62_FNM: e });
                }}
              />
            </Grid.Col>
          </Grid.Col>

          <Grid.Col
            span={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Grid.Col span={4}>
              <Text style={{ fontSize: 12 }}>Print Report Title</Text>
            </Grid.Col>
            <Grid.Col span={4}>
              <PLComboBox
                data={COP1}
                dispexpr="COPF04"
                valexpr="COPF01"
                value={printingObj?.S62_PRH}
                setEdit={(e) => {
                  //console.log(e)
                  setPrintingObj({ ...printingObj, S62_PRH: e });
                }}
              />
            </Grid.Col>
          </Grid.Col>

          <Grid.Col
            span={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Grid.Col span={4}>
              <Text style={{ fontSize: 12 }}>Print Date Range</Text>
            </Grid.Col>
            <Grid.Col span={4}>
              <PLComboBox
                data={COP1}
                dispexpr="COPF04"
                valexpr="COPF01"
                value={printingObj?.S62_PDT}
                setEdit={(e) => {
                  //console.log(e)
                  setPrintingObj({ ...printingObj, S62_PDT: e });
                }}
              />
            </Grid.Col>
          </Grid.Col>

          <Grid.Col
            span={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Grid.Col span={4}>
              <Text style={{ fontSize: 12 }}>Print Page No</Text>
            </Grid.Col>
            <Grid.Col span={4}>
              <PLComboBox
                data={COP1}
                dispexpr="COPF04"
                valexpr="COPF01"
                value={printingObj?.S62_PPN}
                setEdit={(e) => {
                  //console.log(e)
                  setPrintingObj({ ...printingObj, S62_PPN: e });
                }}
              />
            </Grid.Col>
          </Grid.Col>

          {/* <Grid.Col
            span={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Grid.Col span={4}>
              <Text style={{ fontSize: 12 }}>Page Total</Text>
            </Grid.Col>
            <Grid.Col span={4}>
              <PLComboBox
              // value={group?.[h?.S13F02]}
              // setEdit={(e) => {
              //   //console.log(e)
              //   setGroup({ ...group, [h?.S13F02]: e.target.value });
              // }}
              />
            </Grid.Col>
          </Grid.Col> */}
          <Grid.Col
            span={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            {/* <Grid.Col span={4}>
              <Text></Text>
            </Grid.Col> */}
            <Grid.Col span={4}>
              <Text size={15} align="center">Windows</Text>
            </Grid.Col>
            {/* <Grid.Col span={4}>
              <Text>Dos</Text>
            </Grid.Col> */}
          </Grid.Col>
        </Grid>
        <Divider size="xs" />
        <Grid gutter={3} my={3}>
          <Grid.Col
            span={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Grid.Col span={4}>
              <Text style={{ fontSize: 12 }}>Paper Size</Text>
            </Grid.Col>
            <Grid.Col span={4}>
              <PLComboBox
                data={COPPPR}
                dispexpr="DisplayMember"
                valexpr="ValueMember"
                value={printingObj?.S62_WPG}
                setEdit={(e) => {
                  //console.log(e)
                  setPrintingObj({ ...printingObj, S62_WPG: e });
                }}
              />
            </Grid.Col>
            {/* <Grid.Col span={4}>
              <PLComboBox
              // value={group?.[h?.S13F02]}
              // setEdit={(e) => {
              //   //console.log(e)
              //   setGroup({ ...group, [h?.S13F02]: e.target.value });
              // }}
              />
            </Grid.Col> */}
          </Grid.Col>
          <Grid.Col
            span={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Grid.Col span={4}>
              <Text style={{ fontSize: 12 }}>Left Margin</Text>
            </Grid.Col>
            <Grid.Col span={4}>
              <PLNumberBox
                value={printingObj?.S62_WLM}
                setEdit={(e) => {
                  //console.log(e)
                  setPrintingObj({ ...printingObj, S62_WLM: e });
                }}
              />
            </Grid.Col>
            {/* <Grid.Col span={4}>
              <PLComboBox
              // value={group?.[h?.S13F02]}
              // setEdit={(e) => {
              //   //console.log(e)
              //   setGroup({ ...group, [h?.S13F02]: e.target.value });
              // }}
              />
            </Grid.Col> */}
          </Grid.Col>
          <Grid.Col
            span={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Grid.Col span={4}>
              <Text style={{ fontSize: 12 }}>Top Margin</Text>
            </Grid.Col>
            <Grid.Col span={4}>
              <PLNumberBox
                value={printingObj?.S62_WTM}
                setEdit={(e) => {
                  //console.log(e)
                  setPrintingObj({ ...printingObj, S62_WTM: e });
                }}
              />
            </Grid.Col>
            {/* <Grid.Col span={4}>
              <PLComboBox
              // value={group?.[h?.S13F02]}
              // setEdit={(e) => {
              //   //console.log(e)
              //   setGroup({ ...group, [h?.S13F02]: e.target.value });
              // }}
              />
            </Grid.Col> */}
          </Grid.Col>
          <Grid.Col span={12}>
            <Center>
              <Button
                size="xs"
                onClick={savePrint}
              >
                OK
              </Button>
            </Center>
          </Grid.Col>
        </Grid>
      </Box>
    </>
  );
}
