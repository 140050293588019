import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GetMultiBarCode } from '../../utils/slices/CompanySetUpPopUpSlice'
import PLComboBox from '../../PlusComponent/PLComboBox'
import { createColumnHelper } from '@tanstack/react-table'
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table'
import { ActionIcon, Button, Flex, Grid, Tooltip } from '@mantine/core'
import GlobalClass from '../../utils/GlobalClass'
import PLDataGrid from '../../PlusComponent/PLDataGrid'
import PLNumberBox from '../../PlusComponent/PLNumberBox'
import { IconEdit, IconTrash } from '@tabler/icons-react'
import useWindowDimensions from '../../utils/UseWindowDimensions'

const MultiBarCode = (props) => {
    const { value, setEditSend } = props
    console.log("props", props)
    const MultiBarCodeData = useSelector((state) => state.CompanySetupPopUp?.MultiBarCodeSetUpData)
    const MBCDDT = useSelector((state) => state.CompanySetupPopUp?.MultiBarCodeSetUpData?.MBCDDT)
    const MBRDT = useSelector((state) => state.CompanySetupPopUp?.MultiBarCodeSetUpData?.MBRDT)
    const MBRGRD = useSelector((state) => state.CompanySetupPopUp?.MultiBarCodeSetUpData?.MBRGRD)
    const { isLoading } = useSelector((state) => state.CompanySetupPopUp)
    const dispatch = useDispatch()

    const [columns, setColumns] = useState([])
    const [data, setData] = useState([])
    const [MBCObj, setMBCObj] = useState({})
    const [rowSelection, setRowSelection] = useState({})
    const [tableEditingRow, setTableEditingRow] = useState(null)
    const { height, width } = useWindowDimensions();

    const columnHelper = createColumnHelper();

    useEffect(() => {
        console.log("MBCObj", MBCObj)
        console.log("data", data)
    }, [data, MBCObj])

    useEffect(() => {
        dispatch(GetMultiBarCode())
    }, [])

    useEffect(() => {
        if (value) {
            const extractVal = value.split("||")
            if (extractVal && extractVal?.length > 0) {
                let result = extractVal?.map((m) => {
                    let val = m.split("#")
                    console.log("val", val)
                    let newObj = { ...MBCObj }
                    let seqVal = Number(val[0])
                    let barCode = val[1]
                    let width = Number(val[3])
                    let cumulative = val[4]
                    let factor = Number(val[5])

                    return {
                        ...newObj,
                        MBDF01: seqVal,
                        MBDF02: barCode,
                        MBDF03: width,
                        MBDF04: cumulative,
                        MBDF05: factor
                    }
                })
                console.log("result", result)
                return setData([...result, { ...MBCDDT[0] }])
            }
        } else {
            setData([...MBCDDT])
            setMBCObj(MBCDDT[0])
        }
    }, [MBCDDT, value])

    const seperate = (e) => {
        var paraArr = e.split("~C~");
        if (paraArr.length == 1) {
            paraArr[1] = paraArr[0];
        }
        return paraArr;
    };

    const NextIndexFun = (index) => {
        const nextInd = index + 1;
        console.log("nextInd=>", nextInd)
        const nextInput = document.getElementById(`T02Ent_${nextInd}`)
        console.log("nextInput=>", nextInput)

        if (nextInput) {
            nextInput.focus()
        }
    }

    const renderInputBox = (uID, v, c, cellid, index) => {
        let setInput;
        switch (c?.S53F14) {
            case "PLPOPUP":
                if (c?.S53F02 == "MBRCD02") {
                    setInput = (
                        <PLComboBox
                            autoFocus={true}
                            initiallyOpened={true}
                            cmbid={`MBRGRD_${index}`}
                            width={"500px"}
                            value={MBCObj[c?.S53F04]}
                            valexpr="MBRF01"
                            dispexpr="MBRF02"
                            onKeyDown={(e) => {
                                if (e.key == "Enter") {
                                    NextIndexFun(index)
                                }
                            }}
                            data={MBRDT}
                            setEdit={(e) => {
                                setMBCObj({ ...MBCObj, [c?.S53F04]: e })
                            }}
                        />
                    )
                } else {
                    setInput = (
                        <PLDataGrid
                            id={`MBRGRD_${index}`}
                            width={"500px"}
                            value={MBCObj[c?.S53F04]}
                            p1={seperate(c.S53F15)[1]}
                            setEdit={(e) => {
                                // console.log('DataGrid object=>>', e)
                                setMBCObj({ ...MBCObj, [c?.S53F04]: e?.FIELD01 })

                            }}
                        />
                    );
                }
                break;
            case "COMBOBOX":
                setInput = (
                    <PLComboBox
                        // cmbid={`T02Ent_${index}`}
                        // autoFocus={true}
                        cmbid={`MBRGRD_${index}`}
                        value={MBCObj[c?.S53F04]}
                        copno={seperate(c.S53F15)[1]}
                        dispexpr={"DisplayMember"}
                        valexpr={"ValueMember"}
                        onKeyDown={(e) => {
                            if (e.key == "Enter") {
                                NextIndexFun(index)
                            }
                        }}
                        setEdit={(e) => {
                            setMBCObj({ ...MBCObj, [c?.S53F04]: e.value })
                        }}
                    />
                )
                break;
            case "NUMBOX":
                setInput = (
                    <PLNumberBox
                        autoFocus={true}
                        id={`MBRGRD_${index}`}
                        decimalSeparator={true}
                        precision={3}
                        onKeyDown={(e) => {
                            if (e.key == "Enter" || e.key == "Tab") {
                                if (c?.S53F02 == "MBRCD05") {
                                    handleEditSave()
                                }
                            }
                        }}
                        value={MBCObj[c?.S53F04]}
                        setEdit={(e) => {
                            setMBCObj({ ...MBCObj, [c?.S53F04]: e });
                        }}
                    />
                );
                break;
            default:
                setInput = null;
                break;
        }
        return setInput
    }

    useEffect(() => {
        if (MBRGRD) {
            let cols = []
            MBRGRD?.filter((v, i) => v?.S53F00 === "C").map((c, i) => {
                cols.push(
                    columnHelper.accessor(c.S53F04, {
                        header: c.S53F03,
                        size: c.S53F07,
                        Edit: ({ cell, row }) => {
                            let val = cell.getValue();
                            return renderInputBox(c.S53UID, val, c, cell?.id, i)
                        },
                        Cell: ({ cell, row }) => {
                            let val = cell.getValue();
                            // if(c?.S53F02 == "MBRCD04"){
                            //    return data[row.id]?.MBDF04 == "Y" ? "Yes" : "No"
                            // }
                            return val
                        }
                    })
                )
            })
            return setColumns(cols)
        }
    }, [MBRGRD, data, MBCDDT, MBCObj])

    const handleEditSave = () => {
        // setAddMode(true)
        console.log('on enter call function')
        console.log('T02Data in Edit Case', data)
        let EditT02Data = [...data];
        EditT02Data[tableEditingRow.index] = MBCObj;

        let nullFilter = EditT02Data?.filter((d) => d?.MBDF02 != "" && d?.MBDF02 != null)
        setData([...nullFilter, MBCDDT?.[0]]);
        setMBCObj(MBCDDT?.[0])
        setRowSelection({ [data?.length]: true })
        table.setEditingRow(null); //exit editing mode
        // setSelectedProductObj(null)
        // setBatchIssueObj(null)

        setTableEditingRow(null)
    };

    const DeleteRow = (row) => {

        // console.log("DeleteRow", row);
        // if (window.confirm('Confirm to Delete ?')) {
        //     let newArray = data?.filter((d, i) => i != row?.index)
        //     setData(newArray)
        // }

        GlobalClass.Confirm(
            "red",
            "Are you sure to delete selected data?",
            "It will be Deleted Forever and Cannot Be Recovered!!!",
            () => {
                let newArray = data?.filter((d, i) => i != row?.index)
                setData(newArray)
            },
            () => { },
            "Cancel",
            "Confirm"
        )
    }

    const handleRowKeyDown = (e, row) => {
        console.log("handleRowKeyDown parameter =>", [{
            "e": e,
            "row": row,
            "tableEditingRow": tableEditingRow,
            //   "TableRef.current": TableRef.current
        }])
        // if (e?.key == "Enter" && tableEditingRow == null) {
        //   if (row?.id == 'mrt-row-create') {
        //     return
        //   } else if (T02Obj?.LVALID == "N") {
        //     GlobalClass.Notify("warning", "Bill prepared for this entry, can not edit this entry")
        //   } else {
        //     table.setEditingRow(row)
        //     setTableEditingRow(row)
        //     setT02Obj(row?.original)
        //   }
        // }

        // const tabled = TableRef.current;
        // const rows = tabled.querySelectorAll('tr');
        // const currentRowIndex = Array.from(rows).indexOf(e.target);
        // console.log("TABKEY", e.key);
        switch (e?.key) {
            //   case 'ArrowDown':
            //     // e.preventDefault();
            //     if (currentRowIndex < rows.length - 1) {
            //       e.preventDefault();
            //       rows[currentRowIndex + 1].focus();
            //       // rows[currentRowIndex + 1].scrollIntoView({ behavior: "smooth",block: "start"})

            //     } else if (
            //       table?.getPageCount() && table?.getPageCount() > 1 &&
            //       pagination?.pageIndex < table?.getPageCount() - 1
            //     ) {
            //       // Navigate to the next page
            //       table.nextPage()
            //       // setPagination((prev) => ({
            //       //   ...prev,
            //       //   pageIndex: prev.pageIndex + 1,
            //       // }));
            //       // rows[0].scrollIntoView({ behavior: "smooth",block: "start"})
            //       // rows[0].focus();
            //     }

            //     break;
            //   case 'ArrowUp':

            //     if (currentRowIndex > 0) {
            //       e.preventDefault();
            //       rows[currentRowIndex - 1].focus();
            //       // rows[currentRowIndex - 1].scrollIntoView({ behavior: "smooth",block: "end"})
            //     } else if (pagination.pageIndex > 0) {
            //       // Navigate to the previous page
            //       table.previousPage()
            //       // setPagination((prev) => ({
            //       //   ...prev,
            //       //   pageIndex: prev.pageIndex - 1,
            //       // }));
            //       // rows[0].scrollIntoView({ behavior: "smooth",block: "start"})
            //       // rows[0].focus();
            //     }

            //     break;
            case "Enter":
                if (row?.id == 'mrt-row-create') {
                    return
                } else if (tableEditingRow) {
                    return
                } else {
                    table?.setEditingRow(row)
                    setTableEditingRow(row)
                    setMBCObj(row?.original)
                }
                break;
            default:
                break;
        }
    }

    const table = useMantineReactTable({
        columns,
        data,
        mantineTableProps: { withColumnBorders: true },
        enableTopToolbar: false,
        enableSorting: false,
        enableColumnActions: false,
        enableHiding: false,
        enableEditing: true,
        enableMultiRowSelection: true,
        editDisplayMode: "row",
        onEditingRowSave: handleEditSave,
        // onRowSelectionChange: setRowSelection,
        state: {
            rowSelection
        },
        initialState: {
            density: "0px"
        },
        mantineTableBodyRowProps: ({ row, staticRowIndex }) => ({
            tabIndex: 0,
            onKeyUp: (e) => handleRowKeyDown(e, row),
            onClick: () => {
                setRowSelection((prev) => ({
                    ...prev,
                    [row?.id]: !prev[row?.id],
                }))
            },
            selected: rowSelection[row.id],
            sx: { cursor: "pointer" },
        }),
        mantineTableContainerProps: {
            sx: { height: height * 0.2 },
        },
        renderRowActions: ({ row, table }) => (
            <Flex gap="md">
                <Tooltip label="Edit">
                    <ActionIcon
                        onClick={() => {
                            table.setEditingRow(row);
                            console.log("table.setEditingRow=>", row);
                            setMBCObj(row.original);
                            setTableEditingRow(row)
                        }}
                    >
                        <IconEdit size={"20px"} />
                    </ActionIcon>
                </Tooltip>
                {/* {
                    (row?.original?.MBDF02 != "" || row?.original?.MBDF02 != null) &&
                    <Tooltip label="Delete">
                        <ActionIcon color="red" onClick={() => {
                            console.log("openDeleteConfirmModal =>", row);
                            DeleteRow(row)
                        }}>
                            <IconTrash size={'20px'} />
                        </ActionIcon>
                    </Tooltip>
                } */}
            </Flex>
        ),
    })

    const SubmitButton = () => {
        console.log("submit");

        let newArray = []

        let filterArr = data?.filter((item) => item.MBDF02 !== null)
        console.log("filterArr", filterArr)
        filterArr?.map((n, i) => {

            const sequence = n.MBDF01
            const description = n.MBDF02
            const width = n.MBDF03
            const cumulative = n.MBDF04
            const factor = n.MBDF05

            const disName = MBRDT.filter((item) => item.MBRF01 == description)
            console.log("disName", disName)

            const formattedString = `${sequence}#${description}#${disName[0]?.MBRF02}#${width}#${cumulative}#${factor}`
            newArray.push(formattedString)
        })

        const result = newArray.join("||")
        Promise.resolve(setEditSend(result))

        console.log("result", result)

        // Promise.resolve(selectedPendingChallan({ challanData: ChallanData, selectedChallan: newArray })).then(() => setDrawerOBJ({ ...DrawerOBJ, open: false }))
    }

    return (
        <>
            {
                isLoading ? GlobalClass.RenderLoader("dots") :
                    <Grid gutter={4}>
                        <Grid.Col span={12}>
                            <MantineReactTable table={table} />
                        </Grid.Col>
                        <Grid.Col span={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Button
                                onClick={SubmitButton}
                            >OK</Button>
                        </Grid.Col>
                    </Grid>
            }
        </>
    )
}

export default MultiBarCode