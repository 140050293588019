import React, { useEffect, useMemo, useState } from 'react'
import DataGrid from '../../components/DataGrid'
import { MRT_TablePagination, MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import { useDispatch, useSelector } from 'react-redux';
import { GetAccountList } from '../../utils/slices/AccountListSlice';
import { GetGeneralData, GetGeneralTMFormat, getMLF01 } from '../../utils/TransactionSlices/MailingLetter';
import { createColumnHelper } from '@tanstack/react-table';
import { Box, Grid, Group, Text } from '@mantine/core';
import PLTextBox from '../../PlusComponent/PLTextBox';
import ButtonPanel from '../../PlusComponent/ButtonPanel';
import GlobalClass from '../../utils/GlobalClass';
import { ModalDelete } from '../../utils/slices/ModalSlice';

const MailingLetter = (props) => {
  const { obj } = props

  const dispatch = useDispatch();
  const GeneralData = useSelector(store => store?.MailingLetter?.GeneralData);
  const TMFormatData = useSelector(store => store?.MailingLetter?.TMFormatData);
  const isLoading = useSelector(store => store?.MailingLetter?.isLoading)
  const hasError = useSelector(store => store?.MailingLetter?.hasError)
  const MailingLetterDeleteStatus = useSelector(store => store?.MailingLetter?.MailingLetterDeleteStatus)

  const [rowSelection, setRowSelection] = useState({});
  const [columns, setColumns] = useState([]);
  const [search, setSearch] = useState();
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 25,
  });

  const columnHelper = createColumnHelper();

  useEffect(() => {
    Promise.resolve(dispatch(GetGeneralData(obj))).then(() => dispatch(GetGeneralTMFormat()))
  }, [MailingLetterDeleteStatus])

  useEffect(() => {
    console.log("GeneralData", GeneralData, TMFormatData);

    if (TMFormatData && TMFormatData.oDCFrmt && TMFormatData.oDCFrmt.aDCol && GeneralData) {
      var cols = [];
      TMFormatData?.oDCFrmt?.aDCol?.map((item, index) => {
        console.log("item", item);
        cols.push(
          columnHelper.accessor(item.DispExpr, {
            header: item.ColCap,
            // enableColumnFilter: item.lSearchCol,
            // enableSorting: item.lColOrder,
            // size: item.ColWidth,
            // minSize: 0,
            // maxSize: item.ColWidth,
            // mantineTableBodyCellProps: {
            //   align: item.DAlign == 1 ? item.DispCond : "left",
            // },
            // mantineTableHeadCellProps: {
            //   align: item.HAlign == 1 ? item.DispCond : "left",
            // },
            // aggregationFn: "sum",
            // AggregatedCell: ({ cell }) =>
            //   item?.cSType !== "N" ? (
            //     <div>
            //       <Text style={{ fontWeight: "bold", color: "gray" }}>
            //         Total : {cell.getValue().toFixed(4)}
            //       </Text>
            //     </div>
            //   ) : (
            //     ""
            //   ),
            // Cell: ({ cell, renderedCellValue }) =>
            //   //  console.log('cell====>',cell),
            //   rendercolcell(index == 0 ? renderedCellValue : cell.getValue(), v),
            // Footer: () => <div>{Summary(v, index, "c")} </div>,
          })
        );
      });
      setColumns(cols)
    }

  }, [TMFormatData])

  useEffect(() => {
    dispatch(getMLF01(table?.getSelectedRowModel()?.flatRows[0]?.original?.MLF01))
  }, [rowSelection])

  const simulateEnterKeyPress = () => {
    console.log("table?.getSelectedRowModel()", table?.getSelectedRowModel());
    // Get the row element using the rowIndex
    const rowElement = document.querySelector(`table`);
    console.log("rowElement", rowElement);
    if (rowElement) {
      // Create a new KeyboardEvent with the "Enter" key
      const event = new KeyboardEvent("keydown", {
        key: "Enter",
        bubbles: true,
        cancelable: true,
      });

      // Dispatch the event on the row element
      rowElement.dispatchEvent(event);
    }
  };

  const table = useMantineReactTable({
    data: GeneralData ?? [],
    columns,
    mantineTableProps: { withColumnBorders: true },
    // enableFilters: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableTopToolbar: false,
    enablePagination: true,
    // paginationDisplayMode: "default",
    mantinePaginationProps: {
      showRowsPerPage: false,
      // withEdges: true,
      // rowsPerPageOptions: ['5','10', '15', '20', '25','30','50','100'],
    },
    onPaginationChange:setPagination,
    enableColumnActions: false,
    enableSorting: false,
    enableGlobalFilter: true,
    enableGlobalFilterModes: true,
    enableColumnFilters: false,
    enableColumnDragging: false,
    initialState : {
      pagination,
      // globalFilter : search,
    },
    state: {
      pagination,
      density: "4px",
      globalFilter: search,
      rowSelection
    },
    mantineTableBodyRowProps: ({ row, staticRowIndex }) => ({
      onClick: () => {
        setRowSelection((prev) => ({
          [row.id]: !prev[row.id],
        }));
      },
      onDoubleClick: () => {
        Promise.resolve(
          setRowSelection((prev) => ({
            [row.id]: true,
          }))
        ).then(() => {
          simulateEnterKeyPress();
        })
      },
      selected: rowSelection[row.id],
      sx: { cursor: "pointer" },
    }),
    renderBottomToolbar: ({ table }) => (
      <Box>
        <Grid>
          <Grid.Col span={12}>
            <ButtonPanel
              // tableRef={TableRef}
              data={TMFormatData?.oS60?.DTHKey}
              // Skey={tabled.getSelectedRowModel().flatRows[0]}
              from={obj?.id}
              btnPnlObj={obj}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <Group
              spacing={"xs"}
              position="right"
              display={"flex"}
            >
              <MRT_TablePagination position="bottom" table={table} />
            </Group>
          </Grid.Col>
        </Grid>
      </Box>
    )
  });

  return (
    <>
      {
        isLoading == true ? GlobalClass.RenderLoader("dots") :
          <Grid>
            <Grid.Col span={12} m={0} p={0}>
              <Grid.Col span={8} pl={1}>
                <PLTextBox value={search}
                  setEdit={(e) => {
                    setSearch(e.target.value)
                  }}
                />
              </ Grid.Col>
            </Grid.Col>
            <Grid.Col span={12} m={0} p={0}>
              <MantineReactTable table={table} />
            </Grid.Col>

          </Grid>
      }
    </>
  )

}

export default MailingLetter