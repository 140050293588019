import { Alert, Button, Card, Center, Modal, Stack, Text } from '@mantine/core'
import { IconInfoCircle } from '@tabler/icons-react'
import React, { useState } from 'react'
import BackUpForm from '../../Utility/SystemUtility/BackUpForm';
import RestoreData from './RestoreData';
import { modals } from '@mantine/modals';
import Store from '../../utils/store';
import { ModalDelete, ModalFunction } from '../../utils/slices/ModalSlice';
import { GetUserList } from '../../utils/slices/UserListSlice';

const UtilityCompany = (props) => {
    let dra = {
        title: "",
        size: "md",
        open: false,
        onClickCloseBtn: () => { },
    };
    const [DrawerObj, setDrawerObj] = useState(dra);
    const handleBackup = () => {
        // setDrawerObj({
        //     ...dra,
        //     title: <Text fw={700}>Backup</Text>,
        //     body: (<BackUpForm companyList={true} />),
        //     open: true,
        //     size: "xl",
        //     position: "right",
        //     trapFocus:false
        // })
        Store.dispatch(ModalFunction({
            MTitle: <Text fw={700}>Backup</Text>,
            MAction: true,
            MSize:"xl",
            MBody: (i) => (<BackUpForm companyList={true} index={i} />),
            onclose: () => { },
            onClickCloseButton: () => {
            }
        }))
    }

    const handleRestore = () => {
        setDrawerObj({
            ...dra,
            title: <Text fw={700}>Restore</Text>,
            body: (<RestoreData
                DrawerObj={DrawerObj}
                setDrawerObj={(e) => {
                    // setTimeout(() => {
                    Promise.resolve(setDrawerObj(e))
                        .then(() => {
                            Store.dispatch(ModalDelete(props.index))
                        })
                    // }, 500)
                }}
            />),
            open: true,
            size: "xl",
            position: "right"
        })
    }
    return (
        <>
            <Modal
                opened={DrawerObj?.open}
                // onClose={close} 
                withCloseButton={true}
                fullScreen={false}
                closeButtonProps={{
                    onClick: (e) => {
                        if (typeof DrawerObj?.onClickCloseButton == "function") {
                            DrawerObj?.onClickCloseButton();
                        } else {
                            setDrawerObj(dra);
                        }
                    },
                }}
                title={DrawerObj.title}
                size={DrawerObj.size}
                // centered={.Centered}
                closeOnClickOutside={false}
                // overlayProps={m.Overlay}
                position={DrawerObj?.position ?? "bottom"}
                onClose={
                    typeof DrawerObj?.onclose == "function"
                        ? DrawerObj?.onclose
                        : () => {
                            setDrawerObj(dra);
                        }
                }
            >
                {DrawerObj.body}
            </Modal>
            <Center style={{ minWidth: '100vh' }}>
                <Stack spacing="lg" align="center" style={{ width: '100%', maxWidth: '600px' }}>
                    <Card shadow="md" radius="md" padding="xl" style={{ width: '100%' }} withBorder>
                        <Stack spacing="md">
                            <Button
                                variant='outline'
                                onClick={handleBackup}
                            >
                                Backup
                            </Button>
                            <Button
                                variant='outline'
                                onClick={handleRestore}
                            >
                                Restore
                            </Button>
                            <Button
                                variant='outline'
                            >
                                Data Integrity
                            </Button>
                            <Button
                                variant='outline'
                            >
                                Company Option
                            </Button>
                            <Button
                                variant='outline'
                            >
                                System Info
                            </Button>
                        </Stack>
                    </Card>
                </Stack>
            </Center>
        </>
    )
}

export default UtilityCompany