import { createSlice } from '@reduxjs/toolkit'

const RefSlice = createSlice({
    name: "reference",
    initialState: {
        TabRef: null,
    },
    reducers: {
        TabReference: (state, action) => {
            state.TabRef = action.payload
        }
    }
});

export const { TabReference } = RefSlice.actions

export default RefSlice.reducer