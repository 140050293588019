import { Text } from "@mantine/core";
import * as AccountLedgerProductLedgerForm from "../ReportComponent/Account Books/LedGer/AccLedger";
import SingleLedgerVoucherListPopup from "../ReportComponent/VoucherListPopup/SingleLedgerVoucherListPopup";
import { ModalFunction } from "../utils/slices/ModalSlice";
import Store from "../utils/store";

export const ASLEDGER = (itemOBJ) => {
    const OBJ = itemOBJ;
    console.log('executeFunction ASLEDGER ===>', OBJ);
    switch (OBJ?.cSTID) {
        case "ASLEDGER":
        case "PRODLEDGER":
            AccountLedgerProductLedgerForm.TM_ZINOUT(JSON.stringify(OBJ))
            break;
        // case "PRODLABEL": // Master > Other info > Product Label
        //     ProductMaster.M21AED(JSON.stringify(OBJ))
        //     break
        default:
            // AccountLedgerProductLedgerTab.TM_ZINOUT(JSON.stringify(OBJ))
            // AccountLedgerProductLedgerForm.TM_ZINOUT(JSON.stringify(OBJ))

            break;
    }

}

export const ASLAED =(itemOBJ)=>{
    const OBJ = itemOBJ;
    console.log('executeFunction ASLAED ===>', OBJ);

    switch (OBJ?.cSTID) {
        case "ASLEDGER":
            Store.dispatch(ModalFunction({
                MTitle: <Text fw={700}>Voucher Type</Text>,
                MAction: true,
                MSize: "50%",
                // position:'right',
                fullScreen: false,
                // MBody: (i) => (<TransactionSalesForm obj={OBJ} index={i} />),
                MBody: (i) => (<SingleLedgerVoucherListPopup obj={OBJ} index={i} />),
                MClose: true,
                // tab: true,
                obj: OBJ,
                onclose: () => { },
                onClickCloseButton: () => {
                }
            }))
            break;
        default:
            break;
    }
}

export const PROLED =(itemOBJ)=>{
    const OBJ = itemOBJ;
    console.log('executeFunction PROLED ===>', OBJ);

    switch (OBJ?.cSTID) {
        case "PRODLEDGER":
            Store.dispatch(ModalFunction({
                MTitle: <Text fw={700}>Voucher Type</Text>,
                MAction: true,
                MSize: "50%",
                // position:'right',
                fullScreen: false,
                // MBody: (i) => (<TransactionSalesForm obj={OBJ} index={i} />),
                MBody: (i) => (<SingleLedgerVoucherListPopup obj={OBJ} index={i} />),
                MClose: true,
                // tab: true,
                obj: OBJ,
                onclose: () => { },
                onClickCloseButton: () => {
                }
            }))
            break;
        default:
            break;
    }
}