import React, { useState, useEffect, useRef } from "react";
import Store from "../../utils/store";
import { ModalDelete, ModalFunction } from "../../utils/slices/ModalSlice";
import {
  Button,
  Card,
  Center,
  Checkbox,
  FileButton,
  Grid,
  Group,
  Modal,
  PasswordInput,
  Text,
  Textarea,
} from "@mantine/core";
import PartyMaster from "../../Transaction/MasterControl/PartyMaster";
import { GetAccountGroup } from "../../utils/slices/AccountGroupSlice";
import PLComboBox from "../../PlusComponent/PLComboBox";
import PLTextBox from "../../PlusComponent/PLTextBox";
import PLNumberBox from "../../PlusComponent/PLNumberBox";
import { modals } from "@mantine/modals";
import { IconFile, IconMail } from "@tabler/icons-react";
import PLDataGrid from "../../PlusComponent/PLDataGrid";
import { useSelector, useDispatch } from "react-redux";
import AccountListSlice, {
  GetAccountList, GetTMFormat, SetAccountListData
} from "../../utils/slices/AccountListSlice";
import { GetPaginationObj } from "../../utils/slices/DataSaveLoadingSlice";
import { GetM01List } from "../../utils/slices/M01FormSlice";
import PrintSlice, {
  GetAccountListForPrint,
  GetPrintEntData,
} from "../../utils/PrintSlice/PrintSlice";
import ReportViewer from "../../components/ReportViewer";
import { ProductListAPi } from "../../utils/slices/ProductList";
import GlobalClass from "../../utils/GlobalClass";
import { GetAddCmpEnt } from "../../utils/slices/UserListSlice";
import gensetting from "../../utils/gensetting";
import { saveAs } from "file-saver";
import autoTable from "jspdf-autotable";
import jsPDF from "jspdf";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { createColumnHelper } from "@tanstack/react-table";
import useWindowDimensions from "../../utils/UseWindowDimensions";
const PLPrint = (props) => {
  // console.log("PLPrintprops", props);

  const { height, width } = useWindowDimensions();
  const isExecuted = useRef(false);

  const pid = props?.data?.BtnPnlObj?.id;
  // console.log("PID",pid)
  const [selectedAccNames, setSelectedAccNames] = useState([]);
  const [mode, setMode] = useState(false);
  const [handleOkClicked, setHandleOkClicked] = useState(false);
  const [showAdvance, setShowAdvance] = useState(false);
  const [groupSelected, setGroupSelected] = useState(false);
  const [areaSelected, setAreaSelected] = useState(false);
  const [citySelected, setCitySelected] = useState(false);
  const [accountSelected, setAccountSelected] = useState(false);
  const [categorySelected, setCategorySelected] = useState(false);
  const [selectedOfGroup, setSelectedOfGroup] = useState("A");
  const [selectedOfArea, setSelectedOfArea] = useState("A");
  const [selectedOfCity, setSelectedOfCity] = useState("A");
  const [selectedOfAccount, setSelectedOfAccount] = useState("A");
  const [selectedOfCategory, setSelectedOfCategory] = useState("A");
  const [isAreaSelected, setIsAreaSelected] = useState(false);
  const [isCitySelected, setIsCitySelected] = useState(false);
  const [isGroupSelected, setIsGroupSelected] = useState(false);
  const [isCategorySelected, setIsCategorySelected] = useState(false);
  const [isAccountSelected, setIsAccountSelected] = useState(false);
  const [cfmtid, setcfmtid] = useState("");
  const [groupData, setGroupData] = useState([]);
  const [groupData1, setGroupData1] = useState([]);
  const [groupData2, setGroupData2] = useState([]);
  const [areaData, setAreaData] = useState([]);
  const [areaData1, setAreaData1] = useState([]);
  const [areaData2, setAreaData2] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [cityData1, setCityData1] = useState([]);
  const [cityData2, setCityData2] = useState([]);
  const [accountData, setAccountData] = useState([]);
  const [accountData1, setAccountData1] = useState([]);
  const [accountData2, setAccountData2] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [categoryData1, setCategoryData1] = useState([]);
  const [categoryData2, setCategoryData2] = useState([]);
  const [waitForFilter, setWaitForFilter] = useState(true);
  const [TmFormatData, setTmFormatData] = useState();
  const [PDFLoader, setPDFLoader] = useState(false)

  // console.log("TMFormatData",TmFormatData)
  const [pageRange, setPageRange] = useState({
    value: "A",
    Label: "All Pages",
  });
  const [line, setLine] = useState({ value: "N", Label: "None" });
  const [verticalLine, setVerticalLine] = useState({
    value: "N",
    Label: "None",
  });
  const [companyNameOn, setCompanyNameOn] = useState({
    value: "3",
    Label: "All Pages",
  });
  const [reportHeaderOn, setReportHeaderOn] = useState({
    value: "3",
    Label: "All Pages",
  });
  const [footerOn, setFooterOn] = useState({ value: "3", Label: "All Pages" });
  const [isLoading1, setIsLoading1] = useState(false);
  const [AdvanceFormObj, setAdvanceFormObj] = useState({
    "nReportOn": 0,
    "cFileName": null,
    "nNoOfColumn": 0,
    "lTopToBotton": false,
    "cRepFontName": "",
    "nRepFontSize": 0,
    "nPaper": 0,
    "nLeftMargin": 5,
    "nTopMargin": 0,
    "nCNameOn": 3,
    "nRHeaderOn": 3,
    "cReportTitle": "",
    "cReportFooter": "",
    "nRFooterOn": 3,
    "lprintHeader": false,
    "cPaperSize": "",
    "PrintMode": "W",
    "HLine": "N",
    "VLine": "N"
  });

  const [AdvanceFormObjShow, setAdvanceFormObjShow] = useState({
    "StartPage": "1",
    "EndPage": "1",
    "PageRange": "1",
    "StartingPageNo": "1"
  })

  const [ReportOn, setReportOn] = useState("s");
  let defaultprintDetails = {
    type: "PDF",
    filename: "",
    Copies: 1,
    password: "",
    printer: "",
    email: [],
    mobile: [],
    isPassword: false,
  };
  const [printDetails, setPrintDetails] = useState({ ...defaultprintDetails });
  let dra = {
    title: "",
    size: "md",
    open: false,
    body: "",
    onClickCloseBtn: () => { },
  };
  const [DrawerObj, setDrawerObj] = useState(dra);
  const [modalClosed, setModalClosed] = useState();
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 25,
  });
  const [selectedRow, setSelectedRow] = useState();
  const [rowSelection, setRowSelection] = useState({});


  const dispatch = useDispatch();

  const partyData = [
    { dispexpr: "All", valexpr: "A" },
    {
      dispexpr: "Selected",
      valexpr: "S",
    },
  ];

  const M01FormData = useSelector((state) => state?.M01FormSlice?.M01FormData);
  const M01FormLoading = useSelector((state) => state?.M01FormSlice?.isLoading);
  const ProductListData = useSelector(
    (store) => store?.ProductList?.ProductListData
  );
  // console.log("productListData",ProductListData)
  const isLoading = useSelector((state) => state?.PrintList?.isLoading);
  const hasError = useSelector((state) => state?.PrintList?.hasError);
  const ErrorMsg = useSelector((state) => state?.PrintList?.ErrorMsg);

  const TmFormat = useSelector(
    (state) => state?.AccountList?.TMFormatData
  );

  const { oYear, _NAME } = useSelector((state) => state?.Language?.General);
  // console.log("oYear", oYear)
  const GeneralData = useSelector((state) => state?.Language?.General);

  useEffect(() => {
    if (GeneralData) {
      console.log("GeneralData=>", GeneralData);
    }
  }, [GeneralData])


  const TmFormat1 = useSelector((state) =>
    state?.AccountList?.TMFormatData?.[`frmt_${pid}`]
  )
  // console.log("TmFormat",TmFormat)
  // const resultNames = useSelector((state) => state?.AccountList?.AccountListData?.[pid]);
  // const PrintEntData = useSelector((state) => state?.AccountList?.PrintEntData);

  const PrintEntData = useSelector((state) => state?.PrintList?.PrintEntData);


  const { oPDA, CBOPRN, CBOPPR, CBOPMD, CBOF21, CBONMON } = useSelector((state) => state?.PrintList?.PrintEntData);

  const { PrintEntDataisLoading } = useSelector((state) => state?.PrintList);

  const {
    hasError: AccountListhasError,
    ErrorMsg: AccountListErrorMsg,
    isLoading: AccountListisLoading,
    AccountListData,
    TMFormatData
  } = useSelector((state) => state.AccountList);

  const GetGeneral = useSelector((state) => state.Language.General);

  // useEffect(()=>{
  //   if(PrintEntData){
  //     console.log("PrintEntData=>",PrintEntData);
  //   }

  // },[PrintEntData])

  const getcWhereForOk = () => {
    // console.log("getcWhereForOk")
    const formatInClause = (data) => {
      if (data) {
        return `IN ('${data.split(',').join("', '")}')`;
      }
      return '';
    };

    let str = "";
    str = pid == "T_M01" ? `M01.FIELD01 ${formatInClause(accountData1)}` : `M21.FIELD01 ${formatInClause(accountData1)}`

    return str;
  }

  const getcWhere = () => {
    // console.log("areaData1", areaData1);

    // console.log("getcWhere")
    // Helper function to convert a comma-separated string into the desired format
    const formatInClause = (data) => {
      if (data) {
        return `IN ('${data.split(',').join("', '")}')`;
      }
      return '';
    };
    // AND ${M01FormData?.P_M01I?.split("~C~")[2]}
    let str = "";
    str = pid == "T_M01" ? isGroupSelected && isAreaSelected && isCitySelected
      ? `M01.FIELD05 ${formatInClause(groupData1)} AND M01.FIELD18 ${formatInClause(areaData1)} AND M01.FIELD16 ${formatInClause(cityData1)} `
      : !isGroupSelected && isAreaSelected && isCitySelected
        ? `M01.FIELD18 ${formatInClause(areaData1)} AND M01.FIELD16 ${formatInClause(cityData1)} `
        : isGroupSelected && !isAreaSelected && isCitySelected
          ? `M01.FIELD05 ${formatInClause(groupData1)} AND M01.FIELD16 ${formatInClause(cityData1)} `
          : isGroupSelected && isAreaSelected && !isCitySelected
            ? `M01.FIELD05 ${formatInClause(groupData1)} AND M01.FIELD18 ${formatInClause(areaData1)} `
            : !isGroupSelected && !isAreaSelected && isCitySelected
              ? `M01.FIELD16 ${formatInClause(cityData1)} `
              : !isGroupSelected && isAreaSelected && !isCitySelected
                ? `M01.FIELD18 ${formatInClause(areaData1)} `
                : isGroupSelected && !isAreaSelected && !isCitySelected
                  ? `M01.FIELD05 ${formatInClause(groupData1)} `
                  : ``
      : isGroupSelected && isCategorySelected
        ? `M21.FIELD11 ${formatInClause(groupData1)} AND M21.FIELD12 ${formatInClause(categoryData1)} `
        : isGroupSelected && !isCategorySelected
          ? `M21.FIELD11 ${formatInClause(groupData1)}`
          : !isGroupSelected && isCategorySelected
            ? `M21.FIELD12 ${formatInClause(categoryData1)}`
            : ``
      ;

    return str;
  };


  // useEffect(() => {
  //   if (isLoading == true) {
  //     setIsLoading1(false);
  //   }
  // }, [isLoading]);
  // useEffect(()=>{
  //   console.log("TmFormat",TmFormat?.oDCFrmt)
  //   Promise.resolve().then(()=>{
  //     setTmFormatData(TmFormat);
  //   })
  // },[TmFormat])

  // useEffect(() => {
  //   if (accountSelected == true) {
  //     let resData = [];
  //     Promise.resolve(
  //       (resData = Store.getState()?.AccountList?.AccountListData[pid])
  //     ).then(() => {
  //       if (isGroupSelected) {
  //         if (pid == "T_M01") {
  //           let filteredArray = [];

  //           for (let i = 0; i < groupData?.length; i++) {
  //             for (let j = 0; j < resData?.length; j++) {
  //               if (groupData[i]?.FIELD01 == resData[j]?.FIELD05) {
  //                 filteredArray?.push(resData[j]);
  //               }
  //             }
  //           }
  //           resData = filteredArray;
  //         } else if (pid == "T_M21") {
  //           let filteredArray = [];

  //           for (let i = 0; i < groupData?.length; i++) {
  //             for (let j = 0; j < resData?.length; j++) {
  //               if (groupData[i]?.FIELD01 == resData[j]?.FIELD11) {
  //                 filteredArray?.push(resData[j]);
  //               }
  //             }
  //           }
  //           resData = filteredArray;
  //         }
  //       }
  //       if (isCategorySelected) {
  //         let filteredArray = [];

  //         for (let i = 0; i < categoryData?.length; i++) {
  //           for (let j = 0; j < resData?.length; j++) {
  //             if (categoryData[i]?.FIELD01 == resData[j]?.FIELD12) {
  //               filteredArray?.push(resData[j]);
  //             }
  //           }
  //         }
  //         resData = filteredArray;
  //       }
  //       if (isAreaSelected) {
  //         let filteredArray = [];

  //         for (let i = 0; i < areaData?.length; i++) {
  //           for (let j = 0; j < resData?.length; j++) {
  //             if (areaData[i]?.FIELD01 == resData[j]?.FIELD18) {
  //               filteredArray?.push(resData[j]);
  //             }
  //           }
  //         }
  //         resData = filteredArray;
  //       }
  //       if (isCitySelected) {
  //         let filteredArray = [];

  //         for (let i = 0; i < cityData?.length; i++) {
  //           for (let j = 0; j < resData?.length; j++) {
  //             if (cityData[i]?.FIELD01 == resData[j]?.FIELD16) {
  //               filteredArray?.push(resData[j]);
  //             }
  //           }
  //         }

  //         resData = filteredArray;
  //       }
  //       setWaitForFilter(false);
  //       setSelectedAccNames(
  //         resData?.length == 0
  //           ? isCategorySelected ||
  //             isAreaSelected ||
  //             isGroupSelected ||
  //             isCitySelected
  //             ? resData
  //             : resultNames
  //           : resData
  //       );
  //     });
  //   }
  // }, [accountSelected]);

  useEffect(() => {
    var newobj = { ...props?.data?.BtnPnlObj, id: `frmt_${pid}` }
    Promise.resolve(
      dispatch(
        GetTMFormat({
          ...newobj,
          StrtDt: GetGeneral?.dYSDate,
          EndDt: GetGeneral?.dYEDate,
        })
      )
    ).then(() => {
      // dispatch(GetAccountListForPrint(props?.data?.BtnPnlObj));
      if (pid == "T_M01") {
        dispatch(GetM01List({ OBJ: "A" }));
      } else if (pid == "T_M21") {
        dispatch(ProductListAPi(props?.data?.BtnPnlObj));
      }
    }).then(() => {
      // if (!isLoading) {
      dispatch(GetPrintEntData())
      // }
    });
  }, []);

  useEffect(() => {
    if (oPDA) {
      setAdvanceFormObj(oPDA)
      // console.log("PrintEntData oPDA=>",oPDA)
      // console.log("PrintEntData=>",PrintEntData)
    }
  }, [oPDA])

  useEffect(() => {
    if (hasError && !isLoading) {
      GlobalClass.Notify("error", "Error: " + ErrorMsg.code, ErrorMsg.message);
    }
  }, [hasError]);

  // useEffect(() => {
  //   // setAreaData(M01FormData?.P_M31ADT?.jData);
  //   // setCityData(M01FormData?.P_M31TDT?.jData);
  //   // setGroupData(
  //   //   pid == "T_M01"
  //   //     ? M01FormData?.P_M11EDT?.jData
  //   //     : ProductListData?.P_M32GDT?.jData
  //   // );
  //   // setAccountData(resultNames);
  //   // setSelectedAccNames(resultNames);
  //   // setCategoryData(ProductListData?.P_M32CDT?.jData);
  // }, [resultNames]);

  // useEffect(() => {
  //   if (handleOkClicked === true) {
  //     var newobj = { ...props?.data?.BtnPnlObj, p2: cfmtid,id:`print_${pid}`};
  //     Promise.resolve(
  //       dispatch(
  //         GetTMFormat({
  //           ...newobj,
  //           StrtDt: GetGeneral?.dYSDate,
  //           EndDt: GetGeneral?.dYEDate,
  //         })
  //       )
  //     ).then(()=>{
  //       dispatch(
  //         GetAccountList({
  //           ...newobj,
  //           StrtDt: GetGeneral?.dYSDate,
  //           EndDt: GetGeneral?.dYEDate,
  //           cWhere: isAccountSelected ? getcWhereForOk() : getcWhere()
  //         })
  //       )
  //     })
  //     .then((data) => {
  //       if (
  //         data?.meta?.requestStatus == "fulfilled" &&
  //         data?.payload?.error == undefined
  //       ) {
  //         console.log("TmFormatData?.oDCFrmt",TmFormat?.oDCFrmt)
  //         let Adv = {...AdvanceFormObj,...AdvanceFormObjShow}
  //         Store.dispatch(
  //           ModalFunction({
  //             onclose: () => { },
  //             MTitle: <Text fw={700}>{props?.data?.BtnPnlObj?.name}</Text>,
  //             MAction: true,
  //             MSize: "70%",
  //             MBody: (i) => (
  //               <ReportViewer
  //                 title={props?.data?.BtnPnlObj?.name}
  //                 aDcol={TmFormat?.oDCFrmt?.aDCol}
  //                 aDGrp={TmFormat?.oDCFrmt?.aDGrp}
  //                 data={isAccountSelected ? accountData : selectedAccNames}
  //                 AdvanceFormData={Adv}
  //               />
  //             ),
  //             MClose: true,
  //             Overlay: {
  //               backgroundOpacity: 0.8,
  //               blur: 5,
  //             },
  //           })
  //         );

  //         dispatch(ModalDelete(props?.index));
  //       } else {
  //         GlobalClass.Notify(
  //           "error",
  //           data?.payload?.error,
  //           data?.payload?.error
  //         );
  //         return;
  //       }
  //     });
  //   }
  // }, [handleOkClicked]);

  const modeSwitchHandler = () => {
    setMode((prev) => !prev);
  };
  const advanceController = () => {
    setShowAdvance((prev) => !prev);
  };
  const handleOk = async () => {
    // let resData = [];
    // let CmpNumber = sessionStorage.getItem("cmp")
    // console.log("Advance Obj=>", AdvanceFormObj);
    // let FormData = { ...AdvanceFormObj, ...AdvanceFormObjShow, ...GlobalClass?.setupConfig }
    setPDFLoader(true)
    isExecuted.current = false;
    Promise.resolve(dispatch(
      GetAddCmpEnt({ cmpNo: localStorage.getItem("cmp"), action: "E" })
    )).then(() => {
      var newobj = { ...props?.data?.BtnPnlObj, p2: cfmtid, id: `print_${pid}` };
      Promise.resolve(
        dispatch(
          GetTMFormat({
            ...newobj,
            StrtDt: GetGeneral?.dYSDate,
            EndDt: GetGeneral?.dYEDate,
          })
        )
      ).then(() => {
        dispatch(
          GetAccountList({
            ...newobj,
            StrtDt: GetGeneral?.dYSDate,
            EndDt: GetGeneral?.dYEDate,
            cWhere: isAccountSelected ? getcWhereForOk() : getcWhere()
          })
        )
      })
      // .then((data) => {
      //   if (
      //     !Store?.getState().AccountList?.AccountListData?.hasError
      //   ) {

      //     // console.log("Store?.getState().AccountList?.AccountListData?.[`print_${pid}`]?.jData",Store?.getState().AccountList?.AccountListData?.[`print_${pid}`])
      //     let tm = Store?.getState().AccountList?.TMFormatData?.[`print_${pid}`];
      //     console.log("TmFormatData?.oDCFrmt", tm)
      //     let cols = [];
      //     let groupColumnIDs = [];
      //     tm?.oDCFrmt?.aDCol?.map((a, i) => {
      //       if (a?.ColWidth > 0) {
      //         cols.push({ header: a?.ColCap, dataField: a?.DispExpr, width: a?.ColWidth });

      //       }
      //     });
      //     tm?.oDCFrmt?.aDGrp?.map((a, i) => {
      //       groupColumnIDs.push(a?.DispExpr);
      //     });
      //     let companyInfo = {
      //       "name": _NAME,
      //       "address": oYear?.OSC?._ADDRESS_1,
      //       "contact": oYear?.OSC?._MOBILE1,
      //       "gst": oYear?.OSC?._16,
      //       "rh1": GetGeneral?._RHEAD1,
      //       "rh2": GetGeneral?._RHEAD2,
      //       "rf1": GetGeneral?._RHEAD3,
      //       "rf2": GetGeneral?._RHEAD4
      //     }
      //     const blob = GlobalClass.gridToPDF(Store?.getState().AccountList?.AccountListData?.[`print_${pid}`], cols, groupColumnIDs, companyInfo, FormData, `print_${pid}`, props)
      //     console.log("blob", blob)

      //     saveAs(blob, "demo.pdf");

      //     // dispatch(ModalDelete(props?.index));
      //   } else {
      //     GlobalClass.Notify(
      //       "error",
      //       data?.payload?.error,
      //       data?.payload?.error
      //     );
      //     return;
      //   }
      // });
    }
    )
    // setIsLoading1(true);
  };

  useEffect(() => {
    if (AccountListData?.[`print_${pid}`] && TMFormatData?.[`print_${pid}`]) {
      if (!isExecuted.current) {
        console.log("AccountListDataTMFormatData =>", [{
          AccountListData: AccountListData?.[`print_${pid}`],
          TMFormatData: TMFormatData?.[`print_${pid}`]
        }]);
        // console.log("Store?.getState().AccountList?.AccountListData?.[`print_${pid}`]?.jData",Store?.getState().AccountList?.AccountListData?.[`print_${pid}`])
        let FormData = { ...AdvanceFormObj, ...AdvanceFormObjShow, ...GlobalClass?.setupConfig }
        let tm = TMFormatData?.[`print_${pid}`];
        console.log("TmFormatData?.oDCFrmt", tm)
        let cols = [];
        let groupColumnIDs = [];
        tm?.oDCFrmt?.aDCol?.map((a, i) => {
          if (a?.ColWidth > 0) {
            cols.push({ header: a?.ColCap, dataField: a?.DispExpr, width: a?.ColWidth });
          }
        });
        tm?.oDCFrmt?.aDGrp?.map((a, i) => {
          groupColumnIDs.push(a?.DispExpr);
        });
        let companyInfo = {
          "name": _NAME,
          "address": oYear?.OSC?._ADDRESS_1,
          "contact": oYear?.OSC?._MOBILE1,
          "gst": oYear?.OSC?._16,
          "rh1": GetGeneral?._RHEAD1,
          "rh2": GetGeneral?._RHEAD2,
          "rf1": GetGeneral?._RHEAD3,
          "rf2": GetGeneral?._RHEAD4
        }
        const blob = GlobalClass.gridToPDF(AccountListData?.[`print_${pid}`], cols, groupColumnIDs, companyInfo, FormData, `print_${pid}`, props)
        console.log("blob", blob)
        setPDFLoader(false)
        handlePrinting(blob)

        isExecuted.current = true;
      }
    }
  }, [AccountListData, TMFormatData])

  const handlePrinting = (blob) => {
    console.log("handlePrintingPara =>", blob);
    switch (ReportOn) {
      case "s":
        const pdfBlobUrl = URL.createObjectURL(blob)
        setDrawerObj({
          ...DrawerObj,
          title: <Text fw={700}>{props?.data?.BtnPnlObj?.name}</Text>,
          open: true,
          size: "100%",
          position: "right",
          body: <>
            <iframe
              src={pdfBlobUrl}
              width={width * 0.97}
              height={height * 0.87}
              title="PDF Preview"
            />
          </>
        })
        break;
      case "f":
        saveAs(blob, `${props?.data?.BtnPnlObj?.name}.pdf`)
        break;
      case "w":
        break;
      case "e":
        break;
      case "p":
        try {
          // Create a URL for the blob
          const blobUrl = URL.createObjectURL(blob);

          // Open the PDF in a new tab
          const newWindow = window.open(blobUrl);

          // Wait for the new window to load the PDF before triggering print
          if (newWindow) {
            newWindow.addEventListener("load", () => {
              newWindow.focus();
              newWindow.print();
            });
          }

          // Revoke the object URL after printing
          newWindow.addEventListener("beforeunload", () => {
            URL.revokeObjectURL(blobUrl);
            newWindow.removeEventListener("load");
            newWindow.removeEventListener("beforeunload");
          });
        } catch (e) {
          console.log("window Error", e);

          GlobalClass.Notify("warning", "Error Occured");
        }
      default:
        break;
    }
  }

  useEffect(() => {
    if (AccountListhasError && !AccountListisLoading?.[`print_${pid}`]) {
      GlobalClass.Notify("error", "Error: " + AccountListErrorMsg.code, AccountListErrorMsg.message);
    }
  }, [AccountListhasError]);
  const columnHelper = createColumnHelper();

  useEffect(() => {
    let column = [];
    column?.push(
      columnHelper.accessor("FIELD61", {
        header: "Party Name",
        size: 100,
        enableEditing: false,
      })
    );
    column?.push(
      columnHelper.accessor("FIELD05", {
        header: "City",
        size: 100,
        enableEditing: false,
      })
    );
    if (ReportOn == "e") {
      column?.push(
        columnHelper.accessor("FIELD43", {
          header: "email id",
          size: 100,
          enableEditing: false,
        })
      );
    } else if (ReportOn == "w") {
      column?.push(
        columnHelper.accessor("FIELD35", {
          header: "Mobile",
          size: 100,
          enableEditing: false,
        })
      );
    }

    setColumns(column);
  }, [data, ReportOn]);

  const customPageHandler = () => {
    modals.open({
      title: <Text fw={700}>Add Custom Page</Text>,
      zIndex: 300,
      size: "sm",
      children: (
        <>
          <Text>Enter Report Page Height & Width in Inch</Text>
          <Center>
            <Grid
              gutter={4}
              style={{
                display: "flex",
                flexDirection: "column",
                fontSize: "14px",
                marginTop: "7px",
                alignContent: "center",
              }}
            >
              <Grid.Col
                span={12}
                style={{ display: "flex", flexDirection: "row" }}
              >
                <Grid.Col span={2}></Grid.Col>
                <Grid.Col span={3}>Height</Grid.Col>
                <Grid.Col span={4}>
                  <PLNumberBox />
                </Grid.Col>
              </Grid.Col>
              <Grid.Col
                span={12}
                style={{ display: "flex", flexDirection: "row" }}
              >
                <Grid.Col span={2}></Grid.Col>
                <Grid.Col span={3}>Width</Grid.Col>
                <Grid.Col span={4}>
                  <PLNumberBox />
                </Grid.Col>
              </Grid.Col>
            </Grid>
          </Center>
          <Center style={{ marginTop: "10px" }}>
            <Button style={{ width: "100px" }}>Ok</Button>
          </Center>
        </>
      ),
    });
  };

  const bookDataCalled = () => {
    // setbookDataCalledState(true);
    dispatch(
      GetAccountList({
        id: "P_M02",
        name: "",
        p0: "P_M02",
        p1: "P_M02",
        p2: "",
        p3: "",
        type: "A",
      })
    );
    setDrawerObj({
      ...DrawerObj,
      title: <Text fw={700}>Address Book</Text>,
      open: true,
      size: "70%",
      position: "right"
    });
  };

  // console.log("AdvanceFormObj=>",AdvanceFormObj);

  const renderExtraForm = (r) => {
    return (
      <Grid>
        {["f", "w", "e"].includes(r) && (
          <Grid.Col span={12}>
            <Card shadow="sm" padding="lg" radius="md" withBorder>
              <Grid>
                <Grid.Col span={3}>Type</Grid.Col>
                <Grid.Col span={9}>
                  <PLComboBox
                    dispexpr={"value"}
                    valexpr={"value"}
                    value={printDetails.type}
                    setEdit={(e) => {
                      setPrintDetails({ ...printDetails, type: e });
                    }}
                    data={[
                      { value: "PDF" },
                      { value: "Word" },
                      { value: "Excel" },
                      { value: "HTML" },
                      { value: "XML" },
                      { value: "PowerPoint" },
                      { value: "CSV" },
                    ]}
                  />
                </Grid.Col>
                {/* <Grid.Col span={3}></Grid.Col> */}
              </Grid>
              {["w", "e"].includes(r) && (
                <Grid>
                  <Grid.Col span={3}>
                    {r == "w" ? "Whatsapp Number" : "Email Address"}
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <PLTextBox
                      value={
                        r == "w" ? printDetails.mobile : printDetails.email
                      }
                      setEdit={(e) => {
                        r == "w"
                          ? setPrintDetails({
                            ...printDetails,
                            mobile: e.target.value,
                          })
                          : setPrintDetails({
                            ...printDetails,
                            email: e.target.value,
                          });
                      }}
                    />
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <Button compact onClick={bookDataCalled}>
                      {r == "w" ? "Phone Book" : "eMail Book"}
                    </Button>
                  </Grid.Col>
                </Grid>
              )}
              <Grid>
                <Grid.Col span={3}>File Name</Grid.Col>
                <Grid.Col span={9}>
                  <PLTextBox
                    value={printDetails?.filename}
                    setEdit={(e) => {
                      console.log("filename", e.target.value);

                      setPrintDetails({
                        ...printDetails,
                        filename: e.target.value,
                      });
                    }}
                  />
                </Grid.Col>
                {/* <Grid.Col span={3}></Grid.Col> */}
              </Grid>
              <Grid>
                <Grid.Col span={3}>Copies</Grid.Col>
                <Grid.Col span={9}>
                  <PLTextBox
                    value={printDetails.Copies}
                    type="number"
                    setEdit={(e) => {
                      setPrintDetails({
                        ...printDetails,
                        Copies: e.target.value,
                      });
                    }}
                  />
                </Grid.Col>
                {/* <Grid.Col span={6}></Grid.Col> */}
              </Grid>
              {printDetails.type == "PDF" && (
                <Grid>
                  {" "}
                  <Grid.Col span={3}>Password</Grid.Col>
                  <Grid.Col span={1}>
                    <Checkbox
                      checked={printDetails.isPassword}
                      onChange={(e) => {
                        setPrintDetails({
                          ...printDetails,
                          isPassword: !printDetails.isPassword,
                        });
                      }}
                    />
                  </Grid.Col>
                  <Grid.Col span={8}>
                    <PLTextBox
                      value={printDetails.password}
                      disabled={!printDetails.isPassword}
                      setEdit={(e) => {
                        setPrintDetails({
                          ...printDetails,
                          password: e.target.value,
                        });
                      }}
                    />
                  </Grid.Col>
                </Grid>
              )}
            </Card>
          </Grid.Col>
        )}
      </Grid>
    );
  };

  const table = useMantineReactTable({
    columns,
    data: data ? data : [],
    mantineTableContainerProps: {
      sx: { maxHeight: "350px", minHeight: "300px" },
    },
    enableTopToolbar: false,
    enablePagination: true,
    enableRowSelection: true,
    enableMultiRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onPaginationChange: setPagination, //hoist pagination state to your state when it changes internally
    mantineTableBodyRowProps: ({ row, staticRowIndex }) => ({
      sx: { cursor: "pointer" },
    }),
    state: { pagination: pagination, showLoadingOverlay: AccountListisLoading?.P_M02, rowSelection: rowSelection },
    initialState: {
      density: "0px",
      pagination: pagination
    }
  });


  return (
    <>
      {
        (PrintEntDataisLoading || isLoading || M01FormLoading || PDFLoader) ? GlobalClass.RenderLoader("dots") :
          <>
            <Modal
              opened={DrawerObj?.open}
              // onClose={close}
              style={{ padding: 0 }}
              withCloseButton={true}
              fullScreen={true} //
              // overlayProps={{ opacity: 0.5, blur: 4 }}
              closeButtonProps={{
                onClick: (e) => {
                  setModalClosed(true);
                  if (typeof DrawerObj?.onClickCloseButton == "function") {
                    DrawerObj?.onClickCloseButton();
                  } else {
                    setDrawerObj(dra);
                  }
                },
              }}
              title={DrawerObj.title}
              // size={DrawerObj.size}
              // centered={.Centered}
              closeOnClickOutside={true}
              // overlayProps={m.Overlay}
              position={DrawerObj?.position ?? "bottom"}
              onClose={
                typeof DrawerObj?.onclose == "function"
                  ? DrawerObj?.onclose
                  : () => {
                    setDrawerObj(dra);
                  }
              }

            // scrollAreaComponent={ScrollArea.Autosize}
            >
              {
                DrawerObj?.body ? DrawerObj?.body :
                  <MantineReactTable table={table} />
              }
            </Modal>
            {!showAdvance ? (
              <Grid
                gutter={2}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  fontSize: "12px",
                }}
              >
                {pid == "T_M01" && (
                  <>
                    <Grid.Col
                      span={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 0,
                        maxHeight: "47px",
                      }}
                    >
                      <Grid.Col
                        span={6}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: 0,
                        }}
                      >
                        <Grid.Col style={{ display: "flex", flexDirection: "row" }}>
                          <Grid.Col span={3}>
                            <Text style={{ fontSize: 12 }}>Group Name</Text>
                          </Grid.Col>
                          <Grid.Col span={9}>
                            <PLComboBox
                              copno={"AS"}
                              value={selectedOfGroup}
                              dispexpr={"DisplayMember"}
                              valexpr={"ValueMember"}
                              setEdit={(e) => {
                                setSelectedOfGroup(e?.value);
                                setIsGroupSelected(false);
                                if (e?.value == "S") {
                                  setGroupSelected(true);
                                } else {
                                  setGroupSelected(false);
                                }
                              }}
                            />
                          </Grid.Col>
                        </Grid.Col>
                      </Grid.Col>
                      <Grid.Col
                        span={6}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: 0,
                        }}
                      >
                        <Grid.Col span={3}>
                          <Text style={{ fontSize: 12 }}>Area Name</Text>
                        </Grid.Col>
                        <Grid.Col span={9}>
                          <PLComboBox
                            copno={"AS"}
                            value={selectedOfArea}
                            dispexpr={"DisplayMember"}
                            valexpr={"ValueMember"}
                            setEdit={(e) => {
                              setSelectedOfArea(e?.value);
                              setIsAreaSelected(false);
                              if (e?.value == "S") {
                                setAreaSelected(true);
                              } else {
                                setAreaSelected(false);
                              }
                            }}
                          />
                        </Grid.Col>
                      </Grid.Col>
                    </Grid.Col>
                    <Grid.Col
                      span={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 0,
                      }}
                    >
                      <Grid.Col
                        span={6}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: 0,
                        }}
                      >
                        {groupSelected == true ? (
                          <Grid.Col
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Grid.Col span={3}>
                              <Text style={{ fontSize: 12 }}>
                                Select Group Names
                              </Text>
                            </Grid.Col>
                            <Grid.Col span={9}>
                              <PLDataGrid
                                width={"500"}
                                value={{ value: groupData1, label: groupData2 }}
                                setEdit={(e) => {
                                  let group = e?.map((v) => v?.original);
                                  setGroupData(group);
                                  let val2 = [];
                                  let val1 = [];
                                  if (e?.length > 0) {
                                    e?.map((m, i) => {
                                      val2.push(m?.original?.FIELD02);
                                      val1.push(m?.original?.FIELD01);
                                    });

                                   
                                    setIsGroupSelected(true);
                                  }else{
                                    setIsGroupSelected(false)
                                  }
                                  setGroupData1(val1?.join(","));
                                  setGroupData2(val2?.join(","));
                                }}
                                isMulti={true}
                                type={"Group_Name"}
                                TmData={M01FormData?.P_M11E}
                              // data={M01FormData?.P_M11EDT?.jData}
                              />
                            </Grid.Col>
                          </Grid.Col>
                        ) : (
                          ""
                        )}
                      </Grid.Col>
                      <Grid.Col
                        span={6}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: 0,
                        }}
                      >
                        {areaSelected == true ? (
                          <Grid.Col
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Grid.Col span={3}>
                              <Text style={{ fontSize: 12 }}>
                                Select Area Names
                              </Text>
                            </Grid.Col>
                            <Grid.Col span={9}>
                              <PLDataGrid
                                width={"500"}
                                value={{ value: areaData1, label: areaData2 }}
                                setEdit={(e) => {
                                  let area = e?.map((v) => v?.original);
                                  setAreaData(area);
                                  let val2 = [];
                                  let val1 = [];
                                  if (e?.length > 0) {
                                    e?.map((m, i) => {
                                      val2.push(m?.original?.FIELD02);
                                      val1.push(m?.original?.FIELD01);
                                    });

                                   
                                    setIsAreaSelected(true);
                                  }else{
                                    setIsAreaSelected(false);
                                  }
                                  setAreaData1(val1?.join(","));
                                  setAreaData2(val2?.join(","));
                                }}
                                isMulti={true}
                                TmData={M01FormData?.P_M31A}
                              // data={M01FormData?.P_M31ADT?.jData}
                              />
                            </Grid.Col>
                          </Grid.Col>
                        ) : (
                          ""
                        )}
                      </Grid.Col>
                    </Grid.Col>
                    <Grid.Col
                      span={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 0,
                        maxHeight: "47px",
                      }}
                    >
                      <Grid.Col
                        span={6}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: 0,
                        }}
                      >
                        <Grid.Col span={3}>
                          <Text style={{ fontSize: 12 }}>City Name</Text>
                        </Grid.Col>
                        <Grid.Col span={9}>
                          <PLComboBox
                            copno={"AS"}
                            value={selectedOfCity}
                            dispexpr={"DisplayMember"}
                            valexpr={"ValueMember"}
                            setEdit={(e) => {
                              setSelectedOfCity(e?.value);
                              setIsCitySelected(false);
                              if (e?.value == "S") {
                                setCitySelected(true);
                              } else {
                                setCitySelected(false);
                              }
                            }}
                          />
                        </Grid.Col>
                      </Grid.Col>
                      <Grid.Col
                        span={6}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: 0,
                        }}
                      >
                        <Grid.Col span={3}>
                          <Text style={{ fontSize: 12 }}>Account Name</Text>
                        </Grid.Col>
                        <Grid.Col span={9}>
                          <PLComboBox
                            copno={"AS"}
                            value={selectedOfAccount}
                            dispexpr={"DisplayMember"}
                            valexpr={"ValueMember"}
                            setEdit={(e) => {
                              setSelectedOfAccount(e?.value);
                              setIsAccountSelected(false);
                              if (e?.value == "S") {
                                setAccountSelected(true);
                              } else {
                                setAccountSelected(false);
                              }
                            }}
                          />
                        </Grid.Col>
                      </Grid.Col>
                    </Grid.Col>
                    <Grid.Col
                      span={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 0,
                      }}
                    >
                      <Grid.Col
                        span={6}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: 0,
                        }}
                      >
                        {citySelected == true ? (
                          <Grid.Col
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Grid.Col span={3}>
                              <Text style={{ fontSize: 12 }}>
                                Select City Names
                              </Text>
                            </Grid.Col>
                            <Grid.Col span={9}>
                              <PLDataGrid
                                width={"500"}
                                value={{ value: cityData1, label: cityData2 }}
                                setEdit={(e) => {
                                  let city = e?.map((v) => v?.original);
                                  setCityData(city);
                                  let val2 = [];
                                  let val1 = [];
                                  if (e?.length > 0) {
                                    e?.map((m, i) => {
                                      val2.push(m?.original?.FIELD02);
                                      val1.push(m?.original?.FIELD01);
                                    });

                                    
                                    setIsCitySelected(true);
                                  }else{
                                    setIsCitySelected(false);
                                  }
                                  setCityData1(val1?.join(","));
                                    setCityData2(val2?.join(","));
                                }}
                                isMulti={true}
                                TmData={M01FormData?.P_M31T}
                              // data={M01FormData?.P_M31TDT?.jData}
                              />
                            </Grid.Col>
                          </Grid.Col>
                        ) : (
                          ""
                        )}
                      </Grid.Col>
                      <Grid.Col
                        span={6}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: 0,
                        }}
                      >
                        {accountSelected == true ? (
                          <Grid.Col
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Grid.Col span={3}>
                              <Text style={{ fontSize: 12 }}>
                                Select Account Names
                              </Text>
                            </Grid.Col>
                            <Grid.Col span={9}>
                              <PLDataGrid
                                // data={selectedAccNames}
                                pid={"P_M01"}
                                dispexpr="FIELD02"
                                cWhere={getcWhere()}
                                // isLoading={waitForFilter}
                                TmData={"P_M01"}
                                width={"500"}
                                setEdit={(e) => {
                                  let account = e?.map((v) => v?.original);
                                  setAccountData(account);
                                  let val2 = [];
                                  let val1 = [];
                                  if (e?.length > 0) {
                                    e?.map((m, i) => {
                                      val2.push(m?.original?.FIELD02);
                                      val1.push(m?.original?.FIELD01);
                                    });

                                    setAccountData1(val1?.join(","));
                                    setAccountData2(val2?.join(","));
                                    setIsAccountSelected(true);
                                  }
                                  setWaitForFilter(false);
                                }}
                                dropdownPosition={"bottom"}
                                isMulti={true}
                              />
                            </Grid.Col>
                          </Grid.Col>
                        ) : (
                          ""
                        )}
                      </Grid.Col>
                    </Grid.Col>
                  </>
                )}
                {pid == "T_M21" && (
                  <>
                    <Grid.Col
                      span={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 0,
                        maxHeight: "47px",
                      }}
                    >
                      <Grid.Col
                        span={6}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: 0,
                        }}
                      >
                        <Grid.Col style={{ display: "flex", flexDirection: "row" }}>
                          <Grid.Col span={3}>
                            <Text style={{ fontSize: 12 }}>Group Name</Text>
                          </Grid.Col>
                          <Grid.Col span={9}>
                            <PLComboBox
                              copno={"AS"}
                              value={selectedOfGroup}
                              dispexpr={"DisplayMember"}
                              valexpr={"ValueMember"}
                              setEdit={(e) => {
                                setSelectedOfGroup(e?.value);
                                setIsGroupSelected(false);
                                if (e?.value == "S") {
                                  setGroupSelected(true);
                                } else {
                                  setGroupSelected(false);
                                }
                              }}
                            />
                          </Grid.Col>
                        </Grid.Col>
                      </Grid.Col>
                      <Grid.Col
                        span={6}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: 0,
                        }}
                      >
                        <Grid.Col span={3}>
                          <Text style={{ fontSize: 12 }}>Category Name</Text>
                        </Grid.Col>
                        <Grid.Col span={9}>
                          <PLComboBox
                            copno={"AS"}
                            value={selectedOfCategory}
                            dispexpr={"DisplayMember"}
                            valexpr={"ValueMember"}
                            setEdit={(e) => {
                              setSelectedOfCategory(e?.value);
                              setIsCategorySelected(false);
                              if (e?.value == "S") {
                                setCategorySelected(true);
                              } else {
                                setCategorySelected(false);
                              }
                            }}
                          />
                        </Grid.Col>
                      </Grid.Col>
                    </Grid.Col>
                    <Grid.Col
                      span={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 0,
                      }}
                    >
                      <Grid.Col
                        span={6}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: 0,
                        }}
                      >
                        {groupSelected == true ? (
                          <Grid.Col
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Grid.Col span={3}>
                              <Text style={{ fontSize: 12 }}>
                                Select Group Names
                              </Text>
                            </Grid.Col>
                            <Grid.Col span={9}>
                              <PLDataGrid
                                width={"500"}
                                value={{ value: groupData1, label: groupData2 }}
                                setEdit={(e) => {
                                  let group = e?.map((v) => v?.original);
                                  setGroupData(group);
                                  let val2 = [];
                                  let val1 = [];
                                  if (e?.length > 0) {
                                    e?.map((m, i) => {
                                      val2.push(m?.original?.FIELD02);
                                      val1.push(m?.original?.FIELD01);
                                    });

                                    
                                    setIsGroupSelected(true);
                                  }else{
                                    setIsGroupSelected(false);
                                  }
                                  setGroupData1(val1?.join(","));
                                    setGroupData2(val2?.join(","));
                                }}
                                isMulti={true}
                                TmData={ProductListData?.P_M32G}
                              // data={ProductListData?.P_M32GDT?.jData}
                              />
                            </Grid.Col>
                          </Grid.Col>
                        ) : (
                          ""
                        )}
                      </Grid.Col>
                      <Grid.Col
                        span={6}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: 0,
                        }}
                      >
                        {categorySelected == true ? (
                          <Grid.Col
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Grid.Col span={3}>
                              <Text style={{ fontSize: 12 }}>
                                Select Category Names
                              </Text>
                            </Grid.Col>
                            <Grid.Col span={9}>
                              <PLDataGrid
                                width={"500"}
                                value={{ value: categoryData1, label: categoryData2 }}
                                setEdit={(e) => {
                                  let category = e?.map((v) => v?.original);
                                  setCategoryData(category);
                                  let val2 = [];
                                  let val1 = [];
                                  if (e?.length > 0) {
                                    e?.map((m, i) => {
                                      val2.push(m?.original?.FIELD02);
                                      val1.push(m?.original?.FIELD01);
                                    });
                                    setIsCategorySelected(true);
                                  }else{
                                    setIsCategorySelected(false)
                                  }
                                  setCategoryData1(val1?.join(","));
                                  setCategoryData2(val2?.join(","));
                                }}
                                isMulti={true}
                                TmData={ProductListData?.P_M32C}
                              // data={ProductListData?.P_M32CDT?.jData}
                              />
                            </Grid.Col>
                          </Grid.Col>
                        ) : (
                          ""
                        )}
                      </Grid.Col>
                    </Grid.Col>
                    <Grid.Col
                      span={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 0,
                        maxHeight: "47px",
                      }}
                    >
                      <Grid.Col
                        span={6}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: 0,
                        }}
                      >
                        <Grid.Col span={3}>
                          <Text style={{ fontSize: 12 }}>Product Name</Text>
                        </Grid.Col>
                        <Grid.Col span={9}>
                          <PLComboBox
                            copno={"AS"}
                            value={selectedOfAccount}
                            dispexpr={"DisplayMember"}
                            valexpr={"ValueMember"}
                            setEdit={(e) => {
                              setSelectedOfAccount(e?.value);
                              setIsAccountSelected(false);
                              if (e?.value == "S") {
                                setAccountSelected(true);
                              } else {
                                setAccountSelected(false);
                              }
                            }}
                          />
                        </Grid.Col>
                      </Grid.Col>
                    </Grid.Col>
                    <Grid.Col
                      span={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 0,
                      }}
                    >
                      <Grid.Col
                        span={6}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: 0,
                        }}
                      >
                        {accountSelected == true ? (
                          <Grid.Col
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Grid.Col span={3}>
                              <Text style={{ fontSize: 12 }}>
                                Select Product Names
                              </Text>
                            </Grid.Col>
                            <Grid.Col span={9}>
                              <PLDataGrid
                                pid={"P_M21"}
                                // isLoading={waitForFilter}
                                // data={selectedAccNames}
                                cWhere={getcWhere()}
                                TmData={"P_M21"}
                                width={"500"}
                                setEdit={(e) => {
                                  let account = e?.map((v) => v?.original);
                                  setAccountData(account);
                                  let val2 = [];
                                  let val1 = [];
                                  if (e?.length > 0) {
                                    e?.map((m, i) => {
                                      val2.push(m?.original?.FIELD02);
                                      val1.push(m?.original?.FIELD01);
                                    });

                                    setAccountData1(val1?.join(","));
                                    setAccountData2(val2?.join(","));
                                    setIsAccountSelected(true);
                                  }
                                }}
                                dropdownPosition={"bottom"}
                                isMulti={true}
                              />
                            </Grid.Col>
                          </Grid.Col>
                        ) : (
                          ""
                        )}
                      </Grid.Col>
                    </Grid.Col>
                  </>
                )}

                <Grid.Col
                  span={12}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "3px",
                    maxHeight: "38px",
                  }}
                >
                  <Grid.Col
                    span={6}
                    style={{ display: "flex", alignItems: "center", padding: 0 }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ marginTop: "8px" }}>Format</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLComboBox
                        value={cfmtid ? cfmtid : TmFormat1?.DTS62?.at(0)?.S62F02}
                        dispexpr="S62F04"
                        valexpr="S62F02"
                        data={TmFormat1?.DTS62}
                        setEdit={(e) => {
                          setcfmtid(e);
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                </Grid.Col>

                <Grid.Col
                  span={12}
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <Grid.Col
                    span={6}
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <Grid.Col span={3}>
                      <Text>Watermark</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLTextBox disabled={true} />
                    </Grid.Col>
                  </Grid.Col>
                  <Grid.Col
                    span={6}
                    // style={{ border: "1px solid red" }}
                  >
                    <Grid span={12} style={{ display: "flex", alignItems: "center" }}>
                      <Grid.Col span={3} style={{ fontSize: "12px" }}>Report On</Grid.Col>
                      <Grid.Col span={9}>
                        <PLComboBox
                          value={ReportOn}
                          data={[
                            { label: "Screen", value: "s" },
                            { label: "Printer", value: "p" },
                            { label: "File", value: "f" },
                            { label: "Email", value: "e" },
                            { label: "Whatsapp", value: "w" },
                          ]}
                          dispexpr={"label"}
                          valexpr={"value"}
                          setEdit={(e) => {
                            setReportOn(e);
                          }}
                        />
                      </Grid.Col>
                      {/* <Grid.Col span={3} /> */}
                    </Grid>
                    <Grid.Col span={12}>
                      {ReportOn && renderExtraForm(ReportOn)}
                    </Grid.Col>
                  </Grid.Col>
                </Grid.Col>
                <Center>

                  <Button
                    style={{ height: "30px", width: "100px" }}
                    size="sm"
                    onClick={advanceController}
                    mr={4}
                  >Advance</Button>
                  <Button
                    style={{ height: "30px", width: "100px" }}
                    size="sm"
                    onClick={handleOk}
                  // onClick={()=>handlePrinting()}
                  >OK</Button>
                </Center>
              </Grid>
            ) : (
              <Grid
                gutter={2}
                style={{ display: "flex", flexDirection: "column", fontSize: "12px" }}
              >
                <Text
                  style={{
                    alignSelf: "center",
                    backgroundColor: "#228be61a",
                    color: "#228be6",
                    fontSize: "14px",
                  }}
                >
                  Printing option
                </Text>
                <Grid.Col span={12} style={{ display: "flex", flexDirection: "row" }}>
                  <Grid.Col
                    span={6}
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <Grid.Col span={4}>
                      <Text>Start Page</Text>
                    </Grid.Col>

                    <Grid.Col span={8}>
                      <PLNumberBox
                        value={AdvanceFormObjShow?.StartPage}
                        setEdit={(e) => {
                          setAdvanceFormObjShow({ ...AdvanceFormObjShow, StartPage: e });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                  <Grid.Col
                    span={6}
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <Grid.Col span={4}>
                      <Text>End Page</Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                      <PLNumberBox
                        value={AdvanceFormObjShow?.EndPage}
                        setEdit={(e) => {
                          setAdvanceFormObjShow({ ...AdvanceFormObjShow, EndPage: e });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                </Grid.Col>
                <Grid.Col
                  span={12}
                  style={{ display: "flex", flexDirection: "row", maxHeight: "47px" }}
                >
                  <Grid.Col
                    span={6}
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <Grid.Col span={4}>
                      <Text>Page Range</Text>
                    </Grid.Col>
                    <Grid.Col span={8} style={{ paddingBottom: "0px" }}>
                      <PLComboBox
                        data={CBOPRN}
                        valexpr={"ValueMember"}
                        dispexpr={"DisplayMember"}
                        value={AdvanceFormObjShow?.PageRange}
                        setEdit={(e) => {
                          setAdvanceFormObjShow({
                            ...AdvanceFormObjShow,
                            PageRange: e,
                          });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                  <Grid.Col
                    span={6}
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <Grid.Col span={4}>
                      <Text>Starting Page No.</Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                      <PLNumberBox
                        value={AdvanceFormObjShow?.StartingPageNo}
                        setEdit={(e) => {
                          setAdvanceFormObjShow({ ...AdvanceFormObjShow, StartingPageNo: e });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                </Grid.Col>
                <Grid.Col span={12} style={{ display: "flex", flexDirection: "row" }}>
                  <Grid.Col
                    span={6}
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <Grid.Col span={4}>
                      <Text>Left Margin</Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                      <PLNumberBox
                        precision={3}
                        value={AdvanceFormObj?.nLeftMargin}
                        setEdit={(e) => {
                          setAdvanceFormObj({ ...AdvanceFormObj, nLeftMargin: e });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                  <Grid.Col
                    span={6}
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <Grid.Col span={4}>
                      <Text>Top Margin</Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                      <PLNumberBox
                        precision={3}
                        value={AdvanceFormObj?.nTopMargin}
                        setEdit={(e) => {
                          setAdvanceFormObj({ ...AdvanceFormObj, nTopMargin: e });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                </Grid.Col>
                <Grid.Col
                  span={12}
                  style={{ display: "flex", flexDirection: "row", maxHeight: "47px" }}
                >
                  <Grid.Col
                    span={6}
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <Grid.Col span={4}>
                      <Text>Paper Size</Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                      <PLComboBox
                        data={CBOPPR}
                        valexpr={"ValueMember"}
                        dispexpr={"DisplayMember"}
                        value={AdvanceFormObj?.cPaperSize}
                        setEdit={(e) => {
                          setAdvanceFormObj({ ...AdvanceFormObj, cPaperSize: e });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                </Grid.Col>
                <Grid.Col
                  span={12}
                  style={{ display: "flex", flexDirection: "row", maxHeight: "47px" }}
                >
                  <Grid.Col
                    span={6}
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <Grid.Col span={4}>
                      <Text>Line</Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                      <PLComboBox
                        data={CBOF21}
                        valexpr={"ValueMember"}
                        dispexpr={"DisplayMember"}
                        value={AdvanceFormObj?.HLine}
                        setEdit={(e) => {
                          setAdvanceFormObj({ ...AdvanceFormObj, HLine: e?.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                  <Grid.Col
                    span={6}
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <Grid.Col span={4}>
                      <Text>Vertical Line</Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                      <PLComboBox
                        data={CBOF21}
                        valexpr={"ValueMember"}
                        dispexpr={"DisplayMember"}
                        value={AdvanceFormObj?.VLine}
                        setEdit={(e) => {
                          setAdvanceFormObj({
                            ...AdvanceFormObj,
                            VLine: e?.value,
                          });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                </Grid.Col>
                <Text
                  style={{
                    alignSelf: "center",
                    backgroundColor: "#228be61a",
                    color: "#228be6",
                    fontSize: "14px",
                  }}
                >
                  Report Title ( Advance Setup )
                </Text>
                <Grid.Col
                  span={12}
                  style={{ display: "flex", flexDirection: "row", maxHeight: "47px" }}
                >
                  <Grid.Col span={2}>
                    <Text>Report Title</Text>
                  </Grid.Col>
                  {/* <Grid.Col span={1}></Grid.Col> */}
                  <Grid.Col span={10}>
                    <PLTextBox
                      value={AdvanceFormObj?.cReportTitle}
                      setEdit={(e) => {
                        setAdvanceFormObj({
                          ...AdvanceFormObj,
                          cReportTitle: e.target.value,
                        });
                      }}
                    />
                  </Grid.Col>
                </Grid.Col>
                <Grid.Col
                  span={12}
                  style={{ display: "flex", flexDirection: "row", maxHeight: "47px" }}
                >
                  <Grid.Col span={2}>
                    <Text>Report Footer</Text>
                  </Grid.Col>
                  <Grid.Col span={10}>
                    <PLTextBox
                      value={AdvanceFormObj?.cReportFooter}
                      setEdit={(e) => {
                        setAdvanceFormObj({
                          ...AdvanceFormObj,
                          cReportFooter: e.target.value,
                        });
                      }}
                    />
                  </Grid.Col>
                </Grid.Col>
                <Grid.Col
                  span={12}
                  style={{ display: "flex", flexDirection: "row", maxHeight: "45px" }}
                >
                  <Grid.Col
                    span={6}
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <Grid.Col span={4}>
                      <Text>Company Name On</Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                      <PLComboBox
                        data={CBONMON}
                        valexpr={"ValueMember"}
                        dispexpr={"DisplayMember"}
                        value={AdvanceFormObj?.nCNameOn}
                        setEdit={(e) => {
                          // console.log(e);
                          setAdvanceFormObj({
                            ...AdvanceFormObj,
                            nCNameOn: e,
                          });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                </Grid.Col>
                <Grid.Col
                  span={12}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid.Col
                    span={6}
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <Grid.Col span={4}>
                      <Text>Report Header On</Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                      <PLComboBox
                        data={CBONMON}
                        valexpr={"ValueMember"}
                        dispexpr={"DisplayMember"}
                        value={AdvanceFormObj?.nRHeaderOn}
                        setEdit={(e) => {
                          setAdvanceFormObj({
                            ...AdvanceFormObj,
                            nRHeaderOn: e,
                          });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                  <Grid.Col
                    span={6}
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <Grid.Col span={4}>
                      <Text>Footer On</Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                      <PLComboBox
                        data={CBONMON}
                        valexpr={"ValueMember"}
                        dispexpr={"DisplayMember"}
                        value={AdvanceFormObj?.nRFooterOn}
                        setEdit={(e) => {
                          setAdvanceFormObj({
                            ...AdvanceFormObj,
                            nRFooterOn: e,
                          });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                </Grid.Col>
                <Grid.Col span={12} style={{ display: "flex", flexDirection: "row", marginBottom: "0px" }}>
                  <Grid.Col span={3}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start"
                    }}
                  >
                    <Button style={{ height: "30px", width: "100px" }}
                      size="sm">
                      Default
                    </Button>
                  </Grid.Col>
                  <Grid.Col span={6} style={{ display: "flex", justifyContent: "space-around" }}>
                    <Button
                      onClick={customPageHandler}
                      style={{ height: "30px" }}
                      size="sm"
                    >
                      Add Custom Page
                    </Button>
                    <Button
                      onClick={advanceController}
                      style={{ height: "30px" }}
                      size="sm"
                    >
                      Save As Default
                    </Button>
                  </Grid.Col>
                  <Grid.Col
                    span={3}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end"
                    }}
                  >
                    <Button
                      onClick={advanceController}
                      style={{ height: "30px", width: "100px" }}
                      size="sm"
                    >
                      Back
                    </Button>
                  </Grid.Col>
                </Grid.Col>
                {/* <Grid.Col
                  span={12}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: "0px",
                  }}
                >
                  <Grid.Col
                    span={11}
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <Grid.Col span={4}>
                      <Button
                        style={{
                          paddingLeft: "3px",
                          paddingRight: "3px",
                          marginRight: "0px",
                        }}
                      >
                        Default
                      </Button>
                    </Grid.Col>

                    <Grid.Col span={3}>
                      <Button
                        onClick={customPageHandler}
                        style={{
                          paddingLeft: "3px",
                          paddingRight: "3px",
                          marginRight: "0px",
                        }}
                      >
                        Add Custom Page
                      </Button>
                    </Grid.Col>
                    <Grid.Col span={3}>
                      <Button
                        onClick={advanceController}
                        style={{
                          paddingLeft: "3px",
                          paddingRight: "3px",
                          marginLeft: "0px",
                        }}
                      >
                        Save As Default
                      </Button>
                    </Grid.Col>
                  </Grid.Col>
                  <Grid.Col span={1}>
                    <Button
                      onClick={advanceController}
                      style={{
                        paddingLeft: "5px",
                        paddingRight: "5px",
                      }}
                    >
                      Back
                    </Button>
                  </Grid.Col>
                </Grid.Col> */}
              </Grid>
            )}
          </>
      }
    </>

  );
};

export function TM_PRINT(data) {
  const OBJ = JSON.parse(data);
  console.log("TM_PRINT Print btn=>", OBJ);
  Store.dispatch(
    ModalFunction({
      onclose: (e) => { },
      onClickCloseButton: () => {
        // const {
        //   hasError: AccountListhasError,
        //   ErrorMsg: AccountListErrorMsg,
        //   isLoading: AccountListisLoading,
        //   AccountListData,
        //   TMFormatData
        // } = useSelector((state) => state.AccountList);
        const AccListData = Store.getState().AccountList.AccountListData;

        const { [`print_${OBJ?.cSTID}`]: _, ...newAccListData } = AccListData

        Store.dispatch(SetAccountListData(newAccListData))
      },
      MTitle: <Text fw={700}>{OBJ?.text}</Text>,
      MAction: true,
      MSize: "xl",
      position: "right",
      MBody: (i) => <PLPrint data={OBJ} index={i} />,
      MClose: true,
      Overlay: {
        backgroundOpacity: 0.8,
        blur: 5,
      },
    })
  );
}

export default PLPrint;


// Promise.resolve(
// accountSelected == false &&
// Promise.resolve(
//   (resData = Store.getState()?.AccountList?.AccountListData[pid])
// ).then(() => {
//   if (isGroupSelected) {
//     if (pid == "T_M01") {
//       let filteredArray = [];

//       for (let i = 0; i < groupData?.length; i++) {
//         for (let j = 0; j < resData?.length; j++) {
//           if (groupData[i]?.FIELD01 == resData[j]?.FIELD05) {
//             filteredArray?.push(resData[j]);
//           }
//         }
//       }
//       resData = filteredArray;
//     } else if (pid == "T_M21") {
//       let filteredArray = [];

//       for (let i = 0; i < groupData?.length; i++) {
//         for (let j = 0; j < resData?.length; j++) {
//           if (groupData[i]?.FIELD01 == resData[j]?.FIELD11) {
//             filteredArray?.push(resData[j]);
//           }
//         }
//       }
//       resData = filteredArray;
//     }
//   }
//   if (isCategorySelected) {
//     let filteredArray = [];

//     for (let i = 0; i < areaData?.length; i++) {
//       for (let j = 0; j < resData?.length; j++) {
//         if (categoryData[i]?.FIELD01 == resData[j]?.FIELD12) {
//           filteredArray?.push(resData[j]);
//         }
//       }
//     }
//     resData = filteredArray;
//   }
//   if (isAreaSelected) {
//     let filteredArray = [];

//     for (let i = 0; i < areaData?.length; i++) {
//       for (let j = 0; j < resData?.length; j++) {
//         if (areaData[i]?.FIELD01 == resData[j]?.FIELD18) {
//           filteredArray?.push(resData[j]);
//         }
//       }
//     }
//     resData = filteredArray;
//   }
//   if (isCitySelected) {
//     let filteredArray = [];

//     for (let i = 0; i < cityData?.length; i++) {
//       for (let j = 0; j < resData?.length; j++) {
//         if (cityData[i]?.FIELD01 == resData[j]?.FIELD16) {
//           filteredArray?.push(resData[j]);
//         }
//       }
//     }

//     resData = filteredArray;
//   }
//   setSelectedAccNames(
//     resData?.length == 0
//       ? isCategorySelected ||
//         isAreaSelected ||
//         isGroupSelected ||
//         isCitySelected
//         ? resData
//         : resultNames
//       : resData
//   );
// })
// ).then(() => {
// let PostObj = {...AdvanceFormObj};
// console.log("PostObj==>", PostObj)
// GlobalClass.Notify("info","Adding","Please wait while we process your data");

// var data = {
//     CorpID: gensetting.getcorpid(),
//     cUser: gensetting.getcuser(),
//     cPass: gensetting.getcpass(),
//     CmpNo: gensetting.getcmpno(),
//     cAction:"A",
//     cCode: "",
//     cSData: JSON.stringify(PostObj),
// };

// let param = JSON.stringify({
//     CorpID: gensetting.getcorpid(),
//     cUser: gensetting.getcuser(),
//     cPass: gensetting.getcpass(),
//     CmpNo: gensetting.getcmpno(),
//     cAction: "A",
//     cCode: "",
// });
// const requestOptions = {
//     method: "POST",
//     headers: { "Content-Type": "application/json" },
//     body: JSON.stringify(data),
// };
// fetch(
//     GlobalClass.ApiUrl + GlobalClass.PostAdvanceEnt + "?pa=" + param,
//     requestOptions
// )
//     .then((response) => response.json())
//     .then((data) => {
//         if (data.status == "SUCCESS") {
//           Promise.resolve(GlobalClass.Notify("success","Added","Added successfully")).then(()=>dispatch(
//             GetAddCmpEnt({ cmpNo: sessionStorage.getItem("cmp"), action: "E" })
//           )).then(()=>setHandleOkClicked(true))

//         } else if (data.status == "FAIL") {
//           GlobalClass.Notify("error", data?.status, data?.message)
//         }
//     })
//     .catch((e) => GlobalClass.Notify("error", "Error", `${e.message}`))


// })
