import React from "react";
import FormatListTable from "./FormatListTable";
import Store from "../../utils/store";
import { Text } from "@mantine/core";
import { ModalFunction } from "../../utils/slices/ModalSlice";

export function swe(props) {
  // console.log(props , "props--o")
  const OBJ = JSON.parse(props);
  console.log(OBJ , "props--o")

  if (OBJ.p0 === "S") {
    Store.dispatch(
      ModalFunction({
        MTitle: <Text fw={700}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "sm",
        // position:'right',
        MBody: (i) => <FormatListTable obj={OBJ} index={i} />,
        MClose: true,
        onclose: () => {},
        onClickCloseButton: () => {
          // Store.dispatch(GetInvTypeDropDown())
        },
      })
    );
  } else if (OBJ.p0 === "E") {
    Store.dispatch(
      ModalFunction({
        MTitle: <Text fw={700}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "sm",
        // position:'right',
        MBody: (i) => <FormatListTable obj={OBJ} index={i} />,
        MClose: true,
        onclose: () => {},
        onClickCloseButton: () => {
          // Store.dispatch(GetInvTypeDropDown())
        },
      })
    );
  } else if (OBJ.p0 === "W") {
    Store.dispatch(
      ModalFunction({
        MTitle: <Text fw={700}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "sm",
        // position:'right',
        MBody: (i) => <FormatListTable obj={OBJ} index={i} />,
        MClose: true,
        onclose: () => {},
        onClickCloseButton: () => {
          // Store.dispatch(GetInvTypeDropDown())
        },
      })
    );
  }
}
