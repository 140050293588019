import React, { useRef } from "react";
import {
  Grid,
  Button,
  Flex,
  Skeleton,
  Center,
  Tooltip,
  ActionIcon,
  Text,
  Box,
  Group,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { useDispatch, useSelector } from "react-redux";
import {
  VDeleteSalesData,
  VGetSalesData,
} from "../../utils/slices/VSalesSlice";
import {
  MantineReactTable,
  useMantineReactTable,
  MRT_TableOptions,
  MRT_TablePagination,
} from "mantine-react-table";
import PLComboBox from "../../PlusComponent/PLComboBox";
import { VGetSalesEntryData } from "../../utils/slices/VSalesEntrySlice";
import Store from "../../utils/store";
import PLTextBox from "../../PlusComponent/PLTextBox";
import PLDataGrid from "../../PlusComponent/PLDataGrid";
import PLNumberBox from "../../PlusComponent/PLNumberBox";
import gensetting from "../../utils/gensetting";
import GlobalClass from "../../utils/GlobalClass";
import { notifications } from "@mantine/notifications";
import { IconEdit } from "@tabler/icons-react";
import { DataSaveLoading } from "../../utils/slices/DataSaveLoadingSlice";
import useWindowDimensions from "../../utils/UseWindowDimensions";
import { row } from "@syncfusion/ej2-react-grids";

const SalesSetupNew = (props) => {
  console.log("SalesSetupNew props=>",props);
  const { obj } = props;
  const formRef = useRef();
  const spa = useRef();
  const taxAc = useRef();
  const addTaxAc = useRef();
  const cGST = useRef();
  const sGST = useRef();
  const iGST = useRef();
  const cessAc = useRef();
  const [selectedDropdownValue, setSelectedDropdownValue] = useState([]);
  const [ccode, setccode] = useState("");
  const [data, setData] = useState([]);
  const [updatedColumn, setUpdatedColumn] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [editClicked, setEditClicked] = useState(false);
  const [ent, setEnt] = useState("");
  const [editObject, setEditObject] = useState();
  const [var1, setVar1] = useState();
  const [tableEditingRow, setTableEditingRow] = useState(null);
  const [sap, setSap] = useState();
  const VSalesData = useSelector((state) => state.VSalesData?.VSalesData?.data);
  const isLoading = useSelector((state) => state.VSalesData?.isLoading);
  const hasError = useSelector((state) => state.VSalesData?.hasError);
  const ErrorMsg = useSelector((state) => state.VSalesData?.ErrorMsg);
  const isLoading1 = useSelector((state) => state.VSalesData?.isLoading1);
  const hasError1 = useSelector((state) => state.VSalesData?.hasError1);
  const ErrorMsg1 = useSelector((state) => state.VSalesData?.ErrorMsg1);
  const isLoadingEnt = useSelector((state) => state.VSalesEntryData?.isLoading);
  const hasErrorEnt = useSelector((state) => state.VSalesEntryData?.hasError);
  const ErrorMsgEnt = useSelector((state) => state.VSalesEntryData?.ErrorMsg);
  const VSalesEntryData = useSelector(
    (state) => state.VSalesEntryData?.VSalesEnrtyData?.data?.data
  );
  // console.log("VSalesEntryData", VSalesEntryData);
  const SaveLoading = useSelector(
    (state) => state?.DataSaveLoadingSlice?.SaveLoading
  );

  const myComboBox = document.getElementById("#myComboBox");
  const { height } = useWindowDimensions();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(VGetSalesData(props.obj));
  }, []);

  useEffect(() => {
    const formElement = formRef.current;
    if (formElement) {
      formElement.addEventListener("keypress", (e) =>
        GlobalClass.formNextInput(e, formRef)
      );
    }
    return () => {
      if (formElement) {
        formElement.removeEventListener("keypress", (e) =>
          GlobalClass.formNextInput(e, formRef)
        );
      }
    };
  }, [formRef]);

  useEffect(() => {
    Promise.resolve().then(() => {
      if (VSalesEntryData?.M52) {
        setEditObject({
          ...VSalesEntryData?.M52,
          FIELD02:obj?.cSTID == "SS" && ["G","V"].includes(obj?.p0)
              ? "S"
              : obj?.cSTID === "JZ" && ["G"].includes(obj?.p0)
              ? "Z"
              : obj?.cSTID === "JJ" && ["G"].includes(obj?.p0)
              ? "J"
              : "P",
          FIELD03: ent?.FIELD03,
          FIELD04: selectedDropdownValue,
          FIELD05: "G000030G",
        });
      }
    });
  }, [VSalesEntryData]);

  useEffect(() => {
    if (hasError && !isLoading) {
      GlobalClass.Notify("error", "Error: " + ErrorMsg.code, ErrorMsg.message);
    }
  }, [hasError]);

  useEffect(() => {
    if (hasError1 && !isLoading1) {
      GlobalClass.Notify(
        "error",
        "Error: " + ErrorMsg1.code,
        ErrorMsg1.message
      );
    }
  }, [hasError1]);

  useEffect(() => {
    if (hasErrorEnt && !isLoadingEnt) {
      GlobalClass.Notify(
        "error",
        "Error: " + ErrorMsgEnt.code,
        ErrorMsgEnt.message
      );
    }
  }, [hasErrorEnt]);
  useEffect(() => {
    if (ent != "") {
      // console.log("selectedDropDownValue", selectedDropdownValue);
      // console.log("ent", ent);
      let FIELD02ent;

      Promise.resolve(
        (FIELD02ent =obj?.cSTID == "SS" && ["G","V"].includes(obj?.p0)
        ? "S"
        : obj?.cSTID === "JZ" && ["G"].includes(obj?.p0)
        ? "Z"
        : obj?.cSTID === "JJ" && ["G"].includes(obj?.p0)
        ? "J"
        : "P")
      )
        .then(() => {
          let temp = FIELD02ent.concat(
            ent?.FIELD03,
            selectedDropdownValue,
            "G000030G"
          );
          return temp;
        })
        .then((temp) => {
          Promise.resolve().then(() => {
            setccode(temp);
          });
        });
    }
  }, [ent]);

  useEffect(() => {
    if (editClicked == true) {
      // console.log("inside useeffect");
      // console.log("temp", ccode);
      setEditClicked(false);
      Store.dispatch(
        VGetSalesEntryData({ ccode: ccode, obj: props.obj, ent: ent })
      );
    }
  }, [editClicked]);

  useEffect(() => {
    document.addEventListener("keydown", (e) => {
      if (e?.key == "Escape") {
        Promise.resolve()
          .then(() => {
            rowCancelHandler();
          })
          .then(() => {
            if (tableEditingRow == null) {
              table.setCreatingRow(null);
            } else {
              table.setEditingRow(null);
            }
          });
      }
    });
  }, [tableEditingRow]);

  // useEffect(() => {
  //   if (table?.getSelectedRowModel()?.flatRows.length > 0) {
  //     data?.map((t, index) => {
  //       if (index in rowSelection) {
  //         //  onkeydown=((e)=>{
  //         document.addEventListener("keydown", (e) => {
  //           if (e.key == "Enter") {
  //             Promise.resolve()
  //               .then(() => {
  //                 table.setCreatingRow(null);
  //               })
  //               .then(() => {
  //                 Promise.resolve(
  //                     setEnt(table?.getSelectedRowModel()?.flatRows[0]?.original)
  //                 ).then(() => {
  //                   table.setEditingRow(
  //                     table?.getSelectedRowModel()?.flatRows[0]
  //                   );
  //                   setTableEditingRow(row);
  //                 }).then(()=>{
  //                   setTimeout(()=>{
  //                     setEditClicked(true);
  //                   },1000)
  //                 })
  //               });
  //           } else if (e.key == "Escape") {
  //             table.setEditingRow(null);
  //             table.setCreatingRow(null);
  //             setTableEditingRow(null);
  //           }
  //         });
  //         // })
  //       }
  //     });
  //   }
  // }, [rowSelection]);

  let arr = [];
  let column = [];
  var FIELD22;
  var FIELD07;
  var FIELD13;
  const columnHelper = createColumnHelper();
  useEffect(() => {
    if (VSalesData && selectedDropdownValue) {
      FIELD22 = VSalesData?.M01?.jData.FIELD22;
      FIELD07 = VSalesData?.M01?.jData.FIELD07;
      FIELD13 = VSalesData?.M01?.jData.FIELD13;
      VSalesData?.M51?.map((e) => {
        VSalesData?.M52?.map((d) => {
          if (e.FIELD01 === d.FIELD03) {
            arr.push({ M52F03NM: e.FIELD02, ...d });
          }
        });
      });
      for (let i = 0; i < arr.length; i++) {
        if (arr[i]?.FIELD04 != selectedDropdownValue) {
          VSalesData?.M51?.map((e) => {
            VSalesData?.M52?.map((d) => {
              if (e.FIELD01 === arr[i].FIELD03) {
                arr[i] = { M52F03NM: e.FIELD02, ...e };
              }
            });
          });
        }
      }

      setData(arr);
    }
  }, [selectedDropdownValue]);

  useEffect(() => {
    setSelectedDropdownValue(VSalesData?.M52INV[0]?.FIELD01);
  }, [VSalesData]);

  useEffect(() => {
    VSalesData?.M52Grd?.filter((item) => item.S53F00 == "C")?.map((a, i) => {
      column.push(
        columnHelper?.accessor(
          a?.S53F03 == "Product GST Slab" ? "M52F03NM" : a?.S53F04,
          {
            header: a?.S53F03,
            size: 150,
            id: a?.S53UID,
            enableColumnFilter: false,
            enableSorting: false,
            enableGrouping: false,
            enableColumnActions: false,
            Edit: ({ cell, column, table, row }) => {
              let val = cell.getValue();
              return renderValue(a, val, row, column, i);
            },
            Cell : ({ col, cell, row }) => {
              let val = cell.getValue();
              if(a?.S53F14=="PLPOPUP" && a?.S53F03!="Product GST Slab"){
                let nmf = a?.S53F04?.at(0).slice(0,1)+a?.S53F04?.slice(5,7)+"NM";
                // console.log("nmf",(data?.[2])?.[nmf])
                return (data?.[row.index])?.[nmf];
              }
              return val;
            },
          }
        )
      );
    });
    // console.log("column", column);
    setUpdatedColumn(column);
  }, [VSalesData?.M52Grd, data, editObject]);

  // console.log("ent", ent);

  useEffect(() => {
    if(sap!=null && (["SS","PP"].includes(obj?.cSTID) && ["V"].includes(obj?.p0))){
      Promise.resolve().then(()=>{
        setEditObject({ ...editObject,FIELD14:sap?.FIELD01 ?? "",F14NM:sap?.FIELD02 ?? ""})
      }).then(()=>{
        if(editObject?.FIELD14 == sap?.FIELD01){
        rowSaveHandler();}
      })
    }
  }, [sap,editObject?.FIELD14]);

  const NextIndexFun = (index) => {
    let nextInd;
    nextInd = index + 1;
    const nextInput = document.getElementById(`Sales_${nextInd}`);

    if (nextInput) {
      nextInput.focus();
    }
  };

  const renderValue = (ele, val, row, column, index) => {
    var editor = null;
    // console.log("EDITobJECT", editObject);
    // console.log("[ele.S53F04]", [ele.S53F04]);
    if (column?.columnDef?.header == "Product GST Slab") {
      return val;
    }
    // console.log("editObject?.[[ele.S53F04]?.slice(0,1)+[ele?.S53F04].slice(5,7)",editObject?.[[ele?.S53F04]?.at(0)?.slice(0,1)+[ele?.S53F04]?.at(0)?.slice(5,7)+"NM"]);
    // if (ele.S53F03 === column?.columnDef?.header) {
    switch (ele?.S53F14) {
      case "PLPOPUP":
        editor = (
          <PLDataGrid
            id={`Sales_${index}`}
            ref={
              ele?.S53F02 == "RID0061"
                ? spa
                : ele?.S53F02 == "RID0059"
                ? taxAc
                : ele?.S53F02 == "RID0058"
                ? addTaxAc
                : ele?.S53F02 == "RID0003"
                ? cGST
                : ele?.S53F02 == "RID0005"
                ? sGST
                : ele?.S53F02 == "RID0007"
                ? iGST 
                : ele?.S53F02 == "RID0009"
                ? cessAc
                : null
            }
            onKeyDown={(e) => {
              GlobalClass.formNextInput(e, formRef);
            }}
            position="target"
            width={"470px"}
            height={"28px"}
            value={{value:editObject?.[ele.S53F04],label:editObject?.[[ele?.S53F04]?.at(0)?.slice(0,1)+[ele?.S53F04]?.at(0)?.slice(5,7)+"NM"]}}
            p1={seperate(ele.S53F15)[0]}
            setEdit={(e) => {
              if (
                ele?.S53F02 == "RID0061" &&
               (["SS","PP"].includes(obj?.cSTID) && ["V"].includes(obj?.p0))
              ) {
                Promise.resolve(setSap(e))
               
              } else {
                setEditObject({ ...editObject, [ele.S53F04]: e?.FIELD01,[[ele?.S53F04]?.at(0)?.slice(0,1)+[ele?.S53F04]?.at(0)?.slice(5,7)+"NM"]:e?.FIELD02});
                if ((["SS","PP"].includes(obj?.cSTID) && ["V"].includes(obj?.p0)) && ele?.S53F02 == "RID0058") {
                  taxAc?.current?.handlePopoverToggle();
                } else if (
                  ((["SS","PP"].includes(obj?.cSTID) && ["V"].includes(obj?.p0))) &&
                  ele?.S53F02 == "RID0059"
                ) {
                  NextIndexFun(index);
                } else if (
                  (["SS","JZ","JJ","PP"].includes(obj?.cSTID) && ["G"].includes(obj?.p0)) &&
                  ele?.S53F02 == "RID0002"
                ) {
                  cGST?.current?.handlePopoverToggle();
                } else if (
                  (["SS","JZ","JJ","PP"].includes(obj?.cSTID) && ["G"].includes(obj?.p0)) &&
                  ele?.S53F02 == "RID0003"
                ) {
                  NextIndexFun(index);
                } else if (
                  (["SS","JZ","JJ","PP"].includes(obj?.cSTID) && ["G"].includes(obj?.p0)) &&
                  ele?.S53F02 == "RID0005"
                ) {
                  NextIndexFun(index);
                }else if (
                  (["SS","JZ","JJ","PP"].includes(obj?.cSTID) && ["G"].includes(obj?.p0)) &&
                  ele?.S53F02 == "RID0007"
                ) {
                  NextIndexFun(index);
                }else if (
                  (["SS","JZ","JJ","PP"].includes(obj?.cSTID) && ["G"].includes(obj?.p0)) &&
                  ele?.S53F02 == "RID0009"
                ) {
                  NextIndexFun(index);
                }
              }
            }}
          />
        );

        break;
      case "NUMBOX":
        editor = (
          <PLNumberBox
            id={`Sales_${index}`}
            decimalSeparator={true}
            precision={2}
            onKeyDown={(e) => {
              if (
                e.key == "Enter" &&
                ele?.S53F02 == "RID0010" &&
                (["SS","JZ","JJ","PP"].includes(obj?.cSTID) && ["G"].includes(obj?.p0))
              ) {
                rowSaveHandler();
              } else if (
                e.key == "Tab" &&
                ele?.S53F02 == "RID0060" &&
                (["SS","PP"].includes(obj?.cSTID) && ["V"].includes(obj?.p0))
              ) {
                // console.log("hello from popover");
                spa?.current?.handlePopoverToggle();
              } else if (
                e.key == "Tab" &&
                ele?.S53F02 == "RID0004" &&
                (["SS","JZ","JJ","PP"].includes(obj?.cSTID) && ["G"].includes(obj?.p0))
              ) {
                sGST?.current?.handlePopoverToggle();
              } else if (
                e.key == "Tab" &&
                ele?.S53F02 == "RID0006" &&
                (["SS","JZ","JJ","PP"].includes(obj?.cSTID) && ["G"].includes(obj?.p0))
              ) {
                iGST?.current?.handlePopoverToggle();
              } else if (
                e.key == "Tab" &&
                ele?.S53F02 == "RID0008" &&
                (["SS","JZ","JJ","PP"].includes(obj?.cSTID) && ["G"].includes(obj?.p0))
              ) {
                cessAc?.current?.handlePopoverToggle();
              }else {
                if(e?.key=="Tab"){
                NextIndexFun(index);}
              }
            }}
            value={editObject?.[ele.S53F04]}
            setEdit={(e) => {
              setEditObject({ ...editObject, [ele.S53F04]: e });
            }}
            // onKeyDown={(e) => {

            // }}
          />
        );
        break;

      case "COMBOBOX":
        editor = (
          <PLComboBox
            id={`Sales_${index}`}
            onKeyDown={(e) => {
              GlobalClass.formNextInput(e, formRef);
            }}
            copno={seperate(ele?.S53F15)[1]}
            dispexpr="DisplayMember"
            valexpr="ValueMember"
            value={editObject?.[ele.S53F04]}
            setEdit={(e) => {
              setEditObject({ ...editObject, [ele.S53F04]: e?.value });
            }}
          />
        );

        break;
      case "TEXTBOX":
        editor = (
          <PLTextBox
            id={`Sales_${index}`}
            onKeyDown={(e) => {
              GlobalClass.formNextInput(e, formRef);
            }}
            value={editObject?.[ele.S53F04]}
            setEdit={(e) => {
              setEditObject({ ...editObject, [ele.S53F04]: e?.target?.value });
            }}
          />
        );
        break;
      default:
        return null;
    }
    // }

    return editor;
  };

  const rowCancelHandler = () => {
    setEditObject(null);
    table.setEditingRow(null);
    table.setCreatingRow(null);
    setTableEditingRow(null);
  };
  const rowSaveHandler = () => {
    // console.log("editObject",editObject);
    dispatch(DataSaveLoading({[obj?.cSTID]:true}));
    GlobalClass.Notify(
      "info",
      "Editing",
      "Please wait while we process your data"
    );
    var newData = {
      CorpID: gensetting.getcorpid(),
      cUser: gensetting.getcuser(),
      cPass: gensetting.getcpass(),
      CmpNo: gensetting.getcmpno(),
      cAction:
        // props.obj.id === "00810072" ||
        // props.obj.id === "00630057" ||
        // props.obj.id === "00660057" ||
        // props.obj.id === "00840072" ||
        // props.obj.id === "00870072"
        //   ? "E":
        "A",
      cCode: ccode,
      cOPara:["PP"].includes(obj?.cSTID) && ["G"].includes(obj?.p0)
          ? "G~C~P"
          : ["SS"].includes(obj?.cSTID) && ["V"].includes(obj?.p0)
          ? "V~C~S"
          : ["JJ"].includes(obj?.cSTID) && ["G"].includes(obj?.p0)
          ? "G~C~J"
          : ["JZ"].includes(obj?.cSTID) && ["G"].includes(obj?.p0)
          ? "G~C~Z"
          : ["SS"].includes(obj?.cSTID) && ["G"].includes(obj?.p0)
          ? "G~C~S"
          : "V~C~P",
      cSData: JSON.stringify(editObject),
      YrNo:localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
    };

    let param = JSON.stringify({
      CorpID: gensetting.getcorpid(),
      cUser: gensetting.getcuser(),
      cPass: gensetting.getcpass(),
      CmpNo: gensetting.getcmpno(),
      cAction: "A",
      cCode: ccode,
      cOPara:["PP"].includes(obj?.cSTID) && ["G"].includes(obj?.p0)
          ? "G~C~P"
          : ["SS"].includes(obj?.cSTID) && ["V"].includes(obj?.p0)
          ? "V~C~S"
          : ["JJ"].includes(obj?.cSTID) && ["G"].includes(obj?.p0)
          ? "G~C~J"
          : ["JZ"].includes(obj?.cSTID) && ["G"].includes(obj?.p0)
          ? "G~C~Z"
          : ["SS"].includes(obj?.cSTID) && ["G"].includes(obj?.p0)
          ? "G~C~S"
          : "V~C~P",
    });

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(newData),
    };

    fetch(
      GlobalClass.ApiUrl + GlobalClass.PostM52Ent + "?pa=" + param,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        // console.log("api data", data);
        // console.log("api data status", data?.status);
        if (data.status === "SUCCESS") {
          Promise.resolve(dispatch(DataSaveLoading({[obj?.cSTID]:false})))
            .then(() => Store.dispatch(VGetSalesData(props?.obj)))
            .then(() => {
              GlobalClass.Notify("success", "Edited", "Edited successfully");
            });
        } else {
          GlobalClass.Notify("error", data?.status, data?.message);
          dispatch(DataSaveLoading({[obj?.cSTID]:false}));
        }
      })
      .catch((e) => {
        GlobalClass.Notify("error", "Error", `${e?.message}`);
        dispatch(DataSaveLoading({[obj?.cSTID]:false}));
      });
    // }
    setSap(null);
    table?.setEditingRow(null);
    setTableEditingRow(null);
  };

  const handleDelete = () => {
    // if (window.confirm("Are you sure to delete selected data?")) {
    //   Store.dispatch(VDeleteSalesData({ ccode: ccode, obj: props.obj }));
    // } else {
    //   return null;
    // }

    GlobalClass.Confirm(
      "red",
      "Are you sure to delete selected data?",
      "It will be Deleted Forever and Cannot Be Recovered!!!",
      () => {
        Store.dispatch(VDeleteSalesData({ ccode: ccode, obj: props.obj }))
      },
      () => { },
      "Cancel",
      "Confirm"
  )
  };
  const seperate = (e) => {
    // console.log("seperate=>", e);
    var paraArr = e.split("~C~");
    paraArr[1] = paraArr[1] + "";
    // console.log("paraArr", typeof paraArr[1]);
    var paraArrSep = paraArr[1].split("~R~");
    // console.log("paraArrSep==>", paraArrSep);
    return paraArrSep;
  };
  const comboBoxFocus = () => {
    myComboBox && myComboBox.focus();
  };

  // console.log("Data", data);
  // console.log("updatedColumn", updatedColumn);
  const initialStateJW = {
    "Product GST Slab": true,
    "Sale/Purc. A/c": true,
  };
  const initialStateGST = {
    "Sale/Purc. A/c": !(
      (FIELD22 === "V" || FIELD22 === "C") &&
      (FIELD07 === "TS" ||
        FIELD07 === "IN" ||
        FIELD07 === "TI" ||
        FIELD07 === "JP" ||
        FIELD07 === "PT" ||
        FIELD07 === "JE" ||
        FIELD07 === "JT")
    ),
    "CGST A/c.":
      !(
        (FIELD22 === "V" || FIELD22 === "C") &&
        (FIELD07 === "TS" || FIELD07 === "TP")
      ) && FIELD07 === "TX",
    "SGST A/c.":
      !(
        (FIELD22 === "V" || FIELD22 === "C") &&
        (FIELD07 === "TS" || FIELD07 === "TP")
      ) && FIELD07 === "TX",
    "IGST A/c.":
      !(
        (FIELD22 === "V" || FIELD22 === "C") &&
        (FIELD07 === "TS" || FIELD07 === "TP")
      ) && FIELD07 === "TX",
    "CESS A/c.":
      !(
        (FIELD22 === "V" || FIELD22 === "C") &&
        (FIELD07 === "TS" || FIELD07 === "TP")
      ) && FIELD07 === "TX",
  };
  const initialStateVAT = {
    "Sale/Purc. A/c": !(
      FIELD22 === "3" &&
      FIELD22 === "4" &&
      (FIELD07 === "TS" ||
        FIELD07 === "IN" ||
        FIELD07 === "TI" ||
        FIELD07 === "JP" ||
        FIELD07 === "PT" ||
        FIELD07 === "JE" ||
        FIELD07 === "JT")
    ),
    "Tax A/C":
      !(
        FIELD22 === "3" ||
        (FIELD22 === "4" && !FIELD13 === "99" && FIELD22 === "V")
      ) && FIELD07 === "TX",
    "%":
      !(
        FIELD22 === "3" ||
        (FIELD22 === "4" && !FIELD13 === "99" && FIELD22 === "V")
      ) && FIELD07 === "TX",
    "Addi. Tax A/c.":
      !(
        FIELD22 === "3" ||
        (FIELD22 === "4" && !FIELD13 === "99" && FIELD22 === "V")
      ) && FIELD07 === "TX",
  };

  const handleRowKeyDown = (e, row) => {
    if (e?.key == "Enter") {
      Promise.resolve(setEditClicked(true)).then(() => {
        table.setEditingRow(row);
        setEnt(row?.original);
      });
    }
  };

  const table = useMantineReactTable({
    columns: updatedColumn ? updatedColumn : [],
    data: data ? data : [],
    enableFilters: false,
    enableSorting: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableTopToolbar: false,
    enablePagination: true,
    enableGlobalFilter: false,
    enableColumnFilters: false,
    enableColumnActions: false,
    enableBottomToolbar:true,
    mantineTableContainerProps: {
      sx: { height: height * 0.5 },
    },
    mantineTableProps:{withColumnBorders:true},
    editDisplayMode: "row",
    enableEditing: true,
    createDisplayMode: "row",
    enableSelectAll: false,
    enableRowSelection: false,
    enableMultiRowSelection: false,
    onEditingRowSave: rowSaveHandler,
    onEditingRowCancel: rowCancelHandler,
    enableStickyHeader: true,
    state: {
      rowSelection,
      density: "1.5px",
    },
    mantineTableBodyRowProps: ({ row }) => ({
      tabIndex: 0,
      onKeyUp: (e) => {
        handleRowKeyDown(e, row);
      },
      onClick: () => {
        // console.log("Hello from selection");
        Promise.resolve(
          setRowSelection((prev) => ({
            [row?.id]: !prev[row?.id],
          }))
        ).then(() => {
          setEnt(row?.original);
        });
      },
      selected: rowSelection[row?.id],
      sx: {
        cursor: "pointer",
      },
    }),
    renderBottomToolbar: ({ table }) => (
      <Box>
        <Grid style={{ display: "flex", alignItems: "center" }}>
          <Grid.Col span={4}>
          </Grid.Col>

          <Grid.Col span={4}>
            <Group spacing={"xs"} position="center">
            <Button onClick={comboBoxFocus} size="xs" variant="subtle" compact>Invoice Type</Button>
            <Button onClick={handleDelete} size="xs" variant="subtle" compact>
                Delete
              </Button>
            </Group>
          </Grid.Col>

          <Grid.Col span={4}>
            <Group spacing={"xs"} position="right" display={"flex"}>
              <MRT_TablePagination position="bottom" table={table} />
            </Group>
          </Grid.Col>
        </Grid>
      </Box>
    ),
    renderRowActions: ({ row, table }) => (
      <Flex gap="md">
        <Tooltip label="Edit">
          <ActionIcon
            onClick={() => {
              Promise.resolve(setEnt(row?.original))
                .then(() => {
                  table.setEditingRow(row);
                  setTableEditingRow(row);
                  // spa?.current?.handlePopoverToggle();
                })
                .then(() => {
                  setTimeout(() => {
                    setEditClicked(true);
                  }, 1000);
                });
            }}
          >
            <IconEdit />
          </ActionIcon>
        </Tooltip>
      </Flex>
    ),
    initialState: { 
      columnVisibility:
        props?.obj?.id == "00780072" || props?.obj?.id == "00810072"
          ? initialStateGST
          : props?.obj?.id == "00660057" || props?.obj?.id == "00630057"
          ? initialStateVAT
          : initialStateJW,
      density: "0px",
    },
  });
  return (
    <div>
      {hasError ? <>
          <Text>{ErrorMsg?.message}</Text>
          <Button onClick={() => {
            if (props?.obj) {
              dispatch(VGetSalesData(props?.obj));
            }
          }}>Retry</Button>
        </>   :isLoading || isLoading1 || SaveLoading[obj?.cSTID] ? (
        GlobalClass.RenderLoader("dots")
      ) : (
        <>
          <Grid gutter={4}>
            <Grid.Col span={12} mb={5} style={{ display: "flex" }}>
              <>
                <Grid.Col span={2}>
                  <Text style={{ fontSize: "14px" }}>Invoice Type</Text>
                </Grid.Col>
                <Grid.Col span={4}>
                  <PLComboBox
                    cmbid="#myComboBox"
                    data={VSalesData?.M52INV}
                    value={selectedDropdownValue}
                    dispexpr="FIELD02"
                    valexpr="FIELD01"
                    setEdit={(e) => {
                      setSelectedDropdownValue(e);
                    }}
                  />
                </Grid.Col>
              </>
            </Grid.Col>
            <Grid.Col span={12} ref={formRef}>
              <MantineReactTable table={table} striped={true} />
            </Grid.Col>
          </Grid>
        </>
      )}
    </div>
  );
};

export default SalesSetupNew;
