import React, { useEffect, useRef, useState } from 'react'
import { MenuComponent } from '@syncfusion/ej2-react-navigations'
import { useDispatch, useSelector } from 'react-redux';
import { IconEdit as File, IconFile } from '@tabler/icons-react';
import { GetCmpSetUpObj, GetReportIdArray, GetTransactionReportIdArray } from '../utils/slices/DataSaveLoadingSlice';
import { ClearTabs, TabAdd } from '../utils/slices/TabList';
import Store from '../utils/store';
import BindFunWithPara from '../MyComponent/BindFunWithPara';
import { Modal } from '@mantine/core';
import { closest } from '@syncfusion/ej2-base';
import useWindowDimensions from '../utils/UseWindowDimensions';
import { useMediaQuery } from '@mantine/hooks';
import GlobalClass from '../utils/GlobalClass';
import MyComponent from '../MyComponent/MyComponent';


export default function MainMenubar(props) {
    const menuRef = useRef(null)
    const { height, width } = useWindowDimensions()
    const selected = useSelector((state) => state.TabList.selected);
    // console.log("selected", selected)
    const TabList = useSelector((state) => state.TabList.value);
    // console.log("TabList", TabList)
    const TabRef = useSelector((state) => state.reference.TabRef)
    // console.log("TabRef",TabRef)
    const Menuarray = useSelector((state) => state.UserList.MenuListData);
    const [active, setActive] = useState(selected);
    const [activeLink, setActiveLink] = useState('Settings');
    const [MainMenu, setMainMenu] = useState([])
    // console.log("MainMenu", MainMenu)
    let dra = {
        title: null,
        size: "80%",
        body: null,
        open: false,
        onClickCloseBtn: () => { },
        position: null,
        onclose: () => { }
    }
    const [DrawerObj, setDrawerObj] = useState(dra);
    const [prevFocusedItem, setPrevFocusedItem] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        if (Menuarray) {
            const transformItems = (links) => {
                return links?.map((l1) => {
                    if (l1.name.includes("Separator")) {
                        return {
                            separator: true,
                        };
                    }
                    return {
                        ...l1,
                        text: l1.name,
                        value: l1.id,
                        items: l1.items ? transformItems(l1.items) : [],
                    }
                });
            };

            const menudata = Menuarray.map((v) => {
                if (v.name.includes("Separator")) {
                    return {
                        separator: true,
                    };
                }
                return {
                    ...v,
                    text: v.name,
                    // iconCss:'e-icons e-books',
                    iconCss: v.icon,
                    value: v.id,
                    items: transformItems(v.items),
                }
            });

            setMainMenu(menudata);
        }
    }, [Menuarray])

    useEffect(() => {
        if (selected !== "Dash") {
            const selectedLink = TabList[selected]
            setActiveLink(selectedLink.menuId)
        }
    }, [selected])

    // shortcut keys and selected item show
    useEffect(() => {
        const handleKeyPress = (e) => {
            // Ensure only Alt is pressed, not Ctrl or Shift
            if (e.altKey && !e.ctrlKey && !e.shiftKey) {
                let selectedLinkId = '';

                switch (e.key.toLowerCase()) {
                    case 'm':
                        selectedLinkId = '00010000'; // Alt + M
                        break;
                    case 't':
                        selectedLinkId = '00020000'; // Alt + T
                        break;
                    case 'r':
                        selectedLinkId = '00030000'; // Alt + R
                        break;
                    case 'u':
                        selectedLinkId = '00040000'; // Alt + U
                        break;
                    case 's':
                        selectedLinkId = '00050000'; // Alt + S
                        break;
                    case 'e':
                        selectedLinkId = '00060000'; // Alt + E
                        break;
                    default:
                        return; // Exit if key is not in the list
                }

                if (selectedLinkId) {
                    const selectedLink = MainMenu.find(link => link.id === selectedLinkId);
                    console.log("selectedLink", selectedLink);

                    if (selectedLink) {
                        setActive(selectedLink.name);
                        const firstItem = selectedLink.items[0];

                        if (menuRef.current) {
                            const menuButton = menuRef.current.element.querySelector(`li[data-value='${selectedLinkId}']`);
                            if (menuButton) {
                                menuButton.click();
                            }
                        }

                        setTimeout(() => {
                            if (activeLink && selected !== "Dash") {
                                const activeElement = document.getElementById(activeLink);
                                if (activeElement) {
                                    activeElement.classList.add('e-focused');
                                    activeElement.style.backgroundColor = "#e6e6e6";
                                    activeElement.style.border = "1px solid gray";
                                    activeElement.style.borderRadius = "5px";
                                    activeElement.style.color = "black";
                                    activeElement.focus();
                                }
                            } else if (firstItem) {
                                const firstItemElement = document.getElementById(firstItem.id);
                                if (firstItemElement) {
                                    firstItemElement.classList.add('e-focused');
                                    firstItemElement.focus();
                                }
                            }
                        }, 400);
                    }
                    e.preventDefault(); // Prevent default action
                }
            }
        };


        window.addEventListener('keydown', handleKeyPress);

        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [MainMenu, activeLink, selected]);

    const findMainParentMenu = (menus, selectedId) => {
        if (selectedId !== "Dash") {

            const selectedTab = TabList[selectedId]
            // console.log("selectedTab", selectedTab)
            if (selectedTab.id === "_MNU0114" || selectedTab.id === "_MNU0115") {
                let obj = selectedTab.p0
                // console.log("obj", obj)
                for (let menu of menus) {
                    const findInSubMenu = (items) => {
                        for (let item of items) {
                            if (item.id === obj.id) {
                                return menu;
                            }
                            if (item.items) {
                                const result = findInSubMenu(item.items);
                                if (result) {
                                    return result
                                };
                            }
                        }
                        return null;
                    };

                    const result = findInSubMenu(menu.items);
                    if (result) {
                        return result
                    };
                }
            } else {
                for (let menu of menus) {
                    const findInSubMenu = (items) => {
                        for (let item of items) {
                            if (item.id === selectedTab.menuId) {
                                return menu;
                            }
                            if (item.items) {
                                const result = findInSubMenu(item.items);
                                if (result) {
                                    return result
                                };
                            }
                        }
                        return null;
                    };

                    const result = findInSubMenu(menu.items);
                    if (result) {
                        return result
                    };
                }
                return null;
            }
        } else {
            return null
        }
    };

    const mainParentMenu = findMainParentMenu(MainMenu, selected);
    // console.log("mainParentMenu", mainParentMenu)

    // useEffect(() => {
    //     if (mainParentMenu && selected !== "Dash") {
    //         handleFocus(mainParentMenu.id)
    //     } else {
    //         handleFocus("Dash")
    //     }
    // }, [mainParentMenu])

    let ReportArray = [];
    let ReportAarrayList = [];
    let TransactionArray = [];
    let TransactionArrayList = [];
    const SidebarObjSave = (id, items) => {
        switch (id) {
            case "00030000":
                if (items) {
                    items.map((l1) => {
                        // console.log(`report items ${l1.id} =>`,l1?.action?.split("~C~")[1]);

                        ReportArray.push(l1?.action?.split("~C~")[1])
                        if (l1.items) {
                            return SidebarObjSave(id, l1.items)
                        }
                    })
                    // console.log("ReportArray=>", ReportArray);
                    ReportAarrayList = [...ReportArray]
                    // console.log("AarrayList=>", ReportAarrayList);
                }
                Store.dispatch(GetReportIdArray(ReportAarrayList))
                Store.dispatch(GetTransactionReportIdArray(ReportAarrayList))
                break;
            case "00020000":
                if (items) {
                    items.map((l1) => {
                        // if(["04500447","04560447"].includes(l1?.id)){
                        //   console.log("CashbankCstid=>",[{
                        //     "l1":l1,
                        //     action1:l1?.action?.split("~C~")[0],
                        //     action2:l1?.action?.split("~C~")[1]
                        //   }]);
                        // }
                        TransactionArray.push(l1?.action?.split("~C~")[1])
                        if (l1.items) {
                            return SidebarObjSave(id, l1.items)
                        }
                    })
                    // console.log("TransactionArray=>", TransactionArray)
                    TransactionArrayList = [...TransactionArray]
                    // console.log("TransactionArrayList=>", TransactionArrayList)
                }
                Store.dispatch(GetTransactionReportIdArray(TransactionArrayList))
                break;

            default:
                break;
        }

    }

    function BindCall(menu, tabValue = "", fromMenu = false) {
        // console.log("BindCall", [{
        //     // menu:menu,
        //     tabValue: tabValue,
        //     fromMenu: fromMenu
        // }])
        menu.map((link) => {
            if (link.items?.length > 0) {
                BindCall(link.items, tabValue);
            }
            else if ((link.capid == "_MNU0114") && (tabValue == "_MNU0114")) { // Company Setup
                Promise.resolve(dispatch(ClearTabs(''))).then(() => {
                    dispatch(GetCmpSetUpObj(null))
                    var obj = {
                        id: link.capid,
                        text: link.name,
                        name: link.name,
                        type: link.type,
                        p0: link
                    }
                    // Store.dispatch(TabAdd(obj));
                    let TabItems = TabRef.properties?.items
                    // console.log("TabItems",TabItems)

                    // TabRef.removeTab(1)
                    if (TabItems && TabItems.length > 0) {
                        for (let i = TabItems.length - 1; i > 0; i--) {
                            TabRef.removeTab(i);
                        }
                    }
                    // TabRef.RemoveTab()
                    GlobalClass.addTab(obj, () => <div id={"tab" + obj.id} ><MyComponent obj={obj?.p0} /></div>)
                    setActive(obj.name);
                    setActiveLink(link.id)
                })
            }
            else if ((link.capid == "_MNU0115") && (tabValue == "_MNU0115")) { // Company Setup
                Promise.resolve(dispatch(ClearTabs(''))).then(() => {
                    dispatch(GetCmpSetUpObj(null))
                    var obj = {
                        id: link.capid,
                        text: link.name,
                        name: link.name,
                        type: link.type,
                        p0: link
                    }
                    let TabItems = TabRef.properties?.items
                    // console.log("TabItems",TabItems)

                    // TabRef.removeTab(1)
                    if (TabItems && TabItems.length > 0) {
                        for (let i = TabItems.length - 1; i > 0; i--) {
                            TabRef.removeTab(i);
                        }
                    }
                    // Store.dispatch(TabAdd(obj));
                    GlobalClass.addTab(obj, () => <div id={"tab" + obj.id} ><MyComponent obj={obj?.p0} /></div>)
                    setActive(obj.name);
                    setActiveLink(link.id)
                })
            }
            else if (link.id == tabValue) {
                setActive(link.name);
                BindFunWithPara(link);
                setActiveLink(link.id)
            }
            SidebarObjSave(link.id, link.items)
        })
    }

    const handleSelect = (args) => {
        // console.log("handleSelect", args)
        BindCall(MainMenu, ((args.item.capid == "_MNU0114" || args.item.capid == "_MNU0115") ? args.item.capid : args.item.value), true);
        setTimeout(() => {
            if (activeLink && selected !== "Dash") {
                const activeElement = document.getElementById(activeLink)
                console.log("activeElement", activeElement)

                if (activeElement) {
                    activeElement.classList.add('e-focused');
                    activeElement.style.backgroundColor = "#f5f5f5";
                    activeElement.style.border = "1px solid gray"
                    activeElement.style.borderRadius = "5px"
                    activeElement.style.color = "black";
                    activeElement.focus()
                }
            }
        }, 400)
    }

    const onBeforeOpen = (args) => {
        // console.log('onBeforeOpen', args)

        const menuWrapper = args.element.closest('.e-menu-wrapper');
        let itms = args?.items?.filter((v) => v?.text != "")?.length;
        console.log("itms", itms)
        // if (args.parentItem.text === "Report" || args.parentItem.text === "Transaction" || args.parentItem.text === "Master" || args.parentItem.text === "Setup" || args.parentItem.text === "Utility" || args.parentItem.text === "Exit") {
            if (menuWrapper) {
                if (itms > 10) {
                    menuWrapper.style.height = `${itms * 25}px`;
                } 
                // else {
                //     menuWrapper.style.height = `${height * 0.5}px`;
                // }

                setTimeout(() => {
                    const scrollBar = menuWrapper.querySelector('.e-vscroll-bar');
                    if (scrollBar) {
                        scrollBar.scrollTop = 0;
                    }
                }, 500);
            }
        // } else {
        //     if (itms > 6) {
        //         menuWrapper.style.height = `${itms * 30}px`;
        //     } else {

        //         menuWrapper.style.height = `${itms * 34}px`;
        //     }
        //     setTimeout(() => {
        //         const scrollBar = menuWrapper.querySelector('.e-vscroll-bar');
        //         if (scrollBar) {
        //             scrollBar.scrollTop = 0;
        //         }
        //     }, 500);
        // }
    };

    const handleFocus = (e) => {
        // console.log('Focused Item:', e);
        // const focusElement = e.target
        const focusedItem = e.target;
        // console.log('focusedItem', focusedItem);
        if (e === "Dash") { // Remove All Focus

            if (prevFocusedItem) {
                prevFocusedItem.classList.remove('e-focused');
                prevFocusedItem.style.backgroundColor = '';
                prevFocusedItem.style.color = '';
                prevFocusedItem.style.border = '';
                setPrevFocusedItem(null);
            }
            return;
        }
        // if (focusedItem) {
        //     if (prevFocusedItem) {
        //         prevFocusedItem.style.backgroundColor = '';
        //         prevFocusedItem.style.color = '';
        //         prevFocusedItem.style.border = '';
        //     }

        //     focusedItem.classList.add('e-focused');
        //     // focusedItem.style.backgroundColor = "#e6e6e6";
        //     focusedItem.style.border = "1px solid black"
        //     focusedItem.style.borderRadius = "5px"
        //     focusedItem.style.color = "black";
        //     setPrevFocusedItem(focusedItem)
        // } else 
        if (e) {
            // const previousMenu = document.querySelector('.e-focused');
            // if (previousMenu) {
            //     previousMenu.classList.remove('e-focused');
            //     previousMenu.style.border = '';
            //     previousMenu.style.borderRadius = ''; 
            //     previousMenu.style.color = '';
            // }
            const activeMenu = document.getElementById(e)
            console.log("activeMenu", activeMenu)
            if (activeMenu) {
                activeMenu.classList.add('e-focused');
                // activeMenu.style.backgroundColor = "#e6e6e6";
                // activeMenu.style.border = "1px solid gray"
                activeMenu.style.borderRadius = "5px"
                activeMenu.style.color = "black";
                // setPrevFocusedItem(activeMenu)
                activeMenu.focus()
            }
        }
    };

    return (
        <>
            {MainMenu && MainMenu?.length > 0 &&
                <MenuComponent
                    className="main-menu"
                    ref={menuRef}
                    items={MainMenu}
                    hamburgerMode={false}
                    enableScrolling={true}
                    // animationSettings={{ duration: 800 }}
                    showItemOnClick={true}
                    select={handleSelect}
                    // onFocus={handleFocus}
                    beforeOpen={onBeforeOpen}
                />
            }
        </>
    )
}