import { Text } from "@mantine/core";
import { ModalFunction } from "../../../utils/slices/ModalSlice";
import UserDetailForm from "./UserDetailForm";
import { DeleteUserDetailData } from "../../../utils/slices/UserDetailSlice";
import Store from "../../../utils/store";
import PLPrint from "../../../FunctionsCall/Print/PLPrint";
import GlobalClass from "../../../utils/GlobalClass";

export function M81AED(data, Skey) {
  const OBJ = JSON.parse(data);
  // console.log("executeFunction M81AED =>", OBJ);

  if (OBJ.p0 === "A") {
    Store.dispatch(
      ModalFunction({
        onclose: (e) => { },
        MTitle: <Text fw={700}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "lg",
        position: "right",
        MBody: (i) => <UserDetailForm OBJ={OBJ} index={i} />,
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        },
      })
    );

    //===========================================
    // modals.open({
    //   title: <Text fw={700}>{OBJ?.text}</Text>,
    //   size: "50%",
    //   children: <AccountGroupForm OBJ={OBJ} />,
    // });
  } else if (OBJ.p0 === "E" && !OBJ.id == "") {
    Store.dispatch(
      ModalFunction({
        onclose: () => { },
        MTitle: <Text fw={700}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "lg",
        position: "right",
        MBody: (i) => (
          // OBJ?.DataGridRow?.FIELD14 == "S" ? (
          //   GlobalClass.Alert("info", i)
          // ) : (
          <UserDetailForm OBJ={OBJ} index={i} />
        ),
        // ),
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        },
      })
    );
    //=====================================================
    // modals.open({
    //   title: <Text fw={700}>{OBJ?.text}</Text>,
    //   size: "50%",
    //   children: <AccountGroupForm OBJ={OBJ} />,
    // });
  } else if (OBJ.p0 === "D" && !OBJ.id == "") {
    // if (window.confirm("Are you sure to delete selected data?")) {
    //   Store.dispatch(DeleteUserDetailData({ cCode: OBJ.id, cAction: OBJ.p0 }));
    // } else {
    //   return null;
    // }

    GlobalClass.Confirm(
      "red",
      "Are you sure to delete selected data?",
      "It will be Deleted Forever and Cannot Be Recovered!!!",
      () => {
        Store.dispatch(DeleteUserDetailData({ cCode: OBJ.id, cAction: OBJ.p0 }));
      },
      () => { },
      "Cancel",
      "Confirm"
    )
  } else if (OBJ.p0 === "P") {
    Store.dispatch(
      ModalFunction({
        onclose: () => { },
        MTitle: <Text fw={700}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "lg",
        position: "right",
        MBody: (i) => (
          // OBJ?.DataGridRow?.FIELD14 == "S" ? (
          //   GlobalClass.Alert("info", i)
          // ) : (
          <PLPrint data={OBJ} />
        ),
        // ),
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        },
      })
    );
  }
}

export default M81AED;
