import { modals } from '@mantine/modals'
import React from 'react'
import LocationListForm from './LocationListForm'
import { DeleteLocData } from '../../utils/slices/M32LocationFormSlice'
import Store from '../../utils/store'
import { Text } from '@mantine/core'
import { ModalFunction } from '../../utils/slices/ModalSlice'
import GlobalClass from '../../utils/GlobalClass'

export const LocationList = () => {
  return (
    <div>LocationList</div>
  )
}

export function MSAED(data) {
  const OBJ = JSON.parse(data)
  // console.log('obj MSAED', OBJ)
  if (OBJ.p0 === 'A') {
    Store.dispatch(
      ModalFunction({
        onclose: () => { },
        MTitle: <Text fw={700}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "sm",
        position: 'right',
        MBody: (i) => (
          <LocationListForm obj={OBJ} index={i} />
        ),
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        }
      })
    )
  } else if (OBJ.p0 === 'E' && OBJ.id) {
    Store.dispatch(
      ModalFunction({
        onclose: () => { },
        MTitle: <Text fw={700}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "sm",
        position: "right",
        MBody: (i) => (
          <LocationListForm obj={OBJ} index={i} />
        ),
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        }
      })
    )
  } else if (OBJ.p0 === 'D' && OBJ.id) {
    // if (window.confirm('Confirm to Delete ?')) {
    //  Store.dispatch(DeleteLocData({ action: OBJ.p0, code: OBJ.id }))
    // }

    GlobalClass.Confirm(
      "red",
      "Confirm to Delete ?",
      "It will be Deleted Forever and Cannot Be Recovered!!!",
      () => {
        Store.dispatch(DeleteLocData({ action: OBJ.p0, code: OBJ.id }))
      },
      () => { },
      "Cancel",
      "Confirm"
    )
  } else {
    return null
  }
}


