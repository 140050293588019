import { Box, Button, Grid, Group, Modal, Text } from '@mantine/core'
import { createColumnHelper } from '@tanstack/react-table'
import { MRT_TablePagination, MantineReactTable, useMantineReactTable } from 'mantine-react-table'
import React, { useEffect, useState } from 'react'
import PLTextBox from '../PlusComponent/PLTextBox'
import PLComboBox from '../PlusComponent/PLComboBox'
import PrinterForm from './PrinterForm'
import GlobalClass from '../utils/GlobalClass'
import useWindowDimensions from '../utils/UseWindowDimensions'
import gensetting from '../utils/gensetting'

const PrinterTable = () => {
    const columnHelper = createColumnHelper()
    const { height } = useWindowDimensions();
    const [columns, setColumns] = useState([])
    // const [printObj, setPrintObj] = useState({
    //     srno: "",
    //     companyNm: "",
    //     title: "",
    //     choice: "",
    //     format: "",
    //     type: "",
    //     ipaddress: "",
    //     shareNm: ""
    // })
    const [printData, setPrintData] = useState([])
    console.log("printData", printData)
    const [rowSelection, setRowSelection] = useState({})
    const [selectedRow, setSelectedRow] = useState(null);
    let dra = {
        title: "",
        size: "md",
        body: "",
        open: false,
    };
    const [DrawerObj, setDrawerObj] = useState(dra);
    const printingChoice = [
        {
            DisplayMember: "Browser Popup Print",
            ValueMember: "bpp"
        },
        {
            DisplayMember: "Direct Print",
            ValueMember: "dp"
        }
    ];
    const printFormat = [
        {
            DisplayMember: "No Auto KOT Print",
            ValueMember: "KOT"
        },
        {
            DisplayMember: "Thermal 56mm",
            ValueMember: "T56"
        },
        {
            DisplayMember: "Thermal 80mm",
            ValueMember: "T88"
        }
    ]
    const printerType = [
        {
            DisplayMember: "Network Printer",
            ValueMember: "np"
        },
        {
            DisplayMember: "Windows Printer",
            ValueMember: "wp"
        },
    ]
    // const [cmpList, setCmpList] = useState({})
    // console.log("cmpList", cmpList)
    const defineColumns = [
        {
            valExpr: "srno",
            dispExpr: "SN",
            size: 10
        },
        {
            valExpr: "title",
            dispExpr: "Title",
            size: 20
        },
        {
            valExpr: "choice",
            dispExpr: "Printing Choice",
            size: 20
        },
        {
            valExpr: "format",
            dispExpr: "Print Format",
            size: 20
        },
        {
            valExpr: "type",
            dispExpr: "Printer Type",
            size: 20
        },
        {
            valExpr: "ipaddress",
            dispExpr: "Printer IP Address",
            size: 20
        },
        {
            valExpr: "shareNm",
            dispExpr: "Share Name",
            size: 20
        }
    ]
    let PrinterArr = localStorage.getItem("Printer");
    useEffect(() => {
        if (PrinterArr) {
            PrinterArr = JSON.parse(PrinterArr);
            const filterData = PrinterArr.filter((f) => f.companyNm == gensetting.getcmpno())
            console.log("filterData",filterData)
            setPrintData(filterData)
        }
    }, [PrinterArr]);

    const renderInputBox = (v, c, cellid, index) => {
        let setInput;
        switch (c.dispExpr) {
            case 'SN':
                setInput = (
                    <PLTextBox

                    />
                )
                break;
            case 'Company Name':
                setInput = (
                    <PLComboBox
                        // data={cmpList}
                        dispexpr="cmpName"
                        valexpr="cmpNo"
                        setEdit={(e) => {

                        }}
                    />)
                break;
            case 'Title':
                setInput = <PLTextBox

                />
                break;
            case 'Printing Choice':
                setInput = <PLComboBox

                />
                break;
            case "Print Format":
                setInput = <PLComboBox />
                break;
            case "Printer Type":
                setInput = <PLComboBox />
                break;
            case "Printer IP Address":
                setInput = <PLTextBox />
                break;
            case "Share Name":
                setInput = <PLTextBox />
                break;
            default:
                break;
        }
        return setInput
    }

    useEffect(() => {
        if (defineColumns && defineColumns?.length > 0) {
            let cols = [];

            defineColumns?.map((v, i) => {
                cols.push(
                    columnHelper.accessor(v.valExpr, {
                        header: v.dispExpr,
                        size: v.size,
                        Edit: ({ cell, row }) => {
                            let val = cell.getValue();
                            // return renderInputBox(val, v, cell?.id, i);
                        },
                        Cell: ({ cell, row }) => {
                            let val = cell.getValue()
                            if (v.dispExpr === "SN") {
                                return row.index + 1;
                            }
                            else if (v.dispExpr === "Company Name") {
                                let cmpArr = JSON.parse(localStorage.getItem("authUser"));
                                if (cmpArr) {
                                    cmpArr = cmpArr.cmpList || [];
                                }

                                const company = cmpArr.find(c => c.cmpNo === printData[row.id]?.companyNm);
                                console.log("company", company)
                                if (company) {
                                    return `${company.cmpNo} - ${company.cmpName}`
                                }
                            } else if (v.dispExpr === "Printer Type") {
                                const type = printerType.find(p => p.ValueMember === printData[row.id]?.type)
                                if (type) {
                                    return type.DisplayMember
                                }
                            } else if (v.dispExpr === "Printing Choice") {
                                const choiceVal = printingChoice.find(c => c.ValueMember === printData[row.id]?.choice)
                                if (choiceVal) {
                                    return choiceVal.DisplayMember
                                }
                            } else if (v.dispExpr === "Print Format") {
                                const formatVal = printFormat.find(f => f.ValueMember === printData[row.id]?.format)
                                if (formatVal) {
                                    return formatVal.DisplayMember
                                }
                            }
                            return val
                        }
                    }),
                )
            })
            // setFlag(!flag)
            setColumns(cols)
        }
    }, [printData])

    const addData = () => {
        console.log("run")
        // table.setCreatingRow(true)
        setDrawerObj({
            ...dra,
            title: <Text fw={700}>Add Printer</Text>,
            body: (
                <PrinterForm
                    DrawerObj={DrawerObj}
                    setDrawerObj={(e) => {
                        setDrawerObj(e)
                    }}
                />
            ),
            open: true,
            size: "xl",
            position: "right",
        });
    }

    const handleEditRow = (row) => {
        console.log("handleEditRow", row)
        let selectedRow = table.getSelectedRowModel().flatRows[0]
        if (selectedRow) {
            setDrawerObj({
                ...dra,
                title: <Text fw={700}>Add Printer</Text>,
                body: (
                    <PrinterForm
                        DrawerObj={DrawerObj}
                        setDrawerObj={(e) => {
                            setDrawerObj(e)
                        }}
                        rowObj={row}
                        cAction="E"
                        selectedRow={table.getSelectedRowModel().flatRows[0]}
                        setRowSelection={(e) => {
                            setRowSelection(e)
                        }}
                    />
                ),
                open: true,
                size: "xl",
                position: "right",
            });
        }
    }

    const handleDeleteRow = () => {
        let selectedRow = table.getSelectedRowModel().flatRows[0]
        if (selectedRow) {
            // if (window.confirm('Confirm to Delete ?')) {
            //     let printerData = JSON.parse(localStorage.getItem("Printer"))
            //     if(printerData){
            //         let otherCmpData = printerData.filter((p) => p.companyNm !== gensetting.getcmpno())
            //         let newArray = printData?.filter((d, i) => i != selectedRow?.index)
            //         setPrintData(newArray)
            //         const updatedData = [...newArray, ...otherCmpData]
            //         localStorage.setItem("Printer", JSON.stringify(updatedData));
            //     }
            //     GlobalClass.Notify("success", "Success", "Delete successfully")
            // }

            GlobalClass.Confirm(
                "red",
                "Are you sure to delete selected data?",
                "It will be Deleted Forever and Cannot Be Recovered!!!",
                () => {
                    let printerData = JSON.parse(localStorage.getItem("Printer"))
                    if(printerData){
                        let otherCmpData = printerData.filter((p) => p.companyNm !== gensetting.getcmpno())
                        let newArray = printData?.filter((d, i) => i != selectedRow?.index)
                        setPrintData(newArray)
                        const updatedData = [...newArray, ...otherCmpData]
                        localStorage.setItem("Printer", JSON.stringify(updatedData));
                    }
                    GlobalClass.Notify("success", "Success", "Delete successfully")
                },
                () => { },
                "Cancel",
                "Confirm"
            )
        }
    }

    const table = useMantineReactTable({
        data: printData,
        columns,
        mantineTableProps: { withColumnBorders: true },
        enableTopToolbar: false,
        enableColumnActions: false,
        enableSorting: false,
        mantineTableContainerProps: {
            sx: { height: height * 0.3 },
        },
        // onCreatingRowSave: handleCreateRow,
        // onEditingRowSave: handleEditRow,
        // onCreatingRowCancel: handleCanceRow,
        // onEditingRowCancel: handleCanceRow,
        initialState: {
            // density: "0px",
        },
        state: {
            density: "0px",
            rowSelection
        },
        mantineTableBodyRowProps: ({ row, staticRowIndex, cell }) => ({
            onClick: () => {
                // console.log(row?.original, "row?.original");
                setRowSelection((prev) => ({
                    [row.id]: !prev[row.id],
                }));
                setSelectedRow(row?.original)
            },
            selected: rowSelection[row.id],
            sx: { cursor: "pointer" },
            // height: 50,
        }),
        renderBottomToolbar: ({ table }) => (
            <Box>
                <Group
                    spacing={"xs"}
                    position="center"
                    display={"flex"}
                ><MRT_TablePagination position="bottom" table={table} /></Group>
                <Group spacing={"xs"} position="center" display={"flex"}>
                    <Button
                        variant='subtle'
                        onClick={addData}
                    >Add</Button>
                    <Button
                        variant='subtle'
                        onClick={() => handleEditRow(selectedRow)}
                    >Edit</Button>
                    <Button
                        variant='subtle'
                        onClick={() => handleDeleteRow()}
                    >Delete</Button>
                </Group>
            </Box>
        ),
    })
    return (
        <>
            <Modal
                opened={DrawerObj?.open}
                // onClose={close}
                withCloseButton={() => { }}
                fullScreen={false} //
                // overlayProps={{ opacity: 0.5, blur: 4 }}
                closeButtonProps={{
                    onClick: () => {
                        setDrawerObj(dra);
                    },
                }}
                title={DrawerObj.title}
                size={DrawerObj.size}
                closeOnClickOutside={false}
                position={DrawerObj?.position ?? "right"}
                onClose={
                    typeof DrawerObj?.onclose == "function"
                        ? DrawerObj?.onclose
                        : () => {
                            setDrawerObj(dra);
                        }
                }
            >
                {DrawerObj.body}
            </Modal>
            <Grid gutter={4}>
                <Grid.Col span={12}>
                    <MantineReactTable table={table} />
                </Grid.Col>
            </Grid>
        </>
    )
}

export default PrinterTable