import { ActionIcon, Button, Flex, Group, Text, Tooltip } from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import { createColumnHelper, getCoreRowModel } from "@tanstack/react-table";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import React, { useEffect, useRef, useState } from "react";
import Store from "../../utils/store";
import { ModalDelete, ModalFunction } from "../../utils/slices/ModalSlice";
import PLNumberBox from "../../PlusComponent/PLNumberBox";
import PLDateBox from "../../PlusComponent/PLDateBox";
import useWindowDimensions from "../../utils/UseWindowDimensions";
import PLTextBox from "../../PlusComponent/PLTextBox";
import GlobalClass from "../../utils/GlobalClass";

const CapitalAccountTable = (props) => {
  // console.log('props==>', props);

  const { height } = useWindowDimensions();
  const [validationErrors, setValidationErrors] = useState({});
  const [M48, setM48] = useState(null);
  const [columns, setColumns] = useState([]);
  let year = new Date().toLocaleString("en-IN", { year: "numeric" });
  let month = new Date().toLocaleString("en-IN", { month: "2-digit" });
  let day = new Date().toLocaleString("en-IN", { day: "2-digit" });

  let startDate1 = year + month + day;
  // console.log(startDate1,"startdate1")
  const [newObj, setNewObj] = useState({
    FIELD01: null,
    FIELD02: null,
    FIELD03: 0,
    FIELD04: null,
    FIELD10: null,
    FIELD11: null,
    FIELD12: null,
    FIELD13: null,
    FIELD14: null,
    FIELD15: null,
    FIELD16: null,
    FIELD17: startDate1,
    FLDUNQ: null,
    FLDAED: null,
    FLDBRC: null,
    EGKEYID: 1,
  });
  const [search, setSearch] = useState("");
  const [tableEditingRow, setTableEditingRow] = useState(null);
  const TableRef = useRef();
  const dateRef = useRef();
  //CREATE action
  const handleCreateUser = async ({ values, exitCreatingMode }) => {
    // const newValidationErrors = validateUser(values);
    // if (Object.values(newValidationErrors).some((error) => error)) {
    //   setValidationErrors(newValidationErrors);
    //   return;
    // }
    // console.log('newObj==>', newObj);
    // console.log("vals", values);
    setM48([...M48, newObj]);
    // console.log("M48",M48);
    setValidationErrors({});
    setNewObj({
      FIELD01: 0,
      FIELD02: null,
      FIELD03: 0,
      FIELD04: null,
      FIELD10: null,
      FIELD11: null,
      FIELD12: null,
      FIELD13: null,
      FIELD14: null,
      FIELD15: null,
      FIELD16: null,
      FIELD17: startDate1,
      FLDUNQ: null,
      FLDAED: null,
      FLDBRC: null,
      EGKEYID: 1,
    });
    // await createUser(values);
    exitCreatingMode();
  };
  // console.log('CapitalAccountTable M48=>', M48);


  const handleRowKeyDown = (e, row) => {
    // console.log("handleRowKeyDown parameter =>", [{
    //   "e": e,
    //   "row": row,
    //   "tableEditingRow": tableEditingRow,
    //   "TableRef.current": TableRef.current
    // }])

    // if (e?.key == "Enter") {
    //   console.log("handleRowKeyDown parameter enter =>", [{
    //     "e": e,
    //     "row": row
    //   }])

    //   if (row?.id == 'mrt-row-create') {
    //     return
    //   } else {
    //     table.setEditingRow(row)
    //     setTableEditingRow(row)
    //     setT02Obj(row?.original)
    //   }
    // }else if(e?.key == "ArrowDown"){
    //     setRowSelection({[(row?.index) + 1]:true})
    // }else if(e?.key == "ArrowUp"){
    //     setRowSelection({[(row?.index) - 1]:true})
    // }

    const tabled = TableRef.current;
    const rows = tabled.querySelectorAll("tr");
    const currentRowIndex = Array.from(rows).indexOf(e.target);
    switch (e?.key) {
      case "ArrowDown":
        // e.preventDefault();
        if (currentRowIndex < rows.length - 1) {
          e.preventDefault();
          rows[currentRowIndex + 1].focus();
          // rows[currentRowIndex + 1].scrollIntoView({ behavior: "smooth",block: "start"})
        } 

        break;
      case "ArrowUp":
        if (currentRowIndex > 0) {
          e.preventDefault();
          rows[currentRowIndex - 1].focus();
          // rows[currentRowIndex - 1].scrollIntoView({ behavior: "smooth",block: "end"})
        } 

        break;
      case "Enter":
        if (row?.id == "mrt-row-create") {
          return;
        } else if (tableEditingRow) {
          return;
        } else {
          table?.setEditingRow(row);
          // console.log("grpRef",grpRef)
          setTimeout(() => {
          dateRef?.current?.focus()
          }, 500);
          setTableEditingRow(row);
          setNewObj(row?.original);
        }
        break;
      default:
          break;
    }
  };

  //UPDATE action
  const handleSaveUser = async () => {
    // const newValidationErrors = validateUser(values);
    // if (Object.values(newValidationErrors).some((error) => error)) {
    //   setValidationErrors(newValidationErrors);
    //   return;
    // }
    // setValidationErrors({});
    // await updateUser(values);

    let newArray = [...M48];
    newArray[tableEditingRow.index] = newObj;
    let nullFilter = newArray?.filter((d) => d?.FIELD03 != "" || d.FIELD17 != "");
    setM48([...nullFilter,props.p0 == "E" || nullFilter.length>1
    ? { ...M48?.[M48?.length - 1] }
    : { ...M48?.[0]}]);
    // setM48(M48[row.index]:row?.original)
    setTableEditingRow(null);
    setNewObj({
      FIELD01: null,
      FIELD02: null,
      FIELD03: 0,
      FIELD04: null,
      FIELD10: null,
      FIELD11: null,
      FIELD12: null,
      FIELD13: null,
      FIELD14: null,
      FIELD15: null,
      FIELD16: null,
      FIELD17: startDate1,
      FLDUNQ: null,
      FLDAED: null,
      FLDBRC: null,
      EGKEYID: 1,
    })
    table.setEditingRow(null); //exit editing mode
  };

  //DELETE action
  const openDeleteConfirmModal = (row) => {
    // if (window.confirm("Are you sure to delete selected data?")) {
    //   const newArr = M48.filter((object, i) => {
    //     return i !== row.index;
    //   });
    //   setM48(newArr);
    // }

    GlobalClass.Confirm(
      "red",
      "Are you sure to delete selected data?",
      "It will be Deleted Forever and Cannot Be Recovered!!!",
      () => {
        const newArr = M48.filter((object, i) => {
          return i !== row.index;
        });
        setM48(newArr);
      },
      () => { },
      "Cancel",
      "Confirm"
  )
  };

  const columnHelper = createColumnHelper();

  useEffect(() => {
    // console.log("val");
    if (props?.value) {
      // console.log("helloo");
      setM48(props.value);
    }
  }, [props.value]);
  useEffect(() => {
    document.addEventListener("keydown", (e) => {
      if (e?.key == "Escape") {
        Promise.resolve()
          .then(() => {
            setNewObj({
              FIELD01: null,
              FIELD02: null,
              FIELD03: 0,
              FIELD04: null,
              FIELD10: null,
              FIELD11: null,
              FIELD12: null,
              FIELD13: null,
              FIELD14: null,
              FIELD15: null,
              FIELD16: null,
              FIELD17: startDate1,
              FLDUNQ: null,
              FLDAED: null,
              FLDBRC: null,
              EGKEYID: 1,
            })
          })
          .then(() => {
            if (tableEditingRow == null) {
              table.setCreatingRow(null);
            } else {
              table.setEditingRow(null);
            }
          });
      }
    });
  }, [tableEditingRow]);

  useEffect(() => {
    // console.log("grd");
    if (props.value && props.grd) {
      let cols = [];
      // setM48(props.value);
      // console.log("M48",M48)
      props.grd
        .filter((a) => a.S53F00 == "C")
        .map((d) => {
          // if (d.S53F00 !== "H") {
          // console.log("ddd",d);
          cols.push(
            columnHelper.accessor(d.S53F04, {
              header: d.S53F03,
              size: d.S53F07 == 0 ? 0 : 150,
              // props?.defaultval?.slice(0,4)+"-"+props?.defaultval?.slice(4,6)+"-"+props?.defaultval?.slice(6,8)
              Cell: ({ cell, column, table, row }) => {
                var val = cell.getValue();
                // console.log("val",cell.getValue())
                return d.S53F03 == "Date"
                  ? val
                    ? val.toString().slice(6, 8) +
                      "/" +
                      val.toString().slice(4, 6) +
                      "/" +
                      val.toString().slice(0, 4)
                    : ""
                  : val;
              },
              Edit: ({ cell, column, table, row }) => {
                var val = cell.getValue();
                if (d.S53F03 == "Capital Account") {
                  // return <PLNumberBox
                  //   value={row?.original?.EGKEYID}
                  //   setEdit={(e) => {
                  //     // console.log('Capital Account', e);
                  //     setNewObj({ ...newObj, EGKEYID: e })
                  //   }}
                  // />
                } else if (d.S53F03 == "Date") {
                  // console.log("newObj?.FIELD17",newObj?.FIELD17)
                  return (
                    <PLDateBox
                    ref={dateRef}
                      defaultval={newObj?.FIELD17}
                      // defaultval={priceData.FIELD11}
                      dispformat="DD/MM/YYYY"
                      setEdit={(e) => {
                        // console.log('Date', e);
                        let year = e.toLocaleString("en-IN", {
                          year: "numeric",
                        });
                        let month = e.toLocaleString("en-IN", {
                          month: "2-digit",
                        });
                        let day = e.toLocaleString("en-IN", { day: "2-digit" });

                        let startDate = year + month + day;
                        // console.log("startDate", startDate)
                        if (startDate?.length == 8) {
                          setNewObj({ ...newObj, FIELD17: startDate });
                        }
                      }}
                    />
                  );
                } else if (d.S53F03 == "Percentage") {
                  return (
                    <PLNumberBox
                    onKeyDown={(e)=>{
                      if(e.key=="Tab" || e.key=="Enter"){
                          handleSaveUser();
                      }
                    }}
                      max={100}
                      value={row?.original?.FIELD03 == null ?? 0}
                      setEdit={(e) => {
                        // console.log('Percentage', e);
                        setNewObj({ ...newObj, FIELD03: e });
                      }}
                    />
                  );
                }

                return val;
              },
            })
          );
          // }
        });
      setColumns(cols);
    }
  }, [props.grd, newObj]);

  const table = useMantineReactTable({
    columns,
    enableEditing: true,
    editDisplayMode: "row",
    data: M48 ? M48 : [],
    createDisplayMode: "row",
    enableTopToolbar:false,
    enableGlobalFilter:true,
    enableGlobalFilterModes: true,
    mantineTableProps: { withColumnBorders: true },
    getCoreRowModel: getCoreRowModel(),
    initialState: {
      density: "0px",
    },
    state: {
      globalFilter: search,
    },
    // mantineTableContainerProps: { sx: { minHeight: "300px" } },
    mantineTableContainerProps: {
      sx: { height: height * 0.5 },
    },
    mantineTableBodyProps: {
      tabIndex: 0,
      ref: TableRef,
    },
    mantineTableBodyRowProps: ({ row, staticRowIndex, table }) => ({
      tabIndex: 0,
      onKeyUp: (e) => {
        handleRowKeyDown(e, row);
      },
    }),
    // initialState={{ columnVisibility: { address: false } }},
    onCreatingRowCancel: () => setValidationErrors({}),
    // onCreatingRowSave: handleCreateUser,
    onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: handleSaveUser,
    renderRowActions: ({ row, table }) => (
      <Flex gap="md">
        <Tooltip label="Edit">
          <ActionIcon onClick={() => {
            table.setEditingRow(row);
            setTimeout(() => {
              dateRef?.current?.focus()
              }, 500);
            setTableEditingRow(row);
            }}>
            <IconEdit />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Delete">
          <ActionIcon color="red" onClick={() => openDeleteConfirmModal(row)}>
            <IconTrash />
          </ActionIcon>
        </Tooltip>
      </Flex>
    ),
    // renderTopToolbarCustomActions: ({ table }) => (
    //   // <Button
    //   //   onClick={() => {
    //   //     table.setCreatingRow(true);
    //   //   }}
    //   // >
    //   //   Add New
    //   // </Button>
    // ),
    // enableTopToolbar: false
    enableFullScreenToggle: false,
    enableColumnActions: false,
    enableFilters: false,
    enableHiding: false,
    enableDensityToggle: false,
    enablePagination: false,
  });

  const submitCapitalAccount = () => {
    // console.log("submitCapitalAccount ok btn click");
    Promise.resolve(props.getM48(M48))
      .then(() => Store.dispatch(ModalDelete(props.index)))
      .then(() => {
        props?.UserFieldFun();
      });

    // Store.dispatch(ModalFunction({
    //   MTitle: '',
    //   MAction: false,
    //   MSize: '70%',
    //   MBody: () => (''),
    //   MClose: false
    // }))
    // props.UserFieldFun(props.SelectedRowObj)
  };

  return (
    <>
      <PLTextBox
        placeholder="Search Here"
        value={search}
        setEdit={(e) => {
          setSearch(e?.target?.value);
        }}
      />
      <MantineReactTable table={table} />
      <Group
        spacing={"xs"}
        position="center"
        display={"flex"}
        style={{ marginTop: 5 }}
      >
        <Button
          size="sm"
          style={{ width: 100 }}
          onClick={() => submitCapitalAccount()}
          // disabled={submitBtnDisable}
        >
          Ok
        </Button>
      </Group>
    </>
  );
};

export default CapitalAccountTable;
