import { Grid, Text } from "@mantine/core";
import React, { useEffect, useRef, useState } from "react";
import PLDateBox from "../../PlusComponent/PLDateBox";
import { useSelector } from "react-redux";
import { getCurrentDate } from "../../utils/slices/AccountListSlice";
import Store from "../../utils/store";
import { TabAdd } from "../../utils/slices/TabList";
import { ModalDelete } from "../../utils/slices/ModalSlice";
import DataGrid from "../../components/DataGrid";
import GlobalClass from "../../utils/GlobalClass";

const DateRange = (props) => {
  // console.log("props here",props);

  const GetGeneral = useSelector((state) => state?.Language?.General);
  // console.log("GetGeneral",GetGeneral)
  // console.log("GetGeneral?.dYSDate",new Date(GetGeneral?.dYSDate))

  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();

  const fromRef = useRef();
  const toRef = useRef();

  // function dateFormater(date) {
  //   let temp = date?.split("T");
  //   let temp1 = temp[0]?.replaceAll("-", "");
  //   console.log("temp1",temp1)
  //   return temp1;
  // }

  useEffect(() => {
    if (GetGeneral?.dYEDate) {
      setFromDate(new Date(GetGeneral?.dYSDate));
    }
    if (GetGeneral?.dYEDate) {
      setToDate(new Date(GetGeneral?.dYEDate));
    }
  }, [GetGeneral?.dYSDate, GetGeneral?.dYEDate]);

  useEffect(() => {
    setTimeout(() => {
      fromRef.current.focus();
    }, 500);
  }, []);

  return (
    <div>
      <Grid>
        <Grid.Col
          span={12}
          style={{ display: "flex", alignItems: "center", padding: 0 }}
        >
          <Grid.Col span={1}>
            <Text style={{ fontSize: 12 }}>From</Text>
          </Grid.Col>
          <Grid.Col span={5}>
            <PLDateBox
              ref={fromRef}
              autoFocus={true}
              onKeyDown={(e) => {
                if (e?.key == "Tab" || e?.key=="Enter") {
                  setTimeout(() => {
                    toRef?.current?.focus();
                    
                  }, 500);
                }
              }}
              dispformat="DD/MM/YYYY"
              value={fromDate}
              setEdit={(e) => {
                Promise.resolve(
                setFromDate(e)).then(()=>{
                  toRef?.current?.focus();
                })
              }}
            />
          </Grid.Col>
          <Grid.Col span={1}>
            <Text style={{ fontSize: 12 }}>To</Text>
          </Grid.Col>
          <Grid.Col span={5}>
            <PLDateBox
              ref={toRef}
              onKeyDown={(e) => {
                if (e?.key == "Tab" || e?.key == "Enter") {
                  Promise.resolve(
                    Store?.dispatch(
                      getCurrentDate({
                        date: { ...fromDate, From: fromDate, To: toDate },
                        id: props?.obj?.id,
                        flag: true,
                      })
                    )
                  )
                    .then(() => {
                      Store?.dispatch(ModalDelete(props?.index));
                    })
                    .then(() => {
                      // Store.dispatch(TabAdd(props?.obj));
                      GlobalClass.addTab(props?.obj, () => <div id={"tab" + props?.obj?.id} style={{ padding: 5,backgroundColor:"#fff" }}><DataGrid tag="M" IsMultiSelect={false} obj={props?.obj} /></div>)
                    });
                }
              }}
              dispformat="DD/MM/YYYY"
              value={toDate}
              setEdit={(e) => {
                // console.log("e from setEdit To", e);
                setToDate(e);
              }}
            />
          </Grid.Col>
        </Grid.Col>
      </Grid>
    </div>
  );
};

export default DateRange;
