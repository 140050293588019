import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import {
  Select,
  Table,
  Combobox,
  Popover,
  Button,
  Grid,
  Card,
} from "@mantine/core";
import DataGrid from "../components/DataGrid";
import PLTextBox from "./PLTextBox";
import { useDispatch, useSelector } from "react-redux";
import AccountListSlice, {
  GetAccountList,
  GetTMFormat,
  getSelectedState,
} from "../utils/slices/AccountListSlice";
import { setCulture } from "@syncfusion/ej2-base";
import Store from "../utils/store";
import GlobalClass from "../utils/GlobalClass";
var obj;
const PLDataGrid = forwardRef((props, ref) => { // ForwardRef implemented by Vara Pooja
  const {
    type,
    p1,
    p2,
    p0,
    valexpr = "FIELD01",
    dispexpr = "FIELD02",
    id,
    cWhere,
    dropdownPosition
  } = props;
  // console.log("props of dg1", props);
  // console.log("DATAGRIDP1",p1)
  // console.log(type,p1,p2,p0,"..............");
  // console.log("props PLDataGrid=========================>", props);
  const [selectedItem, setSelectedItem] = useState([]);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [Data, setMapData] = useState(null);
  // const [TMFormat, setTMFormat] = useState(null);
  const [selectId, setSelectId] = useState({});
  var mainState = useSelector((state) => state.AccountList);
  // const [cWhere,setCWhere] = useState(null);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 25,
  });
  const [event, setEvent] = useState(null);
  const AccountListData = useSelector(store => store?.AccountList?.AccountListData);//Vara Pooja

  // console.log("AccountListData", AccountListData);
  // console.log("selectedItem", selectedItem);

  {
    /*
    id: "00510001"
    name: "Account List"
    p0: "MT"
    p1: "T_M01"
    p2: ""
    p3: ""
    type: "A"
*/
  }

  if (p1) {
    obj = {
      id: p1,
      name: "",
      type: "A",
      p0: p0,
      p1: p1,
      p2: "",
      p3: "",
    };
  } else {
    // console.log("props.TmData",props);

    obj = {
      name: "",
      type: "A",
      id: id ?? props?.TmData?.split("~C~")[0],
      p0: props.TmData?.split("~C~")[0],
      p1: props.TmData?.split("~C~")[0],
      p2: props.TmData?.split("~C~")[1],
    }

    // switch (type) {
    //   case "ExtraDetailACName":
    //     obj = {
    //       id: "P_M01",
    //       name: "Account Master",
    //       type: "A",
    //       p0: "M01",
    //       p1: "P_M01",
    //       p2: "",
    //       p3: "",
    //     };
    //     break;
    //   case "EntryType_SaleAC":
    //     obj = {
    //       id: "00510001",
    //       name: "Account List",
    //       p0: "MT",
    //       p1: "T_M01",
    //       p2: "",
    //       p3: "",
    //       type: "A",
    //     };
    //     break;
    //   case "Group_Name":
    //     obj = {
    //       id: "P_M11E",
    //       name: "A/c. Group List",
    //       type: "A",
    //       p0: "M11",
    //       p1: "P_M11E",
    //       p2: "",
    //       p3: "",
    //     };
    //     break;
    //   case "Nature_of_Payment":
    //     obj = {
    //       id: "P_M53NO",
    //       name: "Nature Of Payment",
    //       type: "A",
    //       p0: "M53",
    //       p1: "P_M53NO",
    //       p2: "TFORM0000001",
    //       p3: "",
    //     };
    //     break;
    //   case "DepreciationAC":
    //     obj = {
    //       id: "P_M01",
    //       name: "Account Master",
    //       type: "A",
    //       p0: "M01",
    //       p1: "P_M01",
    //       p2: "",
    //       p3: "",
    //     };
    //     break;
    //   case "Area":
    //     obj = {
    //       id: "P_M31A",
    //       name: "Area List",
    //       type: "A",
    //       p0: "M31",
    //       p1: "P_M31A",
    //       p2: "",
    //       p3: "",
    //     };
    //     break;
    //   case "city":
    //     {
    //       /*Error = same data like area*/
    //     }
    //     obj = {
    //       id: "P_M31T",
    //       name: "City List",
    //       type: "A",
    //       p0: "M31",
    //       p1: "P_M31T",
    //       p2: "",
    //       p3: "",
    //     };
    //     break;
    //   case "state":
    //     obj = {
    //       id: "P_PLSTATE",
    //       name: "State",
    //       type: "A",
    //       p0: "PLSTATE",
    //       p1: "P_PLSTATE",
    //       p2: "",
    //       p3: "",
    //     };
    //     break;
    //   case "Type_of_Deducte":
    //     obj = {
    //       id: "P_M53DT",
    //       name: "Deductee Type",
    //       type: "A",
    //       p0: "M53",
    //       p1: "P_M53DT",
    //       p2: "",
    //       p3: "",
    //     };
    //     break;
    //   case "TDSAC":
    //     obj = {
    //       id: "P_M01",
    //       name: "Account Master",
    //       type: "A",
    //       p0: "M01",
    //       p1: "P_M01",
    //       p2: "",
    //       p3: "",
    //     };
    //     break;
    //   case "TDS_AC":
    //     obj = {
    //       id: "P_M01",
    //       name: "Account Master",
    //       type: "A",
    //       p0: "M01",
    //       p1: "P_M01",
    //       p2: "",
    //       p3: "",
    //     };
    //     break;
    //   case "Type_of_Deductee":
    //     obj = {
    //       id: "P_M53DT",
    //       name: "Deductee Type",
    //       type: "A",
    //       p0: "M53",
    //       p1: "P_M53DT",
    //       p2: "",
    //       p3: "",
    //     };
    //     break;
    //   case "interestAC":
    //     obj = {
    //       id: "P_M01",
    //       name: "Account Master",
    //       type: "A",
    //       p0: "M01",
    //       p1: "P_M01",
    //       p2: "",
    //       p3: "",
    //     };
    //     break;
    //   case "tds_AC":
    //     obj = {
    //       id: "P_M01",
    //       name: "Account Master",
    //       type: "A",
    //       p0: "M01",
    //       p1: "P_M01",
    //       p2: "",
    //       p3: "",
    //     };
    //     break;
    //   case "sales setup":
    //     obj = {
    //       id: "",
    //       name: "Account Master",
    //       type: "A",
    //       p0: "V",
    //       p1: "SS",
    //       p2: "",
    //       p3: "",
    //     };
    //     break;
    //   case "address_city":
    //     obj = {
    //       id: "P_M31T",
    //       name: "City List",
    //       type: "A",
    //       p0: "M31",
    //       p1: "P_M31T",
    //       p2: "",
    //       p3: "",
    //     };
    //     break;
    //   case "address_area":
    //     obj = {
    //       id: "P_M31A",
    //       name: "Area List",
    //       type: "A",
    //       p0: "M31",
    //       p1: "P_M31A",
    //       p2: "",
    //       p3: "",
    //     };
    //     break;
    //   case "address_category":
    //     obj = {
    //       id: "P_M31R",
    //       name: "Category List",
    //       type: "A",
    //       p0: "M31",
    //       p1: "P_M31R",
    //       p2: "",
    //       p3: "",
    //     };
    //     break;

    //   // case "address_category":
    //   // obj = {
    //   //   "id": 'P_M01',
    //   //   "name": "Account Master",
    //   //   "type": "A",
    //   //   "p0": "M01",
    //   //   "p1": "P_M01",
    //   //   "p2": "",
    //   //   "p3": ""
    //   // }
    //   // break;

    //   default:
    //     obj = {
    //       id: "00540001",
    //       name: "A/c. Group",
    //       type: "A",
    //       p0: "MT",
    //       p1: "T_M11",
    //       p2: "",
    //       p3: "",
    //     };
    //     break;
    // }
  }
  const dispatch = useDispatch();
  // useEffect(() => {
  //   console.log(obj?.p1, "obj?.p1---");
  //   if (obj?.p1 != undefined) {
  //     Promise.all([
  //       dispatch(
  //         GetAccountList({
  //           ...obj,
  //           StrtDt: StrtDt ?? GetGeneral?.dYSDate,
  //           EndDt: EndDt ?? GetGeneral?.dYEDate,
  //         })
  //       ),
  //       dispatch(
  //         GetTMFormat({
  //           ...obj,
  //           StrtDt: GetGeneral?.dYSDate,
  //           EndDt: GetGeneral?.dYEDate,
  //         })
  //       ),
  //     ]);
  //   }
  // }, [obj.p1]);
  // useEffect(()=>{
  //   if(mainState?.AccountListData[type]){
  //     setMapData(mainState?.AccountListData[type]);
  //   }

  // },[mainState?.AccountListData[type]])

  useEffect(() => {
    if (props.value && props.data) {
      const array = props?.value?.split(',');  //Yasvi Patel (for multi  select)
      if (array.length > 1) {
        let newArr1 = [];
        array?.map((n1, i) => {
          let temp = [];
          // if (newArr instanceof Array) {
          temp = props?.data.filter((n2, j) => n2?.[valexpr] == n1);
          // console.log(temp, "selectedItem2");
          if (temp?.length > 0) {

            newArr1?.push({
              label: temp[0]?.[dispexpr],
              value: temp[0]?.[valexpr],
            });
          }
          // }
          // });
          // setSelectedItem(newArr1);
        });
        setSelectedItem(newArr1);

      } else {
        props.data.map((e) => {
          if (e[valexpr] == props.value) {
            setSelectedItem({ label: e[dispexpr], value: e[valexpr] });
            setSelectId({ [e[valexpr]]: true });
          }
        });
      }
      props.data.map((e, index) => {
        if (e[valexpr] == props.value) {
          // setSelectedItem({ label: e[dispexpr], value: e[valexpr] });
          // setSelectId({ [e[valexpr]]: true });
          let pageNo = (index + 1) / pagination.pageSize;
          let pageNo1 = Math.floor(pageNo)
          setPagination({ ...pagination, pageIndex: pageNo1 })//Vara Pooja
        }
      });
    } else if (typeof props?.value == "object") {
      setSelectedItem({ label: props?.value?.label, value: props?.value?.value });
    } //Vara Pooja
  }, [props.data, props.value]);

  useEffect(() => {
    if (props.data && props.TmData) {
      setMapData(props.data);
      // setTMFormat(props.TmData);
    }
  }, [props.data, props.TmData]);



  // const 

  const CustomDropdown = (e) => {
    // console.log("CustomDropdown", e, selectedItem); 
    return (
      <div>
      {props?.isLoading ? GlobalClass.RenderLoader("dots") : //Yasvi Patel
        <DataGrid
          isButton={props?.isButton}//Vara Pooja
          pagination={pagination}//Vara Pooja
          cWhere={cWhere ?? props?.TmData?.split("~C~")[2]}
          selectedData={selectId}
          selectionId={valexpr} //Nairin Salot
          ValueEdit={(e) => {
            // console.log("OKBTN IN PL", e);
            if (e?.length > 0) {
              let newArr = e?.map((v) => v?.original);
              let newArr1 = [];
              if (props?.pid) {
                AccountListData[props?.pid]?.map((n1, i) => {
                  let temp = [];
                  if (newArr instanceof Array) {
                    temp = newArr?.filter((n2, j) => n2[valexpr] == n1[valexpr]);
                    // console.log(temp, "selectedItem2");
                    if (temp?.length > 0) {

                      newArr1?.push({
                        label: temp[0][dispexpr],
                        value: temp[0][valexpr],
                      });
                    }
                  }
                  // });
                  // setSelectedItem(newArr1);
                });
              }//temporarily implemented for filter button//Vara Pooja
              else {
                Data?.map((n1, i) => {
                  let temp = [];
                  if (newArr instanceof Array) {
                    temp = newArr?.filter((n2, j) => n2[valexpr] == n1[valexpr]);
                    // console.log(temp, "selectedItem2");
                    if (temp?.length > 0) {

                      newArr1?.push({
                        label: temp[0][dispexpr],
                        value: temp[0][valexpr],
                      });
                    }
                  }
                  // });
                  // setSelectedItem(newArr1);
                });
              }

              // console.log(newArr1, "newObj");
              setSelectedItem(newArr1);
              // setSelectId({ [e?.FIELD01]: true });

              props.setEdit(e);
              if(props?.handleClose && typeof props?.handleClose == 'function'){
                props?.handleClose(event);
              }
            } else if (typeof e == "object") {
              if(dispexpr in e){
                setSelectedItem({
                  label: e[dispexpr],
                  value: e[valexpr],
                });
              }
              else{
                var secondKey = Object.keys(e)[1];
                if (!secondKey){
                  secondKey = Object.keys(e)[0];
                }
                setSelectedItem({
                  label: e[secondKey],
                  value: e[valexpr],
                });
              }
              
              props.setEdit(e);
              if(props?.handleClose && typeof props?.handleClose == 'function'){
              props?.handleClose(event);
              }
            } else {
              // console.log(e, "SingleSelect");

              setSelectedItem([]);
              props.setEdit(e);
              if(props?.handleClose && typeof props?.handleClose == 'function'){
              props?.handleClose(event);
              }
            }
          }}
          form={true}
          // TmData={TMFormat}
          obj={obj}
          data={Data}
          size={props?.size}
          setPopOver={setIsPopoverOpen}
          IsMultiSelect={props?.isMulti ?? false}
        />}
        </div>
    );
  };
  useEffect(() => {
    // console.log(selectedItem, "setSelectedItem");
    if (selectedItem?.value?.length > 1 && typeof selectedItem == "object") { //Nairin
      // console.log(selectedItem?.value, "fefre");

      var temp3 = {};
      if (typeof ",".includes(selectedItem?.value)) { //Nairin Salot
        // console.log(selectedItem?.value, "props?.value?.valu");
        selectedItem?.value?.split(",")?.map((v) => {
          // console.log([v], "e000", v);
          temp3 = { ...temp3, [v]: true };
        });
        // console.log(temp3, "temp3");
        setSelectId(temp3);
      } else {
        // console.log(selectedItem, "INElse");
        setSelectId({ [selectedItem?.value]: true });
      }
    } else if (selectedItem?.length > 0) {
      // console.log(selectedItem , "selectedItem?.length")
      const output = {};
      selectedItem.forEach((index) => {
        output[index?.value] = true;
      });
      setSelectId(output);
    } else {
      setSelectId({});
    }
  }, [selectedItem]); //Nairin Salot

  useEffect(() => {
    //Nairin Salot
    // console.log(props.value, "selectedItem--");
    if (props.value == "") {
      setSelectedItem({});
    }
  }, [props.value]);

  const CustomItem = (e) => <div>{e}</div>;
// useEffect(()=>{

// },[props?.autoFocus])
  useImperativeHandle(ref, () => ({
    handlePopoverToggle() {
      // console.log("hi");
      setIsPopoverOpen(!isPopoverOpen);
    },
  }));

  const handlePopoverToggle = (e) => {
    // console.log("handlePopoverToggle");
    setIsPopoverOpen(e ? (e) : (!isPopoverOpen));
  };//Vara Pooja

  return (
    <>
      <div style={{ position: "unset" }}>
        <Popover
          // id={props?.id}
          width={props.width ? props.width :"target"} //props.width ? props.width :
          // width={props.width ? props.width :"500px"}
          // opened
          trapFocus
          withinPortal
          position={props.position ? props.position : "bottom"}
          offset={0}
          zIndex={400}
          opened={isPopoverOpen}
          // styles={{ dropdown: { position: dropdownPosition == true ? 'sticky' : '' } }}
          onClose={() => setIsPopoverOpen(false)}
          clickOutsideEvents={["mouseup", "touchend"]}
          disabled={props.disabled}

        >
          <Popover.Target>
            <Button
              id={props?.id}
              autoFocus={props?.autoFocus}
              onBlur={props?.setBlur}
              disabled={props?.disabled}
              // label={props.value?.label}
              p={0}
              {...props}
              variant="default"
              fullWidth
              onFocus={(e) => {
                if (props?.setFocus && typeof props?.setFocus == 'function') {
                  // console.log(e);
                  props?.setFocus(e)
                }
                if (props?.openOnFocus == true) {
                  setIsPopoverOpen(true)
                }
                let ev = {...e,key:"Enter",preventDefault:()=>{}};
                setEvent(ev)
              }}
            
              onKeyDown={(e) => {
                if (props?.onKeyDown && typeof props?.onKeyDown == 'function') {
                  props?.onKeyDown(e)
                }

              }}
              onClick={() => handlePopoverToggle()}
              style={{
                padding: "0px 5px",
                display: "flex",
                alignItems: "flex-start",
                fontWeight: "normal",
                fontSize: "12px",
                overflow: "hidden",
                height: props?.height//Yasvi Patel
              }}
              size="xs"
            >
              {/* {console.log(
                typeof selectedItem == "object",
                "selectedIteminbutton",
                isPopoverOpen,
                selectedItem
              )} */}
              {selectedItem?.length > 0 && selectedItem != []
                ? selectedItem?.map((item) => item?.label).join(", ")
                : typeof selectedItem == "object" &&
                  selectedItem instanceof Array == false &&
                  selectedItem?.value != undefined && selectedItem?.label != "" && selectedItem?.label != null
                  ? selectedItem?.label
                  : mainState.isLoading[p1]
                    ? "Loading..."
                    :  "Select" } 
            </Button>
          </Popover.Target>
          <Popover.Dropdown style={{ zIndex: -1,padding:0,boxShadow:"0 0 10px rgba(0,0,0,0.3)" }}>
            {CustomDropdown()}
          </Popover.Dropdown>
        </Popover>
      </div>
    </>
  );
});

export default PLDataGrid;
