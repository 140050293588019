import { useDisclosure, useHotkeys, useLocalStorage, useWindowEvent } from "@mantine/hooks";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteCmpEnt,
  GetAddCmpEnt,
  GetMenuList,
  GetUserList,
  SetCmpNumber,
} from "../../utils/slices/UserListSlice";
import { modals } from "@mantine/modals";
import { ClearTabs } from "../../utils/slices/TabList";
import { GetYearDateUrl, SendOldYearDateData } from "../../utils/slices/CompanySetupSlice";
import { GetLanguageData } from "../../utils/slices/LanguageSlice";
import Localize from "../../utils/rc";
import {
  Button,
  Grid,
  Group,
  Header,
  Loader,
  Modal,
  Progress,
  Text,
} from "@mantine/core";
import GlobalClass from "../../utils/GlobalClass";
import AddNewCompanyForm from "./AddNewCompanyForm";
import { ModalFunction } from "../../utils/slices/ModalSlice";
import Store from "../../utils/store";
import useWindowDimensions from "../../utils/UseWindowDimensions";
import ImportCompany from "./ImportCompany";
import UtilityCompany from "./UtilityCompany";
import { YearAndDateTable } from "../YearAndDate/YearAndDateTable";

export const ChangeCompanyTable = (props) => {
  // let GetLanguage = new Localize();

  console.log("ChangeCompanyTable =>", props)

  const [columns, setColumns] = useState([]);
  const [Data, setData] = useState([])
  // const [selectedCmp, setSelectedCmp] = useState({ cmpNo: "", cmpName: "" });
  // const [cmpLoading, setCmpLoading] = useState(false);
  const [companyModal, cmpAction] = useDisclosure(false);
  const [rowSelection, setRowSelection] = useState({});

  // const langisLoading = useSelector((state) => state.Language?.isLoading);
  // const LangData = useSelector((state) => state.Language?.LanguageData);
  // const yrisLoading = useSelector((state) => state.CompanySetup?.isLoading);

  const { height, width } = useWindowDimensions();
  const [isLoaded, setIsLoaded] = useLocalStorage({
    key: 'Loaded',
    defaultValue: false,
    getInitialValueInEffect: true,
  });

  const [isGrouping, setIsGrouping] = useState(false);
  const [expanded, setIsExpanded] = useState(false);
  const [focused, setIsfocused] = useState(false);
  const [YearPopupFlag, setYearPopupFlag] = useState(false)


  const {
    isLoading,
    UserListData,
    isMenuLoading,
    CmpNumber,
    hasError: UserListHasError,
    ErrorMsg: UserListError,
  } = useSelector((state) => state.UserList);

  const GetYearDateData = useSelector((state) => state.CompanySetup?.GetYearDateData)
  const GetYearDateDataOld = useSelector((state) => state.CompanySetup?.GetYearDateDataOld)


  const dispatch = useDispatch();

  useEffect(() => {
    if (GetYearDateData && YearPopupFlag) {
      let SelectRow = table.getSelectedRowModel().flatRows[0].original
      console.log("SelectFinancialYearModal");
      new Promise((resolve) => {
        Store.dispatch(
          ModalFunction({
            onclose: () => { },
            MTitle: <Text fw={700}>Select Financial Year</Text>,
            MAction: true,
            MSize: "sm",
            MBody: (i) => (<YearAndDateTable SelectCmpRow={SelectRow} index={i} yearIndex={props?.cmpAction} />),
            MClose: true,
            Overlay: {
              backgroundOpacity: 0.8,
              blur: 5,
            },
            MCentered: true,
            closeOnClickOutside: true,
            onClickCloseButton: () => {
              Store.dispatch(SendOldYearDateData(GetYearDateDataOld))
              // new Promise((resolve) => {
              //   Store.dispatch(SendOldYearDateData(GetYearDateDataOld))
              //   resolve()
              // }).then(() => setYearPopupFlag(false))
            }
          })
        );
        resolve()
      }).then(() => setYearPopupFlag(false))

    }
  }, [GetYearDateData, YearPopupFlag])

  useEffect(() => {
    // console.log("UserListData", UserListData)
    if (UserListData && UserListData?.cmpList) {
      setData(UserListData?.cmpList)
    }
  }, [UserListData])

  useEffect(() => {
    if (UserListData && UserListData?.cmpList) {
      let col = [];
      Object.keys(UserListData?.cmpList[0]).map((key) => {
        col.push({
          accessorKey: key,
          header: key == "cmpNo" ? "No." : (key == "cmpName" ? "Company Name" : "Group"),
          size: (key == "cmpNo" ? 24 : (key == "cmpName" ? 300 : 40)),
          mantineTableBodyCellProps: {
            align: key == "cmpNo" ? "center" : "left",
          },
          mantineTableHeadCellProps: {
            align: key == "cmpNo" ? "center" : "left",
          },
          enableHiding: false,
          enableColumnFilter: false,
          enableSorting: true,
          enableGlobalFilter: true,
        });
      });
      setColumns(col);
      setSelected();
    }
  }, [UserListData?.cmpList, isGrouping]);

  const handleAdd = () => {
    Store.dispatch(
      ModalFunction({
        onclose: () => { },
        MTitle: <Text fw={700}>Add New Company</Text>,
        MAction: true,
        MSize: "70%",
        MBody: (i) => <AddNewCompanyForm action={"A"} index={i} />,
        MClose: true,
        // Overlay: {
        //   backgroundOpacity: 0.8,
        //   blur: 5,
        // },
        MCentered: true,
        closeOnClickOutside: true,
        zIndex: "260"
      })
    );
  };

  const handleEdit = () => {
    Store.dispatch(
      ModalFunction({
        onclose: () => { },
        MTitle: <Text fw={700}>Edit Company</Text>,
        MAction: true,
        MSize: "70%",
        MBody: (i) => (
          <AddNewCompanyForm
            TableRow={table.getSelectedRowModel().flatRows[0].original}
            action={"E"}
            index={i}
          />
        ),
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        },
        MCentered: true,
        closeOnClickOutside: true
      })
    );
  };

  const handleCmpInfo = (CmpRow) => {
    console.log("handleCmpInfoCmpRow =>", CmpRow);

    Store.dispatch(
      ModalFunction({
        onclose: () => { },
        MTitle: <Text fw={700}>{CmpRow?.cmpName}</Text>,
        MAction: true,
        MSize: "sm",
        MBody: (i) => (
          <AddNewCompanyForm
            TableRow={CmpRow}
            action={"CmpInfo"}
            index={i}
          />
        ),
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        },
        MCentered: true,
        closeOnClickOutside: true
      })
    );
  }

  const handleDelete = () => {
    // dispatch(GetAddCmpEnt({ cmpNo: TableRow?.cmpNo, action: "E" }))

    let TableRow = table.getSelectedRowModel().flatRows[0].original;

    // if (window.confirm("Confirm to Delete ?")) {
    //   Promise.resolve(
    //     GlobalClass.Notify(
    //       "info",
    //       "Info: " + "Delete Progress",
    //       "Please Wait Fetching Data"
    //     )
    //   ).then(() =>
    //     Store.dispatch(DeleteCmpEnt({ cmpNo: TableRow?.cmpNo, action: "D" }))
    //   );
    // }

    GlobalClass.Confirm(
      "red",
      "Confirm to Delete ?",
      "It will be Deleted Forever and Cannot Be Recovered!!!",
      () => {
        Promise.resolve(
          GlobalClass.Notify(
            "info",
            "Info: " + "Delete Progress",
            "Please Wait Fetching Data"
          )
        ).then(() =>
          Store.dispatch(DeleteCmpEnt({ cmpNo: TableRow?.cmpNo, action: "D" }))
        );
      },
      () => { },
      "Cancel",
      "Confirm"
    )
  };

  const setSelected = (id = "") => {
    let scmp = localStorage.getItem("cmp");
    if (scmp) {
      // setCmpLoading(false);
      // cmpAction.open();

      // setSelectedCmp(data[0]);
      dispatch(SetCmpNumber(localStorage.getItem("cmp")));
      var data = UserListData.cmpList.findIndex((e) => e["cmpNo"] == scmp);
      if (data) {
        setRowSelection({ [data]: true })
      }
      else {
        setRowSelection({ 0: true })
      }
      // console.log("selected", data);
    }
  };


  useWindowEvent('keydown', (e) => {
    if (e.key == "Enter") {
      UserListData?.cmpList?.map((t, i) => {
        if (i in rowSelection) {
          console.log("document.addEventListener =>", t);
          e.preventDefault();
          if (localStorage.getItem("cmp") == t["cmpNo"]) {
            modals.closeAll();
          } else {
            localStorage.setItem("YrNo", "");
            localStorage.setItem("cmp", t["cmpNo"]);
            setSelected(t["cmpNo"]);
            dispatch(SetCmpNumber(t["cmpNo"]));
            dispatch(ClearTabs(""));
            window.location.reload();
          }
        }
      })
    }

  });
  const rowRefs = useRef([]);

  useEffect(() => {
    const selectedRowId = Object.keys(rowSelection).find((key) => rowSelection[key]);
    if (selectedRowId && rowRefs.current[selectedRowId] && !focused) {
      // localStorage.setItem("YrNo","")
      rowRefs.current[selectedRowId].scrollIntoView({ behavior: 'smooth', block: 'center' });
      setIsfocused(true);
    }
  }, [rowSelection]);

  const [search, setSearch] = useState("");
  const alphaArr = GlobalClass.keyArr;

  let HotKeys = alphaArr?.map((btn, i) => [
    btn,
    () =>
      btn == "backspace"
        ? setSearch((prev) => prev.slice(0, -1))
        : setSearch((prev) => prev + btn),
  ]);
  useHotkeys(HotKeys);

  const table = useMantineReactTable({
    data: Data,
    columns,
    enableTopToolbar: false,
    positionToolbarAlertBanner: false,
    // enableFilters: true,
    enableBottomToolbar: true,
    mantineTableContainerProps: {
      sx: {
        height: height * 0.45,
      },
    },
    initialState: {
      density: "5px",
      // columnVisibility:{
      //   "cmpGrp":false
      // }
    },
    onExpandedChange: setIsExpanded,
    enableColumnOrdering: false,
    enableStickyHeader: true,
    enableColumnResizing: false,
    columnResizeMode: "onChange",
    enableGrouping: isGrouping,
    enableRowVirtualization: false,
    enablePagination: false,
    enableSelectAll: false,
    enableMultiRowSelection: false,
    enableGlobalFilter: true,
    enableDensityToggle: false,
    enableColumnActions: false,
    enableColumnFilters: false,
    enableColumnDragging: false,
    enableFullScreenToggle: false,
    enableHiding: false,
    enableGlobalFilterModes: false,
    enableTableFooter: true,
    enableStickyFooter: true,
    enableColumnFilterModes: false,
    // groupedColumnMode:false,

    state: {
      rowSelection,
      density: "0px",
      globalFilter: search,
      showAlertBanner: UserListHasError,
      showProgressBars: isLoading,
      showLoadingOverlay: isLoading,
      columnVisibility: {
        "cmpGrp": isGrouping
      },
      grouping: ['cmpGrp'],
      expanded,
      columnOrder: [
        'mrt-row-expand',
        'cmpGrp',
        'cmpNo',
        'cmpName',
      ],
    },

    mantineTableBodyProps: {
      sx: {
        tableLayout: 'fixed', // Apply fixed layout to the body to prevent auto resizing
      },
    },
    renderBottomToolbar: () => (
      <Group
        style={{
          marginTop: "5px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button variant="outline" onClick={() => handleAdd()} compact>
          New
        </Button>

        <Button
          variant="outline"
          onClick={() =>
            table.getSelectedRowModel().flatRows.length > 0
              ? handleEdit()
              : GlobalClass.Notify("info", "Info", "Please Select Company")
          }
          compact
        >
          Edit
        </Button>

        <Button
          variant="outline"
          onClick={() =>
            table.getSelectedRowModel().flatRows.length > 0
              ? handleDelete()
              : GlobalClass.Notify("info", "Info", "Please Select Company")
          }
          compact
        >
          Delete
        </Button>

        <Button
          variant="outline"
          onClick={() => {
            // GetYearDateUrl
            console.log("TableSelectedRow", table.getSelectedRowModel().flatRows[0].original)
            let SelectRow = table.getSelectedRowModel().flatRows
            if (SelectRow?.length > 0) {
              new Promise((resolve) => {
                Store.dispatch(GetYearDateUrl({
                  cmpNo: SelectRow[0].original?.cmpNo
                }))
                resolve()
              }).then(() => {
                setYearPopupFlag(true)
              })

            }

          }}
          compact
        >
          Year
        </Button>

        <Button
          variant="outline"
          onClick={() => {
            let SelectRow = table.getSelectedRowModel().flatRows
            if (SelectRow?.length > 0) {
              handleCmpInfo(SelectRow[0].original)
            } else {
              GlobalClass.Notify("info", "Info", "Please Select Company")
            }

            // table.getSelectedRowModel().flatRows.length > 0
            //   ? handleCmpInfo()
            //   : GlobalClass.Notify("info", "Info", "Please Select Company")
          }}
          compact
        >
          Cmp Info
        </Button>

        <Button
          variant="outline"
          onClick={() => {
            setIsGrouping(!isGrouping)
            setIsExpanded(isGrouping)

          }}
          compact
        >
          {isGrouping ? "Simple" : "Group"}
        </Button>

        <Button variant="outline" onClick={() => {
          // table.getSelectedRowModel().flatRows.length > 0
          // ?
          Store.dispatch(ModalFunction({
            MTitle: <Text size="lg" weight={600} align="center" style={{ color: '#333' }}>
              Import Software Data
            </Text>,
            MAction: true,
            MSize: "auto",
            // position:'right',
            fullScreen: false,
            MBody: (i) => (<ImportCompany company={table.getSelectedRowModel().flatRows[0]?.original?.cmpNo} />),
            MClose: true,
            // tab:OBJ.page.startsWith("P_")?false:true,
            onclose: () => { },
            onClickCloseButton: () => {
              // Promise.resolve(Store.dispatch(GetInvTypeDropDown(null))).then(() => Store.dispatch(GetPendingQuotationDataNull([]))).then(() => Store.dispatch(GetSingleSelectedOrderRowNull([]))).then(() => Store.dispatch(GetPendingOrderDataNull([]))).then(() => Store.dispatch(GetSingleOrderRowNull([]))).then(() => Store.dispatch(GetExpenseDataNull([]))).then(() => Store.dispatch(GetNullTransactionData()))
              // GetPendingOrderDataNull, GetSingleOrderRowNull
            }
          }))
          // : GlobalClass.Notify("info", "Info", "Please Select Company")
        }} compact>
          Import
        </Button>

        <Button
          variant="outline"
          compact
          onClick={() => {
            Store.dispatch(ModalFunction({
              MTitle: <Text size="lg" weight={600} align="center" style={{ color: '#333' }}>
                Utility
              </Text>,
              MAction: true,
              MSize: "auto",
              // position:'right',
              fullScreen: false,
              MBody: (i) => (<UtilityCompany
                index={i}
              />),
              MClose: true,
              onclose: () => { },
              onClickCloseButton: () => { }
            }))
          }}
        >
          Utility
        </Button>
      </Group>
    ),
    mantineTableBodyRowProps: ({ row }) => ({

      ref: (el) => (rowRefs.current[row.id] = el),
      onClick: () => {
        if (!row.getCanExpand()) {
          setRowSelection((prev) => ({
            [row.id]: !prev[row.id],
          }));
        }
      },
      onKeyDown: (e) => {
        console.log(e.key);

        if (e.key == "Enter" && !row.getCanExpand()) {
          e.preventDefault();
          if (localStorage.getItem("cmp") == row.getValue("cmpNo")) {
            modals.closeAll();
            // cmpAction.close();
            // setCmpLoading(false);
          } else {
            localStorage.setItem("YrNo", "");
            localStorage.setItem("cmp", row.getValue("cmpNo"));
            setSelected(row.getValue("cmpNo"));
            dispatch(SetCmpNumber(row.getValue("cmpNo")));
            // setCmpLoading(true);
            // modals.closeAll();
            dispatch(ClearTabs(""));
            window.location.reload();
          }
        }
      },
      onDoubleClick: () => {
        console.log("localStorage", localStorage.getItem("cmp"));

        if (localStorage.getItem("cmp") == row.getValue("cmpNo")) {
          props.cmpAction.close()
          // cmpAction.close();
          // setCmpLoading(false);
        } else if (!row.getCanExpand()) {
          localStorage.setItem("YrNo", "");
          localStorage.setItem("cmp", row.getValue("cmpNo"));
          setSelected(row.getValue("cmpNo"));
          dispatch(SetCmpNumber(row.getValue("cmpNo")));
          // setCmpLoading(true);
          // modals.closeAll();
          dispatch(ClearTabs(""));
          window.location.reload();
        }
      },
      sx: { cursor: "pointer", backgroundColor: rowSelection[row.id] ? "Highlight" : '' },
    }),
    mantineTableProps: { withColumnBorders: true }
  });

  // useEffect(() => {
  //   if (cmpLoading) {
  //     dispatch(GetLanguageData())
  //   }
  // }, [cmpLoading])

  // useEffect(() => {
  //   if (cmpLoading && !langisLoading) {
  //     var i = 0;
  //     var intv = setInterval(() => {
  //       if (i < 33) {
  //         setProgressBar(i + 1);
  //         i++;
  //       }
  //       else {
  //         clearInterval(intv)
  //         dispatch(GetYearDateUrl())
  //       }
  //     }, 50)
  //   }
  // }, [langisLoading])

  // useEffect(() => {
  //   if (cmpLoading && !yrisLoading) {
  //     var i = 33;
  //     var intv = setInterval(() => {
  //       if (i < 66) {
  //         setProgressBar(i + 1);
  //         i++;
  //       }
  //       else {
  //         clearInterval(intv)
  //         dispatch(GetMenuList());
  //       }
  //     }, 50)
  //   }
  // }, [yrisLoading])

  // useEffect(() => {
  //   if (cmpLoading && !isMenuLoading) {
  //     var i = 66;
  //     var intv = setInterval(() => {
  //       if (i < 100) {
  //         setProgressBar(i + 1);
  //         i++;
  //       }
  //       else {
  //         clearInterval(intv)
  //         setCmpLoading(false)
  //         setProgressBar(0);
  //         setLanguageData(GetLanguage.GetLanguageList());
  //         // cmpAction.close();
  //         modals.closeAll()

  //       }
  //     }, 50)
  //   }
  // }, [isMenuLoading])
  return (<>
    <MantineReactTable table={table} />
  </>);
};
