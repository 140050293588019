import {
  ActionIcon,
  Box,
  Button,
  Checkbox,
  Divider,
  Drawer,
  Flex,
  Grid,
  Group,
  Modal,
  ScrollArea,
  Text,
  Textarea,
  Tooltip,
  createStyles,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import PLTextBox from "../../../PlusComponent/PLTextBox";
import PLComboBox from "../../../PlusComponent/PLComboBox";
import PrintingOption from "./InnerBtnForms/PrintingOption";
import GraphFormat from "./InnerBtnForms/GraphFormat";
import Store from "../../../utils/store";
import { ModalDelete, ModalFunction } from "../../../utils/slices/ModalSlice";
import CustomCol from "./InnerBtnForms/CustomCol";
import ReportHeader from "./InnerBtnForms/ReportHeader";
import ReportSummary from "./InnerBtnForms/ReportSummary";
import { useDispatch, useSelector } from "react-redux";
import { GetFormatEnv, GetFormatList } from "../../../utils/FormatSlice/Format";
import GlobalClass from "../../../utils/GlobalClass";
import PLTextarea from "../../../PlusComponent/PLTextarea";
import PLNumberBox from "../../../PlusComponent/PLNumberBox";
import { createColumnHelper } from "@tanstack/react-table";
import { MRT_TablePagination, MantineReactTable, useMantineReactTable } from "mantine-react-table";
import useWindowDimensions from "../../../utils/UseWindowDimensions";
import ButtonPanel from "../../../PlusComponent/ButtonPanel";
import PLDataGrid from "../../../PlusComponent/PLDataGrid";
import { IconEdit } from "@tabler/icons-react";
import { DataSaveLoading } from "../../../utils/slices/DataSaveLoadingSlice";
import gensetting from "../../../utils/gensetting";
import AdvanceOptionBtn from "./InnerBtnForms/AdvanceOptionBtn";
import MultiHeaderFooter from "./InnerBtnForms/MultiHeaderFooter";
import { GetAccountList, GetAllForamt, GetTMFormat } from "../../../utils/slices/AccountListSlice";
const useStyles = createStyles((theme) => ({
  button: {
    "&:disabled, &[data-disabled]": {
      // Add your custom styles for disabled buttons here
      borderColor: "0.0625rem solid transparent",
      backgroundColor: "#228be6",
      color: "white",
      cursor: "pointer",
      pointerEvents: "auto",
    },
  },
}));
export default function NewBtnReportFormatForm(props) {
  const { DrawerOBJ: draw, setDrawerOBJ: setClose, OBJ } = props
  console.log("NewBtnReportFormatForm", props)
  const { classes } = useStyles();
  let dra = {
    title: "",
    size: "md",
    body: "",
    open: false,
  };
  const { height, width } = useWindowDimensions();
  const {
    AccountListData: accList,
    TMFormatData: tmList,
  } = useSelector((state) => state.AccountList);
  console.log("tmList", tmList[OBJ?.data?.cSTID])
  const SaveLoading = useSelector(
    (state) => state?.DataSaveLoadingSlice?.SaveLoading
  );
  const { oYear } = useSelector((state) => state?.Language?.General);
  console.log("oYear", oYear)
  const isLoading = useSelector((state) => state.FormatSlice?.isLoading)
  const accLedgerData = useSelector((state) => state.FormatSlice?.AccData)
  console.log("accLedgerData", accLedgerData)
  const { COLHKEY, COP1, COPF21, COPF35, COPF70, COPF73, COPGPH, COPGPT, COPPPR, ColDT, ColEnt, CustColDT, CustColENT, CustColHKey, F14DIC, GrpDT, GrpEnt, GrpHKEY, MLFootDT, MLFootENT, MLFoothKey, MLHeadDT, MLHeadENT, MLHeadhKey, OS62, RepHeadDT, RepHeadENT, RepHeadHKey, RepSumDT, RepSumENT, RepSumhKey, M47list } = accLedgerData || {}
  console.log("OS62", OS62)

  const [DrawerObj, setDrawerObj] = useState(dra);
  const [OS62Obj, setOS62Obj] = useState({})
  const [F14DICObj, setF14DICObj] = useState({})
  const [content, setContent] = useState("form")
  const [grpColumns, setGrpColumns] = useState([])
  const [grpData, setGrpData] = useState([])
  const [grpObj, setGrpObj] = useState(
    {
      "RECTYPE": "REG",
      "REPORTID": "LEDMAIN",
      "COLRECID": "",
      "DISPCAP": "",
      "DISPCAPID": "",
      "USEDCAP": "",
      "USEDCAPID": "",
      "CTRLSOURCE": "",
      "SOURCEDEF": "",
      "DATATYPE": "",
      "UNBOUND": "",
      "COLWIDTH": 0,
      "COLDEC": " ",
      "DISPFORMAT": "",
      "LTOTAL": "",
      "LGROUP": "",
      "ORDEXPR": "",
      "GRPEXPR": "",
      "DISPCOND": "",
      "GRPSEQ": "",
      "RECSEQ": "",
      "GRPCAPID": "",
      "GRPCAP": "",
      "SPFLDFLAG": "   ",
      "COLPROP": "",
      "COLDATA": "",
      "TOTEXPR": "",
      "LSTRETCH": "",
      "LLEAF": "",
      "SELEXPSRC": "",
      "LORDER": "",
      "SUPFLAG": " ",
      "INCOND": "",
      "INCONDVFP": null,
      "ALIGNMENT": "",
      "FONTNAME": "",
      "FONTSIZE": "",
      "FONTSTYLE": "",
      "BACKCOLOR": "",
      "FORECOLOR": "",
      "COLSUPRESS": "",
      "CLICKDET": "",
      "LCOLUMNGRP": "",
      "DTCODEFLD": "",
      "MTBLNAME": "",
      "MCODEFLD": "",
      "MDISPFLD": "",
      "MFILTER": "",
      "S61FTY": "",
      "FCSOURCE": "",
      "FILTTYPE": " ",
      "FILTPROP": "",
      "FDEFVAL": "",
      "FCUSTEXPR": "",
      "BUNIID": 0,
      "S61CHARID": "A",
      "FGSOURCE": "",
      "lOk": null,
      "ORFLDNM": "",
      "LACTIVE": "",
      "LSYSTEM": "",
      "GRPNO": null,
      "COLNO": null,
      "OLDCOLNO": 0,
      "LDEFFORMAT": "",
      "DATESEP": null,
      "DATEFRM": "",
      "FONTCASE": "",
      "LNZERO": null,
      "LCOMMA": "",
      "LCRDB": "",
      "LABS": "",
      "LCURR": "",
      "LFORECOLOR": "",
      "LBACKCOLOR": "",
      "EGKEYID": 0,
      "ROWNO": null,
      "COLLEVEL": null,
      "LEVELFLD": null,
      "COLTOTAL": null,
      "NONZERO": null,
      "COLGRPREQ": "",
      "LINPRINT": "",
      "LINGRID": "",
      "COLLOCK": "",
      "SEARCHCOL": "",
      "ALIGN_H": "",
      "ALIGN_D": "",
      "ALIGN_F": "",
      "COLDISPIN": null,
      "CACTION": "",
      "COLSPDATA": null,
      "IMGDISPAS": null,
      "IMGSTYLE": null,
      "IMGWIDTH": null,
      "IMGHEIGHT": null,
      "LBLANK": "",
      "GRPPAGE": "",
      "GRPASCOL": "",
      "GRPHFREQ": "",
      "C61FLN": ""
    }
  )
  const [columnObj, setColumnObj] = useState(
    {
      "RECTYPE": "REG",
      "REPORTID": "LEDMAIN",
      "COLRECID": "",
      "DISPCAP": "",
      "DISPCAPID": "",
      "USEDCAP": "",
      "USEDCAPID": "",
      "CTRLSOURCE": "",
      "SOURCEDEF": "",
      "DATATYPE": "",
      "UNBOUND": "",
      "COLWIDTH": 0,
      "COLDEC": " ",
      "DISPFORMAT": "",
      "LTOTAL": "N",
      "LGROUP": "",
      "ORDEXPR": "",
      "GRPEXPR": "",
      "DISPCOND": "",
      "GRPSEQ": "",
      "RECSEQ": "",
      "GRPCAPID": "",
      "GRPCAP": "",
      "SPFLDFLAG": "   ",
      "COLPROP": "",
      "COLDATA": "",
      "TOTEXPR": "",
      "LSTRETCH": "",
      "LLEAF": "",
      "SELEXPSRC": "",
      "LORDER": "",
      "SUPFLAG": " ",
      "INCOND": "",
      "INCONDVFP": null,
      "ALIGNMENT": "",
      "FONTNAME": "",
      "FONTSIZE": "",
      "FONTSTYLE": "",
      "BACKCOLOR": "",
      "FORECOLOR": "",
      "COLSUPRESS": "",
      "CLICKDET": "",
      "LCOLUMNGRP": "",
      "DTCODEFLD": "",
      "MTBLNAME": "",
      "MCODEFLD": "",
      "MDISPFLD": "",
      "MFILTER": "",
      "S61FTY": "",
      "FCSOURCE": "",
      "FILTTYPE": " ",
      "FILTPROP": "",
      "FDEFVAL": "",
      "FCUSTEXPR": "",
      "BUNIID": 0,
      "S61CHARID": "",
      "FGSOURCE": "",
      "lOk": null,
      "ORFLDNM": "",
      "LACTIVE": "",
      "LSYSTEM": "",
      "GRPNO": null,
      "COLNO": null,
      "OLDCOLNO": 0,
      "LDEFFORMAT": "",
      "DATESEP": null,
      "DATEFRM": "",
      "FONTCASE": "",
      "LNZERO": null,
      "LCOMMA": "",
      "LCRDB": "",
      "LABS": "",
      "LCURR": "",
      "LFORECOLOR": "",
      "LBACKCOLOR": "",
      "EGKEYID": 0,
      "ROWNO": null,
      "COLLEVEL": null,
      "LEVELFLD": null,
      "COLTOTAL": null,
      "NONZERO": null,
      "COLGRPREQ": "",
      "LINPRINT": "",
      "LINGRID": "",
      "COLLOCK": "",
      "SEARCHCOL": "",
      "ALIGN_H": "",
      "ALIGN_D": "",
      "ALIGN_F": "",
      "COLDISPIN": null,
      "CACTION": "",
      "COLSPDATA": null,
      "IMGDISPAS": null,
      "IMGSTYLE": null,
      "IMGWIDTH": null,
      "IMGHEIGHT": null,
      "LBLANK": ""
    }
  )
  const [format, setFormat] = useState(false)
  const [rowSelection, setRowSelection] = useState({})
  const [grpToggle, setGrpToggle] = useState(false)
  const [colToggle, setColToggle] = useState(false)
  const [selectedRow, setSelectedRow] = useState();
  const [tableEditingRow, setTableEditingRow] = useState(null)
  const [columnTableEdititngRow, setColumnTableEditingRow] = useState(null)
  const [columns, setColumns] = useState([])
  const [colData, setColData] = useState([])
  const [custColData, setCustColData] = useState([])
  const [repHeadData, setRepHeadData] = useState([])
  const [summaryData, setSummaryData] = useState([])
  const [multiFooterData, setMultiFooterData] = useState([])
  const [multiHeaderData, setMultiHeaderData] = useState([])
  const [originalGrpData, setOriginalGrpData] = useState([])
  const [originalColData, setOriginalColData] = useState([])
  const [filterVal, setFilterVal] = useState(null)
  const [rowSelection1, setRowSelection1] = useState({})
  const [selectedRow1, setSelectedRow1] = useState(null)
  const [updatedGrpRows, setUpdatedGrpRows] = useState([])
  const [updatedColRows, setUpdatedColRows] = useState([])
  let extraDetail = [
    {
      value: "Blank Column",
      name: "Blank Column"
    },
    {
      value: "Special Expression",
      name: "Special Expression"
    }
  ]

  const columnHelper = createColumnHelper()

  const dispatch = useDispatch()

  // useEffect(() => {
  //   console.log("OS62Obj", OS62Obj)
  //   console.log("content", content)
  //   console.log("updatedGrpRows", updatedGrpRows)
  //   console.log("updatedColRows", updatedColRows)
  //   console.log("grpToggle", grpToggle)
  //   console.log("selectedRow", selectedRow)
  //   console.log("rowSelection", rowSelection)
  //   console.log("format", format)
  //   console.log("custColData", custColData)
  //   console.log("repHeadData", repHeadData)
  //   console.log("summaryData", summaryData)
  //   // console.log("grpData", grpData)
  // }, [OS62Obj, summaryData, content, repHeadData, updatedGrpRows, custColData, format, selectedRow, rowSelection, grpData, grpToggle, updatedColRows])

  useEffect(() => {
    if (OBJ) {
      if (props?.cAction === "A") {
        dispatch(GetFormatEnv({ cRepId: OBJ?.data?.cSTID, cAction: props?.cAction, cFmtID: "" }))
      } else if (props?.cAction === "E") {
        dispatch(GetFormatEnv({ cRepId: OBJ?.data?.cSTID, cAction: props?.cAction, cFmtID: props?.OBJ?.DataGridRow?.S62F02 }))
      }
    }
  }, [OBJ])

  useEffect(() => {
    if (OS62) {
      setOS62Obj(OS62)
    }
  }, [OS62])

  useEffect(() => {
    if (F14DIC) {
      setF14DICObj(F14DIC)
    }
  }, [F14DIC])

  useEffect(() => {
    if (CustColDT) {
      setCustColData(CustColDT)
    }
  }, [CustColDT])

  useEffect(() => {
    if (RepHeadDT) {
      setRepHeadData(RepHeadDT)
    }
  }, [RepHeadDT])

  useEffect(() => {
    if (RepSumDT) {
      setSummaryData(RepSumDT)
    }
  }, [RepSumDT])

  useEffect(() => {
    if (MLHeadDT) {
      setMultiHeaderData(MLHeadDT)
    }
  }, [MLHeadDT])

  useEffect(() => {
    if (MLFootDT) {
      setMultiFooterData(MLFootDT)
    }
  }, [MLFootDT])

  function callGraph() {
    setDrawerObj({
      ...dra,
      title: <Text fw={700}>Graph</Text>,
      body: (
        <GraphFormat
          cAction="A"
          DrawerOBJ={DrawerObj}
          setDrawerOBJ={(e) => {
            setDrawerObj(e);
          }}
          GrpDT={GrpDT}
          ColDT={ColDT}
          COPGPH={COPGPH}
          COPGPT={COPGPT}
          F14DICObj={F14DICObj}
          setF14DICObj={(e) => {
            console.log("graph sendObj", e)
            setF14DICObj(e)
          }}
        />
      ),
      open: true,
      size: "lg",
      position: "right",
    });
    // Store.dispatch(
    //     ModalFunction({
    //       MTitle: <Text fw={700}>Graph</Text>,
    //       MAction: true,
    //       MSize: "50%",
    //       MBody: (i) => <GraphFormat index={i} cAction="A" />,
    //       MClose: true,
    //       closeOnClickOutside: true,
    //       onclose: () => {},
    //     })
    //   );
  }
  function callPrinting() {
    setDrawerObj({
      ...dra,
      title: <Text fw={700}>Advance Printing Option</Text>,
      body: (
        <PrintingOption
          cAction="A"
          DrawerOBJ={DrawerObj}
          setDrawerOBJ={(e) => {
            setDrawerObj(e);
          }}
          COPF73={COPF73}
          COP1={COP1}
          COPPPR={COPPPR}
          F14DICObj={F14DICObj}
          setF14DICObj={(e) => {
            console.log("setF14DICObj", e)
            setF14DICObj(e)
          }}
        />
      ),
      open: true,
      size: "lg",
      position: "right",
    });
    // Store.dispatch(
    //     ModalFunction({
    //       MTitle: <Text fw={700}>Advance Printing Option</Text>,
    //       MAction: true,
    //       MSize: "50%",
    //       MBody: (i) => <PrintingOption index={i} cAction="A" />,
    //       MClose: true,
    //       closeOnClickOutside: true,
    //       onclose: () => {},
    //     })
    //   );
  }
  function callCustomCol(tblnm) {
    setDrawerObj({
      ...dra,
      title: <Text fw={700}>{
        tblnm === "customColumn" ? "Custom Column Header" :
          tblnm === "reportHeader" ? "Report Header Detail" :
            "Report Summary Detail"
      }</Text>,
      body: (
        <CustomCol
          cAction="A"
          TblNm={tblnm}
          DrawerObj={DrawerObj}
          setDrawerObj={(e) => {
            setDrawerObj(e);
          }}
          custColData={custColData}
          setCustColData={(e) => {
            setCustColData(e)
          }}
          repHeadData={repHeadData}
          setRepHeadData={(e) => {
            setRepHeadData(e)
          }}
          summaryData={summaryData}
          setSummaryData={(e) => {
            setSummaryData(e)
          }}
          CustColEnt={CustColENT}
          CustColHKey={CustColHKey}
          RepHeadEnt={RepHeadENT}
          RepHeadHKey={RepHeadHKey}
          RepSumEnt={RepSumENT}
          RepSumhKey={RepSumhKey}
        />
      ),
      open: true,
      size: "80%",
      // position: "right",
    });
  }
  function callReportHeader() {
    setDrawerObj({
      ...dra,
      title: <Text fw={700}>Report Header Detail</Text>,
      body: (
        <ReportHeader
          cAction="A"
          DrawerOBJ={DrawerObj}
          setDrawerOBJ={(e) => {
            setDrawerObj(e);
          }}
          RepHeadDT={RepHeadDT}
          RepHeadEnt={RepHeadENT}
          RepHeadHKey={RepHeadHKey}
        />
      ),
      open: true,
      size: "xl",
      position: "right",
    });
    // Store.dispatch(
    //     ModalFunction({
    //       MTitle: <Text fw={700}>Report Header Detail</Text>,
    //       MAction: true,
    //       MSize: "50%",
    //       MBody: (i) => <ReportHeader index={i} cAction="A" />,
    //       MClose: true,
    //       closeOnClickOutside: true,
    //       onclose: () => {},
    //     })
    //   );
  }
  function callSummary() {
    setDrawerObj({
      ...dra,
      title: <Text fw={700}>Report Summary Detail</Text>,
      body: (
        <ReportSummary
          cAction="A"
          DrawerOBJ={DrawerObj}
          setDrawerOBJ={(e) => {
            setDrawerObj(e);
          }}
          RepSumDT={RepSumDT}
          RepSumEnt={RepSumENT}
          RepSumhKey={RepSumhKey}
        />
      ),
      open: true,
      size: "xl",
      position: "right",
    });
  }

  useEffect(() => {
    if (OS62Obj?.S62F06 !== "") {
      setFilterVal("Y")
    } else {
      setFilterVal("N")
    }
  }, [OS62Obj?.S62F06])

  const filterList = () => {
    setDrawerObj({
      ...dra,
      title: <Text fw={700}>Select Filter to Apply</Text>,
      body: (<>
        <PLDataGrid
          data={M47list}
          TmData="P_M47"
          dispexpr={"FIELD04"}
          valexpr={"FIELD02"}
          setEdit={(e) => {
            console.log("setEdit", e)
            setOS62Obj({ ...OS62Obj, S62F06: e?.FIELD02, S62F06NM: e?.FIELD04 })
          }}
        />
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "5px 0px" }}>
          <Button
            onClick={() => {
              setDrawerObj({ ...DrawerObj, open: false })
            }}
          >
            OK
          </Button>
        </div>
      </>
      ),
      open: true,
      size: "sm",
      position: "right",
    });
  }

  const seperate = (e) => {
    var paraArr = e.split("~C~");
    if (paraArr.length == 1) {
      paraArr[1] = paraArr[0];
    }
    return paraArr;
  };

  const seperateLevel = (e) => {
    var paraArr = e.split("~R~");
    // console.log("paraArr",paraArr)
    var val = paraArr[0].split("~C~")
    if (val.length == 1) {
      val[1] = val[0]
    }
    return val
  }

  const renderInputBox = (uID, v, c, cellid, index) => {

    let setInput;
    switch (c?.S53F14) {
      case "PLPOPUP":

        setInput = (
          <PLDataGrid
            id={`T02Ent_${index}`}
            width={"500px"}
            value={grpObj[c?.S53F04]}
            p1={seperate(c.S53F15)[1]}
            setEdit={(e) => {
              setGrpObj({ ...grpObj, [c?.S53F04]: e })
            }}
          />
        );
        break;
      case "COMBOBOX":

        setInput = (
          <PLComboBox
            cmbid={`T02Ent_${index}`}
            // initiallyOpened={initiallyOpened}
            value={grpObj[c?.S53F04]}
            copno={seperate(c.S53F15)[1]}
            dispexpr={"DisplayMember"}
            valexpr={"ValueMember"}
            setEdit={(e) => {
              console.log("COMBOBOX", e)
              setGrpObj({ ...grpObj, [c?.S53F04]: e.value })
            }}
          />
        );

        break;
      case "TEXTBOX":
        setInput = (
          <PLTextBox
            id={`T02Ent_${index}`}
            value={grpObj[c?.S53F04]}
            setEdit={(e) => {
              setGrpObj({ ...grpObj, [c?.S53F04]: e.target.value })
            }}
          />
        );

        break;
      case "NUMBOX":
        setInput = (
          <PLNumberBox
            id={`T02Ent_${index}`}
            value={grpObj[c?.S53F04]}
            setEdit={(e) => {
              setGrpObj({ ...grpObj, [c?.S53F04]: e })
            }}
          />
        );
        break;
      default:
        setInput = null;
        break;
    }
    return setInput;

  };

  useEffect(() => {
    if (GrpEnt) {
      let cols = []
      GrpEnt?.filter((v, i) => v?.S53F00 === "C").map((c, i) => {
        cols.push(
          columnHelper.accessor(c.S53F04, {
            header: c?.S53F03,
            id: c?.S53UID,
            size: c?.S53F07 == 0 ? 0 : c?.S53F07,
            enableEditing: c?.S53F02 == "TMORD2" ? false : true,
            Cell: ({ cell, row }) => {
              // console.log("cell.getValue()", cell.getValue())
              let val = cell.getValue();
              return val
            },
            Edit: ({ cell, row }) => {
              let val = cell.getValue();
              return renderInputBox(c.S53UID, val, c, cell?.id, i);
            },
          })
        )
      })
      // cols.push(
      //   columnHelper.accessor('G0000010', {
      //     header: "G0000010",
      //     size: 0,
      //     enableEditing: false,
      //     enableGrouping: true,
      //     Cell: ({ cell, row }) => {
      //       // console.log("cell.getValue()", cell.getValue())
      //       let val = cell.getValue();
      //       return val
      //     },
      //   })
      // )
      setGrpColumns(cols);
    }
    console.log("grpData", grpData)
  }, [GrpEnt, GrpDT, grpData, grpObj])

  useEffect(() => {
    if (GrpDT) {
      setGrpData(GrpDT)
    }
  }, [GrpDT])

  const findNextSequence = (existingSequences) => {
    const existingIndexes = grpData
      .filter(item => item.COLNO !== null) // Filter out NULL values
      .map(item => item.COLNO);

    // Find the smallest number missing in the sequence
    let nextSequence = 1;
    while (existingSequences.includes(nextSequence)) {
      nextSequence++;
    }
    console.log("nextSequence", nextSequence)
    return nextSequence;
  };

  // Selection Button
  const SelectDeSelect = (row, tableNm) => {
    if (Object.keys(row)?.length > 0) {
      if (tableNm == "GrpDT") {
        const selectedRow = grpData[Object.keys(rowSelection)[0]];
        // console.log("selectedRow", selectedRow)
        // let updatedRows = [];
        if (row.COLNO === null) {
          const updatedData = [...grpData];
          const nextSequence = findNextSequence(grpData
            .filter(item => item.COLNO !== null)
            .map(item => item.COLNO));

          updatedData[Object.keys(rowSelection)[0]] = { ...row, COLNO: nextSequence };
          // console.log("updatedData[Object.keys(rowSelection)[0]]", updatedData[Object.keys(rowSelection)[0]])
          setUpdatedGrpRows([...updatedGrpRows, updatedData[Object.keys(rowSelection)[0]]])
          // updatedRows.push(updatedData[Object.keys(rowSelection)[0]]);
          setGrpData(updatedData);
          setRowSelection({ [Number(Object.keys(rowSelection)[0]) + 1]: true })
        } else {
          const updatedData = [...grpData];
          updatedData[Object.keys(rowSelection)[0]] = {
            ...row,
            COLNO: null,
          };
          setUpdatedGrpRows([...updatedGrpRows, updatedData[Object.keys(rowSelection)[0]]])
          setGrpData(updatedData);
          setRowSelection({ [Number(Object.keys(rowSelection)[0]) + 1]: true })
        }
      } else if (tableNm == "ColDT") {
        const selectedRow = colData[Object.keys(rowSelection)[0]];
        // console.log("selectedRow", selectedRow)
        if (row.COLNO === null) {
          const updatedData = [...colData];
          const nextSequence = findNextSequence(colData
            .filter(item => item.COLNO !== null)
            .map(item => item.COLNO));

          updatedData[Object.keys(rowSelection1)[0]] = { ...row, COLNO: nextSequence, ROWNO: 1, COLLEVEL: "A", NONZERO: row?.DATATYPE === "N" ? "N" : null, COLTOTAL: row?.DATATYPE === "N" ? "S" : null };

          setUpdatedColRows([...updatedColRows, updatedData[Object.keys(rowSelection1)[0]]])
          setColData(updatedData);
          setRowSelection1({ [Number(Object.keys(rowSelection1)[0]) + 1]: true })
        } else {
          const updatedData = [...colData];
          updatedData[Object.keys(rowSelection1)[0]] = {
            ...row,
            COLNO: null,
            COLLEVEL: null,
            ROWNO: null,
            NONZERO: null,
            COLTOTAL: null
          };
          setColData(updatedData);
          setRowSelection1({ [Number(Object.keys(rowSelection1)[0]) + 1]: true })
        }
      }
    }
  }

  // Filter Button
  const FilterBtn = (tableNm) => {
    if (tableNm == "GrpDT") {
      if (!grpToggle) {
        setGrpToggle(true)
        setOriginalGrpData([...grpData])
        let filterData = grpData?.filter((f, i) => f.COLNO !== null)
        setGrpData(filterData)
      } else {
        setGrpToggle(false)
        setGrpData([...originalGrpData])
      }
    } else {
      if (!colToggle) {
        setColToggle(true)
        setOriginalColData([...colData])
        let filterData = colData?.filter((f, i) => f?.COLNO !== null)
        setColData(filterData)
      } else {
        setColToggle(false)
        setColData([...originalColData])
      }
    }
  }

  // Extra Detail
  const ExtraDetails = () => {
    setDrawerObj({
      ...dra,
      title: <Text fw={700}>Extra Details</Text>,
      body: (
        <>
          <Grid gutter={4}>
            <Grid.Col span={12}>
              <PLComboBox
                data={extraDetail}
                dispexpr="name"
                valexpr="value"
                value={grpObj?.DISPCAP}
                setEdit={(e) => {
                  console.log("extradetail_value", e)
                  setGrpObj({ ...grpObj, DISPCAP: e, USEDCAP: e, COLWIDTH: 10 })
                  // if (e) {
                  //   let obj = extraDetail.find((f) => f.value == e)
                  //   setGrpObj({ ...grpObj, DISPCAP: obj?.name, USEDCAP: obj?.name })
                  // }
                }}
              />
            </Grid.Col>
            <Grid.Col span={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Button
                onClick={() => {
                  setGrpData([grpObj, ...grpData]);
                  setDrawerObj({ ...DrawerObj, open: false })
                }}
              >OK</Button>
            </Grid.Col>
          </Grid>
        </>
      ),
      open: true,
      size: "sm",
      position: "right",
    });
  }

  const multiHeaderFooter = (row, p0) => {
    if (row.COLNO !== null) {
      setDrawerObj({
        ...dra,
        title: <Text fw={700}>{p0 === "MH" ? "Account Group - Group Header Detail" : "Account Group - Group Footer Detail"}</Text>,
        body: (<MultiHeaderFooter
          MLHeadDT={MLHeadDT}
          MLHeadENT={MLHeadENT}
          MLHeadhKey={MLHeadhKey}
          MLFootDT={MLFootDT}
          MLFootENT={MLFootENT}
          MLFoothKey={MLFoothKey}
          multiHeaderData={multiHeaderData}
          setMultiHeaderData={(e) => {
            setMultiHeaderData(e)
          }}
          multiFooterData={multiFooterData}
          setMultiFooterData={(e) => {
            setMultiFooterData(e)
          }}
          rowSelection={rowSelection}
          rowSelection1={rowSelection1}
          grpData={grpData}
          setGrpData={(e) => setGrpData(e)}
          row={row}
          p0={p0}
          DrawerObj={DrawerObj}
          setDrawerObj={(e) => setDrawerObj(e)}
        />),
        open: true,
        size: "70%",
        position: "right",
      });
    }
  }

  // width btn
  const WidthBtn = (row, tableNm, p0) => {
    console.log("WidthBtn", row)
    if (row?.COLNO !== null) {
      if (p0 == "W+") {
        if (tableNm === "GrpDT") {
          const updatedData = [...grpData];
          let width = row.COLWIDTH + 1
          console.log("width", width)
          // row.COLWIDTH += 1;
          updatedData[Object.keys(rowSelection)[0]] = { ...row, COLWIDTH: width };
          setGrpData(updatedData);
          setUpdatedGrpRows([...updatedGrpRows, updatedData[Object.keys(rowSelection)[0]]])
          // console.log("Updated COLWIDTH:", row.COLWIDTH);
        } else {
          const updatedData = [...colData];
          let width = row.COLWIDTH + 1
          console.log("width", width)
          // row.COLWIDTH += 1;
          updatedData[Object.keys(rowSelection1)[0]] = { ...row, COLWIDTH: width };
          setColData(updatedData);
          // console.log("Updated COLWIDTH:", row.COLWIDTH);
        }
      } else {
        if (tableNm === "GrpDT") {
          const updatedData = [...grpData];
          let width = row.COLWIDTH
          if (width > 0) {
            width -= 1
          }
          // console.log("width", width)
          updatedData[Object.keys(rowSelection)[0]] = { ...row, COLWIDTH: width };
          setGrpData(updatedData);
        } else {
          const updatedData = [...colData];
          let width = row.COLWIDTH
          if (width > 0) {
            width -= 1
          }
          // console.log("width", width)
          updatedData[Object.keys(rowSelection1)[0]] = { ...row, COLWIDTH: width };
          setColData(updatedData);
        }
      }
    }
  }

  // decimal btn
  const DecimalBtn = (row, tableNm, p1) => {
    console.log("DecimalBtn", row)
    if (row?.COLNO !== null) {
      if (row?.DATATYPE === "N") {
        if (p1 == "+") {
          if (tableNm === "GrpDT") {
            const updatedData = [...grpData];
            let DEC = row.COLDEC
            DEC += 1
            if (DEC > 6) {
              DEC = 6;
            }
            // console.log("DEC=>",DEC)
            updatedData[Object.keys(rowSelection)[0]] = { ...row, COLDEC: DEC };
            setGrpData(updatedData);
          } else {
            const updatedData = [...colData];
            let DEC = row.COLDEC
            DEC += 1
            if (DEC > 6) {
              DEC = 6;
            }
            // console.log("DEC=>",DEC)
            updatedData[Object.keys(rowSelection1)[0]] = { ...row, COLDEC: DEC };
            setColData(updatedData);
          }
        } else {
          if (tableNm === "GrpDT") {
            const updatedData = [...grpData];
            let DEC = row.COLDEC
            DEC -= 1
            if (DEC < -1) {
              DEC = -1
            }
            // console.log("DEC=>", DEC)
            updatedData[Object.keys(rowSelection)[0]] = { ...row, COLDEC: DEC };
            setGrpData(updatedData);
          } else {
            const updatedData = [...colData];
            let DEC = row.COLDEC
            DEC -= 1
            if (DEC < -1) {
              DEC = -1
            }
            // console.log("DEC=>", DEC)
            updatedData[Object.keys(rowSelection1)[0]] = { ...row, COLDEC: DEC };
            setColData(updatedData);
          }
        }
      }
    }
  }

  // total btn
  const totalBtn = (row) => {
    if (row?.COLNO !== null) {
      if (row?.DATATYPE === "N") {
        const updatedData = [...colData]
        let rowIndex = Object.keys(rowSelection1)[0]
        updatedData[rowIndex] = { ...row, COLTOTAL: row?.COLTOTAL == "S" ? "N" : row?.COLTOTAL == "N" ? "A" : "S" }
        setColData(updatedData)
      }
    }
  }

  // column Total
  const columnTotal = (row) => {
    if (row?.COLNO !== null) {
      if (row?.DATATYPE === "N") {
        const updatedData = [...colData]
        let rowIndex = Object.keys(rowSelection1)[0]
        updatedData[rowIndex] = { ...row, COLGRPREQ: row?.COLGRPREQ == "N" ? "Y" : "N" }
        setColData(updatedData)
      }
    }
  }

  // none zero btn
  const noneZero = (row) => {
    if (row?.COLNO !== null) {
      if (row?.DATATYPE === "N") {
        const updatedData = [...colData]
        let rowIndex = Object.keys(rowSelection1)[0]
        updatedData[rowIndex] = { ...row, NONZERO: row?.NONZERO == "N" ? "Y" : "N" }
        setColData(updatedData)
      }
    }
  }

  // level btn
  const levelFun = (row) => {
    if (row?.COLNO !== null) {
      const updatedData = [...colData]
      let rowIndex = Object.keys(rowSelection1)[0]
      updatedData[rowIndex] = { ...row, COLLEVEL: row?.COLLEVEL == "A" ? "L" : row?.COLLEVEL == "L" ? "S" : "A" }
      setColData(updatedData)
    }
  }

  // Stretch btn
  const StretchBtn = (row) => {
    if (row?.COLNO !== null) {
      if (row?.DATATYPE === "C") {
        const updatedData = [...colData]
        let rowIndex = Object.keys(rowSelection1)[0]
        updatedData[rowIndex] = { ...row, LSTRETCH: row?.LSTRETCH == "N" ? "Y" : "N" }
        setColData(updatedData)
      } else {
        GlobalClass.Notify("warning", "Warning", "This Column Stretching Not Possible")
      }
    }
  }

  // header btn & Supress Group btn
  const HeaderChange = (row, tableNm, p0) => {
    if (row?.COLNO !== null) {
      if (p0 === "H") {
        if (row.COLNO !== null) {
          const updatedData = tableNm === "GrpDT" ? [...grpData] : [...colData];

          const drawerContent = (
            <Grid gutter={4}>
              <Grid.Col span={12}>
                <PLTextBox
                  value={row?.USEDCAP}
                  setEdit={(e) => {
                    updatedData[Object.keys(tableNm === "GrpDT" ? rowSelection : rowSelection1)[0]] = { ...row, USEDCAP: e.target.value };
                  }}
                />
              </Grid.Col>
              <Grid.Col span={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Button
                  onClick={() => {
                    if (tableNm === "GrpDT") {
                      setGrpData(updatedData);
                    } else {
                      setColData(updatedData);
                    }
                    setDrawerObj({ ...DrawerObj, open: false });
                  }}
                >
                  OK
                </Button>
              </Grid.Col>
            </Grid>
          );

          setDrawerObj({
            ...dra,
            title: <Text fw={700}>Header Caption</Text>,
            body: drawerContent,
            open: true,
            size: "sm",
            position: "right",
          });

        }
      } else if (p0 === "GS") {
        // Handle GS logic here
        const updatedData = tableNm === "GrpDT" ? [...grpData] : [...colData];
        let rowIndex = Object.keys(tableNm === "GrpDT" ? rowSelection : rowSelection1)[0];
        updatedData[rowIndex] = { ...row, GRPASCOL: row.GRPASCOL === "N" ? "Y" : "N" };

        if (tableNm === "GrpDT") {
          setGrpData(updatedData);
        } else {
          setColData(updatedData);
        }
      }
    }
  };

  // seq. Up & seq.down btn
  const changeSeqUpDown = (row, tableNm, p0) => {
    if (row?.COLNO !== null) {
      if (tableNm === "GrpDT") {
        if (p0 === "U") {
          const updatedData = [...grpData];
          let rowIndex = Object.keys(rowSelection)[0];
          console.log("rowIndex", rowIndex);

          const filterData = updatedData.filter((f) => f.COLNO !== null);
          console.log("filterData", filterData);

          const currentCOLNO = updatedData[rowIndex].COLNO;
          console.log("OldCOLNO", currentCOLNO);

          filterData.sort((a, b) => a.COLNO - b.COLNO);
          console.log("Sorted Filtered Data", filterData);

          const nextObj = filterData
            .filter((v) => v.COLNO < currentCOLNO)
            .pop();

          console.log("nextObj", nextObj)

          updatedData[rowIndex] = { ...row, COLNO: nextObj.COLNO }

          if (nextObj) {
            nextObj.COLNO = currentCOLNO;
            console.log("Updated nextObj.COLNO", nextObj.COLNO);
          }
          setGrpData(updatedData)
        } else {
          const updatedData = [...grpData];
          let rowIndex = Object.keys(rowSelection)[0];
          console.log("rowIndex", rowIndex);

          const filterData = updatedData.filter((f) => f.COLNO !== null);
          console.log("filterData", filterData);

          const currentCOLNO = updatedData[rowIndex].COLNO;
          console.log("OldCOLNO", currentCOLNO);

          // filterData.sort((a, b) => a.COLNO - b.COLNO);
          // console.log("Sorted Filtered Data", filterData);

          const nextObj = filterData
            .find((v) => v.COLNO > currentCOLNO)

          console.log("nextObj", nextObj)

          updatedData[rowIndex] = { ...row, COLNO: nextObj.COLNO }

          if (nextObj) {
            nextObj.COLNO = currentCOLNO;
            console.log("Updated nextObj.COLNO", nextObj.COLNO);
          }
          setGrpData(updatedData)
        }
      } else if (tableNm === "ColDT") {
        if (p0 === "U") {
          const updatedData = [...colData];
          let rowIndex = Object.keys(rowSelection1)[0];
          console.log("rowIndex", rowIndex);

          const filterData = updatedData.filter((f) => f.COLNO !== null);
          console.log("filterData", filterData);

          const currentCOLNO = updatedData[rowIndex].COLNO;
          console.log("OldCOLNO", currentCOLNO);

          filterData.sort((a, b) => a.COLNO - b.COLNO);
          console.log("Sorted Filtered Data", filterData);

          const nextObj = filterData
            .filter((v) => v.COLNO < currentCOLNO)
            .pop();

          console.log("nextObj", nextObj)

          updatedData[rowIndex] = { ...row, COLNO: nextObj.COLNO }

          if (nextObj) {
            nextObj.COLNO = currentCOLNO;
            console.log("Updated nextObj.COLNO", nextObj.COLNO);
          }
          setColData(updatedData)
        } else {
          const updatedData = [...colData];
          let rowIndex = Object.keys(rowSelection)[0];
          console.log("rowIndex", rowIndex);

          const filterData = updatedData.filter((f) => f.COLNO !== null);
          console.log("filterData", filterData);

          const currentCOLNO = updatedData[rowIndex].COLNO;
          console.log("OldCOLNO", currentCOLNO);

          const nextObj = filterData
            .find((v) => v.COLNO > currentCOLNO)

          console.log("nextObj", nextObj)

          updatedData[rowIndex] = { ...row, COLNO: nextObj.COLNO }

          if (nextObj) {
            nextObj.COLNO = currentCOLNO;
            console.log("Updated nextObj.COLNO", nextObj.COLNO);
          }
          setColData(updatedData)
        }
      }
    }
  };

  // display btn
  const displayChange = (row, tableNm) => {
    if (row?.COLNO !== null) {
      if (tableNm === "GrpDT") {
        const updatedData = [...grpData];
        let rowIndex = Object.keys(rowSelection)[0]
        updatedData[rowIndex] = {
          ...row,
          GRPHFREQ: row.GRPHFREQ == "D" ? "H" :
            row.GRPHFREQ == "H" ? "F" :
              row.GRPHFREQ == "F" ? "N" : "D"
        }
        setGrpData(updatedData)
      }
    }
  }

  // change line & page detail btn
  const changeLinePageDetail = (row, p0) => {
    if (row?.COLNO !== null) {
      if (p0 === "FL") {
        const updatedData = [...grpData];
        let rowIndex = Object.keys(rowSelection)[0]
        updatedData[rowIndex] = {
          ...row,
          C61FLN: row?.C61FLN === "N" ? "B" :
            row?.C61FLN === "B" ? "H" :
              row?.C61FLN === "H" ? "D" :
                row?.C61FLN === "D" ? "E" :
                  row?.C61FLN === "E" ? "S" :
                    row?.C61FLN === "S" ? "F" :
                      row?.C61FLN === "F" ? "G" :
                        "N"
        }
        setGrpData(updatedData)
      } else {
        const updatedData = [...grpData];
        let rowIndex = Object.keys(rowSelection)[0]
        updatedData[rowIndex] = {
          ...row,
          GRPPAGE: row?.GRPPAGE === "D" ? "P" :
            row?.GRPPAGE === "P" ? "C" :
              row?.GRPPAGE === "C" ? "R" :
                "D"
        }
        setGrpData(updatedData)
      }
    }

  }

  // inc/dec seq btn
  const IncDecSeq = (row, tableNm, p0) => {
    if (row?.COLNO == null) {
      if (p0 === "I") {
        GlobalClass.Notify("warning", "Warning", "Next Sequence No. is already selected")
      } else if (p0 === "DS") {
        GlobalClass.Notify("warning", "Warning", "Previous Sequence No. is already selected")
      }
    } else {
      if (tableNm === "GrpDT") {
        if (p0 === "I") {
          let updatedData = [...grpData]
          let rowIndex = Object.keys(rowSelection)[0]
          let incSeq = row?.COLNO + 1
          for (let v of grpData) {
            if (v.COLNO == incSeq) {
              incSeq = row?.COLNO;
              GlobalClass.Notify("warning", "Warning", "Next Sequence No. is already selected");
              break;
            }
          }
          updatedData[rowIndex] = { ...row, COLNO: incSeq }
          setGrpData(updatedData)
        } else if (p0 === "DS") {
          let updatedData = [...grpData]
          let rowIndex = Object.keys(rowSelection)[0]
          let decSeq = row?.COLNO
          decSeq -= 1
          // grpData?.map((v) => {
          //   if (v.COLNO == decSeq) {
          //     decSeq = row?.COLNO
          //     GlobalClass.Notify("warning", "Warning", "Previous Sequence No. is already selected")
          //   }
          // })

          for (let v of grpData) {
            if (v.COLNO == decSeq) {
              decSeq = row?.COLNO;
              GlobalClass.Notify("warning", "Warning", "Previous Sequence No. is already selected");
              break;
            }
          }
          if (decSeq < 1) {
            decSeq = 1
            GlobalClass.Notify("warning", "Warning", "Previous Sequence No. is already selected")
          }
          updatedData[rowIndex] = { ...row, COLNO: decSeq }
          setGrpData(updatedData)
        }
      } else if (tableNm === "ColDT") {
        if (p0 === "I") {
          let updatedData = [...colData]
          let rowIndex = Object.keys(rowSelection1)[0]
          let incSeq = row?.COLNO + 1
          for (let v of colData) {
            if (v.COLNO == incSeq) {
              incSeq = row?.COLNO;
              GlobalClass.Notify("warning", "Warning", "Next Sequence No. is already selected");
              break;
            }
          }
          updatedData[rowIndex] = { ...row, COLNO: incSeq }
          setColData(updatedData)
        } else if (p0 === "DS") {
          let updatedData = [...colData]
          let rowIndex = Object.keys(rowSelection1)[0]
          let decSeq = row?.COLNO - 1

          for (let v of colData) {
            if (v.COLNO == decSeq) {
              decSeq = row?.COLNO;
              GlobalClass.Notify("warning", "Warning", "Previous Sequence No. is already selected");
              break;
            }
          }
          if (decSeq < 1) {
            decSeq = 1
            GlobalClass.Notify("warning", "Warning", "Previous Sequence No. is already selected")
          }
          updatedData[rowIndex] = { ...row, COLNO: decSeq }
          setColData(updatedData)
        }
      }
    }
  }

  // insert seq
  const InsSeq = (row, tableNm) => {
    console.log("InsSeq_row", row)
    if (Object.keys(row).length > 0 && row?.COLNO !== null) {
      if (tableNm === "GrpDT") {
        let updatedData = [...grpData]
        // let rowIndex = Object.keys(rowSelection)[0]
        // let seqNo = row?.COLNO + 1
        // updatedData[rowIndex] = { ...row, COLNO: seqNo }
        updatedData = updatedData.map((item) => {
          if (item.COLNO != null) {
            return { ...item, COLNO: item.COLNO + 1 };
          }
          return item;
        });
        setGrpData(updatedData)
      } else {
        let updatedData = [...colData]
        // let rowIndex = Object.keys(rowSelection1)[0]
        // let seqNo = row?.COLNO + 1
        // updatedData[rowIndex] = { ...row, COLNO: seqNo }
        updatedData = updatedData.map((item) => {
          if (item.COLNO != null) {
            return { ...item, COLNO: item.COLNO + 1 };
          }
          return item;
        });
        setColData(updatedData)
      }
    }
  }

  // ad. option
  const AdvanceOption = (row, tableNm) => {
    if (row?.COLNO !== null) {
      setDrawerObj({
        ...dra,
        title: <Text fw={700}>Adv. Opt. (Area Name)</Text>,
        body: (<AdvanceOptionBtn
          COPF70={COPF70}
          updatedGrpRows={updatedGrpRows}
          setUpdatedGrpRows={(e) => setUpdatedGrpRows(e)}
          updatedColRows={updatedColRows}
          setUpdatedColRows={(e) => setUpdatedColRows(e)}
          colData={colData}
          setColData={(e) => setColData(e)}
          rowSelection={rowSelection}
          rowSelection1={rowSelection1}
          grpData={grpData}
          setGrpData={(e) => setGrpData(e)}
          tableNm={tableNm}
          row={row}
          DrawerObj={DrawerObj}
          setDrawerObj={(e) => setDrawerObj(e)}
        />),
        open: true,
        size: "sm",
        position: "right",
      });
    }
    // }
  }

  const SaveFormat = () => {
    // dispatch(DataSaveLoading(true));
    const filterGrpData = grpData?.filter((g) => g.COLNO !== null)
    const filterColData = colData?.filter((c) => c?.COLNO !== null)
    const filterCustColData = custColData?.filter((c) => c?.ROWNO !== null)
    const filterRepHeadData = repHeadData?.filter((h) => h?.ROWNO !== null)
    const filterSummaryData = summaryData?.filter((s) => s?.ROWNO !== null)

    if (OS62Obj.S62F08 === "Y" && filterGrpData.length === 0) {
      GlobalClass.Notify("warning", "Warning", "At least one group must be selected when Zoom is yes")
    } else if (filterColData?.length === 0) {
      GlobalClass.Notify("warning", "Warning", "At least one column must be selected")
    } else {
      GlobalClass.Notify(
        "info",
        props.cAction == "A" ? "Adding" : "Editing",
        "Please wait while we process your data"
      )
      // console.log("repHeadData", repHeadData)
      // console.log("custColData", custColData)
      // console.log("summaryData", summaryData)

      // console.log('filterGrpData',filterGrpData)
      var JData = {
        OS62: OS62Obj, F14DIC: F14DICObj, GrpDT: [...filterGrpData], ColDT: [...filterColData]
      };
      if (filterCustColData.length > 0) {
        JData.CustColDT = [...filterCustColData];
      }

      if (filterRepHeadData.length > 0) {
        JData.RepHeadDT = [...filterRepHeadData];
      }

    if (filterSummaryData.length > 0) {
      JData.RepSumDT = [...filterSummaryData];
    }

    console.log("JData", JData)
    var data = {
      CorpID: gensetting.getcorpid(),
      cUser: gensetting.getcuser(),
      cPass: gensetting.getcpass(),
      CmpNo: gensetting.getcmpno(),
      cAction: props?.cAction,
      cRepId: OBJ?.data?.cSTID,
      cFmtID: props?.cAction === "E" ? props?.OBJ?.DataGridRow?.S62F02 : "",
      Yrno: localStorage.getItem("YrNo")?.replace(/[\\"]/g, ''),
      oparam: JSON.stringify(JData),
    };
    let param = JSON.stringify({
      CorpID: gensetting.getcorpid(),
      cUser: gensetting.getcuser(),
      cPass: gensetting.getcpass(),
      CmpNo: gensetting.getcmpno(),
      cAction: props?.cAction,
      cRepId: OBJ?.data?.cSTID,
      cFmtID: props?.cAction === "E" ? props?.OBJ?.DataGridRow?.S62F02 : "",
      Yrno: localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
    });
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    fetch(
      GlobalClass.ApiUrl + GlobalClass.SaveFormat + "?pa=" + param,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == "SUCCESS") {
          let YrNo = localStorage.getItem("YrNo")
          Promise.resolve(
            dispatch(DataSaveLoading({[OBJ?.data?.cSTID]:false}))
          )
            .then(() => {
              GlobalClass.Notify(
                "success",
                props?.cAction === "A" ? "Added" : "Edited",
                props?.cAction == "A" ? "Added successfully" : "Edited successfully"
              )
            })
            .then(() => {
              setClose({ ...draw, open: false })
            })
            .then(() => {
              dispatch(GetFormatList(OBJ?.data?.BtnPnlObj))
            })
            .then(() => {
              dispatch(
                GetTMFormat({
                  id: OBJ.data?.BtnPnlObj?.id,
                  name: "",
                  type: "A",
                  p0: OBJ.data?.BtnPnlObj?.p0,
                  p1: OBJ.data?.BtnPnlObj?.p1,
                  p2: OBJ.data?.BtnPnlObj?.p2,
                  p3: "",
                })
              )
            }).then(() => {
              dispatch(GetAllForamt(YrNo))
            }).then(() => dispatch(ModalDelete(OBJ?.index)))
            .then(() => {
              dispatch(GetAccountList({
                  id: OBJ.data?.BtnPnlObj?.id,
                  name: "",
                  p0: OBJ.data?.BtnPnlObj?.p0,
                  p1: OBJ.data?.BtnPnlObj?.p1,
                  p2: "",
                  p3: "",
                  type: "A",
                }))
            })
          // .then(() => window.location.reload())
          // dispatch(ModalDelete(OBJ?.index))
        } else {
          Promise.resolve(dispatch(DataSaveLoading(false))).then(() =>
            GlobalClass.Notify("error", data?.status, data?.message)
          );
        }
      })
      .catch((e) => {
        Promise.resolve(
          GlobalClass.Notify("error", "Error", `${e.message}`)
        ).then(() => dispatch(DataSaveLoading(false)));
      });
  }
}

  const handleEditSave = (tableNm) => {
    if (tableNm === "GrpDT") {
      let EditGrpData = [...grpData]
      EditGrpData[tableEditingRow.index] = grpObj
      setGrpData(EditGrpData)
      table.setEditingRow(null);
    } else {
      let EditColData = [...colData]
      EditColData[columnTableEdititngRow.index] = columnObj
      setColData(EditColData)
      columTable.setEditingRow(null);
    }
  }

  const table = useMantineReactTable({
    columns: grpColumns,
    data: grpData,
    mantineTableProps: { withColumnBorders: true },
    mantineTableContainerProps: {
      sx: { height: height * 0.5 },
    },
    enableStickyHeader: true,
    enableTopToolbar: false,
    enableColumnActions: false,
    enableSorting: false,
    enableEditing: true,
    editDisplayMode: "row",
    // enableGrouping: true,
    onEditingRowSave: () => {
      handleEditSave("GrpDT")
    },
    initialState: {
      density: "0px",
      grouping: ["G0000010"]
    },
    state: {
      density: "0px",
      rowSelection,
      // grouping:["G0000010"]
    },
    mantineTableBodyRowProps: ({ row, staticRowIndex, cell }) => ({
      onClick: () => {
        // console.log(row?.original, "row?.original");
        setRowSelection((prev) => ({
          [row.id]: !prev[row.id],
        }));
        setSelectedRow(row?.original)
      },
      selected: rowSelection[row.id],
      sx: { cursor: "pointer" },
      // height: 50,
    }),
    renderRowActions: ({ row, table }) => (
      <Flex gap="md">
        <Tooltip label="Edit">
          <ActionIcon
            onClick={() => {
              table.setEditingRow(row);
              setTableEditingRow(row);
              setGrpObj(row?.original)
            }}
          >
            <IconEdit size={"20px"} />
          </ActionIcon>
        </Tooltip>
      </Flex>
    ),
    renderBottomToolbar: ({ table }) => (
      <Box>
        <Grid gutter={4}>
          <Grid.Col span={12}>
            <Group
              spacing={"xs"}
              position="right"
              display={"flex"}
            >
              <MRT_TablePagination position="bottom" table={table} />
            </Group>
          </Grid.Col>
          <Grid.Col span={12}>
            <ButtonPanel
              //   table={TableRef}
              // data={GrpHKEY}
              // CAPID: "CHANLINE"
              data={
                props?.cAction === "E" && (OS62Obj?.S62F05.includes("D") || OS62Obj?.S62F05.includes("R") || OS62Obj?.S62F05.includes("M") || OS62Obj?.S62F05.includes("X")) ? GrpHKEY.filter((g) => g.CAPID == "CHANLINE") : GrpHKEY
              }
              Skey={table?.getSelectedRowModel()?.flatRows}
              from={OBJ?.data?.id}
              setFormatBtn={(e) => {
                let OBJ = JSON.parse(e);
                console.log("setFormatBtn_OBJ", OBJ)
                switch (OBJ.p0) {
                  case "S":
                    SelectDeSelect(OBJ?.DataGridRow, "GrpDT")
                    break;
                  case "F":
                    FilterBtn("GrpDT")
                    break;
                  case "MH":
                  case "MI":
                    multiHeaderFooter(OBJ?.DataGridRow, OBJ?.p0)
                  case "W+":
                  case "W-":
                    WidthBtn(OBJ?.DataGridRow, "GrpDT", OBJ.p0)
                    break;
                  case "CD":
                    DecimalBtn(OBJ?.DataGridRow, "GrpDT", OBJ?.p1)
                    break;
                  case "H":
                  case "GS":
                    HeaderChange(OBJ?.DataGridRow, "GrpDT", OBJ?.p0)
                    break;
                  case "GD":
                    displayChange(OBJ?.DataGridRow, "GrpDT")
                    break;
                  case "FL":
                  case "GP":
                    changeLinePageDetail(OBJ?.DataGridRow, OBJ?.p0)
                    break;
                  case "U":
                  case "D":
                    changeSeqUpDown(OBJ?.DataGridRow, "GrpDT", OBJ?.p0)
                    break;
                  case "I":
                  case "DS":
                    IncDecSeq(OBJ?.DataGridRow, "GrpDT", OBJ?.p0)
                    break;
                  case "SQ":
                    InsSeq(OBJ?.DataGridRow, "GrpDT")
                    break;
                  case "O":
                    AdvanceOption(OBJ?.DataGridRow, "GrpDT")
                    break;
                  default:
                    break;
                }
              }}
            />
          </Grid.Col>
        </Grid>
      </Box>
    )
  })

  const renderInputBox1 = (uID, v, c, cellid, index) => {
    let setInput;
    switch (c?.S53F14) {
      case "PLPOPUP":

        setInput = (
          <PLDataGrid
            id={`T02Ent_${index}`}
            width={"500px"}
            value={columnObj[c?.S53F04]}
            p1={seperate(c.S53F15)[1]}
            setEdit={(e) => {
              setColumnObj({ ...columnObj, [c?.S53F04]: e })
            }}
          />
        );
        break;
      case "COMBOBOX":
        if (c?.S53F02 == "TMCOL11") { // level
          setInput = (
            <PLComboBox
              cmbid={`T02Ent_${index}`}
              // initiallyOpened={initiallyOpened}
              value={columnObj[c?.S53F04]}
              copno={seperateLevel(c.S53F15)[1]}
              dispexpr={"DisplayMember"}
              valexpr={"ValueMember"}
              setEdit={(e) => {
                console.log("COMBOBOX", e)
                setColumnObj({ ...columnObj, [c?.S53F04]: e.value })
              }}
            />
          );
        } else {
          setInput = (
            <PLComboBox
              cmbid={`T02Ent_${index}`}
              // initiallyOpened={initiallyOpened}
              value={columnObj[c?.S53F04]}
              copno={seperate(c.S53F15)[1]}
              dispexpr={"DisplayMember"}
              valexpr={"ValueMember"}
              setEdit={(e) => {
                console.log("COMBOBOX", e)
                setColumnObj({ ...columnObj, [c?.S53F04]: e.value })
              }}
            />
          );
        }
        break;
      case "TEXTBOX":
        setInput = (
          <PLTextBox
            id={`T02Ent_${index}`}
            value={columnObj[c?.S53F04]}
            setEdit={(e) => {
              setColumnObj({ ...columnObj, [c?.S53F04]: e.target.value })
            }}
          />
        );

        break;
      case "NUMBOX":
        setInput = (
          <PLNumberBox
            id={`T02Ent_${index}`}
            value={columnObj[c?.S53F04]}
            setEdit={(e) => {
              setColumnObj({ ...columnObj, [c?.S53F04]: e })
            }}
          />
        );
        break;
      default:
        setInput = null;
        break;
    }
    return setInput;

  };

  useEffect(() => {
    if (ColEnt) {
      let cols = []
      let grp = []
      ColEnt?.filter((v, i) => v?.S53F00 === "C").map((c, i) => {
        cols.push(
          columnHelper.accessor(c.S53F04, {
            header: c?.S53F03,
            id: c?.S53UID,
            size: c?.S53F07 == 0 ? 0 : c?.S53F07,
            enableEditing: c?.S53F02 === "TMCOL3" ? false : true,
            Cell: ({ cell, row }) => {
              // console.log("cell.getValue()", cell.getValue())
              let val = cell.getValue();
              return c.S53F07 == 0 ? "" : val
            },
            Edit: ({ cell, row }) => {
              let val = cell.getValue();
              return renderInputBox1(c.S53UID, val, c, cell?.id, i);
            },
          })
        )
      })
      setColumns(cols)
    }
  }, [ColEnt, colData, ColDT, columnObj])

  useEffect(() => {
    if (ColDT) {
      setColData(ColDT)
    }
  }, [ColDT])

  const columTable = useMantineReactTable({
    columns,
    data: colData,
    mantineTableProps: { withColumnBorders: true },
    mantineTableContainerProps: {
      sx: { height: height * 0.5 },
    },
    enableStickyHeader: true,
    enableTopToolbar: false,
    enableColumnActions: false,
    enableSorting: false,
    enableEditing: true,
    editDisplayMode: "row",
    onEditingRowSave: () => {
      handleEditSave("ColDT")
    },
    initialState: {
      density: "0px",
    },
    state: {
      rowSelection: rowSelection1,
      density: "0px"
    },
    mantineTableBodyRowProps: ({ row, staticRowIndex, cell }) => ({
      onClick: () => {
        // console.log(row?.original, "row?.original");
        setRowSelection1((prev) => ({
          [row.id]: !prev[row.id],
        }));
        setSelectedRow(row?.original)
      },
      selected: rowSelection1[row.id],
      sx: { cursor: "pointer" },
      // height: 50,
    }),
    renderRowActions: ({ row, table }) => (
      <Flex gap="md">
        <Tooltip label="Edit">
          <ActionIcon
            onClick={() => {
              table.setEditingRow(row);
              setColumnTableEditingRow(row);
              setColumnObj(row?.original)
            }}
          >
            <IconEdit size={"20px"} />
          </ActionIcon>
        </Tooltip>
      </Flex>
    ),
    renderBottomToolbar: ({ table }) => (
      <Box>
        <Grid gutter={4}>
          <Grid.Col span={12}>
            <Group
              spacing={"xs"}
              position="right"
              display={"flex"}
            >
              <MRT_TablePagination position="bottom" table={table} />
            </Group>
          </Grid.Col>
          <Grid.Col span={12}>
            <ButtonPanel
              //   table={TableRef}
              data={COLHKEY}
              Skey={table?.getSelectedRowModel()?.flatRows}
              from={OBJ?.data?.id}
              setFormatBtn={(e) => {
                let OBJ = JSON.parse(e);
                console.log("setFormatBtn_OBJ", OBJ)
                switch (OBJ.p0) {
                  case "S":
                    SelectDeSelect(OBJ?.DataGridRow, "ColDT")
                    break;
                  case "F":
                    FilterBtn("ColDT")
                    break;
                  case "W+":
                  case "W-":
                    WidthBtn(OBJ?.DataGridRow, "ColDT", OBJ?.p0)
                    break;
                  case "CD":
                    DecimalBtn(OBJ?.DataGridRow, "ColDT", OBJ?.p1)
                    break;
                  case "TF":
                    totalBtn(OBJ?.DataGridRow)
                    break;
                  case "CT":
                    columnTotal(OBJ?.DataGridRow)
                    break;
                  case "NZ":
                    noneZero(OBJ?.DataGridRow)
                    break;
                  case "LV":
                    levelFun(OBJ?.DataGridRow)
                    break;
                  case "U":
                  case "D":
                    changeSeqUpDown(OBJ?.DataGridRow, "ColDT", OBJ?.p0)
                    break;
                  case "SC":
                    StretchBtn(OBJ?.DataGridRow)
                    break;
                  case "H":
                  case "GS":
                    HeaderChange(OBJ?.DataGridRow, "ColDT", OBJ?.p0)
                    break;
                  case "GD":
                    displayChange(OBJ?.DataGridRow, "ColDT")
                    break;
                  case "I":
                  case "DS":
                    IncDecSeq(OBJ?.DataGridRow, "ColDT", OBJ?.p0)
                    break;
                  case "SQ":
                    InsSeq(OBJ?.DataGridRow, "ColDT")
                    break;
                  case "O":
                    AdvanceOption(OBJ?.DataGridRow, "ColDT")
                    break;
                  default:
                    break;
                }
              }}
            />
          </Grid.Col>
        </Grid>
      </Box>
    )
  })

  return (
    <>
      {
        SaveLoading[OBJ?.data?.cSTID] ? GlobalClass.RenderLoader("dots") :
          <>
            <Modal
              opened={DrawerObj?.open}
              // onClose={close}
              withCloseButton={() => { }}
              fullScreen={false} //
              // overlayProps={{ opacity: 0.5, blur: 4 }}
              closeButtonProps={{
                onClick: () => {
                  setDrawerObj(dra);
                },
              }}
              title={DrawerObj.title}
              size={DrawerObj.size}
              closeOnClickOutside={false}
              position={DrawerObj?.position ?? "right"}
              onClose={
                typeof DrawerObj?.onclose == "function"
                  ? DrawerObj?.onclose
                  : () => {
                    setDrawerObj(dra);
                  }
              }
            >
              {DrawerObj.body}
            </Modal>
            {isLoading ? GlobalClass.RenderLoader("dots") :
              <>
                {content == "form" ?
                  <>
                    <Box style={{ padding: "30px" }}>
                      <Grid gutter={4}>
                        <Grid.Col
                          span={12}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                          }}
                        >
                          <Grid.Col span={2}>
                            <Text style={{ fontSize: 12 }}>Description</Text>
                          </Grid.Col>
                          <Grid.Col span={9}>
                            <PLTextBox
                              value={OS62Obj?.S62F04}
                              setEdit={(e) => {
                                //console.log(e)
                                setOS62Obj({ ...OS62Obj, S62F04: e.target.value });
                              }}
                            />
                          </Grid.Col>
                        </Grid.Col>

                        <Grid.Col
                          span={12}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                          }}
                        >
                          <Grid.Col span={2}>
                            <Text style={{ fontSize: 12 }}>Report Header</Text>
                          </Grid.Col>
                          <Grid.Col span={9}>
                            <PLTextarea
                              // onFocus={(e) => {
                              //   console.log("onFocus",e)
                              //   setOS62Obj({...OS62Obj, S62F10:OS62Obj?.S62F04 ?? OS62Obj?.S62F10})
                              // }}
                              value={OS62Obj?.S62F10}
                              onChange={(e) => {
                                // console.log("onChange",e)
                                setOS62Obj({ ...OS62Obj, S62F10: e.target.value })
                              }}
                            />
                          </Grid.Col>
                        </Grid.Col>
                        <Grid.Col
                          span={6}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                          }}
                        >
                          <Grid.Col span={4}>
                            <Text style={{ fontSize: 12 }}>Zooming</Text>
                          </Grid.Col>
                          <Grid.Col span={7}>
                            <PLComboBox
                              data={COP1}
                              dispexpr="COPF04"
                              valexpr="COPF01"
                              value={OS62Obj?.S62F08}
                              setEdit={(e) => {
                                console.log(e)
                                setOS62Obj({ ...OS62Obj, S62F08: e });
                              }}
                            />
                          </Grid.Col>
                        </Grid.Col>
                        <Grid.Col
                          span={6}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                          }}
                        >
                          <Grid.Col span={4}>
                            <Text style={{ fontSize: 12 }}>Filter</Text>
                          </Grid.Col>
                          <Grid.Col span={5}>
                            <PLComboBox
                              onKeyDown={(e) => {
                                if (filterVal === "Y") {
                                  if (e.key === "Tab" || e.key === "Enter") {
                                    filterList()
                                  }
                                }
                              }}
                              data={COP1}
                              dispexpr="COPF04"
                              valexpr="COPF01"
                              value={filterVal}
                              setEdit={(e) => {
                                console.log("PLComboBox", e)
                                setFilterVal(e);
                              }}
                            />
                          </Grid.Col>
                          <Grid.Col span={3}>
                            <Text size={12}>{OS62Obj?.S62F06NM}</Text>
                          </Grid.Col>
                        </Grid.Col>
                        <Grid.Col
                          span={6}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                          }}
                        >
                          <Grid.Col span={4}>
                            <Text style={{ fontSize: 12 }}>Grand Total</Text>
                          </Grid.Col>
                          <Grid.Col span={7}>
                            <PLComboBox
                              data={COP1}
                              dispexpr="COPF04"
                              valexpr="COPF01"
                              value={F14DICObj?.S62_GRT}
                              setEdit={(e) => {
                                //console.log(e)
                                setF14DICObj({ ...F14DICObj, S62_GRT: e });
                              }}
                            />
                          </Grid.Col>
                        </Grid.Col>
                        <Grid.Col
                          span={6}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                          }}
                        >
                          <Grid.Col span={4}>
                            <Text style={{ fontSize: 12 }}>Columns</Text>
                          </Grid.Col>
                          <Grid.Col span={7}>
                            <PLNumberBox
                              id="columns"
                              setNumber={(e) => {
                                console.log("setNumber", e)
                                if (e.target.value > "3" || e.target.value < "1") {
                                  document.getElementById("columns").focus()
                                  GlobalClass.Notify("warning", "Warning", "Range: 1 to 3")
                                }
                              }}
                              value={F14DICObj?.S62_MCOL}
                              setEdit={(e) => {
                                //console.log(e)
                                setF14DICObj({ ...F14DICObj, S62_MCOL: e });
                              }}
                            />
                          </Grid.Col>
                        </Grid.Col>
                        <Grid.Col
                          span={6}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                          }}
                        >
                          <Grid.Col span={4}>
                            <Text style={{ fontSize: 12 }}>Line</Text>
                          </Grid.Col>
                          <Grid.Col span={7}>
                            <PLComboBox
                              data={COPF21}
                              dispexpr="COPF04"
                              valexpr="COPF01"
                              value={F14DICObj?.S62_HLN}
                              setEdit={(e) => {
                                //console.log(e)
                                setF14DICObj({ ...F14DICObj, S62_HLN: e });
                              }}
                            />
                          </Grid.Col>
                        </Grid.Col>
                        <Grid.Col
                          span={6}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                          }}
                        >
                          <Grid.Col span={4}>
                            <Text style={{ fontSize: 12 }}>Vertical Line</Text>
                          </Grid.Col>
                          <Grid.Col span={7}>
                            <PLComboBox
                              data={COPF21}
                              dispexpr="COPF04"
                              valexpr="COPF01"
                              value={F14DICObj?.S62_VLN}
                              setEdit={(e) => {
                                //console.log(e)
                                setF14DICObj({ ...F14DICObj, S62_VLN: e });
                              }}
                            />
                          </Grid.Col>
                        </Grid.Col>
                        <Grid.Col
                          span={6}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                          }}
                        >
                          <Grid.Col span={4}>
                            <Text style={{ fontSize: 12 }}>Product Detail</Text>
                          </Grid.Col>
                          <Grid.Col span={7}>
                            <PLComboBox
                              data={COP1}
                              dispexpr="COPF04"
                              valexpr="COPF01"
                              disabled={['PURCREG', 'SALESREG', 'GSTSALES_REG', 'GSTPURCH_REG'].includes(tmList[OBJ?.data?.cSTID]?.oS60?.cSTID) || ['TMREGISTER'].includes(tmList[OBJ?.data?.cSTID]?.oS60?.Handler) ? false : true}
                              value={F14DICObj?.S62_PDET}
                              setEdit={(e) => {
                                //console.log(e)
                                setF14DICObj({ ...F14DICObj, S62_PDET: e });
                              }}
                            />
                          </Grid.Col>
                        </Grid.Col>
                        <Grid.Col
                          span={6}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                          }}
                        >
                          <Grid.Col span={4}>
                            <Text style={{ fontSize: 12 }}>Page Total</Text>
                          </Grid.Col>
                          <Grid.Col span={7}>
                            <PLComboBox
                              data={COP1}
                              dispexpr="COPF04"
                              valexpr="COPF01"
                              disabled={['PURCREG', 'SALESREG', 'GSTSALES_REG', 'GSTPURCH_REG'].includes(tmList[OBJ?.data?.cSTID]?.oS60?.cSTID) || ['TMREGISTER'].includes(tmList[OBJ?.data?.cSTID]?.oS60?.Handler) ? false : true}
                              value={F14DICObj?.S62_PTOT}
                              setEdit={(e) => {
                                //console.log(e)
                                setF14DICObj({ ...F14DICObj, S62_PTOT: e });
                              }}
                            />
                          </Grid.Col>
                        </Grid.Col>
                        <Grid.Col
                          span={6}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                          }}
                        >
                          <Grid.Col span={4}>
                            <Text style={{ fontSize: 12 }}>Party Stock</Text>
                          </Grid.Col>
                          <Grid.Col span={7}>
                            <PLComboBox
                              data={COP1}
                              dispexpr="COPF04"
                              valexpr="COPF01"
                              disabled={['PRTYWISEREP'].includes(tmList[OBJ?.data?.cSTID]?.oS60?.cSTID) ? false : true}
                              value={F14DICObj?.S62_PROP}
                              setEdit={(e) => {
                                //console.log(e)
                                setF14DICObj({ ...F14DICObj, S62_PROP: e });
                              }}
                            />
                          </Grid.Col>
                        </Grid.Col>
                        <Grid.Col
                          span={6}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                          }}
                        >
                          <Grid.Col span={4}>
                            <Text style={{ fontSize: 12 }}>Adjusted Order</Text>
                          </Grid.Col>
                          <Grid.Col span={7}>
                            <PLComboBox
                              data={COP1}
                              dispexpr="COPF04"
                              valexpr="COPF01"
                              disabled={[].includes(tmList[OBJ?.data?.cSTID]?.oS60?.cSTID) || (['PRTYWISEREP'].includes(tmList[OBJ?.data?.cSTID]?.oS60?.cSTID) && oYear?.OSC?._LOCATIONW == "Y") ? false : true}
                              value={F14DICObj?.S62_ADJO}
                              setEdit={(e) => {
                                //console.log(e)
                                setF14DICObj({ ...F14DICObj, S62_ADJO: e });
                              }}
                            />
                          </Grid.Col>
                        </Grid.Col>
                      </Grid>
                    </Box>
                    <Grid mt={20}>
                      <Grid.Col
                        span={12}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Group spacing={"xs"} position="center" mt={5} mb={5}>
                          <Button
                            size="xs"
                            onClick={() => callCustomCol("customColumn")}
                          // disabled={activeBtn ? false : true}
                          >
                            Custom Column
                          </Button>
                          <Button
                            size="xs"
                            onClick={() => callCustomCol("reportHeader")}
                          // disabled={activeBtn ? true : false}
                          // className={classes.button}
                          >
                            Report Header
                          </Button>
                          <Button
                            size="xs"
                            onClick={() => callCustomCol("reportSummary")}
                          // disabled={activeBtn ? true : false}
                          // className={classes.button}
                          >
                            Report Summary
                          </Button>
                        </Group>
                      </Grid.Col>
                    </Grid>
                  </>
                  :
                  content == "table1" && OS62Obj?.S62F08 == "Y" ?
                    <>
                      <Grid gutter={4}>
                        <Grid.Col span={12}>
                          <MantineReactTable table={table} />
                        </Grid.Col>
                      </Grid>
                    </> :
                    content == "table2" ?
                      <>
                        <Grid gutter={4}>
                          <Grid.Col span={12}>
                            <MantineReactTable table={columTable} />
                          </Grid.Col>
                        </Grid>
                      </> : ""
                }
                <Divider size="xs" />
                <Grid>
                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Button
                        size="xs"
                        mr={4}
                        onClick={() => callPrinting()}
                      // disabled={activeBtn ? false : true}
                      >
                        Printing Options
                      </Button>
                      <Button
                        size="xs"

                        onClick={() => callGraph()}
                      // disabled={activeBtn ? true : false}
                      // className={classes.button}
                      >
                        Graph
                      </Button>
                    </Grid.Col>
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        gap: "4px",
                      }}
                    >
                      <Button
                        size="xs"
                        onClick={() => {
                          content == "table2" && OS62Obj?.S62F08 == "Y" ? setContent("table1") :
                            content == "table2" && OS62Obj?.S62F08 == "N" ? setContent("form") :
                              content == "table1" ? setContent("form") : setContent("form")
                        }}
                        disabled={content == "form"}
                      >
                        &lt; Back
                      </Button>
                      <Button
                        size="xs"
                        onClick={() => {
                          const filterGrpData = grpData?.filter((g) => g.COLNO !== null)
                          if (OS62Obj.S62F08 === "Y" && filterGrpData.length === 0 && content == "table1") {
                            GlobalClass.Notify("warning", "Warning", "At least one group must be selected when Zoom is yes")
                          } else {
                            content == "form" && OS62Obj?.S62F08 == "Y" ? setContent("table1") :
                              content == "form" && OS62Obj?.S62F08 == "N" ? setContent("table2") :
                                content == "table1" ? setContent("table2") :
                                  setContent("table2")
                          }
                        }}
                        disabled={content == "table2" || OS62Obj?.S62F04 == ""}
                      >
                        Next &gt;
                      </Button>
                      <Button
                        size="xs"
                        onClick={SaveFormat}
                        disabled={OS62Obj?.S62F04 == ""}
                      >
                        Finish
                      </Button>
                      <Button
                        size="xs"
                        onClick={() => setClose({ ...draw, open: false })}
                      >
                        Cancel
                      </Button>
                    </Grid.Col>
                  </Grid.Col>
                </Grid>
              </>
            }
          </>
      }
    </>
  );
}
