import { Button, Card, Divider, FileButton, FileInput, Grid, Group, Image, Paper, Tabs, Text } from '@mantine/core'
import React, { useEffect, useState } from 'react'
import PLTextBox from '../../PlusComponent/PLTextBox'
import PLComboBox from '../../PlusComponent/PLComboBox'
import PLDateBox from '../../PlusComponent/PLDateBox'
import PLNumberBox from '../../PlusComponent/PLNumberBox'
import { useDispatch, useSelector } from 'react-redux'
import { GetAddCmpEnt, GetUserList } from '../../utils/slices/UserListSlice'
import Store from '../../utils/store'
import PLDataGrid from '../../PlusComponent/PLDataGrid'
import GlobalClass from '../../utils/GlobalClass'
import { DataSaveLoading } from '../../utils/slices/DataSaveLoadingSlice'
import { ModalDelete } from '../../utils/slices/ModalSlice'
import gensetting from '../../utils/gensetting'
import { IconPhotoOff } from '@tabler/icons-react'

const AddNewCompanyForm = (props) => {
    console.log("AddNewCompanyForm prosp", props);

    const { TableRow, action } = props;

    const dropdownVal = [
        { value: 'N', label: 'None' },
        { value: 'C', label: 'Companywise' },
        { value: 'U', label: 'Userwise' },
    ]

    const [GroupName, setGroupName] = useState({
        ComboFlag: true,
        TextFlag: false,
        ComboVal: null
    })

    const [typeVal, setTypeVal] = useState(null)

    const [btnState, setBtnState] = useState({
        btnOne: false,
        btnTwo: true,
        // btnThree: true
    })

    const [file, setFile] = useState(null)
    const [tabName, setTabName] = useState('company')
    const [CMPLogo, setCMPLogo] = useState(null)
    const [base64URL, setBase64URL] = useState("");
    const [CmpInfoFlag, setCmpInfoFlag] = useState(false)
    const [M00Obj, setM00Obj] = useState({
        "DBSVER": 0.0,
        "FIELD01": 0.0,
        "FIELD02": "",
        "FIELD03": "",
        "FIELD04": "",
        "FIELD05": "",
        "FIELD06": "",
        "FIELD07": "",
        "FIELD08": "",
        "FIELD10": "",
        "FIELD11": "",
        "FIELD25": "",
        "FIELD41": "",
        "FIELD61": "",
        "FIELD62": "",
        "FIELD63": "",
        "FIELD64": false,
        "FIELD65": "",
        "FIELD70": "",
        "FIELD81": "",
        "FIELD91": "",
        "FIELD92": "",
        "FIELD93": "",
        "FIELD94": "",
        "FIELD98": "",
        "FIELD99": "",
        "FLDAED": "",
        "FLDBRC": "",
        "FLDUNQ": 1,
        "M00V01": "",
        "M00V02": "",
        "M00V03": "",
        "NBUILD": "",
        "NMAINVER": "",
        "NRELEASE": "",
        "DSDATE": "",
        "DEDATE": "",
        "_ADDRESS_1": "",
        "_ADDRESS_2": "",
        "_ADDRESS_3": "",
        "_CITY": "",
        "_PINCODE": "",
        "_PHONE1": "",
        "_PHONE2": "",
        "_MOBILE1": "",
        "_MOBILE2": "",
        "_FAX1": "",
        "_FAX2": "",
        "_EMAIL": "",
        "_WEB": "",
        "_STATE": "",
        "_COUNTRY": "",
        "_STCD": "",
        "_RPTHD1": "",
        "_RPTHD2": "",
        "_RPTFT1": "",
        "_RPTFT2": "",
        "_CMPLOGO": "",
        "_SYNCID": "",
        "_BSYNCID": "",
        "_01": "",
        "_02": "",
        "_03": "",
        "_05": "",
        "_06": "",
        "_07": "",
        "_08": "",
        "_16": "",
        "_17": "",
        "_09": "",
        "_10": "",
        "_11": "",
        "_12": "",
        "_13": "",
        "_14": "",
        "_15": ""
    })

    const dispatch = useDispatch();

    const SaveLoading = useSelector((state) => state?.DataSaveLoadingSlice?.SaveLoading);
    const AddCmpEnt = useSelector((state) => state?.UserList?.AddCmpEnt)
    const { CBOLAN, M00, P_CONT, P_CONTDT, P_PLCAT, P_PLCATDT, P_STATE, P_STATEDT, P_GRPDT } = useSelector((state) => state?.UserList?.AddCmpEnt)

    const { isLoading: CmpDataIsLoading, hasError: CmpDataError, ErrorMsg: CmpDataErrorMsg } = useSelector((state) => state?.UserList)


    const TabFocusOne = () => {
        setTabName('company')
        setBtnState({
            btnOne: false,
            btnTwo: true,
            // btnThree: true
        })
    }

    const TabFocusTwo = () => {
        setTabName('address')
        setBtnState({
            btnOne: true,
            btnTwo: true,
            // btnThree: true
        })
    }

    const TabFocusThree = () => {
        setTabName('other')
    }

    // console.log('tabName=>>>', tabName);
    // console.log('dropdownVal==>', dropdownVal)
    // console.log('typeVal=>>', typeVal)
    // console.log('file=>>', file)

    useEffect(() => {
        if (TableRow) {
            dispatch(GetAddCmpEnt({ cmpNo: TableRow?.cmpNo, action: (action == "CmpInfo" ? "E" : action) }))
        } else {
            dispatch(GetAddCmpEnt({ cmpNo: "", action: action }))
        }
    }, [TableRow])

    useEffect(() => {
        console.log("AddCmpEnt=>", AddCmpEnt);
        if (AddCmpEnt) {
            setM00Obj(M00)
            if (action == "CmpInfo") {
                setCmpInfoFlag(true)
            }
            // console.log("M00?.DEDATE=>", M00?.DEDATE.split("'").join(''), M00?.DEDATE)
        }
    }, [AddCmpEnt])

    // console.log("CMPLogo==>", CMPLogo);
    // console.log("base64URL==>", base64URL);

    // const getBase64 = (e) => {
    //     return new Promise(resolve => {
    //         let baseURL = "";
    //         let reader = new FileReader();

    //         reader.readAsDataURL(e);

    //         reader.onload = () => {
    //             baseURL = reader.result;
    //             resolve(baseURL);
    //         };
    //     });
    // };

    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            if (!file) {
                reject(new Error("No file provided"));
            }

            let reader = new FileReader();

            // Ensure file is PNG or any other image type
            const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
            if (!allowedTypes.includes(file.type)) {
                reject(new Error("Invalid file type. Only PNG, JPEG, and JPG are allowed."));
            }

            reader.readAsDataURL(file);

            reader.onload = () => {
                resolve(reader.result); // Returns the Base64 string
            };

            reader.onerror = (error) => {
                reject(new Error("File reading failed: " + error));
            };
        });
    };

    const CompanySubmit = () => {

        dispatch(DataSaveLoading({["AddEditCMP"]:true}))

        let PostObj = { "M00": M00Obj };
        console.log("PostObj==>", PostObj)
        GlobalClass.Notify(
            "info",
            action == "A" ? "Adding" : "Editing",
            "Please wait while we process your data"
        );

        var data = {
            CorpID: gensetting.getcorpid(),
            cUser: gensetting.getcuser(),
            cPass: gensetting.getcpass(),
            CmpNo: action == "E" ? TableRow?.cmpNo : "",
            cAction: action == "E" ? "E" : "A",
            cCode: "",
            lcode: "02",
            // "cOPara": "",
            cSData: JSON.stringify(PostObj),
        };

        let param = JSON.stringify({
            CorpID: gensetting.getcorpid(),
            cUser: gensetting.getcuser(),
            cPass: gensetting.getcpass(),
            CmpNo: action == "E" ? TableRow?.cmpNo : "",
            cAction: action == "E" ? "E" : "A",
            cCode: "",
            lcode: "02",
        });
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        };
        fetch(
            GlobalClass.ApiUrl + GlobalClass.CreateCmp + "?pa=" + param,
            requestOptions
        )
            .then((response) => response.json())
            .then((data) => {
                if (data.status == "SUCCESS") {
                    Promise.resolve(sessionStorage.setItem("cmp", M00Obj?.FIELD01)).then(() => dispatch(GetUserList({ Action: action }))).then(() => dispatch(DataSaveLoading({["AddEditCMP"]:false})))
                        .then(() => dispatch(ModalDelete(props.index)))
                        .then(() =>
                            GlobalClass.Notify(
                                "success",
                                action == "A" ? "Added" : "Edited",
                                action == "A" ? "Added successfully" : "Edited successfully"
                            )
                        ).then(() => window.location.reload())
                } else if (data.status == "FAIL") {
                    Promise.resolve(dispatch(DataSaveLoading({["AddEditCMP"]:false}))).then(() =>
                        GlobalClass.Notify("error", data?.status, data?.message)
                    )
                }
            })
            .catch((e) =>
                Promise.resolve(
                    GlobalClass.Notify("error", "Error", `${e.message}`)
                ).then(() => dispatch(DataSaveLoading({["AddEditCMP"]:false})))
            );
    };

    const CmpInfoPopupFields = () => {
        // console.log("CmpInfoPopupFields para=>",para)
        //ccccc
        return (
            <Grid gutter={1}>
                {/* Address 1 */}
                {M00Obj?._ADDRESS_1 && <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                    <Grid.Col span={4}>
                        <Text style={{ fontSize: 12 }}>Address_1:</Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <PLTextBox
                            value={M00Obj?._ADDRESS_1}
                        // setEdit={(e) => {
                        //     // console.log(e)
                        //     setM00Obj({ ...M00Obj, _ADDRESS_1: e.target.value })
                        // }}
                        />
                    </Grid.Col>
                </Grid.Col>}

                {/* Address 2 */}
                {M00Obj?._ADDRESS_2 && <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                    <Grid.Col span={4}>
                        <Text style={{ fontSize: 12 }}>Address_2:</Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <PLTextBox
                            value={M00Obj?._ADDRESS_2}
                        // setEdit={(e) => {
                        //     // console.log(e)
                        //     setM00Obj({ ...M00Obj, _ADDRESS_2: e.target.value })
                        // }}
                        />
                    </Grid.Col>
                </Grid.Col>}

                {/* Address 3 */}
                {M00Obj?._ADDRESS_3 && <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                    <Grid.Col span={4}>
                        <Text style={{ fontSize: 12 }}>Address_3:</Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <PLTextBox
                            value={M00Obj?._ADDRESS_3}
                        // setEdit={(e) => {
                        //     // console.log(e)
                        //     setM00Obj({ ...M00Obj, _ADDRESS_3: e.target.value })
                        // }}
                        />
                    </Grid.Col>
                </Grid.Col>}

                {/* City */}
                {M00Obj?._CITY && <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                    <Grid.Col span={4}>
                        <Text style={{ fontSize: 12 }}>City:</Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <PLTextBox
                            value={M00Obj?._CITY}
                        // setEdit={(e) => {
                        //     // console.log(e)
                        //     setM00Obj({ ...M00Obj, _CITY: e.target.value })
                        // }}
                        />
                    </Grid.Col>
                </Grid.Col>}

                {/* Pincode */}
                {M00Obj?._PINCODE && <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                    <Grid.Col span={4}>
                        <Text style={{ fontSize: 12 }}>Pincode:</Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <PLTextBox
                            entrytype={'NUMERIC'}
                            value={M00Obj?._PINCODE}
                        // setEdit={(e) => {
                        //     console.log("Pincode =>", [{
                        //         "e": e,
                        //         "value": e?.target?.value
                        //     }])
                        //     setM00Obj({ ...M00Obj, _PINCODE: e?.target?.value })
                        // }}
                        />
                    </Grid.Col>
                </Grid.Col>}

                {/* Phone 1 */}
                {M00Obj?._PHONE1 && <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                    <Grid.Col span={4}>
                        <Text style={{ fontSize: 12 }}>Phone1:</Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <PLTextBox
                            nmaxlength={10}
                            entrytype={'NUMERIC'}
                            value={M00Obj?._PHONE1}
                        // setEdit={(e) => {
                        //     // console.log(e)
                        //     setM00Obj({ ...M00Obj, _PHONE1: e?.target?.value })
                        // }}
                        />
                    </Grid.Col>
                </Grid.Col>}

                {/* Phone 2 */}
                {M00Obj?._PHONE2 && <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                    <Grid.Col span={4}>
                        <Text style={{ fontSize: 12 }}>Phone2:</Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <PLTextBox
                            nmaxlength={10}
                            entrytype={'NUMERIC'}
                            value={M00Obj?._PHONE2}
                        // setEdit={(e) => {
                        //     // console.log(e)
                        //     setM00Obj({ ...M00Obj, _PHONE2: e?.target?.value })
                        // }}
                        />
                    </Grid.Col>
                </Grid.Col>}

                {/* Phone 3 */}
                {M00Obj?._PHONE3 && <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                    <Grid.Col span={4}>
                        <Text style={{ fontSize: 12 }}>Phone3:</Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <PLTextBox
                            nmaxlength={10}
                            entrytype={'NUMERIC'}
                            value={M00Obj?._PHONE3}
                        // setEdit={(e) => {
                        //     // console.log(e)
                        //     setM00Obj({ ...M00Obj, _PHONE3: e?.target?.value })
                        // }}
                        />
                    </Grid.Col>
                </Grid.Col>}

                {/* mobile 1 */}
                {M00Obj?._MOBILE1 && <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                    <Grid.Col span={4}>
                        <Text style={{ fontSize: 12 }}>Mobile1:</Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <PLTextBox
                            nmaxlength={10}
                            entrytype={'NUMERIC'}
                            value={M00Obj?._MOBILE1}
                        // setEdit={(e) => {
                        //     // console.log(e)
                        //     setM00Obj({ ...M00Obj, _MOBILE1: e?.target?.value })
                        // }}
                        />
                    </Grid.Col>
                </Grid.Col>}

                {/* mobile 2 */}
                {M00Obj?._MOBILE2 && <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                    <Grid.Col span={4}>
                        <Text style={{ fontSize: 12 }}>Mobile2:</Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <PLTextBox
                            nmaxlength={10}
                            entrytype={'NUMERIC'}
                            value={M00Obj?._MOBILE2}
                        // setEdit={(e) => {
                        //     // console.log(e)
                        //     setM00Obj({ ...M00Obj, _MOBILE2: e?.target?.value })
                        // }}
                        />
                    </Grid.Col>
                </Grid.Col>}

                {/* Fax 1 */}
                {M00Obj?._FAX1 && <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                    <Grid.Col span={4}>
                        <Text style={{ fontSize: 12 }}>Fax1:</Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <PLNumberBox
                            value={M00Obj?._FAX1}
                        // setEdit={(e) => {
                        //     // console.log(e)
                        //     setM00Obj({ ...M00Obj, _FAX1: e })
                        // }}
                        />
                    </Grid.Col>
                </Grid.Col>}

                {/* Fax 2 */}
                {M00Obj?._FAX2 && <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                    <Grid.Col span={4}>
                        <Text style={{ fontSize: 12 }}>Fax2:</Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <PLNumberBox
                            value={M00Obj?._FAX2}
                        // setEdit={(e) => {
                        //     // console.log(e)
                        //     setM00Obj({ ...M00Obj, _FAX2: e })
                        // }}
                        />
                    </Grid.Col>
                </Grid.Col>}

                {/* Email  */}
                {M00Obj?._EMAIL && <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                    <Grid.Col span={4}>
                        <Text style={{ fontSize: 12 }}>Email:</Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <PLTextBox
                            value={M00Obj?._EMAIL}
                        // setEdit={(e) => {
                        //     // console.log(e)
                        //     setM00Obj({ ...M00Obj, _EMAIL: e.target.value })
                        // }}
                        />
                    </Grid.Col>
                </Grid.Col>}

                {/* Website  */}
                {M00Obj?._WEB && <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                    <Grid.Col span={4}>
                        <Text style={{ fontSize: 12 }}>Website:</Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <PLTextBox
                            value={M00Obj?._WEB}
                        // setEdit={(e) => {
                        //     // console.log(e)
                        //     setM00Obj({ ...M00Obj, _WEB: e.target.value })
                        // }}
                        />
                    </Grid.Col>
                </Grid.Col>}

                {/* State  */}
                {M00Obj?._STATE && <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                    <Grid.Col span={4}>
                        <Text style={{ fontSize: 12 }}>State:</Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <PLDataGrid
                            value={M00Obj?._STATE}
                            valexpr={"PLSF01"}
                            dispexpr={"PLSF02"}
                            data={P_STATEDT}
                            TmData={P_STATE}
                        // setEdit={(e) => {
                        //     console.log(e)
                        //     setM00Obj({ ...M00Obj, _STATE: e?.PLSF01 })
                        // }}
                        />
                    </Grid.Col>
                </Grid.Col>}

                {/* State Code  */}
                {M00Obj?._STCD && <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                    <Grid.Col span={4}>
                        <Text style={{ fontSize: 12 }}>State Code:</Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <PLTextBox
                            value={M00Obj?._STCD}
                            // width={"500px"}
                            valexpr={"PLSF01"}
                            dispexpr={"PLSF02"}
                            data={P_STATEDT}
                            TmData={P_STATE}
                        // setEdit={(e) => {
                        //     console.log(e)
                        //     setM00Obj({ ...M00Obj, _STATE: e?.PLSF01 })
                        // }}
                        />
                    </Grid.Col>
                </Grid.Col>}

                {/* Report Header 1 */}
                {M00Obj?.FIELD91 && <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                    <Grid.Col span={4}>
                        <Text style={{ fontSize: 12 }}>Report Header1:</Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <PLTextBox
                            value={M00Obj?.FIELD91}
                        // setEdit={(e) => {
                        //     // console.log(e)
                        //     setM00Obj({ ...M00Obj, FIELD91: e.target.value })
                        // }}
                        />
                    </Grid.Col>
                </Grid.Col>}

                {/* Report Header 2  */}
                {M00Obj?.FIELD92 && <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                    <Grid.Col span={4}>
                        <Text style={{ fontSize: 12 }}>Report Header2:</Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <PLTextBox
                            value={M00Obj?.FIELD92}
                        // setEdit={(e) => {
                        //     // console.log(e)
                        //     setM00Obj({ ...M00Obj, FIELD92: e.target.value })
                        // }}
                        />
                    </Grid.Col>
                </Grid.Col>}

                {/* Report Footer 1  */}
                {M00Obj?.FIELD93 && <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                    <Grid.Col span={4}>
                        <Text style={{ fontSize: 12 }}>Report Footer1:</Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <PLTextBox
                            value={M00Obj?.FIELD93}
                        // setEdit={(e) => {
                        //     // console.log(e)
                        //     setM00Obj({ ...M00Obj, FIELD93: e.target.value })
                        // }}
                        />
                    </Grid.Col>
                </Grid.Col>}

                {/* Report Footer 2  */}
                {M00Obj?.FIELD94 && <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                    <Grid.Col span={4}>
                        <Text style={{ fontSize: 12 }}>Report Footer2:</Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <PLTextBox
                            value={M00Obj?.FIELD94}
                        // setEdit={(e) => {
                        //     // console.log(e)
                        //     setM00Obj({ ...M00Obj, FIELD94: e.target.value })
                        // }}
                        />
                    </Grid.Col>
                </Grid.Col>}

                {/* Pan No.  */}
                {M00Obj?._01 && <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                    <Grid.Col span={4}>
                        <Text style={{ fontSize: 12 }}>PAN NO:</Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <PLTextBox
                            value={M00Obj?._01}
                        // setEdit={(e) => {
                        //     // console.log(e)
                        //     setM00Obj({ ...M00Obj, _01: e?.target?.value })
                        // }}
                        />
                    </Grid.Col>
                </Grid.Col>}

                {/* Tin No.  */}
                {M00Obj?._02 && <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                    <Grid.Col span={4}>
                        <Text style={{ fontSize: 12 }}>TIN NO:</Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <PLTextBox
                            value={M00Obj?._02}
                        // setEdit={(e) => {
                        //     // console.log(e)
                        //     setM00Obj({ ...M00Obj, _02: e?.target?.value })
                        // }}
                        />
                    </Grid.Col>
                </Grid.Col>}

                {/* Cst No. */}
                {M00Obj?._03 && <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                    <Grid.Col span={4}>
                        <Text style={{ fontSize: 12 }}>CST NO:</Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <PLTextBox
                            value={M00Obj?._03}
                        // setEdit={(e) => {
                        //     // console.log(e)
                        //     setM00Obj({ ...M00Obj, _03: e?.target?.value })
                        // }}
                        />
                    </Grid.Col>
                </Grid.Col>}

                {/* Ecc No. */}
                {M00Obj?._05 && <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                    <Grid.Col span={4}>
                        <Text style={{ fontSize: 12 }}>ECC NO:</Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <PLTextBox
                            value={M00Obj?._05}
                        // setEdit={(e) => {
                        //     // console.log(e)
                        //     setM00Obj({ ...M00Obj, _05: e?.target?.value })
                        // }}
                        />
                    </Grid.Col>
                </Grid.Col>}

                {/* Tan No. */}
                {M00Obj?._06 && <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                    <Grid.Col span={4}>
                        <Text style={{ fontSize: 12 }}>TAN NO:</Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <PLTextBox
                            value={M00Obj?._06}
                        // setEdit={(e) => {
                        //     // console.log(e)
                        //     setM00Obj({ ...M00Obj, _06: e?.target?.value })
                        // }}
                        />
                    </Grid.Col>
                </Grid.Col>}

                {/* Service Tax No. */}
                {M00Obj?._07 && <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                    <Grid.Col span={4}>
                        <Text style={{ fontSize: 12 }}>Service Tax No:</Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <PLTextBox
                            value={M00Obj?._07}
                        // setEdit={(e) => {
                        //     // console.log(e)
                        //     setM00Obj({ ...M00Obj, _07: e?.target?.value })
                        // }}
                        />
                    </Grid.Col>
                </Grid.Col>}

                {/* Juridiction City */}
                {M00Obj?._08 && <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                    <Grid.Col span={4}>
                        <Text style={{ fontSize: 12 }}>Juridiction City:</Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <PLTextBox
                            value={M00Obj?._08}
                        // setEdit={(e) => {
                        //     // console.log(e)
                        //     setM00Obj({ ...M00Obj, _08: e.target.value })
                        // }}
                        />
                    </Grid.Col>
                </Grid.Col>}

                {/* GSTIN No */}
                {M00Obj?._16 && <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                    <Grid.Col span={4}>
                        <Text style={{ fontSize: 12 }}>GSTIN No:</Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <PLTextBox
                            value={M00Obj?._16}
                        // setEdit={(e) => {
                        //     // console.log(e)
                        //     setM00Obj({ ...M00Obj, _16: e?.target?.value })
                        // }}
                        />
                    </Grid.Col>
                </Grid.Col>}

                {/* Food Product LIC NO */}
                {M00Obj?._17 && <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                    <Grid.Col span={4}>
                        <Text style={{ fontSize: 12 }}>Food Product LIC NO:</Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <PLTextBox
                            value={M00Obj?._17}
                        // setEdit={(e) => {
                        //     // console.log(e)
                        //     setM00Obj({ ...M00Obj, _17: e?.target?.value })
                        // }}
                        />
                    </Grid.Col>
                </Grid.Col>}

                {/* Bank Name */}
                {M00Obj?._09 && <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                    <Grid.Col span={4}>
                        <Text style={{ fontSize: 12 }}>Bank Name:</Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <PLTextBox
                            value={M00Obj?._09}
                        // setEdit={(e) => {
                        //     // console.log(e)
                        //     setM00Obj({ ...M00Obj, _09: e.target.value })
                        // }}
                        />
                    </Grid.Col>
                </Grid.Col>}

                {/* Branch Name */}
                {M00Obj?._10 && <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                    <Grid.Col span={4}>
                        <Text style={{ fontSize: 12 }}>Branch Name:</Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <PLTextBox
                            value={M00Obj?._10}
                        // setEdit={(e) => {
                        //     // console.log(e)
                        //     setM00Obj({ ...M00Obj, _10: e.target.value })
                        // }}
                        />
                    </Grid.Col>
                </Grid.Col>}

                {/* Address */}
                {M00Obj?._11 && <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                    <Grid.Col span={4}>
                        <Text style={{ fontSize: 12 }}>Address:</Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <PLTextBox
                            value={M00Obj?._11}
                        // setEdit={(e) => {
                        //     // console.log(e)
                        //     setM00Obj({ ...M00Obj, _11: e.target.value })
                        // }}
                        />
                    </Grid.Col>
                </Grid.Col>}

                {/* Account No */}
                {M00Obj?._12 && <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                    <Grid.Col span={4}>
                        <Text style={{ fontSize: 12 }}>Account No:</Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <PLTextBox
                            value={M00Obj?._12}
                        // setEdit={(e) => {
                        //     // console.log(e)
                        //     setM00Obj({ ...M00Obj, _12: e?.target?.value })
                        // }}
                        />
                    </Grid.Col>
                </Grid.Col>}

                {/* IFSC code */}
                {M00Obj?._13 && <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                    <Grid.Col span={4}>
                        <Text style={{ fontSize: 12 }}>IFSC code:</Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <PLTextBox
                            value={M00Obj?._13}
                        // setEdit={(e) => {
                        //     // console.log(e)
                        //     setM00Obj({ ...M00Obj, _13: e?.target?.value })
                        // }}
                        />
                    </Grid.Col>
                </Grid.Col>}

                {/* IBAN No */}
                {M00Obj?._14 && <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                    <Grid.Col span={4}>
                        <Text style={{ fontSize: 12 }}>IBAN No:</Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <PLTextBox
                            value={M00Obj?._14}
                        // setEdit={(e) => {
                        //     // console.log(e)
                        //     setM00Obj({ ...M00Obj, _14: e?.target?.value })
                        // }}
                        />
                    </Grid.Col>
                </Grid.Col>}

                {/* SWIFT Code */}
                {M00Obj?._15 && <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                    <Grid.Col span={4}>
                        <Text style={{ fontSize: 12 }}>SWIFT Code:</Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <PLTextBox
                            value={M00Obj?._15}
                        // setEdit={(e) => {
                        //     // console.log(e)
                        //     setM00Obj({ ...M00Obj, _15: e?.target?.value })
                        // }}
                        />
                    </Grid.Col>
                </Grid.Col>}

            </Grid>
        )
    }

    return (<>
        {
            CmpDataError ? <>
                <Text>{CmpDataErrorMsg}</Text>
                <Button onClick={() => {
                    if (TableRow) {
                        dispatch(GetAddCmpEnt({ cmpNo: TableRow?.cmpNo, action: (action == "CmpInfo" ? "E" : action) }))
                    } else {
                        dispatch(GetAddCmpEnt({ cmpNo: "", action: action }))
                    }
                }}>
                    Retry
                </Button>
            </> :
                <>
                    {
                        SaveLoading["AddEditCMP"] == true || CmpDataIsLoading == true ? GlobalClass.RenderLoader('dots') : <>
                            {
                                action == "CmpInfo" && CmpInfoFlag ? <>
                                    {
                                        CmpInfoPopupFields()
                                    }
                                </> :
                                    <div>
                                        <Tabs color="gray" variant="outline" value={tabName} onTabChange={setTabName}>
                                            <Tabs.List>
                                                <Tabs.Tab value='company' onClick={(e) => btnState}>
                                                    Company
                                                </Tabs.Tab>
                                                <Tabs.Tab value='address' onClick={(e) => btnState}>
                                                    Address
                                                </Tabs.Tab>
                                                <Tabs.Tab value='other' onClick={(e) => btnState}>
                                                    Other
                                                </Tabs.Tab>
                                            </Tabs.List>

                                            <Tabs.Panel value='company'>
                                                <>
                                                    <Paper shadow="md" radius="md" p="xs" withBorder>
                                                        {/* <Card> */}
                                                        <Grid gutter={4}>

                                                            {/* <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                                        <Grid.Col span={2}>
                                            <Text style={{ fontSize: 12 }}>Data Path:</Text>
                                        </Grid.Col>
                                        <Grid.Col span={4}>
                                            <PLTextBox />
                                        </Grid.Col>
                                        <Group>
                                            <FileButton>
                                                {(props) => <Button variant='light' size='xs' {...props}>...</Button>}
                                            </FileButton>
                                        </Group>
                                    </Grid.Col> */}
                                                        </Grid>

                                                        <Divider my="xs" color='darkGray' label={<Text style={{ fontSize: 15, color: 'black' }}>Company Details</Text>} />

                                                        <Grid gutter={4}>
                                                            {/* <Grid.Col span={12}> */}
                                                            <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                                                                <Grid.Col span={2}>
                                                                    <Text style={{ fontSize: 12 }}>Country:</Text>
                                                                </Grid.Col>
                                                                <Grid.Col span={4}>
                                                                    <PLDataGrid
                                                                        value={M00Obj?._COUNTRY}
                                                                        width={"500px"}
                                                                        valexpr={"PLCF01"}
                                                                        dispexpr={"PLCF02"}
                                                                        data={P_CONTDT}
                                                                        TmData={P_CONT}
                                                                        setEdit={(e) => {
                                                                            console.log("==>", e)
                                                                            setM00Obj({ ...M00Obj, _COUNTRY: e?.PLCF01 })
                                                                        }}
                                                                    />
                                                                </Grid.Col>
                                                            </Grid.Col>

                                                            <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                                                                <Grid.Col span={2}>
                                                                    <Text style={{ fontSize: 12 }}>State:</Text>
                                                                </Grid.Col>
                                                                <Grid.Col span={4}>
                                                                    <PLDataGrid
                                                                        value={M00Obj?._STATE}
                                                                        width={"500px"}
                                                                        valexpr={"PLSF01"}
                                                                        dispexpr={"PLSF02"}
                                                                        data={P_STATEDT}
                                                                        TmData={P_STATE}
                                                                        setEdit={(e) => {
                                                                            console.log('state obj',e)
                                                                            setM00Obj({ ...M00Obj, _STATE: e?.PLSF01,_STCD:e?.PLSF06})
                                                                        }}
                                                                    />
                                                                </Grid.Col>

                                                                <Grid.Col span={2}>
                                                                    <Text style={{ fontSize: 12, marginLeft: 50 }}>Language:</Text>
                                                                </Grid.Col>
                                                                <Grid.Col span={2}>
                                                                    <PLComboBox
                                                                        disabled={action == "E"}
                                                                        value={M00Obj?.FIELD81}
                                                                        data={CBOLAN}
                                                                        dispexpr={"DisplayMember"}
                                                                        valexpr={"ValueMember"}
                                                                        setEdit={(e) => {
                                                                            // console.log(e)
                                                                            setM00Obj({ ...M00Obj, FIELD81: e })
                                                                        }}
                                                                    />
                                                                </Grid.Col>
                                                            </Grid.Col>

                                                            {/* </Grid.Col> */}
                                                        </Grid>

                                                        <Grid gutter={4}>
                                                            <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                                                                <Grid.Col span={8}>
                                                                    <Divider my="xs" color='darkGray' label={<Text style={{ fontSize: 15, color: 'black' }}>Company Details</Text>} />
                                                                </Grid.Col>
                                                                <Grid.Col span={4}>
                                                                    <Divider my="xs" color='darkGray' label={<Text style={{ fontSize: 15, color: 'black' }}>Financial Year</Text>} />
                                                                </Grid.Col>
                                                            </Grid.Col>
                                                        </Grid>

                                                        <Grid gutter={4}>
                                                            <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                                                                <Grid.Col span={2}>
                                                                    <Text style={{ fontSize: 12 }}>Company No:</Text>
                                                                </Grid.Col>
                                                                <Grid.Col span={6}>
                                                                    <PLNumberBox
                                                                        disabled={action == "E"}
                                                                        value={M00Obj?.FIELD01}
                                                                        setEdit={(e) => {
                                                                            // console.log(e)
                                                                            setM00Obj({ ...M00Obj, FIELD01: e })
                                                                        }}
                                                                    />
                                                                </Grid.Col>
                                                                <Divider orientation="vertical" color='darkGray' />

                                                                <Grid.Col span={1}>
                                                                    <Text style={{ fontSize: 12, marginLeft: 20 }}>From:</Text>
                                                                </Grid.Col>
                                                                <Grid.Col span={2}>
                                                                    {/* From Date Pending */}
                                                                    <PLDateBox
                                                                        // defaultval={M00Obj?.DSDATE?.split("'").join('')}
                                                                        defaultval={M00Obj?.DSDATE}
                                                                        dispformat="DD/MM/YYYY"
                                                                        setEdit={(e) => {
                                                                            // console.log(e)
                                                                            let year = e.toLocaleString("en-IN", {
                                                                                year: "numeric",
                                                                            });
                                                                            let month = e.toLocaleString("en-IN", {
                                                                                month: "2-digit",
                                                                            });
                                                                            let day = e.toLocaleString("en-IN", {
                                                                                day: "2-digit",
                                                                            });

                                                                            let fromDate = year + month + day;
                                                                            if (fromDate?.length == 8) {
                                                                                setM00Obj({ ...M00Obj, DSDATE: fromDate })
                                                                            }
                                                                        }}
                                                                    />
                                                                </Grid.Col>

                                                            </Grid.Col>
                                                        </Grid>

                                                        <Grid gutter={4}>
                                                            <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>

                                                                <Grid.Col span={2}>
                                                                    <Text style={{ fontSize: 12 }}>Name:</Text>
                                                                </Grid.Col>
                                                                <Grid.Col span={6}>
                                                                    <PLTextBox
                                                                        value={M00Obj?.FIELD02}
                                                                        setEdit={(e) => {
                                                                            // console.log(e)
                                                                            setM00Obj({ ...M00Obj, FIELD02: e.target.value })
                                                                        }}
                                                                    />
                                                                </Grid.Col>
                                                                <Divider orientation="vertical" color='darkGray' />

                                                                <Grid.Col span={1}>
                                                                    <Text style={{ fontSize: 12, marginLeft: 20 }}>To:</Text>
                                                                </Grid.Col>
                                                                <Grid.Col span={2}>
                                                                    {/* To Date Pending */}
                                                                    <PLDateBox
                                                                        // defaultval={M00Obj?.DEDATE?.split("'").join('')}
                                                                        defaultval={M00Obj?.DEDATE}
                                                                        dispformat="DD/MM/YYYY"
                                                                        setEdit={(e) => {
                                                                            // console.log(e)
                                                                            let year = e.toLocaleString("en-IN", {
                                                                                year: "numeric",
                                                                            });
                                                                            let month = e.toLocaleString("en-IN", {
                                                                                month: "2-digit",
                                                                            });
                                                                            let day = e.toLocaleString("en-IN", {
                                                                                day: "2-digit",
                                                                            });

                                                                            let toDate = year + month + day;
                                                                            //   console.log("toDate",`'${toDate}'`);
                                                                            if (toDate?.length == 8) {
                                                                                setM00Obj({ ...M00Obj, DEDATE: toDate })
                                                                            }
                                                                        }}
                                                                    />
                                                                </Grid.Col>
                                                            </Grid.Col>
                                                        </Grid>

                                                        <Grid gutter={4}>
                                                            <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>

                                                                <Grid.Col span={2}>
                                                                    <Text style={{ fontSize: 12 }}>Short Name:</Text>
                                                                </Grid.Col>
                                                                <Grid.Col span={2}>
                                                                    <PLTextBox
                                                                        value={M00Obj?.FIELD10}
                                                                        setEdit={(e) => {
                                                                            // console.log(e)
                                                                            setM00Obj({ ...M00Obj, FIELD10: e.target.value })
                                                                        }}
                                                                    />
                                                                </Grid.Col>
                                                                <Grid.Col span={2}>
                                                                    <Text style={{ fontSize: 12 }}>Category:</Text>
                                                                </Grid.Col>
                                                                <Grid.Col span={2}>
                                                                    {
                                                                        action == "E" ? <PLTextBox disabled={true} placeholder={M00Obj?.FIELD25} /> :
                                                                            <PLDataGrid
                                                                                value={M00Obj?.FIELD25}
                                                                                width={"500px"}
                                                                                // position={"top"}
                                                                                valexpr={"CATF01"}
                                                                                dispexpr={"CATF03"}
                                                                                data={P_PLCATDT}
                                                                                TmData={P_PLCAT}
                                                                                setEdit={(e) => {
                                                                                    console.log("==>", e)
                                                                                    setM00Obj({ ...M00Obj, FIELD25: e?.CATF01 })
                                                                                }}
                                                                            />
                                                                    }
                                                                </Grid.Col>
                                                                <Divider orientation="vertical" color='darkGray' />
                                                            </Grid.Col>
                                                        </Grid>

                                                        <Grid gutter={4}>
                                                            <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                                                                <Grid.Col span={2}>
                                                                    <Text style={{ fontSize: 12 }}>Group:</Text>
                                                                </Grid.Col>
                                                                <Grid.Col span={6}>
                                                                    {
                                                                        P_GRPDT && Object.keys(P_GRPDT).length > 0 ?
                                                                            <>
                                                                                {
                                                                                    GroupName?.ComboFlag &&
                                                                                    <PLComboBox
                                                                                        data={P_GRPDT}
                                                                                        dispexpr={"Grp"}
                                                                                        valexpr={"Grp"}
                                                                                        value={M00Obj?.FIELD11}
                                                                                        setEdit={(e) => {
                                                                                            console.log("group dropdown", e)
                                                                                            Promise.resolve(setM00Obj({ ...M00Obj, FIELD11: e })).then(() => setGroupName({ ...GroupName, ComboVal: e, ComboFlag: false, TextFlag: true }))

                                                                                        }}
                                                                                    />
                                                                                }

                                                                                {
                                                                                    GroupName.TextFlag &&
                                                                                    <PLTextBox
                                                                                        setText={() => {
                                                                                            if (GroupName?.ComboVal?.length <= 0) {
                                                                                                setGroupName({ ...GroupName, ComboFlag: true, TextFlag: false })
                                                                                            }
                                                                                        }}
                                                                                        value={M00Obj?.FIELD11}
                                                                                        setEdit={(e) => {
                                                                                            console.log("group dropdown", e)
                                                                                            Promise.resolve(setM00Obj({ ...M00Obj, FIELD11: e?.target?.value })).then(() => setGroupName({ ...GroupName, ComboVal: e?.target?.value }))
                                                                                        }}
                                                                                    />
                                                                                }
                                                                            </> : <>
                                                                                <PLTextBox
                                                                                    value={M00Obj?.FIELD11}
                                                                                    setEdit={(e) => {
                                                                                        console.log("group dropdown", e)
                                                                                        setM00Obj({ ...M00Obj, FIELD11: e?.target?.value })
                                                                                    }}
                                                                                />

                                                                            </>
                                                                    }



                                                                </Grid.Col>
                                                                <Divider orientation="vertical" color='darkGray' />
                                                            </Grid.Col>
                                                        </Grid>

                                                        {/* <Grid gutter={4}>
                                                    <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                                                        <Grid.Col span={2}>
                                                            <Text style={{ fontSize: 12 }}>Group2:</Text>
                                                        </Grid.Col>
                                                        <Grid.Col span={6}>
                                                            <PLTextBox
                                                                // data={P_GRPDT}
                                                                // dispexpr={"Grp"}
                                                                // valexpr={"Grp"}
                                                                value={M00Obj?.FIELD11}
                                                                setEdit={(e) => {
                                                                    console.log("group dropdown", e)
                                                                    setM00Obj({ ...M00Obj, FIELD11: e?.target?.value })
                                                                }}
                                                            />
                                                        </Grid.Col>
                                                        <Divider orientation="vertical" color='darkGray' />
                                                    </Grid.Col>
                                                </Grid> */}

                                                        <Grid gutter={4}>
                                                            <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                                                                {/* <Grid.Col span={6}>
                                            <Divider my="xs" color='darkGray' label={<Text style={{ fontSize: 15, color: 'black' }}>Security</Text>} />
                                        </Grid.Col> */}

                                                            </Grid.Col>
                                                        </Grid>

                                                        <Grid gutter={4}>
                                                            <Grid.Col span={12}>
                                                                <Divider my="xs" color='darkGray' label={<Text style={{ fontSize: 15, color: 'black' }}>Company Logo</Text>} />
                                                            </Grid.Col>
                                                            <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>

                                                                <Grid.Col span={6}>
                                                                    {/* Select Logo Pending */}
                                                                    {/* <Grid.Col span={8}> */}
                                                                    <FileInput
                                                                        value={file}
                                                                        accept="image/png,image/jpeg"
                                                                        placeholder="Select Company Logo"
                                                                        onChange={(e) => {
                                                                            console.log("onChange=>", e)
                                                                            setFile(e)
                                                                            getBase64(e)
                                                                                .then(result => {
                                                                                    console.log("getBase64 result =>", result);
                                                                                    // setCMPLogo({ ...CMPLogo, base64: result });
                                                                                    let RemoveStr = "data:image/png;base64,"
                                                                                    // setBase64URL(result.substring(RemoveStr.length));
                                                                                    setM00Obj({ ...M00Obj, _CMPLOGO: result })
                                                                                })
                                                                                .catch(err => {
                                                                                    console.log("err=>", err)
                                                                                });
                                                                        }}
                                                                    />

                                                                    {/* </Grid.Col> */}

                                                                </Grid.Col>
                                                                <Grid.Col span={6} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                    {M00Obj?._CMPLOGO === "" ? <IconPhotoOff size={125} color='gray' /> :
                                                                        <Image
                                                                            radius="md"
                                                                            height={125}
                                                                            fit="contain"
                                                                            src={M00Obj?._CMPLOGO}
                                                                        />}
                                                                </Grid.Col>

                                                            </Grid.Col>

                                                        </Grid>
                                                        {/* <Grid.Col span={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <input type='file' onChange={handleChange} />
                                            </Grid.Col> */}

                                                        <Grid gutter={4}>
                                                            <Grid.Col span={12} style={{ display: 'flex' }}>

                                                                <Grid.Col span={6}>
                                                                    {
                                                                        typeVal == 'C' ?
                                                                            <Grid gutter={4}>
                                                                                <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                                                                                    <Grid.Col span={4}>
                                                                                        <Text style={{ fontSize: 12 }}>Password:</Text>
                                                                                    </Grid.Col>
                                                                                    <Grid.Col span={8}>
                                                                                        <PLTextBox />
                                                                                    </Grid.Col>
                                                                                    <Divider orientation="vertical" color='darkGray' />

                                                                                </Grid.Col>
                                                                                <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                                                                                    <Grid.Col span={4}>
                                                                                        <Text style={{ fontSize: 12 }}>ReType Password:</Text>
                                                                                    </Grid.Col>
                                                                                    <Grid.Col span={8}>
                                                                                        <PLTextBox />
                                                                                    </Grid.Col>
                                                                                    <Divider orientation="vertical" color='darkGray' />
                                                                                </Grid.Col>
                                                                            </Grid> :
                                                                            typeVal == 'U' ?
                                                                                <Grid gutter={4}>
                                                                                    <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                                                                                        <Grid.Col span={4}>
                                                                                            <Text style={{ fontSize: 12 }}>User:</Text>
                                                                                        </Grid.Col>
                                                                                        <Grid.Col span={8}>
                                                                                            <PLTextBox />
                                                                                        </Grid.Col>
                                                                                        <Divider orientation="vertical" color='darkGray' />
                                                                                    </Grid.Col>

                                                                                    <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                                                                                        <Grid.Col span={4}>
                                                                                            <Text style={{ fontSize: 12 }}>Password:</Text>
                                                                                        </Grid.Col>
                                                                                        <Grid.Col span={8}>
                                                                                            <PLTextBox />
                                                                                        </Grid.Col>
                                                                                        <Divider orientation="vertical" color='darkGray' />
                                                                                    </Grid.Col>

                                                                                    <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                                                                                        <Grid.Col span={4}>
                                                                                            <Text style={{ fontSize: 12 }}>ReType Password:</Text>
                                                                                        </Grid.Col>
                                                                                        <Grid.Col span={8}>
                                                                                            <PLTextBox />
                                                                                        </Grid.Col>
                                                                                        <Divider orientation="vertical" color='darkGray' />
                                                                                    </Grid.Col>
                                                                                </Grid>
                                                                                : ''
                                                                    }
                                                                </Grid.Col>



                                                                {/* {file && (
                                            <Text size="sm" align="center" mt="sm" ml='xl'>
                                                Picked file: {file.name}
                                            </Text>
                                        )} */}
                                                            </Grid.Col>

                                                        </Grid>

                                                        <Divider size="xs" my='lg' color='darkGray' />

                                                        <Grid gutter={4}>

                                                            <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>

                                                                <Grid.Col span={2}>
                                                                    <Text style={{ fontSize: 12 }}>Report Header:</Text>
                                                                </Grid.Col>
                                                                <Grid.Col span={10}>
                                                                    <PLTextBox
                                                                        value={M00Obj?.FIELD91}
                                                                        setEdit={(e) => {
                                                                            // console.log(e)
                                                                            setM00Obj({ ...M00Obj, FIELD91: e.target.value })
                                                                        }}
                                                                    />
                                                                </Grid.Col>

                                                            </Grid.Col>
                                                        </Grid>

                                                        <Grid gutter={4}>
                                                            <Grid.Col span={2}>
                                                            </Grid.Col>
                                                            <Grid.Col span={10}>
                                                                <PLTextBox
                                                                    value={M00Obj?.FIELD92}
                                                                    setEdit={(e) => {
                                                                        // console.log(e)
                                                                        setM00Obj({ ...M00Obj, FIELD92: e.target.value })
                                                                    }}
                                                                />
                                                            </Grid.Col>
                                                        </Grid>

                                                        {/* </Card> */}
                                                    </Paper>
                                                </>
                                            </Tabs.Panel>

                                            <Tabs.Panel value='address'>
                                                <>
                                                    <Paper shadow="md" radius="md" p="xs" withBorder>
                                                        <Divider my="xs" color='darkGray' label={<Text style={{ fontSize: 15, color: 'black' }}>Company Details</Text>} />
                                                        <Grid gutter={4}>
                                                            <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                                                                <Grid.Col span={2}>
                                                                    <Text style={{ fontSize: 12 }}>Address:</Text>
                                                                </Grid.Col>
                                                                <Grid.Col span={10}>
                                                                    <PLTextBox
                                                                        value={M00Obj?._ADDRESS_1}
                                                                        setEdit={(e) => {
                                                                            // console.log(e)
                                                                            setM00Obj({ ...M00Obj, _ADDRESS_1: e.target.value })
                                                                        }}
                                                                    />
                                                                </Grid.Col>
                                                            </Grid.Col>

                                                            <Grid.Col span={2}>
                                                            </Grid.Col>
                                                            <Grid.Col span={10}>
                                                                <PLTextBox
                                                                    value={M00Obj?._ADDRESS_2}
                                                                    setEdit={(e) => {
                                                                        // console.log(e)
                                                                        setM00Obj({ ...M00Obj, _ADDRESS_2: e.target.value })
                                                                    }}
                                                                />
                                                            </Grid.Col>

                                                            <Grid.Col span={2}>
                                                            </Grid.Col>
                                                            <Grid.Col span={10}>
                                                                <PLTextBox
                                                                    value={M00Obj?._ADDRESS_3}
                                                                    setEdit={(e) => {
                                                                        // console.log(e)
                                                                        setM00Obj({ ...M00Obj, _ADDRESS_3: e.target.value })
                                                                    }}
                                                                />
                                                            </Grid.Col>
                                                        </Grid>

                                                        <Grid gutter={4}>
                                                            <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                                                                <Grid.Col span={2}>
                                                                    <Text style={{ fontSize: 12 }}>City:</Text>
                                                                </Grid.Col>
                                                                <Grid.Col span={4}>
                                                                    <PLTextBox
                                                                        value={M00Obj?._CITY}
                                                                        setEdit={(e) => {
                                                                            // console.log(e)
                                                                            setM00Obj({ ...M00Obj, _CITY: e.target.value })
                                                                        }}
                                                                    />
                                                                </Grid.Col>

                                                                <Grid.Col span={2}>
                                                                    <Text style={{ fontSize: 12, marginLeft: 70 }}>Pincode:</Text>
                                                                </Grid.Col>
                                                                <Grid.Col span={3}>
                                                                    <PLTextBox
                                                                        entrytype={'NUMERIC'}
                                                                        value={M00Obj?._PINCODE}
                                                                        setEdit={(e) => {
                                                                            console.log("Pincode =>", [{
                                                                                "e": e,
                                                                                "value": e?.target?.value
                                                                            }])
                                                                            setM00Obj({ ...M00Obj, _PINCODE: e?.target?.value })
                                                                        }}
                                                                    />
                                                                </Grid.Col>
                                                            </Grid.Col>
                                                        </Grid>

                                                        <Grid gutter={4}>
                                                            <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                                                                <Grid.Col span={2}>
                                                                    <Text style={{ fontSize: 12 }}>Phone No:</Text>
                                                                </Grid.Col>
                                                                <Grid.Col span={3}>
                                                                    <PLTextBox
                                                                        nmaxlength={10}
                                                                        entrytype={'NUMERIC'}
                                                                        value={M00Obj?._PHONE1}
                                                                        setEdit={(e) => {
                                                                            // console.log(e)
                                                                            setM00Obj({ ...M00Obj, _PHONE1: e?.target?.value })
                                                                        }}
                                                                    />
                                                                </Grid.Col>
                                                                <Grid.Col span={3}>
                                                                    <PLTextBox
                                                                        nmaxlength={10}
                                                                        entrytype={'NUMERIC'}
                                                                        value={M00Obj?._PHONE2}
                                                                        setEdit={(e) => {
                                                                            // console.log(e)
                                                                            setM00Obj({ ...M00Obj, _PHONE2: e?.target?.value })
                                                                        }}
                                                                    />
                                                                </Grid.Col>
                                                                <Grid.Col span={3}>
                                                                    {/* _PHONE3 Not in Object Pending */}
                                                                    <PLTextBox
                                                                        nmaxlength={10}
                                                                        entrytype={'NUMERIC'}
                                                                        value={M00Obj?._PHONE3}
                                                                        setEdit={(e) => {
                                                                            // console.log(e)
                                                                            setM00Obj({ ...M00Obj, _PHONE3: e?.target?.value })
                                                                        }}
                                                                    />
                                                                </Grid.Col>
                                                            </Grid.Col>
                                                        </Grid>

                                                        <Grid gutter={4}>
                                                            <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                                                                <Grid.Col span={2}>
                                                                    <Text style={{ fontSize: 12 }}>Fax:</Text>
                                                                </Grid.Col>
                                                                <Grid.Col span={3}>
                                                                    <PLNumberBox
                                                                        value={M00Obj?._FAX1}
                                                                        setEdit={(e) => {
                                                                            // console.log(e)
                                                                            setM00Obj({ ...M00Obj, _FAX1: e })
                                                                        }}
                                                                    />
                                                                </Grid.Col>
                                                                <Grid.Col span={3}>
                                                                    <PLNumberBox
                                                                        value={M00Obj?._FAX2}
                                                                        setEdit={(e) => {
                                                                            // console.log(e)
                                                                            setM00Obj({ ...M00Obj, _FAX2: e })
                                                                        }}
                                                                    />
                                                                </Grid.Col>
                                                            </Grid.Col>
                                                        </Grid>

                                                        <Grid gutter={4}>
                                                            <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                                                                <Grid.Col span={2}>
                                                                    <Text style={{ fontSize: 12 }}>Email:</Text>
                                                                </Grid.Col>
                                                                <Grid.Col span={10}>
                                                                    <PLTextBox
                                                                        value={M00Obj?._EMAIL}
                                                                        setEdit={(e) => {
                                                                            // console.log(e)
                                                                            setM00Obj({ ...M00Obj, _EMAIL: e.target.value })
                                                                        }}
                                                                    />
                                                                </Grid.Col>
                                                            </Grid.Col>
                                                        </Grid>

                                                        <Grid gutter={4}>
                                                            <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                                                                <Grid.Col span={2}>
                                                                    <Text style={{ fontSize: 12 }}>Website:</Text>
                                                                </Grid.Col>
                                                                <Grid.Col span={10}>
                                                                    <PLTextBox
                                                                        value={M00Obj?._WEB}
                                                                        setEdit={(e) => {
                                                                            // console.log(e)
                                                                            setM00Obj({ ...M00Obj, _WEB: e.target.value })
                                                                        }}
                                                                    />
                                                                </Grid.Col>
                                                            </Grid.Col>
                                                        </Grid>

                                                        <Divider my='md' color='darkGray' />

                                                        <Grid gutter={4}>
                                                            <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                                                                <Grid.Col span={2}>
                                                                    <Text style={{ fontSize: 12 }}>Report Footer:</Text>
                                                                </Grid.Col>
                                                                <Grid.Col span={10}>
                                                                    <PLTextBox
                                                                        value={M00Obj?.FIELD93}
                                                                        setEdit={(e) => {
                                                                            // console.log(e)
                                                                            setM00Obj({ ...M00Obj, FIELD93: e.target.value })
                                                                        }}
                                                                    />
                                                                </Grid.Col>
                                                            </Grid.Col>
                                                        </Grid>

                                                        <Grid gutter={4}>
                                                            <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                                                                <Grid.Col span={2}>
                                                                </Grid.Col>
                                                                <Grid.Col span={10}>
                                                                    <PLTextBox
                                                                        value={M00Obj?.FIELD94}
                                                                        setEdit={(e) => {
                                                                            // console.log(e)
                                                                            setM00Obj({ ...M00Obj, FIELD94: e.target.value })
                                                                        }}
                                                                    />
                                                                </Grid.Col>
                                                            </Grid.Col>
                                                        </Grid>
                                                    </Paper>
                                                </>
                                            </Tabs.Panel>

                                            <Tabs.Panel value='other'>
                                                <Paper shadow="md" radius="md" p="xs" withBorder>
                                                    {/* <Card> */}
                                                    <Grid gutter={4}>

                                                        <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                                                            <Grid.Col span={2}>
                                                                <Text style={{ fontSize: 12 }}>Sync ID:</Text>
                                                            </Grid.Col>
                                                            <Grid.Col span={4}>
                                                                <PLTextBox
                                                                    value={M00Obj?.FIELD07}
                                                                    setEdit={(e) => {
                                                                        // console.log(e)
                                                                        setM00Obj({ ...M00Obj, FIELD07: e.target.value })
                                                                    }}
                                                                />
                                                            </Grid.Col>

                                                            <Grid.Col span={2}>
                                                                <Text style={{ fontSize: 12, marginLeft: 50 }}>Branch Code:</Text>
                                                            </Grid.Col>
                                                            <Grid.Col span={4}>
                                                                <PLTextBox
                                                                    nmaxlength={6}
                                                                    value={M00Obj?.FIELD08}
                                                                    setEdit={(e) => {
                                                                        // console.log(e)
                                                                        setM00Obj({ ...M00Obj, FIELD08: e?.target?.value })
                                                                    }}
                                                                />
                                                            </Grid.Col>
                                                        </Grid.Col>

                                                        <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                                                            <Grid.Col span={2}>
                                                                <Text style={{ fontSize: 12 }}>GSTIN No:</Text>
                                                            </Grid.Col>
                                                            <Grid.Col span={6}>
                                                                <PLTextBox
                                                                    value={M00Obj?._16}
                                                                    setEdit={(e) => {
                                                                        // console.log(e)
                                                                        setM00Obj({ ...M00Obj, _16: e?.target?.value })
                                                                    }}
                                                                />
                                                            </Grid.Col>
                                                        </Grid.Col>

                                                        <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                                                            <Grid.Col span={2}>
                                                                <Text style={{ fontSize: 12 }}>TIN NO:</Text>
                                                            </Grid.Col>
                                                            <Grid.Col span={6}>
                                                                <PLTextBox
                                                                    value={M00Obj?._02}
                                                                    setEdit={(e) => {
                                                                        // console.log(e)
                                                                        setM00Obj({ ...M00Obj, _02: e?.target?.value })
                                                                    }}
                                                                />
                                                            </Grid.Col>
                                                        </Grid.Col>

                                                        <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                                                            <Grid.Col span={2}>
                                                                <Text style={{ fontSize: 12 }}>CST NO:</Text>
                                                            </Grid.Col>
                                                            <Grid.Col span={6}>
                                                                <PLTextBox
                                                                    value={M00Obj?._03}
                                                                    setEdit={(e) => {
                                                                        // console.log(e)
                                                                        setM00Obj({ ...M00Obj, _03: e?.target?.value })
                                                                    }}
                                                                />
                                                            </Grid.Col>
                                                        </Grid.Col>

                                                        <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                                                            <Grid.Col span={2}>
                                                                <Text style={{ fontSize: 12 }}>PAN NO:</Text>
                                                            </Grid.Col>
                                                            <Grid.Col span={6}>
                                                                <PLTextBox
                                                                    value={M00Obj?._01}
                                                                    setEdit={(e) => {
                                                                        // console.log(e)
                                                                        setM00Obj({ ...M00Obj, _01: e?.target?.value })
                                                                    }}
                                                                />
                                                            </Grid.Col>
                                                        </Grid.Col>

                                                        <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                                                            <Grid.Col span={2}>
                                                                <Text style={{ fontSize: 12 }}>TAN NO:</Text>
                                                            </Grid.Col>
                                                            <Grid.Col span={6}>
                                                                <PLTextBox
                                                                    value={M00Obj?._06}
                                                                    setEdit={(e) => {
                                                                        // console.log(e)
                                                                        setM00Obj({ ...M00Obj, _06: e?.target?.value })
                                                                    }}
                                                                />
                                                            </Grid.Col>
                                                        </Grid.Col>

                                                        <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                                                            <Grid.Col span={2}>
                                                                <Text style={{ fontSize: 12 }}>ECC NO:</Text>
                                                            </Grid.Col>
                                                            <Grid.Col span={6}>
                                                                <PLTextBox
                                                                    value={M00Obj?._05}
                                                                    setEdit={(e) => {
                                                                        // console.log(e)
                                                                        setM00Obj({ ...M00Obj, _05: e?.target?.value })
                                                                    }}
                                                                />
                                                            </Grid.Col>
                                                        </Grid.Col>

                                                        <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                                                            <Grid.Col span={2}>
                                                                <Text style={{ fontSize: 12 }}>Service Tax No:</Text>
                                                            </Grid.Col>
                                                            <Grid.Col span={6}>
                                                                <PLTextBox
                                                                    value={M00Obj?._07}
                                                                    setEdit={(e) => {
                                                                        // console.log(e)
                                                                        setM00Obj({ ...M00Obj, _07: e?.target?.value })
                                                                    }}
                                                                />
                                                            </Grid.Col>
                                                        </Grid.Col>

                                                        <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                                                            <Grid.Col span={2}>
                                                                <Text style={{ fontSize: 12 }}>Juridiction City:</Text>
                                                            </Grid.Col>
                                                            <Grid.Col span={6}>
                                                                <PLTextBox
                                                                    value={M00Obj?._08}
                                                                    setEdit={(e) => {
                                                                        // console.log(e)
                                                                        setM00Obj({ ...M00Obj, _08: e.target.value })
                                                                    }}
                                                                />
                                                            </Grid.Col>
                                                        </Grid.Col>

                                                        <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                                                            <Grid.Col span={2}>
                                                                <Text style={{ fontSize: 12 }}>Bank Name:</Text>
                                                            </Grid.Col>
                                                            <Grid.Col span={6}>
                                                                <PLTextBox
                                                                    value={M00Obj?._09}
                                                                    setEdit={(e) => {
                                                                        // console.log(e)
                                                                        setM00Obj({ ...M00Obj, _09: e.target.value })
                                                                    }}
                                                                />
                                                            </Grid.Col>
                                                        </Grid.Col>

                                                        <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                                                            <Grid.Col span={2}>
                                                                <Text style={{ fontSize: 12 }}>Branch Name:</Text>
                                                            </Grid.Col>
                                                            <Grid.Col span={6}>
                                                                <PLTextBox
                                                                    value={M00Obj?._10}
                                                                    setEdit={(e) => {
                                                                        // console.log(e)
                                                                        setM00Obj({ ...M00Obj, _10: e.target.value })
                                                                    }}
                                                                />
                                                            </Grid.Col>
                                                        </Grid.Col>

                                                        <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                                                            <Grid.Col span={2}>
                                                                <Text style={{ fontSize: 12 }}>Address:</Text>
                                                            </Grid.Col>
                                                            <Grid.Col span={6}>
                                                                <PLTextBox
                                                                    value={M00Obj?._11}
                                                                    setEdit={(e) => {
                                                                        // console.log(e)
                                                                        setM00Obj({ ...M00Obj, _11: e.target.value })
                                                                    }}
                                                                />
                                                            </Grid.Col>
                                                        </Grid.Col>

                                                        <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                                                            <Grid.Col span={2}>
                                                                <Text style={{ fontSize: 12 }}>Account No:</Text>
                                                            </Grid.Col>
                                                            <Grid.Col span={6}>
                                                                <PLTextBox
                                                                    value={M00Obj?._12}
                                                                    setEdit={(e) => {
                                                                        // console.log(e)
                                                                        setM00Obj({ ...M00Obj, _12: e?.target?.value })
                                                                    }}
                                                                />
                                                            </Grid.Col>
                                                        </Grid.Col>

                                                        <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                                                            <Grid.Col span={2}>
                                                                <Text style={{ fontSize: 12 }}>IFSC code:</Text>
                                                            </Grid.Col>
                                                            <Grid.Col span={6}>
                                                                <PLTextBox
                                                                    value={M00Obj?._13}
                                                                    setEdit={(e) => {
                                                                        // console.log(e)
                                                                        setM00Obj({ ...M00Obj, _13: e?.target?.value })
                                                                    }}
                                                                />
                                                            </Grid.Col>
                                                        </Grid.Col>

                                                        <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                                                            <Grid.Col span={2}>
                                                                <Text style={{ fontSize: 12 }}>IBAN No:</Text>
                                                            </Grid.Col>
                                                            <Grid.Col span={6}>
                                                                <PLTextBox
                                                                    value={M00Obj?._14}
                                                                    setEdit={(e) => {
                                                                        // console.log(e)
                                                                        setM00Obj({ ...M00Obj, _14: e?.target?.value })
                                                                    }}
                                                                />
                                                            </Grid.Col>
                                                        </Grid.Col>

                                                        <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                                                            <Grid.Col span={2}>
                                                                <Text style={{ fontSize: 12 }}>SWIFT Code:</Text>
                                                            </Grid.Col>
                                                            <Grid.Col span={6}>
                                                                <PLTextBox
                                                                    value={M00Obj?._15}
                                                                    setEdit={(e) => {
                                                                        // console.log(e)
                                                                        setM00Obj({ ...M00Obj, _15: e?.target?.value })
                                                                    }}
                                                                />
                                                            </Grid.Col>
                                                        </Grid.Col>

                                                        <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                                                            <Grid.Col span={2}>
                                                                <Text style={{ fontSize: 12 }}>Food Product LIC NO:</Text>
                                                            </Grid.Col>
                                                            <Grid.Col span={6}>
                                                                <PLTextBox
                                                                    value={M00Obj?._17}
                                                                    setEdit={(e) => {
                                                                        // console.log(e)
                                                                        setM00Obj({ ...M00Obj, _17: e?.target?.value })
                                                                    }}
                                                                />
                                                            </Grid.Col>
                                                        </Grid.Col>

                                                    </Grid>
                                                    {/* </Card> */}
                                                </Paper>
                                            </Tabs.Panel>
                                        </Tabs>

                                        <Group style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
                                            <Button
                                                disabled={tabName === 'company' ? true : false}
                                                onClick={
                                                    () => tabName === 'company' ? TabFocusOne() :
                                                        tabName === 'address' ? TabFocusOne() :
                                                            tabName === 'other' ? TabFocusTwo() : ''} >Previous</Button>
                                            <Button
                                                disabled={tabName === 'other' ? true : false}
                                                onClick={
                                                    () => tabName === 'company' ? TabFocusTwo() : TabFocusThree()
                                                }>Next</Button>
                                            <Button onClick={() => CompanySubmit()} >Finish</Button>
                                        </Group>
                                    </div>
                            }
                        </>
                    }
                </>
        }
    </>)
}

export default AddNewCompanyForm