import React from "react";
import LocalizedStrings from "react-localization";
// import { useSelector } from "react-redux";
import Store from "./store";

// export  function rc(capid,strings){

//     strings.setLanguage(localStorage.getItem("userlang")?localStorage.getItem("userlang"):'en')
//   var cap = strings[capid];
//   return  cap ? cap : capid;
// }; 
// export function GetLanguageList(strings){

//     var langs=strings.getAvailableLanguages();
//     return langs;
// }
// export function GetLanguage(strings){

//     var langs=strings.getLanguage();
//     return langs;
// }
var strings;
var userlang;
class Localize extends React.PureComponent {
    constructor() {
        super();
        this.rc = this.rc.bind(this);
        this.GetLanguageList = this.GetLanguageList.bind(this);
        this.GetLanguage = this.GetLanguage.bind(this);

        if (!userlang) {
            userlang = localStorage.getItem("userlang") ? localStorage.getItem("userlang") : 'en'
        }
        this.userlang = userlang
        this.strings = strings;
        // console.log("ClassRC");

    }
    componentDidMount() {
        console.log("ccClassRC");
    }


    rc = (capid) => {
        if (!this.strings) {
            if(Store.getState()?.Language?.LanguageData){
                this.strings = new LocalizedStrings(Store.getState().Language.LanguageData);
            }
            
        }
        this.strings.setLanguage(this.userlang);
        var cap = this.strings[capid];
        return cap ? cap : capid;
    }
    GetLanguageList = () => {
        // console.log("langs",Store.getState().Language.LanguageData);
        if(Store.getState()?.Language?.LanguageData && Store.getState()?.Language?.LanguageData.length != 0){
            var langs = new LocalizedStrings(Store.getState().Language.LanguageData);
            
            if(langs && langs.getAvailableLanguages){
                langs = langs.getAvailableLanguages();
            }
            
            return langs;
        }
        else{
            return
        }
    }
    GetLanguage = () => {
            var langs = new LocalizedStrings(Store.getState().Language.LanguageData)?.getLanguage();
        
        return langs;
    }
}

export default Localize;