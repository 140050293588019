import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GetApplicableTaxRate } from '../../utils/slices/VoucherSetupPopUpSlice'
import PLComboBox from '../../PlusComponent/PLComboBox'
import GlobalClass from '../../utils/GlobalClass'

const ApplicableTaxRate = (props) => {
    const { value, setEditSend } = props
    const isLoading = useSelector((state) => state.VoucherSetupPopUp?.isLoading)
    const ApplicableTaxRateData = useSelector((state) => state.VoucherSetupPopUp?.ApplicableTaxRate)
    console.log('ApplicableTaxRateData', ApplicableTaxRateData)
    const dispatch = useDispatch()
    const [selectedItem, setSelectedItem] = useState([])

    useEffect(() => {
        dispatch(GetApplicableTaxRate())
    }, [])

    useEffect(() => {
        if (selectedItem && selectedItem.length > 0) {
            setEditSend(selectedItem.toString())
        }
    }, [selectedItem])

    const seperate = (a, b) => {
        let arr;
        if (value !== "") {
            arr = a.split(',')
            return arr
        }
    }

    return (
        <div style={{ width: '100%' }}>
            {
                isLoading ? GlobalClass.RenderLoader('dots') :
                    ApplicableTaxRateData &&
                    <PLComboBox
                    autoFous={true}
                initiallyOpened={true}
                        dropdownPosition='top'
                        setComboBox={() => props?.closeCombo(true)}
                        multiple={true}
                        data={ApplicableTaxRateData}
                        value={seperate(value)}
                        dispexpr="cDesc"
                        valexpr="cID"
                        setEdit={(e) => {
                            // console.log('selected val', e)
                            let val = [];
                            if (e?.length > 0) {
                                e?.map((m) => {
                                    val.push(m)
                                    // console.log('val', val)
                                })
                                return setSelectedItem(val)
                            } else {
                                setEditSend("")
                            }
                        }}
                    />
            }
        </div>
    )
}

export default ApplicableTaxRate