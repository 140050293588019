import { useToggle, upperFirst } from '@mantine/hooks';
import { useForm } from '@mantine/form';
import {
  TextInput,
  PasswordInput,
  Text,
  Paper,
  Group,
  PaperProps,
  Button,
  Divider,
  Checkbox,
  Anchor,
  Stack,
  Image,
  Grid,
  Alert,
} from '@mantine/core';
import { Navigate, redirect, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { VerifyUser, checkUser } from '../utils/Auth/LoginSlices';
import logo from '../logo1.svg'
import { IconAlertCircle } from '@tabler/icons-react';
export function Login(props) {
  const history = useNavigate();
  const [type, toggle] = useToggle(['login', 'register']);
  const [loading,toggleLoading] = useToggle([true,false]);
  const dispatch = useDispatch();
  const {Data,isLoading,successMsg,hasError,ErrorMsg} = useSelector((state) => state.LoginSlices);
  let corpid = localStorage.getItem("corpid");
  useEffect(() => {
    if (Data) {
       history("/")
    } 
  }, [Data]);
  useEffect(()=>{
    if (!Data) {
    dispatch(checkUser());
    }
},[]);
  const form = useForm({
    initialValues: {
      p1: corpid??'',
      p2: 'SUPERVISOR',
      p3: 'a@123',
      terms: true,
    }
  });
  // if (Data !== null) {
  //   return (
  //     <Navigate to={{ pathname: "/eplus" }} />
  //   );
  // }
  
  return (
    <div style={{height:"100vh", display:"flex", justifyContent:"center", alignItems:"center"}}>
      
    <Paper radius="md" p="xl" withBorder style={{
        width:400,
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        margin: "auto",
        maxWidth: "100%",
        maxHeight: "100%",
        overflow: "auto",
        boxShadow: "0 0 10px rgba(0,0,0,0.5)",
      }} {...props}>
        <Image 
      height={125} 
      fit="contain" 
      src={logo}
      alt="Aakash Infoway Pvt. Ltd." 
      withPlaceholder
      />
      {/* <Text size="lg" style={{color:"#6691e7"}} fw={500}>
        Welcome to ePlus
      </Text> */}
      
      {/* <Group grow mb="md" mt="md">
        <GoogleButton radius="xl">Google</GoogleButton>
        <TwitterButton radius="xl">Twitter</TwitterButton>
      </Group> */}

      <Divider label={upperFirst(type)+ " To Continue"} labelPosition="center" my="lg" />

      <form onSubmit={form.onSubmit(() => {
        toggleLoading()
        dispatch(VerifyUser({p1:form.values.p1,p2:form.values.p2,p3:form.values.p3}))
        // setTimeout(() => {
        //   // redirect("/eplus")
        //   // window.location.replace("/eplus");
        // }, 6000);
      })}>
        <Stack>
          
          
          <TextInput
            required
            label="Corporate ID"
            placeholder="e.g PL-P-9998"
            value={form.values.p1}
            onChange={(event) => form.setFieldValue('p1', event.currentTarget.value)}
            error={form.errors.p1 && 'Corporate ID is Required'}
            radius="md"
          />
          <TextInput
            required
            label="User Name"
            placeholder="e.g Username"
            value={form.values.p2}
            onChange={(event) => form.setFieldValue('p2', event.currentTarget.value)}
            error={form.errors.p2 && 'Username is Required'}
            radius="md"
          />
          <PasswordInput
            required
            label="Password"
            placeholder="Your password"
            value={form.values.p3}
            onChange={(event) => form.setFieldValue('p3', event.currentTarget.value)}
            error={form.errors.p3 && 'Password is Required'}
            radius="md"
          />

          
        </Stack>

        <Group mt="xl">
          {/* <Anchor component="button" type="button" c="dimmed" onClick={() => toggle()} size="xs">
            {type === 'register'
              ? 'Already have an account? Login'
              : "Don't have an account? Register"}
          </Anchor> */}
          <Button type="submit" size='md' radius="md" variant="gradient" gradient={{ from: '#0061bf', to: '#09007f',deg: 180 }} fullWidth loading={isLoading}>
            {upperFirst(type)}
          </Button>
        </Group>
        {hasError &&<Group mt={"md"} >
          <Alert title="Error!" style={{width:"100%"}} icon={<IconAlertCircle size="1rem" />} radius="md" variant="outline" color='red'>{ErrorMsg}</Alert>
        </Group>}
      </form>
      <Group style={{
        width:400,
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        margin: "auto",
        maxWidth: "100%",
        maxHeight: "100%",
        overflow: "auto",
        paddingTop:15,
      }}>
      <Image 
      width={65} 
      height={120} 
      fit="contain" 
      src={"https://www.aakashinfo.com/accounting-software/plusslide.webp"} 
      alt="Plus Accounting" 
      withPlaceholder
      
      />
      <Image 
      width={85} 
      height={120} 
      fit="contain" 
      src={"https://www.aakashinfo.com/accounting-software/kishanslide.webp"} 
      alt="Kishan Software" 
      withPlaceholder
      
      />
      <Image 
      width={85} 
      height={120} 
      fit="contain" 
      src={"https://www.aakashinfo.com/accounting-software/pharmaslide.webp"} 
      alt="Pharmacare" 
      withPlaceholder
      />
      <Image 
      width={65} 
      height={120} 
      fit="contain" 
      src={"https://www.aakashinfo.com/accounting-software/dalalslide.webp"} 
      alt="Dalal Software" 
      withPlaceholder
      />
    </Group>
    </Paper>
    
    
    </div>
  );
}