import React, { useState } from 'react';
import GSTR1 from '../../Utility/Files/GSTR1/GSTR1.xlsx';
import { saveAs } from 'file-saver';

function Generate(props) {
  const [loading, setLoading] = useState(false);

 
  const downloadFile = () => {
    setLoading(true);
    fetch(GSTR1)
      .then((response) => response.blob()) 
      .then((blob) => {
        saveAs(blob, 'GSTR1.xlsx');
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error downloading the file:', error);
        setLoading(false);
      });
  };

  return (
    <div>
      <button onClick={downloadFile} disabled={loading}>
        {loading ? 'Downloading...' : 'Download GSTR1 Excel'}
      </button>
    </div>
  );
}

export default Generate;

