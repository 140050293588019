const DynamicFunList = {
  M01: require("./M01"),
  MSAED:require("./MSAED"),
  M51AED:require("./M51AED"),
  M14AED:require("./M14AED"),
  PLED_MAIN:require("./PLED_MAIN"),
  M21AED:require("./M21AED"),
  PRODLABEL:require("./PRODLABEL"),
  // PLED_MAIN:require("./M21AED"),
  M24AED:require("./M24AED"),
  M71AED:require("./M71AED"),
  M02LAED:require("./M02LAED"),
  VouEntAct:require("./VouEntAct"),
  LEADAED:require("./LEADAED"),
  TM_ZINOUT:require("./TM_ZINOUT"),
  TM_PRINT:require("./TM_PRINT"),
  TM_EXPORT:require("./TM_EXPORT"),
  TM_FORMAT:require("./TM_FORMAT"),
  PrintingFunc:require("./PrintingFunc"),
  TM_PRINTVF:require("./TM_PRINTVF"),
  swe:require("./swe"),
  SMSAED:require("./SMSEMAILWHATSAPP"),
  EMLAED:require("./SMSEMAILWHATSAPP"),
  WAPAED:require("./SMSEMAILWHATSAPP"),
  TMSHOWORDER:require("./TMSHOWORDER"),
  TM_MSEL:require("./TM_MSEL"),
  CHLNOS:require("./CHLNOS"),
  CHLNOP:require("./CHLNOP"),
  BTOBOSTDR:require("./BTOBOSTDR"),
  M35AED:require("./M35AED"),
  M41UFAction:require("./M41UFAction"),
  M41UMAction:require("./M41UMAction"),
  T95AED:require("./T95AED"),
  WMKAED:require("./WMKAED"),
  CallInvType:require("./CallInvType"),
  LTRAED:require("./LTRAED"),
  M81AED:require("./M81AED"),
  M81GAED:require("./M81GAED"),
  G1RET:require("./GSTFormat"),
  GSTR2:require("./GSTFormat"),
  TMD_RowSel:require("./TMD_RowSel"), // Selection Button
  TM_SETUP : require("./TM_SETUP"),
  ASL:require("./ASLEDGER"),
  PRD:require("./ASLEDGER"),
  TDSCHALLAN:require("./TDSCHALLAN")
};
export default DynamicFunList;