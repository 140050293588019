import { ActionIcon, Box, Button, Center, Flex, Grid, Group, Tooltip } from '@mantine/core';
import { createColumnHelper } from '@tanstack/react-table';
import { MRT_TablePagination, MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import React, { useEffect, useState } from 'react'
import ButtonPanel from '../../../../PlusComponent/ButtonPanel';
import PLDataGrid from '../../../../PlusComponent/PLDataGrid';
import PLComboBox from '../../../../PlusComponent/PLComboBox';
import PLTextBox from '../../../../PlusComponent/PLTextBox';
import PLNumberBox from '../../../../PlusComponent/PLNumberBox';
import { IconEdit } from '@tabler/icons-react';

const MultiHeaderFooter = (props) => {
    const { p0, multiHeaderData, setMultiHeaderData, multiFooterData, setMultiFooterData, MLHeadDT, MLHeadENT, MLHeadhKey, MLFootDT, MLFootENT, MLFoothKey, rowSelection, grpData, setGrpData, colData, setColData, rowSelection1, row, DrawerObj, setDrawerObj } = props;
    const [tableData, setTableData] = useState([])
    const [tableObj, setTableObj] = useState({
        "RECTYPE": "REG",
        "REPORTID": "LEDMAIN",
        "COLRECID": "",
        "DISPCAP": "",
        "DISPCAPID": "",
        "USEDCAP": "",
        "USEDCAPID": "",
        "CTRLSOURCE": "",
        "SOURCEDEF": "",
        "DATATYPE": "",
        "UNBOUND": "",
        "COLWIDTH": 0,
        "COLDEC": " ",
        "DISPFORMAT": "",
        "LTOTAL": "",
        "LGROUP": "",
        "ORDEXPR": "",
        "GRPEXPR": "",
        "DISPCOND": "",
        "GRPSEQ": "",
        "RECSEQ": "",
        "GRPCAPID": "",
        "GRPCAP": "",
        "SPFLDFLAG": "   ",
        "COLPROP": "",
        "COLDATA": "",
        "TOTEXPR": "",
        "LSTRETCH": "",
        "LLEAF": "",
        "SELEXPSRC": "",
        "LORDER": "",
        "SUPFLAG": " ",
        "INCOND": "",
        "INCONDVFP": null,
        "ALIGNMENT": "",
        "FONTNAME": "",
        "FONTSIZE": "",
        "FONTSTYLE": "",
        "BACKCOLOR": "",
        "FORECOLOR": "",
        "COLSUPRESS": "",
        "CLICKDET": "",
        "LCOLUMNGRP": "",
        "DTCODEFLD": "",
        "MTBLNAME": "",
        "MCODEFLD": "",
        "MDISPFLD": "",
        "MFILTER": "",
        "S61FTY": "",
        "FCSOURCE": "",
        "FILTTYPE": " ",
        "FILTPROP": "",
        "FDEFVAL": "",
        "FCUSTEXPR": "",
        "BUNIID": 0,
        "S61CHARID": "",
        "FGSOURCE": "",
        "lOk": null,
        "ORFLDNM": "",
        "LACTIVE": "",
        "LSYSTEM": "",
        "GRPNO": null,
        "COLNO": null,
        "OLDCOLNO": 0,
        "LDEFFORMAT": "",
        "DATESEP": null,
        "DATEFRM": "",
        "FONTCASE": "",
        "LNZERO": null,
        "LCOMMA": "",
        "LCRDB": "",
        "LABS": "",
        "LCURR": "",
        "LFORECOLOR": "",
        "LBACKCOLOR": "",
        "EGKEYID": 0,
        "ROWNO": null,
        "COLLEVEL": null,
        "LEVELFLD": null,
        "COLTOTAL": null,
        "NONZERO": null,
        "COLGRPREQ": "",
        "LINPRINT": "",
        "LINGRID": "",
        "COLLOCK": "",
        "SEARCHCOL": "",
        "ALIGN_H": "",
        "ALIGN_D": "",
        "ALIGN_F": "",
        "COLDISPIN": null,
        "CACTION": "",
        "COLSPDATA": null,
        "IMGDISPAS": null,
        "IMGSTYLE": null,
        "IMGWIDTH": null,
        "IMGHEIGHT": null,
        "LBLANK": "",
        "COLTYPE": null,
        "COLSNO": null,
        "COLENO": null,
        "PRINTAT": null,
        "GRPRECID": null
    })
    const [columns, setColumns] = useState([])
    const [tableRowSelection, setTableRowSelection] = useState({})
    const [selectedRow, setSelectedRow] = useState(null)
    const [tableEditingRow, setTableEditingRow] = useState(null)
    const [tableToggle, setTableToggle] = useState(false)
    const [originalTableData, setOriginalTableData] = useState([])
    const columnHelper = createColumnHelper()
    useEffect(() => {
        if (p0 === "MH") {
            setTableData(multiHeaderData)
        } else if (p0 === "MI") {
            setTableData(multiFooterData)
        }
    }, [p0])

    const seperate = (e) => {
        var paraArr = e.split("~C~");
        if (paraArr.length == 1) {
            paraArr[1] = paraArr[0];
        }
        return paraArr;
    };

    const renderInputBox = (uID, v, c, cellid, index) => {
        let setInput;
        switch (c?.S53F14) {
            case "PLPOPUP":
                setInput = (
                    <PLDataGrid
                        id={`T02Ent_${index}`}
                        width={"500px"}
                        value={tableObj[c?.S53F04]}
                        p1={seperate(c.S53F15)[1]}
                        setEdit={(e) => {
                            setTableObj({ ...tableObj, [c?.S53F04]: e })
                        }}
                    />
                );
                break;
            case "COMBOBOX":

                setInput = (
                    <PLComboBox
                        cmbid={`T02Ent_${index}`}
                        // initiallyOpened={initiallyOpened}
                        value={tableObj[c?.S53F04]}
                        copno={seperate(c.S53F15)[1]}
                        dispexpr={"DisplayMember"}
                        valexpr={"ValueMember"}
                        setEdit={(e) => {
                            console.log("combobox", e)
                            setTableObj({ ...tableObj, [c?.S53F04]: e.value })
                        }}
                    />
                );
                break;
            case "TEXTBOX":
                setInput = (
                    <PLTextBox
                        id={`T02Ent_${index}`}
                        value={tableObj[c?.S53F04]}
                        setEdit={(e) => {
                            setTableObj({ ...tableObj, [c?.S53F04]: e.target.value })
                        }}
                    />
                );

                break;
            case "NUMBOX":
                setInput = (
                    <PLNumberBox
                        id={`T02Ent_${index}`}
                        value={tableObj[c?.S53F04]}
                        setEdit={(e) => {
                            setTableObj({ ...tableObj, [c?.S53F04]: e })
                        }}
                    />
                );
                break;
            // case "COLORPICKER":
            //   setInput = (
            //     <>
            //       <Button
            //         style={{ backgroundColor: color, color: "#fff" }}
            //         size="xs"
            //       />

            //       <Popover
            //         opened={opened}
            //         onClose={() => setOpened(false)}
            //         position="bottom"
            //         withArrow
            //       >
            //         <Popover.Target>
            //           <Button 
            //           onClick={() => setOpened((o) => !o)} 
            //           size="0px"
            //           >
            //             ...
            //           </Button>
            //         </Popover.Target>
            //         <Popover.Dropdown>
            //           <ColorPicker
            //             format="hex"
            //             value={color}
            //             // onChange={handleColorChange}
            //             withPicker={false}
            //             // fullWidth
            //             swatches={[
            //               "#2e2e2e",
            //               "#868e96",
            //               "#fa5252",
            //               "#e64980",
            //               "#be4bdb",
            //               "#7950f2",
            //               "#4c6ef5",
            //               "#228be6",
            //               "#15aabf",
            //               "#12b886",
            //               "#40c057",
            //               "#82c91e",
            //               "#fab005",
            //               "#fd7e14",
            //             ]}
            //           />
            //         </Popover.Dropdown>
            //       </Popover>
            //     </>
            //   )
            //   break;
            default:
                setInput = null;
                break;
        }
        return setInput;

    };

    useEffect(() => {
        if (p0 === "MH") {
            let cols = [];
            if (MLHeadENT) {
                MLHeadENT.filter((v, i) => v.S53F00 === "C")?.map((c, i) => {
                    cols.push(
                        columnHelper.accessor(c.S53F04, {
                            header: c.S53F03,
                            id: c?.S53UID,
                            size: c?.S53F07 == 0 ? 0 : c?.S53F07,
                            enableEditing: c?.S53F02 == "TMMHF05" ? false : true,
                            Edit: ({ cell, row }) => {
                                let val = cell.getValue();
                                return renderInputBox(c.S53UID, val, c, cell?.id, i);
                            },
                            Cell: ({ cell, row }) => {
                                let val = cell.getValue();
                                return c.S53F07 == 0 ? "" : val
                            },
                        })
                    )
                })
                setColumns(cols)
            }
        } else if (p0 === "MI") {
            let cols = [];
            if (MLFootENT) {
                MLFootENT.filter((v, i) => v.S53F00 === "C")?.map((c, i) => {
                    cols.push(
                        columnHelper.accessor(c.S53F04, {
                            header: c.S53F03,
                            id: c?.S53UID,
                            size: c?.S53F07 == 0 ? 0 : c?.S53F07,
                            enableEditing: c?.S53F02 == "TMMHF05" ? false : true,
                            Edit: ({ cell, row }) => {
                                let val = cell.getValue();
                                return renderInputBox(c.S53UID, val, c, cell?.id, i);
                            },
                            Cell: ({ cell, row }) => {
                                let val = cell.getValue();
                                return c.S53F07 == 0 ? "" : val
                            },
                        })
                    )
                })
                setColumns(cols)
            }
        }
    }, [p0, multiFooterData, multiHeaderData, tableData, tableObj])

    const findNextSequence = (existingSequences) => {
        const existingIndexes = tableData
            .filter(item => item.ROWNO !== null) // Filter out NULL values
            .map(item => item.ROWNO);

        // Find the smallest number missing in the sequence
        let nextSequence = 1;
        while (existingSequences.includes(nextSequence)) {
            nextSequence++;
        }
        console.log("nextSequence", nextSequence)
        return nextSequence;
    };

    // selection btn
    const SelectDeSelect = (row) => {
        if (Object.keys(row)?.length > 0) {
            const selectedRow = tableData[Object.keys(tableRowSelection)[0]];
            // console.log("selectedRow", selectedRow)
            // let updatedRows = [];
            if (row.ROWNO === null) {
                const updatedData = [...tableData];
                const nextSequence = findNextSequence(tableData
                    .filter(item => item.ROWNO !== null)
                    .map(item => item.ROWNO));

                updatedData[Object.keys(tableRowSelection)[0]] = { ...row, ROWNO: nextSequence, FONTSIZE: "D" };
                setTableData(updatedData);
                setTableRowSelection({ [Number(Object.keys(tableRowSelection)[0]) + 1]: true })
            } else {
                const updatedData = [...tableData];
                updatedData[Object.keys(tableRowSelection)[0]] = {
                    ...row,
                    FONTSIZE: "",
                    ROWNO: null,
                };
                setTableData(updatedData);
                setTableRowSelection({ [Number(Object.keys(tableRowSelection)[0]) + 1]: true })
            }
        }
    }

    // filter btn
    const FilterBtn = () => {
        if (!tableToggle) {
            setTableToggle(true)
            setOriginalTableData([...tableData])
            let filterData = tableData?.filter((f, i) => f.ROWNO !== null)
            setTableData(filterData)
        } else {
            setTableToggle(false)
            setTableData([...originalTableData])
        }
    }

    // alignment btn
    const AlignMentBtn = (row) => {
        if (row?.ROWNO !== null) {
            const updatedData = [...tableData]
            let rowIndex = Object.keys(tableRowSelection)[0]
            updatedData[rowIndex] = {
                ...row,
                ALIGNMENT: row?.ALIGNMENT == "" ? "DDD" :
                    row?.ALIGNMENT == "DDD" ? "000" :
                        row?.ALIGNMENT === "000" ? "111" :
                            row?.ALIGNMENT === "111" ? "222" :
                                row?.ALIGNMENT === "222" ? "DDD" : ""
            }
            setTableData(updatedData)
        }
    }

    // style btn
    const StyleBtn = (row) => {
        if (row?.ROWNO !== null) {
            const updatedData = [...tableData]
            let rowIndex = Object.keys(tableRowSelection)[0]
            updatedData[rowIndex] = {
                ...row,
                FONTSTYLE: row?.FONTSTYLE == "" ? "N" :
                    row?.FONTSTYLE == "N" ? "B" :
                        row?.FONTSTYLE == "B" ? "I" :
                            row?.FONTSTYLE == "I" ? "U" :
                                row?.FONTSTYLE == "U" ? "BI" :
                                    row?.FONTSTYLE == "BI" ? "IU" :
                                        row?.FONTSTYLE == "IU" ? "BIU" :
                                            row?.FONTSTYLE == "BIU" ? "N" : ""
            }
            setTableData(updatedData)
        }
    }

    // font size
    const FontSizeBtn = (row) => {
        if (row?.ROWNO !== null) {
            const updatedData = [...tableData]
            let rowIndex = Object.keys(tableRowSelection)[0]
            let fontSize = row?.FONTSIZE ?? 6
            fontSize++;
            console.log("fontSize", fontSize)
            if (fontSize > 20) {
                fontSize = 20
            }
            updatedData[rowIndex] = {
                ...row,
                FONTSIZE: fontSize
            }
            setTableData(updatedData)
        }
    }

    // Edit btn
    const handleEditModeOpen = (row) => {
        if (row?.ROWNO !== null) {
            console.log("handleEditModeOpen", row)
            table?.setEditingRow(table.getSelectedRowModel().flatRows[0])
            setTableEditingRow(table.getSelectedRowModel().flatRows[0])
            setTableObj(row)
        }
    }

    const handleEditSave = () => {
        let editTableData = [...tableData]
        editTableData[tableEditingRow.index] = tableObj
        setTableData(editTableData)
        table.setEditingRow(null)
    }

    const handleEditCancel = () => {
        setTableObj({
            "RECTYPE": "REG",
            "REPORTID": "LEDMAIN",
            "COLRECID": "",
            "DISPCAP": "",
            "DISPCAPID": "",
            "USEDCAP": "",
            "USEDCAPID": "",
            "CTRLSOURCE": "",
            "SOURCEDEF": "",
            "DATATYPE": "",
            "UNBOUND": "",
            "COLWIDTH": 0,
            "COLDEC": " ",
            "DISPFORMAT": "",
            "LTOTAL": "",
            "LGROUP": "",
            "ORDEXPR": "",
            "GRPEXPR": "",
            "DISPCOND": "",
            "GRPSEQ": "",
            "RECSEQ": "",
            "GRPCAPID": "",
            "GRPCAP": "",
            "SPFLDFLAG": "   ",
            "COLPROP": "",
            "COLDATA": "",
            "TOTEXPR": "",
            "LSTRETCH": "",
            "LLEAF": "",
            "SELEXPSRC": "",
            "LORDER": "",
            "SUPFLAG": " ",
            "INCOND": "",
            "INCONDVFP": null,
            "ALIGNMENT": "",
            "FONTNAME": "",
            "FONTSIZE": "",
            "FONTSTYLE": "",
            "BACKCOLOR": "",
            "FORECOLOR": "",
            "COLSUPRESS": "",
            "CLICKDET": "",
            "LCOLUMNGRP": "",
            "DTCODEFLD": "",
            "MTBLNAME": "",
            "MCODEFLD": "",
            "MDISPFLD": "",
            "MFILTER": "",
            "S61FTY": "",
            "FCSOURCE": "",
            "FILTTYPE": " ",
            "FILTPROP": "",
            "FDEFVAL": "",
            "FCUSTEXPR": "",
            "BUNIID": 0,
            "S61CHARID": "",
            "FGSOURCE": "",
            "lOk": null,
            "ORFLDNM": "",
            "LACTIVE": "",
            "LSYSTEM": "",
            "GRPNO": null,
            "COLNO": null,
            "OLDCOLNO": 0,
            "LDEFFORMAT": "",
            "DATESEP": null,
            "DATEFRM": "",
            "FONTCASE": "",
            "LNZERO": null,
            "LCOMMA": "",
            "LCRDB": "",
            "LABS": "",
            "LCURR": "",
            "LFORECOLOR": "",
            "LBACKCOLOR": "",
            "EGKEYID": 0,
            "ROWNO": null,
            "COLLEVEL": null,
            "LEVELFLD": null,
            "COLTOTAL": null,
            "NONZERO": null,
            "COLGRPREQ": "",
            "LINPRINT": "",
            "LINGRID": "",
            "COLLOCK": "",
            "SEARCHCOL": "",
            "ALIGN_H": "",
            "ALIGN_D": "",
            "ALIGN_F": "",
            "COLDISPIN": null,
            "CACTION": "",
            "COLSPDATA": null,
            "IMGDISPAS": null,
            "IMGSTYLE": null,
            "IMGWIDTH": null,
            "IMGHEIGHT": null,
            "LBLANK": "",
            "COLTYPE": null,
            "COLSNO": null,
            "COLENO": null,
            "PRINTAT": null,
            "GRPRECID": null
        })
        setTableEditingRow(null)
    }

    const handleSubmit = () => {
        if (p0 === "MH") {
            setMultiHeaderData(tableData)
            setDrawerObj({ ...DrawerObj, open: false })
        } else {
            setMultiFooterData(tableData)
            setDrawerObj({ ...DrawerObj, open: false })
        }
    }

    const table = useMantineReactTable({
        columns,
        data: tableData,
        mantineTableProps: { withColumnBorders: true },
        enableTopToolbar: false,
        enableColumnActions: false,
        enableSorting: false,
        enableEditing: true,
        editDisplayMode: "row",
        onEditingRowSave: handleEditSave,
        onEditingRowCancel:handleEditCancel,
        state: {
            density: "0px",
            rowSelection: tableRowSelection
        },
        initialState: {
            density: "0px"
        },
        mantineTableBodyRowProps: ({ row, staticRowIndex, cell }) => ({
            onClick: () => {
                // console.log(row?.original, "row?.original");
                setTableRowSelection((prev) => ({
                    [row.id]: !prev[row.id],
                }));
                setSelectedRow(row?.original)
            },
            selected: tableRowSelection[row.id],
            sx: { cursor: "pointer" },
            // height: 50,
        }),
        renderRowActions: ({ row, table }) => (
            <Flex gap="md">
                <Tooltip label="Edit">
                    <ActionIcon
                        onClick={() => {
                            table.setEditingRow(row);
                            setTableEditingRow(row);
                            setTableObj(row?.original)
                        }}
                    >
                        <IconEdit size={"20px"} />
                    </ActionIcon>
                </Tooltip>
            </Flex>
        ),
        renderBottomToolbar: ({ table }) => (
            <Box>
                <Grid gutter={4}>
                    <Grid.Col span={12}>
                        <Group
                            spacing={"xs"}
                            position="right"
                            display={"flex"}
                        >
                            <MRT_TablePagination position="bottom" table={table} />
                        </Group>
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <ButtonPanel
                            //   table={TableRef}
                            data={p0 === "MH" ? MLHeadhKey : MLFoothKey}
                            Skey={table?.getSelectedRowModel()?.flatRows}
                            // from={OBJ?.data?.id}
                            setFormatBtn={(e) => {
                                let OBJ = JSON.parse(e);
                                console.log("setFormatBtn_OBJ", OBJ)
                                switch (OBJ.p0) {
                                    case "S":
                                        SelectDeSelect(OBJ?.DataGridRow)
                                        break;
                                    case "F":
                                        FilterBtn()
                                        break;
                                    case "AL":
                                        AlignMentBtn(OBJ?.DataGridRow)
                                        break;
                                    case "FT":
                                        StyleBtn(OBJ?.DataGridRow)
                                        break;
                                    case "FS":
                                        FontSizeBtn(OBJ?.DataGridRow)
                                        break;
                                    case "E":
                                        handleEditModeOpen(OBJ.DataGridRow)
                                        break;
                                    default:
                                        break;
                                }
                            }}
                        />
                        <Center>
                            <Button
                                size="sm"
                                variant='subtle'
                                compact
                                onClick={handleSubmit}
                            >OK</Button>
                            <Button
                                size="sm"
                                variant='subtle'
                                compact
                                onClick={() => setDrawerObj({ ...DrawerObj, open: false })}
                            >Cancel</Button>
                        </Center>
                    </Grid.Col>
                </Grid>
            </Box>
        )
    })

    return (
        <>
            <Grid gutter={4}>
                <Grid.Col span={12}>
                    <MantineReactTable table={table} />
                </Grid.Col>
            </Grid>
        </>
    )
}

export default MultiHeaderFooter