import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import gensetting from '../../utils/gensetting';
import GlobalClass from '../../utils/GlobalClass';
import { Button, Progress, Text } from '@mantine/core';
import Store from '../../utils/store';
import { ModalDelete } from '../../utils/slices/ModalSlice';

const Reposting = (props) => {
    const GetGeneral = useSelector((state) => state.Language.General);
    console.log("GetGeneral", GetGeneral)
    const [isRepostingLoading, setIsRepostingLoading] = useState(false)
    const [progress, setProgress] = useState(0)

    useEffect(() => {
        if (isRepostingLoading) {
            let i = 0;
            const intv = setInterval(() => {
                i += 10;
                setProgress(i);

                if (i >= 100) {
                    clearInterval(intv);
                }
            }, 50);
        }
    }, [isRepostingLoading])

    useEffect(() => {
        // RepostingFun()
        setIsRepostingLoading(true)
        var data = {
            CorpID: gensetting.getcorpid(),
            cUser: gensetting.getcuser(),
            cPass: gensetting.getcpass(),
            CmpNo: gensetting.getcmpno(),
            YrNo:localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
        }
        let param = JSON.stringify({
            CorpID: gensetting.getcorpid(),
            cUser: gensetting.getcuser(),
            cPass: gensetting.getcpass(),
            CmpNo: gensetting.getcmpno()
        });
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        };
        fetch(
            GlobalClass.ApiUrl + GlobalClass.DoRepost + "?pa=" + param,
            requestOptions
        )
            .then((response) => response.json())
            .then((data) => {
                console.log("data", data)
                if (data.status == "SUCCESS") {
                    setIsRepostingLoading(false)
                    Store.dispatch(ModalDelete(props.index))
                    GlobalClass.Notify("success", "Success", "Successfull")
                } else {
                    setIsRepostingLoading(false);
                    GlobalClass.Notify("error", data?.status, data?.message)
                }
            })
            .catch((error) => {
                setIsRepostingLoading(false);
                GlobalClass.Notify("error", "Error", error.message);
            });
    }, [])
    return (

        <>
            <Text align='center' fw={700}>{`Year - ${GetGeneral?.oYear?.cYrStr}`}</Text>
            {
                isRepostingLoading ? <Progress value={progress} animate size={'md'} /> : ""
                // isRepostingLoading ? GlobalClass.RenderLoader("dots") : ""
            }
        </>
    )
}

export default Reposting