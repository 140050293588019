import { Button, Center, Grid, Text } from '@mantine/core';
import React, { useEffect, useState } from 'react'
import PLDateBox from '../../PlusComponent/PLDateBox';
import GlobalClass from '../../utils/GlobalClass';
import PLNumberBox from '../../PlusComponent/PLNumberBox';
import PLTextBox from '../../PlusComponent/PLTextBox';
import { DataSaveLoading } from '../../utils/slices/DataSaveLoadingSlice';
import { useDispatch, useSelector } from 'react-redux';
import gensetting from '../../utils/gensetting';
import { GetAccountList } from '../../utils/slices/AccountListSlice';
import { ModalDelete } from '../../utils/slices/ModalSlice';
import Store from '../../utils/store';

const TDSChallanEntryForm = (props) => {
 
  console.log("props here",props);

  const dispatch = useDispatch();
  const SaveLoading = useSelector(
    (state) => state?.DataSaveLoadingSlice?.SaveLoading
  );

  const [challanEntryObj,setChallanEntryObj]=useState({
     FIELD11:"",
     FIELD10:"",
     FIELD30:"",
     FIELD72:""
  });

console.log("props?.obj?.DataGridRow?.length ",typeof props?.obj?.id )
  useEffect(()=>{
    let row = typeof props?.obj?.id == "string" ? props?.obj?.DataGridRow : props?.obj?.DataGridRow[0];
    setChallanEntryObj({
      FIELD11:row?.FIELD11,
      FIELD10:row?.FIELD10,
      FIELD30:row?.FIELD30,
      FIELD72:row?.FIELD72
    })
  },[props?.obj?.DataGridRow])

  const handleOkButton = () =>{
    dispatch(DataSaveLoading({[props?.obj?.cSTID]:true}));
        if(challanEntryObj?.FIELD10==""){
          GlobalClass.Notify(
            "warning",
            "Validation error",
            "Please fill out all the required fields"
          )
          dispatch(DataSaveLoading({[props?.obj?.cSTID]:false}));
        }else {
        //   let a = Object.keys(props?.obj?.DataGridRow).map(key => {
        //     return props?.obj?.DataGridRow[key];
        // })
        // console.log("a here",a)
        console.log("props?.obj?.DataGridRow[0]",props?.obj?.DataGridRow)
        
          let arrayOfObjects = typeof props?.obj?.id == "object" ?  Object.keys(props?.obj?.DataGridRow).map(key => {
            return props?.obj?.DataGridRow[key];
        }) : [props?.obj?.DataGridRow]

        console.log("arrayOfObjects",arrayOfObjects)
          arrayOfObjects = arrayOfObjects?.map(obj => ({
            ...obj,
            FIELD10: challanEntryObj?.FIELD10,
            FIELD11: challanEntryObj?.FIELD11,
            FIELD30: challanEntryObj?.FIELD30,
            FIELD72: challanEntryObj?.FIELD72
          })) 
          console.log("arrayOfObjects",arrayOfObjects)
          GlobalClass.Notify(
            "info", "Editing",
            "Please wait while we process your data"
          );
          var data = {
            CorpID: gensetting.getcorpid(),
            cUser: gensetting.getcuser(),
            cPass: gensetting.getcpass(),
            CmpNo: gensetting.getcmpno(),
            cAction: "A",
            cSData: JSON.stringify({"DT": arrayOfObjects}),
            YrNo:localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
          };
  
          let param = JSON.stringify({
            CorpID: gensetting.getcorpid(),
            cUser: gensetting.getcuser(),
            cPass: gensetting.getcpass(),
            CmpNo: gensetting.getcmpno(),
            cAction: "A",
            YrNo:localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
          });
  
          const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
          };
          fetch(
            GlobalClass.ApiUrl + GlobalClass.PostChallanEntry + "?pa=" + param,
            requestOptions
          )
            .then((response) => response.json())
            .then((data) => {
              // console.log("api data", data);
              // console.log("api data status", data?.status);
              if (data.status === "SUCCESS") {
                // modals.closeAll();
                // props.ModalFunctionClose();
  
                // dispatch(ModalDelete(props.index))
  
                GlobalClass.Notify(
                  "success", "Edited","Edited successfully"
                );
                let PaginationObj =
                  Store.getState().DataSaveLoadingSlice.PaginationObj;
                Promise.resolve(dispatch(DataSaveLoading({[props?.obj?.cSTID]:false})))
                  // .then(() => dispatch(ModalDelete(props.index)))
                  .then(() => {
                    // if (props?.OBJ?.p0 == "A" && !props?.OBJ.page.startsWith("P_")) {
                    //   dispatch(GetAccountGroup());
                    // } else {
                      dispatch(ModalDelete(props.index));
                    // }
                  })
                  .then(() =>
                    dispatch(
                      GetAccountList({
                        id: "TDSCHALLAN",
                        name: "",
                        p0: "MT",
                        p1: "TDSCHALLAN",
                        p2: "",
                        p3: "",
                        type: "A",
                        pagination: PaginationObj["TDSCHALLAN"],
                      })
                    )
                  );
              } else {
                GlobalClass.Notify("error", data?.status, data?.message);
                dispatch(DataSaveLoading({[props?.obj?.cSTID]:false}));
              }
            })
            .catch((e) => {
              GlobalClass.Notify("error", "Error", `${e.message}`);
              dispatch(DataSaveLoading({[props?.obj?.cSTID]:false}));
            });
        }
  }

  const DateToString = (e) => {
    let year = e.toLocaleString("en-IN", { year: "numeric" });
    let month = e.toLocaleString("en-IN", {
      month: "2-digit",
    });
    let day = e.toLocaleString("en-IN", { day: "2-digit" });
    let startDate = year + month + day;
    return startDate;
  }

  return (
    SaveLoading[props?.obj?.cSTID] ? (
      GlobalClass.RenderLoader("dots")
    ) : (
    
      <div><Grid gutter={4}>
    <Grid.Col
      span={12}
      style={{ display: "flex", alignItems: "center", padding: 0 }}
    >
      <Grid.Col span={2}>
        <Text style={{ fontSize: 12 }}>Date</Text>
      </Grid.Col>
      <Grid.Col span={10}>
        
      {challanEntryObj?.FIELD11?.trim() == "" ? (
                        <PLDateBox
                          onKeyDown={(e) => {
                            // GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            if (DateToString(e)?.length == 8) {
                              setChallanEntryObj({
                                ...challanEntryObj,
                                FIELD11: DateToString(e),
                              });
                            }
                          }}
                        />
                      ) : (
                        <PLDateBox
                          onKeyDown={(e) => {
                            // GlobalClass.formNextInput(e, formRef);
                          }}
                          defaultval={challanEntryObj?.FIELD11}
                          setEdit={(e) => {
                            if (DateToString(e)?.length == 8) {
                              {
                                setChallanEntryObj({
                                  ...challanEntryObj,
                                  FIELD11: DateToString(e),
                                });
                              }
                            }
                          }}
                        />
                      )}
      </Grid.Col>
    </Grid.Col>

    <Grid.Col
      span={12}
      style={{ display: "flex", alignItems: "center", padding: 0 }}
    >
      <Grid.Col span={2}>
        <Text style={{ fontSize: 12 }}>Challan No.</Text>
      </Grid.Col>
      <Grid.Col span={10}>
        <PLTextBox
          value={challanEntryObj?.FIELD10}
          setEdit={(e)=>{
            setChallanEntryObj({...challanEntryObj,FIELD10:e?.target.value})
          }}
        />
      </Grid.Col>
    </Grid.Col>

    <Grid.Col
      span={12}
      style={{ display: "flex", alignItems: "center", padding: 0 }}
    >
      <Grid.Col span={2}>
        <Text style={{ fontSize: 12 }}>Bank</Text>
      </Grid.Col>
      <Grid.Col span={10}>
        <PLTextBox
          value={challanEntryObj?.FIELD30}
          setEdit={(e)=>{
            setChallanEntryObj({...challanEntryObj,FIELD30:e?.target.value})
          }}
        />
      </Grid.Col>
    </Grid.Col>
    <Grid.Col
      span={12}
      style={{ display: "flex", alignItems: "center", padding: 0 }}
    >
      <Grid.Col span={2}>
        <Text style={{ fontSize: 12 }}>Cheque No.</Text>
      </Grid.Col>
      <Grid.Col span={10}>
        <PLTextBox
          value={challanEntryObj?.FIELD72}
          setEdit={(e)=>{
            setChallanEntryObj({...challanEntryObj,FIELD72:e?.target.value})
          }}
        />
      </Grid.Col>
    </Grid.Col>

    <Grid.Col span={12}>
      <Center>
        <Button
        size="sm"
        mt={2}
          style={{ width: "100px", height:"30px"}}
          onKeyDown={(e) => {
            if (e.key == "Enter") {
              e.preventDefault();
              handleOkButton();
            }
          }}
          onClick={handleOkButton}
        >
          Ok
        </Button>
      </Center>
    </Grid.Col>
  </Grid>
</div>
    )
  )
}

export default TDSChallanEntryForm