import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GetReminder } from '../utils/TransactionSlices/Reminder'
import { createColumnHelper } from '@tanstack/react-table'
import { Grid, Modal, Text } from '@mantine/core'
import PLTextBox from '../PlusComponent/PLTextBox'
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table'

function ReminderPopUp(props) {

  // const {temp,setTemp} = props

  const dispatch = useDispatch()
  const columnHelper = createColumnHelper()

  let dra = {
    title: "",
    size: "xs",
    body: "",
    open: false,
    onClickCloseBtn: ""
  }

  const [GRDDT, setGRDDT] = useState();
  const [REMGRD, setREMGRD] = useState();
  const [search, setSearch] = useState();
  const [columns, setColumns] = useState([])
  const [grouping, setGrouping] = useState([]); // Vara Pooja
  const [DrawerObj, setDrawerObj] = useState(dra);
  const [rowSelection, setRowSelection] = useState({});

  const { ReminderData } = useSelector(store => store?.Reminder);
  const dWDate = useSelector((state) => state.Language.General?.oYear?.dWDate);
  const GetGeneral = useSelector((state) => state?.Language?.General)

  const currentDate = new Date();

  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  const day = currentDate.getDate().toString().padStart(2, '0');

  const formattedDate = `${day}/${month}/${year}`;

  useEffect(() => {
    const obj = {
      "name": "Reminder",
      "type": "A",
      "p0": "",
      // "cWhere": " F23.FIELD06 = 'S' "
    }
    if (!props?.temp) {
      dispatch(GetReminder(obj))
    }
  }, [])

  useEffect(() => {
    // console.log("ReminderData", ReminderData?.GRDDT);
    // console.log("General", GetGeneral?.oYear?.dWDate);

    if (ReminderData) {

      const obj = ReminderData?.GRDDT?.map((item) => item?.FIELD06 == "C" || 1 ? { ...item, FIELD06: true } : { ...item, FIELD06: false })
      const remDate = GetGeneral?.oYear?.dWDate

      const modifiedObj = obj?.map((row, index) => {

        row = { ...row, FIELD06: false }
        const FIELD03 = row?.FIELD03
        const FIELD01 = row?.FIELD01
        const FIELD02 = row?.FIELD02?.toString()

        const Between = (start, date, end) => {
          const startDate = new Date(start);
          const checkDate = new Date(date);
          const endDate = new Date(end);
          return checkDate >= startDate && checkDate <= endDate;
        }

        let formattedFIELD01 = FIELD01?.slice(0, 4) + '-' + FIELD01?.slice(4, 6) + '-' + FIELD01?.slice(6);

        const addDays = (days) => {
          const date = new Date(formattedFIELD01)
          date.setDate(date.getDate() + days);
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const day = String(date.getDate()).padStart(2, '0');
          return `${year}-${month}-${day}`;
        }

        if (FIELD01) {

          let startDate = addDays(-FIELD03)
          let normalizedRemDate = remDate.split('T')[0];

          if ((Between(startDate, normalizedRemDate, formattedFIELD01) || Between(formattedFIELD01, normalizedRemDate, startDate))) {
            row = { ...row, FIELD06: true }
          }
          else if (FIELD02 === "O" &&
            (Between(startDate, normalizedRemDate, formattedFIELD01) || Between(formattedFIELD01, normalizedRemDate, startDate))) {
            row = { ...row, FIELD06: true }
          }

          let formattedDateObj = new Date(formattedFIELD01);
          let remDateObj = new Date(remDate);

          // console.log("formattedDateObj", formattedDateObj, remDateObj);

          if (FIELD02 === "D" && formattedDateObj <= remDateObj) {
            row = { ...row, FIELD06: true }
          }
          else if (FIELD02 === "O" && formattedDateObj < remDateObj) {
            return null;
          }
          else if (FIELD02 === "W") {
            const startDay = new Date(startDate).getDay(); // 0
            const endDay = new Date(formattedFIELD01).getDay(); // 6
            const remDay = new Date(normalizedRemDate).getDay(); // 0

            // console.log("startDay", startDay, "endDay", endDay, "remDay", remDay);

            const Between = (startDay, remDay, endDay) => {
              return remDay >= startDay && remDay <= endDay;
            }

            if ((Between(startDay, remDay, endDay) || Between(endDay, remDay, startDay))) {
              row = { ...row, FIELD06: true }
            }
          }
          else if (FIELD02 === "M") {

            const newRemDate = new Date(normalizedRemDate);
            const nDate1 = new Date(newRemDate.getFullYear(), newRemDate.getMonth(), new Date(formattedFIELD01).getDay());
            const nDate2 = new Date(newRemDate.getFullYear(), newRemDate.getMonth() === 11 ? 0 : newRemDate.getMonth() + 1, new Date(formattedFIELD01).getDay());

            const addDays1 = (days) => {
              const date = new Date(normalizedRemDate);
              date.setDate(date.getDate() + days);
              const year = date.getFullYear();
              const month = String(date.getMonth() + 1).padStart(2, '0');
              const day = String(date.getDate()).padStart(2, '0')
              return `${year}-${month}-${day}`;
            }

            let date = addDays1(FIELD03);

            const Between = (startDay, remDay, endDay) => {
              return remDay >= startDay && remDay <= endDay;
            }

            if ((Between(nDate1, normalizedRemDate, date)) || Between(nDate2, normalizedRemDate, date)) {
              row = { ...row, FIELD06: true };
            }

          }
          else if (FIELD02 === "Y") {

            const newRemDate = new Date(normalizedRemDate);

            const nYear = newRemDate.getFullYear();
            const nDate1 = new Date(nYear, new Date(formattedFIELD01).getMonth(), new Date(formattedFIELD01).getDay());
            const nDate2 = new Date(nYear + 1, new Date(formattedFIELD01).getMonth(), new Date(formattedFIELD01).getDay());

            const addDays2 = (days) => {
              date.setDate(nDate1.getDate() + days);
              const year = date.getFullYear();
              const month = String(date.getMonth() + 1).padStart(2, '0');
              const day = String(date.getDate()).padStart(2, '0');
              return `${year}-${month}-${day}`;
            }

            let date = addDays2(-FIELD03)

            if ((Between(date, normalizedRemDate, nDate1) && newRemDate <= nDate1) || (Between(date, normalizedRemDate, nDate2) && newRemDate <= nDate2)) {
              row = { ...row, FIELD06: true }
            }
          }
          else {
            row = { ...row, FIELD06: false }
          }



        }
        return row;
      });

      if (modifiedObj) {
        const finalModifiedObj = modifiedObj?.filter(row => row !== null && row?.FIELD06 == true && row?.FIELD07 == GetGeneral?.oYear?.OSC?.USERID);
        // console.log("finalModifiedObj", finalModifiedObj);
        finalModifiedObj && setGRDDT(finalModifiedObj);
      }

    }

    setREMGRD(ReminderData?.REMGRD);

  }, [ReminderData]) // Vara Pooja

  useEffect(() => {
    if (REMGRD && REMGRD?.length > 0) {

      var cols = [];

      let columnObj = [
        { Name: "Particular", Value: "FIELD05" },
        { Name: "Remaining Days", Value: "FIELD01" },
        { Name: "Remind Date", Value: "FIELD03" },
        { Name: "User Name", Value: "M81F02" },
        { Name: "Mobile", Value: "FIELD08" },
        { Name: "R", Value: "FIELD09" }
      ]

      columnObj?.map((item, index) => {
        cols.push(
          columnHelper.accessor(item?.Value, {
            header: item?.Name,
            enableGrouping: true,
            enableColumnFilter: true,
            id: item?.S53UID,
            size: item?.Value === "FIELD05" ? 80 : 50, 
            minWidth: 20, 
            maxWidth: 80, 
            Cell: ({ cell, row }) => {
              let val = cell.getValue()
              if (item?.Name == "Remaining Days") {
                // console.log("val",val);
                return val.slice(6, 8) + "/" + val.slice(4, 6) + "/" + val.slice(0, 4);
              }

              return val
            }
          })
        );
      });

      setColumns(cols);
      setGrouping(["FIELD05"]);

      // REMGRD.filter(ele => ele.S53F00 !== "H").filter(item => item.S53F02 == "RID0075" || item.S53F02 == "RID0077" || item.S53F02 == "RID0078" || item.S53F02 == "RID0079" || item.S53F02 == "RID0080").map((item, index) => {
      //   // console.log(object)
      //   cols.push(
      //     columnHelper.accessor(item?.S53F04, {
      //       header: item?.S53F03,
      //       // enableGrouping: true,
      //       enableColumnFilter: true,
      //       id: item?.S53UID,
      //       size: item?.S53F03 == "Particular" ? 160 : 120,
      //       Cell: ({ cell, row }) => {
      //         let val = cell.getValue()
      //         if (item.S53F02 == "RID0075" && val?.trim()) {
      //           return val.slice(6, 8) + "/" + val.slice(4, 6) + "/" + val.slice(0, 4);
      //         }

      //         return val
      //       }
      //     })
      //   );
      // });
      // cols.push(
      //   columnHelper.accessor("M81F02", {
      //     header: "User Name",
      //     // enableGrouping: true,
      //     enableColumnFilter: true,
      //     // id: item?.S53UID,
      //     size: 120,
      //     Cell: ({ cell, row }) => {
      //       let val = cell.getValue()
      //       return val
      //     }
      //   })
      // );

    }
  }, [REMGRD]) // Vara Pooja

  useEffect(() => {

    if (GRDDT) {
      if (GRDDT?.length > 0) {
        setDrawerObj({
          ...dra,
          onClickCloseButton: () => {
            setDrawerObj({ ...DrawerObj, open: false })
            props?.setTemp && props?.setTemp(false)
          },
          title: <Text fw={700}>Reminder for Date : {formattedDate} </Text>,
          body: (
            <Grid>
              <Grid.Col span={12} m={0} p={0}>
                <Grid.Col span={6} pl={1}>
                  <PLTextBox value={search}
                    placeholder="Search Here"
                    setEdit={(e) => {
                      // console.log("search", e?.target?.value);
                      setSearch(e?.target?.value)
                    }}
                  />
                </ Grid.Col>
              </Grid.Col>
              <Grid.Col span={12} m={0} p={0}>
                <MantineReactTable table={table} />
              </Grid.Col>
            </Grid>
          ),
          open: true,
          size: "70%",
          position: "right",
        });
      }
      // setTemp(false)

    }
  }, [GRDDT]) // Vara Pooja

  // useEffect(() => {
  //   console.log(props?.temp,"props?.temp");
  //   if (props?.temp) {
  //     console.log(ReminderData, columns, GRDDT);
  //   }
  // }, [props?.temp])

  // useEffect(() => {
  //   console.log(DrawerObj, "DrawerObj");
  // }, [DrawerObj])

  const table = useMantineReactTable({
    data: GRDDT ?? [],
    columns,
    mantineTableProps: { withColumnBorders: true },
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableTopToolbar: false,
    enablePagination: true,
    enableColumnActions: false,
    enableSorting: false,
    enableGrouping: true,

    enableGlobalFilter: true,
    enableGlobalFilterModes: true,

    enableColumnFilters: false,
    enableColumnDragging: false,
    enableHiding: false,
    enableRowNumbers: false,
    enableColumnOrdering: false,
    positionToolbarAlertBanner: false,
    onGroupingChange: setGrouping, // Vara Pooja 

    initialState: {
      expanded: true,
      grouping,
      density: "0px"
    },
    state: {
      grouping,
      rowSelection,
      globalFilter: search,
      density: '0px'
    },

    mantineTableBodyRowProps: ({ row }) => ({
      // tabIndex: 0,
      onClick: () => {
        setRowSelection((prev) => ({
          [row.id]: !prev[row.id],
        }));
      },
    }),
  });

  return (
    <div>
      <Modal
        opened={DrawerObj?.open}
        // onClose={close}
        withCloseButton={true}
        fullScreen={false}//
        // overlayProps={{ opacity: 0.5, blur: 4 }}
        closeButtonProps={{
          onClick: (e) => {
            if (typeof DrawerObj?.onClickCloseButton == "function") {
              DrawerObj?.onClickCloseButton();
            }
            else {
              setDrawerObj(dra);
            }
          },
        }}
        title={DrawerObj?.title}
        size="auto"  // This helps keep the modal within limits but allows custom width.
        style={{
          width: '40%',    // Custom width
          maxWidth: '70%', // Ensure the modal doesn't grow beyond 70%
        }}
        // size={DrawerObj?.size}
        // centered={.Centered}
        closeOnClickOutside={true}
        // overlayProps={m.Overlay}
        position={DrawerObj?.position ?? 'bottom'}
        onClose={typeof DrawerObj?.onclose == "function" ? DrawerObj?.onclose : () => { setDrawerObj(dra); }}
      // scrollAreaComponent={ScrollArea.Autosize}
      >
        {DrawerObj.body}
      </Modal>

    </div>
  )
}

export default ReminderPopUp
