
import React from 'react'
import PriceListForm from './PriceListForm'
import Store from '../../utils/store'
import { DeletePriceData, GetHeaderData, GetMultiSelectHeaderData } from '../../utils/slices/PriceListSlice'
import { Text } from '@mantine/core'
import PartyAndProductList from './PartyAndProductList'
import CItyData from './CItyData'
import GroupData from './GroupData'
import Category from './CategoryData'
import RateWiseData from './RateWiseData'
import { ModalFunction } from '../../utils/slices/ModalSlice'
import M25DataTable from './M25DataTable'
import M25RateWise from './M25RateWise'
import GlobalClass from '../../utils/GlobalClass'


export function M24AED(data) {
  const OBJ = JSON.parse(data)
  // const OBJ = data
  console.log('obj M24AED', OBJ)
  if (OBJ.p0 === 'A') {
    Store.dispatch(
      ModalFunction({
        onclose: () => { },
        MTitle: <Text fw={700}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "lg",
        position: 'right',
        MBody: (i) => (
          <PriceListForm obj={OBJ} index={i} />
        ),
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        }
      })
    )
  } else if (OBJ.p0 === 'E' && OBJ.id) {
    Store.dispatch(
      ModalFunction({
        onclose: () => { },
        MTitle: <Text fw={700}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "lg",
        position: 'right',
        MBody: (i) => (
          <PriceListForm obj={OBJ} index={i} />
        ),
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        }
      })
    )
  } else if (OBJ.p0 === 'D' && OBJ.id) {
    // if (window.confirm('Confirm to Delete ?')) {
    //   Store.dispatch(DeletePriceData({ action: OBJ.p0, code: OBJ.id }))
    // }

    GlobalClass.Confirm(
      "red",
      "Are you sure to delete selected data?",
      "It will be Deleted Forever and Cannot Be Recovered!!!",
      () => {
        Store.dispatch(DeletePriceData({ action: OBJ.p0, code: OBJ.id }))
      },
      () => { },
      "Cancel",
      "Confirm"
  )
  } else if (OBJ.p0 === 'Z' && OBJ.id) {
    if (OBJ?.DataGridRow?.FIELD25 == "R") {
      return Store.dispatch(
        ModalFunction({
          onclose: () => { },
          MTitle: <Text fw={700}>{OBJ?.text}</Text>,
          MAction: true,
          MSize: "80%",
          position: 'right',
          MBody: (i) => (
            <M25RateWise obj={OBJ} index={i} />
          ),
          MClose: true,
          onClickCloseButton: () => {
            Store.dispatch(GetHeaderData({ productData: [], partyData: [] }))
            Store.dispatch(GetMultiSelectHeaderData({
              partyData: [],
              productData: [],
              cityData: [],
              partyGroupData: [],
              productGroupData: [],
              categoryData: [],
              areaData: [],
            }))
          },
          Overlay: {
            backgroundOpacity: 0.8,
            blur: 5,
          }
        })
      )
    } else {
      return Store.dispatch(
        ModalFunction({
          onclose: () => { },
          MTitle: <Text fw={700}>{OBJ?.text}</Text>,
          MAction: true,
          MSize: "80%",
          position: 'right',
          MBody: (i) => (
            <M25DataTable obj={OBJ} index={i} />
          ),
          MClose: true,
          onClickCloseButton: () => {
            Store.dispatch(GetHeaderData({ productData: [], partyData: [] }))
            Store.dispatch(GetMultiSelectHeaderData({
              partyData: [],
              productData: [],
              cityData: [],
              partyGroupData: [],
              productGroupData: [],
              categoryData: [],
              areaData: [],
            }))
          },
          Overlay: {
            backgroundOpacity: 0.8,
            blur: 5,
          }
        })
      )
    }
    // if (OBJ.DataGridRow?.FIELD21 === "#FIELD12,FIELD01" && OBJ.DataGridRow?.FIELD22 === "#M32C,M21") {
    //   return Store.dispatch(
    //     ModalFunction({
    //       onclose: () => { },
    //       MTitle: <Text fw={700}>{OBJ?.text}</Text>,
    //       MAction: true,
    //       MSize: "xl",
    //       position: 'right',
    //       MBody: (i) => (
    //         <Category obj={OBJ} index={i} />
    //       ),
    //       MClose: true,
    //       Overlay: {
    //         backgroundOpacity: 0.8,
    //         blur: 5,
    //       }
    //     })
    //   )
    // } else if (OBJ.DataGridRow?.FIELD21 === "#FIELD01,FIELD12" && OBJ.DataGridRow?.FIELD22 === "#M21,M32C") {
    //   return Store.dispatch(
    //     ModalFunction({
    //       onclose: () => { },
    //       MTitle: <Text fw={700}>{OBJ?.text}</Text>,
    //       MAction: true,
    //       MSize: "xl",
    //       position: 'right',
    //       MBody: (i) => (
    //         <Category obj={OBJ} index={i} />
    //       ),
    //       MClose: true,
    //       Overlay: {
    //         backgroundOpacity: 0.8,
    //         blur: 5,
    //       }
    //     })
    //   )

    // }
    // else if (OBJ.DataGridRow?.FIELD21 === "FIELD01#FIELD01" && OBJ.DataGridRow?.FIELD22 === "M01#M21" && OBJ.DataGridRow?.FIELD25 === "%") {
    //   return Store.dispatch(
    //     ModalFunction({
    //       onclose: () => { },
    //       MTitle: <Text fw={700}>{OBJ?.text}</Text>,
    //       MAction: true,
    //       MSize: "xl",
    //       position: 'right',
    //       MBody: (i) => (
    //         <PartyAndProductList obj={OBJ} index={i} />
    //       ),
    //       MClose: true,
    //       Overlay: {
    //         backgroundOpacity: 0.8,
    //         blur: 5,
    //       }
    //     })
    //   )
    // } else if (OBJ.DataGridRow?.FIELD21 === "FIELD16,FIELD01#FIELD11,FIELD01" && OBJ.DataGridRow?.FIELD22 === "M31C,M01#M32,M21") {
    //   return Store.dispatch(
    //     ModalFunction({
    //       onclose: () => { },
    //       MTitle: <Text fw={700}>{OBJ?.text}</Text>,
    //       MAction: true,
    //       MSize: "xl",
    //       position: 'right',
    //       MBody: (i) => (
    //         <CItyData obj={OBJ} index={i} />
    //       ),
    //       MClose: true,
    //       Overlay: {
    //         backgroundOpacity: 0.8,
    //         blur: 5,
    //       }
    //     })
    //   )
    // } else if (OBJ.DataGridRow?.FIELD21 === "FIELD01,FIELD16#FIELD01,FIELD11" && OBJ.DataGridRow?.FIELD22 === "M01,M31C#M21,M32") {
    //   return Store.dispatch(
    //     ModalFunction({
    //       onclose: () => { },
    //       MTitle: <Text fw={700}>{OBJ?.text}</Text>,
    //       MAction: true,
    //       MSize: "xl",
    //       position: 'right',
    //       MBody: (i) => (
    //         <CItyData obj={OBJ} index={i} />
    //       ),
    //       MClose: true,
    //       Overlay: {
    //         backgroundOpacity: 0.8,
    //         blur: 5,
    //       }
    //     })
    //   )

    // } else if (OBJ.DataGridRow?.FIELD21 === "FIELD01,FIELD16#FIELD11,FIELD01" && OBJ.DataGridRow?.FIELD22 === "M01,M31C#M32,M21") {
    //   return Store.dispatch(
    //     ModalFunction({
    //       onclose: () => { },
    //       MTitle: <Text fw={700}>{OBJ?.text}</Text>,
    //       MAction: true,
    //       MSize: "xl",
    //       position: 'right',
    //       MBody: (i) => (
    //         <CItyData obj={OBJ} index={i} />
    //       ),
    //       MClose: true,
    //       Overlay: {
    //         backgroundOpacity: 0.8,
    //         blur: 5,
    //       }
    //     })
    //   )
    // } else if (OBJ.DataGridRow?.FIELD21 === "FIELD16,FIELD11,FIELD01#FIELD01" && OBJ.DataGridRow?.FIELD22 === "M31C,M32,M01#M21") {
    //   return Store.dispatch(
    //     ModalFunction({
    //       onclose: () => { },
    //       MTitle: <Text fw={700}>{OBJ?.text}</Text>,
    //       MAction: true,
    //       MSize: "xl",
    //       position: 'right',
    //       MBody: (i) => (
    //         <CItyData obj={OBJ} index={i} />
    //       ),
    //       MClose: true,
    //       Overlay: {
    //         backgroundOpacity: 0.8,
    //         blur: 5,
    //       }
    //     })
    //   )

    // } else if (OBJ.DataGridRow?.FIELD21 === "FIELD01#FIELD11,FIELD01" && OBJ.DataGridRow?.FIELD22 === "M01#M32,M21") {
    //   return Store.dispatch(
    //     ModalFunction({
    //       onclose: () => { },
    //       MTitle: <Text fw={700}>{OBJ?.text}</Text>,
    //       MAction: true,
    //       MSize: "xl",
    //       position: 'right',
    //       MBody: (i) => (
    //         <GroupData obj={OBJ} index={i} />
    //       ),
    //       MClose: true,
    //       Overlay: {
    //         backgroundOpacity: 0.8,
    //         blur: 5,
    //       }
    //     })
    //   )

    // }
    // else if (OBJ.DataGridRow?.FIELD21 === "FIELD11,FIELD01#FIELD01" && OBJ.DataGridRow?.FIELD22 === "M32,M01#M21") {
    //   return Store.dispatch(
    //     ModalFunction({
    //       onclose: () => { },
    //       MTitle: <Text fw={700}>{OBJ?.text}</Text>,
    //       MAction: true,
    //       MSize: "xl",
    //       position: 'right',
    //       MBody: (i) => (
    //         <GroupData obj={OBJ} index={i} />
    //       ),
    //       MClose: true,
    //       Overlay: {
    //         backgroundOpacity: 0.8,
    //         blur: 5,
    //       }
    //     })
    //   )
    // } else if (OBJ.DataGridRow?.FIELD21 === "FIELD01#FIELD01" && OBJ.DataGridRow?.FIELD22 === "M01#M21" && OBJ.DataGridRow?.FIELD21 === "FIELD01#FIELD01" && OBJ.DataGridRow?.FIELD25 === "R") {
    //   return Store.dispatch(
    //     ModalFunction({
    //       onclose: () => { },
    //       MTitle: <Text fw={700}>{OBJ?.text}</Text>,
    //       MAction: true,
    //       MSize: "xl",
    //       position: 'right',
    //       MBody: (i) => (
    //         <RateWiseData obj={OBJ} index={i} />
    //       ),
    //       MClose: true,
    //       Overlay: {
    //         backgroundOpacity: 0.8,
    //         blur: 5,
    //       }
    //     })
    //   )
    // }
  } else {
    return null
  }
}