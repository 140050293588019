import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GetCmpSetUpPopUp } from '../../utils/slices/CompanySetUpPopUpSlice'
import PLDataGrid from '../../PlusComponent/PLDataGrid'
import GlobalClass from '../../utils/GlobalClass'

const SecLang = (props) => {
    const { obj, value, setEditSend } = props
    console.log('vallue==>>', value)
    const SecondaryLangData = useSelector((state) => state.CompanySetupPopUp?.CmpSetUpPopUpData)
    const { isLoading } = useSelector((state) => state.CompanySetupPopUp)
    const P_PLSYSLANG = useSelector((state) => state.CompanySetupPopUp?.CmpSetUpPopUpData?.P_PLSYSLANG)
    const P_PLSYSLANGDT = useSelector((state) => state.CompanySetupPopUp?.CmpSetUpPopUpData?.P_PLSYSLANGDT)
    const dispatch = useDispatch()

    useEffect(() => {
        if (obj) {
            dispatch(GetCmpSetUpPopUp(obj))
        }
    }, [obj])

    const seperate = (inputVal) => {
        let val = inputVal.split("||")
        return val.join(",")
    }

    return (
        <div style={{ width: "100%" }}>
            {
                isLoading ? GlobalClass.RenderLoader('dots') :
                    SecondaryLangData &&
                    <PLDataGrid
                    autoFocus={true}
                    openOnFocus={true}
                        dropdownPosition={true}
                        // width={"500px"}
                        dispexpr={"LANGF02"}
                        valexpr={"LANGF01"}
                        isMulti={true}
                        TmData={P_PLSYSLANG}
                        data={P_PLSYSLANGDT?.jData}
                        setEdit={(e) => {
                            // console.log('e====>',e)
                            let val = [];
                            if (e?.length > 0) {
                                e?.map((m) => {
                                    val.push(m?.original?.LANGF01)
                                })
                                // console.log('val=>', val);
                                const result = val.join("||")
                                setEditSend(result)
                            } else {
                                setEditSend("")
                            }
                        }}
                        value={seperate(value)}
                    />
            }
        </div>
    )
}

export default SecLang