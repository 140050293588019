import {
  Container,
  Group,
  ActionIcon,
  rem,
  Image,
  useMantineColorScheme,
  createStyles,
  Text,
  useMantineTheme,
  Dialog,
  Divider,
  Grid,
} from "@mantine/core";
import {
  IconBrandTwitter,
  IconBrandYoutube,
  IconBrandInstagram,
  IconVersions,
  IconBrandWhatsapp,
  IconSun,
  IconMoonStars,
  IconAB,
  IconTypography,
  IconPalette,
  IconBellRinging2,
  IconBellRinging,
} from "@tabler/icons-react";
import App from "../App.json";
import WhatsAppPopover from "../MyComponent/WhatsappPopup";
import { useEffect, useState } from "react";
import SessionSetting from "./SessionSetting";
import PLDateBox from "../PlusComponent/PLDateBox";
import { useDispatch, useSelector } from "react-redux";
import { getFooterDate } from "../utils/slices/LanguageSlice";
import { createColumnHelper } from "@tanstack/react-table";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import useWindowDimensions from "../utils/UseWindowDimensions";
import { ModalFunction } from "../utils/slices/ModalSlice";
import PLTextBox from "../PlusComponent/PLTextBox";
import ReminderPopUp from "../MyComponent/ReminderPopUp";
var iconSize = "2rem";
export function FooterBar(styles) {
  let dra = {
    title: "",
    size: "md",
    body: "",
    open: false,
    onClickCloseBtn: "",
  };
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const theme = useMantineTheme();
  const dispatch = useDispatch();
  const dark = colorScheme === "dark";
  const date1 = new Date();
  const columnHelper = createColumnHelper()
  const { height, width } = useWindowDimensions();

  const GetGeneral = useSelector((state) => state?.Language?.General);
  const isLoading = useSelector((state) => state.Language?.isLoading);
  // const { ReminderData } = useSelector(store => store?.Reminder); // // Vara Pooja

  const [DrawerObj, setDrawerObj] = useState(dra);
  const [fDate, setFDate] = useState();
  const [temp, setTemp] = useState(false)
  // const [GRDDT, setGRDDT] = useState();
  // const [REMGRD, setREMGRD] = useState();
  // const [columns, setColumns] = useState([])
  // const [search, setSearch] = useState();
  // const [grouping, setGrouping] = useState([]); // Vara Pooja

  const week = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

  // const currentDate = new Date();

  // const year = currentDate.getFullYear();
  // const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  // const day = currentDate.getDate().toString().padStart(2, '0');

  // const formattedDate = `${day}/${month}/${year}`; // Vara Pooja

  useEffect(() => {
    dispatch(getFooterDate(new Date(GetGeneral?.dYSDate).getTime() <= date1.getTime() &&
      new Date(GetGeneral?.dYEDate).getTime() >= date1.getTime()
      ? date1 : new Date(GetGeneral?.dYEDate)))
  }, [GetGeneral])

  // useEffect(() => {
  //   // console.log("ReminderData", ReminderData?.GRDDT);
  //   // console.log("General", GetGeneral?.oYear?.dWDate);

  //   if (ReminderData) {

  //     const obj = ReminderData?.GRDDT?.map((item) => item?.FIELD06 == "C" || 1 ? { ...item, FIELD06: true } : { ...item, FIELD06: false })
  //     const remDate = GetGeneral?.oYear?.dWDate

  //     const modifiedObj = obj?.map((row, index) => {

  //       row = { ...row, FIELD06: false }
  //       const FIELD03 = row?.FIELD03
  //       const FIELD01 = row?.FIELD01
  //       const FIELD02 = row?.FIELD02?.toString()

  //       const Between = (start, date, end) => {
  //         const startDate = new Date(start);
  //         const checkDate = new Date(date);
  //         const endDate = new Date(end);
  //         return checkDate >= startDate && checkDate <= endDate;
  //       }

  //       let formattedFIELD01 = FIELD01?.slice(0, 4) + '-' + FIELD01?.slice(4, 6) + '-' + FIELD01?.slice(6);

  //       const addDays = (days) => {
  //         const date = new Date(formattedFIELD01)
  //         date.setDate(date.getDate() + days);
  //         const year = date.getFullYear();
  //         const month = String(date.getMonth() + 1).padStart(2, '0');
  //         const day = String(date.getDate()).padStart(2, '0');
  //         return `${year}-${month}-${day}`;
  //       }

  //       if (FIELD01) {

  //         let startDate = addDays(-FIELD03)
  //         let normalizedRemDate = remDate.split('T')[0];

  //         if ((Between(startDate, normalizedRemDate, formattedFIELD01) || Between(formattedFIELD01, normalizedRemDate, startDate))) {
  //           row = { ...row, FIELD06: true }
  //         }
  //         else if (FIELD02 === "O" &&
  //           (Between(startDate, normalizedRemDate, formattedFIELD01) || Between(formattedFIELD01, normalizedRemDate, startDate))) {
  //           row = { ...row, FIELD06: true }
  //         }

  //         let formattedDateObj = new Date(formattedFIELD01);
  //         let remDateObj = new Date(remDate);

  //         // console.log("formattedDateObj", formattedDateObj, remDateObj);

  //         if (FIELD02 === "D" && formattedDateObj <= remDateObj) {
  //           row = { ...row, FIELD06: true }
  //         }
  //         else if (FIELD02 === "O" && formattedDateObj < remDateObj) {
  //           return null;
  //         }
  //         else if (FIELD02 === "W") {
  //           const startDay = new Date(startDate).getDay(); // 0
  //           const endDay = new Date(formattedFIELD01).getDay(); // 6
  //           const remDay = new Date(normalizedRemDate).getDay(); // 0

  //           // console.log("startDay", startDay, "endDay", endDay, "remDay", remDay);

  //           const Between = (startDay, remDay, endDay) => {
  //             return remDay >= startDay && remDay <= endDay;
  //           }

  //           if ((Between(startDay, remDay, endDay) || Between(endDay, remDay, startDay))) {
  //             row = { ...row, FIELD06: true }
  //           }
  //         }
  //         else if (FIELD02 === "M") {

  //           const newRemDate = new Date(normalizedRemDate);
  //           const nDate1 = new Date(newRemDate.getFullYear(), newRemDate.getMonth(), new Date(formattedFIELD01).getDay());
  //           const nDate2 = new Date(newRemDate.getFullYear(), newRemDate.getMonth() === 11 ? 0 : newRemDate.getMonth() + 1, new Date(formattedFIELD01).getDay());

  //           const addDays1 = (days) => {
  //             const date = new Date(normalizedRemDate);
  //             date.setDate(date.getDate() + days);
  //             const year = date.getFullYear();
  //             const month = String(date.getMonth() + 1).padStart(2, '0');
  //             const day = String(date.getDate()).padStart(2, '0')
  //             return `${year}-${month}-${day}`;
  //           }

  //           let date = addDays1(FIELD03);

  //           const Between = (startDay, remDay, endDay) => {
  //             return remDay >= startDay && remDay <= endDay;
  //           }

  //           if ((Between(nDate1, normalizedRemDate, date)) || Between(nDate2, normalizedRemDate, date)) {
  //             row = { ...row, FIELD06: true };
  //           }

  //         }
  //         else if (FIELD02 === "Y") {

  //           const newRemDate = new Date(normalizedRemDate);

  //           const nYear = newRemDate.getFullYear();
  //           const nDate1 = new Date(nYear, new Date(formattedFIELD01).getMonth(), new Date(formattedFIELD01).getDay());
  //           const nDate2 = new Date(nYear + 1, new Date(formattedFIELD01).getMonth(), new Date(formattedFIELD01).getDay());

  //           const addDays2 = (days) => {
  //             date.setDate(nDate1.getDate() + days);
  //             const year = date.getFullYear();
  //             const month = String(date.getMonth() + 1).padStart(2, '0');
  //             const day = String(date.getDate()).padStart(2, '0');
  //             return `${year}-${month}-${day}`;
  //           }

  //           let date = addDays2(-FIELD03)

  //           if ((Between(date, normalizedRemDate, nDate1) && newRemDate <= nDate1) || (Between(date, normalizedRemDate, nDate2) && newRemDate <= nDate2)) {
  //             row = { ...row, FIELD06: true }
  //           }
  //         }
  //         else {
  //           row = { ...row, FIELD06: false }
  //         }



  //       }
  //       return row;
  //     });

  //     if (modifiedObj) {
  //       const finalModifiedObj = modifiedObj?.filter(row => row !== null && row?.FIELD06 == true && row?.FIELD07 == GetGeneral?.oYear?.OSC?.USERID);
  //       // console.log("finalModifiedObj", finalModifiedObj);
  //       finalModifiedObj && setGRDDT(finalModifiedObj);
  //     }

  //   }

  //   setREMGRD(ReminderData?.REMGRD);


  // }, [ReminderData]) // Vara Pooja

  // useEffect(() => {
  //   if (REMGRD && REMGRD?.length > 0) {
  //     var cols = [];

  //     let columnObj = [
  //       { Name: "Particular", Value: "FIELD05" },
  //       { Name: "Remaining Days", Value: "FIELD01" },
  //       { Name: "Remind Date", Value: "FIELD03" },
  //       { Name: "User Name", Value: "M81F02" },
  //       { Name: "Mobile", Value: "FIELD08" },
  //       { Name: "R", Value: "FIELD09" }
  //     ]

  //     columnObj?.map((item, index) => {
  //       cols.push(
  //         columnHelper.accessor(item?.Value, {
  //           header: item?.Name,
  //           enableGrouping: true,
  //           enableColumnFilter: true,
  //           id: item?.S53UID,
  //           size: item?.Value == "FIELD05" ? 100 : item?.Value == "FIELD01" ? 20 : 70,
  //           Cell: ({ cell, row }) => {
  //             let val = cell.getValue()
  //             if (item?.Name == "Remaining Days") {
  //               return val.slice(6, 8) + "/" + val.slice(4, 6) + "/" + val.slice(0, 4);
  //             }

  //             return val
  //           }
  //         })
  //       );
  //     });

  //     setColumns(cols);
  //     setGrouping(["FIELD05"]);

  //     // var cols = [];
  //     // REMGRD.filter(ele => ele.S53F00 !== "H").filter(item => item.S53F02 == "RID0075" || item.S53F02 == "RID0077" || item.S53F02 == "RID0078" || item.S53F02 == "RID0079" || item.S53F02 == "RID0080").map((item, index) => {
  //     //   // console.log(object)
  //     //   cols.push(
  //     //     columnHelper.accessor(item?.S53F04, {
  //     //       header: item?.S53F03,
  //     //       // enableGrouping: true,
  //     //       enableColumnFilter: true,
  //     //       id: item?.S53UID,
  //     //       size: item?.S53F03 == "Particular" ? 160 : 120,
  //     //       Cell: ({ cell, row }) => {
  //     //         let val = cell.getValue()
  //     //         if (item.S53F02 == "RID0075" && val?.trim()) {
  //     //           return val.slice(6, 8) + "/" + val.slice(4, 6) + "/" + val.slice(0, 4);
  //     //         }

  //     //         return val
  //     //       }
  //     //     })
  //     //   );
  //     // });
  //     // cols.push(
  //     //   columnHelper.accessor("M81F02", {
  //     //     header: "User Name",
  //     //     // enableGrouping: true,
  //     //     enableColumnFilter: true,
  //     //     // id: item?.S53UID,
  //     //     size: 120,
  //     //     Cell: ({ cell, row }) => {
  //     //       let val = cell.getValue()
  //     //       return val
  //     //     }
  //     //   })
  //     // );
  //     // setColumns(cols);
  //     // setGrouping(["FIELD05"]);
  //   }
  // }, [REMGRD]) // Vara Pooja

  // const table = useMantineReactTable({
  //   data: GRDDT ?? [],
  //   columns,
  //   mantineTableProps: { withColumnBorders: true },
  //   enableFullScreenToggle: false,
  //   enableDensityToggle: false,
  //   enableTopToolbar: false, // Vara Pooja
  //   enablePagination: true,
  //   enableColumnActions: false,
  //   enableSorting: false,
  //   enableColumnFilters: false,
  //   enableColumnDragging: false,
  //   enableGrouping: true,
  //   enableHiding: false,
  //   enableRowNumbers: false,
  //   enableColumnOrdering: false,
  //   enableGlobalFilter: false,
  //   positionToolbarAlertBanner: false,
  //   onGroupingChange: setGrouping,

  //   initialState: {
  //     expanded:true,
  //     grouping,
  //     density: "0px"
  //   },
  //   state: {
  //     grouping,
  //     globalFilter: search,
  //     density: '0px'
  //   },
  //   mantineTableBodyRowProps: ({ row }) => ({
  //     tabIndex: 0,
  //     // onKeyUp: (e) => handleRowKeyDown(e, row),
  //     // onClick: () => {
  //     //   setTableRowSelection((prev) => {
  //     //     if (!prev[row.id]) {
  //     //       return { [row.id]: true };
  //     //     }
  //     //     return prev;
  //     //   });
  //     // },
  //     // selected: tableRowSelection[row.id],
  //   }),
  //   mantineTableContainerProps: {
  //     sx: { height: height * 0.4 },
  //   },

  // });


  return (
    <>
      <Dialog
        opened={DrawerObj.open}
        withCloseButton
        onClose={() => setDrawerObj(dra)}
        size="lg"
        radius="md"
        p={30}
        shadow="md"
      >
        {DrawerObj.body}
      </Dialog>

      {isLoading ? "" : <Container fluid className={"footerf"}>
        {/* <div className={'footerf'} style={{paddingBottom:5}} >   */}
        <Grid columns={11}>
          <Grid.Col span={2}>
            <Text variant="text" color={"gray"}>
              {App.version}
            </Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <WhatsAppPopover />
          </Grid.Col>
          <Grid.Col span={1}>
            <ActionIcon
              onClick={() => toggleColorScheme()}
              variant="transparent"
              color={dark ? "yellow" : "blue"}
              title="Toggle color scheme"
              mr={5}
            >
              {dark ? (
                <IconSun size={iconSize} />
              ) : (
                <IconMoonStars size={iconSize} />
              )}
            </ActionIcon>
          </Grid.Col>
          <Grid.Col span={1}>
            <ActionIcon
              onClick={() =>
                setDrawerObj({
                  ...dra,
                  title: <Text fw={700}>Font Setting</Text>,
                  body: (
                    <>
                      <SessionSetting setting={"font"} />
                    </>
                  ),
                  open: true,
                  size: "md",
                  position: "right",
                })
              }
              variant="transparent"
              // color={dark ? 'yellow' : 'blue'}
              title="Font Settings"
              mr={5}
            >
              <IconTypography size={iconSize} />
            </ActionIcon>
          </Grid.Col>
          <Grid.Col span={1}>
            <ActionIcon
              onClick={() =>
                setDrawerObj({
                  ...dra,
                  title: <Text fw={700}>Color Setting</Text>,
                  body: (
                    <>
                      <SessionSetting setting={"color"} />
                    </>
                  ),
                  open: true,
                  size: "md",
                  position: "right",
                })
              }
              variant="transparent"
              // color={dark ? 'yellow' : 'blue'}
              title="color Settings"
              mr={5}
            >
              <IconPalette size={iconSize} />
            </ActionIcon>
          </Grid.Col>
          <Grid.Col span={4} style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>

            <PLDateBox
              value={
                fDate ?? (new Date(GetGeneral?.dYSDate).getTime() <= date1.getTime() &&
                  new Date(GetGeneral?.dYEDate).getTime() >= date1.getTime()
                  ? date1 : new Date(GetGeneral?.dYEDate))
              }
              setEdit={(e) => {
                if (e || !isNaN(e?.getTime())) {
                  setFDate(e);
                  dispatch(getFooterDate(e))
                }

              }}
            />
          </Grid.Col>
          <Grid.Col span={1}>
            <ActionIcon
              onClick={() => setTemp(true)}
              variant="transparent"
              // color={dark ? 'yellow' : 'blue'}
              title="Reminder"
              mr={5}
            >
              <IconBellRinging size={iconSize} />
            </ActionIcon>
            {/* <ActionIcon
              onClick={() =>
              // setDrawerObj({
              //   ...dra,
              //   title: <Text fw={700}>Color Setting</Text>,
              //   body: (
              //     <>
              //       <SessionSetting setting={"color"} />
              //     </>
              //   ),
              //   open: true,
              //   size: "md",
              //   position: "right",
              // })

              // setDrawerObj({
              //   ...dra,
              //   title: <Text fw={700}>Reminder for Date : {formattedDate} </Text>,
              //   body: (
              //     <>
              //       <MantineReactTable table={table} />
              //     </>
              //   ),
              //   open: true,
              //   size: "100%",
              //   position: "right",
              // })

              // {
                // if (GRDDT && GRDDT?.length > 0) {
                //   dispatch(ModalFunction({
                //     MTitle: <Text size="lg" weight={600} align="center" style={{ color: '#333' }}> Reminder for Date : {formattedDate} </Text>,
                //     MAction: true,
                //     MSize: "auto",
                //     // position:'right',
                //     fullScreen: false,
                //     MBody: (i) => (
                //       <Grid>
                //         <Grid.Col span={12} m={0} p={0}>
                //           <Grid.Col span={6} pl={1}>
                //             <PLTextBox value={search}
                //               setEdit={(e) => {
                //                 setSearch(e.target.value)
                //               }}
                //             />
                //           </ Grid.Col>
                //         </Grid.Col>
                //         <Grid.Col span={12} m={0} p={0}>
                //           <MantineReactTable table={table} />
                //         </Grid.Col>
                //       </Grid>
                //     ),
                //     MClose: true,
                //     // tab:OBJ.page.startsWith("P_")?false:true,
                //     onclose: () => { },
                //     onClickCloseButton: () => {
                //       // Promise.resolve(Store.dispatch(GetInvTypeDropDown(null))).then(() => Store.dispatch(GetPendingQuotationDataNull([]))).then(() => Store.dispatch(GetSingleSelectedOrderRowNull([]))).then(() => Store.dispatch(GetPendingOrderDataNull([]))).then(() => Store.dispatch(GetSingleOrderRowNull([]))).then(() => Store.dispatch(GetExpenseDataNull([]))).then(() => Store.dispatch(GetNullTransactionData()))
                //       // GetPendingOrderDataNull, GetSingleOrderRowNull
                //     }
                //   }))
                // }
              // }

              }
              variant="transparent"
              // color={dark ? 'yellow' : 'blue'}
              title="Reminder"
              mr={5}
            >
              <IconBellRinging size={iconSize} />
            </ActionIcon> */}
          </Grid.Col>
        </Grid>
        {
          temp && <ReminderPopUp temp={temp} setTemp={setTemp} />
        }
        {/* <Divider orientation="vertical" /> */}

        {/* </Group> */}
      </Container>}


      {/* </div> */}
    </>
  );
}
