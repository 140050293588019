import {
  ActionIcon,
  Alert,
  Button,
  Card,
  Center,
  Checkbox,
  Container,
  Flex,
  Grid,
  Group,
  List,
  Modal,
  Overlay,
  Paper,
  Text,
  Tooltip,
} from "@mantine/core";
import NewReportViewer from "./NewReportViewer";
import Store from "../utils/store";
import { ModalDelete, ModalFunction } from "../utils/slices/ModalSlice";
import VoucherFmtForm from "../SetupComponent/VoucherFormat/VoucherFmtForm";
import useWindowDimensions from "../utils/UseWindowDimensions";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import PLComboBox from "../PlusComponent/PLComboBox";
import MyReportDesigner from "./ReportDesigner";
import { useDispatch, useSelector } from "react-redux";
import { GetAccountList, GetTMFormat } from "../utils/slices/AccountListSlice";
import { useEffect, useState } from "react";
import { GetReportDesignerData } from "../utils/PrintSlice/ReportDesignerSlice";
import GlobalClass from "../utils/GlobalClass";
import DataGrid from "./DataGrid";
import ReportViewerTest from "./ReportViewerTest";
import gensetting from "../utils/gensetting";
import { IconAlertCircle, IconEdit } from "@tabler/icons-react";
import PLTextBox from "../PlusComponent/PLTextBox";
import { saveAs } from "file-saver";
import { createColumnHelper } from "@tanstack/react-table";
import { findCellIndex } from "@syncfusion/ej2-react-grids";
import { CashBankAddApi } from "../utils/TransactionSlices/CashBankSlice";
let globalid = "";
let pageid = "";
let pageformat = "";
const reportUrl = GlobalClass.ApiUrl + "ReportViewer/PostReportAction";
export const TM_PRINTVF = (data,handleClose) => {

  const obj = JSON.parse(data);
  console.log("TM_PRINTVF 123=>", obj);

  if (obj?.id != "") {
    Store.dispatch(
      ModalFunction({
        MTitle: <Text fw={700}>{obj?.text}</Text>,
        MAction: true,
        MSize: "75%",
        // position:'right',
        fullScreen: false,
        MBody: (i) => <VoucherPrintForm obj={obj} />, //<NewReportViewer obj={obj} index={i} />,
        MClose: true,
        onclose: () => {},
        onClickCloseButton: () => {
          // Store.dispatch(GetInvTypeDropDown(null))
          if (handleClose) {
            handleClose(); 
        }
        },
      })
    );
  }
};
let defaultprintDetails = {
  type: "PDF",
  filename: "SaleBill",
  Copies: 1,
  password: "",
  printer: "",
  email: [],
  mobile: [],
  isPassword: false,
};
export function VoucherPrintForm({ obj }) {
  const { height } = useWindowDimensions();
  let dra = {
    title: "",
    size: "md",
    open: false,
    onClickCloseBtn: () => {},
  };
  const { isLoading, hasError, ErrorMsg, Data } = useSelector(
    (state) => state?.ReportDesigner
  );
  const { TMFormatData: tmList } = useSelector((state) => state.AccountList);
  const {
    AccountListData: accList,
    TMFormatData: tmListData,
    isLoading: AccNameisLoading,
  } = useSelector((state) => state.AccountList);
  const CashBankAddData = useSelector(store => store?.CashBank?.CashBankAddData);


  const [ccode, setccode] = useState(null);
  const [listObj, setListobj] = useState(null);
  const [selected, setSelected] = useState();
  const [ReportOn, setReportOn] = useState("s");
  const [printDetails, setPrintDetails] = useState({ ...defaultprintDetails });
  const [isPrintLoading, setIsPrintLoading] = useState(false);
  let column = [];
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 25,
  });

  const [DrawerObj, setDrawerObj] = useState(dra);
  const [rowSelection, setRowSelection] = useState({});
  const [selectedRow, setSelectedRow] = useState();
  const [modalClosed, setModalClosed] = useState();
  const [printerData, setPrinterData] = useState([])
  const [selectedPrinter, setSelectedPrinter] = useState(null)
  // console.log("selectedPrinter", selectedPrinter)
  const columnHelper = createColumnHelper();
  const dispatch = useDispatch();

  useEffect(() => {
    if (obj.BtnPnlObj?.cSTID == "T_QC" || obj.BtnPnlObj?.cSTID == "T_QJ") {
      dispatch(CashBankAddApi(obj.BtnPnlObj))
    }
    else {
      dispatch(GetTMFormat(obj.BtnPnlObj));
    }
    globalid = obj?.BtnPnlObj?.id;
    pageid = obj?.id ?? obj?.BtnPnlObj?.id;
  }, []);

  useEffect(() => {
    console.log("Report TMFORMAT", tmList[obj?.BtnPnlObj?.id]);

    if (tmList[obj?.BtnPnlObj?.id]) {
      setccode(tmList[obj?.BtnPnlObj?.id]?.oS60?.cS92ID);
    }
  }, [tmList, obj?.BtnPnlObj?.id]);

  useEffect(() => {
    CashBankAddData && setccode(CashBankAddData?.OS11?.S11S92ID)
  }, [CashBankAddData])

  useEffect(() => {
    column?.push(
      columnHelper.accessor("FIELD61", {
        header: "Party Name",
        size: 100,
        enableEditing: false,
      })
    );
    column?.push(
      columnHelper.accessor("FIELD05", {
        header: "City",
        size: 100,
        enableEditing: false,
      })
    );
    if(ReportOn=="e"){column?.push(
      columnHelper.accessor("FIELD43", {
        header: "email id",
        size: 100,
        enableEditing: false,
      })
    );}else if(ReportOn=="w"){column?.push(
      columnHelper.accessor("FIELD35", {
        header: "Mobile",
        size: 100,
        enableEditing: false,
      })
    );}

    setColumns(column);
  }, [data, ReportOn]);

  useEffect(() => {
    setData(accList?.P_M02);
  }, [accList?.P_M02]);


  const getData = () => {
    dispatch(GetReportDesignerData({ id: obj?.BtnPnlObj?.id, cCode: ccode }));
  };

  useEffect(() => {
    console.log("ccc", ccode);
    if (ccode) {
      getData();
    }
  }, [ccode]);
  useEffect(() => {
    // if(!Data[obj?.BtnPnlObj?.id]){
    //   GlobalClass.Notify("error","Report Editing Data Not Available","Contact Support",false,6000)
    // }
    if (hasError) {
      GlobalClass.Notify("error", ErrorMsg, "Contact Support", false, 6000);
    }
    if (!isLoading && Data[obj?.BtnPnlObj?.id]) {
      let objpc50 = {
        name: "",
        type: "A",
        id: Data[obj?.BtnPnlObj?.id].P_C50.split("~C~")[0],
        p0: Data[obj?.BtnPnlObj?.id].P_C50.split("~C~")[0],
        p1: Data[obj?.BtnPnlObj?.id].P_C50.split("~C~")[0],
        p2: Data[obj?.BtnPnlObj?.id].P_C50.split("~C~")[1],
      };
      setListobj(objpc50);
      pageformat = objpc50;
    }
  }, [Data, isLoading, hasError]);

  useEffect(() => {
    if (modalClosed == true) {
      let arr = {
        email: [],
        mobile: []
      }
      if (Object.keys(rowSelection)?.length > 0) {
        Array.from(Object.keys(rowSelection)).map((item) => {
          arr?.email?.push(accList?.P_M02[item]?.FIELD43);
          arr?.mobile?.push(accList?.P_M02[item]?.FIELD35);
          setPrintDetails({ ...printDetails, email: arr?.email, mobile: arr?.mobile })
        })
      } else {
        setPrintDetails({ ...printDetails, email: [], mobile: [] });
      }
    }

  }, [modalClosed, rowSelection]);

  const handlePDFPrint = (pdfBlob) => {
    try {
      // Create a URL for the blob
      const blobUrl = URL.createObjectURL(pdfBlob);

      // Open the PDF in a new tab
      const newWindow = window.open(blobUrl);

      // Wait for the new window to load the PDF before triggering print
      if (newWindow) {
        newWindow.addEventListener("load", () => {
          newWindow.focus();
          newWindow.print();
        });
      }

      // Revoke the object URL after printing
      newWindow.addEventListener("beforeunload", () => {
        URL.revokeObjectURL(blobUrl);
        newWindow.removeEventListener("load");
        newWindow.removeEventListener("beforeunload");
      });
    } catch (e) {
      console.log("window Error", e);

      GlobalClass.Notify("warning", "Error Occured");
    }
  };
  const renderExtraForm = (r) => {
    return (
      <Grid>
        {["f", "w", "e"].includes(r) && (
          <Grid.Col span={12}>
            <Card shadow="sm" padding="lg" radius="md" withBorder>
              <Grid>
                <Grid.Col span={3}>Type</Grid.Col>
                <Grid.Col span={6}>
                  <PLComboBox
                    dispexpr={"value"}
                    valexpr={"value"}
                    value={printDetails.type}
                    setEdit={(e) => {
                      setPrintDetails({ ...printDetails, type: e });
                    }}
                    data={[
                      { value: "PDF" },
                      { value: "Word" },
                      { value: "Excel" },
                      { value: "HTML" },
                      { value: "XML" },
                      { value: "PowerPoint" },
                      { value: "CSV" },
                    ]}
                  />
                </Grid.Col>
                <Grid.Col span={3}></Grid.Col>
              </Grid>
              {["w", "e"].includes(r) && (
                <Grid>
                  <Grid.Col span={3}>
                    {r == "w" ? "Whatsapp Number" : "Email Address"}
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <PLTextBox
                      value={
                        r == "w" ? printDetails.mobile : printDetails.email
                      }
                      setEdit={(e) => {
                        r == "w"
                          ? setPrintDetails({
                            ...printDetails,
                            mobile: e.target.value,
                          })
                          : setPrintDetails({
                            ...printDetails,
                            email: e.target.value,
                          });
                      }}
                    />
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <Button compact onClick={bookDataCalled}>
                      {r == "w" ? "Phone Book" : "eMail Book"}
                    </Button>
                  </Grid.Col>
                </Grid>
              )}
              <Grid>
                <Grid.Col span={3}>File Name</Grid.Col>
                <Grid.Col span={6}>
                  <PLTextBox
                    value={printDetails?.filename}
                    setEdit={(e) => {
                      console.log("filename", e.target.value);

                      setPrintDetails({
                        ...printDetails,
                        filename: e.target.value,
                      });
                    }}
                  />
                </Grid.Col>
                <Grid.Col span={3}></Grid.Col>
              </Grid>
              <Grid>
                {" "}
                <Grid.Col span={3}>Copies</Grid.Col>
                <Grid.Col span={3}>
                  <PLTextBox
                    value={printDetails.Copies}
                    type="number"
                    setEdit={(e) => {
                      setPrintDetails({
                        ...printDetails,
                        Copies: e.target.value,
                      });
                    }}
                  />
                </Grid.Col>
                <Grid.Col span={6}></Grid.Col>
              </Grid>
              {printDetails.type == "PDF" && (
                <Grid>
                  {" "}
                  <Grid.Col span={3}>Password</Grid.Col>
                  <Grid.Col span={1}>
                    <Checkbox
                      checked={printDetails.isPassword}
                      onChange={(e) => {
                        setPrintDetails({
                          ...printDetails,
                          isPassword: !printDetails.isPassword,
                        });
                      }}
                    />
                  </Grid.Col>
                  <Grid.Col span={8}>
                    <PLTextBox
                      value={printDetails.password}
                      disabled={!printDetails.isPassword}
                      setEdit={(e) => {
                        setPrintDetails({
                          ...printDetails,
                          password: e.target.value,
                        });
                      }}
                    />
                  </Grid.Col>
                </Grid>
              )}
            </Card>
          </Grid.Col>
        )}
      </Grid>
    );
  };
  const toLowerCaseKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map(toLowerCaseKeys);
    } else if (obj !== null && typeof obj === "object") {
      return Object.keys(obj).reduce((acc, key) => {
        acc[key.toLowerCase()] = toLowerCaseKeys(obj[key]);
        return acc;
      }, {});
    }
    return obj;
  };

  const getReport = async () => {
    const reportPath = {
      CorpID: gensetting.getcorpid(),
      cUser: gensetting.getcuser(),
      cPass: gensetting.getcpass(),
      CmpNo: gensetting.getcmpno(),
      cCode: selected,
    }; // Replace with your actual report path
    // setIsPrintLoading(true);
    try {
      return fetch(GlobalClass.ApiUrl + "Vouformat/GetS92Src", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          CorpID: gensetting.getcorpid(),
          cUser: gensetting.getcuser(),
          cPass: gensetting.getcpass(),
          CmpNo: gensetting.getcmpno(),
          cCode: obj?.id,
          cOPara: selected,
          cSData: "",
          cAction: "",
          YrNo: localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
        }),
      })
        .then((res) => res.json())
        .then(async (e) => {
          if (e.status == "SUCCESS") {
            var dt = e?.data?.xmlsrc;
            var grp = e?.data?.dataset;
            let formattedData = grp.map((baseItem) => {
              let section = dt[baseItem?.trim()?.toUpperCase()];
              return {
                value: section
                  ? Array.isArray(section)
                    ? toLowerCaseKeys(section)
                    : section != {}
                      ? [toLowerCaseKeys(section)]
                      : []
                  : [],
                name: baseItem?.trim()?.toLowerCase(),
              };
            });
            // console.log("formattedData",formattedData);
            let res = {
              reportAction: "ReportLoad",
              isReloadReport: false,
              controlId: "reportviewer-container",
              reportPath: reportPath,
              enableVirtualEvaluation: false,
              reportServerUrl: "",
              processingMode: "Local",
              locale: "en-US",
              enableSearchText: false,
              accessInternalValue: false,
              customBrandSettings: {
                hideHelpLink: false,
                customDomain: "https://help.boldreports.com",
                customBrandName: "Bold Reports",
                customLinks: [
                  {
                    name: "ESLicenseMessage",
                    url: "/licensing/license-token/",
                  },
                ],
              },
            };
            return GetReportProcess(res).then((e) => {
              if (e.status) {
                res = {
                  reportAction: "GetPageModel",
                  refresh: false,
                  dataRefresh: false,
                  pageindex: 1,
                  pageInit: false,
                  isPrint: true,
                  dataSources: formattedData,
                  parameters: null,
                  reportViewerToken: e.data.reportViewerToken,
                  reportViewerClientId: e.data.reportViewerClientId,
                };
                return GetReportProcess(res).then((i) => {
                  if (i.status) {
                    res = {
                      reportAction: "ClearObjects",
                      reportViewerToken: e.data.reportViewerToken,
                      previousAction: "GetPageModel",
                      isPrint: true,
                      reportViewerClientId: e.data.reportViewerClientId,
                    };
                    return GetReportProcess(res).then((j) => {
                      if (j.status) {
                        res = {
                          reportAction: "Export",
                          controlID: e.data.reportViewerToken,
                          resourcetype: ["f", "w", "e"].includes(ReportOn)
                            ? printDetails.type
                            : "PDF",
                          serviceAuthorizationToken: "",
                          isPrint: false,
                          reportName: printDetails.filename,
                          pageindex: 1,
                          imageQuality: 2,
                          exportSetup: {
                            CommonOptions: {
                              ImageQuality: 2,
                              FileName: printDetails.filename,
                              UsePrintSizes: false,
                            },
                            PDFOptions: {
                              EnableComplexScript: false,
                              PdfConformanceLevel: 0,
                              Security: {
                                UserPassword: printDetails.isPassword
                                  ? printDetails.password
                                  : "",
                              },
                            },
                            ExcelOptions: {
                              ExcelSaveType: 3,
                              ExcelSheetProtection: 0,
                              ReadOnlyRecommended: false,
                              DisableCellFormat: 0,
                              LayoutOption: 0,
                              PasswordToOpen: null,
                              PasswordToModify: null,
                            },
                            WordOptions: {
                              FormatType: 2,
                              ProtectionType: 0,
                              LayoutOption: 0,
                              ParagraphSpacing: {
                                Top: 1,
                                Bottom: 1,
                              },
                              EncryptionPassword: null,
                              ProtectionPassword: null,
                            },
                            exportOptions: 255,
                            customItems: [],
                            CsvOptions: {
                              Qualifier: '"',
                              FieldDelimiter: ",",
                              UseFormattedValues: false,
                            },
                            HTMLOptions: {
                              HidePageSeparator: false,
                            },
                            PPTOptions: {
                              FormatType: 0,
                              EncryptionPassword: null,
                            },
                            SecurityOptions: {
                              EnableProtection: false,
                              Protect: null,
                              Edit: null,
                            },
                          },
                          PageSetup: null,
                          reportViewerToken: e.data.reportViewerToken,
                          reportViewerClientId: e.data.reportViewerToken,
                        };
                        return GetReportProcess(res, true).then((main) => {
                          if (main.status) {
                            console.log("mainpdflog", main.data);
                            res = {
                              reportAction: "ClearObjects",
                              reportViewerToken: e.data.reportViewerToken,
                              previousAction: "Export",
                              isPrint: true,
                              reportViewerClientId: e.data.reportViewerClientId,
                            };
                            GetReportProcess(res);
                            // saveAs(main.data,printDetails.filename)
                            // setIsPrintLoading(false);
                            return {
                              status: true,
                              blob: main.data,
                              msg: "error Occurred!!",
                            };
                          } else {
                            return {
                              status: false,
                              blob: "",
                              msg: "error Occurred!!",
                            };
                          }
                        });
                      } else {
                        return {
                          status: false,
                          blob: "",
                          msg: "error Occurred!!",
                        };
                      }
                    });
                  } else {
                    return { status: false, blob: "", msg: "error Occurred!!" };
                  }
                });
              } else {
                return { status: false, blob: "", msg: "error Occurred!!" };
              }
            });
          } else {
            return { status: false, blob: "", msg: "error Occurred!!" };
          }
        })
        .catch((e) => {
          // setIsPrintLoading(false);
          return { status: false, blob: "", msg: "error Occurred!!" };
        });
    } catch (error) {
      console.error("Error exporting the report:", error);
      // setIsPrintLoading(false);
      return { status: false, blob: "", msg: "error Occurred!!" };
    }
  };
  const GetReportProcess = async (req, final = false) => {
    const response = await fetch(reportUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...req, YrNo: localStorage.getItem("YrNo")?.replace(/[\\"]/g, '') }),
    });
    if (!response.ok) {
      return { status: false, data: "", msg: "error Occurred!!" };
    }
    let dt = "";
    if (final) {
      dt = await response.json();
      var formdata = new FormData();
      formdata.append("reportAction", "Export");
      formdata.append("controlID", req.controlID);
      formdata.append("serviceAuthorizationToken", "");
      formdata.append("isPrint", false);
      formdata.append("downloadreportid", dt.downloadreportid);
      formdata.append("sessionToken", "");
      formdata.append("serverUrl", "");
      formdata.append("YrNo", localStorage.getItem("YrNo")?.replace(/[\\"]/g, ''));
      let fileDownload = await fetch(
        GlobalClass.ApiUrl + "ReportViewer/PostFormReportAction",
        {
          method: "POST",
          body: formdata,
        }
      );
      if (fileDownload.ok) {
        let file = await fileDownload.blob();
        return { status: true, data: file, msg: "error Occurred!!" };
      }
    } else {
      dt = await response.json();
    }

    return { status: true, data: dt, msg: "error Occurred!!" };
  };
  const validateFWE = (e) => {
    let error = false;
    let msg = "";
    if (!printDetails.filename || !printDetails.filename.length >= 1) {
      error = true;
      msg = "Filename is Required";
    } else if (["w", "e"].includes(e)) {
      if (
        e == "w" &&
        !printDetails.mobile &&
        !printDetails.mobile.length == 10
      ) {
        error = true;
        msg = "Whatsapp Number is Required";
      }
      if (e == "e" && !printDetails.email) {
        error = true;
        msg = "eMail Address is Required";
      }
    } else if (printDetails.type == "PDF") {
      if (
        printDetails.isPassword &&
        (!printDetails.password || printDetails.password.length <= 5)
      ) {
        error = true;
        msg = "Password is Required ~ Password length must be Greater than 6";
      }
    }
    return { status: error, msg: msg };
  };
  const handleFWE = async (e) => {
    let validate = validateFWE(e);
    if (validate.status) {
      GlobalClass.Notify(
        "error",
        validate.msg.split("~")[0],
        validate.msg.split("~")[1]
      );
      return;
    }
    setIsPrintLoading(true);
    let r = await getReport();
    switch (e) {
      case "f":
        if (r.status) {
          if (printDetails.Copies > 0) {
            for (let i = 0; i < printDetails.Copies; i++) {
              saveAs(r.blob, printDetails.filename + (i > 0 ? "-" + i : ""));
            }
          }

          // saveAs(r.blob,printDetails.filename);
        }
        break;
      case "w":
        break;
      case "e":
        break;
      case "p":
        handlePDFPrint(r.blob);
      default:
        break;
    }
    setIsPrintLoading(false);
  };
  const handlePrinting = () => {
    switch (ReportOn) {
      case "s":
        Store.dispatch(
          ModalFunction({
            MTitle: <Text fw={700}>{"Print Report"}</Text>,
            MAction: true,
            MSize: "100%",
            // position:'right',
            fullScreen: true,
            MBody: (i) => (
              <ReportViewerTest
                cCode={pageid}
                cOPara={selected}
                Data={Data[globalid].final}
              />
            ), //<NewReportViewer obj={obj} index={i} />,
            MClose: true,
            onclose: () => { },
            onClickCloseButton: () => {
              // Store.dispatch(GetInvTypeDropDown(null))
            },
          })
        );
        break;
      case "p":
      case "f":
      case "w":
      case "e":
        handleFWE(ReportOn);
        break;
      default:
        break;
    }
  };

  const bookDataCalled = () => {
    // setbookDataCalledState(true);
    dispatch(
      GetAccountList({
        id: "P_M02",
        name: "",
        p0: "P_M02",
        p1: "P_M02",
        p2: "",
        p3: "",
        type: "A",
      })
    );
    setDrawerObj({
      ...DrawerObj, title: <Text fw={700}>Address Book</Text>, open: true,
      size: "70%",
      position: "right",
    });
  };

  useEffect(() => {
    let Printer = JSON.parse(localStorage.getItem("Printer"))
    if (Printer) {
      let FilterData = Printer.filter((f) => f.companyNm === gensetting.getcmpno())
      // console.log("FilterData", FilterData)
      setPrinterData(FilterData)
    }
  }, [])




  const table = useMantineReactTable({
    columns,
    data: data ? data : [],
    mantineTableContainerProps: {
      sx: { maxHeight: "350px", minHeight: "300px" },
    },
    enableTopToolbar: false,
    enablePagination: true,
    enableRowSelection: true,
    enableMultiRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onPaginationChange: setPagination, //hoist pagination state to your state when it changes internally
    mantineTableBodyRowProps: ({ row, staticRowIndex }) => ({
      sx: { cursor: "pointer" },
    }),
    state: { pagination: pagination, showLoadingOverlay: AccNameisLoading?.P_M02, rowSelection: rowSelection },
    initialState: {
      density: "0px",
      pagination: pagination
    }
  });

  return (
    <>
      {isLoading
        ? GlobalClass.RenderLoader("dots")
        : listObj && (
          <>
            <Modal
              opened={DrawerObj?.open}
              // onClose={close}
              withCloseButton={true}
              fullScreen={false} //
              // overlayProps={{ opacity: 0.5, blur: 4 }}
              closeButtonProps={{
                onClick: (e) => {
                  setModalClosed(true);
                  if (typeof DrawerObj?.onClickCloseButton == "function") {
                    DrawerObj?.onClickCloseButton();
                  } else {
                    setDrawerObj(dra);
                  }
                },
              }}
              title={DrawerObj.title}
              size={DrawerObj.size}
              // centered={.Centered}
              closeOnClickOutside={true}
              // overlayProps={m.Overlay}
              position={DrawerObj?.position ?? "bottom"}
              onClose={
                typeof DrawerObj?.onclose == "function"
                  ? DrawerObj?.onclose
                  : () => {
                    setDrawerObj(dra);
                  }
              }

                // scrollAreaComponent={ScrollArea.Autosize}
              >
                 <MantineReactTable table={table} />
              </Modal>{" "}
              <Grid>
                <Grid.Col span={6}>
                  <DataGrid
                    ValueEdit={(e) => {
                      console.log("ValueEdit", e);
                      setSelected(e?.FIELD00);
                    }}
                    setPopOver={() => {}}
                    obj={listObj}
                    // data={Data[obj?.BtnPnlObj?.id]?.P_C50DT}
                    form={false}
                    isFormatDropdown={false}
                    isRefresh={false}
                    isButton={false}
                    isPagination={false}
                    size={"0.6"} // deval
                    btnName="Print"
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Card padding="lg">
                    <Grid span={12}>
                      <Grid.Col span={3}>Report On</Grid.Col>
                      <Grid.Col span={6}>
                        <PLComboBox
                          value={ReportOn}
                          data={[
                            { label: "Screen", value: "s" },
                            { label: "Printer", value: "p" },
                            { label: "File", value: "f" },
                            { label: "Email", value: "e" },
                            { label: "Whatsapp", value: "w" },
                          ]}
                          dispexpr={"label"}
                          valexpr={"value"}
                          setEdit={(e) => {
                            setReportOn(e);
                          }}
                        />
                      </Grid.Col>
                      <Grid.Col span={3} />
                    </Grid>
                  </Card>
                  {ReportOn && renderExtraForm(ReportOn)}

                <Card padding="lg">
                  <Grid style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Grid.Col span={9} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                      {
                        ReportOn === "p" ?
                          printerData && printerData?.length > 0 &&
                          <>
                            <Grid.Col span={4}>
                              <Text size={12}>Select Printer</Text>
                            </Grid.Col>
                            <Grid.Col span={8}>
                              <PLComboBox
                                data={printerData}
                                dispexpr="title"
                                valexpr="title"
                                value={selectedPrinter}
                                setEdit={(e) => {
                                  setSelectedPrinter(e)
                                }}
                              />
                            </Grid.Col>
                          </> : ""
                      }
                    </Grid.Col>
                    <Grid.Col span={3}>
                      <Button
                        onClick={() => {
                          handlePrinting();
                        }}
                        loading={isPrintLoading}
                        fullWidth
                        compact
                      >
                        Print
                      </Button>
                    </Grid.Col>
                  </Grid>
                </Card>
              </Grid.Col>
            </Grid>
          </>
        )}
    </>
  );
}
export function PrintingFunc(data) {
  const OBJ = JSON.parse(data);
  console.log("dataOBJ=>", globalid);
  const { hasError, ErrorMsg, Data } = Store.getState().ReportDesigner; //useSelector((state) => state?.ReportDesigner);
  const { TMFormatData: tmList } = Store.getState().AccountList;

  switch (OBJ.p0) {
    case "A":
      Store.dispatch(
        ModalFunction({
          MTitle: <Text fw={700}>{"Create New Report Format"}</Text>,
          MAction: true,
          MSize: "100%",
          // position:'right',
          fullScreen: true,
          MBody: (i) => (
            <MyReportDesigner
              modalIndex={i}
              Data={Data[globalid]?.final}
              cCode={tmList[globalid]?.oS60?.cS92ID}
              getData={() => {
                Store.dispatch(GetAccountList(pageformat));
              }}
            />
          ), //<NewReportViewer obj={obj} index={i} />,
          MClose: true,
          onclose: () => { },
          onClickCloseButton: () => {
            // Store.dispatch(GetInvTypeDropDown(null))
          },
        })
      );
      break;
    case "E":
      Store.dispatch(
        ModalFunction({
          MTitle: (
            <Text fw={700}>
              {"Edit Report Format: " + OBJ.DataGridRow.FIELD04}
            </Text>
          ),
          MAction: true,
          MSize: "100%",
          // position:'right',
          fullScreen: true,
          MBody: (i) => (
            <MyReportDesigner
              modalIndex={i}
              Data={Data[globalid]?.final}
              cCode={tmList[globalid]?.oS60?.cS92ID}
              edit={OBJ.DataGridRow.FIELD00}
              getData={() => {
                Store.dispatch(GetAccountList(pageformat));
              }}
            />
          ), //<NewReportViewer obj={obj} index={i} />,
          MClose: true,
          onclose: () => { },
          onClickCloseButton: () => {
            // Store.dispatch(GetInvTypeDropDown(null))
          },
        })
      );
      break;
    case "P":
      Store.dispatch(
        ModalFunction({
          MTitle: <Text fw={700}>{"Print Report"}</Text>,
          MAction: true,
          MSize: "100%",
          // position:'right',
          fullScreen: true,
          MBody: (i) => (
            <ReportViewerTest
              cCode={pageid}
              cOPara={OBJ.DataGridRow.FIELD00}
              Data={Data[globalid]?.final}
            />
          ), //<NewReportViewer obj={obj} index={i} />,
          MClose: true,
          onclose: () => { },
          onClickCloseButton: () => {
            // Store.dispatch(GetInvTypeDropDown(null))
          },
        })
      );
      break;
    case "D":
      GlobalClass.Confirm(
        "red",
        "Are you Sure to Delete This Report?",
        "It will be Deleted Forever and Cannot Be Recovered!!!",
        () => {
          GlobalClass.Notify(
            "info",
            "Deleting Report Format",
            OBJ.DataGridRow.FIELD04,
            true,
            false,
            "delrep"
          );
          let param = {
            CorpID: gensetting.getcorpid(),
            cUser: gensetting.getcuser(),
            cPass: gensetting.getcpass(),
            CmpNo: gensetting.getcmpno(),
            cCode: OBJ.DataGridRow.FIELD00,
            cAction: "D",
            cOPara: "",
          };

          fetch(
            GlobalClass.ApiUrl +
            "Vouformat/GetC50Ent?pa=" +
            JSON.stringify(param)
          )
            .then((res) => res.json())
            .then((e) => {
              if (e.status == "SUCCESS") {
                GlobalClass.Notifyupdate(
                  "success",
                  "Deleted Successfully",
                  "",
                  false,
                  3000,
                  "delrep"
                );
                Store.dispatch(
                  GetReportDesignerData({
                    id: globalid,
                    cCode: tmList[globalid]?.oS60?.cS92ID,
                  })
                );
              } else {
                GlobalClass.Notifyupdate(
                  "error",
                  "Error Deleting Report Format",
                  e.message,
                  false,
                  3000,
                  "delrep"
                );
              }
            })
            .catch((e) => {
              GlobalClass.Notifyupdate(
                "error",
                "Error Deleting Report Format",
                "Network Error!!",
                false,
                3000,
                "delrep"
              );
            });
        },
        () => { }
      );
      break;
    default:
      break;
  }
  // Store.dispatch(
  //   ModalFunction({
  //     onclose: (e) => { },
  //     MTitle: <Text fw={700}>{OBJ?.text}</Text>,
  //     MAction: true,
  //     MSize: "xl",
  //     position:"right",
  //     MBody: (i) => <PLPrint data={OBJ} index={i} />,
  //     MClose: true,
  //     Overlay: {
  //       backgroundOpacity: 0.8,
  //       blur: 5,
  //     },
  //   })
  // );
}
