

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import GlobalClass from '../GlobalClass';
import gensetting from '../gensetting';
import axios from 'axios';
import Store from '../store';

export const GetFormatList = createAsyncThunk(
    "FormatSlice/GetFormatList",
    async (obj) => {
        console.log("FormatSlice", obj)
        try {
            let param = JSON.stringify({
                CorpID: gensetting.getcorpid(),
                cUser: gensetting.getcuser(),
                cPass: gensetting.getcpass(),
                CmpNo: gensetting.getcmpno(),
                cRepId: obj?.cSTID ?? "",
                cFmtID: "",
                "Yrno": localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
            });
            const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.GetFormatList + "?pa=" + param);
            return response.data.data;

        } catch (error) {
            GlobalClass.Notify("error", "Error", error.message);
            return { error };
        }
    }
)

export const GetFormatEnv = createAsyncThunk(
    "FormatSlice/GetFormatEnv",
    async (obj) => {
        console.log("GetFormatEnv", obj)
        try {
            let param = JSON.stringify({
                CorpID: gensetting.getcorpid(),
                cUser: gensetting.getcuser(),
                cPass: gensetting.getcpass(),
                CmpNo: gensetting.getcmpno(),
                cRepId: obj?.cRepId ?? "",
                cFmtID: obj?.cFmtID ?? "",
                cAction: obj?.cAction ?? "",
                "Yrno": localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
            });
            const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.GetFormatEnv + "?pa=" + param);
            return response.data.data;
        } catch (error) {
            GlobalClass.Notify("error", "Error", error.message);
            return { error };
        }
    }
)

export const DeleteFormatEnv = createAsyncThunk(
    "FormatSlice/DeleteFormatEnv",
    async (obj) => {
        GlobalClass.Notify(
            "info",
           "Deleting",
            "Please wait while we process your data"
          );
    // console.log("DeleteFormatEnv =>", obj);
          try {
            let param = JSON.stringify({
                CorpID: gensetting.getcorpid(),
                cUser: gensetting.getcuser(),
                cPass: gensetting.getcpass(),
                CmpNo: gensetting.getcmpno(),
                cRepId: obj?.cRepId ?? "",
                cFmtID: obj?.cFmtID ?? "",
                cAction: obj?.cAction ?? "",
                "Yrno": localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
            });
            const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.GetFormatEnv + "?pa=" + param);
            // return response.data.data;
            if (response.data.status === "SUCCESS") {
                GlobalClass.Notify("success", "Deleted", "Deleted successfully");
                Store?.dispatch(GetFormatList(obj?.data))
              } else {
                GlobalClass.Notify(
                  "error",
                  response?.data?.status,
                  response?.data?.message
                );
              }
          } catch (error) {
            GlobalClass.Notify("error", "Error", error.message);
            return { error };
          }
    }
)

const FormatSlice = createSlice({
    name: "FormatSlice",
    initialState: {
        Data: [],
        Original: {},
        AccData: [],
        isLoading: true,
        hasError: false,
        ErrorMsg: {},
        isFilterApplied: false
    },
    reducers: {
        // getFiltersName: (state, action) => {
        //   console.log("action", action.payload)

        //   if (typeof action.payload === 'object' && !Array.isArray(action.payload) && action.payload !== null) {
        //     const key = Object.keys(action.payload)[0];
        //     const value = action.payload[key];

        //     console.log("key", key, "value", value);
        //     state.FiltersName = { ...state.FiltersName, [key]: value }
        //   }
        //   else {
        //     delete state?.FiltersName[action.payload]
        //     console.log("else");
        //     console.log("FiltersName", state.FiltersName);

        //   }
        // },
        // getFilterStatus: (state, action) => {
        //   state.isFilterApplied = action.payload
        // },
        // getDataStateNull :(state, action)=>{
        //   state.Data = {}
        // }
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetFormatList.pending, (state, action) => {
                state.hasError = false;
                state.isLoading = true;

            })
            .addCase(GetFormatList.fulfilled, (state, action) => {
                if (!action.payload?.error) {
                    console.log("fulfilled", action.payload);

                    state.Data = action.payload
                    // state.Data = {
                    //   ...state.Data,
                    //   [action.payload[1]]: action.payload[0],
                    // };
                    // state.Original = {
                    //   ...state.Original,
                    //   [action.payload[1]]: action.payload[2],
                    // } 
                    state.isLoading = false;
                    state.hasError = false;
                    state.ErrorMsg = null;
                } else {
                    state.isLoading = false;
                    state.hasError = true;
                    state.ErrorMsg = action.payload?.error;
                }

                // state.OneProductData = {};
            })
            .addCase(GetFormatList.rejected, (state, action) => {
                state.hasError = true;
                state.isLoading = false;
            })
            .addCase(GetFormatEnv.pending, (state, action) => {
                state.hasError = false;
                state.isLoading = true
            })
            .addCase(GetFormatEnv.fulfilled, (state, action) => {
                if (!action.payload?.error) {
                    console.log("fulfilled", action.payload);

                    state.AccData = action.payload
                    state.isLoading = false;
                    state.hasError = false;
                    state.ErrorMsg = null;
                } else {
                    state.isLoading = false;
                    state.hasError = true;
                    state.ErrorMsg = action.payload?.error;
                }
            })
            .addCase(GetFormatEnv.rejected, (state, action) => {
                state.hasError = true;
                state.isLoading = false;
            })
    }

});
export default FormatSlice.reducer;
