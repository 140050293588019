import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetM01List, getAccountName } from "../../utils/slices/M01FormSlice";
import {
  Button,
  Card,
  Drawer,
  Grid,
  Group,
  Modal,
  Skeleton,
  Tabs,
  Text,
  TextInput,
} from "@mantine/core";
import PLTextBox from "../../PlusComponent/PLTextBox";
import PLComboBox from "../../PlusComponent/PLComboBox";
import PLNumberBox from "../../PlusComponent/PLNumberBox";
import { modals } from "@mantine/modals";
import Localize from "../../utils/rc";
import DataGrid from "../../components/DataGrid";
import PLDataGrid from "../../PlusComponent/PLDataGrid";
import { ModalFunction } from "../../utils/slices/ModalSlice";
import AddUserField from "../UserField/AddUserField";
import PLDateBox from "../../PlusComponent/PLDateBox";
import GlobalClass from "../../utils/GlobalClass";
import CapitalAccountTable from "./CapitalAccountTable";
import gensetting from "../../utils/gensetting";
import { notifications } from "@mantine/notifications";
import {
  GetAccountList,
  getGroupEdited,
} from "../../utils/slices/AccountListSlice";
import { ModalDelete } from "../../utils/slices/ModalSlice";
import { DataSaveLoading } from "../../utils/slices/DataSaveLoadingSlice";
import Store from "../../utils/store";
import { DateInput } from "@mantine/dates";
import PLBillToBill from "../../PlusComponent/PLBillToBill";
import CheckGst from "./CheckGst";
import { TabRemove } from "../../utils/slices/TabList";
var capitalJSON = [];
var CAPITAL_ACCOUNT = "CP";
var DEPRECIATION_ACCOUNT = "FA";
var UNSECURED_ACCOUNT = "UL";
var PURCHASE_ACCOUNT = "TP";
var PARTY_ACCOUNT = "PR";
var TRADING = "T";
var STOCK_IN_HAND = "G0000008";
var SALES_ACCOUNT = "TS";
var DUTIES_TAXES = "TX";
var FIXED_ASSET = "FA";
var LOAN_LIABILITY = "LI";

var lAddressDetail, lPRDetail, lDepreciation;
var lType,
  lTypeUpdated,
  lType_tsortp,
  lTaxType,
  lFATaxtType,
  lMulCur,
  lCostCen,
  lTradingAC,
  lSecTaxType,
  lIO_1,
  lIO_2,
  lGroupName,
  G_lIO,
  V_lIO,
  lVATIO,
  VorC,
  lGSTIN,
  lBankRecons, //start
  lPANNo,
  lTDSPOG,
  lDepreciation,
  cmdInterest,
  cmdAddress,
  lBtoB,
  cmdCapital,
  lBtoB;
var lTDS, lTCS, WLTOD, lTrade;
var FelDeductee, lTDSlower, lTDSrate, lTDSsurcharge, lTDSlimit, TDSratedisabled;
var cmdTDSbtn,
  cmdTCSbtn,
  cmdInterest,
  cmdAddress,
  UfieldBtn,
  cmdCapital,
  cmdBtoB,
  cmdDepreciation;
var lNPMT, lNOG;
let selectedRow = {};
let VorCRow = {};
var visibleArr = [
  "cboTDS",
  "cboTCS",
  "TDSPOG",
  "costCen",
  "bankRecons",
  "type",
  "typeUpdated",
  "type_tsortp",
  "txtTaxType",
  "txtSecTaxType",
  "FAtxtTaxType",
  "wlNOG",
  "txtNPMT",
  "cboVatType",
  "V_I/OCombo",
  "G_I/OCombo",
  "deprecition",
  "txtArea",
  "txtCreditLimits",
  "txtCity",
  "txtState",
  "txtVatNo",
  "txtAddhar",
  "txtPANNo",
  "txtCSTNo",
  "txtGroupName",
  "txtDLNo",
  "balanceMethod",
  "txtCreditDays",
  "cboChallan",
  "txtGSTIN",
  "cboRegType",
  "mulCur",
  "tradingAC",
  "cboBillType",
  "cboRateType",
  "txtBSeries",
  "txtDisc1",
  "txtdiscper",
  "txtLAmt",
  "txtDisc2",
];
var tabDisabledArr = [
  "CAPTDS",
  "PLUSM500",
  "INTEREST",
  "_F020009",
  "B100",
  "UFIELD",
];
function DateToString(e) {
  // console.log(e, "Datetostring");
  let year = e.toLocaleString("en-IN", { year: "numeric" });
  let month = e.toLocaleString("en-IN", {
    month: "2-digit",
  });
  let day = e.toLocaleString("en-IN", { day: "2-digit" });

  let formatedStartDate = day + "/" + month + "/" + year;
  let startDate = year + month + day;
  return { formatedStartDate, startDate };
}

let M01 = {};
let M02 = {};
let M48 = [];
let M48I = {};
let M48D = {};
let M48TC = {};
let M48TD = {};
let M54 = {};
let UFM01 = [];
let M48CGrd = [];
// console.log("M01 FormData", M01);

const M01Forms = (props) => {
  let rc = new Localize().rc;
  console.log("M01Forms props==>", props);

  const dispatch = useDispatch();
  const GetGeneral = useSelector(
    (state) => state?.Language?.General?.oYear?.OSC
  );
  const isLoading = useSelector((state) => state.M01FormSlice.isLoading);
  const hasError = useSelector((state) => state?.M01FormSlice?.hasError);
  const ErrorMsg = useSelector((state) => state?.M01FormSlice?.ErrorMsg);
  const M01FormData = useSelector((state) => state.M01FormSlice.M01FormData);
  const SaveLoading = useSelector(
    (state) => state?.DataSaveLoadingSlice?.SaveLoading
  );
  const accountName = useSelector((state) => state?.M01FormSlice?.accountName);
  const T05 = useSelector((state) => state.M01FormSlice?.M01FormData?.T05);
  const inputRef = useRef();
  const inputRef1 = useRef();

  const inputRef2 = useRef();
  const contactRef = useRef();
  const bankRef = useRef();
  // console.log("Getgeneral", GetGeneral);
  // const groupEdited = useSelector((state) => state.M01FormSlice.groupEdited);
  // const [M01, setM01] = useState(null);
  // const [M02, setM02] = useState(null);
  // const [M48, setM48] = useState(null);
  // const [M48I, setM48I] = useState(null);
  // const [M48D, setM48D] = useState(null);
  // const [M48TC, setM48TC] = useState(null);
  // const [M48TD, setM48TD] = useState(null);
  // const [M54, setM54] = useState(null);
  // const [UFM01, setUFM01] = useState(null);
  // const [M48CGrd, setM48CGrd] = useState(null);
  // const [G000001A, setG000001A]=useState('');

  // let M01 = {};
  // let M02 = {};
  // let M48 = [];
  // let M48I = {};
  // let M48D = {};
  // let M48TC = {};
  // let M48TD = {};
  // let M54 = {};
  // let UFM01 = [];
  // let M48CGrd = [];

  const [visible, setVisible] = useState(visibleArr);
  const [tabDisabled, setTabDisabled] = useState(tabDisabledArr);
  const [ufPopupHide, setUfPopupHide] = useState(false);
  const [TDSDetail, setTDSDetail] = useState("N");
  const [TCSDetail, setTCSDetail] = useState("N");
  const [taxType, setTaxType] = useState();
  const [creditDebitBM, setCreditDebitBM] = useState();
  const [creditDebit, setCreditDebit] = useState();
  const [validation, setValidation] = useState({
    gst: true,
    aadhar: true,
    pan: true,
  });
  const [showCheckGSTBtn,setShowCheckGSTBTN]=useState(false);
  const [type, setType] = useState();
  const [tdsLowerRate, setTDSLowerRate] = useState(M01?.TDSLOWER);
  const [openingBalance, setOpeningBalance] = useState();
  // console.log("validation",validation);
  // const [visible, setVisible] = useState([]);
  // const [tabDisabled, setTabDisabled] = useState([]);
  const [groupEdited, setGroupEdited] = useState(false);
  const [TDSPOG, setTDSPOG] = useState("N");
  // const [F01Code, setF01Code] = useState(props?.OBJ?.id);
  const [T05DataArray, setT05DataArray] = useState([]);
  const [effDate, setEffDate] = useState(M01?.FIELD81?.trim());
  const [GSTINData, setGSTINData] = useState(null);
  const [activeTab, setActiveTab] = useState(
    props?.OBJ?.p0 == "R" ? rc("_F020009") : rc("A_MAST76")
  );
  const [UFClicked, setUFClicked] = useState(true);
  const [GSTValue, setGSTValue] = useState();
  const [ratetype, setRateType] = useState("N");
  const [b2b, setB2B] = useState();
  // const [PAN,setPAN] = useState();
  const formRef = useRef(null);
  const userFieldRef = useRef(null);
  const groupRef = useRef();
  const categoryRef = useRef();
  const areaRef1 = useRef();
  const cityRef1 = useRef();
  const interestRef = useRef();
  const tdsRef = useRef();
  // const [accountName,setAccountName] = useState("");
  let dra = {
    title: "",
    size: "md",
    body: "",
    open: false,
    onClickCloseBtn: () => {},
  };
  const [DrawerObj, setDrawerObj] = useState(dra);
  useEffect(() => {
    //  console.log("groupEdited changed")
  }, [groupEdited]);

  useEffect(() => {
    if (hasError && !isLoading) {
      GlobalClass.Notify("error", "Error: " + ErrorMsg.code, ErrorMsg.message);
    }
  }, [hasError]);

  useEffect(() => {
    if (props?.OBJ?.p0 == "R") {
      if (
        "G000001A,G0000002,G0000004,G000016A,G0000017,G0000019".includes(
          M01FormData?.M01?.FIELD05
        ) ||
        "G000001A,G0000002,G0000004,G000016A,G0000017,G0000019".includes(
          M01FormData?.M01?.FIELD06
        ) ||
        "PR,BK,CP,LA".includes(M01FormData?.M01?.FIELD07)
      ) {
        setActiveTab(rc("_F020009"));
      } else {
        setActiveTab(rc("A_MAST76"));
      }
    } else {
      setActiveTab(rc("A_MAST76"));
    }
  }, [props?.OBJ?.p0]);

  useEffect(() => {
    // console.log("M01Props", props?.OBJ);
    if (props?.OBJ?.p0 == "A") {
      M01 = {};
      M02 = {};
      M48 = [];
      M48I = {};
      M48D = {};
      M48TC = {};
      M48TD = {};
      M54 = {};
      UFM01 = [];
      M48CGrd = [];
      dispatch(GetM01List({ Skey: "", OBJ: props?.OBJ?.p0 }));
    } else {
      dispatch(GetM01List({ Skey: props?.OBJ?.id, OBJ:  props?.OBJ?.cSTID == "T_M01OB" && props?.OBJ?.text=="Edit" ? "O" : "E" }));
    }
  }, []);
  // console.log("M01FormData", M01FormData);

  useEffect(() => {
    if (T05) {
      setT05DataArray(T05);
    }
  }, [T05]);

  // useEffect(()=>{
  //   if(T05DataArray){
  //     console.log("T05DATAARayya",T05DataArray);}
  // },[T05DataArray])

  useEffect(() => {
    if (props?.OBJ?.p0 == "E") {
      setCreditDebit(M01FormData?.M01?.FIELD80 < 0 ? "D" : "C");
      setGSTValue(M01FormData?.M01?.FIELD35);
      setCreditDebitBM(M01FormData?.M01?.FIELD90CD);
      setShowCheckGSTBTN(M01FormData?.M01?.FIELD35 ? true : false)
    }
    if (props?.OBJ?.p0 == "A") {
      setCreditDebitBM("L,I,C".includes(M01FormData?.M01?.M11F09) ? "C" : "D");
    }
  }, [M01FormData?.M01]);

  // useEffect(() => {
  //     if (M01FormData) {
  //          M01 = M01FormData?.M01;
  //          M02 = M01FormData?.M02;
  //          M48 = M01FormData?.M48;
  //          M48I = M01FormData?.M48I;
  //          M48D = M01FormData?.M48D;
  //          M48TC = M01FormData?.M48TC;
  //          M48TD = M01FormData?.M48TD;
  //          M54 = M01FormData?.M54;
  //          UFM01 = M01FormData?.UFM01;
  //          M48CGrd = M01FormData?.M48CGrd;
  //     }
  //     // console.log('M01FormData ===>', M01FormData);
  // }, [M01FormData])

  const handleUpperCase = () => {
    const inputField = document.getElementById("gstTextBox");

    inputField?.addEventListener("keyup", function (event) {
      event.preventDefault();
      inputField.defaultValue = inputField.defaultValue.toUpperCase();
    });
  };



  const BillToBillTableFun = () => {
    document?.activeElement?.blur();
    Store.dispatch(
      ModalFunction({
        onclose: () => {},
        MTitle: <Text fw={700}>Bill to Bill {}</Text>,  
        MAction: true,
        MSize: "50%",
        fullSize:false,
        // position: "right",
        MBody: (i) => <>
        <PLBillToBill
          T41ObjSend={M01}
          isM01Form={true}
          T05UserField={M01FormData?.UFT05H}
          T05Format={M01FormData?.T05ENT}
          T05Data={T05DataArray}
          setT05Data={(e) => setT05DataArray(e)}
        />
      </>,
        // MBody: (i) => <OpeningStockForm/>,
        onClickCloseButton: () => {document?.getElementById("crlmt")?.focus()},
        MClose: true,
        tab:false,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        },
      })
    );


    // setDrawerObj({
    //   ...dra,
    //   title: <Text fw={700}>Bill to Bill</Text>,
    //   onClickCloseButton: () => {
    //     // setDrawerObj(dra);

    //     Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => {
    //         document?.getElementById("crlmt")?.focus()
    //       });
    //   },
    //   body: (
    //     <>
    //       <PLBillToBill
    //         T41ObjSend={M01}
    //         isM01Form={true}
    //         T05UserField={M01FormData?.UFT05H}
    //         T05Format={M01FormData?.T05ENT}
    //         T05Data={T05DataArray}
    //         setT05Data={(e) => setT05DataArray(e)}
    //       />
    //     </>
    //   ),
    //   open: true,
    //   size: "50%",
    //   position: "right",
    // });

    // modals.openContextModal({
    //   modal: "PLBillToBill",
    //   title: <Text fw={700}>Bill to Bill</Text>,
    //   zIndex: 300,
    //   size: "70%",
    //   withCloseButton: true,
    //   innerProps: {
    //     modalBody: (
    //       <>
    //         <PLBillToBill
    //           T05Format={T05ENT}
    //           T05Data={T05DataArray}
    //           setT05Data={(e) => setT05DataArray(e)}
    //         />
    //       </>
    //     ),
    //   },
    // });
  };

  let allowedFileExtensions = [".jpg", ".jpeg", ".png"];
  let allowedFileExtensionsattchment = [
    ".docx",
    ".xlsx",
    ".xls",
    ".pdf",
    ".txt",
    ".ppt",
    ".xml",
  ];
  String.prototype.includes = function (subStr) {
    if (subStr?.length == 0 || this.indexOf(subStr) === -1) {
      return false;
    }
    return true;
  };
  let islAddressDetail = () => {
    return (
      "G0000002,G0000004,G000001A,G000016A,G0000017,G0000019".includes(
        M01?.FIELD05
      ) ||
      "G0000002,G0000004,G000001A,G000016A,G0000017,G0000019".includes(
        M01?.FIELD06
      ) ||
      "PR,BK,CP,LA".includes(M01?.FIELD07)
    );
  };
  let islPRDetail = () => {
    // console.log(
    //   "M01?.FIELD07",
    //   M01?.FIELD07?.toString() +
    //     "-" +
    //     "PR".includes(M01?.FIELD07)
    // );
    return "PR".includes(M01?.FIELD07);
  };
  let islTDS = () => {
    return "PR,LI,UL,SL".includes(M01?.FIELD07) && GetGeneral?._TDS == "Y"; //&& TDS required
  };
  let islTCS = () => {
    return PARTY_ACCOUNT.includes(M01?.FIELD07) && GetGeneral?._TCSREQD == "Y"; //&& TCSREq
  };
  let islTDSPOG = () => {
    return (
      "PR,LI,UL,SL".includes(M01?.FIELD07) &&
      GetGeneral?._TDS == "Y" &&
      GetGeneral?._TDSONPOG == "Y"
    );
  };
  let islCostCen = () => {
    return (
      "BK,CS,PT,FA,IN,TP,TS,PR".includes(M01?.FIELD07) &&
      GetGeneral?._COSTCENT == "Y"
    );
  };
  let islMulCur = () => {
    return (
      "ET,PT,IN,JP,PR".includes(M01?.FIELD07) && GetGeneral?._MULTICUR == "Y"
    );
  };
  let islTradingAC = () => {
    return (
      GetGeneral?._ITEMSPACC == "Y" &&
      ("T".includes(M01?.FIELD04) || "G0000008".includes(M01?.FIELD05))
    );
  };
  let islGroupName = () => {
    return M01?.FIELD02 != "";
  };
  let islInterest = () => {
    return "UL,PR,CP".includes(M01?.FIELD07);
  };
  let islPANNo = () => {
    return islAddressDetail() || "LI,UL".includes(M01?.FIELD07);
  };
  let islBtoB = () => {
    // console.log("M01?.FIELD07", M01?.FIELD07);
    // if (M01 === 'Capital Account') {
    //     dispatch(ModalFunction({
    //         onclose: () => {
    //             dispatch(ModalFunction({
    //                 MTitle: '',
    //                 MAction: false,
    //                 MSize: 'md',
    //                 MBody: () => (''),
    //                 MClose: false
    //             }))
    //         },
    //         MTitle: null,
    //         MAction: true,
    //         MSize: 'md',
    //         MBody: () => (<>
    //             <h2>Capital Account</h2>
    //         </>),
    //         MClose: false
    //     }))
    // }
    return "PR".includes(M01?.FIELD07) && "2".includes(M01?.FIELD07); // BtoB required
  };
  let islDepreciation = () => {
    return "FA".includes(M01?.FIELD07);
  };
  let islCapital = () => {
    return "CP,RS".includes(M01?.FIELD07);
  };
  let islTrade = () => {
    return (
      TRADING.includes(M01?.FIELD04) || STOCK_IN_HAND.includes(M01?.FIELD05)
    );
  };
  let islType = () => {
    return (
      "TX".includes(M01?.FIELD07) &&
      (GetGeneral?._VATSETUP == "Y" ||
        GetGeneral?._TDS == "Y" ||
        GetGeneral?._GSTSETUP == "Y")
    );
  };
  let islType_tsortp = () => {
    return "TS,TP".includes(M01?.FIELD07);
  };
  let islTypeUpdated = () => {
    return (
      GetGeneral?._GSTSETUP == "Y" && "TS,TP,TI,IN,JP".includes(M01?.FIELD07)
    );
  };
  let islTaxType = () => {
    //'FA
    return (
      "TS,TP,TX,FA".includes(M01?.FIELD07) &&
      (GetGeneral?._VATSETUP == "Y" || GetGeneral?._GSTSETUP == "Y")
    );
  };
  let islFATaxtType = () => {
    return "FA".includes(M01?.FIELD07);
  };
  let islSecTaxType = () => {
    return "V,C,D".includes(M01?.FIELD22) || "FA".includes(M01?.FIELD07);
  };
  let islNPMT = () => {
    return (
      "TX".includes(M01?.FIELD07) &&
      "T".includes(M01?.FIELD22) &&
      !"".includes(M01?.FIELD22) &&
      GetGeneral?._TDS == "Y"
    );
  };
  let islNOG = () => {
    return (
      "TX".includes(M01?.FIELD07) &&
      "S".includes(M01?.FIELD22) &&
      !"".includes(M01?.FIELD22) &&
      GetGeneral?._TCSREQD == "Y"
    );
  };
  let isV_lIO = () => {
    return (
      (GetGeneral?._VATSETUP || GetGeneral?._GSTSETUP) &&
      "TS,TP,TX".includes(M01?.FIELD07) &&
      ("TS,TP".includes(M01?.FIELD07)
        ? "V,C,D,3,4".includes(M01?.FIELD22)
        : "V,3,4,G".includes(M01?.FIELD22) && !"".includes(M01?.FIELD22))
    );
  };
  let isG_lIO = () => {
    return (
      (GetGeneral?._VATSETUP || GetGeneral?._GSTSETUP) &&
      "TS,TP,TX".includes(M01?.FIELD07)
    );
  };
  let islVATIO = () => {
    return "V,C,D".includes(M01?.FIELD22) && !"".includes(M01?.FIELD22); //field03 from P_M51 (popup)
  };

  let islGSTIN = () => {
    return (
      "PR,LI,LA,UL,BK".includes(M01?.FIELD07) ||
      ("PR".includes(M01?.FIELD07) && GetGeneral?._GSTSETUP == "Y")
    );
  };

  let islCRLimit = () => {
    return "PR".includes(M01?.FIELD07) || "BK".includes(M01?.FIELD07);
  };

  let islBankRecons = () => {
    return "BK".includes(M01?.FIELD07);
  };

  const setFormData = () => {
    if (M01FormData && !isLoading) {
      M01 = {
        ...M01FormData?.M01,
        FIELD22:
          M01FormData?.M01?.FIELD22 == ""
            ? GetGeneral?._GSTSETUP == "Y"
              ? 3
              : GetGeneral?._VATSETUP == "Y"
              ? "V"
              : GetGeneral?._TDS == "Y"
              ? "T"
              : "Q"
            : M01FormData?.M01?.FIELD22,
      };
      UFM01 = M01FormData.UFM01;
      M02 = M01FormData.M02;
      M48 = M01FormData.M48;
      M48I = M01FormData.M48I;
      M48D = M01FormData.M48D;
      M48TC = M01FormData.M48TC;
      M48TD = M01FormData.M48TD;
      M54 = M01FormData.M54;
      M48CGrd = M01FormData.M48CGrd;
      setTDSDetail(M01?.M01FTDS);
      setTCSDetail(M01?.FIELD23);
    }else if(M01FormData==null && !isLoading){
      M01=null
    }
  };
  // console.log("M01",M01)

  useEffect(() => {
    Promise.resolve(setFormData()).then(() => {
      if (M01 != null) {
        // console.log("M01==>", M01);
        // console.log("M01.FIELD04==>", M01?.FIELD04);
        let M11E = {
          FIELD06: M01?.FIELD04,
          FIELD01: M01?.FIELD05,
          FIELD05: M01?.FIELD06,
          FIELD08: M01?.FIELD07,
        };
        // console.log("M11E===>", M11E);

        handleonclosed1(M11E);
      }
    });
  }, [M01FormData]);

  const ChkGSTINCheckSum = (cGSTIN) => {
    if (cGSTIN.length !== 15) return false;

    cGSTIN = cGSTIN.toUpperCase();
    let nI = 65;
    let nFVal = 0,
      nTOt = 0,
      nData = 0;
    let cFChar = "\0";
    let aRForSum = new Array(14);
    let cChkSum = cGSTIN[cGSTIN.length - 1];

    let arrABCD = new Array(35).fill(null).map(() => new Array(2));
    for (let i = 1; i <= 35; i++) {
      if (i <= 9) {
        arrABCD[i - 1][0] = i.toString();
        arrABCD[i - 1][1] = i.toString();
      } else {
        arrABCD[i - 1][0] = String.fromCharCode(nI);
        arrABCD[i - 1][1] = i.toString();
        nI++;
      }
    }

    for (let i = 0; i < 14; i++) {
      let cChar = cGSTIN[i];
      let cVal = arrABCD.findIndex((elem) => elem[0] === cChar.toString());
      if (cVal !== -1) {
        if ((i + 1) % 2 === 0) nData = parseInt(arrABCD[cVal][1]) * 2;
        else nData = parseInt(arrABCD[cVal][1]);

        let Quo = Math.abs(Math.floor(nData / 36));
        let rem = Math.abs(nData % 36);
        aRForSum[i] = Quo + rem;
      } else aRForSum[i] = 0;

      nTOt += aRForSum[i];
    }

    nTOt %= 36;

    nFVal = (36 - nTOt) % 36;

    if (nFVal === 0) cFChar = "0";
    else {
      for (let i = 0; i < 35; i++) {
        if (arrABCD[i][1] === nFVal.toString()) {
          cFChar = arrABCD[i][0];
          break;
        }
      }
    }

    return cFChar === cChkSum;
  };
  const handleGstBlur = () => {
    if (M01?.FIELD35.length > 0) {
      const result = ChkGSTINCheckSum(M01?.FIELD35?.trim());
      // setIsValid(result);
      if(result==true){
         setShowCheckGSTBTN(true);
      }else{
        setShowCheckGSTBTN(false);
      }
      setValidation({ ...validation, gst: result });
    } else {
      setValidation({ ...validation, gst: true });
      setShowCheckGSTBTN(false);
    }
    // console.log("GST Validation",result);
  };
  const isPANValid = (cPAN) => {
    // Regular expression for validating PAN card format
    const regex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    return regex.test(cPAN);
  };
  const isAadhaarValid = (cAadhaar) => {
    // Check for 12-digit numeric pattern
    const regex = /^[0-9]{12}$/;
    return regex.test(cAadhaar);
  };

  const handlePAnBlur = () => {
    if (M01?.FIELD34?.trim() == "") {
      setValidation({ ...validation, pan: true });
    } else {
      const result = isPANValid(M01?.FIELD34?.trim()?.toUpperCase()); // Convert to uppercase for uniformity
      setValidation({ ...validation, pan: result });
    }
  };
  const handleAadharBlur = () => {
    if (M01?.FIELD25?.trim() == "") {
      setValidation({ ...validation, aadhar: true });
    } else {
      const result = isAadhaarValid(M01?.FIELD25?.trim()); // Convert to uppercase for uniformity
      setValidation({ ...validation, aadhar: result });
    }
  };
  //   const capitalAccFun = () => {
  //     modals.openContextModal({
  //         modal: "SITableUserField",
  //         title: <Text fw={700}>User Field</Text>,
  //         zIndex: 300,
  //         size: "sm",
  //         withCloseButton: true,
  //         innerProps: {
  //             modalBody: (<>
  //                 <CapitalAccountTable T02UF={T02UF} T02ObjSend={T02Obj} setT02Objsend={(e) => {
  //                     setT02Obj(e)
  //                     // // console.log("TableUserField=>",e);
  //                 }} />
  //             </>)
  //         }
  //     })
  // }
  // const capAcc = (fun2) => {
  //   return (
  //     <CapitalAccountTable
  //       p0={props?.OBJ?.p0}
  //       index={props?.index}
  //       grd={M48CGrd}
  //       value={M48}
  //       UserFieldFun={() => fun2()}
  //       // SelectedRowObj={rowObj}
  //       getM48={(e) => {
  //         M48 = e;
  //         // console.log("getM48 State==>", M48);
  //       }}
  //       DrawerOBJ={DrawerObj}
  //       setDrawerOBJ={(e) => {
  //         setDrawerObj(e);
  //       }}
  //     />
  //   );
  // };
  const functionOne = (e) => {
    // console.log("capital account Popup");
   
      Store.dispatch(
        ModalFunction({
          onclose: () => {},
          MTitle: <Text fw={700}>Capital Account {}</Text>,  
          MAction: true,
          MSize: "md",
          fullSize:false,
          // position: "right",
          MBody: (i) => 
         <CapitalAccountTable
        p0={props?.OBJ?.p0}
        grd={M48CGrd}
        value={M48}
        UserFieldFun={ UFM01.length>0 ? functionTwo(e) : GlobalClass.formNextInput(e,formRef)}
        // SelectedRowObj={rowObj}
        getM48={(e) => {
          M48 = e;
          // console.log("getM48 State==>", M48);
        }}
        index={i}
      />,
          // MBody: (i) => <OpeningStockForm/>,
          onClickCloseButton: () => {UFM01.length>0 ? functionTwo(e) : GlobalClass.formNextInput(e,formRef)},
          MClose: true,
          tab:false,
          Overlay: {
            backgroundOpacity: 0.8,
            blur: 5,
          },
        })
      );
   

    // modals.openContextModal({
    //   modal: "CapitalAccountTable",
    //   title: <Text fw={700}>Capital Account</Text>,
    //   zIndex: 300,
    //   size: "50%",
    //   innerProps: {
    //     modalBody: (
    //       <>
    //         <CapitalAccountTable
    //           grd={M48CGrd}
    //           value={M48}
    //           UserFieldFun={(e) => fun2(e)}
    //           SelectedRowObj={rowObj}
    //           getM48={(e) => {
    //             // console.log("getM48==>", e);
    //             M48 = e;
    //             // console.log("getM48 State==>", M48);
    //           }}
    //         />
    //       </>
    //     ),
    //   },
    // });
    // dispatch(
    //   ModalFunction({
    //     onclose: () => {
    //       dispatch(
    //         ModalFunction({
    //           MTitle: "",
    //           MAction: false,
    //           MSize: "md",
    //           MBody: () => "",
    //           MClose: false,
    //         })
    //       );
    //       // fun2(rowObj)
    //     },
    //     MTitle: "Capital Account",
    //     MAction: true,
    //     MSize: "70%",
    //     MBody: () => (
    //       <>
    //       {// console.log("M48",M48)}
    //         <CapitalAccountTable
    //           grd={M48CGrd}
    //           value={M48}
    //           UserFieldFun={(e) => fun2(e)}
    //           SelectedRowObj={rowObj}
    //           getM48={(e) => {
    //             // console.log("getM48==>", e);
    //             M48 = e;
    //             // setM48(e)
    //             //  setM48([...M48,e])
    //             // console.log("getM48 State==>", M48);
    //           }}
    //         />
    //       </>
    //     ),
    //     MClose: false,
    //   })
    // );
  };

  const functionTwo = (e="") => {
      
      Store.dispatch(
        ModalFunction({
          onclose: () => {},
          MTitle: <Text fw={700}>User Field {}</Text>,  
          MAction: true,
          MSize: "md",
          fullSize:false,
          // position: "right",
          MBody: (i) => 
          <div ref={userFieldRef}>
          {popupUserField(UFM01)}{" "}
          <Grid>
            <Grid.Col span={12}>
              <Group spacing={"xs"} position="center">
                <Button
                    size="sm"
                    style={{ width: "100px",height:"30px" }}
                  onClick={() => {
                    Store.dispatch(ModalDelete(i))
                    console.log("UFClicked",UFClicked);
                    setTimeout(() => {
                      if (UFClicked == false) {
                        GlobalClass.formNextInput(e,formRef)
                      }
                    }, 500);
                    
                  }}
                >
                  Ok
                </Button>
              </Group>
            </Grid.Col>
          </Grid>
              </div>,
          onClickCloseButton: () => {UFClicked==false &&  GlobalClass.formNextInput(e,formRef)},
          MClose: true,
          tab:false,
          Overlay: {
            backgroundOpacity: 0.8,
            blur: 5,
          },
        })
      );
    
    // modals.open({
    //   title: <Text fw={700}>User Field</Text>,
    //   zIndex: 300,
    //   size: "sm",
    //   children: (
    //     <>
    //       <>
    //         {popupUserField(UFM01)}{" "}
    //         <Group
    //           spacing={"xs"}
    //           position="center"
    //           display={"flex"}
    //           style={{ marginTop: 5 }}
    //         >
    //           <Button
    //             size="sm"
    //             style={{ width: 100 }}
    //             onClick={() => {
    //               modals.closeAll();
    //             }}
    //           >
    //             Ok
    //           </Button>
    //         </Group>
    //       </>
    //     </>
    //   ),
    // });

    //       dispatch(
    //         ModalFunction({
    //           onclose: () => {
    //             dispatch(
    //               ModalFunction({
    //                 MTitle: "",
    //                 MAction: false,
    //                 MSize: "md",
    //                 MBody: () => "",
    //                 MClose: false,
    //               })
    //             );
    //           },
    //           MTitle: "User Field",
    //           MAction: true,
    //           MSize: "50%",
    //           MBody: () => (
    //             <>
    // {            popupUserField(UFM01)
    // }              <Group
    //                 spacing={"xs"}
    //                 position="center"
    //                 display={"flex"}
    //                 style={{ marginTop: 5 }}
    //               >
    //                 <Button
    //                   size="sm"
    //                   style={{ width: 100 }}
    //                   onClick={() => {
    //                     // console.log("popupUserField call");
    //                     dispatch(
    //                       ModalFunction({
    //                         MTitle: "",
    //                         MAction: false,
    //                         MSize: "md",
    //                         MBody: () => "",
    //                         MClose: false,
    //                       })
    //                     );
    //                   }}
    //                   // disabled={submitBtnDisable}
    //                 >
    //                   Ok
    //                 </Button>
    //               </Group>
    //             </>
    //           ),
    //           MClose: false,
    //           MCentered: false,
    //         })
    //       );
  };

  const handleonclosed1 = (SelectRowsData) => {
    let rowObj = SelectRowsData;
    // console.log("rowObj==>11111", rowObj);
    if (!rowObj) {
      return null;
    }

    if (typeof rowObj === Array) {
      selectedRow = rowObj[0];
    } else {
      selectedRow = rowObj;
    }

    M01 = {
      ...M01,
      FIELD04: selectedRow.FIELD06,
      FIELD05: selectedRow.FIELD01,
      FIELD06: selectedRow.FIELD05,
      FIELD07: selectedRow.FIELD08,
    };
    VorC = "";
    //================================ Error : it is not function================================
    // lAddressDetail = islAddressDetail();
    // lPRDetail = lPRDetail();
    // lTDS = lTDS();
    // lTCS = lTCS();
    // lType = lType();
    // lType_tsortp = lType_tsortp();
    // lTaxType = (lTaxType() && lVATIO());
    // lFATaxtType = lFATaxtType();
    // G_lIO = (lType && G_lIO());
    // V_lIO = (lType && V_lIO());
    // lVATIO = (lType_tsortp && lVATIO());
    // lTrade = lTrade();
    // lNPMT = lNPMT();
    // lNOG = lNOG();
    // lDepreciation = lDepreciation();
    // cmdInterest = islInterest();
    // cmdAddress = lAddressDetail;
    // lBtoB = lBtoB();
    // cmdCapital = islCapital();
    //=====================================================================

    lAddressDetail = islAddressDetail();
    lPRDetail = islPRDetail();
    lTDS = islTDS();
    lTCS = islTCS();
    lMulCur = islMulCur();
    lGroupName = islGroupName();
    lCostCen = islCostCen();
    lTradingAC = islTradingAC();
    lType = islType();
    lTypeUpdated = islTypeUpdated();
    lType_tsortp = islType_tsortp();
    lTaxType = islTaxType() && islVATIO();
    lFATaxtType = islFATaxtType();
    lSecTaxType = islSecTaxType();
    G_lIO = islType() && isG_lIO();
    V_lIO = islType() && isV_lIO();
    lVATIO = islType_tsortp() && islVATIO();
    lTrade = islTrade();
    lNPMT = islNPMT();
    lNOG = islNOG();
    lGSTIN = islGSTIN();
    lBankRecons = islBankRecons();
    lPANNo = islPANNo();
    lTDSPOG = islTDSPOG();
    lDepreciation = islDepreciation();
    cmdInterest = islInterest();
    cmdAddress = islAddressDetail();
    lBtoB = islBtoB();
    cmdCapital = islCapital();

    cmdTCSbtn = islTCS() && "Y".includes(M01?.FIELD23);
    cmdTDSbtn = islTDS() && "Y".includes(M01?.M01FTDS);

    FelDeductee = islTDS() && "Y".includes(M01?.M01FTDS);
    lTDSlower = islTDS() && "Y".includes(M01?.M01FTDS);
    lTDSrate = islTDS() && "Y".includes(M01?.M01FTDS);
    lTDSlimit = islTDS() && "Y".includes(M01?.M01FTDS);
    lTDSsurcharge = islTDS() && "Y".includes(M01?.M01FTDS);

    WLTOD = islTCS() && "Y".includes(M01?.FIELD23);
    TDSratedisabled = "N".includes(M01?.TDSLOWER);

    // console.log(
    //   "Onhandle",
    //   cmdTDSbtn +
    //     " - " +
    //     cmdTCSbtn +
    //     " - " +
    //     cmdInterest +
    //     " - " +
    //     cmdAddress +
    //     " - " +
    //     cmdCapital
    // );
    // // console.log("visible",visible);
    // visible.filter((v,i)=>cmdCapital ? v!=='txtArea':v);

    // for Tab

    var filtertab = tabDisabledArr.filter((v, i) =>
      cmdTDSbtn ? v != "CAPTDS" : v
    );
    filtertab = filtertab.filter((v, i) => (cmdTCSbtn ? v != "PLUSM500" : v));
    filtertab = filtertab.filter((v, i) => (cmdInterest ? v != "INTEREST" : v));
    filtertab = filtertab.filter((v, i) => (cmdAddress ? v != "_F020009" : v));
    filtertab = filtertab.filter((v, i) =>
      lAddressDetail || "PR,BK,CP,LA,CL".includes(M01?.FIELD07)
        ? v != "B100"
        : v
    );
    // filtertab = filtertab.filter((v,i)=>(UfieldBtn ? v != "UFIELD" : v))

    setTabDisabled(filtertab);

    // For Field
    var visiblefilter = visibleArr.filter((v, i) =>
      lAddressDetail ? v !== "txtArea" : v
    );
    visiblefilter = visiblefilter.filter((v, i) =>
      lAddressDetail ? v !== "txtCity" : v
    );
    visiblefilter = visiblefilter.filter((v, i) =>
      lAddressDetail ? v !== "txtState" : v
    );
    visiblefilter = visiblefilter.filter((v, i) =>
      lPANNo ? v !== "txtAddhar" : v
    );
    visiblefilter = visiblefilter.filter((v, i) =>
      lPRDetail && GetGeneral?._VATSETUP == "Y" ? v !== "txtCSTNo" : v
    );
    visiblefilter = visiblefilter.filter((v, i) =>
      lPRDetail && GetGeneral?._BILLTOBIL == "Y" ? v !== "balanceMethod" : v
    );
    visiblefilter = visiblefilter.filter((v, i) =>
      lPRDetail && GetGeneral?._VATSETUP == "Y" ? v !== "txtVatNo" : v
    );
    visiblefilter = visiblefilter.filter((v, i) =>
      lPANNo ? v !== "txtPANNo" : v
    );
    visiblefilter = visiblefilter.filter((v, i) =>
      lGroupName ? v !== "txtGroupName" : v
    );
    visiblefilter = visiblefilter.filter((v, i) =>
      lPRDetail ? v !== "txtDLNo" : v
    );
    visiblefilter = visiblefilter.filter((v, i) =>
      lPRDetail || M01?.FIELD07 === "BK" ? v !== "txtCreditLimits" : v
    );
    visiblefilter = visiblefilter.filter((v, i) =>
      lPRDetail ? v !== "txtCreditDays" : v
    );
    visiblefilter = visiblefilter.filter((v, i) =>
      lGSTIN ? v !== "txtGSTIN" : v
    );
    visiblefilter = visiblefilter.filter((v, i) =>
      lBankRecons ? v != "bankRecons" : v
    );
    visiblefilter = visiblefilter.filter((v, i) =>
      lGSTIN ? v !== "cboRegType" : v
    );
    visiblefilter = visiblefilter.filter((v, i) => (lType ? v !== "type" : v));
    visiblefilter = visiblefilter.filter((v, i) =>
      lTypeUpdated ? v !== "typeUpdated" : v
    );
    visiblefilter = visiblefilter.filter((v, i) =>
      lType_tsortp ? v !== "type_tsortp" : v
    );
    visiblefilter = visiblefilter.filter((v, i) =>
      lTaxType ? v !== "txtTaxType" : v
    );

    visiblefilter = visiblefilter.filter((v, i) =>
      lFATaxtType ? v !== "FAtxtTaxType" : v
    );
    visiblefilter = visiblefilter.filter((v, i) =>
      lSecTaxType ? v !== "txtSecTaxType" : v
    );
    visiblefilter = visiblefilter.filter((v, i) => (lNOG ? v !== "wlNOG" : v));
    visiblefilter = visiblefilter.filter((v, i) =>
      lNPMT ? v !== "txtNPMT" : v
    );
    visiblefilter = visiblefilter.filter((v, i) =>
      lVATIO ? v !== "cboVatType" : v
    );
    visiblefilter = visiblefilter.filter((v, i) =>
      V_lIO ? v !== "V_I/OCombo" : v
    );
    visiblefilter = visiblefilter.filter((v, i) =>
      G_lIO ? v !== "G_I/OCombo" : v
    );
    visiblefilter = visiblefilter.filter((v, i) => (lTDS ? v !== "cboTDS" : v));
    visiblefilter = visiblefilter.filter((v, i) => (lTCS ? v !== "cboTCS" : v));
    visiblefilter = visiblefilter.filter((v, i) =>
      lTDSPOG ? v !== "TDSPOG" : v
    );
    visiblefilter = visiblefilter.filter((v, i) =>
      lMulCur ? v !== "mulCur" : v
    );
    visiblefilter = visiblefilter.filter((v, i) =>
      lCostCen ? v !== "costCen" : v
    );
    visiblefilter = visiblefilter.filter((v, i) =>
      lTradingAC ? v !== "tradingAC" : v
    );
    visiblefilter = visiblefilter.filter((v, i) =>
      lDepreciation ? v !== "deprecition" : v
    );
    visiblefilter = visiblefilter.filter((v, i) =>
      lPRDetail ? v !== "txtdiscper" : v
    );
    visiblefilter = visiblefilter.filter((v, i) =>
      lPRDetail ? v !== "cboBillType" : v
    );
    visiblefilter = visiblefilter.filter((v, i) =>
      lPRDetail ? v !== "cboRateType" : v
    );
    visiblefilter = visiblefilter.filter((v, i) =>
      lPRDetail ? v !== "cboChallan" : v
    );
    visiblefilter = visiblefilter.filter((v, i) =>
      lPRDetail ? v !== "txtBSeries" : v
    );
    visiblefilter = visiblefilter.filter((v, i) =>
      lPRDetail ? v !== "txtDisc1" : v
    );
    visiblefilter = visiblefilter.filter((v, i) =>
      lPRDetail ? v !== "txtDisc2" : v
    );
    visiblefilter = visiblefilter.filter((v, i) =>
      lPRDetail ? v !== "txtLAmt" : v
    );

    setVisible(visiblefilter);
  };
  const handleonclosed = (SelectRowsData) => {
    let rowObj = SelectRowsData;
    // console.log("rowObj==>", rowObj);
    if (!rowObj) {
      return null;
    }

    

    //     if (rowObj.FIELD01 !== M01.FIELD05) {
    //         // console.log('User Field Popup');
    // UFPopupRef.current.instance.show();
    // }

   
    VorC = "";
    //================================ Error : it is not function================================
    // lAddressDetail = islAddressDetail();
    // lPRDetail = lPRDetail();
    // lTDS = lTDS();
    // lTCS = lTCS();
    // lType = lType();
    // lType_tsortp = lType_tsortp();
    // lTaxType = (lTaxType() && lVATIO());
    // lFATaxtType = lFATaxtType();
    // G_lIO = (lType && G_lIO());
    // V_lIO = (lType && V_lIO());
    // lVATIO = (lType_tsortp && lVATIO());
    // lTrade = lTrade();
    // lNPMT = lNPMT();
    // lNOG = lNOG();
    // lDepreciation = lDepreciation();
    // cmdInterest = islInterest();
    // cmdAddress = lAddressDetail;
    // lBtoB = lBtoB();
    // cmdCapital = islCapital();
    //=====================================================================

    lAddressDetail = islAddressDetail();
    lPRDetail = islPRDetail();
    lTDS = islTDS();
    lTCS = islTCS();
    lMulCur = islMulCur();
    lCostCen = islCostCen();
    lGroupName = islGroupName();
    lTradingAC = islTradingAC();
    lType = islType();
    lTypeUpdated = islTypeUpdated();
    lType_tsortp = islType_tsortp();
    lTaxType = islTaxType() && islVATIO();
    lFATaxtType = islFATaxtType();
    lSecTaxType = islSecTaxType();
    G_lIO = islType() && isG_lIO();
    V_lIO = islType() && isV_lIO();
    lVATIO = islType_tsortp() && islVATIO();
    lTrade = islTrade();
    lNPMT = islNPMT();
    lNOG = islNOG();
    lGSTIN = islGSTIN();
    lBankRecons = islBankRecons();
    lPANNo = islPANNo();
    lTDSPOG = islTDSPOG();
    lDepreciation = islDepreciation();
    cmdInterest = islInterest();
    cmdAddress = islAddressDetail();
    lBtoB = islBtoB();
    cmdCapital = islCapital();

    cmdTCSbtn = islTCS() && "Y".includes(M01?.FIELD23);
    cmdTDSbtn = islTDS() && "Y".includes(M01?.M01FTDS);

    FelDeductee = islTDS() && "Y".includes(M01?.M01FTDS);
    lTDSlower = islTDS() && "Y".includes(M01?.M01FTDS);
    lTDSrate = islTDS() && "Y".includes(M01?.M01FTDS);
    lTDSlimit = islTDS() && "Y".includes(M01?.M01FTDS);
    lTDSsurcharge = islTDS() && "Y".includes(M01?.M01FTDS);

    WLTOD = islTCS() && "Y".includes(M01?.FIELD23);
    TDSratedisabled = "N".includes(M01?.TDSLOWER);

    // console.log(
    //   "Onhandle",
    //   cmdTDSbtn +
    //     " - " +
    //     cmdTCSbtn +
    //     " - " +
    //     cmdInterest +
    //     " - " +
    //     cmdAddress +
    //     " - " +
    //     cmdCapital
    // );
    // // console.log("visible",visible);
    // visible.filter((v,i)=>cmdCapital ? v!=='txtArea':v);

    // for Tab

    var filtertab = tabDisabledArr.filter((v, i) =>
      cmdTDSbtn ? v != "CAPTDS" : v
    );
    filtertab = filtertab.filter((v, i) => (cmdTCSbtn ? v != "PLUSM500" : v));
    filtertab = filtertab.filter((v, i) => (cmdInterest ? v != "INTEREST" : v));
    filtertab = filtertab.filter((v, i) => (cmdAddress ? v != "_F020009" : v));
    filtertab = filtertab.filter((v, i) =>
      lAddressDetail || "PR,BK,CP,LA,CL".includes(M01?.FIELD07)
        ? v != "B100"
        : v
    );

    // filtertab = filtertab.filter((v,i)=>(UfieldBtn ? v != "UFIELD" : v))

    setTabDisabled(filtertab);

    // For Field
    var visiblefilter = visibleArr.filter((v, i) =>
      lAddressDetail ? v !== "txtArea" : v
    );
    visiblefilter = visiblefilter.filter((v, i) =>
      lAddressDetail ? v !== "txtCity" : v
    );
    visiblefilter = visiblefilter.filter((v, i) =>
      lAddressDetail ? v !== "txtState" : v
    );
    visiblefilter = visiblefilter.filter((v, i) =>
      lPANNo ? v !== "txtAddhar" : v
    );
    visiblefilter = visiblefilter.filter((v, i) =>
      lPRDetail && GetGeneral?._VATSETUP == "Y" ? v !== "txtCSTNo" : v
    );
    visiblefilter = visiblefilter.filter((v, i) =>
      lPRDetail && GetGeneral?._BILLTOBIL == "Y" ? v !== "balanceMethod" : v
    );
    visiblefilter = visiblefilter.filter((v, i) =>
      lPRDetail && GetGeneral?._VATSETUP == "Y" ? v !== "txtVatNo" : v
    );
    visiblefilter = visiblefilter.filter((v, i) =>
      lPANNo ? v !== "txtPANNo" : v
    );
    visiblefilter = visiblefilter.filter((v, i) =>
      lPRDetail ? v !== "txtDLNo" : v
    );
    visiblefilter = visiblefilter.filter((v, i) =>
      lPRDetail || "BK".includes(M01?.FIELD07) ? v !== "txtCreditLimits" : v
    );
    visiblefilter = visiblefilter.filter((v, i) =>
      lPRDetail ? v !== "txtCreditDays" : v
    );
    visiblefilter = visiblefilter.filter((v, i) =>
      lGSTIN ? v !== "txtGSTIN" : v
    );
    visiblefilter = visiblefilter.filter((v, i) =>
      lBankRecons ? v != "bankRecons" : v
    );
    visiblefilter = visiblefilter.filter((v, i) =>
      lGSTIN ? v !== "cboRegType" : v
    );
    visiblefilter = visiblefilter.filter((v, i) => (lType ? v !== "type" : v));

    visiblefilter = visiblefilter.filter((v, i) =>
      lTypeUpdated ? v !== "typeUpdated" : v
    );
    visiblefilter = visiblefilter.filter((v, i) =>
      lType_tsortp ? v !== "type_tsortp" : v
    );
    visiblefilter = visiblefilter.filter((v, i) =>
      lTaxType ? v !== "txtTaxType" : v
    );
    visiblefilter = visiblefilter.filter((v, i) =>
      lFATaxtType ? v !== "FAtxtTaxType" : v
    );
    visiblefilter = visiblefilter.filter((v, i) =>
      lSecTaxType ? v !== "txtSecTaxType" : v
    );
    visiblefilter = visiblefilter.filter((v, i) => (lNOG ? v !== "wlNOG" : v));
    visiblefilter = visiblefilter.filter((v, i) =>
      lNPMT ? v !== "txtNPMT" : v
    );
    visiblefilter = visiblefilter.filter((v, i) =>
      lVATIO ? v !== "cboVatType" : v
    );
    visiblefilter = visiblefilter.filter((v, i) =>
      V_lIO ? v !== "V_I/OCombo" : v
    );
    visiblefilter = visiblefilter.filter((v, i) =>
      G_lIO ? v !== "G_I/OCombo" : v
    );
    visiblefilter = visiblefilter.filter((v, i) => (lTDS ? v !== "cboTDS" : v));
    visiblefilter = visiblefilter.filter((v, i) => (lTCS ? v !== "cboTCS" : v));
    visiblefilter = visiblefilter.filter((v, i) =>
      lTDSPOG ? v !== "TDSPOG" : v
    );
    visiblefilter = visiblefilter.filter((v, i) =>
      lMulCur ? v !== "mulCur" : v
    );
    visiblefilter = visiblefilter.filter((v, i) =>
      lCostCen ? v !== "costCen" : v
    );
    visiblefilter = visiblefilter.filter((v, i) =>
      lTradingAC ? v !== "tradingAC" : v
    );
    visiblefilter = visiblefilter.filter((v, i) =>
      lDepreciation ? v !== "deprecition" : v
    );
    visiblefilter = visiblefilter.filter((v, i) =>
      lPRDetail ? v !== "txtdiscper" : v
    );
    visiblefilter = visiblefilter.filter((v, i) =>
      lPRDetail ? v !== "cboBillType" : v
    );
    visiblefilter = visiblefilter.filter((v, i) =>
      lPRDetail ? v !== "cboRateType" : v
    );
    visiblefilter = visiblefilter.filter((v, i) =>
      lPRDetail ? v !== "cboChallan" : v
    );
    visiblefilter = visiblefilter.filter((v, i) =>
      lPRDetail ? v !== "txtBSeries" : v
    );
    visiblefilter = visiblefilter.filter((v, i) =>
      lPRDetail ? v !== "txtDisc1" : v
    );
    visiblefilter = visiblefilter.filter((v, i) =>
      lPRDetail ? v !== "txtDisc2" : v
    );
    visiblefilter = visiblefilter.filter((v, i) =>
      lPRDetail ? v !== "txtLAmt" : v
    );

    setVisible(visiblefilter);
  };
  const seperatelength = (e) => {
    var paraArr = e.split("~C~");
    return paraArr[1];
  };
  const M01handlechange = (e) => {
    // console.log("e.value()",e?.value);
    // console.log("e.id()",e?.id);
    // console.log("M01handlechange=>", e);
    // e = e.target;
    // if(e?.id==="UASDFDSA"){
    //   if(e?.value?.length > seperatelength(e?.maxLength)){
    //     GlobalClass.Notify("warning","Maximum length is 1 allowed","Length can't be more than 1");
    //      return;
    //   }
    // }
    M01 = {
      ...M01,
      [e?.id]:
        e?.value?.length === 1
          ? e?.value[0]
          : e?.value?.length <= 0
          ? ""
          : e?.value,
    };
    // console.log("M01",M01)

    if (e?.id === "FIELD02") {
      //    if (txtM02FIELD61ref !== null && txtM02FIELD61ref.current !== null) {
      //       txtM02FIELD61ref.current.instance.option('value', e.value);
      //    }
      // setAccountName(e.value);
      M02 = {
        ...M02,
        FIELD61:
          e?.value.length === 1
            ? e?.value[0]
            : e?.value.length <= 0
            ? ""
            : e?.value,
      };
    } else if (e?.id === "FIELD16") {
      M02 = {
        ...M02,
        FIELD08:
          e?.value.length === 1
            ? e?.value[0]
            : e?.value.length <= 0
            ? ""
            : e?.value,
      };
    } else if (e?.id == "FIELD18") {
      M02 = {
        ...M02,
        FIELD09:
          e?.value.length === 1
            ? e?.value[0]
            : e?.value.length <= 0
            ? ""
            : e?.value,
      };
    }
  };
  const M02handlechange = (e) => {
    // console.log("M02handlechange=>", e);

    // e = e.target;
    M02 = {
      ...M02,
      [e?.id]:
        e?.value?.length === 1
          ? e?.value[0]
          : e?.value?.length <= 0
          ? ""
          : e?.value,
    };
  };
  const M54handlechange = (e) => {
    // console.log("M54handlechange=>", e);

    // e = e.target;

    M54 = {
      ...M54,
      [e?.id]:
        e?.value?.length === 1
          ? e?.value[0]
          : e?.value?.length <= 0
          ? ""
          : e?.value,
    };
  };
  const M48Ihandlechange = (e) => {
    // e = e.target;
    // console.log("e from M48IhandleChange",e)
    M48I = {
      ...M48I,
      [e?.id]:
        e?.value?.length === 1
          ? e?.value[0]
          : e?.value?.length <= 0
          ? ""
          : e?.value,
    };
  };
  const M48Dhandlechange = (e) => {
    // // console.log('M48Dhandlechange=>',e);
    // // console.log('M48Dhandlechange id=>',e.id);
    // // console.log('M48Dhandlechange value=>',e.value);

    // e = e.target;
    M48D = {
      ...M48D,
      [e?.id]:
        e?.value?.length === 1
          ? e?.value[0]
          : e?.value?.length <= 0
          ? ""
          : e?.value,
    };
    // // console.log('M48Dhandlechange M48D FIELD03=>',M48D);
  };
  const M48TChandlechange = (e) => {
    // e = e.target;
    M48TC = {
      ...M48TC,
      [e?.id]:
        e?.value?.length === 1
          ? e?.value[0]
          : e?.value?.length <= 0
          ? ""
          : e?.value,
    };
  };
  const M48TDhandlechange = (e) => {
    // e = e.target;
    M48TD = {
      ...M48TD,
      [e?.id]:
        e?.value?.length === 1
          ? e?.value[0]
          : e?.value?.length <= 0
          ? ""
          : e?.value,
    };
  };

  const handleCBOTypeValue = (e) => {
    // console.log("handleCBOTypeValue=>", e);
    M01 = {
      ...M01,
      FIELD22: e,
    };
    lTaxType = lTaxType();
    V_lIO = lType() && V_lIO();
    G_lIO = lType() && G_lIO();
    lNPMT = lNPMT();
    lNOG = lNOG();
    lVATIO = lType_tsortp() && lVATIO();

    setVisible(visible.filter((v, i) => (lTaxType ? v !== "txtTaxType" : v)));
    setVisible(
      visible.filter((v, i) => (lSecTaxType ? v !== "txtSecTaxType" : v))
    );
    setVisible(visible.filter((v, i) => (lVATIO ? v !== "cboVatType" : v)));
    setVisible(visible.filter((v, i) => (V_lIO ? v !== "V_I/OCombo" : v)));
    setVisible(visible.filter((v, i) => (G_lIO ? v !== "G_I/OCombo" : v)));
    setVisible(visible.filter((v, i) => (lNOG ? v !== "wlNOG" : v)));
    setVisible(visible.filter((v, i) => (lNPMT ? v !== "txtNPMT" : v)));
  };

  const TaxTypeonClosed = (SelectRowsData) => {
    let rowObj = SelectRowsData;
    if (rowObj && rowObj.length === 0) return null;

    VorCRow = rowObj[0];
    VorC = rowObj[0].FIELD03;
    M01 = {
      ...M01,
      FIELD20: VorCRow.FIELD01,
    };

    lVATIO = lType_tsortp() && lVATIO();
    // formRef.current.instance.itemOption("groupName.cboVatType", 'visible', lVATIO);
  };
  const seprate = (e) => {
    // console.log("seprate=>", e);
    var paraStart = e.indexOf("(");
    var paraEnd = e.lastIndexOf(")");
    var fun = e.substring(0, paraStart);
    var para = e.substring(paraStart + 1, paraEnd); // paraEnd-1
    var paraArr = para.split("~C~");
    // console.log("paraArr==>", paraArr);
    return paraArr;
  };

  const popupUserField = (e) => {
    // console.log("popupUserField", e);
    return e && e.length > 0 ? (
      <div>
        {" "}
        {e.map((item, index) => {
          // console.log("Item", item);
          // console.log(item.S13F09[1]);
          return (
            <Grid gutter={4}>
              {item.S13F14 === "TEXTBOX" && (
                <Grid.Col
                  span={12}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: 0,
                  }}
                >
                  <Grid.Col span={3}>
                    <Text style={{ fontSize: 12 }}>{item.S13F07}:</Text>
                  </Grid.Col>
                  <Grid.Col span={9}>
                    <PLTextBox
                      autoFocus={index == 0 ? true : false}
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, userFieldRef);
                      }}
                      name={item.S13F02}
                      nmaxlength={
                        item.S13F02 == "UASDFDSA"
                          ? seperatelength(item.S13F09)
                          : ""
                      }
                      value={M01[item.S13F02]}
                      setEdit={(e) => {
                        // console.log(e);
                        M01handlechange({
                          id: item.S13F02,
                          // maxLength: item.S13F09,
                          value: e.target.value,
                        });
                      }}
                    />
                  </Grid.Col>
                </Grid.Col>
              )}

              {item.S13F14 === "NUMBOX" && (
                <Grid.Col
                  span={12}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: 0,
                  }}
                >
                  <Grid.Col span={3}>
                    <Text style={{ fontSize: 12 }}>{item.S13F07}:</Text>
                  </Grid.Col>
                  <Grid.Col span={9}>
                    <PLNumberBox
                      autoFocus={index == 0 ? true : false}
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, userFieldRef);
                      }}
                      name={item.S13F02}
                      value={M01[item.S13F02]}
                      setEdit={(e) => {
                        // console.log(e);
                        M01handlechange({ id: item.S13F02, value: e });
                      }}
                    />
                  </Grid.Col>
                </Grid.Col>
              )}

              {item.S13F14 === "PLPOPUP" && (
                <Grid.Col
                  span={12}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: 0,
                  }}
                >
                  <Grid.Col span={3}>
                    <Text style={{ fontSize: 12 }}>{item.S13F07}:</Text>
                  </Grid.Col>
                  <Grid.Col span={9}>
                    {/* {// console.log(
                      "M01[item.S13F02]",
                      M01[item.S13F02],
                      ".................."
                    )} */}
                    <PLDataGrid
                      autoFocus={index == 0 ? true : false}
                      // onKeyDown={(e) => {
                      //   GlobalClass.formNextInput(e, userFieldRef);
                      // }}
                      width="470px" 
                      openOnFocus={true}
                      name={item.S13F02}
                      value={M01[item.S13F02]}
                      p0={seprate(item.S13F09)[0]}
                      p1={seprate(item.S13F09)[1]}
                      setEdit={(e) => {
                        // console.log(e);
                        M01handlechange({ id: item.S13F02, value: e?.FIELD01 });
                      }}
                      // handleClose={(e) => {
                      //   GlobalClass.formNextInput(e, formRef);
                      // }}
                    />
                  </Grid.Col>
                </Grid.Col>
              )}

              {item.S13F14 === "COMBOBOX" && (
                <Grid.Col
                  span={12}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: 0,
                  }}
                >
                  <Grid.Col span={3}>
                    <Text style={{ fontSize: 12 }}>{item.S13F07}:</Text>
                  </Grid.Col>
                  <Grid.Col span={9}>
                    <PLComboBox
                      autoFocus={index == 0 ? true : false}
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, userFieldRef);
                      }}
                      name={item.S13F02}
                      value={M01[item.S13F02]}
                      copno={"val1"}
                      dispexpr="DisplayMember"
                      valexpr="ValueMember"
                      setEdit={(e) => {
                        // console.log(e);
                        M01handlechange({ id: item.S13F02, value: e });
                      }}
                    />
                  </Grid.Col>
                </Grid.Col>
              )}

              {item.S13F14 === "DATEPICKER" && (
                <Grid.Col
                  span={12}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: 0,
                  }}
                >
                  <Grid.Col span={3}>
                    <Text style={{ fontSize: 12 }}>{item.S13F07}:</Text>
                  </Grid.Col>
                  <Grid.Col span={9}>
                    <PLDateBox
                      autoFocus={index == 0 ? true : false}
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, userFieldRef);
                      }}
                      name={item.S13F02}
                      value={M01[item.S13F02]}
                      setEdit={(e) => {
                        // console.log(e);
                        M01handlechange({ id: item.S13F02, value: e });
                      }}
                    />
                  </Grid.Col>
                </Grid.Col>
              )}

              {item.S13F14 === "TIMEPICKER" && (
                <Grid.Col
                  span={12}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: 0,
                  }}
                >
                  <Grid.Col span={3}>
                    <Text style={{ fontSize: 12 }}>{item.S13F07}:</Text>
                  </Grid.Col>
                  <Grid.Col span={9}>
                    <PLDateBox
                      autoFocus={index == 0 ? true : false}
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, userFieldRef);
                      }}
                      name={item.S13F02}
                      value={M01[item.S13F02]}
                      setEdit={(e) => {
                        // console.log(e);
                        M01handlechange({ id: item.S13F02, value: e });
                      }}
                    />
                  </Grid.Col>
                </Grid.Col>
              )}

              {item.S13F14 === "MEMOBOX" && item.S13F21 === "N" && (
                <Grid.Col
                  span={12}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: 0,
                  }}
                >
                  <Grid.Col span={3}>
                    <Text style={{ fontSize: 12 }}>{item.S13F07}:</Text>
                  </Grid.Col>
                  <Grid.Col span={9}>
                    <PLTextBox
                      autoFocus={index == 0 ? true : false}
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, userFieldRef);
                      }}
                      name={item.S13F02}
                      value={M01[item.S13F02]}
                      setEdit={(e) => {
                        M01handlechange({
                          id: item.S13F02,
                          value: e.target.value,
                        });
                        // console.log(e);
                      }}
                    />
                  </Grid.Col>
                </Grid.Col>
              )}

              {item.S13F14 === "MEMOBOX" && item.S13F21 === "H" && (
                <Grid.Col
                  span={12}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: 0,
                  }}
                >
                  <Grid.Col span={3}>
                    <Text style={{ fontSize: 12 }}>{item.S13F07}:</Text>
                  </Grid.Col>
                  <Grid.Col span={9}>
                    <PLTextBox
                      autoFocus={index == 0 ? true : false}
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, userFieldRef);
                      }}
                      name={item.S13F02}
                      value={M01[item.S13F02]}
                      setEdit={(e) => {
                        // console.log(e);
                        M01handlechange({
                          id: item.S13F02,
                          value: e.target.value,
                        });
                      }}
                    />
                  </Grid.Col>
                </Grid.Col>
              )}

              {item.S13F14 === "FILEUPLOAD" && item.S13F21 === "I" && (
                <Grid.Col
                  span={12}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: 0,
                  }}
                >
                  <Grid.Col span={3}>
                    <Text style={{ fontSize: 12 }}>{item.S13F07}:</Text>
                  </Grid.Col>
                  <Grid.Col span={9}>
                    <PLTextBox
                      autoFocus={index == 0 ? true : false}
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, userFieldRef);
                      }}
                      setEdit={(e) => {
                        // console.log(e);
                        M01handlechange({
                          id: item.S13F02,
                          value: e.target.value,
                        });
                      }}
                    />
                  </Grid.Col>
                </Grid.Col>
              )}

              {item.S13F14 === "FILEUPLOAD" && item.S13F21 === "A" && (
                <Grid.Col
                  span={12}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: 0,
                  }}
                >
                  <Grid.Col span={3}>
                    <Text style={{ fontSize: 12 }}>{item.S13F07}:</Text>
                  </Grid.Col>
                  <Grid.Col span={9}>
                    <PLTextBox
                      autoFocus={index == 0 ? true : false}
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, userFieldRef);
                      }}
                      setEdit={(e) => {
                        // console.log(e);
                        M01handlechange({
                          id: item.S13F02,
                          value: e.target.value,
                        });
                      }}
                    />
                  </Grid.Col>
                </Grid.Col>
              )}
            </Grid>
          );
        })}
      </div>
    ) : (
      []
    );
  };

  // const [M01FormDataObj, setM01FormDataObj] = useState({
  //     "M01": M01,
  //     "M02": M02,
  //     "M48": M48,
  //     "M48I": M48I,
  //     "M48D": M48D,
  //     "M48TC": M48TC,
  //     "M48TD": M48TD,
  //     "M54": M54,
  //     "UFM01": UFM01,
  //     "M48CGrd": M48CGrd
  // });

  const submitM01FormData = () => {
    let obj1 = {
      M01: M01,
      M02: M02,
      M48: M48,
      M48I: M48I,
      M48D: M48D,
      M48TC: M48TC,
      M48TD: M48TD,
      M54: M54,
      UFM01: UFM01,
      M48CGrd: M48CGrd,
      T05: T05DataArray,
    };
    // console.log("submitM01FormData===>", obj);
    dispatch(DataSaveLoading({[props?.OBJ?.cSTID]:true}));
    // console.log("obj?.M01?.FIELD04",obj?.M01?.FIELD04)
    // console.log("obj?.M01",obj?.M01)
    if (
      props?.OBJ?.cSTID !== "T_M01OB" &&
      props?.OBJ?.text !== "Edit" &&
      (obj1?.M01?.FIELD04 == "" ||
        obj1?.M01?.FIELD04 == undefined ||
        obj1?.M01?.FIELD02 == "" ||
        ("TS,TP,FA".includes(obj1?.M01?.FIELD07) &&
          "V,G".includes(obj1?.M01?.FIELD22) &&
          obj1?.M01?.FIELD20 == ""))
    ) {
      GlobalClass.Notify(
        "warning",
        "Validation error",
        "Please fill out all the required fields"
      );
      dispatch(DataSaveLoading({[props?.OBJ?.cSTID]:false}));
      return;
    }

    let Jdata = obj1;
    GlobalClass.Notify(
      "info",
      props?.OBJ?.p0 == "A" ? "Adding" : "Editing",
      "Please wait while we process your data"
    );
    var data = {
      CorpID: gensetting.getcorpid(),
      cUser: gensetting.getcuser(),
      cPass: gensetting.getcpass(),
      CmpNo: gensetting.getcmpno(),
      cAction: props?.OBJ?.p0=="E" ? props?.OBJ?.cSTID == "T_M01OB" && props?.OBJ?.text=="Edit" ? "O" : "E" : props?.OBJ?.p0 ,
      cCode:  props?.OBJ?.p0=="A" ? "" : M01?.FIELD01,
      cOPara: "",
      cSData: JSON.stringify(Jdata),
      YrNo:localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
    };

    let param = JSON.stringify({
      CorpID: gensetting.getcorpid(),
      cUser: gensetting.getcuser(),
      cPass: gensetting.getcpass(),
      CmpNo: gensetting.getcmpno(),
      cAction: props?.OBJ?.p0=="E" ? props?.OBJ?.cSTID == "T_M01OB" && props?.OBJ?.text=="Edit" ? "O" : "E" : props?.OBJ?.p0 ,
      cCode: props?.OBJ?.p0=="A" ? "" : M01?.FIELD01,
      cOPara: "",
    });

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    fetch(
      GlobalClass.ApiUrl + GlobalClass.PostM01Ent + "?pa=" + param,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        // console.log("api data=>", data);
        // console.log("api data status=>", data?.status);

        if (data.status == "SUCCESS") {
          GlobalClass.Notify(
            "success",
            props?.OBJ.p0 == "A" ? "Added" : "Edited",
            props?.OBJ.p0 == "A" ? "Added successfully" : "Edited successfully"
          );
          // modals.closeAll();
          let PaginationObj =
            Store.getState().DataSaveLoadingSlice.PaginationObj;
          Promise.resolve(dispatch(DataSaveLoading({[props?.OBJ?.cSTID]:false})))
            // .then(() => dispatch(ModalDelete(props.index)))
            .then(() => {
              if (props?.OBJ?.p0 == "A") {
                setGSTValue("");
                setActiveTab(rc("A_MAST76"));
                setUFClicked(true);
                setTDSDetail("N");
                setTCSDetail("N");
                setCreditDebitBM("");
                setCreditDebit("");
                setValidation({
                  gst: true,
                  aadhar: true,
                  pan: true,
                });
                setShowCheckGSTBTN(false);
                setType("");
                setTDSLowerRate("");
                setEffDate("");
                setGroupEdited(false);
                setTDSPOG("N");
                setGSTINData(null);
                setRateType("N");
                dispatch(GetM01List({ Skey: "", OBJ: props?.OBJ?.p0 }));
                setActiveTab(rc("A_MAST76"));
                setTDSDetail("N");
                setTCSDetail("N");
                setCreditDebitBM("");
                setCreditDebit("");
                setValidation({
                  gst: true,
                  aadhar: true,
                  pan: true,
                });
                setShowCheckGSTBTN(false);
                setType("");
                setTDSLowerRate("");
                setEffDate("");
                setGroupEdited(false);
                setTDSPOG("N");
                setGSTINData(null);
                setRateType("N");
                dispatch(GetM01List({ Skey: "", OBJ: props?.OBJ?.p0 }));
              } else {
                // if(String(props?.index).startsWith("modalForm")){
                //   dispatch(TabRemove(props?.index))
                // }else{
                dispatch(ModalDelete(props.index));
                // }
              }
            })
            .then(() =>{
              // dispatch(GetAccountList({
              //     id: "00510001",
              //     name: "Account List",
              //     p0: "MT",
              //     p1: "T_M01",
              //     p2: "",
              //     p3: "",
              //     type: "A",
              //     pagination: PaginationObj["00510001"],
              //   }))
              
              dispatch(GetAccountList({...props?.OBJ?.BtnPnlObj ,pagination: PaginationObj[props?.OBJ?.cSTID]}))
            });
          // // console.log("PaginationObj submit",PaginationObj);
        } else if (data.status == "FAIL") {
          GlobalClass.Notify("error", data?.status, data?.message);
          dispatch(DataSaveLoading({[props?.OBJ?.cSTID]:false}));
        }
      })
      .catch((e) => {
        GlobalClass.Notify("error", "Error", `${e.message}`);
        dispatch(DataSaveLoading({[props?.OBJ?.cSTID]:false}));
      });
  };

  // useEffect(() => {
  //   // // console.log('M01?.FIELD02 useEffect=>',M01?.FIELD02);
  //   if (M01?.FIELD02 != "") {
  //     setSubmitBtnDisable(false);
  //   } else {
  //     setSubmitBtnDisable(true);
  //   }
  // }, [submitBtnDisable, M01?.FIELD02]);

  // console.log("M01FormData==>", M01FormData);

  // const [AccGroupLength, setAccGroupLength] = useState("");

  const accountGroupInputFun = (e) => {
    // console.log("inputFld => ", [
    //   {
    //     e: e,
    //   },
    // ]);

    if (e?.length > 0) {
      return <Text>Account group</Text>;
    } else {
      return null;
    }

    // return  <Grid.Col>
    //       <Text>Account group</Text>
    //     </Grid.Col>
  };

  useEffect(() => {
    if (GSTINData) {
      Promise.resolve()
        .then(() => {
          // console.log("GSTINData =>", GSTINData);
          M02 = {
            ...M02,
            FIELD02: GSTINData?.Address1,
            FIELD03: GSTINData?.Address2,
            // FIELD08:e?.City,
            FIELD07: JSON.parse(GSTINData?.Pincode),
            FIELD50: GSTINData?.TradeName,
          };
        })
        .then(() => setActiveTab(rc("_F020009")));
    }
  }, [GSTINData]);

  const GstDetailFun = (e) => {
    // console.log("GST Details from Copy", e);
    M02 = {
      ...M02,
      FIELD61: e?.TradeName,
      FIELD02: e?.Address1,
      FIELD03: e?.Address2,
      // FIELD08:e?.City,
      FIELD07: JSON.parse(e?.Pincode),
      FIELD50: e?.TradeName,
    };
  };

  // console.log("GST detail console =>", [{
  //   "GSTINData": GSTINData,
  //   "M02?.FIELD61": M02?.FIELD61,
  //   "M02?.FIELD02": M02?.FIELD02,
  //   "M02?.FIELD03": M02?.FIELD03,
  //   "M02?.FIELD07": M02?.FIELD07,
  //   "M02?.FIELD50": M02?.FIELD50
  // }]);
  useEffect(() => {
    const formElement = formRef.current;
    if (formElement) {
      formElement.addEventListener("keypress", (e) =>
        GlobalClass.formNextInput(e, formRef)
      );
    }
    return () => {
      if (formElement) {
        formElement.removeEventListener("keypress", (e) =>
          GlobalClass.formNextInput(e, formRef)
        );
      }
    };
  }, [formRef]);
  useEffect(() => {
    const formElement = userFieldRef.current;
    if (formElement) {
      formElement.addEventListener("keypress", (e) =>
        GlobalClass.formNextInput(e, userFieldRef)
      );
    }
    return () => {
      if (formElement) {
        formElement.removeEventListener("keypress", (e) =>
          GlobalClass.formNextInput(e, userFieldRef)
        );
      }
    };
  }, [userFieldRef]);

  return (
    <div ref={formRef}>
      {/* <Modal
        opened={DrawerObj?.open}
        // onClose={close}
        withCloseButton={() => {}}
        fullScreen={false} //
        // overlayProps={{ opacity: 0.5, blur: 4 }}
        closeButtonProps={{
          onClick: (e) => {
            if (typeof DrawerObj?.onClickCloseButton == "function") {
              DrawerObj?.onClickCloseButton();
            } else {
              setDrawerObj(dra);
            }
          },
        }}
        title={DrawerObj.title}
        size={DrawerObj.size}
        // centered={.Centered}
        closeOnClickOutside={true}
        // overlayProps={m.Overlay}
        position={DrawerObj?.position ?? "bottom"}
        onClose={
          typeof DrawerObj?.onclose == "function"
            ? DrawerObj?.onclose
            : () => {
                setDrawerObj(dra);
              }
        }
        // scrollAreaComponent={ScrollArea.Autosize}
      >
        {DrawerObj.body}
      </Modal> */}
      { hasError ? <>
        <Text>{ErrorMsg?.message}</Text>
          <Button onClick={() => {
            if (props?.OBJ) {
              if (props?.OBJ?.p0 == "A") {
                M01 = {};
                M02 = {};
                M48 = [];
                M48I = {};
                M48D = {};
                M48TC = {};
                M48TD = {};
                M54 = {};
                UFM01 = [];
                M48CGrd = [];
                dispatch(GetM01List({ Skey: "", OBJ: props?.OBJ?.p0 }));
              } else {
                dispatch(GetM01List({ Skey: props?.OBJ?.id, OBJ: "E" }));
              }
            }
          }}>Retry</Button>
      </> :SaveLoading[props?.OBJ?.cSTID] ? (
        GlobalClass.RenderLoader("dots")
      ) : isLoading ? (
        GlobalClass.RenderLoader("dots")
      ) : !(props?.OBJ?.cSTID == "T_M01OB" && props?.OBJ?.text == "Edit") ? (
        M01FormData==null ? <>{GlobalClass.Alert("info", props.index)}</> :  <Tabs
          color="gray"
          variant="outline"
          // defaultValue={rc("A_MAST76")}
          value={activeTab}
          onTabChange={setActiveTab}
        >
          <Tabs.List>
            <Tabs.Tab value={rc("A_MAST76")}>
              {" "}
              {/* Main Details*/}
              {rc("A_MAST76")}
            </Tabs.Tab>
            <Tabs.Tab
              value={rc("_F020009")}
              disabled={tabDisabled.includes("_F020009") ?? true}
            >
              {" "}
              {/* Address*/}
              {rc("_F020009")}
            </Tabs.Tab>
            <Tabs.Tab
              value={rc("CAPTDS")}
              disabled={tabDisabled.includes("CAPTDS") ? true : false}
            >
              {" "}
              {/* TDS*/}
              {rc("CAPTDS")}
            </Tabs.Tab>
            <Tabs.Tab
              value={rc("PLUSM500")}
              disabled={tabDisabled.includes("PLUSM500") ?? true}
            >
              {" "}
              {/*TCS*/}
              {rc("PLUSM500")}
            </Tabs.Tab>
            <Tabs.Tab
              value={rc("INTEREST")}
              disabled={tabDisabled.includes("INTEREST") ?? true}
            >
              {" "}
              {/* Interest*/}
              {rc("INTEREST")}
            </Tabs.Tab>
            <Tabs.Tab
              value={rc("B100")}
              disabled={tabDisabled.includes("B100") ?? true}
            >
              {" "}
              {/* Bank*/}
              {rc("B100")}
            </Tabs.Tab>
            {/* <Tabs.Tab
          value={"User Field"}
          disabled={props?.OBJ?.p0=="A"}
        >
          {" "}
          {"User Field"}
        </Tabs.Tab> */}
          </Tabs.List>
          <Tabs.Panel value={rc("A_MAST76")}>
            <>
              {/* // console.log(visible, "Visible") */}
              <div style={{ padding: "10px 0px" }}>
                <Grid gutter={4}>
                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={2}>
                      <Text style={{ fontSize: 12 }}>{rc("CAP00117")}:</Text>
                      {/* name */}
                    </Grid.Col>
                    <Grid.Col span={10}>
                      <PLTextBox
                        autoFocus={true}
                        setEdit={(e) => {
                          // console.log("M01?.FIELD02", e.target.value);

                          M01handlechange({
                            id: "FIELD02",
                            value: e.target.value,
                          });

                          // setAccGroupLength(e.target.value)
                        }}
                        //                     setText={(e)=>{
                        //                           lGroupName = islGroupName();
                        //                         var  visiblefilter = visiblefilter.filter((v,i)=>
                        //   lGroupName ? v!=="txtGroupName" : v
                        // );
                        //                           setVisible(visiblefilter);
                        //                     }}
                        name="FIELD02" //'TXTName1'
                        value={M01?.FIELD02}
                        defaultValue={M01?.FIELD02}
                        nmaxlength={50}
                      />
                    </Grid.Col>
                  </Grid.Col>

                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={2}>
                      <Text style={{ fontSize: 12 }}>{rc("AMASTE01")}:</Text>
                      {/* Alias */}
                    </Grid.Col>
                    <Grid.Col span={10}>
                      <PLTextBox
                        nmaxlength={15}
                        value={M01?.FIELD40}
                        name="FIELD40"
                        setEdit={(e) => {
                          M01handlechange({
                            id: "FIELD40",
                            value: e.target.value,
                          });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>

                  {/* {!visible.includes("txtGroupName") ? */}
                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <>
                      <Grid.Col span={2}>
                        <Text style={{ fontSize: 12 }}>{rc("MENU0008")}:</Text>
                        {/* Group Name*/}
                      </Grid.Col>
                      <Grid.Col span={10}>
                        <PLDataGrid
                        ref={groupRef}
                          width="500px"
                          setBlur={() => {
                            // console.log("hello from blur")
                            setGroupEdited(true);
                          }}
                          setEdit={(e) => {
                            setTDSPOG("N");
                            setCreditDebitBM(
                              "L,I,C".includes(e?.FIELD09) ? "C" : "D"
                            );
                            setUFClicked(false);
                            M01 = {
                              ...M01,
                              FIELD04: e.FIELD06,
                              FIELD05: e.FIELD01,
                              FIELD06: e.FIELD05,
                              FIELD07: e.FIELD08,
                              M11F09: e.FIELD09,
                              FIELD05NM: e.FIELD02,
                            };
                            handleonclosed(e);
                           
                          }}
                          handleClose={(e) => {
                            console.log("heee");
                            if (
                              (M01?.FIELD05NM === "Capital Account" ||
                              M01?.FIELD05NM === "ds" ||
                              M01?.FIELD05NM === "Reserves & Surplus"
                            ) && M48.length > 0 && M48CGrd.length > 0) {
                              // console.log("Capital Account Called");
                              functionOne(e);
                            } else if(UFM01.length>0){
                              functionTwo(e);
                            }else{
                              console.log("here in else")
                              GlobalClass.formNextInput(e,formRef);
                            }
                                
                          }}
                          openOnFocus={true}
                          value={{ value: M01?.FIELD05, label: M01?.FIELD05NM }}
                          type={"Group_Name"}
                          TmData={M01FormData?.P_M11E}
                          // data={M01FormData?.P_M11EDT?.jData}
                        />
                      </Grid.Col>
                    </>
                  </Grid.Col>

                  {!visible.includes("typeUpdated") ? (
                    <Grid.Col
                      span={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 0,
                      }}
                    >
                      <Grid.Col span={2}>
                        <Text style={{ fontSize: 12 }}>{rc("IT_EX_10")}:</Text>{" "}
                        {/* Type */}
                      </Grid.Col>
                      <Grid.Col span={10}>
                        <PLComboBox
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          name="FIELD22"
                          data={M01FormData?.COP40}
                          dispexpr="DisplayMember"
                          valexpr="ValueMember"
                          value={M01?.FIELD22}
                          setEdit={(e) => {
                            M01 = {
                              ...M01,
                              FIELD22: e,
                            };
                            setType(e);
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  ) : null}

                  {!visible.includes("type") ? (
                    <Grid.Col
                      span={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 0,
                      }}
                    >
                      <Grid.Col span={2}>
                        <Text style={{ fontSize: 12 }}>{rc("CAP00006")}:</Text>{" "}
                        {/* Tax Type */}
                      </Grid.Col>
                      <Grid.Col span={10}>
                        {/* not find */}
                        {/* <PLDataGrid /> */}
                        <PLComboBox
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          name="FIELD22"
                          data={M01FormData?.COP2}
                          dispexpr="DisplayMember"
                          valexpr="ValueMember"
                          value={M01?.FIELD22}
                          setEdit={(e) => {
                            M01 = {
                              ...M01,
                              FIELD22: e,
                            };
                            setType(e);
                            // handleonclosed(e);
                            // lTaxType = islTaxType();
                            // V_lIO = islType() && isV_lIO();
                            // G_lIO = islType() && isG_lIO();
                            // lNPMT = islNPMT();
                            // lNOG = islNOG();
                            // lVATIO = islType_tsortp() && islVATIO();

                            // setVisible(
                            //   visible.filter((v, i) =>
                            //     lTaxType ? v !== "txtTaxType" : v
                            //   )
                            // );
                            // setVisible(
                            //   visible.filter((v, i) =>
                            //     lVATIO ? v !== "cboVatType" : v
                            //   )
                            // );
                            // setVisible(
                            //   visible.filter((v, i) =>
                            //     V_lIO ? v !== "V_I/OCombo" : v
                            //   )
                            // );
                            // setVisible(
                            //   visible.filter((v, i) =>
                            //     G_lIO ? v !== "G_I/OCombo" : v
                            //   )
                            // );
                            // setVisible(
                            //   visible.filter((v, i) =>
                            //     lNOG ? v !== "wlNOG" : v
                            //   )
                            // );
                            // setVisible(
                            //   visible.filter((v, i) =>
                            //     lNPMT ? v !== "txtNPMT" : v
                            //   )
                            // );
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  ) : null}

                  {!visible.includes("tradingAC") ? (
                    <Grid.Col
                      span={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 0,
                      }}
                    >
                      <Grid.Col span={2}>
                        <Text style={{ fontSize: 12 }}>{rc("_MND0145")}:</Text>
                        {/* Trading A/c. */}
                      </Grid.Col>
                      <Grid.Col span={10}>
                        <PLDataGrid
                          width="500px"
                          TmData={M01FormData?.P_M11}
                          // data={M01FormData?.P_M11DT?.jData}
                          value={{ value: M01?.FIELD18, label: M01?.FIELD18NM }}
                          setEdit={(e) => {
                            M01 = {
                              ...M01,
                              FIELD18: e?.FIELD01,
                              FIELD18NM: e?.FIELD02,
                            };
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  ) : null}
                </Grid>

                <Grid gutter={4}>
                  {!visible.includes("txtSecTaxType") ||
                  "V,C,D".includes(type) ? (
                    <Grid.Col
                      span={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 0,
                      }}
                    >
                      <Grid.Col span={2}>
                        <Text style={{ fontSize: 12 }}>{rc("CAP00006")}:</Text>{" "}
                        {/*TAX Type */}
                      </Grid.Col>
                      <Grid.Col span={10}>
                        <PLDataGrid
                          width="500px"
                          value={{ value: M01?.FIELD20, label: M01?.FIELD20NM }}
                          TmData={M01FormData?.P_M51}
                          // data={M01FormData?.P_M51DT?.jData}
                          setEdit={(e) => {
                            M01 = {
                              ...M01,
                              FIELD20: e?.FIELD01,
                              FIELD20NM: e?.FIELD02,
                            };
                            // lTaxType = islTaxType();
                            // V_lIO = islType() && isV_lIO();
                            // G_lIO = islType() && isG_lIO();
                            // lNPMT = islNPMT();
                            // lNOG = islNOG();
                            // lVATIO = islType_tsortp() && islVATIO();

                            // setVisible(
                            //   visible.filter((v, i) =>
                            //     lTaxType ? v !== "txtTaxType" : v
                            //   )
                            // );
                            // setVisible(
                            //   visible.filter((v, i) =>
                            //     lVATIO ? v !== "cboVatType" : v
                            //   )
                            // );
                            // setVisible(
                            //   visible.filter((v, i) =>
                            //     V_lIO ? v !== "V_I/OCombo" : v
                            //   )
                            // );
                            // setVisible(
                            //   visible.filter((v, i) =>
                            //     G_lIO ? v !== "G_I/OCombo" : v
                            //   )
                            // );
                            // setVisible(
                            //   visible.filter((v, i) =>
                            //     lNOG ? v !== "wlNOG" : v
                            //   )
                            // );
                            // setVisible(
                            //   visible.filter((v, i) =>
                            //     lNPMT ? v !== "txtNPMT" : v
                            //   )
                            // );
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  ) : null}

                  {
                    // !visible.includes('FAtxtTaxType') ?
                    //     <Grid.Col span={12} style={{ display: 'flex', alignItems: 'center', padding: 0 }} >
                    //         <Grid.Col span={2}>
                    //             <Text style={{ fontSize: 12 }}>{rc('CAP00006')}:</Text> {/* Tax Type */}
                    //         </Grid.Col>
                    //         <Grid.Col span={10}>
                    //             {/* not find */}
                    //             {/* <PLDataGrid /> */}
                    //             <PLComboBox onKeyDown={(e)=>{
                    // GlobalClass.formNextInput(e,formRef);
                    //}}
                    //                 name='FIELD22'
                    //                 data={M01FormData?.COP40}
                    //                 dispexpr="DisplayMember"
                    //                 valexpr="ValueMember"
                    //                 value={M01?.FIELD22}
                    //                 setEdit={handleCBOTypeValue}
                    //             />
                    //         </Grid.Col>
                    //     </Grid.Col> : null
                  }

                  {!visible.includes("wlNOG") &&
                  TCSDetail == "Y" &&
                  type == "S" ? (
                    <Grid.Col
                      span={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 0,
                      }}
                    >
                      <Grid.Col span={2}>
                        <Text style={{ fontSize: 12 }}>{rc("PLUSM501")}:</Text>{" "}
                        {/* Nature of Goods */}
                      </Grid.Col>
                      <Grid.Col span={10}>
                        <PLDataGrid
                          width="500px"
                          setEdit={(e) => {
                            M01 = {
                              ...M01,
                              FIELD20: e.FIELD01,
                              FIELD20NM: e?.FIELD20NM,
                            };
                            handleonclosed(e);
                          }}
                          handleClose={(e) => {
                            handleonclosed(e);
                          }}
                          value={{ value: M01?.FIELD20, label: M01?.FIELD20NM }}
                          // type={"Group_Name"}
                          TmData={M01FormData?.P_PLTCSNOG}
                          // data={M01FormData?.P_PLTCSNOGDT?.jData}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  ) : null}

                  {!visible.includes("txtNPMT") &&
                  TDSDetail == "Y" &&
                  taxType == "T" ? (
                    <Grid.Col
                      span={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 0,
                      }}
                    >
                      <Grid.Col span={2}>
                        <Text style={{ fontSize: 12 }}>{rc("TDS01")}:</Text>{" "}
                        {/* Nature of Payment */}
                      </Grid.Col>
                      <Grid.Col span={10}>
                        <PLDataGrid
                          width="500px"
                          setEdit={(e) => {
                            M01 = {
                              ...M01,
                              FIELD20: e.FIELD01,
                              FIELD20NM: e.FIELD02,
                            };
                            M01handlechange(e);
                          }}
                          handleClose={(e) => {
                            // console.log("handleClose", e);
                            M01handlechange(e);
                          }}
                          value={{ value: M01?.FIELD20, label: M01?.FIELD20NM }}
                          type={"Nature_of_Payment"}
                          TmData={M01FormData?.P_M53NO}
                          // data={M01FormData?.P_M53NODT?.jData}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  ) : null}

                  {!visible.includes("V_I/OCombo") ? (
                    <Grid.Col
                      span={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 0,
                      }}
                    >
                      <Grid.Col span={2}>
                        <Text style={{ fontSize: 12 }}>
                          {rc("PRDEF_06")}Test:
                        </Text>{" "}
                        {/* Input/Output */}
                      </Grid.Col>
                      <Grid.Col span={10}>
                        <PLComboBox
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          name="FIELD08"
                          data={M01FormData?.COP4}
                          dispexpr="DisplayMember"
                          valexpr="ValueMember"
                          value={M01?.FIELD08}
                          setEdit={(e) => {
                            M01 = {
                              ...M01,
                              FIELD08: e,
                            };
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  ) : null}

                  {!visible.includes("G_I/OCombo") &&
                  ("TS,TP".includes(M01?.FIELD07)
                    ? "G,C,D,3,4".includes(M01?.FIELD22)
                    : "V,3,4,G".includes(M01?.FIELD22)) ? (
                    <Grid.Col
                      span={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 0,
                      }}
                    >
                      <Grid.Col span={2}>
                        <Text style={{ fontSize: 12 }}>{rc("PRDEF_06")}:</Text>{" "}
                        {/* Input/Output */}
                      </Grid.Col>
                      <Grid.Col span={10}>
                        <PLComboBox
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          name="FIELD08_1"
                          data={M01FormData?.COP5}
                          dispexpr="DisplayMember"
                          valexpr="ValueMember"
                          value={M01?.FIELD08}
                          setEdit={(e) => {
                            M01 = {
                              ...M01,
                              FIELD08: e,
                            };
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  ) : null}

                  {!visible.includes("deprecition") &&
                  M01?.FIELD05 == "G000014A" ? (
                    <Grid.Col
                      span={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 0,
                      }}
                    >
                      <Grid.Col
                        span={6}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: 0,
                        }}
                      >
                        <Grid.Col span={4}>
                          <Text style={{ fontSize: 12 }}>
                            {rc("A_MAST67")}:
                          </Text>{" "}
                          {/* Depreciation% */}
                        </Grid.Col>
                        <Grid.Col span={8}>
                          <PLNumberBox
                            // defaultval={0}
                            name="FIELD03"
                            // ntypeofentry="P2"
                            value={M48D?.FIELD03}
                            setEdit={(e) =>
                              M48Dhandlechange({ id: "FIELD03", value: e })
                            }
                          />
                        </Grid.Col>
                      </Grid.Col>

                      <Grid.Col
                        span={6}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: 0,
                        }}
                      >
                        <Grid.Col span={4}>
                          <Text style={{ fontSize: 12 }}>
                            {rc("A_MAST68")}:
                          </Text>{" "}
                          {/* Depreciation A/C*/}
                        </Grid.Col>
                        <Grid.Col span={8}>
                          <PLDataGrid
                            width="500px"
                            setEdit={(e) => {
                              M48D = {
                                ...M48D,
                                FIELD02: e.FIELD01,
                                M48DF02NM: e?.FIELD02,
                              };
                              M48Dhandlechange(e);
                            }}
                            handleClose={(e) => {
                              // console.log("handleClose", e);
                              M48Dhandlechange(e);
                            }}
                            value={{
                              value: M48D?.FIELD02,
                              label: M48D?.M48DF02NM,
                            }}
                            type={"DepreciationAC"}
                            TmData={M01FormData?.P_M01I}
                            // data={M01FormData?.P_M01IDT?.jData}
                          />
                        </Grid.Col>
                      </Grid.Col>
                    </Grid.Col>
                  ) : null}

                  {!visible.includes("txtCity") ? (
                    <Grid.Col
                      span={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 0,
                      }}
                    >
                      <Grid.Col span={2}>
                        <Text style={{ fontSize: 12 }}>{rc("_F020017")}:</Text>{" "}
                        {/* city  _VTSAT*/}
                      </Grid.Col>
                      <Grid.Col span={10}>
                        <PLDataGrid
                          width="500px"
                          ref={inputRef}
                          openOnFocus={true}
                          setEdit={(e) => {
                            M01 = {
                              ...M01,
                              FIELD16: e?.FIELD01,
                              FIELD17: e?.FIELD02,
                            };
                            M02 = { ...M02, FIELD08: e?.FIELD01 };
                            M01handlechange({
                              id: "FIELD16",
                              value: e?.FIELD01,
                            });
                            // inputRef1?.current?.handlePopoverToggle();
                          }}
                          handleClose={(e) => {
                            GlobalClass.formNextInput(e,formRef);
                          }}
                          // value={props?.OBJ?.p0 == "A" ? "" : M01?.FIELD16}
                          value={{ value: M01?.FIELD16, label: M01?.FIELD17 }}
                          type={"city"}
                          TmData={M01FormData?.P_M31T}
                          // data={M01FormData?.P_M31TDT?.jData}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  ) : null}

                  {!visible.includes("txtArea") ? (
                    <Grid.Col
                      span={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 0,
                      }}
                    >
                      <Grid.Col span={2}>
                        <Text style={{ fontSize: 12 }}>{rc("ADDR_3")}:</Text>{" "}
                        {/* area */}
                      </Grid.Col>
                      <Grid.Col span={10}>
                        <PLDataGrid
                          width="500px"
                          // ref={inputRef1}
                          openOnFocus={true}
                          setEdit={(e) => {
                            M01 = {
                              ...M01,
                              FIELD18: e.FIELD01,
                              FIELD19: e?.FIELD02,
                            };
                            M02 = { ...M02, FIELD09: e?.FIELD01 };
                            M01handlechange({
                              id: "FIELD18",
                              value: e?.FIELD01,
                            });
                            // inputRef2?.current?.handlePopoverToggle();
                          }}
                          handleClose={(e) => {
                            GlobalClass.formNextInput(e,formRef);
                          }}
                          value={{ value: M01?.FIELD18, label: M01?.FIELD19 }}
                          type={"Area"}
                          TmData={M01FormData?.P_M31A}
                          // data={M01FormData?.P_M31ADT?.jData}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  ) : null}

                  {!visible.includes("txtState") ? (
                    <Grid.Col
                      span={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 0,
                      }}
                    >
                      <Grid.Col span={2}>
                        <Text style={{ fontSize: 12 }}>{rc("_F020027")}:</Text>{" "}
                        {/* state*/}
                      </Grid.Col>
                      <Grid.Col span={10}>
                        <PLDataGrid
                          width={"500px"}
                          position={"target"}
                          // ref={inputRef2}
                          openOnFocus={true}
                          setEdit={(e) => {
                            // console.log("e of state", e);
                            M01 = {
                              ...M01,
                              FIELD51: e.PLSF01,
                              FIELD52: e?.PLSF02,
                            };
                            M01handlechange(e);
                            // let dt= {...e,"key": "Enter"}
                            
                          }}
                          handleClose={(e) => {
                            // M01handlechange(e);
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          value={{ value: M01?.FIELD51, label: M01?.FIELD52 }}
                          type={"state"}
                          dispexpr={"PLSF02"}
                          valexpr={"PLSF01"}
                          TmData={M01FormData?.P_STATE}
                          
                          // data={M01FormData?.P_STATEDT?.jData}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  ) : null}

                  {
                    // !visible.includes("cboVatType") ? (
                    //   <Grid.Col
                    //     span={12}
                    //     style={{
                    //       display: "flex",
                    //       alignItems: "center",
                    //       padding: 0,
                    //     }}
                    //   >
                    //     <Grid.Col span={2}>
                    //       <Text style={{ fontSize: 12 }}>
                    //         {rc("V_MAST07")}:
                    //       </Text>{" "}
                    //       VAT Type
                    //      </Grid.Col>
                    //     <Grid.Col span={10}>
                    //       <PLComboBox onKeyDown={(e)=>{
                    //GlobalClass.formNextInput(e,formRef);
                    // }}
                    //         name="FIELD08"
                    //         data={M01FormData?.COP6}
                    //         dispexpr="DisplayMember"
                    //         valexpr="ValueMember"
                    //         value={M01?.FIELD08}
                    //         setEdit={(e) => {
                    //           M01 = {
                    //             ...M01,
                    //             FIELD08: e,
                    //           };
                    //         }}
                    //       />
                    //     </Grid.Col>
                    //   </Grid.Col>
                    // ) : null
                  }

                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    {
                      // !visible.includes('txtVatNo') ?
                      //     <Grid.Col span={4} style={{ display: 'flex', alignItems: 'center', padding: 0 }} >
                      //         <Grid.Col span={6}>
                      //             <Text style={{ fontSize: 12 }}>{rc('CAP00003')}:</Text> {/*TIN No */}
                      //         </Grid.Col>
                      //         <Grid.Col span={6}>
                      //             <PLTextBox
                      //                 name='FIELD33'
                      //                 value={M01?.FIELD33}
                      //                 setEdit={(e) => {
                      //                     M01handlechange({ id: "FIELD33", value: e.target.value })
                      //                 }}
                      //             />
                      //         </Grid.Col>
                      //     </Grid.Col> : null
                    }
                    {!visible.includes("txtPANNo") ? (
                      <Grid.Col
                        span={6}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: 0,
                        }}
                      >
                        <Grid.Col span={4}>
                          <Text style={{ fontSize: 12 }}>
                            {rc("AMASTE06")}:
                          </Text>{" "}
                          {/* PAN no */}
                        </Grid.Col>
                        <Grid.Col span={8}>
                          <PLTextBox
                            name="FIELD34" //'TXTPANNo'
                            value={M01?.FIELD34}
                            setText={handlePAnBlur}
                            error={
                              validation.pan ? "" : "Enter a Valid Pan Number"
                            }
                            setEdit={(e) => {
                              // if (e?.target.value?.length > 0) {
                              let a = ChkGSTINCheckSum(e.target.value?.trim());
                              if (a) {
                                M01 = {
                                  ...M01,
                                  FIELD34: e.target.value?.substring(2, 12),
                                };
                                // setPAN(e.target.value?.substring(2,12));
                              } else {
                                M01handlechange({
                                  id: "FIELD34",
                                  value: e.target.value,
                                });
                                // setPAN(e.target.value);
                              }
                              // }
                            }}
                          />
                        </Grid.Col>
                      </Grid.Col>
                    ) : null}

                    {!visible.includes("txtAddhar") ? (
                      <Grid.Col
                        span={6}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: 0,
                        }}
                      >
                        <Grid.Col span={4}>
                          <Text style={{ fontSize: 12 }}>
                            {rc("AADHARNO")}:
                          </Text>{" "}
                          {/*Aadhar No */}
                        </Grid.Col>
                        <Grid.Col span={8}>
                          <PLTextBox
                            name="FIELD25" //'TXTAddhar'
                            value={M01?.FIELD25}
                            setText={handleAadharBlur}
                            error={
                              validation.aadhar
                                ? ""
                                : "Enter a Valid Aadhar Number"
                            }
                            setEdit={(e) => {
                              M01handlechange({
                                id: "FIELD25",
                                value: e.target.value,
                              });
                            }}
                          />
                        </Grid.Col>
                      </Grid.Col>
                    ) : null}
                  </Grid.Col>

                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    {
                      // !visible.includes('txtCSTNo') ?
                      //     <Grid.Col span={12} style={{ display: 'flex', alignItems: 'center', padding: 0 }} >
                      //         <Grid.Col span={2}>
                      //             <Text style={{ fontSize: 12 }}>{rc('TMP164')}:</Text> {/*C.S.T. No */}
                      //         </Grid.Col>
                      //         <Grid.Col span={10}>
                      //             <PLTextBox
                      //                 name='FIELD32'
                      //                 value={M01?.FIELD32}
                      //                 setEdit={(e) => {
                      //                     M01handlechange({ id: "FIELD32", value: e.target.value })
                      //                 }}
                      //             />
                      //         </Grid.Col>
                      //     </Grid.Col> : null
                    }

                    {/* {
                                    !visible.includes('txtDLNo') ?
                                        <Grid.Col span={4} style={{ display: 'flex', alignItems: 'center', padding: 0 }} >
                                            <Grid.Col span={6}>
                                                <Text style={{ fontSize: 12, fontWeight: 'bold' }}>{rc('AMASTDL')}:</Text> {/*D.L. No 
                                            </Grid.Col>
                                            <Grid.Col span={6}>
                                                <PLTextBox
                                                    name='FIELD56'//'TXTDLNo'
                                                    value={M01?.FIELD56}
                                                    setEdit={M01handlechange}
                                                />
                                            </Grid.Col>
                                        </Grid.Col> : null
                                } */}

                    {/* {
                                    !visiblArr.includes('cboChallan') ?
                                        <Grid.Col span={4} style={{ display: 'flex', alignItems: 'center', padding: 0 }} >
                                            <Grid.Col span={6}>
                                                <Text style={{ fontSize: 12, fontWeight: 'bold' }}>{rc('MTRD0013')}:</Text> {/* Challan 
                                            </Grid.Col>
                                            <Grid.Col span={6}>
                                                <PLComboBox onKeyDown={(e)=>{
                        GlobalClass.formNextInput(e,formRef);
                      }}
                                                    name='FIELD58'
                                                    data={M01FormData?.COP1}
                                                    dispexpr="DisplayMember"
                                                    valexpr="ValueMember"
                                                    value={M01?.FIELD58}
                                                    setEdit={(e) => {
                                                        setM01({
                                                            ...M01,
                                                            FIELD58: e.value,
                                                        })
                                                    }}
                                                />
                                            </Grid.Col>
                                        </Grid.Col> : null
                                } */}
                  </Grid.Col>

                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    {!visible.includes("txtGSTIN") &&
                    visible.includes("bankRecons") ? (
                      <>
                        <Grid.Col
                          span={6}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: 0,
                          }}
                        >
                          <Grid.Col span={4}>
                            <Text style={{ fontSize: 12 }}>
                              {rc("PLUSM024")}:
                            </Text>{" "}
                            {/*GSTIN no */}
                          </Grid.Col>
                          <Grid.Col span={8}>
                            <PLTextBox
                              id="gstTextBox"
                              nmaxlength={15}
                              // autoCapitalize="true"
                              // tt={"uppercase"}
                              value={GSTValue}
                              // defaultValue={M01?.FIELD35}
                              name="FIELD35" //'TXTDLNo'
                              // value={M01?.FIELD35}

                              error={
                                !validation.gst
                                  ? "GST No. is InValid!, Please Enter Proper GST Number"
                                  : ""
                              }
                              setText={handleGstBlur}
                              setEdit={(e) => {
                                M01 = {
                                  ...M01,
                                  FIELD35: e.target.value?.toUpperCase(),
                                };
                                setGSTValue(e.target.value?.toUpperCase());
                                // console.log("35",M01?.FIELD35);
                              }}
                            />
                          </Grid.Col>
                        </Grid.Col>
                        <Grid.Col
                          span={6}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: 0,
                          }}
                        >
                          <CheckGst
                          disabled={!showCheckGSTBtn}
                            gst={M01?.FIELD35}
                            copyGstDetails={(e) => {
                              setGSTINData(e);
                              //  GstDetailFun(e)
                            }}
                          />
                        </Grid.Col>
                      </>
                    ) : null}
                  </Grid.Col>

                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    {!visible.includes("cboRegType") &&
                    visible.includes("bankRecons") ? (
                      <Grid.Col
                        span={6}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: 0,
                        }}
                      >
                        <Grid.Col span={4}>
                          <Text style={{ fontSize: 12 }}>
                            {rc("PLUSM112")}:
                          </Text>{" "}
                          {/*Regi. Type */}
                        </Grid.Col>
                        <Grid.Col span={8}>
                          <PLComboBox
                            onKeyDown={(e) => {
                              GlobalClass.formNextInput(e, formRef);
                            }}
                            name="FIELD37"
                            cmbid="FIELD37"
                            data={M01FormData?.COP9}
                            // onFocusOpen={true}
                            dispexpr="DisplayMember"
                            valexpr="ValueMember"
                            value={
                              M01?.FIELD37 == ""
                                ? M01FormData?.COP9?.[0]?.ValueMember
                                : M01?.FIELD37
                            }
                            setEdit={(e) => {
                              M01 = {
                                ...M01,
                                FIELD37: e,
                              };
                            }}
                          />
                        </Grid.Col>
                      </Grid.Col>
                    ) : null}
                  </Grid.Col>

                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    {!visible.includes("TDSPOG") ? (
                      <Grid.Col
                        span={6}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: 0,
                        }}
                      >
                        <Grid.Col span={4}>
                          <Text style={{ fontSize: 12 }}>
                            {rc("PLUSM516")}:
                          </Text>{" "}
                          {/*TDS On Purchase On Goods */}
                        </Grid.Col>
                        <Grid.Col span={8}>
                          <PLComboBox
                            onKeyDown={(e) => {
                              GlobalClass.formNextInput(e, formRef);
                            }}
                            value={M01?.FIELD35}
                            dispexpr="DisplayMember"
                            valexpr="ValueMember"
                            data={M01FormData?.COP1}
                            setEdit={(e) => {
                              setTDSPOG(e);
                              // console.log("FIELD35", e.target.value);
                              M01 = {
                                ...M01,
                                FIELD35: e,
                              };
                              // console.log("35",M01?.FIELD35);
                            }}
                          />
                        </Grid.Col>
                      </Grid.Col>
                    ) : null}

                    {!visible.includes("mulCur") ? (
                      <Grid.Col
                        span={6}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: 0,
                        }}
                      >
                        <Grid.Col span={4}>
                          <Text style={{ fontSize: 12 }}>
                            {rc("MTLCUR01")}:
                          </Text>{" "}
                          {/*Multi currency */}
                        </Grid.Col>
                        <Grid.Col span={8}>
                          <PLComboBox
                            onKeyDown={(e) => {
                              GlobalClass.formNextInput(e, formRef);
                            }}
                            data={M01FormData?.COP1}
                            dispexpr="DisplayMember"
                            valexpr="ValueMember"
                            value={M01?.M01CF09}
                            setEdit={(e) => {
                              M01 = {
                                ...M01,
                                M01CF09: e,
                              };
                            }}
                          />
                        </Grid.Col>
                      </Grid.Col>
                    ) : null}
                  </Grid.Col>

                  {/* <Grid.Col span={12} style={{ display: 'flex', alignItems: 'center', padding: 0 }}>
                                {
                                    !visible.includes('cboBillType') ?
                                        <Grid.Col span={6} style={{ display: 'flex', alignItems: 'center', padding: 0 }} >
                                            <Grid.Col span={4}>
                                                <Text style={{ fontSize: 12, fontWeight: 'bold' }}>{rc('BRC_0074')}:</Text> {/* Bill Type 
                                            </Grid.Col>
                                            <Grid.Col span={8}>
                                                <PLComboBox onKeyDown={(e)=>{
                        GlobalClass.formNextInput(e,formRef);
                      }}
                                                    name='FIELD57'
                                                    data={M01FormData?.COP74}
                                                    dispexpr="DisplayMember"
                                                    valexpr="ValueMember"
                                                    value={M01?.FIELD57}
                                                    setEdit={(e) => {
                                                        setM01({
                                                            ...M01,
                                                            FIELD57: e.value,
                                                        })
                                                    }}
                                                />
                                            </Grid.Col>
                                        </Grid.Col> : null
                                }

                                {
                                    !visible.includes('cboRateType') ?
                                        <Grid.Col span={6} style={{ display: 'flex', alignItems: 'center', padding: 0 }} >
                                            <Grid.Col span={4}>
                                                <Text style={{ fontSize: 12, fontWeight: 'bold' }}>{rc('PRICEL_4')}:</Text> {/* Rate Type 
                                            </Grid.Col>
                                            <Grid.Col span={8}>
                                                <PLComboBox onKeyDown={(e)=>{
                        GlobalClass.formNextInput(e,formRef);
                      }}
                                                    name='FIELD55'
                                                    data={M01FormData?.COP81}
                                                    dispexpr="DisplayMember"
                                                    valexpr="ValueMember"
                                                    value={M01?.FIELD55}
                                                    setEdit={(e) => {
                                                        M01 = {
                                                            ...M01,
                                                            FIELD55: e.value,
                                                        }
                                                    }}
                                                />
                                            </Grid.Col>
                                        </Grid.Col> : null
                                }

                            </Grid.Col>

                            {
                                !visible.includes('txtBSeries') ?
                                    <Grid.Col span={12} style={{ display: 'flex', alignItems: 'center', padding: 0 }} >
                                        <Grid.Col span={2}>
                                            <Text style={{ fontSize: 12, fontWeight: 'bold' }}>{rc('BILLSER')}:</Text>{/*Bill Series  
                                        </Grid.Col>
                                        <Grid.Col span={10}>
                                            <PLComboBox onKeyDown={(e)=>{
                        GlobalClass.formNextInput(e,formRef);
                      }} />
                                        </Grid.Col>
                                    </Grid.Col> : null
                            }


                            <Grid.Col span={12} style={{ display: 'flex', alignItems: 'center', padding: 0 }}>
                                {
                                    !visible.includes('txtDisc1') ?
                                        <Grid.Col span={6} style={{ display: 'flex', alignItems: 'center', padding: 0 }} >
                                            <Grid.Col span={4}>
                                                <Text style={{ fontSize: 12, fontWeight: 'bold' }}>{rc('A_MASTCD')}:</Text> {/*Cash Discount% 
                                            </Grid.Col>
                                            <Grid.Col span={8}>
                                                <PLNumberBox
                                                    name='FIELD60'
                                                    // ntypeofentry="P2"
                                                    value={M01?.FIELD60}
                                                    setEdit={(e) => M01handlechange({ id: 'FIELD60', value: e })}
                                                />
                                            </Grid.Col>
                                        </Grid.Col> : null
                                }

                                {
                                    !Arr.includes('txtdiscper') ?
                                        <Grid.Col span={6} style={{ display: 'flex', alignItems: 'center', padding: 0 }} >
                                            <Grid.Col span={4}>
                                                <Text style={{ fontSize: 12, fontWeight: 'bold' }}>{rc('RC_10')}:</Text>{/*Discount %
                                            </Grid.Col>
                                            <Grid.Col span={8}>
                                                <PLNumberBox
                                                    name='FIELD11'
                                                    // ntypeofentry="P2"
                                                    value={M01?.FIELD11}
                                                    setEdit={(e) => M01handlechange({ id: 'FIELD11', value: e })}
                                                />
                                            </Grid.Col>
                                        </Grid.Col> : null
                                }

                            </Grid.Col>
                        </Grid>

                        <Grid gutter={4}>
                            <Grid.Col span={12} style={{ display: 'flex', alignItems: 'center', padding: 0 }}>
                                {
                                    !visible.includes('txtLAmt') ?
                                        <Grid.Col span={6} style={{ display: 'flex', alignItems: 'center', padding: 0 }} >
                                            <Grid.Col span={4}>
                                                <Text style={{ fontSize: 12, fontWeight: 'bold' }}>{rc('EPL_0492')}:</Text> {/*Above 
                                            </Grid.Col>
                                            <Grid.Col span={8}>
                                                <PLNumberBox
                                                    name='FIELD62'
                                                    //  ntypeofentry="AM"
                                                    value={M01?.FIELD62}
                                                    setEdit={(e) => M01handlechange({ id: 'FIELD62', value: e })}
                                                />
                                            </Grid.Col>
                                        </Grid.Col> : null
                                }

                                {
                                    !visible.includes('txtDisc2') ?
                                        <Grid.Col span={6} style={{ display: 'flex', alignItems: 'center', padding: 0 }} >
                                            <Grid.Col span={4}>
                                                <Text style={{ fontSize: 12, fontWeight: 'bold' }}>{rc('V_MAST02')}:</Text>{/*  % 
                                            </Grid.Col>
                                            <Grid.Col span={8}>
                                                <PLNumberBox
                                                    name='FIELD61'
                                                    // ntypeofentry='P2'
                                                    value={M01?.FIELD61}
                                                    onvalchange={(e) => M01handlechange({ id: 'FIELD61', value: e })}
                                                />
                                            </Grid.Col>
                                        </Grid.Col> : null
                                }

                            </Grid.Col>*/}

                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    {!visible.includes("cboTDS") ? (
                      <Grid.Col
                        span={6}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: 0,
                        }}
                      >
                        <Grid.Col span={4}>
                          <Text style={{ fontSize: 12 }}>
                            {rc("INTER_11")}:
                          </Text>{" "}
                          {/*TDS Detail */}
                        </Grid.Col>
                        <Grid.Col span={8}>
                          <PLComboBox
                            onKeyDown={(e) => {
                              GlobalClass.formNextInput(e, formRef);
                            }}
                            name="M01FTDS"
                            data={M01FormData?.COP1}
                            dispexpr="DisplayMember"
                            valexpr="ValueMember"
                            value={M01?.M01FTDS}
                            setEdit={(e) => {
                              M01 = {
                                ...M01,
                                M01FTDS: e,
                              };
                              cmdTDSbtn = lTDS && "Y".includes(e);
                              setTDSDetail(e);
                              // formRef.current.instance.itemOption(rc("CAPTDS"), 'disabled', !cmdTDSbtn);
                              if (!cmdTDSbtn) {
                                tabDisabled.push("CAPTDS");
                                setTabDisabled(tabDisabled);
                                return;
                              } else {
                                setTabDisabled(
                                  tabDisabled.filter((v, i) =>
                                    cmdTDSbtn ? v != "CAPTDS" : v
                                  )
                                );
                              }
                              if (lTDS && "Y".includes(e)) {
                                setActiveTab(rc("CAPTDS"));
                              }
                            }}
                          />
                        </Grid.Col>
                      </Grid.Col>
                    ) : null}
                  </Grid.Col>

                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    {TDSPOG == "Y" ? (
                      <Grid.Col
                        span={6}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: 0,
                        }}
                      >
                        <Grid.Col span={4}>
                          <Text style={{ fontSize: 12 }}>
                            {rc("TDSHRATE")}:
                          </Text>
                          {/*TDS Higher Rate */}
                        </Grid.Col>
                        <Grid.Col span={8}>
                          <PLComboBox
                            onKeyDown={(e) => {
                              GlobalClass.formNextInput(e, formRef);
                            }}
                            data={M01FormData?.COP1}
                            dispexpr="DisplayMember"
                            valexpr="ValueMember"
                            value={M01?.FIELD26}
                            setEdit={(e) => {
                              M01 = {
                                ...M01,
                                FIELD26: e,
                              };
                            }}
                          />
                        </Grid.Col>
                      </Grid.Col>
                    ) : null}
                    {!visible.includes("costCen") ? (
                      <Grid.Col
                        span={6}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: 0,
                        }}
                      >
                        <Grid.Col span={4}>
                          <Text style={{ fontSize: 12 }}>
                            {rc("COSTCENT")}:
                          </Text>
                          {/*Cost Center */}
                        </Grid.Col>
                        <Grid.Col span={8}>
                          <PLComboBox
                            onKeyDown={(e) => {
                              GlobalClass.formNextInput(e, formRef);
                            }}
                            data={M01FormData?.COP1}
                            dispexpr="DisplayMember"
                            valexpr="ValueMember"
                            value={M01?.M01CCF01}
                            setEdit={(e) => {
                              M01 = {
                                ...M01,
                                M01CCF01: e,
                              };
                            }}
                          />
                        </Grid.Col>
                      </Grid.Col>
                    ) : null}
                  </Grid.Col>

                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    {!visible.includes("cboTCS") ? (
                      <Grid.Col
                        span={6}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: 0,
                        }}
                      >
                        <Grid.Col span={4}>
                          <Text style={{ fontSize: 12 }}>
                            {rc("TCSDETAL")}:
                          </Text>{" "}
                          {/*TCS Detaill*/}
                        </Grid.Col>
                        <Grid.Col span={8}>
                          <PLComboBox
                            onKeyDown={(e) => {
                              GlobalClass.formNextInput(e, formRef);
                            }}
                            name="FIELD23"
                            data={M01FormData?.COP1}
                            dispexpr="DisplayMember"
                            valexpr="ValueMember"
                            value={M01?.FIELD23}
                            setEdit={(e) => {
                              // console.log("e", e);
                              M01 = {
                                ...M01,
                                FIELD23: e,
                              };
                              setTCSDetail(e);
                              cmdTCSbtn = lTCS && "Y".includes(e);
                              // formRef.current.instance.itemOption(rc("PLUSM500"), 'disabled', !cmdTCSbtn);

                              // setTabDisabled(tabDisabled.filter((v, i) => cmdTCSbtn ? v != 'PLUSM500' : v));
                              if (!cmdTCSbtn) {
                                tabDisabled.push("PLUSM500");
                                setTabDisabled(tabDisabled);
                                return;
                              } else {
                                setTabDisabled(
                                  tabDisabled.filter((v, i) =>
                                    cmdTCSbtn ? v != "PLUSM500" : v
                                  )
                                );
                              }
                              if (lTCS && "Y".includes(e)) {
                                setActiveTab(rc("PLUSM500"));
                              }
                            }}
                          />
                        </Grid.Col>
                      </Grid.Col>
                    ) : null}
                  </Grid.Col>
                </Grid>

                {!visible.includes("bankRecons") ? (
                  <>
                    <Card.Section style={{ marginTop: "20" }}>
                      <Text weight={500}>{rc("BANK_X")}</Text>{" "}
                      {/*Bank Reconciliation*/}
                      <hr />
                    </Card.Section>
                    <Grid gutter={4}>
                      <Grid.Col
                        span={12}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: 0,
                        }}
                      >
                        <Grid.Col span={2}>
                          <Text style={{ fontSize: 12 }}>{rc("OPBAL")}:</Text>{" "}
                          {/*Opening Balance*/}
                        </Grid.Col>
                        <Grid.Col span={10}>
                          <PLNumberBox
                            name="FIELD80"
                            decimalSeparator={true}
                            precision={2}
                            value={Math?.abs(M01?.FIELD80)}
                            setEdit={(e) => {
                              M01handlechange({
                                id: "FIELD80",
                                value: creditDebit == "C" ? e : e * -1,
                              });
                              setCreditDebit(e < 0 ? "D" : "C");
                            }}
                          />
                        </Grid.Col>
                      </Grid.Col>
                      <Grid.Col
                        span={12}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: 0,
                        }}
                      >
                        <Grid.Col
                          span={6}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: 0,
                          }}
                        >
                          <Grid.Col span={4}>
                            <Text style={{ fontSize: 12 }}>
                              {rc("BL_ST17")}:
                            </Text>
                            {/*Credit/Debit */}
                          </Grid.Col>
                          <Grid.Col span={8}>
                            <PLComboBox
                              onKeyDown={(e) => {
                                GlobalClass.formNextInput(e, formRef);
                              }}
                              data={M01FormData?.COP8}
                              dispexpr="DisplayMember"
                              valexpr="ValueMember"
                              value={creditDebit}
                              setEdit={(e) => {
                                // console.log("e of credit debit", e);
                                M01 = {
                                  ...M01,
                                  FIELD80:
                                    e == "C"
                                      ? M01?.FIELD80 * 1
                                      : M01?.FIELD80 * -1,
                                };
                                setCreditDebit(M01?.FIELD80 < 0 ? "D" : "C");
                              }}
                            />
                          </Grid.Col>
                        </Grid.Col>

                        <Grid.Col
                          span={6}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: 0,
                          }}
                        >
                          <Grid.Col span={4}>
                            <Text style={{ fontSize: 12 }}>
                              Effective date:
                            </Text>
                            {/*Effective Date */}
                          </Grid.Col>
                          <Grid.Col span={8}>
                            {props?.OBJ?.p0 == "A" ? (
                              <PLDateBox
                                dispformat="DD/MM/YYYY"
                                setEdit={(e) => {
                                  let { startDate } = DateToString(e);
                                  M01 = {
                                    ...M01,
                                    FIELD81: startDate,
                                  };
                                  setEffDate(startDate);
                                }}
                                isVal={false}
                                placeholder="if blank then take previous year reff"
                              />
                            ) : effDate == "" ? (
                              <PLDateBox
                                dispformat="DD/MM/YYYY"
                                setEdit={(e) => {
                                  let { startDate } = DateToString(e);
                                  M01 = {
                                    ...M01,
                                    FIELD81: startDate,
                                  };
                                  setEffDate(startDate);
                                }}
                                isVal={false}
                                placeholder="if blank then take previous year reff"
                              />
                            ) : (
                              <PLDateBox
                                dispformat="DD/MM/YYYY"
                                defaultval={effDate}
                                setEdit={(e) => {
                                  let { startDate } = DateToString(e);
                                  M01 = {
                                    ...M01,
                                    FIELD81: startDate,
                                  };
                                  setEffDate(startDate);
                                }}
                                isVal={true}
                              />
                            )}
                          </Grid.Col>
                        </Grid.Col>
                      </Grid.Col>
                      <Grid.Col
                        span={12}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: 0,
                        }}
                      >
                        {!visible.includes("txtGSTIN") ? (
                          <>
                            <Grid.Col
                              span={6}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                padding: 0,
                              }}
                            >
                              <Grid.Col span={4}>
                                <Text style={{ fontSize: 12 }}>
                                  {rc("PLUSM024")}:
                                </Text>{" "}
                                {/*GSTIN no */}
                              </Grid.Col>
                              <Grid.Col span={8}>
                                <PLTextBox
                                  // autoCapitalize="true"
                                  // tt={"uppercase"}
                                  id="gstTextBox"
                                  name="FIELD35" //'TXTDLNo'
                                  value={GSTValue}
                                  nmaxlength={15}
                                  //  defaultValue={M01?.FIELD35}
                                  error={
                                    !validation.gst
                                      ? "GST No. is InValid!, Please Enter Proper GST Number"
                                      : ""
                                  }
                                  setText={handleGstBlur}
                                  setEdit={(e) => {
                                    M01 = {
                                      ...M01,
                                      FIELD35: e.target.value?.toUpperCase(),
                                    };
                                    setGSTValue(e.target.value?.toUpperCase());
                                    // console.log("35",M01?.FIELD35);
                                  }}
                                />
                              </Grid.Col>
                            </Grid.Col>
                            <Grid.Col
                              span={6}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                padding: 0,
                              }}
                            >
                              <CheckGst
                                gst={M01?.FIELD35}
                                copyGstDetails={(e) => {
                                  setGSTINData(e);
                                  //  GstDetailFun(e)
                                }}
                              />
                            </Grid.Col>
                          </>
                        ) : null}
                      </Grid.Col>
                      <Grid.Col
                        span={12}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: 0,
                        }}
                      >
                        {
                          // !visible.includes("txtGSTIN") &&
                          // M01?.FIELD05 !== "G0000017" ? (
                          //   <Grid.Col
                          //     span={6}
                          //     style={{
                          //       display: "flex",
                          //       alignItems: "center",
                          //       padding: 0,
                          //     }}
                          //   >
                          //     <Grid.Col span={4}>
                          //       <Text style={{ fontSize: 12 }}>
                          //         {rc("PLUSM024")}:
                          //       </Text>{" "}
                          //       {/*GSTIN no */}
                          //     </Grid.Col>
                          //     <Grid.Col span={8}>
                          //       <TextInput
                          //         name="FIELD35" //'TXTDLNo'
                          //         // value={M01?.FIELD35}
                          //         error={
                          //           !validation.gst
                          //             ? "GST No. is InValid!, Please Enter Proper GST Number"
                          //             : ""
                          //         }
                          //         onBlur={handleGstBlur}
                          //         onChange={(e) => {
                          //           // console.log("FIELD35", e.target.value);
                          //           M01 = {
                          //             ...M01,
                          //             FIELD35: e.target.value,
                          //           };
                          //           // console.log("35",M01?.FIELD35);
                          //         }}
                          //       />
                          //     </Grid.Col>
                          //   </Grid.Col>
                          // ) : null
                        }

                        {!visible.includes("cboRegType") ? (
                          <Grid.Col
                            span={6}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              padding: 0,
                            }}
                          >
                            <Grid.Col span={4}>
                              <Text style={{ fontSize: 12 }}>
                                {rc("PLUSM112")}:
                              </Text>{" "}
                              {/*Regi. Type */}
                            </Grid.Col>
                            <Grid.Col span={8}>
                              <PLComboBox
                                onKeyDown={(e) => {
                                  GlobalClass.formNextInput(e, formRef);
                                }}
                                name="FIELD37"
                                data={M01FormData?.COP9}
                                dispexpr="DisplayMember"
                                valexpr="ValueMember"
                                value={
                                  M01?.FIELD37 == ""
                                    ? M01FormData?.COP9?.[0]?.ValueMember
                                    : M01?.FIELD37
                                }
                                setEdit={(e) => {
                                  M01 = {
                                    ...M01,
                                    FIELD37: e,
                                  };
                                }}
                              />
                            </Grid.Col>
                          </Grid.Col>
                        ) : null}
                        {!visible.includes("costCen") ? (
                          <Grid.Col
                            span={6}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              padding: 0,
                            }}
                          >
                            <Grid.Col span={4}>
                              <Text style={{ fontSize: 12 }}>
                                {rc("COSTCENT")}:
                              </Text>{" "}
                              {/*Cost Center*/}
                            </Grid.Col>
                            <Grid.Col span={8}>
                              <PLComboBox
                                onKeyDown={(e) => {
                                  GlobalClass.formNextInput(e, formRef);
                                }}
                                data={M01FormData?.COP1}
                                dispexpr="DisplayMember"
                                valexpr="ValueMember"
                                value={M01?.M01CCF01}
                                setEdit={(e) => {
                                  M01 = {
                                    ...M01,
                                    M01CCF01: e,
                                  };
                                }}
                              />
                            </Grid.Col>
                          </Grid.Col>
                        ) : null}
                      </Grid.Col>
                      {
                        //   <Grid.Col
                        //   span={12}
                        //   style={{
                        //     display: "flex",
                        //     alignItems: "center",
                        //     padding: 0,
                        //   }}
                        // >
                        //   <Grid.Col
                        //     span={6}
                        //     style={{
                        //       display: "flex",
                        //       alignItems: "center",
                        //       padding: 0,
                        //     }}
                        //   >
                        //     <Grid.Col span={4}>
                        //       <Text style={{ fontSize: 12 }}>{rc("B100")}:</Text>
                        //       {/*Bank */}
                        //     </Grid.Col>
                        //     <Grid.Col span={8}>
                        //       <PLDataGrid
                        //       TmData={M01FormData?.P_M53NO}
                        //       data={M01FormData?.P_M53NODT?.jData}
                        //       value={M01?.FIELD61}
                        //       setEdit={(e) => {
                        //         M01 = {
                        //           ...M01,
                        //           FIELD61: e,
                        //         };
                        //       }}
                        //       />
                        //     </Grid.Col>
                        //   </Grid.Col>
                        // </Grid.Col>
                      }

                      {/* <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={2}>
                      <Text style={{ fontSize: 12 }}>
                        {rc("_F020010")}:
                      </Text>{" "}
                      Account No.
                    </Grid.Col>
                    <Grid.Col span={10}>
                      <PLTextBox
                        nmaxlength={50}
                        value={M01?.FIELD64}
                        setEdit={(e) => {
                          M01handlechange({
                            id: "FIELD64",
                            value: e.target.value,
                          });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col> */}
                      {/* <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={2}>
                      <Text style={{ fontSize: 12 }}>
                        {rc("_F020011")}:
                      </Text>{" "}
                      IFSC Code
                    </Grid.Col>
                    <Grid.Col span={10}>
                      <PLTextBox
                        nmaxlength={50}
                        value={M01?.FIELD65}
                        setEdit={(e) => {
                          M01 = {
                            ...M01,
                            FIELD65: e.target.value,
                          };
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col> */}
                    </Grid>
                  </>
                ) : (
                  ""
                )}

                <Card.Section style={{ marginTop: 20 }}>
                  <Text weight={500}>{rc("AMASTE02")}</Text>{" "}
                  {/* BALANCE MEthod*/}
                  <hr />
                </Card.Section>

                <Grid gutter={4}>
                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={2}>
                      <Text style={{ fontSize: 12 }}>{rc("BALMET02")}:</Text>{" "}
                      {/*Bill To Bill{BD000002} */}
                    </Grid.Col>
                    <Grid.Col span={10}>
                      <PLComboBox
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        disabled={
                          !"PR".includes(M01?.FIELD07) ||
                          GetGeneral?._BILLTOBIL !== "Y"
                        }
                        name="FIELD08"
                        copno={"B2B"}
                        value={
                          M01?.FIELD08 !== "1" && M01?.FIELD08 !== "2"
                            ? M01FormData?.COP1?.[1]?.ValueMember
                            : M01?.FIELD08
                        }
                        dispexpr="DisplayMember"
                        valexpr="ValueMember"
                        setEdit={(e) => {
                          M01handlechange({ id: "FIELD08", value: e?.value });
                          setB2B(e?.value);
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>

                  <Grid.Col
                    span={6}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={4}>
                      <Text style={{ fontSize: 12 }}>{rc("OPBAL")}:</Text>{" "}
                      {/*Opening Balance */}
                    </Grid.Col>
                    <Grid.Col span={8}>
                      <PLNumberBox
                        name="FIELD90"
                        decimalSeparator={true}
                        precision={2}
                        min={0}
                        value={Math?.abs(M01?.FIELD90)}
                        setEdit={(e) => {
                          console.log("here e of crdb",e)
                          M01handlechange({
                            id: "FIELD90",
                            value: creditDebitBM == "C" ? e : e * -1,
                          });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>

                  <Grid.Col
                    span={6}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={4}>
                      <Text style={{ fontSize: 12 }}>{rc("BL_ST17")}:</Text>
                      {/*Credit/Debit */}
                    </Grid.Col>
                    <Grid.Col span={8}>
                      <PLComboBox
                        onKeyDown={(e) => {
                          if(
                            M01FormData?.T05ENT &&
                            M01?.FIELD08 == "2" &&
                            M01?.FIELD90 != 0
                          ){
                            // console.log("here")
                          BillToBillTableFun();
                          }
                         else if (!"PR,BK".includes(M01?.FIELD07)) {
                            if (!tabDisabled.includes("_F020009")) {
                              // console.log("here1")
                              setActiveTab(rc("_F020009"));
                            } else if (!tabDisabled.includes("INTEREST")) {
                              // console.log("here2")
                              setActiveTab(rc("INTEREST"));
                            } else if (!tabDisabled.includes("B100")) {
                              // console.log("here3")
                              setActiveTab(rc("B100"));
                              bankRef?.current?.focus();
                            } else {
                              // console.log("here4")
                              GlobalClass.formNextInput(e, formRef);
                            }
                          }else{
                            GlobalClass.formNextInput(e, formRef);
                          }
                        }}
                        name="FIELD90CD"
                        data={M01FormData?.COP8}
                        dispexpr="DisplayMember"
                        valexpr="ValueMember"
                        value={creditDebitBM}
                        setEdit={(e) => {
                          M01 = {
                            ...M01,
                            FIELD90CD: e,
                          };
                          setCreditDebitBM(e);
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>

                  <Grid.Col
                    span={6}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={4}>
                      <Text style={{ fontSize: 12 }}>{rc("A_MAST78")}:</Text>
                      {/*Credit Limit */}
                    </Grid.Col>
                    <Grid.Col span={8}>
                      <PLNumberBox
                        id={"crlmt"}
                        name="FIELD10"
                        onKeyDown={(e) => {
                          if (
                            (e?.key == "Enter" || e?.key == "Tab") &&
                            !"PR".includes(M01?.FIELD07)
                          ) {
                            if (!tabDisabled.includes("_F020009")) {
                              setActiveTab(rc("_F020009"));
                            } else if (!tabDisabled.includes("INTEREST")) {
                              setActiveTab(rc("INTEREST"));
                            } else if (!tabDisabled.includes("B100")) {
                              setActiveTab(rc("B100"));
                              bankRef?.current?.focus();
                            } else {
                              GlobalClass.formNextInput(e, formRef);
                            }
                          }
                        }}
                        disabled={!"PR,BK".includes(M01?.FIELD07)}
                        // ntypeofentry="AM"
                        value={M01?.FIELD10}
                        setEdit={(e) => {
                          M01handlechange({ id: "FIELD10", value: e });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>

                  <Grid.Col
                    span={6}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={4}>
                      <Text style={{ fontSize: 12 }}>{rc("CRDTDAYS")}:</Text>
                      {/*Credit Days */}
                    </Grid.Col>
                    <Grid.Col span={8}>
                      <PLNumberBox
                        name="FIELD09"
                        onKeyDown={(e) => {
                          if (e?.key == "Tab" || e?.key == "Enter") {
                            if (!tabDisabled.includes("_F020009")) {
                              setActiveTab(rc("_F020009"));
                            } else if (!tabDisabled.includes("INTEREST")) {
                              setActiveTab(rc("INTEREST"));
                            } else if (!tabDisabled.includes("B100")) {
                              setActiveTab(rc("B100"));
                              bankRef?.current?.focus();
                            } else {
                              GlobalClass.formNextInput(e, formRef);
                            }
                          }
                        }}
                        // ntypeofentry="DD"
                        disabled={!"PR".includes(M01?.FIELD07)}
                        value={M01?.FIELD09}
                        setEdit={(e) =>
                          M01handlechange({ id: "FIELD09", value: e })
                        }
                      />
                    </Grid.Col>
                  </Grid.Col>
                </Grid>
              </div>
            </>
          </Tabs.Panel>{" "}
          {/*Main Details*/}
          <Tabs.Panel value={rc("CAPTDS")}>
            <>
              <div style={{ padding: "10px 0px" }}>
                <Grid gutter={4}>
                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={2}>
                      <Text style={{ fontSize: 12 }}>{rc("A_MAST85")}:</Text>
                      {/*Type of Deducte*/}
                    </Grid.Col>
                    <Grid.Col span={10}>
                      <PLDataGrid
                        width={"500px"}
                        setEdit={(e) => {
                          M01 = {
                            ...M01,
                            FIELD20: e?.FIELD01,
                            FIELD20NM: e?.FIELD02,
                          };
                          M01handlechange({ id: "FIELD20", value: e?.FIELD01 });
                        }}
                        handleClose={(e) => {
                          M01handlechange(e);
                        }}
                        value={{ value: M01?.FIELD20, label: M01?.FIELD20NM }}
                        type={"Type_of_Deducte"}
                        TmData={M01FormData?.P_M53DT}
                        // data={M01FormData?.P_M53DTDT?.jData}
                      />
                    </Grid.Col>
                  </Grid.Col>

                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 0,
                      }}
                    >
                      <Grid.Col span={4}>
                        <Text style={{ fontSize: 12 }}>{rc("TDSMAST")}:</Text>{" "}
                        {/* Tds lower rate */}
                      </Grid.Col>
                      <Grid.Col span={8}>
                        <PLComboBox
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          name="TDSLOWER"
                          data={M01FormData?.COP1}
                          dispexpr="DisplayMember"
                          valexpr="ValueMember"
                          value={M01?.TDSLOWER}
                          setEdit={(e) => {
                            M01 = {
                              ...M01,
                              TDSLOWER: e,
                            };
                            TDSratedisabled = "N".includes(e);
                            setTDSLowerRate(e);
                            M01handlechange({ id: "TDSLOWER", value: e });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>

                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 0,
                      }}
                    >
                      <Grid.Col span={4}>
                        <Text style={{ fontSize: 12 }}>{rc("CAP00138")}:</Text>
                        {/* Rate */}
                      </Grid.Col>
                      <Grid.Col span={8}>
                        <PLNumberBox
                          disabled={tdsLowerRate == "N"}
                          // ntypeofentry="RT"
                          name="FIELD15"
                          value={M48TC?.FIELD15}
                          setEdit={(e) =>
                            M48TChandlechange({ id: "FIELD15", value: e })
                          }
                        />
                      </Grid.Col>
                    </Grid.Col>
                  </Grid.Col>

                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 0,
                      }}
                    >
                      <Grid.Col span={4}>
                        <Text style={{ fontSize: 12 }}>{rc("TDSSURCH")}:</Text>{" "}
                        {/* ignore surcharge limit */}
                      </Grid.Col>
                      <Grid.Col span={8}>
                        <PLComboBox
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          name="TDSSURCH"
                          data={M01FormData?.COP1}
                          dispexpr="DisplayMember"
                          valexpr="ValueMember"
                          value={M01?.TDSSURCH}
                          setEdit={(e) => {
                            M01 = {
                              ...M01,
                              TDSSURCH: e.value,
                            };
                            M01handlechange({ id: "TDSSURCH", value: e });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>

                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 0,
                      }}
                    >
                      <Grid.Col span={4}>
                        <Text style={{ fontSize: 12 }}>{rc("TDSLIMIT")}:</Text>{" "}
                        {/* ignore TDS limit */}
                      </Grid.Col>
                      <Grid.Col span={8}>
                        <PLComboBox
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          name="TDSLIMIT"
                          data={M01FormData?.COP1}
                          dispexpr="DisplayMember"
                          valexpr="ValueMember"
                          value={M01?.TDSLIMIT}
                          setEdit={(e) => {
                            M01 = {
                              ...M01,
                              TDSLIMIT: e,
                            };
                            M01handlechange({ id: "TDSLIMIT", value: e });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  </Grid.Col>
                </Grid>

                <Card.Section style={{ marginTop: 20 }}>
                  <Text weight={500}>{rc("_MND0193")}</Text> {/* TDs payble */}
                  <hr />
                </Card.Section>

                <Grid gutter={4}>
                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={2}>
                      <Text style={{ fontSize: 12 }}>{rc("A_MAST45")}:</Text>{" "}
                      {/* TDS A/C*/}
                    </Grid.Col>
                    <Grid.Col span={10}>
                      <PLDataGrid
                        width={"500px"}
                        setEdit={(e) => {
                          M48TC = {
                            ...M48TC,
                            FIELD02: e.FIELD01,
                            M48TCF02NM: e?.FIELD02,
                          };
                          M48TChandlechange(e);
                        }}
                        handleClose={(e) => {
                          // console.log("handleClose", e);
                          M48TChandlechange(e);
                        }}
                        value={{
                          value: M48TC?.FIELD02,
                          label: M48TC?.M48TCF02NM,
                        }}
                        type={"TDSAC"}
                        TmData={M01FormData?.P_M01TDS}
                        // data={M01FormData?.P_M01TDSDT?.jData}
                      />
                    </Grid.Col>
                  </Grid.Col>

                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={2}>
                      <Text style={{ fontSize: 12 }}>{rc("A_MAST48")}:</Text>{" "}
                      {/* Calculation on */}
                    </Grid.Col>
                    <Grid.Col span={10}>
                      <PLComboBox
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        name="FIELD13"
                        data={M01FormData?.M48CBOD}
                        dispexpr="DisplayMember"
                        valexpr="ValueMember"
                        value={M48TC?.FIELD13}
                        setEdit={(e) => {
                          M48TC = {
                            ...M48TC,
                            FIELD13: e,
                          };
                          M48TChandlechange({ id: "FIELD13", value: e });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                </Grid>

                <Card.Section style={{ marginTop: 20 }}>
                  <Text weight={500}>{rc("_MND0194")}</Text>
                  {/* TDS (deducated by other)*/}
                  <hr />
                </Card.Section>

                <Grid gutter={4}>
                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={2}>
                      <Text style={{ fontSize: 12 }}>{rc("A_MAST45")}:</Text>
                      {/*TDS A/C*/}
                    </Grid.Col>
                    <Grid.Col span={10}>
                      <PLDataGrid
                        width={"500px"}
                        setEdit={(e) => {
                          M48TD = {
                            ...M48TD,
                            FIELD02: e.FIELD01,
                            M48TDF02NM: e?.FIELD02,
                          };
                          M48TDhandlechange(e);
                        }}
                        handleClose={(e) => {
                          // console.log("handleClose", e);
                          M48TDhandlechange(e);
                        }}
                        value={{
                          value: M48TD?.FIELD02,
                          label: M48TD?.M48TDF02NM,
                        }}
                        type={"TDS_AC"}
                        TmData={M01FormData?.P_M01TDS}
                        // data={M01FormData?.P_M01TDSDT?.jData}
                      />
                    </Grid.Col>
                  </Grid.Col>

                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={2}>
                      <Text style={{ fontSize: 12 }}>{rc("A_MAST48")}:</Text>
                      {/*  Calculation on */}
                    </Grid.Col>
                    <Grid.Col span={10}>
                      <PLComboBox
                        onKeyDown={(e) => {
                          setActiveTab(rc("A_MAST76"));
                        }}
                        name="FIELD13"
                        data={M01FormData?.M48CBOC}
                        dispexpr="DisplayMember"
                        valexpr="ValueMember"
                        value={M48TD?.FIELD13}
                        setEdit={(e) => {
                          M48TD = {
                            ...M48TD,
                            FIELD13: e,
                          };
                          M48TDhandlechange({ id: "FIELD13", value: e });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                </Grid>
              </div>
            </>
          </Tabs.Panel>{" "}
          {/*TDS*/}
          <Tabs.Panel value={rc("PLUSM500")}>
            <>
              <div style={{ padding: "10px 0px" }}>
                <Grid gutter={4}>
                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={2}>
                      <Text style={{ fontSize: 12 }}>{rc("A_MAST85")}:</Text>{" "}
                      {/*Type of Deductee*/}
                    </Grid.Col>
                    <Grid.Col span={10}>
                      <PLDataGrid
                        autoFocus={true}
                        width={"500px"}
                        setEdit={(e) => {
                          M01 = {
                            ...M01,
                            FIELD24: e.FIELD01,
                            FIELD24NM: e.FIELD02,
                          };
                          M01handlechange();
                        }}
                        handleClose={(e) => {
                          // console.log("handleClose", e);
                          M01handlechange(e);
                        }}
                        value={{ value: M01?.FIELD24, label: M01?.FIELD24NM }}
                        // type={"Nature_of_Payment"}
                        TmData={M01FormData?.P_PLTCSNTD}
                        // data={M01FormData?.P_PLTCSNTDDT?.jData}
                      />
                    </Grid.Col>
                  </Grid.Col>

                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={2}>
                      <Text style={{ fontSize: 12 }}>{rc("PLUSM485")}:</Text>{" "}
                      {/* TCS A/C.*/}
                    </Grid.Col>
                    <Grid.Col span={10}>
                      <PLDataGrid
                        width={"500px"}
                        setEdit={(e) => {
                          M54 = {
                            ...M54,
                            FIELD05: e.FIELD01,
                            M54F05NM: e?.FIELD02,
                          };
                          M54handlechange(e);
                        }}
                        handleClose={(e) => {
                          // console.log("handleClose", e);
                          M54handlechange(e);
                        }}
                        value={{ value: M54?.FIELD05, label: M54?.M54F05NM }}
                        // type={"Nature_of_Payment"}
                        TmData={M01FormData?.P_M01TCS}
                        // data={M01FormData?.P_M01TCSDT?.jData}
                      />
                    </Grid.Col>
                  </Grid.Col>

                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={2}>
                      <Text style={{ fontSize: 12 }}>{rc("PRICEL_4")}:</Text>
                      {/* Rate Type */}
                    </Grid.Col>
                    <Grid.Col span={10}>
                      <PLComboBox
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        name="FIELD06"
                        data={M01FormData?.COP78}
                        dispexpr="DisplayMember"
                        valexpr="ValueMember"
                        value={M54?.FIELD06}
                        setEdit={(e) => {
                          M54 = {
                            ...M54,
                            FIELD06: e,
                          };
                          setRateType(e);
                          M54handlechange({ id: "FIELD06", value: e });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>

                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={2}>
                      <Text style={{ fontSize: 12 }}>{rc("CAP00138")}:</Text>
                      {/* Rate  */}
                    </Grid.Col>
                    <Grid.Col span={10}>
                      <PLNumberBox
                        name="FIELD07"
                        disabled={
                          ratetype == "H"
                            ? false
                            : ratetype == "L"
                            ? false
                            : true
                        }
                        // ntypeofentry="RT"
                        value={M54?.FIELD07}
                        setEdit={(e) => {
                          M54handlechange({ id: "FIELD07", value: e });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>

                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={2}>
                      <Text style={{ fontSize: 12 }}>{rc("PLUSM131")}:</Text>
                      {/* Expense Rate */}
                    </Grid.Col>
                    <Grid.Col span={10}>
                      <PLComboBox
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        disabled={
                          ratetype == "H"
                            ? false
                            : ratetype == "L"
                            ? false
                            : true
                        }
                        name="FIELD11"
                        data={M01FormData?.COP80}
                        dispexpr="DisplayMember"
                        valexpr="ValueMember"
                        value={M54?.FIELD11}
                        setEdit={(e) => {
                          M54 = {
                            ...M54,
                            FIELD11: e,
                          };
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>

                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={2}>
                      <Text style={{ fontSize: 12 }}>{rc("TDSSURCH")}:</Text>{" "}
                      {/* ignore Surcharge limit? */}
                    </Grid.Col>
                    <Grid.Col span={10}>
                      <PLComboBox
                        onKeyDown={(e) => {
                          setActiveTab(rc("A_MAST76"));
                        }}
                        name="FIELD08"
                        data={M01FormData?.COP1}
                        dispexpr="DisplayMember"
                        valexpr="ValueMember"
                        value={M54?.FIELD08}
                        setEdit={(e) => {
                          M54 = {
                            ...M54,
                            FIELD08: e,
                          };
                          M54handlechange({ id: "FIELD08", value: e });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                </Grid>
              </div>
            </>
          </Tabs.Panel>{" "}
          {/*TCS*/}
          <Tabs.Panel value={rc("INTEREST")}>
            <>
              <div style={{ padding: "10px 0px" }}>
                <Grid gutter={4}>
                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={2}>
                      <Text style={{ fontSize: 12 }}>{rc("INTPER_1")}:</Text>
                      {/* interest % */}
                    </Grid.Col>
                    <Grid.Col span={10}>
                      <PLNumberBox
                        autoFocus={true}
                        // ntypeofentry='P4'
                        name="FIELD03"
                        value={M48I?.FIELD03}
                        setEdit={(e) =>
                          M48Ihandlechange({ id: "FIELD03", value: e })
                        }
                      />
                    </Grid.Col>
                  </Grid.Col>

                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={2}>
                      <Text style={{ fontSize: 12 }}>{rc("M48_09")}:</Text>{" "}
                      {/* interest a/c*/}
                    </Grid.Col>
                    <Grid.Col span={10}>
                      <PLDataGrid
                      // ref={interestRef}
                      openOnFocus={true}
                        width={"500px"}
                        setEdit={(e) => {
                          // console.log("Hello from interest e",e)
                          M48I = {
                            ...M48I,
                            FIELD02: e.FIELD01,
                            M48IF02NM: e?.FIELD02,
                          };
                          M48Ihandlechange(e);
                        }}
                        handleClose={(e) => {
                          GlobalClass.formNextInput(e,formRef)
                        }}
                        value={{ value: M48I?.FIELD02, label: M48I?.M48IF02NM }}
                        type={"interestAC"}
                        TmData={M01FormData?.P_M01I}
                        // data={M01FormData?.P_M01IDT?.jData}
                      />
                    </Grid.Col>
                  </Grid.Col>

                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={2}>
                      <Text style={{ fontSize: 12 }}>{rc("A_MAST45")}:</Text>
                      {/* tds a/c */}
                    </Grid.Col>
                    <Grid.Col span={10}>
                      <PLDataGrid
                      // ref={tdsRef}
                        openOnFocus={true}
                        width={"500px"}
                        setEdit={(e) => {
                          M48I = {
                            ...M48I,
                            FIELD14: e.FIELD01,
                            M48IF14NM: e?.FIELD02,
                          };
                          M48Ihandlechange(e);
                        }}
                        handleClose={(e) => {
                          GlobalClass.formNextInput(e,formRef);
                        }}
                        value={{ value: M48I?.FIELD14, label: M48I?.M48IF14NM }}
                        type={"tds_AC"}
                        TmData={M01FormData?.P_M01T}
                        // data={M01FormData?.P_M01TDT?.jData}
                      />
                    </Grid.Col>
                  </Grid.Col>
                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={2}>
                      <Text style={{ fontSize: 12 }}>Day in year:</Text>
                    </Grid.Col>
                    <Grid.Col span={10}>
                      <PLNumberBox
                        defaultval={365}
                        value={M48I?.FIELD11}
                        setEdit={(e) => {
                          M48Ihandlechange({
                            id: "FIELD11",
                            value: e,
                          });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={2}>
                      <Text style={{ fontSize: 12 }}>{rc("A_MAST49")}:</Text>
                      {/* cr/db*/}
                    </Grid.Col>
                    <Grid.Col span={10}>
                      <PLComboBox
                        onKeyDown={(e) => {
                          if (e?.key == "Enter" || e?.key == "Tab") {
                            if (!tabDisabled.includes("B100")) {
                              setActiveTab(rc("B100"));
                              bankRef?.current?.focus();
                            } else {
                              GlobalClass.formNextInput(e, formRef);
                            }
                          }
                        }}
                        name="FIELD12"
                        data={M01FormData?.COP8}
                        dispexpr="DisplayMember"
                        valexpr="ValueMember"
                        value={M48I?.FIELD12}
                        setEdit={(e) => {
                          M48I = {
                            ...M48I,
                            FIELD12: e,
                          };
                          if (!tabDisabled.includes("B100")) {
                            setActiveTab(rc("B100"));
                            setTimeout(() => {
                              
                            bankRef?.current?.focus();
                            }, 500);
                          } else {
                            GlobalClass.formNextInput(e, formRef);
                          }
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                </Grid>
              </div>
            </>
          </Tabs.Panel>{" "}
          {/*Interest*/}
          <Tabs.Panel value={rc("_F020009")}>
            <>
              <div style={{ padding: "10px 0px" }}>
                <Grid gutter={4}>
                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={2}>
                      <Text style={{ fontSize: 12 }}>{rc("CAP00117")}:</Text>
                      {/* name */}
                    </Grid.Col>
                    <Grid.Col span={10}>
                      <PLTextBox
                        disabled={true}
                        name="FIELD61"
                        value={M02?.FIELD61}
                        setEdit={(e) => {
                          M02handlechange({
                            id: "FIELD61",
                            value: e.target.value,
                          });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>

                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={2}>
                      <Text style={{ fontSize: 12 }}>{rc("AM21")}:</Text>{" "}
                      {/* contact person */}
                    </Grid.Col>
                    <Grid.Col span={5}>
                      <PLTextBox
                        autoFocus={true}
                        name="FIELD50"
                        value={M02?.FIELD50}
                        setEdit={(e) => {
                          M02handlechange({
                            id: "FIELD50",
                            value: e.target.value,
                          });
                        }}
                      />
                    </Grid.Col>
                    <Grid.Col span={5}>
                      <PLTextBox
                        name="FIELD51"
                        value={M02?.FIELD51}
                        setEdit={(e) => {
                          M02handlechange({
                            id: "FIELD51",
                            value: e.target.value,
                          });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>

                  <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
                    <Grid.Col span={2} style={{ marginTop: 10 }}>
                      <Text style={{ fontSize: 12 }}>{rc("_F020009")}:</Text>
                      {/* Address rc('B_ADDRES') */}
                    </Grid.Col>
                    <Grid.Col span={10}>
                      <PLTextBox
                        name="FIELD02"
                        value={M02?.FIELD02}
                        setEdit={(e) => {
                          M02handlechange({
                            id: "FIELD02",
                            value: e.target.value,
                          });
                        }}
                      />
                      <PLTextBox
                        // maxLength={30}
                        name="FIELD03"
                        value={M02?.FIELD03}
                        setEdit={(e) => {
                          M02handlechange({
                            id: "FIELD03",
                            value: e.target.value,
                          });
                        }}
                      />
                      <PLTextBox
                        // maxLength={30}
                        name="FIELD04"
                        value={M02?.FIELD04}
                        setEdit={(e) => {
                          M02handlechange({
                            id: "FIELD04",
                            value: e.target.value,
                          });
                        }}
                      />
                      <PLTextBox
                        // maxLength={30}
                        name="FIELD15"
                        value={M02?.FIELD15}
                        setEdit={(e) => {
                          M02handlechange({
                            id: "FIELD15",
                            value: e.target.value,
                          });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>

                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 0,
                      }}
                    >
                      <Grid.Col span={4}>
                        <Text style={{ fontSize: 12 }}>{rc("_F020017")}:</Text>
                        {/* city  _VTSAT*/}
                      </Grid.Col>
                      <Grid.Col span={8}>
                        <PLDataGrid
                        ref={cityRef1}
                        openOnFocus={true}
                          width={"500px"}
                          position={"target"}
                          setEdit={(e) => {
                            M02 = { ...M02, FIELD08: e.FIELD01 };
                            M02handlechange({
                              id: "FIELD08",
                              value: e?.FIELD01,
                            });
                          }}
                          handleClose={(e) => {
                            GlobalClass.formNextInput(e,formRef);
                          }}
                          value={{ value: M02?.FIELD08, label: M01?.FIELD17 }}
                          type={"address_city"}
                          TmData={M01FormData?.P_M31T}
                          // data={M01FormData?.P_M31TDT?.jData}
                        />
                      </Grid.Col>
                    </Grid.Col>

                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 0,
                      }}
                    >
                      <Grid.Col span={4}>
                        <Text style={{ fontSize: 12 }}>{rc("_F020018")}:</Text>
                        {/* PinCode */}
                      </Grid.Col>
                      <Grid.Col span={8}>
                        <PLTextBox
                          nmaxlength={6}
                          entrytype={"numeric"}
                          name="FIELD07"
                          value={M02?.FIELD07}
                          setEdit={(e) => {
                            M02handlechange({
                              id: "FIELD07",
                              value: e?.target?.value,
                            });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  </Grid.Col>

                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={2}>
                      <Text style={{ fontSize: 12 }}>{rc("ADDR_3")}:</Text>
                      {/* area */}
                    </Grid.Col>
                    <Grid.Col span={10}>
                      <PLDataGrid
                      ref={areaRef1}
                      openOnFocus={true}
                        width={"500px"}
                        position={"target"}
                        setEdit={(e) => {
                          M02 = { ...M02, FIELD09: e.FIELD01 };
                          M02handlechange({ id: "FIELD09", value: e?.FIELD01 });
                        }}
                        handleClose={(e) => {
                          GlobalClass.formNextInput(e,formRef);
                        }}
                        value={{ value: M02?.FIELD09, label: M01?.FIELD19 }}
                        type={"address_area"}
                        TmData={M01FormData?.P_M31A}
                        // data={M01FormData?.P_M31ADT?.jData}
                      />
                    </Grid.Col>
                  </Grid.Col>

                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={2}>
                      <Text style={{ fontSize: 12 }}>{rc("ADDR_4")}:</Text>
                      {/* category */}
                    </Grid.Col>
                    <Grid.Col span={10}>
                      <PLDataGrid
                      // ref={categoryRef}
                      // setFocus={(e)=>{
                      //   categoryRef?.current?.handlePopoverToggle();
                      // }}
                      openOnFocus={true}
                        width={"500px"}
                        position={"target"}
                        setEdit={(e) => {
                          M02 = { ...M02, FIELD10: e.FIELD01 };
                          M02handlechange({ id: "FIELD10", value: e?.FIELD01 });
                        }}
                        handleClose={(e) => {
                          GlobalClass.formNextInput(e,formRef);
                        }}
                        value={props?.OBJ?.p0 == "A" ? "" : M02?.FIELD10}
                        type={"address_category"}
                        TmData={M01FormData?.P_M31R}
                        data={M01FormData?.P_M31RDT?.jData}
                      />
                    </Grid.Col>
                  </Grid.Col>

                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={2}>
                      <Text style={{ fontSize: 12 }}>{rc("PHONE_05")}:</Text>
                      {/* Phoneno */}
                    </Grid.Col>
                    <Grid.Col span={3}>
                      <PLTextBox
                        nmaxlength={10}
                        name="FIELD21"
                        entrytype={"numeric"}
                        value={M02?.FIELD21}
                        setEdit={(e) => {
                          M02handlechange({
                            id: "FIELD21",
                            value: e.target.value,
                          });
                        }}
                      />
                    </Grid.Col>
                    <Grid.Col span={3}>
                      <PLTextBox
                        nmaxlength={10}
                        name="FIELD22"
                        entrytype={"numeric"}
                        value={M02?.FIELD22}
                        setEdit={(e) => {
                          M02handlechange({
                            id: "FIELD22",
                            value: e.target.value,
                          });
                        }}
                      />
                    </Grid.Col>
                    <Grid.Col span={3}>
                      <PLTextBox
                        nmaxlength={10}
                        name="FIELD23"
                        entrytype={"numeric"}
                        value={M02?.FIELD23}
                        setEdit={(e) => {
                          M02handlechange({
                            id: "FIELD23",
                            value: e.target.value,
                          });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>

                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={2}>
                      <Text style={{ fontSize: 12 }}>{rc("AM12")}:</Text>{" "}
                      {/* fax */}
                    </Grid.Col>
                    <Grid.Col span={5}>
                      <PLTextBox
                        name="FIELD31"
                        value={M02?.FIELD31}
                        setEdit={(e) => {
                          M02handlechange({
                            id: "FIELD31",
                            value: e.target.value,
                          });
                        }}
                      />
                    </Grid.Col>
                    <Grid.Col span={5}>
                      <PLTextBox
                        name="FIELD32"
                        value={M02?.FIELD32}
                        setEdit={(e) => {
                          M02handlechange({
                            id: "FIELD32",
                            value: e.target.value,
                          });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>

                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 0,
                      }}
                    >
                      <Grid.Col span={4}>
                        <Text style={{ fontSize: 12 }}>{rc("AM10")}:</Text>
                        {/* mobileno */}
                      </Grid.Col>
                      <Grid.Col span={8}>
                        <PLTextBox
                          nmaxlength={10}
                          name="FIELD35"
                          entrytype="numeric"
                          value={M02?.FIELD35}
                          setEdit={(e) => {
                            M02handlechange({
                              id: "FIELD35",
                              value: e.target.value,
                            });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>

                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 0,
                      }}
                    >
                      <Grid.Col span={4}>
                        <Text style={{ fontSize: 12 }}>{rc("AM13")}:</Text>
                        {/*  E-Mail-ID*/}
                      </Grid.Col>
                      <Grid.Col span={8}>
                        <PLTextBox
                          entrytype="EMAIL"
                          name="FIELD43"
                          value={M02?.FIELD43}
                          setEdit={(e) => {
                            M02handlechange({
                              id: "FIELD43",
                              value: e.target.value,
                            });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  </Grid.Col>

                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={2}>
                      <Text style={{ fontSize: 12 }}>{rc("TMP13")}:</Text>
                      {/* website */}
                    </Grid.Col>
                    <Grid.Col span={10}>
                      <PLTextBox
                        name="FIELD42"
                        value={M02?.FIELD42}
                        setEdit={(e) => {
                          M02handlechange({
                            id: "FIELD42",
                            value: e.target.value,
                          });
                        }}
                        onKeyDown={(e) => {
                          if (e?.key == "Enter" || e?.key == "Tab") {
                            if (!tabDisabled.includes("INTEREST")) {
                              setActiveTab(rc("INTEREST"));
                            } else if (!tabDisabled.includes("B100")) {
                              setActiveTab(rc("B100"));
                              bankRef?.current?.focus();
                            } else {
                              GlobalClass.formNextInput(e, formRef);
                            }
                          }
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                </Grid>
              </div>
            </>
          </Tabs.Panel>{" "}
          {/*Address*/}
          <Tabs.Panel value={rc("B100")}>
            <>
              <div style={{ padding: "10px 0px" }}>
                <Grid gutter={4}>
                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={2}>
                      <Text style={{ fontSize: 12 }}>{rc("_F020007")}:</Text>
                      {/* Bank Name */}
                    </Grid.Col>
                    <Grid.Col span={10}>
                      <PLTextBox
                        ref={bankRef}
                        // autoFocus={true}
                        name="FIELD61"
                        value={M01?.FIELD61}
                        setEdit={(e) => {
                          M01handlechange({
                            id: "FIELD61",
                            value: e.target.value,
                          });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>

                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={2}>
                      <Text style={{ fontSize: 12 }}>{rc("_F020008")}:</Text>
                      {/* Branch Name */}
                    </Grid.Col>
                    <Grid.Col span={10}>
                      <PLTextBox
                        name="FIELD62"
                        value={M01?.FIELD62}
                        setEdit={(e) => {
                          M01handlechange({
                            id: "FIELD62",
                            value: e.target.value,
                          });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>

                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={2}>
                      <Text style={{ fontSize: 12 }}>{rc("_F020009")}:</Text>{" "}
                      {/* Address*/}
                    </Grid.Col>
                    <Grid.Col span={10}>
                      <PLTextBox
                        name="FIELD63"
                        value={M01?.FIELD63}
                        setEdit={(e) => {
                          M01handlechange({
                            id: "FIELD63",
                            value: e.target.value,
                          });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>

                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={2}>
                      <Text style={{ fontSize: 12 }}>{rc("_F020010")}:</Text>
                      {/* Account No.*/}
                    </Grid.Col>
                    <Grid.Col span={10}>
                      <PLTextBox
                        name="FIELD64"
                        value={M01?.FIELD64}
                        setEdit={(e) => {
                          M01handlechange({
                            id: "FIELD64",
                            value: e.target.value,
                          });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>

                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={2}>
                      <Text style={{ fontSize: 12 }}>{rc("_F020011")}:</Text>
                      {/* IFSC Code*/}
                    </Grid.Col>
                    <Grid.Col span={10}>
                      <PLTextBox
                        name="FIELD65"
                        value={M01?.FIELD65}
                        setEdit={(e) => {
                          M01handlechange({
                            id: "FIELD65",
                            value: e.target.value,
                          });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>

                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={2}>
                      <Text style={{ fontSize: 12 }}>{rc("_F020012")}:</Text>
                      {/* IBAN Name*/}
                    </Grid.Col>
                    <Grid.Col span={10}>
                      <PLTextBox
                        name="FIELD66"
                        value={M01?.FIELD66}
                        setEdit={(e) => {
                          M01handlechange({
                            id: "FIELD66",
                            value: e.target.value,
                          });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>

                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={2}>
                      <Text style={{ fontSize: 12 }}>{rc("_F020013")}:</Text>
                      {/* SWIFT Code */}
                    </Grid.Col>
                    <Grid.Col span={10}>
                      <PLTextBox
                        name="FIELD67"
                        value={M01?.FIELD67}
                        setEdit={(e) => {
                          M01handlechange({
                            id: "FIELD67",
                            value: e.target.value,
                          });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                </Grid>
              </div>
            </>
          </Tabs.Panel>{" "}
          {/* <Tabs.Panel value={rc("User Field")}>
      <Grid>
        <Grid.Col span={12} style={{marginTop:"12px"}}>
            <Group spacing={'xs'} position='center'>
            <Button
            size="sm"
            onClick={() => {
              if(M01?.FIELD05 == "G000001A" || M01?.FIELD05=="G0000002" || M01?.FIELD05 == "GPV3CE1U"){
                functionOne(functionTwo);
              }else{
                functionTwo();
              }
            }}
          >
          User field
          </Button>
            </Group>
        </Grid.Col>
        </Grid>
      </Tabs.Panel>{" "} */}
          {/* User field */}
          <div style={{ padding: "5px 0px" }}>
            <Group spacing={"xs"} position="center">
              {props?.OBJ?.p0 == "E" ? (
                <Button
                  size="sm"
                  disabled={UFM01?.length==0}
                  style={{ width: "103px",height:"30px" }}
                  onClick={() => {
                    // if (
                    //   M01?.FIELD05 == "G000001A" ||
                    //   M01?.FIELD05 == "G0000002" ||
                    //   M01?.FIELD05 == "GPV3CE1U"
                    // ) {
                    //   Promise.resolve(setUFClicked(true)).then(() => {
                    //     functionOne();
                    //   });
                    // } else {
                      Promise.resolve(setUFClicked(true)).then(() => {
                        functionTwo();
                      });
                    // }
                  }}
                >
                  User field
                </Button>
              ) : (
                ""
              )}
              <Button
                  size="sm"
                  style={{ width: "80px",height:"30px" }}
                onClick={() => submitM01FormData()}
                onKeyDown={(e) => {
                  if (e.key == "Enter") {
                    e.preventDefault();
                    submitM01FormData();
                  }
                }}
                disabled={
                  validation?.aadhar == false ||
                  validation?.gst == false ||
                  validation?.pan == false
                }
              >
                Ok
              </Button>
            </Group>
          </div>
        </Tabs>
      ) : (
        M01FormData==null ? <>{GlobalClass.Alert("info", props.index)}</> :
        <div style={{ paddingBottom: "0px" }} >
          <Grid gutter={4}>
            <Grid.Col
              span={12}
              style={{ display: "flex", alignItems: "center", padding: 0 }}
            >
              <Grid.Col span={3}>
                <Text style={{ fontSize: 12 }}>{rc("OPBAL")}:</Text>
              </Grid.Col>
              <Grid.Col span={9}>
                <PLNumberBox
                  name="FIELD90"
                  decimalSeparator={true}
                  precision={2}
                  min={0}
                  value={Math?.abs(M01?.FIELD90)}
                  setEdit={(e) => {
                    // console.log("here from crdb")
                    M01handlechange({
                      id: "FIELD90",
                      value: creditDebitBM == "C" ? e : e * -1,
                    });
                  }}
                />
              </Grid.Col>
            </Grid.Col>
            <Grid.Col
              span={12}
              style={{ display: "flex", alignItems: "center", padding: 0 }}
            >
              <Grid.Col span={3}>
                <Text style={{ fontSize: 12 }}>{rc("BL_ST17")}:</Text>
              </Grid.Col>
              <Grid.Col span={9}>
                <PLComboBox
                  onKeyDown={(e) => {
                    if (e.key == "Tab" || e.key == "Enter") {
                      if (
                        M01FormData?.T05ENT &&
                        M01?.FIELD08 == "2" &&
                        M01?.FIELD90 != 0
                      ) {
                        BillToBillTableFun();
                      }
                    }else{
                      GlobalClass.formNextInput(e, formRef);
                    }
                  }}
                  name="FIELD90CD"
                  data={M01FormData?.COP8}
                  dispexpr="DisplayMember"
                  valexpr="ValueMember"
                  value={creditDebitBM}
                  setEdit={(e) => {
                    M01 = {
                      ...M01,
                      FIELD90CD: e,
                    };
                    setCreditDebitBM(e);
                  }}
                />
              </Grid.Col>
            </Grid.Col>
            <Grid.Col span={12}>
              {/* <div style={{ padding: "5px 0px" }}> */}
                <Group spacing={"xs"} position="center">
                  <Button
                    id={"crlmt"}
                    size="sm"
                    style={{ width: "100px",height:"30px" }}
                    onClick={() => submitM01FormData()}
                    onKeyDown={(e) => {
                      if (e.key == "Enter") {
                        e.preventDefault();
                        submitM01FormData();
                      }
                    }}
                  >
                    Ok
                  </Button>
                </Group>
              {/* </div> */}
            </Grid.Col>
          </Grid>
        </div>
      )}
    </div>
  );
};

export default M01Forms;
