import React from "react";
import Store from "../../utils/store";
import { ModalFunction } from "../../utils/slices/ModalSlice";
import StockJournalAddForm from "./StockJournalAddForm";
import { Text } from "@mantine/core";
import GlobalClass from "../../utils/GlobalClass";
import { DataSaveLoading } from "../../utils/slices/DataSaveLoadingSlice";
import StockTransferForm from "./StockTransfer/StockTransferForm";
import { GetNullTransactionData, TransactionDeleteApi } from "../../utils/TransactionSlices/Transaction";
import PLRange from "../../PlusComponent/PLRange";
import SIDetailPage from "../SaleEntry/SIDetailPage";
import { TM_PRINTVF } from "../../components/PrintVoucherReport";

export function VouEntAct(props) {
  const OBJ = JSON.parse(props);

  // console.log("props VouEntAct===============>", data);

  if (OBJ?.p0 == "A") {
    Store.dispatch(
      ModalFunction({
        onclose: () => { },
        MTitle: <Text fw={700}>{OBJ?.text} {OBJ?.BtnPnlObj?.name}</Text>,
        MAction: true,
        MSize: "98%",
        // position:'right',
        tab: OBJ.page.startsWith("P_") ? false : true,
        MBody: (i) => <StockJournalAddForm obj={OBJ} index={i} />,
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        },
        onClickCloseButton: () => {
          Promise.resolve(Store.dispatch(GetNullTransactionData()));
        },
      })
    );
  } else if (OBJ?.id && OBJ?.p0 == "E") {
    Store.dispatch(
      ModalFunction({
        onclose: () => { },
        MTitle: <Text fw={700}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "98%",
        // position:'right',
        tab: OBJ.page.startsWith("P_") ? false : true,
        MBody: (i) => <StockJournalAddForm obj={OBJ} index={i} />,
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        },
        onClickCloseButton: () => {
          Promise.resolve(Store.dispatch(GetNullTransactionData()));
        },
      })
    );
  } else if (OBJ.p0 === "D" && OBJ.id) {
    // if (window.confirm("Confirm to Delete ?")) {
    //    Store.dispatch(TransactionDeleteApi(OBJ));
    // }

    GlobalClass.Confirm(
      "red",
      "Are you sure to delete selected data?",
      "It will be Deleted Forever and Cannot Be Recovered!!!",
      () => {
        Store.dispatch(TransactionDeleteApi(OBJ));
      },
      () => { },
      "Cancel",
      "Confirm"
    )
  } else if (OBJ.p0 === "U" && OBJ?.id) {
    console.log(OBJ?.id, "data?.id")

    // UserField
    Store.dispatch(
      ModalFunction({
        MTitle: <Text fw={700}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "50%",
        // position:'right',
        MBody: (i) => <StockJournalAddForm obj={OBJ} index={i} />,
        MClose: true,
        onclose: () => { },
        onClickCloseButton: () => {
          Promise.resolve(Store.dispatch(GetNullTransactionData()));
        },
      })
    );
  } else if (OBJ.p0 === 'T' && OBJ?.id) { //Audit
    // if (window.confirm('Confirm to Audit ?')) {
    //   Store.dispatch(TransactionDeleteApi(OBJ))
    // }

    GlobalClass.Confirm(
      "blue",
      "Confirm to Audit ?",
      "Are you sure you want to proceed!!!",
      () => {
        Store.dispatch(TransactionDeleteApi(OBJ))
      },
      () => { },
      "Cancel",
      "Confirm"
    )

  }
  else if (OBJ.p0 == "R") { //Range Button
    Store.dispatch(
      ModalFunction({
        onclose: () => { },
        MTitle: <Text fw={500}>Enter Voucher No. Range</Text>,
        MAction: true,
        MSize: "98%",
        position: "right",
        MBody: (i) => <PLRange obj={OBJ} index={i} />,
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        },
      })
    );
  } else if (OBJ.id && OBJ.p0 === "P") {
    // Print
    // eval('TM_PRINTVF('+OBJ+')')
    TM_PRINTVF(props);
  } else if (OBJ.p0 === 'VD' && OBJ?.id) { // Detail
    Store.dispatch(ModalFunction({
      MTitle: <Text fw={700}>{OBJ?.text}</Text>,
      MAction: true,
      MSize: "50%",
      MBody: (i) => (<SIDetailPage obj={OBJ} index={i} notShowQty={true} />),
      MClose: true,
      onclose: () => { }
    }))
  }

  // else if (data?.id && data?.p0 == "D") {
  //   // console.log(data?.id, "data?.id");

  //   if (window.confirm("Confirm to Delete?")) {
  //     Promise.resolve(Store.dispatch(DataSaveLoading(true)))
  //       .then(
  //         Promise.resolve(
  //           GlobalClass.Notify(
  //             "info",
  //             "Info: " + "Delete Progress",
  //             "Please Wait Fetching Data"
  //           )
  //         )
  //       )
  //       .then(() => Store.dispatch(GetStockJournalEnt(data)))
  //       .then((data) => {

  //         if (data?.meta?.requestStatus == "fulfilled") {
  //           Promise.resolve(Store.dispatch(GetStockJournalGen()))
  //             .then(() => Store.dispatch(GetStockJournalTM()))
  //             .then(() =>
  //               Promise.resolve(Store.dispatch(DataSaveLoading(false)))
  //             )
  //             .then(() =>
  //               GlobalClass.Notify(
  //                 "success",
  //                 "Successful",
  //                 "Deleted Successfully"
  //               )
  //             );
  //         }
  //       });
  //   }
  // }
}
