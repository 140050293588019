import { Text } from "@mantine/core";
import Store from "../../utils/store";
import { ModalFunction } from "../../utils/slices/ModalSlice";
import { SaleUnitForm } from "./SaleUnitForm";
import { SalePurchaseDeleteAPI } from "../../utils/slices/ProductList";
import GlobalClass from "../../utils/GlobalClass";

export const M35AED = (data) => {
    const OBJ = JSON.parse(data);
    console.log('function call', OBJ);

    if (OBJ.p0 === 'A') {
        Store.dispatch(
            ModalFunction({
                onclose: (e) => {},
                MTitle: <Text fw={700}>{OBJ?.text}</Text>,
                MAction: true,
                MSize: "60%",
                position:'right',
                MBody: (i) => <SaleUnitForm OBJ={OBJ} index={i} />,
                MClose: true,
                Overlay: {
                  backgroundOpacity: 0.8,
                  blur: 5,
                },
            })
        )
        //====================================================
        // modals.open({
        //     title:<Text style={{fontWeight:700}}>{OBJ?.text}</Text>,
        //     size: '100%',
        //     children: (
        //         <>
        //             <ProductListForm OBJ={OBJ} />
        //         </>
        //     ),
        // })
    }
    else if (OBJ.id && OBJ.p0 === 'E') {
        Store.dispatch(
            ModalFunction({
                onclose: (e) => {},
                MTitle: <Text fw={700}>{OBJ?.text}</Text>,
                MAction: true,
                MSize: "xl",
                position:'right',
                MBody: (i) => <SaleUnitForm OBJ={OBJ} index={i} />,
                MClose: true,
                Overlay: {
                  backgroundOpacity: 0.8,
                  blur: 5,
                },
            })
        )
        //================================================================
        // modals.open({
        //     title:<Text style={{fontWeight:700}}>{OBJ?.text}</Text>,
        //     size: '100%',
        //     children: (
        //         <>
        //             <ProductListForm OBJ={OBJ} />
        //         </>
        //     ),
        // })
    }
    else if (OBJ.id && OBJ.p0 === 'D') {
        // const confirm = window.confirm("Are you sure you want to delete this row?");
        // if (confirm) {
        //     Store.dispatch(SalePurchaseDeleteAPI(OBJ))
        // }

        GlobalClass.Confirm(
            "red",
            "Are you sure to delete selected data?",
            "It will be Deleted Forever and Cannot Be Recovered!!!",
            () => {
                Store.dispatch(SalePurchaseDeleteAPI(OBJ))
            },
            () => { },
            "Cancel",
            "Confirm"
        )
    }
}

