import React from 'react'
import Store from '../../utils/store';
import { ModalFunction } from '../../utils/slices/ModalSlice';
import { Text } from '@mantine/core';
import MailingLetterForm from './MailingLetterForm';
import { GetMailingLatterDeleteApi } from '../../utils/TransactionSlices/MailingLetter';
import GlobalClass from '../../utils/GlobalClass';

export function LTRAED(data) {
  const OBJ = JSON.parse(data)
  console.log("MailingLetterForm OBJ", OBJ);

  const state = Store.getState();
  const MLF01 = state?.MailingLetter?.MLF01;

  if (OBJ.p0 === "A") {
    Store.dispatch(
      ModalFunction({
        onclose: () => { },
        MTitle: <Text fw={700}>Writing New Letter</Text>,
        MAction: true,
        MSize: "xl",
        // position:"right",
        MBody: (i) => (
          <MailingLetterForm OBJ={OBJ} index={i} />
        ),
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        }
      })
    )
  }

  if (OBJ.p0 === "E" && MLF01) {
    Store.dispatch(
      ModalFunction({
        onclose: () => { },
        MTitle: <Text fw={700}>Writing New Letter</Text>,
        MAction: true,
        MSize: "xl",
        // position:"right",
        MBody: (i) => (
          <MailingLetterForm OBJ={OBJ} index={i} />
        ),
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        }
      })
    )

  }
  else if (OBJ.p0 === "D") {
    // const confirm = window.confirm("Are you sure ?");
    // if (confirm) {
    //   Store.dispatch(GetMailingLatterDeleteApi(OBJ))
    // }

    GlobalClass.Confirm(
      "red",
      "Are you sure to delete selected data?",
      "It will be Deleted Forever and Cannot Be Recovered!!!",
      () => {
        Store.dispatch(GetMailingLatterDeleteApi(OBJ))
      },
      () => { },
      "Cancel",
      "Confirm"
    )
  }

}



