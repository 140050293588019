import React, { useState } from "react";
import FormatForm from "../FormatForm";
import { Drawer, Text } from "@mantine/core";
import Store from "../../../utils/store";
import { ModalFunction } from "../../../utils/slices/ModalSlice";
import { GetEMAILData, GetSMSData, GetWhatsAppData, RefreshEmailBtn, RefreshSmsBtn, RefreshWapBtn } from "../../../utils/TransactionSlices/MessageBtnSlice";
import GenerateBtn from "./GenerateBtn";
import GlobalClass from "../../../utils/GlobalClass";
const dispatchModal = (obj) => {
  Store.dispatch(
    ModalFunction({
      MTitle: <Text fw={700}>{obj?.text}</Text>,
      MAction: true,
      MSize: "50%",
      MBody: (i) => <FormatForm obj={obj} index={i} />,
      MClose: true,
      onclose: () => { },
      onClickCloseButton: () => {
        // Store.dispatch(GetInvTypeDropDown())
      },
    })
  );
};
export function SMSAED(data) {
  const obj = JSON.parse(data);
  console.log("data", data);
  console.log(obj?.BtnPnlObj, 'obj?.id-')
  if (obj?.p0 == "A") {
    Promise.resolve(dispatchModal(obj)).then(() => {
      Store.dispatch(RefreshSmsBtn({}));
    });
  } else if (obj?.p0 == "E" && obj?.id) {
    Promise.resolve(dispatchModal(obj)).then(() => {
      Store.dispatch(RefreshSmsBtn({}));
    });
  } else if (obj?.p0 == "D" && obj?.id) {
    // if (window.confirm("Are you sure to delete selected data?")) {
    //   Store.dispatch(GetSMSData(obj));
    // }

    GlobalClass.Confirm(
      "red",
      "Are you sure to delete selected data?",
      "It will be Deleted Forever and Cannot Be Recovered!!!",
      () => {
        Store.dispatch(GetSMSData(obj));
      },
      () => { },
      "Cancel",
      "Confirm"
    )
  } else if (obj?.p0 == "G" && obj?.BtnPnlObj?.id) {
    Store.dispatch(
      ModalFunction({
        MTitle: <Text fw={700}>{obj?.text}</Text>,
        MAction: true,
        MSize: "50%",
        MBody: (i) => <GenerateBtn obj={obj} index={i} />,
        MClose: true,
        onclose: () => { },
        onClickCloseButton: () => {
          // Store.dispatch(GetInvTypeDropDown())
        },
      })
    );
  }
}
export function EMLAED(data) {
  // console.log(data, "data--");
  const obj = JSON.parse(data);
  if (obj?.p0 == "A") {
    Promise.resolve(dispatchModal(obj)).then(() => {
      Store.dispatch(RefreshEmailBtn({}));
    });
  } else if (obj?.p0 == "E" && obj?.id) {
    Promise.resolve(dispatchModal(obj)).then(() => {
      Store.dispatch(RefreshSmsBtn({}));
    });
  } else if (obj?.p0 == "D" && obj?.id) {
    // if (window.confirm("Are you sure to delete selected data?")) {
    //   Store.dispatch(GetEMAILData(obj));
    // }

    GlobalClass.Confirm(
      "red",
      "Are you sure to delete selected data?",
      "It will be Deleted Forever and Cannot Be Recovered!!!",
      () => {
        Store.dispatch(GetEMAILData(obj));
      },
      () => { },
      "Cancel",
      "Confirm"
    )
  }
}
export function WAPAED(data) {
  // console.log(data, "data--");
  const obj = JSON.parse(data);
  if (obj?.p0 == "A") {
    Promise.resolve(dispatchModal(obj)).then(() => {
      Store.dispatch(RefreshWapBtn({}));
    });
  } else if (obj?.p0 == "E" && obj?.id) {
    Promise.resolve(dispatchModal(obj)).then(() => {
      Store.dispatch(RefreshSmsBtn({}));
    });
  } else if (obj?.p0 == "D" && obj?.id) {
    // if (window.confirm("Are you sure to delete selected data?")) {
    //   Store.dispatch(GetWhatsAppData(obj));
    // }

    GlobalClass.Confirm(
      "red",
      "Are you sure to delete selected data?",
      "It will be Deleted Forever and Cannot Be Recovered!!!",
      () => {
        Store.dispatch(GetWhatsAppData(obj));
      },
      () => { },
      "Cancel",
      "Confirm"
    )
  }
}
