import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GetCmpSetUpPopUp } from '../../utils/slices/CompanySetUpPopUpSlice'
import PLDataGrid from '../../PlusComponent/PLDataGrid'

const NatureOfGoods = (props) => {
    const { obj, setEditSend, value } = props
    const NatureOfGoodsData = useSelector((state) => state.CompanySetupPopUp?.CmpSetUpPopUpData)
    const P_PLTCSDT = useSelector((state) => state.CompanySetupPopUp?.CmpSetUpPopUpData?.P_PLTCSDT)
    const P_PLTCSDTDT = useSelector((state) => state.CompanySetupPopUp?.CmpSetUpPopUpData?.P_PLTCSDTDT)
    const dispatch = useDispatch()

    useEffect(() => {
        if (obj) {
            dispatch(GetCmpSetUpPopUp(obj))
        }
    }, [obj])

    return (
        <div style={{ width: "100%" }}>
            {
                NatureOfGoodsData &&
                <PLDataGrid
                autoFocus={true}
                openOnFocus={true}
                    dropdownPosition={true}
                    // width={"500px"}
                    TmData={P_PLTCSDT}
                    data={P_PLTCSDTDT?.jData}
                    setEdit={(e) => {
                        console.log('e====>', e)
                        setEditSend(e.FIELD01)
                    }}
                    value={value}
                />
            }
        </div>
    )
}

export default NatureOfGoods