import {
  MRT_TablePagination,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetColumnSelection } from "../utils/TransactionSlices/ColumnSelectionSlice";
import { createColumnHelper } from "@tanstack/react-table";
import useWindowDimensions from "../utils/UseWindowDimensions";
import PLNumberBox from "./PLNumberBox";
import {
  ActionIcon,
  Box,
  Button,
  Flex,
  Grid,
  Group,
  Text,
  Tooltip,
} from "@mantine/core";
import { IconEdit } from "@tabler/icons-react";
import PLTextBox from "./PLTextBox";
import PLComboBox from "./PLComboBox";
import GlobalClass from "../utils/GlobalClass";
import gensetting from "../utils/gensetting";
import { DataSaveLoading } from "../utils/slices/DataSaveLoadingSlice";
import { ModalDelete } from "../utils/slices/ModalSlice";
import Store from "../utils/store";
import PLDataGrid from "./PLDataGrid";

const PLColumnSelection = (props) => {
  const dispatch = useDispatch();
  const columnHelper = createColumnHelper();
  const { height } = useWindowDimensions();

  const columnSelectionData = useSelector(
    (state) => state?.ColumnSelection?.columnSelectionData
  );
  const isLoading = useSelector((state) => state?.ColumnSelection?.isLoading);
  const hasError = useSelector((state) => state?.ColumnSelection?.hasError);
  const ErrorMsg = useSelector((state) => state?.ColumnSelection?.ErrorMsg);
  const SaveLoading = useSelector(
    (state) => state?.DataSaveLoadingSlice?.SaveLoading
  );
  const [columns, setColumns] = useState([]);
  const [columnData, setColumnData] = useState([]);
  const [columnData1, setColumnData1] = useState([]);
  const [f4Obj, setF4Obj] = useState([]);
  const [tableEditingRow, setTableEditingRow] = useState(null);
  const [rowSelection, setRowSelection] = useState({});
  const [buttonClicked, setButtonClicked] = useState(false);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const [focusset, setfocusset] = useState(false);
  const numRef = useRef();
  const TableRef = useRef();

  useEffect(() => {
    dispatch(GetColumnSelection(props.cCode));
  }, []);

  useEffect(() => {
    if (hasError && !isLoading) {
      GlobalClass.Notify("error", "Error: " + ErrorMsg.code, ErrorMsg.message);
    }
  }, [hasError]);

  useEffect(() => {
    setF4Obj({
      ...columnSelectionData?.DT?.[columnSelectionData?.DT?.length - 1],
    });
    setColumnData(columnSelectionData?.DT);
  }, [columnSelectionData]);

  // useEffect(()=>{

  // })

  useEffect(() => {
    let col = [];
    columnSelectionData?.MIDGRD?.filter((item) => item.S53F00 == "C")?.map(
      (a, i) => {
        col.push(
          columnHelper?.accessor(a?.S53F04, {
            header: a.S53F07 == 0 ? "" : a.S53F03,
            size: a?.S53F07,
            // minSize: 0,
            // maxSize: a?.ColWidth,
            id: a?.S53UID,
            Edit: ({ cell, column, table, row }) => {
              let val = cell.getValue();
              return renderValue(a, row, i, column, cell?.id, a?.S53UID, val);
            },
            Cell: ({ col, cell, row }) => {
              let val = cell.getValue();
              return val;
            },
          })
        );
      }
    );
    setColumns(col);
  }, [columnSelectionData?.MIDGRD, f4Obj]);


  useEffect(() => {
    const tabled = TableRef.current;
    // if (!focusset) {
    focusSelected(tabled)
    // }
    // const handleTableKeyDown = (e) => {
    //   // Handle global key events if needed
    //   // handlekeyPress(e);
    // };

    // tabled?.addEventListener('keydown', handleTableKeyDown);

    // return () => {
    //   tabled?.removeEventListener('keydown', handleTableKeyDown);
    // };


  }, [pagination, TableRef]); // Re-run the effect when pagination changes
  // console.log("rowSelection=>", rowSelection);

  const focusSelected = async (tabled) => {

    const rows = await tabled?.querySelectorAll('tr');
    const body = tabled?.querySelectorAll('tbody');
    // Set initial focus on the first row of the current page

    if (rows?.length > 0 && props?.selectedData) {
      // console.log("props?.selectedData",props?.selectedData);

      var inn = Object.keys(props?.selectedData)[0];
      const inn2 = (table.getSortedRowModel().rows ?? []).findIndex((row) => row.original[props?.selectionId]?.toString() === inn);
      // const paginatedrows = (tabled.getPaginationRowModel().rows??[]).findIndex((row) => row.original[props?.selectionId]?.toString() === inn);
      // console.log("pageee",);


      if (inn) {
        const selectedRowPage = Math.floor(inn2 / pagination.pageSize);
        // let f = rows.activeElement
        if (pagination.pageIndex !== selectedRowPage && selectedRowPage >= 0) {
          // Navigate to the correct page if the selected row is not on the current page
          // setPageIndex(selectedRowPage);
          setPagination({ ...pagination, pageIndex: selectedRowPage });

        } else if (rows[inn2 % pagination.pageSize]) {
          // console.log("pageeee 2",inn2);
          // Scroll to the row when it's on the correct page
          setTimeout(() => {
            rows[inn2 % pagination.pageSize].scrollIntoView({ behavior: 'smooth', block: 'center' });
            setfocusset(true);
          }, 500);
        }
        // rows[inn].scrollIntoView({behavior:'smooth',"block":'center'})
      }
      else if (!inn) {
        tabled.focus();
        setTimeout(() => {
          rows[0].focus();
          setfocusset(true);
        }, 300);
      }
      // 
    }
    else if (rows?.length > 0) {
      tabled.focus();
      setTimeout(() => {
        rows[0].focus();
        setfocusset(true);
      }, 300);
    }
  }
  const seperate = (e) => {
    // console.log("e here", e);
    var paraArr = e?.split("~C~");
    if (paraArr?.length == 1) {
      paraArr[1] = paraArr[0];
    }
    return paraArr;
  };

  const renderValue = (c, row, index, column, cellid, uID, val) => {
    if (
      c?.S53F02 == "RID0085"
      // ||
      // (row[index].S53F18 == "N") ||
      // (row[index].S53F06 == "N")
    ) {
      return val;
    }
    switch (c?.S53F14) {
      case "NUMBOX":
        return (
          <PLNumberBox
            id={`F04_${c?.S53F02}`}
            disabled={c?.S53F02 == "RID0084" && row?.original?.S53F18 == "N"}
            value={f4Obj?.[c?.S53F04]}
            setEdit={(e) => {
              setF4Obj({ ...f4Obj, [c?.S53F04]: e });
            }}
            onKeyDown={(e) => {
              if (e.key == "Enter") {
                if (
                  c?.S53F02 == "RID0088" &&
                  !document?.getElementById("F04_RID0089")
                ) {
                  rowSaveHandler();
                }
              }
            }}
          />
        );
      case "TEXTBOX":
        return (
          <PLTextBox
            id={`F04_${c?.S53F02}`}
            value={f4Obj?.[c?.S53F04]}
            setEdit={(e) => {
              setF4Obj({ ...f4Obj, [c?.S53F04]: e?.target?.value });
            }}
          />
        );
      case "COMBOBOX":
        return (
          <PLComboBox
            id={`F04_${c?.S53F02}`}
            disabled={row?.original?.S53F06 != "N" && c?.S53F02 == "RID0089"}
            value={f4Obj?.[c?.S53F04]}
            copno={seperate(c?.S53F15)?.[1]}
            dispexpr={"DisplayMember"}
            valexpr={"ValueMember"}
            setEdit={(e) => {
              setF4Obj({ ...f4Obj, [c?.S53F04]: e.value });
            }}
            onKeyDown={(e) => {
              if (e.key == "Enter") {
                if (c?.S53F02 == "RID0089") {
                  rowSaveHandler();
                }
              }
            }}
          />
        );
      case "PLPOPUP":
        return (
          <PLDataGrid
            width={"500px"}
            value={f4Obj?.[c?.S13F02]}
            p1={seperate(c.S13F09)[1]}
            setEdit={(e) => {
              setF4Obj({ ...f4Obj, [c?.S13F02]: e?.FIELD01 });
            }}
          />
        );

      default:
        break;
    }
  };

  const rowSaveHandler = () => {
    let newArray = [...columnData];
    newArray[tableEditingRow?.index] = f4Obj;
    setColumnData(newArray);
    setF4Obj({
      ...columnSelectionData?.DT?.[columnSelectionData?.DT?.length - 1],
    });
    setTableEditingRow(null);
    table.setEditingRow(null);
  };

  const rowCancelHandler = () => {
    setF4Obj({
      ...columnSelectionData?.DT?.[columnSelectionData?.DT?.length - 1],
    });
    setTableEditingRow(null);
    table.setEditingRow(null);
  };

  // Function to find the next available sequence
  const findNextSequence = (existingSequences) => {
    const existingIndexes = columnData
      .filter((item) => item.INDEX_FLD !== null) // Filter out NULL values
      .map((item) => item.INDEX_FLD);

    // Find the smallest number missing in the sequence
    let nextSequence = 1;
    while (existingIndexes.includes(nextSequence)) {
      nextSequence++;
    }
    return nextSequence;
  };

  const handleResetButton = () => {
    // if (window.confirm("Confirm to reset?")) {
    //   const updatedData = columnData.map((item, index) => {
    //     if (item.S53F18 === "Y") {
    //       return { ...item, INDEX_FLD: null }; // Set INDEX_FLD to NULL if condition is met
    //     } else if (item?.S53F18 == "N") {
    //       return { ...item, S53F03H: item.S53F03, S53F07: item.oNSeq };
    //     }
    //     return item; // Keep the other rows unchanged
    //   });
    //   // Update the state with the modified data
    //   setColumnData(updatedData);
    // }

    GlobalClass.Confirm(
      "red",
      "Confirm to reset?",
      "This action will reset all modifications. Do you wish to continue?",
      () => {
        const updatedData = columnData.map((item, index) => {
          if (item.S53F18 === "Y") {
            return { ...item, INDEX_FLD: null }; // Set INDEX_FLD to NULL if condition is met
          } else if (item?.S53F18 == "N") {
            return { ...item, S53F03H: item.S53F03, S53F07: item.oNSeq };
          }
          return item; // Keep the other rows unchanged
        });
        // Update the state with the modified data
        setColumnData(updatedData);
      },
      () => { },
      "Cancel",
      "Confirm"
    )
  };

  const handleRowKeyDown = (e, row) => {
    const tabled = TableRef.current;
    const rows = tabled.querySelectorAll("tr");
    const currentRowIndex = Array.from(rows).indexOf(e.target);
    switch (e?.key) {
      case "ArrowDown":
        // e.preventDefault();

        if (currentRowIndex < rows.length - 1) {
          e.preventDefault();
          rows[currentRowIndex + 1].focus();
          // rows[currentRowIndex + 1].scrollIntoView({ behavior: "smooth",block: "start"})
        } else if (
          table?.getPageCount() > 1 &&
          pagination?.pageIndex < table?.getPageCount() - 1
        ) {
          // Navigate to the next page
          table.nextPage();
          // setPagination((prev) => ({
          //   ...prev,
          //   pageIndex: prev.pageIndex + 1,
          // }));
          // rows[0].scrollIntoView({ behavior: "smooth",block: "start"})
          // rows[0].focus();
          // currentRowIndex = Array.from(rows).indexOf(e.target);
          rows[0].scrollIntoView({ behavior: "smooth", block: "start" })
          rows[0].focus();
        }

        break;
      case "ArrowUp":
        if (currentRowIndex > 0) {
          e.preventDefault();
          rows[currentRowIndex - 1].focus();
          // rows[currentRowIndex - 1].scrollIntoView({ behavior: "smooth",block: "end"})
        } else if (pagination.pageIndex > 0) {
          // Navigate to the previous page
          table.previousPage();
          // setPagination((prev) => ({
          //   ...prev,
          //   pageIndex: prev.pageIndex - 1,
          // }));
          rows[0].scrollIntoView({ behavior: "smooth", block: "start" })
          rows[0].focus();
        }

        break;

      case "Enter":
        if (tableEditingRow) {
          return;
        } else {
          table?.setEditingRow(row);
          setTableEditingRow(row);
          setF4Obj(row?.original);
          document?.getElementById("F04_RID0084")
            ? document?.getElementById(`F04_RID0084`)?.focus()
            : document?.getElementById("F04_RID0086")?.focus();
        }
        break;
      default:
        break;
    }
  };

  const handleFinishButton = () => {
    let selectedColumns = columnData?.filter((a) => a?.INDEX_FLD !== null);
    GlobalClass.Notify(
      "info",
      "Editing",
      "Please wait while we process your data"
    );
    var data = {
      CorpID: gensetting.getcorpid(),
      cUser: gensetting.getcuser(),
      cPass: gensetting.getcpass(),
      CmpNo: gensetting.getcmpno(),
      cAction: "A",
      cCode:
        props?.cCode?.length == 4
          ? props?.cCode?.substring(0, 2)
          : props?.cCode,
      cSData: JSON.stringify({ DT: selectedColumns }),
      YrNo: localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
    };

    let param = JSON.stringify({
      CorpID: gensetting.getcorpid(),
      cUser: gensetting.getcuser(),
      cPass: gensetting.getcpass(),
      CmpNo: gensetting.getcmpno(),
      cAction: "A",
      cCode:
        props?.cCode?.length == 4
          ? props?.cCode?.substring(0, 2)
          : props?.cCode,
    });

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    fetch(
      GlobalClass.ApiUrl + GlobalClass.PostF4ColumnData + "?pa=" + param,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "SUCCESS") {
          // modals.closeAll();
          // props.ModalFunctionClose();

          // dispatch(ModalDelete(props.index))

          GlobalClass.Notify("success", "Edited", "Edited successfully");
          let PaginationObj =
            Store.getState().DataSaveLoadingSlice.PaginationObj;
          Promise.resolve(dispatch(DataSaveLoading({ ["CustomeColumn"]: false })))
            // .then(() => dispatch(ModalDelete(props.index)))
            .then(() => {
              // if (props?.OBJ?.p0 == "A" && !props?.OBJ.page.startsWith("P_")) {
              //   dispatch(GetAccountGroup());
              // } else {
              dispatch(ModalDelete(props.i))
              // }
            });
        } else {
          GlobalClass.Notify("error", data?.status, data?.message);
          dispatch(DataSaveLoading({ ["CustomeColumn"]: false }));
        }
      })
      .catch((e) => {
        GlobalClass.Notify("error", "Error", `${e.message}`);
        dispatch(DataSaveLoading({ ["CustomeColumn"]: false }));
      });
  };

  const table = useMantineReactTable({
    columns: columns ? columns : [],
    data: columnData ? columnData : [],
    enableBottomToolbar: true,
    enableTopToolbar: false,
    enableColumnActions: false,
    enableSorting: false,
    enableEditing: true,
    editDisplayMode: "row",
    enableStickyFooter: true,
    enableStickyHeader: true,
    enablePagination: true,
    paginationDisplayMode: "default",
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ['5', '10', '15', '20', '25', '30', '50', '100'],
    },
    // enableRowSelection: false,
    // enableMultiRowSelection: true,
    onPaginationChange: setPagination,
    mantineTableContainerProps: {
      sx: { height: height * 0.6 },
    },
    initialState: {
      pagination,
    },
    state: {
      density: "1.5px",
      rowSelection,
      pagination
    },
    onEditingRowSave: () => {
      rowSaveHandler();
    },
    onEditingRowCancel: () => {
      rowCancelHandler();
    },
    mantineTableBodyProps: {
      // tabIndex: 0,
      ref: TableRef,
    },
    mantineTableBodyRowProps: ({ row, staticRowIndex, table }) => ({
      // ref: TableContainer,
      tabIndex: 0,
      onFocus: () => {
        // console.log("row.getIsGrouped()",row.getIsGrouped());

        // console.log("setRowSelection in else")
        setRowSelection((prev) => ({
          [row?.id]: !prev[row?.id],
        }))
      },
      onKeyDown: (e) => {
        handleRowKeyDown(e, row);
      },
      onClick: () => {
        // let newArray = [...columnData];

        // newArray[row?.index] = ;
        // setColumnData(newArray);

        setRowSelection((prev) => {
          if (!prev[row.id]) {
            return { [row.id]: true };
          }
          return prev;
        });
      },
    }),
    renderRowActions: ({ row, table }) => (
      <Flex gap="md">
        <Tooltip label="Edit">
          <ActionIcon
            onClick={() => {
              // console.log("row", row);
              table.setEditingRow(row);
              setF4Obj(row.original);
              setTableEditingRow(row);
              document?.getElementById(`F04_RID0084`)
                ? document?.getElementById(`F04_RID0084`)?.focus()
                : document?.getElementById(`F04_RID0086`)?.focus();
            }}
          >
            <IconEdit size={"20px"} />
          </ActionIcon>
        </Tooltip>
      </Flex>
    ),
    renderBottomToolbar: ({ table }) => (
      <>
        <Box>
          <Grid style={{ display: "flex", alignItems: "center" }}>
            <Grid.Col span={6}>
              <Group spacing={"xs"} position="center">
                <Button
                  size="xs"
                  variant="subtle"
                  compact
                  onClick={() => {
                    // console.log("row selection",Object.keys(rowSelection)[0])
                    const selectedRow =
                      columnData[Object.keys(rowSelection)[0]];
                    if (selectedRow.S53F18 === "Y") {
                      if (selectedRow?.INDEX_FLD == null) {
                        const updatedData = [...columnData];
                        const nextSequence = findNextSequence(
                          updatedData.map((row) => row?.original?.INDEX_FLD)
                        );

                        // Update the INDEX_FLD of the selected row
                        updatedData[Object.keys(rowSelection)[0]] = {
                          ...selectedRow,
                          INDEX_FLD: nextSequence,
                        };
                        setColumnData(updatedData); // Update state with new data
                      } else {
                        const updatedData = [...columnData];
                        updatedData[Object.keys(rowSelection)[0]] = {
                          ...selectedRow,
                          INDEX_FLD: null,
                        };
                        setColumnData(updatedData); // Update state with new data
                      }

                    }
                    // const tabled = TableRef.current;
                    // const rows = tabled.querySelectorAll("tr");
                    // rows[selectedRow]?.focus();
                  }}
                >
                  Selection
                </Button>
                <Button
                  size="xs"
                  variant="subtle"
                  compact
                  onClick={() => {
                    // console.log("table?.getSelectedRowModel()",table?.getSelectedRowModel())
                    let columns = [];
                    // for(let i=0;i<table?.getSelectedRowModel()?.flatRows?.length;i++){
                    //   columns.push(table?.getSelectedRowModel()?.flatRows[i]?.original);
                    // }
                    setRowSelection({});
                    setColumnData1(columnData);
                    for (let i = 0; i < columnData?.length; i++) {
                      if (columnData?.[i]?.INDEX_FLD !== null) {
                        columns.push(columnData?.[i]);
                      }
                    }
                    // console.log("columns==>",columns)

                    if (buttonClicked == false) {
                      setColumnData(columns);
                      setButtonClicked(true);
                    } else {
                      // console.log(columnData)
                      setColumnData(columnData1);
                      setButtonClicked(false);
                    }

                    // Promise.resolve(
                    // setButtonClicked(!buttonClicked)).

                    // then(()=>{

                    //   setColumnData(buttonClicked ? columns : columnData1);

                    // })
                  }}
                >
                  Select
                </Button>
                <Button
                  size="xs"
                  variant="subtle"
                  compact
                  onClick={handleResetButton}
                >
                  Reset
                </Button>
              </Group>
            </Grid.Col>

            <Grid.Col span={6}>
              <Group spacing={"xs"} position="right" display={"flex"}>
                <MRT_TablePagination position="bottom" table={table} />
              </Group>
            </Grid.Col>
          </Grid>
        </Box>
      </>
    ),
  });

  // console.log("columnSelectionData", columnSelectionData);

  return (
    <>
      {hasError ? (
        <>
          <Text>{ErrorMsg?.message}</Text>
          <Button
            onClick={() => {
              dispatch(GetColumnSelection(props.cCode));
            }}
          >
            Retry
          </Button>
        </>
      ) : isLoading || SaveLoading["CustomeColumn"] ? (
        GlobalClass?.RenderLoader("dots")
      ) : (
        <Grid>
          <Grid.Col span={12}>

            <MantineReactTable table={table} />
          </Grid.Col>
          <Grid.Col span={12}>

            
              <Group spacing={"xs"} position="right" display={"flex"}>
                <Button style={{ height: "30px", width: "100px" }}
                      size="sm" onClick={handleFinishButton}>
                  Finish
                </Button>
                <Button
                  style={{ height: "30px", width: "100px" }}
                  size="sm"
                  onClick={() => {
                    dispatch(ModalDelete(props.i))
                  }}
                >
                  Cancel
                </Button>
              </Group>
           
          </Grid.Col>
        </Grid>
      )}
    </>
  );
};

export default PLColumnSelection;
