import { Button, Grid, Group, Text } from "@mantine/core";
import PLDataGrid from "../../PlusComponent/PLDataGrid";
import PLTextBox from "../../PlusComponent/PLTextBox";
import PLNumberBox from "../../PlusComponent/PLNumberBox";
import PLComboBox from "../../PlusComponent/PLComboBox";
import PLDateBox from "../../PlusComponent/PLDateBox";
import { useEffect, useRef } from "react";
import GlobalClass from "../../utils/GlobalClass";

export default function ProductListUserField(props) {

    const { state, setState, DrawerOBJ, setDrawerOBJ } = props;

    const userFieldRef = useRef(null);
    const clsAccRef = useRef(null);
    const btnRef = useRef(null);
    const trdAccRef = useRef(null);

    const saveBtn = () => {
        Promise.resolve(setDrawerOBJ({ ...DrawerOBJ, open: false })).then(() => { props.setFocus() })
    }


    useEffect(() => {
        trdAccRef?.current?.handlePopoverToggle();
    }, [])

    useEffect(() => {

        const formElement = userFieldRef.current;
        if (formElement) {
            formElement.addEventListener('keypress', (e) => GlobalClass.formNextInput(e, userFieldRef));
        }
        return () => {
            if (formElement) {
                formElement.removeEventListener('keypress', (e) => GlobalClass.formNextInput(e, userFieldRef));
            }
        };
    }, [userFieldRef]);

    return (
        <div ref={userFieldRef}>
            <Grid gutter={4}>
                <Grid.Col span={12} style={{ display: 'flex', padding: 0 }}>
                    <Grid.Col span={5}>
                        <Text style={{ fontSize: 12 }} >Trading Account</Text>
                    </Grid.Col>
                    <Grid.Col span={7}>
                        <PLDataGrid autofocus={true} position="" width={"500px"} ref={trdAccRef}
                            value={{ value: state?.M21?.FIELD77, label: state?.M21?.FIELD77NM }}
                            TmData={state?.P_M01TR}
                            // data={state?.P_M01TRDT.jData}
                            setEdit={(e) => {
                                Promise.resolve(setState((prevState) => ({
                                    ...prevState,
                                    M21: {
                                        ...prevState.M21,
                                        FIELD77: e.FIELD01,
                                        FIELD77NM: e.FIELD02
                                    }
                                }))).then(() => {
                                    clsAccRef?.current?.handlePopoverToggle();
                                })

                            }}

                        />
                    </Grid.Col>
                </Grid.Col>
                <Grid.Col span={12} style={{ display: 'flex', padding: 0 }}>
                    <Grid.Col span={5}>
                        <Text style={{ fontSize: 12 }}>Closing Stock Account</Text>
                    </Grid.Col>
                    <Grid.Col span={7}>
                        <PLDataGrid ref={clsAccRef} position="" width={"500px"}
                            value={{ value: state?.M21?.FIELD76, label: state?.M21?.FIELD76NM }}
                            TmData={state?.P_M01CL}
                            // data={state?.P_M01CLDT.jData}
                            setEdit={(e) => {
                                Promise.resolve(setState((prevState) => ({
                                    ...prevState,
                                    M21: {
                                        ...prevState.M21,
                                        FIELD76: e.FIELD01,
                                        FIELD76NM: e.FIELD02
                                    }
                                }))).then(() => {
                                    // setTimeout(() => {
                                    btnRef?.current?.focus()
                                    // }, 1000);
                                    // console.log("btnRef",);
                                })
                            }}
                            onKeyDown={(e) => {
                                GlobalClass.formNextInput(e, userFieldRef);
                            }}
                        />
                    </Grid.Col>
                </Grid.Col>
            </Grid>
            <Group spacing={'xs'} position="center" display={'flex'} mt={10}>
                <Button ref={btnRef}
                    size='sm' style={{ width: 100 }}
                    onClick={() => saveBtn()}
                    onKeyDown={(e) => {
                        if (e.key == "Enter" || e.key == "Tab") {
                            console.log('ok press')
                            saveBtn()
                        }
                    }}
                >
                    Ok
                </Button>
            </Group>
        </div>
    )

}
