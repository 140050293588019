import { createColumnHelper } from '@tanstack/react-table'
import React, { useEffect, useState } from 'react'
import PLDataGrid from '../../../../PlusComponent/PLDataGrid'
import PLComboBox from '../../../../PlusComponent/PLComboBox'
import PLTextBox from '../../../../PlusComponent/PLTextBox'
import PLNumberBox from '../../../../PlusComponent/PLNumberBox'
import { MRT_TablePagination, MantineReactTable, useMantineReactTable } from 'mantine-react-table'
import { ActionIcon, Box, Button, Center, ColorPicker, Flex, Grid, Group, Popover, Tooltip } from '@mantine/core'
import ButtonPanel from '../../../../PlusComponent/ButtonPanel'
import GlobalClass from '../../../../utils/GlobalClass'
import { IconEdit } from '@tabler/icons-react'
import useWindowDimensions from '../../../../utils/UseWindowDimensions'

export default function CustomCol(props) {
  console.log("CustomCol", props)
  const { DrawerObj, custColData, setCustColData, repHeadData, setRepHeadData, summaryData, setSummaryData, setDrawerObj, TblNm, CustColEnt, CustColHKey, RepHeadEnt, RepHeadHKey, RepSumEnt, RepSumhKey } = props
  const { height, width } = useWindowDimensions();
  const [columns, setColumns] = useState([])
  const [tableData, setTableData] = useState([])
  const [columnEnt, setColumnEnt] = useState([])
  const [ButtonsData, setButtonsData] = useState([])
  const [rowSelection, setRowSelection] = useState({})
  const [tableToggle, setTableToggle] = useState(false)
  const [opened, setOpened] = useState(false);
  const [tableObj, setTableObj] = useState({
    "RECTYPE": "REG",
    "REPORTID": "LEDMAIN",
    "COLRECID": "",
    "DISPCAP": "",
    "DISPCAPID": "",
    "USEDCAP": "",
    "USEDCAPID": "",
    "CTRLSOURCE": "",
    "SOURCEDEF": "",
    "DATATYPE": "",
    "UNBOUND": "",
    "COLWIDTH": 0,
    "COLDEC": " ",
    "DISPFORMAT": "",
    "LTOTAL": "",
    "LGROUP": "",
    "ORDEXPR": "",
    "GRPEXPR": "",
    "DISPCOND": "",
    "GRPSEQ": "",
    "RECSEQ": "",
    "GRPCAPID": "",
    "GRPCAP": "",
    "SPFLDFLAG": "   ",
    "COLPROP": "",
    "COLDATA": "",
    "TOTEXPR": "",
    "LSTRETCH": "",
    "LLEAF": "",
    "SELEXPSRC": "",
    "LORDER": "",
    "SUPFLAG": " ",
    "INCOND": "",
    "INCONDVFP": null,
    "ALIGNMENT": "",
    "FONTNAME": "",
    "FONTSIZE": "",
    "FONTSTYLE": "",
    "BACKCOLOR": "",
    "FORECOLOR": "",
    "COLSUPRESS": "",
    "CLICKDET": "",
    "LCOLUMNGRP": "",
    "DTCODEFLD": "",
    "MTBLNAME": "",
    "MCODEFLD": "",
    "MDISPFLD": "",
    "MFILTER": "",
    "S61FTY": "",
    "FCSOURCE": "",
    "FILTTYPE": " ",
    "FILTPROP": "",
    "FDEFVAL": "",
    "FCUSTEXPR": "",
    "BUNIID": 0,
    "S61CHARID": "",
    "FGSOURCE": "",
    "lOk": null,
    "ORFLDNM": "",
    "LACTIVE": "",
    "LSYSTEM": "",
    "GRPNO": null,
    "COLNO": null,
    "OLDCOLNO": 0,
    "LDEFFORMAT": "",
    "DATESEP": null,
    "DATEFRM": "",
    "FONTCASE": "",
    "LNZERO": null,
    "LCOMMA": "",
    "LCRDB": "",
    "LABS": "",
    "LCURR": "",
    "LFORECOLOR": "",
    "LBACKCOLOR": "",
    "EGKEYID": 0,
    "ROWNO": null,
    "COLLEVEL": null,
    "LEVELFLD": null,
    "COLTOTAL": null,
    "NONZERO": null,
    "COLGRPREQ": "",
    "LINPRINT": "",
    "LINGRID": "",
    "COLLOCK": "",
    "SEARCHCOL": "",
    "ALIGN_H": "",
    "ALIGN_D": "",
    "ALIGN_F": "",
    "COLDISPIN": null,
    "CACTION": "",
    "COLSPDATA": null,
    "IMGDISPAS": null,
    "IMGSTYLE": null,
    "IMGWIDTH": null,
    "IMGHEIGHT": null,
    "LBLANK": "",
    "COLTYPE": null,
    "COLSNO": null,
    "COLENO": null,
    "PRINTAT": null,
    "GRPRECID": null
  })
  const [color, setColor] = useState("#ffffff");
  const [tableEditingRow, setTableEditingRow] = useState(null)
  const [originalTableData, setOriginalTableData] = useState([])
  const columnHelper = createColumnHelper()

  const seperate = (e) => {
    var paraArr = e.split("~C~");
    if (paraArr.length == 1) {
      paraArr[1] = paraArr[0];
    }
    return paraArr;
  };

  const seperateColType = (e) => {
    var paraArr = e.split("~R~");
    var val = paraArr[0].split("~C~")
    if (val.length == 1) {
      val[1] = val[0]
    }
    return val
  }

  useEffect(() => {
    if (TblNm == "customColumn") {
      setColumnEnt(CustColEnt)
      setButtonsData(CustColHKey)
      setTableData(custColData)
    } else if (TblNm == "reportHeader") {
      setColumnEnt(RepHeadEnt)
      setButtonsData(RepHeadHKey)
      setTableData(repHeadData)
    } else {
      setColumnEnt(RepSumEnt)
      setButtonsData(RepSumhKey)
      setTableData(summaryData)
    }
  }, [TblNm])

  const renderInputBox = (uID, v, c, cellid, index) => {
    let setInput;
    switch (c?.S53F14) {
      case "PLPOPUP":
        setInput = (
          <PLDataGrid
            id={`T02Ent_${index}`}
            width={"500px"}
            value={tableObj[c?.S53F04]}
            p1={seperate(c.S53F15)[1]}
            setEdit={(e) => {
              setTableObj({ ...tableObj, [c?.S53F04]: e })
            }}
          />
        );
        break;
      case "COMBOBOX":
        if (c?.S53F02 === "TMMHF06") { // Col.type
          setInput = (
            <PLComboBox
              cmbid={`T02Ent_${index}`}
              // initiallyOpened={initiallyOpened}
              value={tableObj[c?.S53F04]}
              copno={seperateColType(c.S53F15)[1]}
              dispexpr={"DisplayMember"}
              valexpr={"ValueMember"}
              setEdit={(e) => {
                console.log("combobox", e)
                setTableObj({ ...tableObj, [c?.S53F04]: e.value })
              }}
            />
          );
        } else {
          setInput = (
            <PLComboBox
              cmbid={`T02Ent_${index}`}
              // initiallyOpened={initiallyOpened}
              value={tableObj[c?.S53F04]}
              copno={seperate(c.S53F15)[1]}
              dispexpr={"DisplayMember"}
              valexpr={"ValueMember"}
              setEdit={(e) => {
                console.log("combobox", e)
                setTableObj({ ...tableObj, [c?.S53F04]: e.value })
              }}
            />
          );
        }
        break;
      case "TEXTBOX":
        setInput = (
          <PLTextBox
            id={`T02Ent_${index}`}
            value={tableObj[c?.S53F04]}
            setEdit={(e) => {
              setTableObj({ ...tableObj, [c?.S53F04]: e.target.value })
            }}
          />
        );

        break;
      case "NUMBOX":
        setInput = (
          <PLNumberBox
            id={`T02Ent_${index}`}
            value={tableObj[c?.S53F04]}
            setEdit={(e) => {
              setTableObj({ ...tableObj, [c?.S53F04]: e })
            }}
          />
        );
        break;
      // case "COLORPICKER":
      //   setInput = (
      //     <>
      //       <Button
      //         style={{ backgroundColor: color, color: "#fff" }}
      //         size="xs"
      //       />

      //       <Popover
      //         opened={opened}
      //         onClose={() => setOpened(false)}
      //         position="bottom"
      //         withArrow
      //       >
      //         <Popover.Target>
      //           <Button 
      //           onClick={() => setOpened((o) => !o)} 
      //           size="0px"
      //           >
      //             ...
      //           </Button>
      //         </Popover.Target>
      //         <Popover.Dropdown>
      //           <ColorPicker
      //             format="hex"
      //             value={color}
      //             // onChange={handleColorChange}
      //             withPicker={false}
      //             // fullWidth
      //             swatches={[
      //               "#2e2e2e",
      //               "#868e96",
      //               "#fa5252",
      //               "#e64980",
      //               "#be4bdb",
      //               "#7950f2",
      //               "#4c6ef5",
      //               "#228be6",
      //               "#15aabf",
      //               "#12b886",
      //               "#40c057",
      //               "#82c91e",
      //               "#fab005",
      //               "#fd7e14",
      //             ]}
      //           />
      //         </Popover.Dropdown>
      //       </Popover>
      //     </>
      //   )
      //   break;
      default:
        setInput = null;
        break;
    }
    return setInput;

  };

  useEffect(() => {
    if (columnEnt) {
      let cols = []
      columnEnt?.filter((v, i) => v?.S53F00 === "C").map((c, i) => {
        cols.push(
          columnHelper.accessor(c.S53F04, {
            header: c?.S53F03,
            id: c?.S53UID,
            size: c?.S53F07 == 0 ? 0 : c?.S53F07,
            enableEditing: c?.S53F02 === "TMMHF05" ? false : true,
            Cell: ({ cell, row }) => {
              // console.log("cell.getValue()", cell.getValue())
              let val = cell.getValue();
              if (c.S53F02 == "TMMHF11") {
                return tableData[row.id]?.ALIGNMENT == "DDD" ? "Default" :
                  tableData[row.id]?.ALIGNMENT == "000" ? "Left" :
                    tableData[row.id]?.ALIGNMENT == "111" ? "Right" :
                      tableData[row.id]?.ALIGNMENT == "222" ? "Center" : ""
              }
              return val
            },
            Edit: ({ cell, row }) => {
              let val = cell.getValue();
              return renderInputBox(c.S53UID, val, c, cell?.id, i);
            },
          })
        )
      })
      return setColumns(cols);
    }
  }, [columnEnt, tableData, tableObj, CustColEnt, custColData])

  const findNextSequence = (existingSequences) => {
    const existingIndexes = tableData
      .filter(item => item.ROWNO !== null) // Filter out NULL values
      .map(item => item.ROWNO);

    // Find the smallest number missing in the sequence
    let nextSequence = 1;
    while (existingSequences.includes(nextSequence)) {
      nextSequence++;
    }
    console.log("nextSequence", nextSequence)
    return nextSequence;
  };

  // selection btn
  const SelectDeSelect = (row) => {
    if (Object.keys(row)?.length > 0) {
      const selectedRow = tableData[Object.keys(rowSelection)[0]];
      // console.log("selectedRow", selectedRow)
      // let updatedRows = [];
      if (row.ROWNO === null) {
        const updatedData = [...tableData];
        const nextSequence = findNextSequence(tableData
          .filter(item => item.ROWNO !== null)
          .map(item => item.ROWNO));

        updatedData[Object.keys(rowSelection)[0]] = { ...row, ROWNO: nextSequence, COLNO: 1, ALIGNMENT:"000", FONTSTYLE:"N", FONTSIZE: "D" };
        setTableData(updatedData);
        setRowSelection({ [Number(Object.keys(rowSelection)[0]) + 1]: true })
      } else {
        const updatedData = [...tableData];
        updatedData[Object.keys(rowSelection)[0]] = {
          ...row,
          FONTSIZE: "",
          ROWNO: null,
        };
        setTableData(updatedData);
        setRowSelection({ [Number(Object.keys(rowSelection)[0]) + 1]: true })
      }
    }
  }

  // filter btn
  const FilterBtn = () => {
    if (!tableToggle) {
      setTableToggle(true)
      setOriginalTableData([...tableData])
      let filterData = tableData?.filter((f, i) => f.ROWNO !== null)
      setTableData(filterData)
    } else {
      setTableToggle(false)
      setTableData([...originalTableData])
    }
  }

  // alignment btn
  const AlignMentBtn = (row) => {
    if (row?.ROWNO !== null) {
      const updatedData = [...tableData]
      let rowIndex = Object.keys(rowSelection)[0]
      updatedData[rowIndex] = {
        ...row,
        ALIGNMENT: row?.ALIGNMENT == "" ? "DDD" :
          row?.ALIGNMENT == "DDD" ? "000" :
            row?.ALIGNMENT === "000" ? "111" :
              row?.ALIGNMENT === "111" ? "222" :
                row?.ALIGNMENT === "222" ? "DDD" : ""
      }
      setTableData(updatedData)
    }
  }

  // style btn
  const StyleBtn = (row) => {
    if (row?.ROWNO !== null) {
      const updatedData = [...tableData]
      let rowIndex = Object.keys(rowSelection)[0]
      updatedData[rowIndex] = {
        ...row,
        FONTSTYLE: row?.FONTSTYLE == "" ? "N" :
          row?.FONTSTYLE == "N" ? "B" :
            row?.FONTSTYLE == "B" ? "I" :
              row?.FONTSTYLE == "I" ? "U" :
                row?.FONTSTYLE == "U" ? "BI" :
                  row?.FONTSTYLE == "BI" ? "IU" :
                    row?.FONTSTYLE == "IU" ? "BIU" :
                      row?.FONTSTYLE == "BIU" ? "N" : ""
      }
      setTableData(updatedData)
    }
  }

  // font size
  const FontSizeBtn = (row) => {
    if (row?.ROWNO !== null) {
      const updatedData = [...tableData]
      let rowIndex = Object.keys(rowSelection)[0]
      let fontSize = row?.FONTSIZE ?? 6
      fontSize++;
      console.log("fontSize", fontSize)
      if (fontSize > 20) {
        fontSize = 20
      }
      updatedData[rowIndex] = {
        ...row,
        FONTSIZE: fontSize
      }
      setTableData(updatedData)
    }
  }

  // Edit btn
  const handleEditModeOpen = (row) => {
    if (row?.ROWNO !== null) {
      console.log("handleEditModeOpen", row)
      table?.setEditingRow(table.getSelectedRowModel().flatRows[0])
      setTableEditingRow(table.getSelectedRowModel().flatRows[0])
      setTableObj(row)
    }
  }

  const canceEditingRow = () => {
    setTableObj({
      "RECTYPE": "REG",
      "REPORTID": "LEDMAIN",
      "COLRECID": "",
      "DISPCAP": "",
      "DISPCAPID": "",
      "USEDCAP": "",
      "USEDCAPID": "",
      "CTRLSOURCE": "",
      "SOURCEDEF": "",
      "DATATYPE": "",
      "UNBOUND": "",
      "COLWIDTH": 0,
      "COLDEC": " ",
      "DISPFORMAT": "",
      "LTOTAL": "",
      "LGROUP": "",
      "ORDEXPR": "",
      "GRPEXPR": "",
      "DISPCOND": "",
      "GRPSEQ": "",
      "RECSEQ": "",
      "GRPCAPID": "",
      "GRPCAP": "",
      "SPFLDFLAG": "   ",
      "COLPROP": "",
      "COLDATA": "",
      "TOTEXPR": "",
      "LSTRETCH": "",
      "LLEAF": "",
      "SELEXPSRC": "",
      "LORDER": "",
      "SUPFLAG": " ",
      "INCOND": "",
      "INCONDVFP": null,
      "ALIGNMENT": "",
      "FONTNAME": "",
      "FONTSIZE": "",
      "FONTSTYLE": "",
      "BACKCOLOR": "",
      "FORECOLOR": "",
      "COLSUPRESS": "",
      "CLICKDET": "",
      "LCOLUMNGRP": "",
      "DTCODEFLD": "",
      "MTBLNAME": "",
      "MCODEFLD": "",
      "MDISPFLD": "",
      "MFILTER": "",
      "S61FTY": "",
      "FCSOURCE": "",
      "FILTTYPE": " ",
      "FILTPROP": "",
      "FDEFVAL": "",
      "FCUSTEXPR": "",
      "BUNIID": 0,
      "S61CHARID": "",
      "FGSOURCE": "",
      "lOk": null,
      "ORFLDNM": "",
      "LACTIVE": "",
      "LSYSTEM": "",
      "GRPNO": null,
      "COLNO": null,
      "OLDCOLNO": 0,
      "LDEFFORMAT": "",
      "DATESEP": null,
      "DATEFRM": "",
      "FONTCASE": "",
      "LNZERO": null,
      "LCOMMA": "",
      "LCRDB": "",
      "LABS": "",
      "LCURR": "",
      "LFORECOLOR": "",
      "LBACKCOLOR": "",
      "EGKEYID": 0,
      "ROWNO": null,
      "COLLEVEL": null,
      "LEVELFLD": null,
      "COLTOTAL": null,
      "NONZERO": null,
      "COLGRPREQ": "",
      "LINPRINT": "",
      "LINGRID": "",
      "COLLOCK": "",
      "SEARCHCOL": "",
      "ALIGN_H": "",
      "ALIGN_D": "",
      "ALIGN_F": "",
      "COLDISPIN": null,
      "CACTION": "",
      "COLSPDATA": null,
      "IMGDISPAS": null,
      "IMGSTYLE": null,
      "IMGWIDTH": null,
      "IMGHEIGHT": null,
      "LBLANK": "",
      "COLTYPE": null,
      "COLSNO": null,
      "COLENO": null,
      "PRINTAT": null,
      "GRPRECID": null
    })
    setTableEditingRow(null)
  }

  const handleEditSave = () => {
    let editTableData = [...tableData]
    editTableData[tableEditingRow.index] = tableObj
    setTableData(editTableData)
    table.setEditingRow(null)
  }

  const handleSubmit = () => {
    if (TblNm == "customColumn") {
      setCustColData(tableData)
      setDrawerObj({ ...DrawerObj, open: false })
    } else if (TblNm === "reportHeader") {
      setRepHeadData(tableData)
      setDrawerObj({ ...DrawerObj, open: false })
    } else {
      setSummaryData(tableData)
      setDrawerObj({ ...DrawerObj, open: false })
    }
  }

  const table = useMantineReactTable({
    columns,
    data: tableData,
    mantineTableProps: { withColumnBorders: true },
    mantineTableContainerProps: {
      sx: { height: height * 0.5 },
    },
    enableStickyHeader:true,
    enableTopToolbar: false,
    enableColumnActions: false,
    enableSorting: false,
    enableEditing: true,
    editDisplayMode: "row",
    onEditingRowCancel: canceEditingRow,
    onEditingRowSave: handleEditSave,
    initialState: {
      density: "xs",
      // expanded: true,
    },
    state: {
      density: "0px",
      rowSelection
    },
    mantineTableBodyRowProps: ({ row, staticRowIndex, cell }) => ({
      onClick: () => {
        // console.log(row?.original, "row?.original");
        setRowSelection((prev) => ({
          [row.id]: !prev[row.id],
        }));
      },
      selected: rowSelection[row.id],
      sx: { cursor: "pointer" },
      // height: 50,
    }),
    renderRowActions: ({ row, table }) => (
      <Flex gap="md">
        <Tooltip label="Edit">
          <ActionIcon
            onClick={() => {
              table.setEditingRow(row);
              setTableEditingRow(row);
              setTableObj(row?.original)
            }}
          >
            <IconEdit size={"20px"} />
          </ActionIcon>
        </Tooltip>
      </Flex>
    ),
    renderBottomToolbar: ({ table }) => (
      <Box>
        <Grid gutter={4}>
          <Grid.Col span={12}>
            <Group
              spacing={"xs"}
              position="right"
              display={"flex"}
            >
              <MRT_TablePagination position="bottom" table={table} />
            </Group>
          </Grid.Col>
          <Grid.Col span={12}>
            <ButtonPanel
              //   table={TableRef}
              data={ButtonsData}
              Skey={table?.getSelectedRowModel()?.flatRows}
              // from={props?.obj?.id}
              setFormatBtn={(e) => {
                let OBJ = JSON.parse(e);
                console.log("setFormatBtn_OBJ", OBJ)
                switch (OBJ.p0) {
                  case "S":
                    SelectDeSelect(OBJ?.DataGridRow)
                    break;
                  case "F":
                    FilterBtn()
                    break;
                  case "AL":
                    AlignMentBtn(OBJ?.DataGridRow)
                    break;
                  case "FT":
                    StyleBtn(OBJ?.DataGridRow)
                    break;
                  case "FS":
                    FontSizeBtn(OBJ?.DataGridRow)
                    break;
                  case "E":
                    handleEditModeOpen(OBJ.DataGridRow)
                    break;
                  default:
                    break;
                }
              }}
            />
            <Center>
              <Button
                size="sm"
                variant='subtle'
                compact
                onClick={handleSubmit}
              >OK</Button>
              <Button
                size="sm"
                variant='subtle'
                compact
                onClick={() => setDrawerObj({ ...DrawerObj, open: false })}
              >Cancel</Button>
            </Center>
          </Grid.Col>
        </Grid>
      </Box>
    )
  })
  return (
    <>
      {!columns && columns?.length == 0 && !tableData && tableData?.length == 0 ? GlobalClass.RenderLoader("dots") :
        columns && columns?.length > 0 && tableData && tableData?.length > 0 &&
        <Grid gutter={4}>
          <Grid.Col span={12}>
            <MantineReactTable table={table} />
          </Grid.Col>
        </Grid>
      }
    </>
  )
}
