import {
  ActionIcon,
  // Box,
  Button,
  Center,
  Divider,
  Drawer,
  Flex,
  Grid,
  Group,
  Modal,
  Paper,
  Spoiler,
  Text,
  Textarea,
  Tooltip,
} from "@mantine/core";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import PLDateBox from "../../../PlusComponent/PLDateBox";
import PLComboBox from "../../../PlusComponent/PLComboBox";
import PLTextBox from "../../../PlusComponent/PLTextBox";
import PLNumberBox from "../../../PlusComponent/PLNumberBox";
import { useEffect, useRef, useState } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { useDispatch, useSelector } from "react-redux";
import Store from "../../../utils/store";
import SITableUserField from "../../SaleEntry/SITableUserField";
import PLDataGrid from "../../../PlusComponent/PLDataGrid";
import { modals } from "@mantine/modals";
import GlobalClass from "../../../utils/GlobalClass";
import {
  BatchLogAddRemoveFun,
  GetUserFieldObj,
  TransactionApi,
} from "../../../utils/TransactionSlices/Transaction";
import { IconEdit } from "@tabler/icons-react";
import gensetting from "../../../utils/gensetting";
import { DataSaveLoading } from "../../../utils/slices/DataSaveLoadingSlice";
import { ModalDelete } from "../../../utils/slices/ModalSlice";
import {
  GetAccountList,
  GetTMFormat,
  // GetTMFormat,
} from "../../../utils/slices/AccountListSlice";
import PLNarration from "../../../PlusComponent/PLNarration";
import T02Table from "../../T02/T02Table";
import PLTextarea from "../../../PlusComponent/PLTextarea";
import PLUserFieldBtn from "../../../PlusComponent/PLUserFieldBtn";
import PLPrint from "../../../FunctionsCall/Print/PLPrint";
import FormatListTable from "../../SMSEmailWpBtn/FormatListTable";
import { TabRemove } from "../../../utils/slices/TabList";

export default function StockTransferForm(props) {
  // console.log(props, "StockTransferForm props");
  const [t02Data, setT02Data] = useState([]);
  const [t02DataENT, setT02DataENT] = useState([]);
  const [t02Obj, setT02Obj] = useState({
    "FIELD01": "",
    "FIELD02": "",
    "FIELD03": "",
    "FIELD04": "",
    "FIELD05": "",
    "FIELD06": 0.0,
    "FIELD07": 0.0,
    "FIELD08": 0.0,
    "FIELD09": 0.0,
    "FIELD10": 0.0,
    "FIELD11": "",
    "FIELD12": "",
    "FIELD56": "",
    "FIELD13": "",
    "FIELD14": "",
    "FIELD15": "",
    "FIELD16": "",
    "FIELD17": "",
    "FIELD18": "",
    "FIELD19": "",
    "FIELD20": "",
    "FIELD21": 0.0,
    "FIELD22": 0.0,
    "FIELD23": 0.0,
    "FIELD24": "",
    "FIELD25": 0.0,
    "FIELD26": "",
    "FIELD27": "",
    "FIELD28": false,
    "FIELD29": 0.0,
    "FIELD30": "",
    "FIELD31": 0.0,
    "FIELD32": 0.0,
    "FIELD33": 0.0,
    "FIELD41": "",
    "FIELD50": 0.0,
    "FIELD51": "",
    "FIELD52": 0.0,
    "FIELD71": "",
    "FIELD81": 0.0,
    "FIELD82": "",
    "FIELD83": "",
    "FIELD84": "",
    "FIELD85": "",
    "FIELD86": "",
    "FIELD61": 0.0,
    "FIELD62": 0.0,
    "FIELD63": "",
    "FIELD64": 0.0,
    "FIELD65": "",
    "FIELD66": 0.0,
    "FIELD67": 0.0,
    "FIELD72": "",
    "FIELD68": "",
    "FIELD69": "",
    "FIELD53": "",
    "FIELD54": 0.0,
    "FIELD55": 0.0,
    "FIELD091": 2.0,
    "FIELD58": "",
    "FIELD57": "",
    "FIELD60": 0.0,
    "T41PVT": "",
    "FIELD87": 0.0,
    "FIELD88": "",
    "FLDUNQ": 0,
    "FLDAED": "",
    "FLDBRC": "",
    "FIELD90": 0.0,
    "IA00000001": "",
    "IA00000002": "",
    "IA00000003": "",
    "IA00000004": "",
    "IA00000005": "",
    "IA00000006": "",
    "IA00000007": "",
    "IA00000008": "",
    "IA00000009": "",
    "IA00000010": "",
    "IA00000011": "",
    "IA00000012": "",
    "IA00000013": "",
    "IA00000014": "",
    "IA00000015": "",
    "IA00000016": "",
    "IA00000017": "",
    "IA00000018": "",
    "IA00000019": "",
    "IA00000020": "",
    "IA00000021": "",
    "IA00000022": "",
    "IA00000023": "",
    "IA00000024": "",
    "IA00000025": "",
    "ID00000001": 0.0,
    "ID00000002": 0.0,
    "ID00000003": 0.0,
    "ID00000004": 0.0,
    "ID00000005": 0.0,
    "ID00000006": 0.0,
    "ID00000007": 0.0,
    "ID00000008": 0.0,
    "ID00000009": 0.0,
    "ID00000010": 0.0,
    "ID00000011": 0.0,
    "ID00000012": 0.0,
    "ID00000013": 0.0,
    "ID00000014": 0.0,
    "ID00000015": 0.0,
    "ID00000016": 0.0,
    "ID00000017": 0.0,
    "ID00000018": 0.0,
    "ID00000019": 0.0,
    "ID00000020": 0.0,
    "ID00000021": 0.0,
    "ID00000022": 0.0,
    "ID00000023": 0.0,
    "ID00000024": 0.0,
    "ID00000025": 0.0,
    "IP00000001": 0.0,
    "IP00000002": 0.0,
    "IP00000003": 0.0,
    "IP00000004": 0.0,
    "IP00000005": 0.0,
    "IP00000006": 0.0,
    "IP00000007": 0.0,
    "IP00000008": 0.0,
    "IP00000009": 0.0,
    "IP00000010": 0.0,
    "IP00000011": 0.0,
    "IP00000012": 0.0,
    "IP00000013": 0.0,
    "IP00000014": 0.0,
    "IP00000015": 0.0,
    "IP00000016": 0.0,
    "IP00000017": 0.0,
    "IP00000018": 0.0,
    "IP00000019": 0.0,
    "IP00000020": 0.0,
    "IP00000021": 0.0,
    "IP00000022": 0.0,
    "IP00000023": 0.0,
    "IP00000024": 0.0,
    "IP00000025": 0.0,
    "IT00000001": "",
    "IT00000002": "",
    "IT00000003": "",
    "IT00000004": "",
    "IT00000005": "",
    "IT00000006": "",
    "IT00000007": "",
    "IT00000008": "",
    "IT00000009": "",
    "IT00000010": "",
    "IT00000011": "",
    "IT00000012": "",
    "IT00000013": "",
    "IT00000014": "",
    "IT00000015": "",
    "IT00000016": "",
    "IT00000017": "",
    "IT00000018": "",
    "IT00000019": "",
    "IT00000020": "",
    "IT00000021": "",
    "IT00000022": "",
    "IT00000023": "",
    "IT00000024": "",
    "IT00000025": "",
    "UAP": 0.0,
    "UBA": "",
    "UDDATE": "",
    "UDIS": 0.0,
    "UDISC": 0.0,
    "UGRO": 0.0,
    "UISSUERA": 0.0,
    "UMUR": 0.0,
    "UPE": 0.0,
    "URATE": 0.0,
    "URE": "",
    "UREMARK": "",
    "IA00000026": "",
    "ID00000026": 0.0,
    "IP00000026": 0.0,
    "IT00000026": "",
    "UUWQ": "",
    "USERFLD": "",
    "F03NM": "",
    "F20NM": "",
    "CATNM": "",
    "GRPNM": "",
    "CATCD": "",
    "GRPCD": "",
    "USERFLDB": "2",
    "SRDETAIL": "",
    "EXPDUMMY": " ",
    "BARCODE": "",
    "OLDPRD": "",
    "OLDQTY": 0.0,
    "OQTY2": 0.0,
    "OLDRATE": 0.0,
    "LADDNEW": "Y",
    "PRICELIST": " ",
    "_BATSTOCK": " ",
    "_LOCWISE": " ",
    "LDUALSTK": " ",
    "SRNOSTK": " ",
    "MRPSTK": " ",
    "RORI": " ",
    "ORDDUMMY": " ",
    "JODUMMY": " ",
    "SRIDUMMY": " ",
    "SRRDUMMY": " ",
    "SRIDUMMYF": " ",
    "SRRDUMMYF": " ",
    "SRISSCD": "",
    "OLDSRNO": "",
    "SCNOCL": "",
    "SCNOCL1": "",
    "MANUDATE": "",
    "EXPDATE": "",
    "T02F08EXP": 0.0,
    "_EXPTOTALAMT": 0.0
  });
  const [editT41, setEditT41] = useState({});
  const [editT02, setEditT02] = useState({});
  const [flagCopy, setFlagCopy] = useState(false);
  const [group, setGroup] = useState({
    FIELD01: "IJPWZANWVPGV",
    FIELD02: "20240331",
    FIELD03: 0,
    FIELD04: "",
    FIELD05: "",
    FIELD06: "0",
    FIELD07: "0",
    FIELD08: 0,
    FIELD09: 0,
    FIELD10: "",
    FIELD11: "",
    FIELD12: "",
    FIELD13: "",
    FIELD14: "N",
    FIELD15: "",
    FIELD16: "",
    FIELD17: "",
    FIELD18: 0,
    FIELD21: "",
    FIELD22: "",
    FIELD23: "",
    FIELD30: "",
    FIELD31: "",
    FIELD32: "",
    FIELD33: "",
    FIELD70: "",
    FIELD71: "",
    FIELD75: "",
    FIELD99: "",
    FIELD72: "",
    FIELD20: 0,
    FIELD80: "",
    FIELD51: 0,
    FIELD34: "",
    FIELD35: "",
    FIELD36: "",
    FIELD37: "",
    FIELD38: "",
    FLDJIO: "",
    FIELD39: "",
    FIELD40: "",
    FIELD41: "",
    FIELD42: "",
    FIELD43: "",
    FIELD44: "",
    FIELD45: "",
    FIELD46: "",
    FIELD47: "",
    FIELD48: "",
    FIELD49: "",
    FIELD50: "",
    FIELD52: "",
    FIELD53: "",
    T41PVT: "IJ",
    FIELD54: "",
    T41VNO: "",
    CMID: 0,
    ADDID: 0,
    PRESCPATH: "",
    OSTATUS: "",
    DTYPE: "",
    DELURL: "",
    DELMBNO: "",
    FIELD55: "",
    FIELD56: "",
    T41POS: "",
    FLDUNQ: 0,
    FLDAED: "A",
    FLDBRC: "",
    UU73: "",
    EA00000001: "",
    EA00000002: "",
    EA00000003: "",
    EA00000004: "",
    EA00000005: "",
    EA00000006: "",
    EA00000007: "",
    EA00000008: "",
    EA00000009: "",
    EA00000010: "",
    EA00000011: "",
    EA00000012: "",
    EA00000013: "",
    EA00000014: "",
    EA00000015: "",
    ED00000001: 0,
    ED00000002: 0,
    ED00000003: 0,
    ED00000004: 0,
    ED00000005: 0,
    ED00000006: 0,
    ED00000007: 0,
    ED00000008: 0,
    ED00000009: 0,
    ED00000010: 0,
    ED00000011: 0,
    ED00000012: 0,
    ED00000013: 0,
    ED00000014: 0,
    ED00000015: 0,
    EP00000001: 0,
    EP00000002: 0,
    EP00000003: 0,
    EP00000004: 0,
    EP00000005: 0,
    EP00000006: 0,
    EP00000007: 0,
    EP00000008: 0,
    EP00000009: 0,
    EP00000010: 0,
    EP00000011: 0,
    EP00000012: 0,
    EP00000013: 0,
    EP00000014: 0,
    EP00000015: 0,
    PA000001: "",
    PA000002: "",
    PD000001: 0,
    PD000002: 0,
    UBILTO: "",
    UDISP: "",
    UEMAIL: "",
    UGSTR: "",
    ULEN: "",
    ULRD: "",
    UNAME: "",
    UOD: "",
    UODD: "",
    UODP: "",
    UOP: "",
    UPAYT: "",
    UPR: "",
    URA: "",
    UREMA: "",
    UREST6: "",
    USAL: 0,
    USALM: "",
    UTR: "",
    UTRA: "",
    UUAASS01: "",
    UUASWER: "",
    UUFR4: "",
    UUJA: "",
    UUJBWID: "",
    UUJWTRID: "",
    UUPRDID: "",
    UUPRODC: "",
    UUPRTR: "",
    UUSALM: "",
    UUT: "",
    UUT78: "",
    UUTPR: "",
    UUUASD09: "",
    UUUASWER: "",
    UUUJBOS: "",
    UUUJOB: "",
    UUUOS: "",
    UU05: "",
    UU87: "",
    UUISSUID: "",
    UUE: "",
    EA00000016: "",
    ED00000016: 0,
    EP00000016: 0,
    EA00000017: "",
    ED00000017: 0,
    EP00000017: 0,
    EA00000018: "",
    ED00000018: 0,
    EP00000018: 0,
    EA00000019: "",
    ED00000019: 0,
    EP00000019: 0,
    EA00000020: "",
    ED00000020: 0,
    EP00000020: 0,
    EA00000021: "",
    ED00000021: 0,
    EP00000021: 0,
    EA00000022: "",
    ED00000022: 0,
    EP00000022: 0,
    UUABCD: "",
    UUGFEJH: "",
    UUTEST56: "",
    EA00000023: "",
    ED00000023: 0,
    EP00000023: 0,
    UUSALESB: "",
    EA00000024: "",
    ED00000024: 0,
    EP00000024: 0,
    DSDATE: "2023-04-01T00:00:00+03:00",
    DEDATE: "2024-03-31T00:00:00+02:00",
    CACTION: "A",
    lInvType: false,
    lMTaxEnt: false,
    T92F00: "",
    FIELD04NM: "",
    CFRMTITLE: "Add Stock Journal",
    BTOBEFF: " ",
    M01BAL: "0",
    M01GRP: "",
    M01OPPGRP: "",
    M01CT: "",
    F07ACC: "",
    LBLF08AMT: "0.00   ",
    LBLF07AMT: "0.00   ",
    LBLF08SYMB: null,
    LBLF07SYMB: null,
  });
  const [isVisible, setIsVisible] = useState(false);
  const [billAmt, setBillAmt] = useState(0);
  const [NarrationData, setNarrationData] = useState([]);
  const [SelectedNarrationData, setSelectedNarrationData] = useState([]);
  const [creatingModeOn, setCreatingModeOn] = useState(false);
  const [toArr, setToArr] = useState([]);
  const [locFromId, setLocFromId] = useState({});
  const [t02Flag, setT02Flag] = useState(false)
  const [flag, setFlag] = useState(true);
  const [selectedProductObj, setSelectedProductObj] = useState({});
  const [editingRow, setEditingRow] = useState(null);
  const [tableData, setTableData] = useState(null);
  // const [tableExpenseVal, setTableExpenseVal] = useState(0);
  const [footerButtonArray, setFooterButtonArray] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [isHandleCreateNeeded, setIsHandleCreateNeeded] = useState("")
  const [HeaderExpenseFlag, setHeaderExpenseFlag] = useState(false);
  const [pagecCode, setPagecCode] = useState("");
 const [f02,setF02]=useState(false);
 const [vDate,setVDate]=useState("");


  let dra = {
    title: "",
    size: "md",
    body: "",
    open: false,
    onClickCloseButton: () => { },
  };
  const [DrawerObj, setDrawerObj] = useState(dra);
  const [BillSeriesHideShow, setBillSeriesHideShow] = useState({
    BillNoVal: "",
    TextInput: false,
    DataGridInput: true,
  });
  const [BillNoStatus, setBillNoStatus] = useState(false)
  const spoilerControlRef = useRef(null);
  const locFromRef = useRef(null);
  const locToref = useRef(null);
  const formRef = useRef(null);
  const TableRef = useRef();
  const TableContainer = useRef(null);
  const vouNoRef = useRef();
  const docNoRef = useRef();
  const docDateRef = useRef();

  const dispatch = useDispatch();
  const columnHelper = createColumnHelper();
  const {
    AccountListData: accList,
    TMFormatData: tmList,
    isLoading: AccNameisLoading,
  } = useSelector((state) => state.AccountList);
  let p0 = props.obj.p0;
  let { index, obj } = props;
  const rowSum = t02Data.reduce((a, b) => {
    if (b?.FIELD05 == "C") a = a + (b?.FIELD08 || 0);
    else if (b?.FIELD05 == "D") a = a - (b?.FIELD08 || 0);
    return a;
  }, 0);
  const {
    TransactionData: dataEnt,
    isLoading,
    hasError,
    ErrorMsg,
  } = useSelector((state) => state?.Transaction);
  const SaveLoading = useSelector(
    (state) => state?.DataSaveLoadingSlice?.SaveLoading
  );
  const { isUserFieldLoading, UserField } = useSelector(
    (store) => store?.Transaction
  );
  const copyRow = useSelector((state) => state?.GetPendingIssue?.copyRow);
  const GetYearAndDate = useSelector(
    (state) => state?.UserList?.YearAndDateSend
  );
  const { oYear } = useSelector((state) => state?.Language?.General);
  const footerDate = useSelector((state)=>state?.Language?.footerDate);
  const T02UF = dataEnt?.T02UF;
  const dtUFT11 = dataEnt?.dtUFT11;
  const P_M32LF = dataEnt?.P_M32LF;
  const P_M32LFDT = dataEnt?.P_M32LFDT;
  const P_M32LT = dataEnt?.P_M32LT;
  const P_M32LTDT = dataEnt?.P_M32LTDT;
  const OS11 = dataEnt?.OS11;
  const T92 = dataEnt?.T92;
  const T41 = dataEnt?.T41;
  const T02 = dataEnt?.T02;
  const UFT41H = dataEnt?.UFT41H;
  const lEditVou = dataEnt?.lEditVou;
  const OSV = dataEnt?.OSV;
  const map = new Map();
  let UFT41Hval = UFT41H?.filter((v) => v?.S13F87 == "HEADER");
  let UFT41Fval = UFT41H?.filter((v) => v?.S13F87 == "FOOTER");

  let editData = props.obj;
  let editId = editData?.id;

  let ExpenseObj = {
    OS11,
    OSV,
    oYear,
    ItemCode: selectedProductObj?.FIELD03,
    selectedProductObj : selectedProductObj,
    T02Obj: { ...t02Obj, VorC: group?.FIELD45 },
    group,
    t02Data
  }

  //pre filled fields

  useEffect(()=>{
   if(vDate?.length>0 && p0=="A"){
    setGroup({...group,FIELD02:vDate})
   }
  },[vDate,group?.FIELD02])


  useEffect(() => {
    setFooterButtonArray(["Copy", "Serial No."])
  }, [])

  useEffect(() => {
    if (obj) {
      Promise.resolve(
        Store.dispatch(
          TransactionApi({ ...obj, p0: obj.p0 == "U" ? "E" : obj.p0 })
        )
      )
      // .then(() =>
      //   dispatch(
      //     GetAccountList({
      //       id: "T_M32C",
      //       name: "",
      //       type: "A",
      //       p0: "T_M32C",
      //       p1: "T_M32C",
      //       p2: "",
      //       p3: "",
      //     })
      //   )
      // )
      // .then(() =>
      //   dispatch(
      //     GetTMFormat({
      //       id: "T_M32C",
      //       name: "",
      //       type: "A",
      //       p0: "T_M32C",
      //       p1: "T_M32C",
      //       p2: "",
      //       p3: "",
      //     })
      //   )
      // );
    }


    setPagecCode("TSTS");

  }, [obj]);

  useEffect(() => {
    if (UFT41H && p0 == "E") {


      UFT41H?.forEach((item) => {
        if (item?.S13F14 == "PLPOPUP") {
          const key = seperate(item.S13F09)[1]; // Key
          const value = T41?.[item.S13F02]; // Value
          // }
          if (map.has(key)) {
            // If it exists, add the value to the set/array of values for that key
            map.get(key).add(value); // Use a Set to ensure uniqueness of values

          }
          else {
            map.set(key, new Set([value]));
          }
        }

      })

      if (map?.size > 0) {
        map.forEach((value, key) => {
          const dyValue = Array.from(value).length ? ("'" + Array.from(value).join("', '") + "'") : '';
          setTimeout(() => {
            Promise.resolve(() => {
              dispatch(
                GetAccountList({
                  id: key,
                  name: "",
                  type: "A",
                  p0: key,
                  p1: key,
                  p2: "",
                  p3: "",
                  cWhere: `${seperate1(key)[1]}.FIELD01 IN (${dyValue})`,
                })
              );
            }).then(() => {
              dispatch(GetTMFormat({
                "id": key,
                "name": "",
                "type": "A",
                "p0": key,
                "p1": key,
                "p2": "TFORM0000001",
                "p3": ""
              }))
            })
          }, [500])
        })
      }




    }
  }, [UFT41H])

  useEffect(() => {
    if (T02) {
      setT02Flag(true)
      setT02Data(T02);

      // setT02Data(T02?.filter((t) => t?.FIELD03 != "" && t?.FIELD03 != null));
      if (obj.p0 == "E") {
        setT02Obj(T02?.[T02?.length - 1]);
      } else {
        setT02Obj(T02?.[0])
      }
    }
  }, [T02])

  // useEffect(() => {
  //   if (t02Obj?.FIELD33) {
  //     setT02Obj({ ...t02Obj, FIELD21: parseInt(t02Obj?.FIELD33) / parseInt(t02Obj?.FIELD22) })
  //   }
  // }, [t02Obj?.FIELD33])

  let stringOfNames;

  useEffect(() => {
    if (SelectedNarrationData?.length > 0) {
      const stringifyArray = SelectedNarrationData?.map((a) => a.FIELD01);
      stringOfNames = stringifyArray.join(" ");
      console.log("stringOfNames", stringOfNames);
    }
    setGroup({
      ...group,
      FIELD15:
        group?.FIELD15 != ""
          ? group?.FIELD15 + " " + stringOfNames
          : stringOfNames,
    });
    // setGroup({...group,FIELD15:})
  }, [stringOfNames, SelectedNarrationData]);

  useEffect(() => {
    // console.log(dataEnt?.T41, "dataEnt");
    setGroup({...dataEnt?.T41,FIELD02:vDate?.length>0 && p0=="A" ? vDate : dataEnt?.T41?.FIELD02});
    setT02DataENT(dataEnt?.T02ENT);
  }, [dataEnt]);

  useEffect(() => {
    // console.log("setBillAmt isVisible", t02Data);
    if (isVisible)
      setBillAmt(t02Data?.reduce((a, b) => (a = a + b?.FIELD08), 0));
  }, [isVisible, t02Data]);

  const seperate = (e) => {
    var paraArr = e.split("~C~");
    // console.log("paraArr=>", paraArr);
    if (paraArr.length == 1) {
      paraArr[1] = paraArr[0];
    }
    return paraArr;
  };

  const seperate1 = (e) => {
    var paraArr = e.split("_");
    // console.log("paraArr=>", paraArr);
    if (paraArr.length == 1) {
      paraArr[1] = paraArr[0];
    }
    return paraArr;
  };

  function dateFormater(date){
    let year = date.toLocaleString("en-IN", { year: "numeric" });
        let month = date.toLocaleString("en-IN", { month: "2-digit" });
        let day = date.toLocaleString("en-IN", { day: "2-digit" });
        let date1 = year + month + day;
        return date1;
   }


  function NarrationBox() {
    setFlag(false);
    // if(flag )
    setDrawerObj({
      ...dra,
      title: <Text fw={700}>Naration</Text>,
      body: (
        <PLNarration
          NarrationArray={NarrationData}
          Flag={SelectedNarrationData?.length > 0 ? false : true}
          selectedNarrationArray={SelectedNarrationData}
          // NarrationVal={group}
          setNarrationArray={(e) => {
            // setGroup(e)
            // console.log("setNarrationArray", e);
            setNarrationData(e.NarrationData);
            setSelectedNarrationData(e.selectedNarration);
          }}
          DrawerOBJ={DrawerObj}
          setDrawerOBJ={(e) => setDrawerObj(e)}
        />
      ),
      open: true,
      size: "md",
      position: "right",
    });
  }
  function DateToString(e) {
    // console.log(e, "Datetostring");
    let year = e?.toLocaleString("en-IN", { year: "numeric" });
    let month = e?.toLocaleString("en-IN", {
      month: "2-digit",
    });
    let day = e?.toLocaleString("en-IN", { day: "2-digit" });

    let formatedStartDate = day + "/" + month + "/" + year;
    let startDate = year + month + day;
    return { formatedStartDate, startDate };
  }

  let compareTwoArrayOfObjects = (editT02, t02Data) => {
    return (
      editT02?.length === t02Data?.length &&
      editT02?.every((v) =>
        t02Data?.some((c) => Object.keys(v).every((key) => v[key] === c[key]))
      )
    );
  };
  function dispatchFunction() {
    Promise.resolve(Store.dispatch(DataSaveLoading({[obj?.cSTID]:false})))
      .then(() => {
        if (p0 == "A") {
          Promise.resolve(Store.dispatch(TransactionApi(obj))).then(()=>{
            setF02(false);
          })
        } else {
          if(String(props?.index).startsWith("modalForm")){
            dispatch(TabRemove(props?.index))
          }else{
            dispatch(ModalDelete(props.index))
          }
        } // Store.dispatch(ModalDelete(index));
      })
      .then(() => {
        setBillSeriesHideShow({
          ...BillSeriesHideShow,
          BillNoVal: "",
        });
      });
    let PaginationObj = Store.getState().DataSaveLoadingSlice.PaginationObj;
    // console.log(index, "ModalDelete");
    // Store.dispatch(ModalDelete(index));
    Promise.resolve(
      // Store.dispatch(
      //   GetAccountList({
      //     id: "03630342",
      //     name: "Stock Transfer",
      //     p0: "MT",
      //     p1: "T41VOULISTTS",
      //     p2: "",
      //     p3: "",
      //     type: "A",
      //     pagination: PaginationObj["03630342"],
      //   })
      // )

      dispatch(GetAccountList({ ...obj?.BtnPnlObj, pagination: PaginationObj[obj?.cSTID] }))  
    );
  }
  function OnPrint() {
    Promise.resolve(dispatch(DataSaveLoading({[obj?.cSTID]:false}))).then(() => {
      setDrawerObj({
        ...dra,
        title: <Text fw={700}>Print Voucher</Text>,
        onClickCloseButton: () => {
          // setDrawerObj(dra);
          Promise.resolve(
            setDrawerObj({
              ...DrawerObj,
              open: false,
            })
          ).then(() => dispatchFunction());
        },
        onClose: () => {
          // setDrawerObj(dra);
          Promise.resolve(
            setDrawerObj({
              ...DrawerObj,
              open: false,
            })
          ).then(() => dispatchFunction());
        },
        body: (
          <>
            <PLPrint data={obj} />
          </>
        ),
        open: true,
        size: "sm",
        position: "right",
      });
    });
  }

  function OnEmail() {
    setDrawerObj({
      ...dra,
      title: <Text fw={700}>EMail </Text>,
      onClickCloseButton: () => {
        // setDrawerObj(dra);
        console.log(OSV._ONLINEVOU, "OSV._ONLINEVOU");
        Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
          () => {

            if (OSV._ONLINEVOU == "Y" || OSV._ONLINEVOU == "D") {
              OnPrint();
            } else {
              dispatchFunction();
            }
          }
        );
      },
      onClose: () => {
        // setDrawerObj(dra);
        Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
          () => {

            if (OSV._ONLINEVOU == "Y" || OSV._ONLINEVOU == "D") {
              OnPrint();
            } else {
              dispatchFunction();
            }
          }
        );
      },
      body: (
        <>
          <FormatListTable
            obj={{ ...obj, p0: "E", text: "EMail" }}
            index={props.index}
          />
        </>
      ),
      open: true,
      size: "50%",
      position: "right",
    });
  }
  function handleOkButton(e) {
    // console.log("OK BTN", t02Data?.length, group?.length);
    // if (t02Data?.length < 2 ) {

    // if (group?.FIELD04 == "" || group?.FIELD05 == "") {
    if (group?.FIELD04 == "" || group?.FIELD05 == "") {
      GlobalClass.Notify(
        "warning",
        "Validation error",
        "Please fill out all the required fields"
      );
    } else {
      let newT02 = T02?.filter((v, i) => v?.FIELD03 !== "");
      if (
        obj.p0 == "E" &&
        compareTwoArrayOfObjects(newT02, t02Data) &&
        JSON.stringify(T41) === JSON.stringify(group)
      ) {
        Promise.resolve(dispatch(DataSaveLoading({[obj?.cSTID]:false}))).then(() =>
          {
            if(String(props?.index).startsWith("modalForm")){
              dispatch(TabRemove(props?.index))
            }else{
              dispatch(ModalDelete(props.index))
            }
          }
        );
        return;
      } else {
        Promise.resolve(Store.dispatch(DataSaveLoading({[obj?.cSTID]:true}))).then(() =>
          GlobalClass.Notify(
            "info",
            p0 == "A" ? "Adding" : "Editing",
            "Please wait while we process your data"
          )
        );
        // console.log(group?.FIELD07, "group?.FIELD07");
        let newT41 = {
          ...group,
          FIELD06: group?.FIELD07 == 0 ? rowSum : group?.FIELD07,
        };

        let FilterT02Data = t02Data?.filter((t) => t?.FIELD03 != "")

        let dataToSend = { T41: e ? e : { ...newT41 }, T02: FilterT02Data };

        var data = {
          CorpID: gensetting.getcorpid(),
          cUser: gensetting.getcuser(),
          cPass: gensetting.getcpass(),
          CmpNo: gensetting.getcmpno(),
          cAction: props ? p0 : "A",
          cCode: p0 == "E" ? editId : "TSTS",
          cSData: JSON.stringify(dataToSend),
          YrNo:localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
        };
        // console.log(dataToSend, "data Voucher Num");
        let param = JSON.stringify({
          CorpID: gensetting.getcorpid(),
          cUser: gensetting.getcuser(),
          cPass: gensetting.getcpass(),
          CmpNo: gensetting.getcmpno(),
          cAction: p0 == "E" ? p0 : "A",
          cCode: p0 == "E" ? editId : "TSTS",
        });

        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        };
        // console.log(dataToSend, "param Stock Journal");
        // try {
        fetch(
          GlobalClass.ApiUrl + GlobalClass.PostVouEnt + "?pa=" + param,
          requestOptions
        )
          .then((response) => {
            // console.log(!response.ok, "response.ok");
            if (!response?.ok) {
              throw new Error(`HTTP error! Status: ${response?.status}`);
            } //This block give more detail info about error other then 2xx status codes.
            return response?.json();
          })
          .then((data) => {
            if (data?.status == "SUCCESS") {
              GlobalClass.Notify(
                "success",
                p0 == "A" ? "Added" : "Edited",
                p0 == "A" ? "Added successfully" : "Edited successfully"
              );

              Promise.resolve(
                OSV._ONLINEMAL == "Y" || OSV._ONLINEMAL == "L"
                  ? Promise.resolve(dispatch(DataSaveLoading({[obj?.cSTID]:false}))).then(
                    () => {
                      OnEmail();
                    }
                  )
                  : Promise.resolve(
                    setDrawerObj({ ...DrawerObj, open: false })
                  ).then(() => {
                    if (OSV._ONLINEVOU == "Y" || OSV._ONLINEVOU == "D") {
                      OnPrint();
                    } else {
                      dispatchFunction();
                    }
                  })
              );
            } else if (data?.status == "FAIL") {
              Promise.resolve(Store.dispatch(DataSaveLoading({[obj?.cSTID]:false}))).then(
                () => {
                  setT02Data(t02Data);
                  GlobalClass.Notify("error", data?.status, data?.message);
                }
              );
            }
          })
          .catch((e) => {
            // console.log("inside catch", e);
            GlobalClass.Notify("error", "Error", `${e.message}`);
            Promise.resolve(Store.dispatch(DataSaveLoading({[obj?.cSTID]:false}))).then(() => {
              setT02Data(t02Data);
            });
          });
      }
    }
  }
  function UFT41HFieldRenderer(e) {
    if (e == "Header") {
      return (
        UFT41H &&
        UFT41H?.length > 0 &&
        UFT41H.map((h) => {
          // console.log("User Field=>", h?.S13F14, h?.S13F07, h?.S13F02);
          if (h?.S13F87 == "HEADER") {
            //b?.S13F14
            //console.log("UFT41HRenderHeader==>",h);
            return (
              <>
                {h?.S13F14 == "TEXTBOX" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLTextBox
                        value={group?.[h?.S13F02]}
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [h?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h?.S13F14 == "NUMBOX" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLNumberBox
                        value={group?.[h?.S13F02]}
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [h?.S13F02]: e });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h?.S13F14 == "PLPOPUP" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLDataGrid
                        width={"500px"}
                        name={h.S13F02}
                        value={{
                          value: group?.[h?.S13F02],
                          label: accList?.[seperate(h.S13F09)[1]]?.filter((a) => a?.FIELD01 == group?.[h?.S13F02])?.[0]?.[h?.S13F88 == "H" ? "FIELD01" : ("FIELD02" in (accList?.[seperate(h.S13F09)[1]]?.filter((a) => a?.FIELD01 === group?.[h?.S13F02])?.[0] || {}) ? "FIELD02" : tmList[seperate(h?.S13F09)[1]]?.oS60?.cAssignFld)],
                        }}
                        p0={seperate(h.S13F09)[0]}
                        p1={seperate(h.S13F09)[1]}
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          //console.log(e);
                          setGroup({ ...group, [h?.S13F02]: e?.FIELD01 });
                          // M01handlechange({ id: item.S13F02, value: e })
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h?.S13F14 == "COMBOBOX" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLComboBox
                        value={group?.[h?.S13F02]}
                        copno={seperate(h?.S13F09)[1]}
                        dispexpr={"DisplayMember"}
                        valexpr={"ValueMember"}
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [h?.S13F02]: e?.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h?.S13F14 == "DATEPICKER" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLDateBox
                        defaultval={
                          group?.[h?.S13F02]?.trim() != ""
                            ? group?.[h?.S13F02]
                            : GetYearAndDate?.strtDate
                        }
                        dispformat="DD/MM/YYYY"
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          let { startDate } = DateToString(e);
                          if (startDate?.length == 8) {
                            setGroup({ ...group, [h?.S13F02]: e });
                          }
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h?.S13F14 == "TIMEPICKER" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={4}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLDateBox
                        // value={new Date(group?.[h?.S13F02]).getTime()}
                        dispformat="DD/MM/YYYY"
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [h?.S13F02]: e });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h.S13F14 === "MEMOBOX" && h.S13F21 === "N" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLTextBox
                        value={group?.[h?.S13F02]}
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [h?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h.S13F14 === "MEMOBOX" && h.S13F21 === "H" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLTextBox
                        value={group?.[h?.S13F02]}
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [h?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h.S13F14 === "FILEUPLOAD" && h.S13F21 === "I" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLTextBox
                        value={group?.[h?.S13F02]}
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [h?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h.S13F14 === "FILEUPLOAD" && h.S13F21 === "A" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLTextBox
                        value={group?.[h?.S13F02]}
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [h?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}
              </>
            );
          }
        })
      );
    } else if (e == "Footer") {
      return (
        UFT41H &&
        UFT41H?.length > 0 &&
        UFT41H.map((f) => {
          if (f?.S13F87 == "FOOTER") {
            //b?.S13F14
            return (
              <>
                {f?.S13F14 == "TEXTBOX" && (
                  <>
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <PLTextBox
                          value={group?.[f?.S13F02]}
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            //console.log(e)
                            setGroup({ ...group, [f?.S13F02]: e.target.value });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  </>
                )}

                {f?.S13F14 == "NUMBOX" && (
                  <>
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <PLNumberBox
                          value={group?.[f?.S13F02]}
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            //console.log(e)
                            setGroup({ ...group, [f?.S13F02]: e });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  </>
                )}

                {f?.S13F14 == "PLPOPUP" && (
                  <>
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <PLDataGrid
                          width={"500px"}
                          value={{
                            value: group?.[f?.S13F02],
                            label: accList?.[seperate(f.S13F09)[1]]?.filter((a) => a?.FIELD01 == group?.[f?.S13F02])?.[0]?.[f?.S13F88 == "H" ? "FIELD01" : ("FIELD02" in (accList?.[seperate(f.S13F09)[1]]?.filter((a) => a?.FIELD01 === group?.[f?.S13F02])?.[0] || {}) ? "FIELD02" : tmList[seperate(f?.S13F09)[1]]?.oS60?.cAssignFld)],
                          }}
                          p0={seperate(f.S13F09)[0]}
                          p1={seperate(f.S13F09)[1]}
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            //console.log(e)
                            setGroup({ ...group, [f?.S13F02]: e?.FIELD01 });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  </>
                )}

                {f?.S13F14 == "COMBOBOX" && (
                  <>
                    {/* <Grid.Col
                      span={4}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                     
                      <PLComboBox
                        value={group?.[f?.S13F02]}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [f?.S13F02]: e.value });
                        }}
                      />
                    </Grid.Col> */}
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <PLComboBox
                          value={group?.[f?.S13F02]}
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            //console.log(e)
                            setGroup({ ...group, [f?.S13F02]: e.value });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  </>
                )}

                {f?.S13F14 == "DATEPICKER" && (
                  <>
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <PLDateBox
                          defaultval={
                            group?.[f?.S13F02]?.trim() != ""
                              ? group?.[f?.S13F02]
                              : GetYearAndDate?.strtDate
                          }
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            let { startDate } = DateToString(e);
                            if (startDate?.length == 8) {
                              setGroup({ ...group, [f?.S13F02]: e });
                            }
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                    {/* <Grid.Col
                      span={4}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                    
                      <PLDateBox
                        // value={new Date(group?.[f?.S13F02]).getTime()}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [f?.S13F02]: e });
                        }}
                      />
                    </Grid.Col> */}
                  </>
                )}

                {f?.S13F14 == "TIMEPICKER" && (
                  <>
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <PLDateBox
                          // value={new Date(group?.[f?.S13F02]).getTime()}
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            //console.log(e)
                            setGroup({ ...group, [f?.S13F02]: e });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                    {/* <Grid.Col
                      span={4}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                     
                      <PLDateBox
                        // value={new Date(group?.[f?.S13F02]).getTime()}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [f?.S13F02]: e });
                        }}
                      />
                    </Grid.Col> */}
                  </>
                )}

                {f.S13F14 === "MEMOBOX" && f.S13F21 === "N" && (
                  <>
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <PLTextBox
                          value={group?.[f?.S13F02]}
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            //console.log(e)
                            setGroup({ ...group, [f?.S13F02]: e.target.value });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                    {/* <Grid.Col
                      span={4}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                 
                      <PLTextBox
                        value={group?.[f?.S13F02]}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [f?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col> */}
                  </>
                )}

                {f.S13F14 === "MEMOBOX" && f.S13F21 === "H" && (
                  <>
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <PLTextBox
                          value={group?.[f?.S13F02]}
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            //console.log(e)
                            setGroup({ ...group, [f?.S13F02]: e.target.value });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  </>
                )}

                {f.S13F14 === "FILEUPLOAD" && f.S13F21 === "I" && (
                  <>
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <PLTextBox
                          value={group?.[f?.S13F02]}
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            //console.log(e)
                            setGroup({ ...group, [f?.S13F02]: e.target.value });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  </>
                )}

                {f.S13F14 === "FILEUPLOAD" && f.S13F21 === "A" && (
                  <>
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <PLTextBox
                          value={group?.[f?.S13F02]}
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            //console.log(e)
                            setGroup({ ...group, [f?.S13F02]: e.target.value });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  </>
                )}
              </>
            );
          }
        })
      );
    }
  }
  useEffect(() => {
    const formElement = formRef.current;
    if (formElement) {
      formElement.addEventListener("keypress", (e) =>
        GlobalClass.formNextInput(e, formRef)
      );
    }
    return () => {
      if (formElement) {
        formElement.removeEventListener("keypress", (e) =>
          GlobalClass.formNextInput(e, formRef)
        );
      }
    };
  }, [formRef]);
  // useEffect(() => {
  //   if (BillSeriesHideShow.BillNoVal.length <= 0) {
  //     setBillSeriesHideShow({
  //       ...BillSeriesHideShow,
  //       DataGridInput: true,
  //       TextInput: false,
  //     });
  //   }
  // }, [BillSeriesHideShow.BillNoVal]);
  useEffect(() => {
    // console.log(P_M32LTDT, "P_M32LTDT-");
    setToArr(P_M32LTDT?.jData?.filter((v) => v?.FIELD01 !== group?.FIELD04));
  }, [group?.FIELD04]);
  useEffect(() => {
    if (creatingModeOn == true) {
      setT02Obj({
        ...t02Obj,
        FIELD05: "R,C".includes(OS11?.S11F22) ? "C" : "D",
      });
    }
  }, [creatingModeOn, OS11?.FIELD22]);
  useEffect(() => {
    console.log(OS11?.S11F01, "OS11?.S11F01=");
    if (OS11?.S11F01 && obj?.p0 == "U") {
      dispatch(GetUserFieldObj(OS11?.S11F01));
    }
  }, [OS11?.S11F01, obj]);


  console.log(
    isUserFieldLoading,
    "isUserFieldLoading",
    isLoading,
    AccNameisLoading["T_M32C"],
    SaveLoading
  );
  return (
    <>
      {hasError ? <>
        <Text>{ErrorMsg?.message}</Text>
        <Button onClick={() => {
          if (obj) {
            Promise.resolve(dispatch(TransactionApi({ ...obj, p0: obj.p0 == "U" ? "E" : obj.p0 }))).then(() => {
              dispatch(GetAccountList({
                "id": "P_M21",
                "name": "",
                "type": "A",
                "p0": "P_M21",
                "p1": "P_M21",
                "p2": "",
                "p3": ""
              }))
            }).then(() => {
              if (obj?.p0 == "U") {
                dispatch(GetUserFieldObj(OS11?.S11F01))
              }
            })
          }
        }}>Retry</Button>
      </> : SaveLoading[obj?.cSTID] == true ||
        isLoading ||
        isUserFieldLoading ? (
        GlobalClass.RenderLoader("dots")
      ) : obj?.p0 == "U" ? (
        <>
          <PLUserFieldBtn
            UserFieldArray={UserField?.UFTbl}
            T41={group}
            onChange={(e) => {
              // Promise.resolve(setGroup(e)).then(()=>
              handleOkButton(e);
              // )
            }}
          />
        </>
      ) : (
        <div ref={formRef} onKeyDown={(e)=>{
          if(e?.key=="Escape"){
            if(window?.confirm("Are you sure to exit?")){
              if (String(props?.index).startsWith("modalForm")) {
                dispatch(TabRemove(props?.index));
              } else {
                dispatch(ModalDelete(props.index));
              }}
          }
        }}>
          <Modal
            opened={DrawerObj?.open}
            // onClose={close}
            withCloseButton={true}
            fullScreen={false} //
            // overlayProps={{ opacity: 0.5, blur: 4 }}
            closeButtonProps={{
              onClick: (e) => {
                if (typeof DrawerObj?.onClickCloseButton == "function") {
                  DrawerObj?.onClickCloseButton();
                } else {
                  setDrawerObj(dra);
                }
              },
            }}
            title={DrawerObj?.title}
            size={DrawerObj?.size}
            // centered={.Centered}
            closeOnClickOutside={true}
            // overlayProps={m.Overlay}
            position={DrawerObj?.position ?? "bottom"}
            onClose={
              typeof DrawerObj?.onclose == "function"
                ? DrawerObj?.onclose
                : () => {
                  setDrawerObj(dra);
                }
            }
          // scrollAreaComponent={ScrollArea.Autosize}
          >
            {DrawerObj.body}
          </Modal>
          <Paper shadow="md" radius="md" p="xs" withBorder>
            <Grid gutter={4}>
              <Grid.Col
                span={12}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "0",
                  marginTop: "10",
                  marginBottom: "0",
                }}
              >
                <Grid.Col
                  span={4}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  <Grid.Col span={3}>
                    <Text style={{ fontSize: 12 }}>Date</Text>
                  </Grid.Col>
                  <Grid.Col span={9}>
                    <PLDateBox
                      // value={group?.FIELD02}
                      autoFocus={true}
                      onKeyDown={(e) => {
                        if(e?.key=="Tab"){
                          if(OSV?._VOUNUMBER=="Y"){
                            if(vouNoRef?.current){
                              vouNoRef?.current?.handlePopoverToggle();
                            }else{
                              document.getElementById("vouNo")?.focus()
                            }}else if(OSV?._DOCNODATE=="Y"){
                              document?.getElementById("docNo")?.focus();
                            }else{
                              locFromRef?.current?.handlePopoverToggle();
                            }
                        }else{
                        GlobalClass.formNextInput(e, formRef);}
                      }}
                      style={{ margin: "0", padding: "0" }}
                      dispformat="DD/MM/YYYY"
                      setEdit={(e) => {
                        // console.log("Stocke PLDateBox", e);
                        let { startDate } = DateToString(e);
                        // console.log(startDate, "startDateinpl");
                        if (startDate?.length == 8) {
                          setF02(true);
                          Promise.resolve(
                            setGroup({ ...group, FIELD02: startDate })                   
                          ).then(()=>{
                            setVDate(startDate)
                          }).then(() => {
                            if(OSV?._VOUNUMBER=="Y"){
                                  if(vouNoRef?.current){
                                    vouNoRef?.current?.handlePopoverToggle();
                                  }else{
                                    document.getElementById("vouNo")?.focus()
                                  }}else if(OSV?._DOCNODATE=="Y"){
                                    document?.getElementById("docNo")?.focus();
                                  }else{
                                    locFromRef?.current?.handlePopoverToggle();
                                  }
                            // OSV?._DOCNODATE=="Y" ? document?.getElementById("docNo").focus() : locFromRef?.current?.handlePopoverToggle()  ;
                          });
                        }
                      }}
                      defaultval={f02 || p0=="E"? group?.FIELD02 : vDate?.length!==0 ? vDate: dateFormater(footerDate)}
                    />
                  </Grid.Col>
                  {/* <Grid.Col span={1}>
            {dayVal}
            {date.toString().slice(0, 3)}
          </Grid.Col> */}
                </Grid.Col>
                <Grid.Col
                  span={4}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Grid.Col span={3}>
                    <Text style={{ fontSize: 12 }}>Doc. No.</Text>
                  </Grid.Col>
                  <Grid.Col span={9}>
                    <PLTextBox
                       onKeyDown={(e) => {
                       if( OSV?._DOCNODATE !== "N" && e?.key=="Tab"){
                          document?.getElementById("docDate")?.focus();
                       };
                      }}
                      ref={docNoRef}
                      id="docNo"
                      disabled={OSV?._DOCNODATE == "N"}
                      value={group?.FIELD10}
                      setEdit={(e) => {
                        setGroup({ ...group, FIELD10: e?.target?.value });
                      }}
                    />
                  </Grid.Col>
                  {/* <Grid.Col span={1}>
            {dayVal}
            {date.toString().slice(0, 3)}
          </Grid.Col> */}
                </Grid.Col>
                <Grid.Col
                  span={4}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Grid.Col span={3}>
                    <Text style={{ fontSize: 12 }}>Location From</Text>
                  </Grid.Col>
                  <Grid.Col span={9}>
                    <PLDataGrid
                      ref={locFromRef}
                      width={"500px"}
                      value={{ value: group?.FIELD04, label: group?.FIELD04NM }}
                      TmData={P_M32LF}
                      // onKeyDown={(e) => {
                      //   GlobalClass.formNextInput(e, formRef);
                      // }}
                      // data={P_M32LFDT?.jData}
                      // setFocus={(e)=>{
                      //   locFromRef?.current?.handlePopoverToggle()
                      // }}
                      setEdit={(e) => {
                        Promise.resolve(
                          setGroup({
                            ...group,
                            FIELD04: e?.FIELD01,
                            FIELD04NM: e?.FIELD02,
                          })
                        ).then(()=>{
                          setLocFromId(e?.FIELD01);
                        }).then(() => {
                          // console.log(e, "locFromId");
                           locToref?.current?.handlePopoverToggle();
                          
                        });
                      }}
                      handleClose={(e) => {
                        // console.log("handleClose", e);
                        // M48Dhandlechange(e);
                      }}
                    />
                  </Grid.Col>
                </Grid.Col>
              </Grid.Col>
              <Grid.Col
                span={12}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "0",
                  marginTop: "0",
                  marginBottom: "0",
                }}
              >
                <Grid.Col
                  span={4}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  <Grid.Col span={3}>
                    <Text style={{ fontSize: 12 }}>Voucher No</Text>
                  </Grid.Col>
                  <Grid.Col span={9}>
                  {
                                    obj?.p0 == "E" ?
                                      <Grid.Col span={12}>
                                        <PLTextBox
                                        ref={vouNoRef}
                                        id="vouNo"
                                        disabled={OSV?._VOUNUMBER=="N"}
                                          onKeyDown={(e) => {
                                            if(e?.key=="Tab" && OSV?._DOCNODATE=="Y"){
                                              document?.getElementById("docNo")?.handlePopoverToggle();
                                            }else if(e?.key=="Tab"){
                                              locFromRef?.current?.handlePopoverToggle();
                                            }else{
                                            GlobalClass.formNextInput(e, formRef);}
                                          }}
                                          setText={(e) => {
                                            if (e?.target?.value?.length > 0) {
                                              if (accList[obj?.cSTID] && accList[obj?.cSTID].filter((a) => a?.FIELD12 == e?.target?.value)?.length > 0) {
                                                console.log("Duplicate Value log call");
                                                GlobalClass.Notify("warning", "Warning", "Voucher Number Already Present")
                                                setBillNoStatus(true)
                                              } else {
                                                setBillNoStatus(false)
                                              }
                                            } else {
                                              setBillNoStatus(false)
                                            }
                                          }}
                                          value={group?.FIELD12}
                                          setEdit={(e) => {
                                            console.log("setEdit=>", e);
                                            setGroup({ ...group, T92F00: "", FIELD12: e.target.value })
                                          }}
                                        />
                                      </Grid.Col> :
                                      <Grid.Col span={12}>
                                        {
                                          T92 ? <>
                                            {
                                              BillSeriesHideShow?.DataGridInput == true && <>
                                                {
                                                  !group?.T92F00 ?
                                                    <PLDataGrid
                                                    ref={vouNoRef}
                                                    id="vouNo"
                                                     disabled={OSV?._VOUNUMBER=="N"}
                                                      onKeyDown={(e) => {
                                                        if(e?.key=="Tab" && OSV?._DOCNODATE=="Y"){
                                                          document?.getElementById("docNo")?.handlePopoverToggle();
                                                        }else if(e?.key=="Tab"){
                                                          locFromRef?.current?.handlePopoverToggle();
                                                        }else{
                                                        GlobalClass.formNextInput(e, formRef);}
                                                      }}
                                                      width={"400px"}
                                                      position={"unset"}
                                                      valexpr={"FIELD00"}
                                                      // width={350} 
                                                      value={BillSeriesHideShow?.BillNoVal}
                                                      TmData={T92}
                                                      // data={T92DT}
                                                      setEdit={(e) => {
                                                        console.log("Bill No.=>", e)
                                                        // Promise.resolve(setGroup({ ...group, T92F00: e.FIELD01 })).then(()=>{
                                                        //   setBillSeriesHideShow({...BillSeriesHideShow,DataGridInput:false,TextInput:true,BillNoVal:e.FIELD01})
                                                        // })
                                                        //"FIELD00"
                                                        Promise.resolve(setGroup({ ...group, T92F00: e.FIELD00, FIELD12: "" }))
                                                        .then(() => {
                                                          if (e) {
                                                            var letr = e.FIELD02
                                                            console.log("num=>", num);
                                                            var num = parseInt(e.FIELD03) + 1
                                                            setBillSeriesHideShow({ ...BillSeriesHideShow, DataGridInput: false, TextInput: true, BillNoVal: letr + num })
                                                          }
                                                        }).then(()=>{
                                                          OSV?._DOCNODATE=="Y" ? document?.getElementById("docNo")?.focus() :locToref?.current?.handlePopoverToggle();
                                                        })

                                                      }}
                                                    /> : <PLTextBox
                                                    ref={vouNoRef}
                                                    id="vouNo"
                                                    disabled={OSV?._VOUNUMBER=="N"}
                                                    setText={(e) => {
                                                      // console.log("bill number setText 1", [{
                                                      //   "e": e,
                                                      //   "value": e?.target?.value
                                                      // }]);
                                                      if (e?.target?.value?.length > 0) {
                                                        if (accList[obj?.cSTID] && accList[obj?.cSTID].filter((a) => a?.T41BNO == e?.target?.value)?.length > 0) {
                                                          console.log("Duplicate Value log call");
                                                          GlobalClass.Notify("warning", "Warning", "Voucher Number Already Present")
                                                          setBillNoStatus(true)
                                                        } else {
                                                          setBillNoStatus(false)
                                                        }
                                                      } else {
                                                        setBillNoStatus(false)
                                                      }
                                                    }}
                                                    onKeyDown={(e) => {
                                                      if(e?.key=="Tab" && OSV?._DOCNODATE=="Y"){
                                                        document?.getElementById("docNo")?.handlePopoverToggle();
                                                      }else if(e?.key=="Tab"){
                                                        locFromRef?.current?.handlePopoverToggle();
                                                      }else{
                                                      GlobalClass.formNextInput(e, formRef);}
                                                    }}
                                                      value={group?.FIELD12}
                                                      // value={group?.T92F00}
                                                      setEdit={(e) => {
                                                        console.log("setEdit=>", e);
                                                        // setBillSeriesHideShow({ ...BillSeriesHideShow, BillNoVal: e.target.value })
                                                        setGroup({ ...group, T92F00: "", FIELD12: e.target.value })
                                                      }}
                                                    />
                                                }
                                              </>
                                            }
                                            {BillSeriesHideShow?.TextInput == true && <PLTextBox
                                            ref={vouNoRef}
                                            id="vouNo"
                                            disabled={OSV?._VOUNUMBER=="N"}
                                                setText={(e) => {
                                                  // console.log("bill number setText 2", [{
                                                  //   "e": e,
                                                  //   "value": e?.target?.value
                                                  // }]);
                                                  if (e?.target?.value?.length > 0) {
                                                    if (accList[obj?.cSTID] && accList[obj?.cSTID].filter((a) => a?.T41BNO == e?.target?.value)?.length > 0) {
                                                      console.log("Duplicate Value log call");
                                                      GlobalClass.Notify("warning", "Warning", "Voucher Number Already Present")
                                                      setBillNoStatus(true)
                                                    } else {
                                                      setBillNoStatus(false)
                                                    }
                                                  } else {
                                                    setBillNoStatus(false)
                                                  }
                                                  if (BillSeriesHideShow.BillNoVal.length <= 0) {
                                                    setBillSeriesHideShow({ ...BillSeriesHideShow, DataGridInput: true, TextInput: false })
                                                  }
                                                }}
                                                onKeyDown={(e) => {
                                                  if(e?.key=="Tab" && OSV?._DOCNODATE=="Y"){
                                                    document?.getElementById("docNo")?.focus();
                                                  }else if(e?.key=="Tab"){
                                                    locFromRef?.current?.handlePopoverToggle();
                                                  }else{
                                                  GlobalClass.formNextInput(e, formRef);}
                                                }}
                                              value={BillSeriesHideShow?.BillNoVal}
                                              // value={group?.T92F00}
                                              setEdit={(e) => {
                                                console.log("setEdit=>", e);
                                                setBillSeriesHideShow({ ...BillSeriesHideShow, BillNoVal: e.target.value })
                                                setGroup({ ...group, T92F00: "", FIELD12: e.target.value })
                                              }}
                                            />
                                            }
                                          </> :
                                            < PLTextBox
                                            ref={vouNoRef}
                                            id="vouNo"
                                            disabled={OSV?._VOUNUMBER=="N"}
                                            setText={(e) => {
                                              // console.log("bill number setText 3", [{
                                              //   "e": e,
                                              //   "value": e?.target?.value
                                              // }]);
                                              if (e?.target?.value?.length > 0) {
                                                if (accList[obj?.cSTID] && accList[obj?.cSTID].filter((a) => a?.T41BNO == e?.target?.value)?.length > 0) {
                                                  console.log("Duplicate Value log call");
                                                  GlobalClass.Notify("warning", "Warning", "Voucher Number Already Present")
                                                  setBillNoStatus(true)
                                                } else {
                                                  setBillNoStatus(false)
                                                }
                                              } else {
                                                setBillNoStatus(false)
                                              }
                                            }}
                                            value={BillSeriesHideShow?.BillNoVal}
                                            setEdit={(e) => {
                                              console.log("setEdit=>", e);
                                              setBillSeriesHideShow({ ...BillSeriesHideShow, BillNoVal: e.target.value })
                                              // setGroup({ ...group, T92F00: "", FIELD12: e?.target?.value })
                                              // if(EntryPages?.PurchaseEntry?.includes(obj?.page) || EntryPages?.PurchaseEntry?.includes(obj?.id)){
                                              //   setGroup({ ...group, T92F00: "", FIELD12: e.target.value, FIELD10: e.target.value })
                                              // }else{
                                              setGroup({ ...group, T92F00: "", FIELD12: e.target.value })
                                              // }
                                            }}
                                            onKeyDown={(e) => {
                                              if(e?.key=="Tab" && OSV?._DOCNODATE=="Y"){
                                                document?.getElementById("docNo")?.handlePopoverToggle();
                                              }else if(e?.key=="Tab"){
                                                locFromRef?.current?.handlePopoverToggle();
                                              }else{
                                              GlobalClass.formNextInput(e, formRef);}
                                            }}
                                            />
                                        }
                                      </Grid.Col>
                                  }
                  </Grid.Col>
                </Grid.Col>
                <Grid.Col
                  span={4}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Grid.Col span={3}>
                    <Text style={{ fontSize: 12 }}>Doc Date</Text>
                  </Grid.Col>
                  <Grid.Col span={9}>
                    <PLDateBox
                    id="docDate"
                    ref={docDateRef}
                       onKeyDown={(e) => {
                        if(e?.key=="Tab"){
                        locFromRef?.current?.handlePopoverToggle();}
                      }}
                      style={{ margin: "0", padding: "0" }}
                      dispformat="DD/MM/YYYY"
                      disabled={OSV?._DOCNODATE == "N"}
                      // setEdit={(e) => {
                      //   setGroup({ ...group, FIELD11: e });
                      // }}
                      setEdit={(e) => {
                        // console.log("e PLDateBox", e);
                        let { startDate } = DateToString(e);
                        // console.log(startDate, "startDateinpl");
                        Promise.resolve(
                        startDate?.length == 8 && 
                          setGroup({ ...group, FIELD11: startDate })).then(()=>{
                            locFromRef?.current?.handlePopoverToggle();
                          })
                      }}
                      // value={group?.FIELD11}

                      defaultval={group?.FIELD11?.trim()}
                    />
                  </Grid.Col>
                </Grid.Col>
                <Grid.Col
                  span={4}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Grid.Col span={3}>
                    <Text style={{ fontSize: 12 }}>To</Text>
                  </Grid.Col>
                  <Grid.Col span={9}>
                    <PLDataGrid
                      ref={locToref}
                      width={"500px"}
                      // value={group?.FIELD05}

                      cWhere={`M32.FIELD01 NOT LIKE '%${locFromId}%'`}
                      value={{ value: group?.FIELD05, label: group?.FIELD05NM }}
                      TmData={P_M32LT}
                      // data={P_M32LTDT?.jData}
                      // data={toArr ?? []}
                      setEdit={(e) => {
                        // console.log("handleClose", e);

                        setGroup({
                          ...group,
                          FIELD05: e?.FIELD01,
                          FIELD05NM: e?.FIELD02,
                        });
                      }}
                    />
                  </Grid.Col>
                </Grid.Col>
              </Grid.Col>
            </Grid>
            {UFT41H?.length > 0 && UFT41Hval?.length > 0 &&
              <>
                <Divider
                  my="xs"
                  label={<Button
                    variant="subtle"
                    color="dark"
                    size="xs"
                    compact
                    onFocus={() => {
                      if (UFT41H?.filter((af) => af.S13HMF == "H")?.length > 3) {
                        spoilerControlRef.current.click()
                      }
                    }}
                  >User Fields</Button>}
                  labelPosition="center"
                  labelProps={{ component: "strong" }}
                />

                <Spoiler
                  // initialState={userFieldSpoiler}
                  // initialState={true}

                  maxHeight={40}
                  showLabel
                  hideLabel
                  controlRef={spoilerControlRef}
                >
                  <Grid gutter={2} onKeyDown={(e) => {
                    GlobalClass.formNextInput(e, formRef);
                  }}>{UFT41HFieldRenderer("Header")}</Grid>
                </Spoiler>
              </>
            }
            <Divider
              my="xs"
              label={<Button
                variant="subtle"
                color="dark"
                size="xs"
                compact
                onFocus={() => {
                  if (UFT41H?.filter((af) => af.S13HMF == "H")?.length > 3) {
                    spoilerControlRef.current.click()
                  }
                  TableContainer?.current?.focus()

                  // TableRef?.current?.focus()

                  setRowSelection({ 0: true })
                }}
              >Products</Button>}
              labelPosition="center"
              labelProps={{ component: "strong" }}
            />
            <Grid gutter={4}
            // onKeyUp={(e) => {
            //   GlobalClass.formNextInput(e, formRef);
            // }}
            >
              <Grid.Col span={12} my={7}>
                {/* <MantineReactTable table={table} /> */}
                {t02Flag ?
                  <T02Table
                    accList={accList}
                    tmList={tmList}
                    dataEnt={dataEnt}
                    obj={obj}
                    setT02Data={(e) => setT02Data(e)}
                    t02Data={t02Data}
                    setIsVisible={setIsVisible}
                    setCreatingModeOn={setCreatingModeOn}
                    t41={group}
                    t02Obj={t02Obj}
                    setT02Obj={(e) => setT02Obj(e)}
                    group={group}
                    setGroup={(e) => setGroup(e)}

                    setEditingRow={setEditingRow}
                    editingRow={editingRow}

                    // setTableData={setTableData}
                    // setTableExpenseVal={setTableExpenseVal}
                    ExpenseObj={ExpenseObj}

                    setSelectedProductObj={setSelectedProductObj}
                    selectedProductObj={selectedProductObj}
                    // ExpenseData={
                    //   ExpenseData instanceof Array || ExpenseData == undefined
                    //     ? []
                    //     : ExpenseData
                    // }
                    // InvTypeDropDown={InvTypeDropDown}
                    flagCopy={flagCopy}
                    setFlagCopy={(e) => {
                      setFlagCopy(e)
                    }}

                    footerButtonArray={footerButtonArray}
                    TableRef={TableRef}
                    TableContainer={TableContainer}
                    rowSelection={rowSelection}
                    setRowSelection={setRowSelection}
                    setHeaderExpenseFlag={setHeaderExpenseFlag}
                    HeaderExpenseFlag={HeaderExpenseFlag}
                    isHandleCreateNeeded={isHandleCreateNeeded}
                    setIsHandleCreateNeeded={setIsHandleCreateNeeded}
                    pagecCode={pagecCode}
                  /> : ""
                }
              </Grid.Col>
            </Grid>

            {/* <Group spacing={"xs"} position="center">
              <Button
                variant="light"
                compact
                onClick={() => {
                  console.log(copyRow?.original, "tableData-", copyRow);
                  if (
                    !creatingModeOn
                  ) {
                    setFlagCopy(true)
                  }
                }}
              >
                Copy
              </Button>
              <Button
                variant="light"
                compact
              //  onKeyDown={(e) => {
              //             GlobalClass.formNextInput(e, formRef);
              //           }}
              >
                Serial No.
              </Button>
            </Group> */}

            {UFT41Fval?.length > 0 ? (
              <>
                <Divider my={2} />
                <Grid
                  gutter={4}
                  onKeyDown={(e) => {
                    GlobalClass.formNextInput(e, formRef);
                  }}
                >
                  {/* <Grid.Col span={6}> */}
                  {UFT41HFieldRenderer("Footer")}
                  {/* </Grid.Col> */}
                </Grid>
              </>
            ) : (
              ""
            )}
            <Grid
              span={12}
              my={3}
              style={{
                display: "flex",
                alignItems: "stretch",
                flexWrap: "nowrap",
              }}
            >
              <Grid.Col
                span={9}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <Grid.Col span={6}>
                  {/* <Grid.Col span={3}> */}
                  <Text size={12}>Narration</Text>
                  {/* </Grid.Col> */}

                  {/* <Grid.Col span={7}> */}

                  <PLTextarea
                    minRows={2}
                    value={group?.FIELD15}
                    onChange={(e) => {
                      // //console.log(e)
                      setGroup({ ...group, FIELD15: e.target.value });
                    }}
                    onFocus={(e) => {
                      // console.log(e, "OnFocusNarr")
                      if (OSV?._ANARRHELP == "Y" && flag) {
                        NarrationBox();
                      } else {
                        return;
                      }
                    }}
                    onKeyDown={(e) => {
                      // console.log(e, "OnFocusNarr")
                      // GlobalClass.formNextInput(e, formRef);
                      if (e.ctrlKey && e.key === "F1") {
                        NarrationBox();
                      }
                    }}
                  />
                </Grid.Col>
                {/* </Grid.Col> */}
              </Grid.Col>
              <Grid.Col
                span={3}
                // offset={2}
                style={{ display: "flex", flexDirection: "column" }}
              >
                {/* <Box style={{ overflow: "hidden" }}> */}
                <Grid.Col span={6}>
                  {(
                    <Text style={{ fontSize: 12 }}>
                      Bill Amount:
                      { rowSum} {OS11 ? (OS11["S11F21"] == "D" ? "CR" : "DB") : ""}
                    </Text>
                  )}
                </Grid.Col>

                <Group wrap="nowrap">
                  <Button
                    style={{ height: "30px", width: "100px" }}
                    size="sm"
                    onClick={() => BillNoStatus ? GlobalClass.Notify("warning", "Warning", "Voucher Number Already Present") : handleOkButton()}
                    disabled={
                      creatingModeOn
                      // || t02Data?.length < 1
                    }
                  >
                    Ok
                  </Button>
                  <Button
                    style={{ height: "30px", width: "100px" }}
                    size="sm"
                    onClick={OnPrint}>
                    Print
                  </Button>
                </Group>
                {/* </Box> */}
              </Grid.Col>
            </Grid>
          </Paper>
          {lEditVou == false && <> {GlobalClass.Alert("info", props.index)}</>}
        </div>
      )}
    </>
  );
}












