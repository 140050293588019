import { Badge, Box, Button, FileInput, Grid, Group, Text } from '@mantine/core'
import React, { useEffect, useState } from 'react'
import PLComboBox from '../../PlusComponent/PLComboBox'
import PLTextBox from '../../PlusComponent/PLTextBox'
import { IconFileZip, IconUpload } from '@tabler/icons-react'
import gensetting from '../../utils/gensetting'
import GlobalClass from '../../utils/GlobalClass'
import Store from '../../utils/store'
import { GetUserList } from '../../utils/slices/UserListSlice'

const RestoreData = (props) => {
    const { DrawerObj, setDrawerObj } = props
    const [cmpVal, setCmpVal] = useState([])
    console.log("cmpVal", cmpVal)
    const [objSave, setObjSave] = useState({})
    console.log("objSave", objSave)
    const [backupVal, setBackupVal] = useState("U")
    const [file, setFile] = useState(null);
    // console.log("file", file)
    const [value, setValue] = useState({
        email: [],
        mobile: [],
        supportEmail: ""
    })
    const [isRestoreLoading, setIsRestoreLoading] = useState(false)
    const [confirmedCmpNos, setConfirmedCmpNos] = useState(new Set());
    // console.log("confirmedCmpNos",typeof confirmedCmpNos)
    let backupData = [
        {
            DisplayMember: "Upload File",
            ValueMember: "U"
        },
        {
            DisplayMember: "E-Mail",
            ValueMember: "E"
        },
        {
            DisplayMember: "WhatsApp",
            ValueMember: "W"
        },
        {
            DisplayMember: "Send for Support",
            ValueMember: "S"
        }
    ]
    useEffect(() => {
        let CmpListarr = localStorage.getItem("authUser")
        if (CmpListarr) {
            CmpListarr = JSON.parse(CmpListarr)
            let obj = {
                ...CmpListarr, cmpList: CmpListarr?.cmpList.map(({ cmpNo, cmpName, cmpGrp }) => ({
                    cmpNo,
                    cmpName: `${cmpNo} - ${cmpName}`,
                    cmpGrp,
                }))
            }
            setObjSave(obj);
        }
    }, [])

    // useEffect(() => {
    //     if (cmpVal && objSave?.cmpList) {
    //         objSave?.cmpList?.forEach((v) => {
    //             cmpVal.forEach((c) => {
    //                 if (v.cmpNo == c) {
    //                     console.log("useEffcet if condition")
    //                     if (window.confirm("This company is already available, Overwrite it?")) {
    //                         return
    //                     } else {
    //                         const newCmpVal = [...cmpVal];
    //                         newCmpVal.pop();

    //                         setCmpVal(newCmpVal);
    //                     }
    //                 }
    //             })
    //         })
    //     }
    // }, [cmpVal, objSave?.cmpList])

    useEffect(() => {
        if (cmpVal && objSave?.cmpList) {
            objSave?.cmpList.forEach((v) => {
                cmpVal.forEach((c) => {
                    if (v.cmpNo == c && !confirmedCmpNos.has(v.cmpNo)) {
    
                        // if (window.confirm("This company is already available, Overwrite it?")) {
                        //     setConfirmedCmpNos((prev) => new Set(prev).add(v.cmpNo));
                        //     return;
                        // } else {
                        //     const newCmpVal = [...cmpVal];
                        //     newCmpVal.pop();
                        //     setCmpVal(newCmpVal);
                        // }

                        GlobalClass.Confirm(
                            "red",
                            "This company is already available, Overwrite it?",
                            "A company with this name already exists. Do you want to overwrite the existing data?",
                            () => {
                                setConfirmedCmpNos((prev) => new Set(prev).add(v.cmpNo));
                            },
                            () => {
                                const newCmpVal = [...cmpVal];
                                newCmpVal.pop();
                                setCmpVal(newCmpVal);
                             },
                            "Cancel",
                            "Confirm"
                        )
                    }
                });
            });
        }
    }, [cmpVal, objSave?.cmpList, confirmedCmpNos]);

    const handleRestore = async () => {
        if (!file) {
            GlobalClass.Notify("warning", "Warning", "Please upload a file")
        } else if (cmpVal.length == 0) {
            GlobalClass.Notify("warning", "Warning", "Please Enter Company Number")
        } else if (cmpVal.length !== file.length) {
            GlobalClass.Notify("warning", "Warning", "Company number incomplete, To add more than one number, please separate them with a comma.")
        } else {
            // if (backupVal === "U" && file && cmpVal) {
            //     // for (let i = 0; i < cmpVal.length; i++) {
            //         setIsRestoreLoading(true)
            //         const formData = new FormData();
            //         formData.append("file", file);
            //         const param = JSON.stringify({
            //             CorpID: gensetting.getcorpid(),
            //             cUser: gensetting.getcuser(),
            //             cPass: gensetting.getcpass(),
            //             CmpNo: cmpVal,
            //         });
            //         formData.append("pa", param)
            //         const requestOptions = {
            //             method: "POST",
            //             // headers: { "Content-Type": "multipart/form-data" },
            //             body: formData,
            //         };

            //         try {
            //             const response = await fetch(
            //                 `${GlobalClass.ApiUrl + GlobalClass.RestoreBackup}?pa=${param}`,
            //                 requestOptions
            //             );

            //             const result = await response.json();

            //             if (result.status === "SUCCESS") {
            //                 setIsRestoreLoading(false)
            //                 GlobalClass.Notify("success", "Success", "Successfull")
            //             } else {
            //                 setIsRestoreLoading(false)
            //                 GlobalClass.Notify("error", result?.status, result?.message);
            //             }
            //         } catch (error) {
            //             setIsRestoreLoading(false)
            //             GlobalClass.Notify("error", "Error", error.message);
            //         }
            //     // }
            // }

            if (backupVal === "U" && file && cmpVal) {
                setIsRestoreLoading(true);

                for (let i = 0; i < file.length; i++) {
                    const currentCmpNo = cmpVal[i]
                    const currentFile = file[i];
                    const formData = new FormData();
                    formData.append("file", currentFile);

                    const param = JSON.stringify({
                        CorpID: gensetting.getcorpid(),
                        cUser: gensetting.getcuser(),
                        cPass: gensetting.getcpass(),
                        CmpNo: currentCmpNo,
                    });
                    formData.append("pa", param);

                    const requestOptions = {
                        method: "POST",
                        body: formData,
                    };

                    try {
                        const response = await fetch(
                            `${GlobalClass.ApiUrl + GlobalClass.RestoreBackup}?pa=${param}`,
                            requestOptions
                        );

                        const result = await response.json();

                        if (result.status === "SUCCESS") {
                            // if (i === file.length - 1) {
                            setIsRestoreLoading(false);
                            GlobalClass.Notify("success", "Success", "files restored successfully");
                            // }
                        } else {
                            setIsRestoreLoading(false);
                            GlobalClass.Notify("error", result?.status, result?.message);
                        }
                    } catch (error) {
                        setIsRestoreLoading(false);
                        GlobalClass.Notify("error", "Error", error.message);
                    }
                }
                setDrawerObj({ ...DrawerObj, open: false })
                Store.dispatch(GetUserList({ Action: "A" }))
            }
        }
    };

    return (
        <>
            <Grid gutter={4}>
                <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Grid.Col span={3}>
                        <Text size={12}>Backup</Text>
                    </Grid.Col>
                    <Grid.Col span={3}>
                        <PLComboBox
                            dispexpr="DisplayMember"
                            valexpr="ValueMember"
                            data={backupData}
                            value={backupVal}
                            setEdit={(e) => {
                                // console.log("combo value", e)
                                setBackupVal(e)
                            }}
                        />
                    </Grid.Col>
                    <Grid.Col span={4}>
                        {
                            backupVal == "U" ?
                                <FileInput
                                    // label="Select ZIP File"
                                    placeholder="Choose a ZIP file"
                                    value={file}
                                    onChange={setFile}
                                    accept=".zip"
                                    icon={<IconUpload size={18} />}
                                    multiple
                                /> :
                                <PLTextBox
                                    disabled={backupVal == "S" ? true : false}
                                    value={backupVal == "E" ? value.email : backupVal == "W" ? value.mobile : value.supportEmail}
                                    setEdit={(e) => {
                                        backupVal == "E" ?
                                            setValue({ ...value, email: e.target.value }) :
                                            setValue({ ...value, mobile: e.target.value })
                                    }}
                                />
                        }
                    </Grid.Col>
                    <Grid.Col span={2}>
                        {
                            (backupVal == "E" || backupVal == "W") &&
                            <Button
                                compact
                            // onClick={bookDataCalled}
                            >
                                <Text size={12}> {backupVal == "E" ? "eMail Book" : "Phone Book"}</Text>
                            </Button>
                        }
                    </Grid.Col>
                </Grid.Col>
                <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Grid.Col span={3}>
                        <Text size={12}>New Company Number</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                        <PLTextBox
                            value={cmpVal}
                            setEdit={(e) => {
                                // console.log("setEdit", e)
                                let val = ""
                                val = e.target.value
                                console.log("val", val)
                                if (val) {
                                    setCmpVal(val.split(","))
                                } else {
                                    setCmpVal([])
                                    setConfirmedCmpNos(new Set())
                                }
                            }}
                        />
                    </Grid.Col>
                </Grid.Col>
            </Grid>
            <Grid gutter={4}>
                <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Button
                        onClick={handleRestore}
                        loading={isRestoreLoading}
                    >Restore</Button>
                </Grid.Col>
            </Grid>
        </>
    )
}

export default RestoreData