import React, { useEffect, useState } from 'react'
import CMPSetup from './CMPSetup';
import { TDSMaster } from '../FunctionsCall/TDSMaster/TDSMaster';
import { CashBank } from '../Transaction/QuickEntry/CashBank';


const Component = {
  CMPSetup: CMPSetup,
  Datagrid: TDSMaster,
  // CashBank : CashBank
};

export default function MyComponent(props) {
  // console.log('MyComponent props =>', props);
  const [SplitActionName, setSplitActionName] = useState(null)

  var action = props?.obj?.action;
  useEffect(() => {
    if (action) {
      let splitAction = action.split("cId=")
      // console.log("splitAction==>", splitAction);
      setSplitActionName(splitAction[1])
    }
  }, [action])

  var TagName = Component[props.obj.action];
  console.log("objj", props.obj);
  if (action.includes("S72F#S72FList?cId=CCCC")) {
    TagName = Component.CMPSetup
  } else if (action.includes("S72F#S72FList?cId=TTTT")) {
    TagName = Component.CMPSetup
  } else {
    TagName = Component.Datagrid
  }
  return (
    <TagName
      index={props.index}
      obj={props.obj}
      cID={SplitActionName}
    />
  )
}
