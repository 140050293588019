import { Text } from "@mantine/core";
import Store from "../../../utils/store";
import { ModalDelete, ModalFunction } from "../../../utils/slices/ModalSlice";
import UserMasterEntryForm from "./UserMasterEntryForm";
import { DeleteUserMasterEntry } from "../../../utils/slices/UserMasterEntrySlice";
import GlobalClass from "../../../utils/GlobalClass";

export function MSAED(data, Skey) {
  const OBJ = JSON.parse(data);

  if (OBJ.p0 === "A") {
    Store.dispatch(
      ModalFunction({
        onclose: (e) => {},
        MTitle: <Text fw={700}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "md",
        position: "right",
        MBody: (i) => <UserMasterEntryForm OBJ={OBJ} index={i} />,
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        },
      })
    );

    //===========================================
    // modals.open({
    //   title: <Text fw={700}>{OBJ?.text}</Text>,
    //   size: "50%",
    //   children: <AccountGroupForm OBJ={OBJ} />,
    // });
  } else if (OBJ.p0 === "E" && !OBJ.id == "") {
    Store.dispatch(
      ModalFunction({
        onclose: () => {},
        MTitle: <Text fw={700}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "md",
        position: "right",
        MBody: (i) => <UserMasterEntryForm OBJ={OBJ} index={i} />,
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        },
      })
    );
  } else if (OBJ.p0 === "D" && !OBJ.id == "") {
    // if (window.confirm("Are you sure to delete selected data?")) {
    //     Store.dispatch(DeleteUserMasterEntry(OBJ));
    // } else {
    //   return null;
    // }

    GlobalClass.Confirm(
      "red",
      "Are you sure to delete selected data?",
      "It will be Deleted Forever and Cannot Be Recovered!!!",
      () => {
        Store.dispatch(DeleteUserMasterEntry(OBJ));
      },
      () => { },
      "Cancel",
      "Confirm"
  )
  }
}
