import { useEffect, useRef, useState } from "react";
import {
  createStyles,
  rem,
  Group as Grp,
  Button,
  Grid,
  Box,
  Text,
  Card,
  Paper,
  MantineProvider,
  useMantineTheme,
  Kbd,
  MediaQuery,
  Tooltip,
} from "@mantine/core";
import { useDispatch, useSelector } from "react-redux";
import { GetAccountList, GetTMFormat, SaveAccountListData, getCurrentDate, getDateRange, getRangeBtnState } from "../utils/slices/AccountListSlice";
import { createColumnHelper } from "@tanstack/react-table";
import useOnScreen from "../utils/useOnScreen";
import PLComboBox from "../PlusComponent/PLComboBox";
import ButtoPanel from "../PlusComponent/ButtonPanel";
import { jsPDF } from "jspdf"; //or use your library of choice here
import autoTable from "jspdf-autotable";
import * as XLSX from 'xlsx';
// import {
//   IconAlertTriangle,
//   IconArrowBadgeDownFilled,
//   IconArrowBadgeRight,
//   IconArrowBadgeRightFilled,
//   IconChevronLeft,
//   IconChevronRight,
//   IconX,
// } from "@tabler/icons-react";
// import { IconArrowBadgeDown } from "@tabler/icons-react";
// import ButtoPanel from "../PlusComponent/ButtonPanel";
// import { modals } from "@mantine/modals";
// import LocalizedStrings from "react-localization";

import { MRT_TablePagination, MantineReactTable, useMantineReactTable } from "mantine-react-table";
import React from "react";
import { useHotkeys, useLocalStorage, useMediaQuery } from "@mantine/hooks";
import PLTextBox from "../PlusComponent/PLTextBox";
import GlobalClass from "../utils/GlobalClass";
import PLNumberBox from "../PlusComponent/PLNumberBox";
import { ProcessMasterData } from "../utils/slices/ProcessMasterSlice";
import { notifications } from "@mantine/notifications";
import {
  GetButtonPannel,
  GetButtonPannelList,
  GetPaginationObj,
  GetReportIdArray,
} from "../utils/slices/DataSaveLoadingSlice";
import PLDateBox from "../PlusComponent/PLDateBox";
import { IconFileDownload, IconRefresh, IconRefreshDot, IconSearch } from "@tabler/icons-react";
import { DateInput } from "@mantine/dates";
import Store from "../utils/store";
import { ModalFunction } from "../utils/slices/ModalSlice";
import ReportViewer from "./ReportViewer";
import useWindowDimensions from "../utils/UseWindowDimensions";
import PLFormat from "../FunctionsCall/AccountLedger/PLFormat";
import { GetAccountListForPrint } from "../utils/PrintSlice/PrintSlice";
import { getFilterStatus } from "../utils/FilterSlice/FilterSlice";
import ContextMenu from "./ContextMenu";
import { GridComponent, Inject, Page, Group, Sort, Aggregate, Toolbar, VirtualScroll } from '@syncfusion/ej2-react-grids';
const useStyles = createStyles((theme) => ({
  header: {
    position: "sticky",
    top: 0,
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
    transition: "box-shadow 150ms ease",

    "&::after": {
      content: '""',
      position: "absolute",
      left: 0,
      right: 0,
      bottom: 0,
      borderBottom: `${rem(1)} solid ${theme.colorScheme === "dark"
        ? theme.colors.dark[3]
        : theme.colors.gray[2]
        }`,
    },
  },

  scrolled: {
    boxShadow: theme.shadows.sm,
  },
}));

function DataGridSyncfusion(props) {
  // console.log("DataGrid Props==>", props);
  // console.log("datagriddl props", props);
  let ref= {};
  const globalTheme = useMantineTheme();
  const { height, width } = useWindowDimensions();
  const { tag, obj, form, id, cWhere, isFormatDropdown, isRefresh, isPagination } = props;
  // console.log("cWhere", obj);
  const date1 = new Date(); //Yasvi Patel
  const [focusset, setfocusset] = useState(false);
  // console.log("obj of dg",obj)
  const { classes, cx } = useStyles();
  const [scrolled, setScrolled] = useState(false);
  const [isMultiGroup,setIsMultiGroup] = useState(false);
  const [MultiGroups,setMultiGroups] = useState([]);
  const [currentGroupIndex, setCurrentGroupIndex] = useState(0);
  const disRef = useRef();
  const dispatch = useDispatch();
  const TableRef = useRef();
  const childRef = useRef();
  ref = {...ref,[obj.id]:null}
  useEffect(() => {
    const elements = document.querySelectorAll('button[aria-label="Show/Hide search"]');
    elements.forEach((el) => (el.style.display = "none"));
    
  }, []);
  const {
    hasError,
    ErrorMsg,
    isLoading,
    AccountListData: accList,
    TMFormatData: tmList,
    cFooter,
    rowCount,
    isRefetching,
  } = useSelector((state) => state.AccountList);
  // const PaginationObj = useSelector(state => state?.DataSaveLoadingSlice?.PaginationObj)

  const [acTM, setacTM] = useState({
    AccountListData: [],
    TMFormatData: [],
  });
  // console.log('TMFormatData', TMFormatData);
  const [cfmtid, setcfmtid] = useState("");
  const [columns, setColumns] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [grouping, setGrouping] = useState({showDropArea: false,showGroupedColumn: false,disablePageWiseAggregates:false,columns:[]});
  const [rowSelection, setRowSelection] = useState({}); //{ 0: true }
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 25,
  });

  const [sessionSetting, setSessionSetting] = useLocalStorage({
    key: 'session_setting',
    defaultValue: {
      Primary_Color: 'blue',
      Font_Family: 'Arial',
      Font_Size: 12,
      Font_XS: 10,
      Font_SM: 12,
      Font_MD: 14,
      Font_LG: 16,
      Font_XL: 20
    },
    getInitialValueInEffect: true,
  })

  const ReportIdArray = useSelector((state) => state.DataSaveLoadingSlice.ReportIdArray);

  const InputRef = useRef();

  const TransactionReportIdArray = useSelector((store) => store.DataSaveLoadingSlice.TransactionReportIdArray); // Vara Pooja
  const GetGeneral = useSelector((state) => state.Language.General);
  const { oYear } = useSelector((state) => state?.Language?.General);

  const SearchInputValue = useSelector((store) => store?.AccountList?.SearchInputValue);
  const currentDate = useSelector((state) => state?.AccountList?.currentDate); // Yasvi Patel ( for implementing this functionality : tab switch kria to pn date nu filter as it is revu joia)
  const flagDate = useSelector((state) => state?.AccountList?.flag); // Yasvi Patel
  const isFilterApplied = useSelector(store => store?.FilterSlice?.isFilterApplied) // Filter is applied or not 
  const sortingOrderField = useSelector((store) => store?.AccountList?.sortingOrderField); // Yasvi Patel
  const sortingArrangeField = useSelector((store) => store?.AccountList?.sortingArrangeField); // Yasvi Patel
  const SaveAccountListArr = useSelector((store) => store?.AccountList.SaveAccountListArr); //Rathod kishan
  const BtnStatus = useSelector((store) => store?.DataSaveLoadingSlice.ButtonPannelSave); //rathod kishan
  const BtnStatusList = useSelector((store) => store?.DataSaveLoadingSlice?.ButtonPannelList); //rathod kishan
  const footerDate = useSelector((state) => state?.Language?.footerDate); //Yasvi Patel
  const dateRange1 = useSelector((state) => state?.AccountList?.dateRange); //Yasvi Patel
  // const GetYearDateData = useSelector((state) => state.CompanySetup?.GetYearDateData?.YearList);
  // const YearAndDateSend = useSelector((state) => state.UserList.YearAndDateSend);
  // const [dateAndYear, setDateAndYear] = useState();
  // const [isDataGridCalled, setisDataGridCalled] = useState();
  const media = useMediaQuery('(min-width: 62em)');
  const [date, setDate] = useState({});
  const [minimunDate, setMinimumDate] = useState();
  const [maximumDate, setMaximumDate] = useState();
  const [searchColumns, setSearchColumns] = useState();
  const [StrtDt, setStrtDt] = useState();
  const [EndDt, setEndDt] = useState();
  const [search, setSearch] = useState({});
  const [query, setQuery] = useState();
  const [flag, setFlag] = useState(false);
  const [currentDate1, setcurrentDate1] = useState({}); // Yasvi Patel
  const [flagDate1, setFlagDate1] = useState({}); // Yasvi Patel
  // const [dDate,setDDate] = useState({});
  const [isDateChanged, setIsDateChanged] = useState(false);
  const [date2, setDate2] = useState({});
  const [flagOfReport, setFlagOfReport] = useState(false);
  const [flagOfReport1, setFlagOfReport1] = useState(false);
  const [dateRange, setDateRange] = useState();
  // const AccountListData = useSelector(store => store?.PrintList?.PrintData);

  useEffect(() => {
    // AccountListData: accList,
    // TMFormatData: tmList,
    // if (accList && tmList) {
    //   console.log("GenData and Tmformat =>", [{
    //     "accList": accList,
    //     "tmList": tmList
    //   }]);
    // }
  }, [accList, tmList])

  function getCurrentWeekDates() {
    const today = new Date(footerDate);
    // console.log("today",today);
    // console.log("today footerdate",footerDate)
    const dayOfWeek = today.getDay(); // Sunday = 0, Monday = 1, ..., Saturday = 6

    // Get the first date of the current week (Sunday)
    let From = new Date(today);
    From.setDate(today.getDate() - dayOfWeek);
    From.setHours(0, 0, 0, 0); // Reset time to start of the day

    // Get the last date of the current week (Saturday)
    let To = new Date(From);
    To.setDate(From.getDate() + 6); // Saturday is 6 days after Sunday
    To.setHours(23, 59, 59, 999); // Set time to end of the day

    if (!(From <= new Date(GetGeneral?.dYEDate) && From >= new Date(GetGeneral?.dYSDate)) || !(To <= new Date(GetGeneral?.dYEDate) && To >= new Date(GetGeneral?.dYSDate))) {
      if (!(From <= new Date(GetGeneral?.dYEDate) && From >= new Date(GetGeneral?.dYSDate))) {
        From = new Date(today);
        From.setDate(today.getDate());
        From.setHours(0, 0, 0, 0);
      } else {
        To = new Date(GetGeneral?.dYEDate);
        To.setDate(new Date(GetGeneral?.dYEDate).getDate());
        To.setHours(0, 0, 0, 0);
      }
    }

    return {
      From: From,
      To: To,
    };
  }

  function getLastWeekDates() {
    const today = new Date(footerDate);

    // Get the previous Monday
    let lastSunday = new Date(today);
    lastSunday.setDate(today.getDate() - today.getDay() - 7);

    // Get the previous Sunday
    let lastSaturday = new Date(lastSunday);
    lastSaturday.setDate(lastSunday.getDate() + 6);

    if (!(lastSunday <= new Date(GetGeneral?.dYEDate) && lastSunday >= new Date(GetGeneral?.dYSDate)) || !(lastSaturday <= new Date(GetGeneral?.dYEDate) && lastSaturday >= new Date(GetGeneral?.dYSDate))) {
      if (!(lastSunday <= new Date(GetGeneral?.dYEDate) && lastSunday >= new Date(GetGeneral?.dYSDate)) && !(lastSaturday <= new Date(GetGeneral?.dYEDate) && lastSaturday >= new Date(GetGeneral?.dYSDate))) {
        lastSunday = new Date(GetGeneral?.dYSDate);
        lastSunday.setDate(new Date(GetGeneral?.dYSDate).getDate());
        lastSunday.setHours(0, 0, 0, 0);
        lastSaturday = new Date(GetGeneral?.dYSDate);
        lastSaturday.setDate(new Date(GetGeneral?.dYSDate).getDate());
        lastSaturday.setHours(0, 0, 0, 0);
      } else if (!(lastSunday <= new Date(GetGeneral?.dYEDate) && lastSunday >= new Date(GetGeneral?.dYSDate))) {
        lastSunday = new Date(GetGeneral?.dYSDate);
        lastSunday.setDate(new Date(GetGeneral?.dYSDate).getDate());
        lastSunday.setHours(0, 0, 0, 0);
      }
    }


    return {
      From: lastSunday,
      To: lastSaturday
    };
  }


  function getLastMonthDates() {
    // Get today's date
    const today = new Date(footerDate);

    // Set the month to the previous month (current month - 1)
    let lastMonth;
    if (today.getMonth() == 2 && today?.getDate() == 31) {
      lastMonth = new Date(today.getFullYear(), 1, 1);
    } else {
      lastMonth = new Date(today);
      // Adjust the month to the previous month
      lastMonth.setMonth(today.getMonth() - 1);
    }
    // Now, handle the leap year case if last month was February
    const year = lastMonth.getFullYear();
    const month = lastMonth.getMonth();




    // If last month was February (month 1)
    if (month === 1) {
      // Check if the year is a leap year
      const isLeapYear = (year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0));

      // If it's a leap year, February has 29 days, otherwise 28
      if (isLeapYear) {
        lastMonth.setDate(29);  // February 29th in a leap year
      } else {
        lastMonth.setDate(28);  // February 28th in a common year
      }
    }

    // Get the first day of the last month (1st of the month)
    let startOfLastMonth = new Date(lastMonth.getFullYear(), lastMonth.getMonth(), 1);

    // Get the last day of the last month by setting the month to the next month and using the 0th day
    let endOfLastMonth = new Date(lastMonth.getFullYear(), lastMonth.getMonth() + 1, 0);

    if (!(endOfLastMonth <= new Date(GetGeneral?.dYEDate) && endOfLastMonth >= new Date(GetGeneral?.dYSDate))) {
      startOfLastMonth = new Date(new Date(GetGeneral?.dYSDate));
      endOfLastMonth = new Date(GetGeneral?.dYSDate);
    }

    // Return start and end dates of last month
    return {
      From: startOfLastMonth,
      To: endOfLastMonth
    };
  }

  function getCurrentMonthDates() {
    // Get today's date
    const today = new Date(footerDate);

    // Get the first day of the current month (1st of the month)
    const startOfCurrentMonth = new Date(today.getFullYear(), today.getMonth(), 1);

    // Get the last day of the current month
    const endOfCurrentMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0); // 0th day of next month gives the last day of the current month


    // Return start and end dates of current month
    return {
      From: startOfCurrentMonth,
      To: endOfCurrentMonth
    };
  }

  function getCurrentDay() {
    // Get today's date
    const today = new Date(footerDate);

    // Return today's date in YYYY-MM-DD format
    return today;
  }

  function getLastDay() {
    // Get today's date
    let yesterday = new Date(footerDate);

    // Subtract one day to get yesterday's date
    yesterday.setDate(yesterday.getDate() - 1);
    if (!(yesterday <= new Date(GetGeneral?.dYEDate) && yesterday >= new Date(GetGeneral?.dYSDate))) {
      yesterday = new Date(GetGeneral?.dYSDate);
    }
    // Return yesterday's date

    return yesterday;
  }


  function getCurrentQuarterDates() {
    const currentDate = new Date(footerDate);
    const currentMonth = currentDate.getMonth(); // Get the current month (0-11)

    // Adjust the month for your fiscal year starting in April
    const fiscalMonth = currentMonth >= 3 ? currentMonth - 3 : currentMonth + 9;

    let startDate = null;
    let endDate = null;

    // Determine the start and end dates of the current quarter
    if (fiscalMonth >= 0 && fiscalMonth < 3) {
      // Q1: April 1st - June 30th
      startDate = new Date(currentDate.getFullYear(), 3, 1); // April 1st
      endDate = new Date(currentDate.getFullYear(), 5, 30); // June 30th
    } else if (fiscalMonth >= 3 && fiscalMonth < 6) {
      // Q2: July 1st - September 30th
      startDate = new Date(currentDate.getFullYear(), 6, 1); // July 1st
      endDate = new Date(currentDate.getFullYear(), 8, 30); // September 30th
    } else if (fiscalMonth >= 6 && fiscalMonth < 9) {
      // Q3: October 1st - December 31st
      startDate = new Date(currentDate.getFullYear(), 9, 1); // October 1st
      endDate = new Date(currentDate.getFullYear(), 11, 31); // December 31st
    } else if (fiscalMonth >= 9 && fiscalMonth < 12) {
      // Q4: January 1st - March 31st
      startDate = new Date(currentDate.getFullYear(), 0, 1); // January 1st
      endDate = new Date(currentDate.getFullYear(), 2, 31); // March 31st
    }

    // Return the formatted start and end dates
    return {
      From: startDate,
      To: endDate,
    };
  }


  function getFinancialYearDates() {
    const today = new Date(footerDate);
    const currentYear = today.getFullYear();

    const financialYearStart = new Date(currentYear, 3, 1);

    if (today < financialYearStart) {
      financialYearStart.setFullYear(currentYear - 1);
    }
    const financialYearEnd = new Date(financialYearStart.getFullYear() + 1, 2, 31);



    return {
      From: financialYearStart,
      To: financialYearEnd
    };
  }


  function getLastQuarterDates() {
    const currentDate = new Date(footerDate);
    const currentMonth = currentDate.getMonth(); // Get the current month (0-11)

    // Adjust for fiscal year starting in April
    const fiscalMonth = currentMonth >= 3 ? currentMonth - 3 : currentMonth + 9;

    let startDate = null;
    let endDate = null;

    // Determine the start and end dates of the last quarter
    if (fiscalMonth >= 0 && fiscalMonth < 3) {
      // Last quarter is Q4 (Jan-Mar)
      startDate = new Date(currentDate.getFullYear(), 0, 1); // January 1st
      endDate = new Date(currentDate.getFullYear(), 2, 31); // March 31st
    } else if (fiscalMonth >= 3 && fiscalMonth < 6) {
      // Last quarter is Q1 (Apr-Jun)
      startDate = new Date(currentDate.getFullYear(), 3, 1); // April 1st
      endDate = new Date(currentDate.getFullYear(), 5, 30); // June 30th
    } else if (fiscalMonth >= 6 && fiscalMonth < 9) {
      // Last quarter is Q2 (Jul-Sep)
      startDate = new Date(currentDate.getFullYear(), 6, 1); // July 1st
      endDate = new Date(currentDate.getFullYear(), 8, 30); // September 30th
    } else if (fiscalMonth >= 9 && fiscalMonth < 12) {
      // Last quarter is Q3 (Oct-Dec)
      startDate = new Date(currentDate.getFullYear() - 1, 9, 1); // October 1st
      endDate = new Date(currentDate.getFullYear() - 1, 11, 31); // December 31st
    }

    if (!(startDate <= new Date(GetGeneral?.dYEDate) && startDate >= new Date(GetGeneral?.dYSDate))) {
      startDate = new Date(GetGeneral?.dYSDate);
      endDate = new Date(GetGeneral?.dYSDate);
    }

    // Return the formatted start and end dates
    return {
      From: startDate,
      To: endDate,
    };
  }






  useEffect(() => {
    if (dateRange) {
      switch (dateRange) {
        case "DF":
          dispatch(getCurrentDate({ "date": { From: date?.From, To: date?.To }, "id": obj?.id, "flag": true }))
          break;
        case "CW":
          const weekDates = getCurrentWeekDates();
          dispatch(getCurrentDate({ "date": { From: weekDates?.From, To: weekDates?.To }, "id": obj?.id, "flag": true }))
          break;
        case "LW":
          const weekDates1 = getLastWeekDates();
          dispatch(getCurrentDate({ "date": { From: weekDates1?.From, To: weekDates1?.To }, "id": obj?.id, "flag": true }))
          break;
        case "LM":
          const monthDates = getLastMonthDates();
          dispatch(getCurrentDate({ "date": { From: monthDates?.From, To: monthDates?.To }, "id": obj?.id, "flag": true }))
          break;
        case "CM":
          const monthDates1 = getCurrentMonthDates();
          dispatch(getCurrentDate({ "date": { From: monthDates1?.From, To: monthDates1?.To }, "id": obj?.id, "flag": true }))
          break;
        case "CD":
          const todayDate = getCurrentDay();
          dispatch(getCurrentDate({ "date": { From: todayDate, To: todayDate }, "id": obj?.id, "flag": true }))
          break;
        case "LD":
          const lastDay = getLastDay();
          dispatch(getCurrentDate({ "date": { From: lastDay, To: lastDay }, "id": obj?.id, "flag": true }))
          break;
        case "CQ":
          const currentQuarter = getCurrentQuarterDates();
          dispatch(getCurrentDate({ "date": { From: currentQuarter.From, To: currentQuarter.To }, "id": obj?.id, "flag": true }))
          break;
        case "LQ":
          const lastQuarter = getLastQuarterDates();
          dispatch(getCurrentDate({ "date": { From: lastQuarter.From, To: lastQuarter.To }, "id": obj?.id, "flag": true }))
          break;
        case "YR":
          const yearRange = getFinancialYearDates();
          dispatch(getCurrentDate({ "date": { From: yearRange.From, To: yearRange.To }, "id": obj?.id, "flag": true }))
          break;
        default:
          break;
      }
    }
  }, [dateRange, footerDate])

  
  const dateFormater2 = (inputDate) => { // Yasvi Patel
    // console.log("inputDate",inputDate)
    const date = new Date(inputDate);

    // Extract components
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    // Get the timezone offset
    const timezoneOffset = -date.getTimezoneOffset(); // in minutes
    const offsetHours = String(Math.floor(Math.abs(timezoneOffset) / 60)).padStart(2, '0');
    const offsetMinutes = String(Math.abs(timezoneOffset) % 60).padStart(2, '0');
    const timezoneString = (timezoneOffset >= 0 ? '+' : '-') + offsetHours + ':' + offsetMinutes;

    // Construct the final output
    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:00${timezoneString}`;

    return formattedDate;
  }

  const dateFormater = (date, temp) => {
    let result;
    // console.log("date in dateFormatter",date)
    if (temp === 0) {
      var paraArr = date?.split("T");
      var paraArr1 = new Date(paraArr);
      result = paraArr1;

    } else if (temp === 1) {
      let year = date.toLocaleString("en-IN", { year: "numeric" });
      let month = date.toLocaleString("en-IN", { month: "2-digit" });
      let day = date.toLocaleString("en-IN", { day: "2-digit" });
      let date1 = year + month + day;
      const date2 =
        date1?.slice(0, 4) +
        "-" +
        date1?.slice(4, 6) +
        "-" +
        date1?.slice(6, 8);
      result = date1;
    }
    return result;
  };//Vara Pooja
  // program to check leap year
  function checkLeapYear(year) {

    //three conditions to find out the leap year
    if ((0 == year % 4) && (0 != year % 100) || (0 == year % 400)) {
      return true;
    } else {
      return false;
    }
  }

  let lom = "";
  let m = String(date1?.getMonth() + 1).padStart(2, '0');
  switch (m.toString()) {
    case "01":
      lom = 31;
      break;
    case "02":
      lom = checkLeapYear(date1.getFullYear()) ? 29 : 28;
      break;
    case "03":
      lom = 31;
      break;
    case "04":
      lom = 30;
      break;
    case "05":
      lom = 31;
      break;
    case "06":
      lom = 30;
      break;
    case "07":
      lom = 31;
      break;
    case "08":
      lom = 31;
      break;
    case "09":
      lom = 30;
      break;
    case "10":
      lom = 31;
      break;
    case "11":
      lom = 30;
      break;
    case "12":
      lom = 31;
      break;
    default:
      break;
  }


  useEffect(() => {
    setcurrentDate1(currentDate);
    setFlagDate1(flagDate);
  }, [currentDate, flagDate]); //Yasvi Patel

  // useEffect(() => {
  //   // console.log("GetGeneral", GetGeneral?.dYEDate, GetGeneral?.dYEDate);
  //   const strtDate = date1.getFullYear()
  //       + GetGeneral?.dYSDate.substr(4,1)+parseInt(date1.getMonth()+1)+GetGeneral?.dYSDate.substr(7,1)+"01"+GetGeneral?.dYSDate.substr(10);
  //    console.log("strtDate",strtDate);
  //   //     const currentdateNewFrom = currentDate[obj.id]?.From?.getFullYear()
  //   // + GetGeneral?.dYSDate.substr(4,1)+parseInt(currentDate[obj.id]?.From?.getMonth()+1)+GetGeneral?.dYSDate.substr(7,1)+currentDate[obj.id]?.From?.getDate()+GetGeneral?.dYSDate.substr(10);
  //   // console.log("currentdateNew",currentdateNewFrom);

  //     const endDate = date1.getFullYear()
  //       + GetGeneral?.dYSDate.substr(4,1)+parseInt(date1.getMonth()+1)+GetGeneral?.dYSDate.substr(7,1)+lom+GetGeneral?.dYSDate.substr(10);   
  //       // const currentdateNewTo = currentDate[obj.id]?.From?.getFullYear()
  //       // + GetGeneral?.dYSDate.substr(4,1)+parseInt(currentDate[obj.id]?.To?.getMonth()+1)+GetGeneral?.dYSDate.substr(7,1)+currentDate[obj.id]?.To?.getDate()+GetGeneral?.dYSDate.substr(10);;
  //       // console.log("currentdateTo",currentdateNewTo);


  //       if(flagDate[obj?.id]==false)  {

  //     Store.dispatch(getCurrentDate({"date":{From: dateFormater(strtDate, 0), To: dateFormater(endDate, 0)},"id": obj?.id,"flag":false}))};

  // }, [GetGeneral]);//Vara Pooja

  useEffect(() => {
    // console.log("GetGeneral", GetGeneral?.dYEDate, GetGeneral?.dYEDate);
    const strtDate1 = GetGeneral?.dYSDate;
    const endDate1 = GetGeneral?.dYEDate;
    //  if(tmList[obj?.id]){
    // setFlagOfReport1(true);
    let flag = false;
    if (new Date(GetGeneral?.dYSDate).getTime() <= date1.getTime() && new Date(GetGeneral?.dYEDate).getTime() >= date1.getTime()) {
      flag = true;
    }

    const strtDate = flag ? date1.getFullYear()
      + "-" + String(date1?.getMonth() + 1).padStart(2, '0') + "-" + "01" + GetGeneral?.dYSDate.substr(10) : GetGeneral?.dYEDate?.replace(/31/, '01')
    const endDate = flag ? date1.getFullYear()
      + "-" + String(date1?.getMonth() + 1).padStart(2, '0') + "-" + lom + GetGeneral?.dYSDate.substr(10) : GetGeneral?.dYEDate
    setDate({ From: dateFormater(strtDate, 0), To: dateFormater(endDate, 0) })
    // }
    // }
    // console.log("flagDate",flagDate1)
    if (flagDate1[obj?.id] == false || flagDate1 == {} || flagDate1 == false || flagDate1 == undefined) {
      if (tmList[obj.id]?.oDTR?.DTType == "S") {
        Store.dispatch(getCurrentDate({ "date": { From: dateFormater(date2?.From, 0), To: dateFormater(date2?.To, 0) }, "id": obj?.id, "flag": false }))

      } else if (tmList[obj.id]?.oDTR?.DTType == "R") {
        Store.dispatch(getCurrentDate({ "date": { From: dateFormater(strtDate, 0), To: dateFormater(endDate, 0) }, "id": obj?.id, "flag": false }))
      }
    }; //Yasvi Patel

    setMinimumDate(dateFormater(strtDate1, 0)); //Yasvi Patel
    setMaximumDate(dateFormater(endDate1, 0)); //Yasvi Patel}
    // }
  }, [GetGeneral]); //Vara Pooja 

  //SEARCH

  useEffect(() => {
    // console.log("SearchInputValue in Datagrid", SearchInputValue);
    if (SearchInputValue == "" && flag) {
      setFlag(false)
      setSearch({[obj.id]: ""})
      // console.log("InputRef",InputRef);
      // InputRef.current.value = ""
    }
  }, [SearchInputValue]) //Vara Pooja 



  //TEMP SEARCH
  // useEffect(() => {
  //   if (aSrcDic) {
  //     let objValueAry = Object.values(aSrcDic);
  //     let columnArray = [];
  //     objValueAry.filter((item) => {
  //       acTM?.TMFormatData?.oDCFrmt?.aDCol?.map((ele, index) => {
  //         // Search on first column
  //         // if (index === 0 && ele.DispExpr === item.cFldID) {
  //         if (ele.DispExpr === item.cFldID) {
  //           columnArray.push(item.FileID + "." + ele.SearchFld);
  //         }
  //       });
  //     });
  //     setSearchColumns(columnArray);
  //     // console.log("objValueAry", objValueAry);
  //     // console.log("acTM?.TMFormatData?.oDCFrmt?.aDCol", acTM?.TMFormatData?.oDCFrmt?.aDCol);
  //     console.log("columnArray", columnArray);
  //     // console.log(aSrcDic,"aSrcDic");
  //   }
  // }, [aSrcDic]);//Vara Pooja

  // useEffect(() => {
  //   // console.log("search", search);
  //   // console.log(query, "qr");
  //   // console.log("pagination", pagination);


  //   if (flag && search.length <= 0) {
  //     setQuery()
  //     // if (pagination.pageIndex === 0 && pagination.pageSize === 25) {
  //     //   dispatch(GetAccountList({ ...obj,pagination,StrtDt: sDate, EndDt: eDate, cWhere: query }));
  //     // }
  //     // else {
  //     //   setPagination({
  //     //     pageIndex: 0,
  //     //     pageSize: 25,
  //     //   });
  //     // }
  //     // setPagination({
  //     //   pageIndex: 0,
  //     //   pageSize: 25,
  //     // });
  //     dispatch(GetAccountList({ ...obj }))
  //     setFlag(false)
  //   }

  // }, [search])//Vara Pooja

  const searchButton = () => {
    let query = ""
    const sDate = dateFormater(currentDate?.[obj?.id]?.From ? currentDate?.[obj?.id]?.From : tmList[obj?.id]?.oDTR?.DTType == "S" ? date2?.From : date?.From, 1); // Last change by Yasvi Patel (for implementing this functionality : tab switch kria to pn date nu filter as it is revu joia)
    const eDate = dateFormater(currentDate?.[obj?.id]?.To ? currentDate?.[obj?.id]?.To : tmList[obj?.id]?.oDTR?.DTType == "S" ? date2?.To : date?.To, 1);
    setStrtDt(sDate);
    setEndDt(eDate);
    var newobj;
    if (cfmtid == "") {
      newobj = { ...obj, p2: obj.p2 };
    } else {
      newobj = { ...obj, p2: cfmtid };
    }
    if (searchColumns && search[obj.id]) {
      let str = [];
      searchColumns?.map((item) => str.push(`${item} LIKE '%${search[obj.id]}%'`))
      query = (cWhere ? cWhere + " and " : "") + str.join(' OR ')
      setQuery(query)
      setFlag(true)
    }
    // if (TransactionReportIdArray.includes(obj.id) && sDate && eDate) {
      if (sDate <= eDate) {
        dispatch(GetAccountList({ ...newobj, StrtDt:  sDate , EndDt:  eDate, cWhere: query })); //, cWhere: query 
        dispatch(getRangeBtnState({ "id": [obj.id], "rangeBtnState": true }))
      }
      else {
        GlobalClass.Notify("warning", "", "'From date' should be less than or equals 'To date'");
      }
    // }
    // else {

    //   dispatch(GetAccountList({ ...newobj, StrtDt: props?.obj?.StrtDt ?? sDate, EndDt: props?.obj?.EndDt ?? eDate, cWhere: query })); //, cWhere: query 
    //   dispatch(getRangeBtnState({ "id": [obj.id], "rangeBtnState": true }))
    // }


  };//Vara Pooja

  const PLSearchButton = () => {

    // console.log("search", search);
    // console.log("searchColumns", searchColumns);

    // if (date?.From !== dDate?.From) {
    dispatch(getRangeBtnState({ "id": [obj.id], "rangeBtnState": true }))
    // if (search.length > 0) {

    //Search on first column
    let query = `${cWhere ? cWhere + " and " : ""}${searchColumns[0] ?? ""} LIKE '%${search[obj.id]}%'`;
    setFlag(true)
    // setQuery(query);

    // console.log("query", query);
    // console.log("pagination", pagination);

    // let str = [];
    // searchColumns.map((item) => str.push(`${item} LIKE '%${search}%'`))
    // let query = str.join(' OR ')
    // setQuery(query)

    // if (isDataGridCalled != true && date.From && date.To && acTM?.AccountListData.length !== 0) {
    const sDate = dateFormater(currentDate?.[obj?.id]?.From ? currentDate?.[obj?.id]?.From : tmList[obj?.id]?.oDTR?.DTType == "S" ? date2?.From : date?.From, 1); // Last change by Yasvi Patel (for implementing this functionality : tab switch kria to pn date nu filter as it is revu joia)
    const eDate = dateFormater(currentDate?.[obj?.id]?.To ? currentDate?.[obj?.id]?.To : tmList[obj?.id]?.oDTR?.DTType == "S" ? date2?.From : date?.To, 1);
    setStrtDt(sDate);
    setEndDt(eDate);
    // if (date?.From && date?.To) {
    // if (date.From.valueOf() < date.To.valueOf()) {
    dispatch(GetAccountList({ ...obj, StrtDt: props?.obj?.StrtDt ? props?.obj?.StrtDt : sDate, EndDt: props?.obj?.EndDt ? props?.obj?.EndDt : eDate, cWhere: query }));
    // if (pagination.pageIndex === 0 && pagination.pageSize === 25) {
    // }
    // else {
    //   setPagination({
    //     pageIndex: 0,
    //     pageSize: 25,
    //   });
    // }

    // }
    // else {
    //   GlobalClass.Notify("warning", "", "'From date' should be less than 'To date'");
    // }
    // }

    // setMinimumDate(date?.From);
    // }

    // const sDate = dateFormater(date?.From, 1);
    // const eDate = dateFormater(date?.To, 1)
    // dispatch(GetAccountList({ ...obj, StrtDt: sDate, EndDt: eDate, pagination, cWhere: search, }));
    // }
  };//Vara Pooja

 
  
  const alphaArr = GlobalClass.keyArr;
  let HotKeys = alphaArr.map((btn) => [
    btn,
    () => {
      setSearch((prev) =>
        btn === "backspace"
          ? { ...prev, [obj.id]: prev[obj.id]?.slice(0, -1) }
          : { ...prev, [obj.id]: prev[obj.id]? prev[obj.id]+ btn: btn }
      );
    },
    { preventDefault: true },
  ]);
  
  useHotkeys(HotKeys); // Enable hotkeys only when active

  useEffect(() => {
    if (hasError && !isLoading[obj?.id]) {
      GlobalClass.Notify("error", "Error: " + ErrorMsg.code, ErrorMsg.message);
    }
  }, [hasError]);

  // useEffect(()=>{
  //   // console.log("........................",acTM.AccountListData,acTM.TMFormatData);
  // },[acTM.TMFormatData,acTM.TMFormatData])
  // const [searchParams, setSearchParams] = useSearchParams();

  const columnHelper = createColumnHelper();
//   const ref = useRef(null);
//   const isVisible = useOnScreen(ref);
  let summaryarr = [];

  useEffect(() => {
    // console.log("notag");
    // if (tag == 'PM') {
    //   dispatch(ProcessMasterData())
    //   console.log('ProcessMData==>', ProcessMData);
    // } else {
    // setcfmtid(selectedId);
    var newobj;
    if (cfmtid == "") {
      newobj = { ...obj, p2: obj.p2 };
    } else {
      newobj = { ...obj, p2: cfmtid };
    }

    if (props.TmData) {
      setacTM({ TMFormatData: props.TmData, AccountListData: props.data });
    } else {
      let flag = false
      if (new Date(GetGeneral?.dYSDate).getTime() <= date1.getTime() && new Date(GetGeneral?.dYEDate).getTime() >= date1.getTime()) {
        flag = true;
      }

      const strtDate = flag ? date1.getFullYear()
        + "-" + String(date1?.getMonth() + 1).padStart(2, '0') + "-" + "01" + GetGeneral?.dYSDate.substr(10) : GetGeneral?.dYEDate?.replace(/31/, '01');
      const edate = flag ? date1.getFullYear()
        + "-" + String(date1?.getMonth() + 1).padStart(2, '0') + "-" + lom + GetGeneral?.dYSDate.substr(10) : GetGeneral?.dYEDate

      dispatch(
        GetTMFormat({
          ...newobj,
          // StrtDt: currentDate?.[obj?.id]?.From ? currentDate?.[obj?.id]?.From : tmList[obj?.id]?.oDTR?.DTType=="S" ?date2?.From :strtDate, // Last change by Yasvi Patel (for implementing this functionality : tab switch kria to pn date nu filter as it is revu joia)
          // EndDt: currentDate?.[obj?.id]?.To ? currentDate?.[obj?.id]?.To : tmList[obj?.id]?.oDTR?.DTType=="S" ?date2?.To :edate,
        })
      );
      setFlagOfReport(false)
    }

    // if (props.data) {
    //   // dispatch(GetAccountList(obj));

    // } else {
    //   dispatch(GetAccountList({...obj,pagination}));
    // }

    // }
  }, [cfmtid]);


  // console.log("query",query);
  // console.log("cWhere",cWhere);

  // useEffect(() => {
  //   if (form && props.data) {
  //     setPagination({ pageIndex: props?.pagination?.pageIndex, pageSize: props?.pagination?.pageSize })
  //   }
  // }, [props.data])//PLDATAGRID VAlUE ISSUE (2 OR 3 Page pr hoy to edit na case ma proper display krva mate)//VARA POOJA

  useEffect(() => {
    if (props.data) {
      // dispatch(GetAccountList(obj));
      // setManualPagination(false);
      setacTM({ ...acTM, AccountListData: props.data });
      // ref[obj.id].dataSource = props.data;
      // props.data.map((item,index) => {
      //   let value = Object.keys(props.selectedData)
      //   if(value ==){
      //   }
      // })
    } else {
      if (tmList?.[obj.id] && flagOfReport == false) {
        setFlagOfReport(true)
        if (!ReportIdArray.includes(obj.id) || (acTM.AccountListData && acTM.AccountListData.length <= 0) || true) {
          let flag = false
          if (new Date(GetGeneral?.dYSDate).getTime() <= date1.getTime() && new Date(GetGeneral?.dYEDate).getTime() >= date1.getTime()) {
            flag = true;
          }

          const strtDate = flag ? date1.getFullYear()
            + "-" + String(date1?.getMonth() + 1).padStart(2, '0') + "-" + "01" + GetGeneral?.dYSDate.substr(10) : GetGeneral?.dYEDate?.replace(/31/, '01')
          const edate = flag ? date1.getFullYear()
            + "-" + String(date1?.getMonth() + 1).padStart(2, '0') + "-" + lom + GetGeneral?.dYSDate.substr(10) : GetGeneral?.dYEDate

          let date2 = {
            From: !flagDate1[obj?.id] ?
              new Date(GetGeneral?.dYSDate).getTime() <= date1.getTime() && new Date(GetGeneral?.dYEDate).getTime() >= date1.getTime() ? (date1.getFullYear() + "-" + String(date1.getMonth() + 1).padStart(2, '0') + "-" + String(date1?.getDate()).padStart(2, '0') + GetGeneral?.dYSDate.substr(10))
                : GetGeneral?.dYEDate :
              currentDate[obj?.id], To: !flagDate1[obj?.id] ?
                new Date(GetGeneral?.dYSDate).getTime() <= date1.getTime() && new Date(GetGeneral?.dYEDate).getTime() >= date1.getTime() ? (date1.getFullYear() + "-" + String(date1.getMonth() + 1).padStart(2, '0') + "-" + String(date1?.getDate()).padStart(2, '0') + GetGeneral?.dYSDate.substr(10))
                  : GetGeneral?.dYEDate :
                currentDate[obj?.id]
          }
          dispatch(getRangeBtnState({ "id": [obj.id], "rangeBtnState": true }))
          var newobj;
          if (cfmtid == "") {
            newobj = { ...obj, p2: obj.p2 };
          } else {
            newobj = { ...obj, p2: cfmtid };
          }
          dispatch(GetAccountList({
            ...newobj,
            // pagination: ReportIdArray.includes(obj.id) ? null : pagination,
            StrtDt: props?.obj?.StrtDt ? props?.obj?.StrtDt : StrtDt ?? currentDate[obj?.id]?.From ? dateFormater2(currentDate[obj?.id]?.From) : tmList[obj?.id]?.oDTR?.DTType == "S" ? date2?.From : strtDate, // Last change by Yasvi Patel (for implementing this functionality : tab switch kria to pn date nu filter as it is revu joia)
            EndDt: props?.obj?.EndDt ? props?.obj?.EndDt : EndDt ?? currentDate[obj?.id]?.To ? dateFormater2(currentDate[obj?.id]?.To) : tmList[obj?.id]?.oDTR?.DTType == "S" ? date2?.From : edate,
            cWhere: cWhere ?? query,
            // ReportIdSave: ReportIdArray.includes(obj.id),
          })
          );
        }
      }
      // dispatch(
      //   GetPaginationObj({
      //     id: obj.id,
      //     // pagination: ReportIdArray.includes(obj.id) ? null : pagination,
      //     // ReportIdSave: ReportIdArray.includes(obj.id),
      //   })
      // );
    }
    // }
  }, [props.data, tmList[obj.id], flagOfReport]);

  let lfstcol = -1;
  let lcolno = -1;
  let nstr = "#";
  let dstr = "0";

  const Summary = (obj, i, tag) => {
    let stype = "sum";
    var value = null;
    const closingBal = cFooter?.cFooter?.[0]?.CtrlProp?.Text
    // console.log("Summary",obj);
    if (obj.cSType !== "N") {
      if (lfstcol < 0) {
        lfstcol = i;
      }
      switch (obj.cSType) {
        case "C":
        case "Y":
          stype = "count";
          // value = (props.data
          //   ? props.data.reduce((acc, curr) => acc + curr[obj.DispExpr], 0)
          //   : acTM.AccountListData.reduce(
          //     (acc, curr) => acc + curr[obj.DispExpr],
          //     0
          //   ))?.toFixed(4);

          value = (props.data
            ? props.data.reduce((acc, curr) => {
              const num = parseFloat(curr[obj.DispExpr]);
              return isNaN(num) ? parseFloat(acc) : acc + num;
            }, 0)
            : acTM.AccountListData.reduce((acc, curr) => {
              const num = parseFloat(curr[obj.DispExpr]);
              return isNaN(num) ? parseFloat(acc) : acc + num;
            }, 0)).toFixed(4);
          break;
        case "A":
          stype = "avg";
          break;
        case "X":
          stype = "max";
          break;
        case "M":
          stype = "min";
          break;
        case "U":
          stype = "custom";
          break;
        default:
          // value = props.data
          //   ? props.data.reduce((acc, curr) => acc + curr[obj.DispExpr], 0)
          //   : acTM.AccountListData.reduce(
          //     (acc, curr) => acc + curr[obj.DispExpr],
          //     0
          //   );
          break;
      }
    } else if (obj.ColWidth > 4 && lfstcol < 0) {
      lcolno = i;
    }
    // console.log('value',value)
    // console.log("Summaryvalue",obj.cSType);
    // if (value && !isNaN(Number(value))) {
    //   return closingBal
    // }
    return <div style={{ textAlign: 'right' }}>
      {(value)}
      {i == 5 ? <Text>{(value && !isNaN(Number(value))) ? closingBal?.split(" : ")[0] : ''}</Text> : null}
      {i == 6 ? <Text>{(value && !isNaN(Number(value))) ? closingBal?.split(" : ")[1] : ''}</Text> : null}
    </div>
  };
  const evaluateExpression = (expression, obj) => {
    // Use a function to safely evaluate the expression
    // let result = "";
    try {
      return new Function(...Object.keys(obj), `return ${expression};`)(...Object.values(obj));
    }
    catch {
      return obj[expression];
    }
    // return result;
  };
  const rendercolcell = (data, v, row) => {

    // if (v.DataType === "N") {
    //   console.log('rendercolcellPara =>>', v)
    // }
    // console.log('rendercolcellPara GetGeneral=>>', GetGeneral)

    let dtxt = data;
    var datatype =
      v.DataType === "N"
        ? "number"
        : v.ColClick !== "" || v.DispExpr === "M01F68"
          ? "custom"
          : "string";

    if (datatype === "custom") {
      if (dtxt === "A") {
        dtxt = "✓";
      } else {
        dtxt = "";
      }
    }

    // if (datatype == "number") {
    //   if (["R"].includes(v.ColDec)) { // Rate = 3
    //     dtxt = Number(dtxt).toFixed(oYear?.OSC?._RATEDEC)
    //   } else if (["Q"].includes(v.ColDec)) { //Qty = 3
    //     dtxt = Number(dtxt).toFixed(oYear?.OSC?._DEC1)
    //   } else if (["S"].includes(v.ColDec)) { // 2
    //     dtxt = Number(dtxt).toFixed(oYear?.OSC?._DEC2)
    //   } else if (["A"].includes(v.ColDec)) { // Amount = 2
    //     dtxt = Number(dtxt).toFixed(oYear?.OSC?._AMTDEC)
    //   } else { //Default = 2
    //     dtxt = Number(dtxt).toFixed(oYear?.OSC?._AMTDEC)
    //   }
    // }

    if (v.DispFormat.includes("A")) {
      dtxt = Math.abs(dtxt);
    }
    if (v.DataType.includes("D")) {
      // dtxt = dtxt?.slice(0, 4) + "/" + dtxt?.slice(4, 6) + "/" + dtxt?.slice(6, 8) ;
      //  dtxt = "2023/12/12"
      if (data != null && data != "" && data != undefined) {
        dtxt = dtxt.toString()  //Vara Pooja
        dtxt =
          // dtxt?.slice(0, 4) + "/" + dtxt?.slice(4, 6) + "/" + dtxt?.slice(6, 8);
          dtxt?.slice(6, 8) + "/" + dtxt?.slice(4, 6) + "/" + dtxt?.slice(0, 4); //Yasvi Patel 
      } else {
        dtxt = "";
      }
      // return dtxt
    }
    if (v.DispFormat.includes("Z")) {
      if (dtxt === ".00") {
        dtxt = "";
      }
    }

    if (v.DispFormat.includes("X")) {
      if (dtxt !== "") {
        if (parseFloat(dtxt) > 0) {
          dtxt = Math.abs(dtxt);
            if (["R"].includes(v.ColDec)) { // Rate = 3
            dtxt = Number(dtxt).toFixed(oYear?.OSC?._RATEDEC)
          } else if (["Q"].includes(v.ColDec)) { //Qty = 3
            dtxt = Number(dtxt).toFixed(oYear?.OSC?._DEC1)
          } else if (["S"].includes(v.ColDec)) { // 2
            dtxt = Number(dtxt).toFixed(oYear?.OSC?._DEC2)
          } else if (["A"].includes(v.ColDec)) { // Amount = 2
            dtxt = Number(dtxt).toFixed(oYear?.OSC?._AMTDEC)
          } else { //Default = 2
            dtxt = Number(dtxt).toFixed(oYear?.OSC?._AMTDEC)
          }
          dtxt += " CR";
        } else if (parseFloat(dtxt) == 0) {
          dtxt = 0
        }else {
          dtxt = Math.abs(dtxt);
          if (["R"].includes(v.ColDec)) { // Rate = 3
            dtxt = Number(dtxt).toFixed(oYear?.OSC?._RATEDEC)
          } else if (["Q"].includes(v.ColDec)) { //Qty = 3
            dtxt = Number(dtxt).toFixed(oYear?.OSC?._DEC1)
          } else if (["S"].includes(v.ColDec)) { // 2
            dtxt = Number(dtxt).toFixed(oYear?.OSC?._DEC2)
          } else if (["A"].includes(v.ColDec)) { // Amount = 2
            dtxt = Number(dtxt).toFixed(oYear?.OSC?._AMTDEC)
          } else { //Default = 2
            dtxt = Number(dtxt).toFixed(oYear?.OSC?._AMTDEC)
          }
          dtxt += " DB";
        }
      }
    }

    const replaceMethod = (substring, index, replacement) => {
      return substring.substring(0, index) + replacement + substring.substring(index + replacement.length);
    }


    return (
      <p
        style={{
          fontSize: v.FontSize ? v.FontSize : sessionSetting?.Font_Size,
          fontFamily: v.FontName ? v.FontName : sessionSetting?.Font_Family,
          color: v.ForeColor,
          backgroundColor: v.BackColor,
        }}
        dangerouslySetInnerHTML={{__html: dtxt}}
      >
      </p>
    );
  };

  const rendercolcellGrp = (data, v) => {
    // console.log("EPLUS",v);
    // console.log('data===>>',data)
    let dtxt = data;
    var datatype =
      v.DataType === "N"
        ? "number"
        : v.ColClick !== "" || v.DispExpr === "M01F68"
          ? "custom"
          : "string";
    if (datatype === "custom") {
      if (dtxt === "A") {
        dtxt = "✓";
      } else {
        dtxt = "";
      }
    }

    if (v.DispFormat.includes("A")) {
      dtxt = Math.abs(dtxt);
    }
    if (v.DataType.includes("D")) {
      // dtxt = dtxt?.slice(0, 4) + "/" + dtxt?.slice(4, 6) + "/" + dtxt?.slice(6, 8);
      //  dtxt = "2023/12/12"
      if (data != null && data != "" && data != undefined) {
        dtxt =
          dtxt?.slice(0, 4) + "/" + dtxt?.slice(4, 6) + "/" + dtxt?.slice(6, 8);
      } else {
        dtxt = "";
      }
      return dtxt;
    }
    if (v.DispFormat.includes("Z")) {
      if (dtxt === ".00") {
        dtxt = "";
      }
    }

    if (v.DispFormat.includes("X")) {
      if (dtxt !== "") {
        if (parseFloat(dtxt) > 0) {
          dtxt += " CR";
        } else {
          dtxt += " DB";
        }
      }
    }

    return (
      <p
        style={{
          fontSize: v.FontSize ? v.FontSize : 10,
          fontFamily: v.FontName ? v.FontName : "Verdana",
          color: v.ForeColor,
          backgroundColor: v.BackColor,
        }}
      >
        {dtxt}
      </p>
    );
  };
  function validURL(str) {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  }
  function cellRender(data) {
    if (validURL(data.value)) {
      return <img src={data.value} />;
    } else {
      return data.value;
    }
  }
  const renderGroup = (data) => {
    //console.log(data);
    // return  <p style={
    //   {
    //     'fontSize': 12,
    //     'fontWeight':'bold',
    //     'fontFamily': 'Verdana',
    //     'color': 'red',
    //     'backgroundColor': 'pink'
    //   }
    // }
    // >

    return <div>{data.value}</div>;
    // </p>
  };

  useEffect(() => {
    // console.log("tmList", accList, tmList);
    if (accList && tmList && accList[obj.id] && tmList[obj.id] && !props.TmData) {
      setacTM({
        AccountListData: props?.data ?? (accList[obj.id] ?? []),
        TMFormatData: tmList[obj.id],
      });
      // ref[obj.id].dataSource = props?.data ?? (accList[obj.id] ?? []);
      setfocusset(false);

      // console.log("SaveAccountListArr 1",[{
      //   "SaveAccountListArr":SaveAccountListArr,
      //   "accList":accList,
      //   'accList[obj.id]':accList[obj.id],
      //   "obj":obj
      // }]);
      // ((ReportIdArray.includes(obj?.id)) && accList[obj.id] && (SaveAccountListArr) && (!(obj.id in SaveAccountListArr)))  // Deval & kishan 
      if (accList[obj.id] && (SaveAccountListArr) && (!(obj.id in SaveAccountListArr))) {
        // console.log("SaveAccountListArr 11")
        dispatch(SaveAccountListData({ ...SaveAccountListArr, [obj.id]: accList[obj.id] }))
        dispatch(GetButtonPannel({ ...BtnStatus, [obj.id]: "C" }))
        dispatch(GetButtonPannelList({ ...BtnStatusList, [obj.id]: { ...BtnStatusList[obj.id], "AdjustmentWise": "F", "PendingDue": "A", "AllParty": "A", "DueDays": "F" } }))
        // dispatch(GetButtonPannel({obj,Btn:acTM.TMFormatData?.oS60?.DTHKey}))
      }

    } else if (tmList && tmList[obj.id] && !props.TmData) {
      setacTM({
        AccountListData: props?.data ?? [],
        TMFormatData: tmList[obj.id],
      });
      // ref[obj.id].dataSource = props?.data ?? [];
      // console.log("SaveAccountListArr 2",[{
      //   "SaveAccountListArr":SaveAccountListArr,
      //   'accList[obj.id]':accList[obj.id]
      // }]);

      // ((ReportIdArray.includes(obj?.id)) && props?.data && (SaveAccountListArr) && (!(obj.id in SaveAccountListArr)))  // Deval & kishan 
      if (props?.data && (SaveAccountListArr) && (!(obj.id in SaveAccountListArr))) {
        // console.log("SaveAccountListArr 22")
        dispatch(SaveAccountListData({ ...accList, [obj.id]: props?.data }))
        dispatch(GetButtonPannel({ ...BtnStatus, [obj.id]: "C" }))
        // dispatch(GetButtonPannel({obj,Btn:acTM.TMFormatData?.oS60?.DTHKey}))
      }
    }

  }, [accList, tmList, accList[obj.id]]);
 
  useEffect(() => {
    // console.log("acTM.TMFormatData", acTM?.TMFormatData);
    if (tag && tag == "PM") {
    } else {
      if (
        acTM.TMFormatData &&
        acTM.TMFormatData.oDCFrmt &&
        acTM.TMFormatData.oDCFrmt.aDCol &&
        (acTM.AccountListData || props.data) 
      ) {
        console.log("acTM.TMFormatData", acTM?.TMFormatData);
        var cols = [];
        var aggregates=[];
        var grp = [];
        let grps = [];
        // ref[obj.id].aggregates =[];
        // ref[obj.id].clearGrouping();
        // ref[obj.id].columns = [];
        
        // ref[obj.id].refreshColumns();
        // console.log("colset", acTM?.TMFormatData?.oDCFrmt);
        
        if (acTM.TMFormatData.oDCFrmt?.aDGrp.length > 0) {
          let v = acTM.TMFormatData.oDCFrmt?.aDGrp;
          v.map((v, i) => {
              if(!v.DispExpr) return;
              cols.push({
                  field:v?.DispExpr,
                  headerText:v?.ColCap??"",
                  id:v.DispExpr,
                  width:v?.ColCap ? v?.ColWidth+100 : 0,
                  textAlign:v?.DAlign == 1 ? "right" : "left",
                  allowGrouping:true,
                  allowResizing:false,
                  allowSorting:v?.ColCap ? v?.lColOrder : false,
                  allowReordering:false,
                  allowSearching:((v?.DataType == "D" || !v?.ColCap) ? false : true)
              });
            
              grps.push(v.DispExpr);
            
          })
          // setGrouping(grps);
          
      }
        acTM.TMFormatData.oDCFrmt.aDCol.map((v, i) => {
          

          if(!v.DispExpr) return;
          if(grps.includes(v.DispExpr)) return;
            cols.push({
              
                field:v.DispExpr,
                headerText:v.ColCap,
                id:v.DispExpr,
                width:v.ColCap ? v.ColWidth+100 : 0,
                textAlign:v.DAlign == 1 ? "right" : "left",
                allowGrouping:true,
                allowResizing:false,
                allowSorting:v.ColCap ? v.lColOrder : false,
                allowReordering:false,
                allowSearching:((v.DataType == "D" || !v.ColCap) ? false : true),
                template:(row)=>rendercolcell(evaluateExpression(v.DispExpr, row), v, row)
            });
            // if(v?.cSType !== "N"){
            //   console.log("notN");
              
            //   aggregates.push({
            //     field:v.DispExpr,
            //     type:"Sum",
            //     // format:"C2",
            //     groupFooterTemplate:(props)=>{
            //       console.log("groupfooterTemp",props);
            //       if(props.field == grps[0]){
            //         return <Text style={{ fontWeight: "bold", color: "gray", fontSize: '12px' }}>
            //         Total : {parseFloat(props?.Sum)?.toFixed(4)}
            //       </Text>
            //       }else{
            //         return null
            //       }
            //     },
                  
            //     footerTemplate:(props)=>{
            //       console.log("footerTemp",props);
            //       return Summary(v, i, "c")
            //     }
            //   })
            // }
            
            v?.aChildCol?.map((c) => {
              
              if(!c.DispExpr) return;
                cols.push({
                    field:c.DispExpr,
                    headerText:c.ColCap,
                    id:c.DispExpr,
                    width:c.ColCap ? c.ColWidth+100 : 0,
                    textAlign:c.DAlign == 1 ? "right" : "left",
                    allowGrouping:true,
                    allowResizing:false,
                    allowSorting:c.ColCap ? c.lColOrder : false,
                    allowReordering:false,
                    allowSearching:((c.DataType == "D" || !c.ColCap) ? false : true),
                    template:(row)=>rendercolcell(evaluateExpression(v.DispExpr, row), v, row)
                });
            });
        });
        setColumns(cols)
        // ref[obj.id].columns = cols;
        // ref[obj.id].refreshColumns();
        // if(grps.length > 1){
        //   setIsMultiGroup(true)
        //   setMultiGroups(grps);
        // }
        // else{
        //   ref[obj.id].groupColumn(grps[0]);
        // }
        // aggregates.forEach((a)=>{
        //   ref[obj.id].aggregateColumn(a)
        // })
        
        
        // console.log("reff",ref[obj.id].setProperties);
        // ref[obj.id].toolbar = ["Toolbar"];
        //  ref[obj.id].refreshColumns();
        
        // if(aggregates && aggregates.length > 0){
        //   const aggregateSettings = [
            
        //     {showGroupFooter: true,
        //       columns: aggregates
        //     },
        //   ];
        //   ref[obj.id].aggregates = aggregateSettings;
        // }
        // ref[obj.id].refresh();
       
        // ref[obj.id].refresh();
        // console.log("columnset",ref[obj.id].groupSettings);
       
      
    }}
    // console.log('hasError==>',hasError);
    // console.log("AccountList & TmFormate Data==>", acTM);

    // let ary = acTM?.TMFormatData?.oDCFrmt?.aDCol?.filter((item) => item.lSearchCol === true); // Search
    let ary = acTM?.TMFormatData?.oDCFrmt?.aDCol.filter(item => item.DataType != "D")
    // setlSearchCol(ary);
    if (acTM?.TMFormatData?.oDCFrmt?.aSrcDic) {
      let objValueAry = Object.values(acTM?.TMFormatData?.oDCFrmt?.aSrcDic)
      let columnArray = []
      objValueAry.filter((item) => {
        ary.map(ele => {
          if (ele.DispExpr === item.cFldID) {
            columnArray.push(item.FileID + "." + ele.SearchFld)
          }
        })
      })
      setSearchColumns(columnArray)
    }
    // setisDataGridCalled(true);
  }, [acTM.TMFormatData, acTM.AccountListData]); //acTM.AccountListData   // Deval

useEffect(()=>{
  if (acTM.TMFormatData.oDCFrmt?.aDGrp.length > 0 && columns.length >0) {
    let v = acTM.TMFormatData.oDCFrmt?.aDGrp;
    setGrouping({showDropArea: false,showGroupedColumn: false,disablePageWiseAggregates:false,columns:v.map((v, i) => {
      return v.DispExpr;
  })});
  ref[obj.id].toolbar = ["Toolbar"];
  ref[obj.id].refresh();
    console.log("columns","Groups",columns,v.map((v, i) => {
      return v.DispExpr;
  }));
    
}
},[columns])

  useEffect(() => {
    let arr = [];
    arr.push({
      id: sortingArrangeField?.[props?.obj?.id], desc: sortingOrderField?.[props?.obj?.id] == "D" ? true : false
    })
    setSorting(arr);
  }, [sortingOrderField, sortingArrangeField]) //Yasvi Patel


  useEffect(() => {
    if (tmList[obj?.id]?.oDTR?.DTType == "S") {
      setDate2({
        From: !flagDate1[obj?.id] ?
          new Date(GetGeneral?.dYSDate).getTime() <= date1.getTime() && new Date(GetGeneral?.dYEDate).getTime() >= date1.getTime() ? (date1.getFullYear() + "-" + String(date1.getMonth() + 1).padStart(2, '0') + "-" + String(date1?.getDate()).padStart(2, '0') + GetGeneral?.dYSDate.substr(10))
            : GetGeneral?.dYEDate :
          currentDate[obj?.id], To: !flagDate1[obj?.id] ?
            new Date(GetGeneral?.dYSDate).getTime() <= date1.getTime() && new Date(GetGeneral?.dYEDate).getTime() >= date1.getTime() ? (date1.getFullYear() + "-" + String(date1.getMonth() + 1).padStart(2, '0') + "-" + String(date1?.getDate()).padStart(2, '0') + GetGeneral?.dYSDate.substr(10))
              : GetGeneral?.dYEDate :
            currentDate[obj?.id]
      })
    }
  }, [tmList[obj?.id]?.oDTR?.DTType])
  return (<div className='control-pane'>
            <div className='control-section'>
              <GridComponent
                ref={r=>{
                  ref = {...ref,[obj.id]:r}
                }}
                dataSource={acTM.AccountListData??[]}
                columns={columns}
                allowSorting={true} 
                allowPaging={true} 
                allowKeyboard={true}
                adaptiveUIMode='Both'
                rowHeight={25}
                emptyRecordTemplate={"No Records Found!!!"}
                autoFit={true}
                enableAdaptiveUI={true} 
                enableVirtualization={false}
                enableStickyHeader
                
                height={`${height * (props.size ?? (form ? (0.3) : 0.55))}`}
                allowGrouping={true} 
                pageSettings={{pageSize:25,pageSizes:["100","500","1000","2500","All"]}}
                groupSettings={grouping}
                // rowSelected={(event) => {
                //   if (event.key === "Enter" && currentGroupIndex < MultiGroups.length - 1) {
                //     setCurrentGroupIndex((prev) => prev + 1); // ✅ Expand to the next group level
                //   }
                // }}
                toolbarTemplate={()=>{
                  return <Grid>
                  {!form && acTM.TMFormatData && acTM.TMFormatData.DTS62 && (
                    <>
                      {isFormatDropdown && <Grid.Col m={0} p={5} mb={media ? 0 : 3} span={media ? 4 : 10}>
                        {/* <Card shadow="sm" padding={5} radius="md" withBorder display={'block'}> */}
                        <PLComboBox
                          value={
                            cfmtid
                              ? cfmtid
                              : ReportIdArray.includes(obj.id)
                                ? obj.p2
                                : acTM.TMFormatData.DTS62[0]["S62F02"]
                          }
                          dispexpr="S62F04"
                          valexpr="S62F02"
                          data={acTM.TMFormatData.DTS62}
                          setEdit={(e) => {
                            setcfmtid(e);
                          }}
                        />
                      </Grid.Col>}
                      {isRefresh && <Grid.Col m={0} p={5} mb={media ? 0 : 3} span={2}>
                        <Tooltip label="Refresh Data">
                          <Button
                            size='xs'
                            variant="subtle"
                            // compact
                            onClick={() => {
                              // searchButton();
                              // dispatch(GetAccountList({ ...obj }))
                              searchButton()
                              dispatch((getFilterStatus(false)))
                            }
                            }
                          ><IconRefresh /></Button>
                        </Tooltip>
                      </Grid.Col>}
                      <Grid.Col m={0} p={0} mb={media ? 0 : 3} span={media ? 6 : 12} pl={5}>
                        
                        {<Paper p={4} style={{ display: 'flex', float: 'right' }}>
                          <Grid gutter={1}>
                            <Grid.Col m={0} p={0} span={tmList[obj?.id]?.oDTR?.DTType == "R" ? 3 : tmList[obj?.id]?.oDTR?.DTType == "S"  ? 5 : 9} pl={5}>
                              <PLTextBox
                                // ref={InputRef}
                                placeholder="Search here..."
                                value={search[obj.id]}
                                // onkeyPress={(e) => {
                                //   console.log("search event",e);
                                // }}{ 
                                onKeyDown={(e) => {
                                  if (e.keyCode == 13) {
                                    searchButton()
                                  }
                                }}
                                rightSection={<IconSearch
                                  style={{
                                    color: "lightgray",
                                  }}
                                />
                                }
                                setEdit={(e) => {
                                  setSearch({[obj.id]:e.target.value});
                                }}
                              />
                            </Grid.Col>
                            {tmList[obj?.id]?.oDTR?.DTType=="R" && ( // Showing this combo Box where there are From and To dates in Transaction and Report pages because sir has told to do this 05/03/2025  Yasvi Patel
          
                              <Grid.Col span={2} pl={2}>
                                <PLComboBox
                                  copno="DTR"
                                  value={dateRange1?.[obj.id] ? dateRange1?.[obj.id] : "DF"}
                                  dispexpr="DisplayMember"
                                  valexpr="ValueMember"
                                  setEdit={(e) => {
                                    setDateRange(e?.value);
                                    Store?.dispatch(getDateRange({ "dateRange": e.value, "id": obj?.id, "flag": true }));
                                  }}
                                />
                              </Grid.Col>
                            )}
                          {/* {console.log("tmList[obj?.id]?.oDTR?.DTType",tmList[obj?.id]?.oDTR?.DTType)} */}
                          
                            {tmList[obj?.id]?.oDTR?.DTType == "R" && (
                              <>
                                <Grid.Col 
                                  m={0} p={0} span={tmList[obj?.id]?.oDTR?.DTType == "R" ? 6 : 7} pl={4}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Text
                                    style={{ marginRight: "7px", marginLeft: "5px" }}
                                  >
                                    From
                                  </Text>
                                  <PLDateBox
                                    // style={{ zIndex: 99 }}
                                    value={flagDate1?.[obj?.id] == true ? currentDate?.[obj.id]?.From : date?.From} // Last change by Yasvi Patel (for implementing this functionality : tab switch kria to pn date nu filter as it is revu joia)
                                    // valueFormat="DD/MM/YYYY"
                                    // size="xs"
                                    minDate={dateFormater(GetGeneral?.dYSDate, 0)}
                                    maxDate={maximumDate}
                                    setEdit={(e) => {
                                      // console.log("eeeeee", e);
                                      setDate({ ...date, From: e });
                                      Store?.dispatch(getCurrentDate({ "date": { ...date, From: e }, "id": obj?.id, "flag": true })); // Last change by Yasvi Patel (for implementing this functionality : tab switch kria to pn date nu filter as it is revu joia)
                                      setIsDateChanged(true); // Pooja Vara
          
                                      // setisDataGridCalled(false)
                                    }}
                                  />
          
                                  <Text
                                    style={{ marginRight: "8px", marginLeft: "10px" }}
                                  >
                                    To
                                  </Text>
                                  <PLDateBox
                                    value={flagDate1?.[obj?.id] == true ? currentDate?.[obj?.id]?.To : date?.To} // Last change by Yasvi Patel (for implementing this functionality : tab switch kria to pn date nu filter as it is revu joia)
                                    // valueFormat="DD/MM/YYYY"
                                    // size="xs"
                                    minDate={minimunDate}
                                    maxDate={maximumDate}
                                    setEdit={(e) => {
                                      setDate({ ...date, To: e });
                                      Store?.dispatch(getCurrentDate({ "date": { ...date, To: e }, "id": obj?.id, "flag": true })); // Last change by Yasvi Patel (for implementing this functionality : tab switch kria to pn date nu filter as it is revu joia)
                                      setIsDateChanged(true); //Pooja Vara
                                      // setisDataGridCalled(false)
                                    }}
                                  />
                                </Grid.Col>
                              </>
                            )}
                            {
                                tmList[obj?.id]?.oDTR?.DTType == "S" && (
                                <>
                                  <Grid.Col
                                    m={0} p={0} span={6} pl={5}
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Text
                                      style={{ marginRight: "0px", marginLeft: "3px", fontSize: "12px" }}
                                    >
                                      Report Date:
                                    </Text>
                                    <PLDateBox
                                      // style={{ zIndex: 99 }}
                                      value={flagDate1?.[obj?.id] == true ? currentDate?.[obj?.id]?.To : new Date(date2?.From)} // Last change by Yasvi Patel (for implementing this functionality : tab switch kria to pn date nu filter as it is revu joia)
                                      valueFormat="DD/MM/YYYY"
                                      size="xs"
                                      minDate={dateFormater(GetGeneral?.dYSDate, 0)}
                                      maxDate={maximumDate}
                                      setEdit={(e) => {
                                        setDate2({ ...date2, From: e, To: e });
                                        Store?.dispatch(getCurrentDate({ "date": { ...date2, From: e, To: e }, "id": obj?.id, "flag": true })); // Last change by Yasvi Patel (for implementing this functionality : tab switch kria to pn date nu filter as it is revu joia)
                                        setIsDateChanged(true); // Pooja Vara
                                        // setisDataGridCalled(false)
                                      }}
                                    />
                                  </Grid.Col>
                                </>
          
                              )
          
          
                            }
                            <Grid.Col m={0} p={0} span={1} pl={5} style={{ display: "flex" }}>
                              <Button size="xs" onClick={() => searchButton()}>
                                Go
                              </Button>
                            </Grid.Col>
                          </Grid>
                        </Paper>}
          
                      </Grid.Col>
                      
                    </>
                  )}
                  {form ? (
                    <Grid.Col span={12} style={{ display: 'flex' }}>
                      <Grid.Col p={0} span={10} >
                        <PLTextBox
                          placeholder="Search here..."
                          value={search[obj.id]}
                          setEdit={(e) => {
                            setSearch((prev)=>({...prev, [obj.id]:e.target.value}));
                          }}
                        />
                      </Grid.Col>
                      <Grid.Col p={0} span={2} pl={5}>
                        <Button size="xs" onClick={PLSearchButton}>Go</Button>
                      </Grid.Col>
                    </Grid.Col>
          
                  ) : null}
                </Grid>}}
                toolbar={["Toolbar"]}
                // aggregates={}
                // enableAdaptiveUI={true}
                // enableImmutableMode={true}
                // enableVirtualization={true}
                // enableStickyHeader={true}
                // delayUpdate
                // enablePersistence={true}
                // toolbar={['Add', 'Edit', 'Delete', 'Update', 'Cancel','PdfExport']}
                // allowTextWrap={true} 

                >
                    <Inject services={[Page,Group, Sort, Aggregate,Toolbar]}/>
                </GridComponent>
                </div>
                </div>
  );
}
DataGridSyncfusion.defaultProps = {
  isFormatDropdown: true,
  isRefresh: true,
  isPagination: true
}
export default DataGridSyncfusion;
