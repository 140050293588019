import * as PrintButton from "../FunctionsCall/Print/PLPrint";
import * as PrintVoucherFormat from "../components/PrintVoucherReport";
import Store from "../utils/store";

export const TM_PRINT=(itemOBJ)=>{
    const OBJ = itemOBJ;
    console.log('executeFunction TM_PRINT ===>', OBJ);
    let GetTmFormat = Store?.getState()?.AccountList?.TMFormatData;
    
    if(GetTmFormat[OBJ?.cSTID]?.oS60?.cS92ID){
        PrintVoucherFormat.TM_PRINTVF(JSON.stringify(OBJ));
    }else{
        PrintButton.TM_PRINT(JSON.stringify(OBJ))
    }
}