import { Text } from "@mantine/core";
import { ModalFunction } from "../../utils/slices/ModalSlice";
import Store from "../../utils/store";
import CMPSetup from "../../MyComponent/CMPSetup";
import { GetCmpSetUpObj } from "../../utils/slices/DataSaveLoadingSlice";


export function TM_SETUP(data) {
  const OBJ = JSON.parse(data);
  // console.log("TM_SETUP Print btn=>", OBJ?.text);
  Store.dispatch(
    ModalFunction({
      onClickCloseButton: (e) => {
        // console.log("onclose");
        Store.dispatch(GetCmpSetUpObj(null))
      },
      MTitle: <Text fw={700}>{OBJ?.text}</Text>,
      MAction: true,
      MSize: "100%",
      position: "right",
      MBody: (i) => <CMPSetup obj={OBJ} index={i} />,
      MClose: true,
      Overlay: {
        backgroundOpacity: 0.8,
        blur: 5,
      },
    })
  );
}