import { Button, Grid, Modal, Text, Textarea } from '@mantine/core'
import React, { useEffect, useState } from 'react'
import PLTextBox from '../../PlusComponent/PLTextBox'
import PLNumberBox from '../../PlusComponent/PLNumberBox'
import { useDispatch, useSelector } from 'react-redux'
import { GetGeneralData, GetGeneralTMFormat, GetMailingLatter } from '../../utils/TransactionSlices/MailingLetter'
import GlobalClass from '../../utils/GlobalClass'
import gensetting from '../../utils/gensetting'
import { DataSaveLoading } from '../../utils/slices/DataSaveLoadingSlice'
import { ModalDelete } from '../../utils/slices/ModalSlice'
import FieldValueList from '../../Transaction/SMSEmailWpBtn/FieldValueList'

function MailingLetterForm(props) {

    const { OBJ } = props

    const obj = {
        ...OBJ,
        BtnPnlObj: {
            ...OBJ?.BtnPnlObj,
            p1: "LETTER_",
            page: "LETTER_"
        }
    }

    const dispatch = useDispatch();
    const isLoading = useSelector(store => store?.MailingLetter?.isLoading)
    const MailingLetterData = useSelector(store => store?.MailingLetter?.MailingLetterData);
    const SaveLoading = useSelector((store) => store?.DataSaveLoadingSlice?.SaveLoading);
    const MLF01 = useSelector(store => store?.MailingLetter?.MLF01)
    const hasError = useSelector((state) => state?.MailingLetter?.hasError);
    const ErrorMsg = useSelector((state) => state?.MailingLetter?.ErrorMsg);
    const [opened, setOpened] = useState(false);
    const [msg, setMsg] = useState("");
    const [msgValueProp, setMsgValueProp] = useState("");


    const [data, setData] = useState();
    const [dataObj, setDataObj] = useState();

    useEffect(() => {
        dispatch(GetMailingLatter({ OBJ, MLF01 }))
    }, [])

    useEffect(() => {
        if (hasError && !isLoading) {
            GlobalClass.Notify("error", "Error: " + ErrorMsg.code, ErrorMsg.message);
        }
    }, [hasError]);

    useEffect(() => {
        setData(MailingLetterData);
        setDataObj(MailingLetterData)
    }, [MailingLetterData])

    const cancelBtn = () => {
        dispatch(ModalDelete(props.index))
        Promise.resolve(dispatch(GetGeneralData())).then(() => dispatch(GetGeneralTMFormat()))
    }

    const saveBtn = () => {
        if (data?.LETTER.trim().length > 0) {
            dispatch(DataSaveLoading({ ["MailingLetter"]: true }));
            GlobalClass.Notify("info", OBJ.p0 == "A" ? "Adding" : "Editing", "Please wait while we process your data");

            var data1 = {
                CorpID: gensetting.getcorpid(),
                cUser: gensetting.getcuser(),
                cPass: gensetting.getcpass(),
                CmpNo: gensetting.getcmpno(),
                cAction: OBJ.p0,
                cCode: "",
                cOPara: "",
                cSData: JSON.stringify(data),
                YrNo: localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
            };

            let param = JSON.stringify({
                CorpID: gensetting.getcorpid(),
                cUser: gensetting.getcuser(),
                cPass: gensetting.getcpass(),
                CmpNo: gensetting.getcmpno(),
                cAction: OBJ.p0,
                cCode: "",
                cOPara: "",
            });

            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(data1),
            };

            if (OBJ.p0 == "E" && JSON.stringify(data) === JSON.stringify(dataObj)) {
                GlobalClass.Notify("success", "Edited", "Edited successfully");
                Promise.resolve(dispatch(DataSaveLoading({ ["MailingLetter"]: false })))
                    .then(() => dispatch(ModalDelete(props.index)));
            }
            else {
                fetch(GlobalClass.ApiUrl + GlobalClass.PostMailingLatter + "?pa=" + param, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.status === "SUCCESS") {
                            Promise.resolve(dispatch(DataSaveLoading({ ["MailingLetter"]: false })));
                            GlobalClass.Notify("success", OBJ.p0 == "A" ? "Added" : "Edited", OBJ.p0 == "A" ? "Added successfully" : "Edited successfully");
                            setData(dataObj)


                            if (OBJ.p0 == "E") {
                                dispatch(ModalDelete(props.index))
                                Promise.resolve(dispatch(GetGeneralData())).then(() => dispatch(GetGeneralTMFormat()))
                            }



                            // let PaginationObj = Store.getState().DataSaveLoadingSlice.PaginationObj;
                            // dispatch(GetAccountList({
                            //   id: "14950123",
                            //   name: "E-Commerce Operator",
                            //   p0: "MT",
                            //   p1: "T_G02",
                            //   p2: "",
                            //   p3: "",
                            //   type: "A",
                            //   pagination: PaginationObj["14950123"],
                            // })
                            // );
                        }
                        else if (data.status === "FAIL") {
                            Promise.resolve(dispatch(DataSaveLoading({ ["MailingLetter"]: false })));
                            GlobalClass.Notify("error", data?.status, data?.message);
                        }
                    })
                    .catch((e) => {
                        Promise.resolve(dispatch(DataSaveLoading({ ["MailingLetter"]: false })));
                        GlobalClass.Notify("error", "Error", `${e?.message}`);
                    });
            }
        }
        else {
            GlobalClass.Notify("warning", "Letter Name Cannot Be Empty")
        }
    }

    return (
        <>
            {
                hasError ?
                    <>
                        <Text>{ErrorMsg?.message}</Text>
                        <Button
                            onClick={() => {
                                if (OBJ) {
                                    dispatch(GetMailingLatter({ OBJ, MLF01 }))
                                }
                            }}
                        >
                            Retry
                        </Button>
                    </>
                    :
                    isLoading == true || SaveLoading["MailingLetter"] == true ? GlobalClass.RenderLoader("dots") :
                        <>
                            <Modal
                                opened={opened}
                                onClose={() => setOpened(false)}
                                title={<Text fw={700}>Format</Text>}
                                padding="xl"
                                size="lg"
                                position="right"
                            // open="true"
                            >
                                <FieldValueList obj={obj} setOpened={setOpened} setMsg={setMsg} setMsgValueProp={setMsgValueProp} />
                            </Modal>
                            <Grid>
                                <Grid.Col span={12} style={{ display: 'flex' }}>
                                    <Grid.Col span={6} style={{ display: 'flex' }} >
                                        <Grid.Col span={4}>
                                            <Text>Letter Name</Text>
                                        </Grid.Col>
                                        <Grid.Col span={8}>
                                            <PLTextBox value={data?.LETTER}
                                                setEdit={(e) => {
                                                    setData({ ...data, LETTER: e.target.value })
                                                }}
                                            />
                                        </Grid.Col>
                                    </Grid.Col>
                                    <Grid.Col span={6} style={{ display: 'flex' }}>
                                        <Grid.Col span={6} style={{ display: 'flex' }}>
                                            <Text mr={4}>Width</Text>
                                            <PLNumberBox size="xs" value={data?.NWD}
                                                decimalSeparator={true}
                                                precision={4}
                                                setEdit={(e) => {
                                                    setData({ ...data, NWD: e })
                                                }}
                                            />
                                            <Text ml={4}>Inch</Text>
                                        </Grid.Col>
                                        <Grid.Col span={6} style={{ display: 'flex' }}>
                                            <Text mr={4}>Height</Text>
                                            <PLNumberBox size="xs" value={data?.NHT}
                                                decimalSeparator={true}
                                                precision={4}
                                                setEdit={(e) => {
                                                    setData({ ...data, NHT: e })
                                                }} />
                                            <Text ml={4}>Inch</Text>
                                        </Grid.Col>
                                    </Grid.Col>
                                </Grid.Col>
                                <Grid.Col span={12}>
                                    <Textarea minRows={18} value={data?.DRAFT}
                                        onChange={(e) => {
                                            console.log("e", e);
                                            setData({ ...data, DRAFT: e.target.value })
                                        }}
                                    />
                                </Grid.Col>
                                <Grid.Col span={12}>
                                    <Button size="sm" variant="subtle" compact m={4} onClick={() => setOpened(true)}>Field Value</Button>
                                    <Button size="sm" variant="subtle" compact m={4} onClick={saveBtn}>Save</Button>
                                    <Button size="sm" variant="subtle" compact m={4} onClick={cancelBtn}>Cancel</Button>
                                </Grid.Col>
                            </Grid>
                        </>
            }
        </>
    )
}

export default MailingLetterForm
