import React from 'react'
import Store from '../../utils/store'
import ProductionForm from './ProductionForm'
import { ModalFunction } from '../../utils/slices/ModalSlice'
import { Text } from '@mantine/core'
import GlobalClass from '../../utils/GlobalClass'
import { TransactionDeleteApi } from '../../utils/TransactionSlices/Transaction'
import ProductionQuickEntryForm from './ProductionQuickEntryForm'
import { GetPreFillTransactionFormData } from '../../utils/TransactionSlices/TSalesSlices'

let PreFillFormData = {
  CashDebit: "",
  PartyAc: "",
  PartyAcName: "",
  DeliveryAt: "",
  DeliveryAtName: "",
  InvoiceType: "",
  BillDate: ""
}

export function VouEntAct(data) {
  const OBJ = JSON.parse(data)
  // console.log('production OBJ=>>',OBJ)
  if (OBJ.p0 == "A") {
    Store.dispatch(
      ModalFunction({
        onclose: () => { },
        MTitle: <Text fw={700}>{OBJ?.text} {OBJ?.BtnPnlObj?.name}</Text>,
        MAction: true,
        MSize: "98%",
        position: 'right',
        tab: true,
        onClickCloseButton: () => {
          Store.dispatch(GetPreFillTransactionFormData(PreFillFormData))
          // GetPendingOrderDataNull, GetSingleOrderRowNull
        },
        MBody: (i) => (
          <ProductionForm obj={OBJ} index={i} />
        ),
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        }
      })
    )
  } else if (OBJ.p0 == "Q") {
    Store.dispatch(
      ModalFunction({
        onclose: () => { },
        MTitle: <Text fw={700}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "80%",
        position: 'right',
        MBody: (i) => (
          <ProductionQuickEntryForm obj={OBJ} index={i} />
        ),
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        }
      })
    )
  } else if (OBJ.p0 == 'E' && OBJ.id) {
    Store.dispatch(
      ModalFunction({
        onclose: () => { },
        MTitle: <Text fw={700}>{OBJ?.text} {OBJ?.BtnPnlObj?.name}</Text>,
        MAction: true,
        MSize: "80%",
        position: 'right',
        tab: true,
        MBody: (i) => (
          <ProductionForm obj={OBJ} index={i} />
        ),
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        }
      })
    )
  } else if (OBJ.p0 == 'D' && OBJ.id) {
    // if (window.confirm('Confirm to Delete ?')) {
    //   Store.dispatch(TransactionDeleteApi(OBJ))
    // }

    GlobalClass.Confirm(
      "red",
      "Are you sure to delete selected data?",
      "It will be Deleted Forever and Cannot Be Recovered!!!",
      () => {
        Store.dispatch(TransactionDeleteApi(OBJ))
      },
      () => { },
      "Cancel",
      "Confirm"
    )
  } else if (OBJ.p0 === 'T' && OBJ?.id) { //Audit
    // if (window.confirm('Confirm to Audit ?')) {
    //     Store.dispatch(TransactionDeleteApi(OBJ))
    // }
    // GlobalClass.Confirm(
    //   "red",
    //   "Are you Sure to Audit This voucher?",
    //   "",
    //   () => {
    //     Store.dispatch(TransactionDeleteApi(OBJ))
    //   },
    //   () => { })

      GlobalClass.Confirm(
        "blue",
        "Confirm to Audit ?",
        "Are you sure you want to proceed!!!",
        () => {
          Store.dispatch(TransactionDeleteApi(OBJ))
        },
        () => { },
        "Cancel",
        "Confirm"
    )
  } else if (OBJ.p0 === 'U' && OBJ?.id) { // UserField
    Store.dispatch(ModalFunction({
      MTitle: <Text fw={700}>{OBJ?.text}</Text>,
      MAction: true,
      MSize: "50%",
      // position:'right',
      MBody: (i) => (<ProductionForm obj={OBJ} index={i} />),
      MClose: true,
      onclose: () => { },
      onClickCloseButton: () => {

      }
    }))
  } else {
    return null
  }
}