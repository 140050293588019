import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import Store from "../utils/store";
// import {
//   DeleteVNumField,
//   GetVouchNum,
//   GetVouchNumData,
// } from "../utils/slices/VoucherNumSlice";
import { MRT_TablePagination, MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { createColumnHelper } from "@tanstack/react-table";
import {
  Box,
  Button,
  Grid,
  Group,
  Skeleton,
  Text,
  createStyles,
} from "@mantine/core";
// import PLTextBox from "../PlusComponent/PLTextBox";
// import VoucherNumNew from "../MyChanges/FormOnVoucherPage/VoucherNumNew";
// import { ModalFunction } from "../utils/slices/ModalSlice";
import Store from "../../utils/store";
import {
  DeleteVNumField,
  GetVouchNum,
  GetVouchNumData,
  GetVouchNumField,
} from "../../utils/slices/VoucherNumSlice";
// import VoucherNumNew from "../../MyChanges/FormOnVoucherPage/VoucherNumNew";
import PLTextBox from "../../PlusComponent/PLTextBox";
import { ModalFunction } from "../../utils/slices/ModalSlice";
import ButtonPanel from "../../PlusComponent/ButtonPanel";
import GlobalClass from "../../utils/GlobalClass";
import VoucherNumberForm from "./VoucherNumberForm";
const useStyles = createStyles((theme) => ({
  button: {
    "&:disabled, &[data-disabled]": {
      // Add your custom styles for disabled buttons here
      borderColor: "0.0625rem solid transparent",
      backgroundColor: "#228be6",
      color: "white",
      cursor: "pointer",
      pointerEvents: "auto",
    },
  },
}));

const VoucherNumberGrid = ({ obj }) => {
  // console.log("VoucherNumberGrid==>", obj);

  const [columns, setColumns] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [search, setSearch] = useState("");
  const [editData, setEditData] = useState("");
  const [activeBtn, setActiveBtn] = useState(true);
  const [deleteBtn, setDelete] = useState(false);

  const [data, setData] = useState({});

  const columnHelper = createColumnHelper();
  const { classes } = useStyles();

  // console.log(obj, "obj.link");
  const {
    successMsg,
    VoucherNumTemp: VoucherNum,
    isLoading,
    VoucherNumdata: Vdata,
  } = useSelector((state) => state?.VoucherNum);
  // console.log(VoucherNum, "VoucherNum rerender");

  // let data = Vdata?.jData || [];

  useEffect(() => {
    const fetchData = async () => {
      await Store.dispatch(GetVouchNumData());
      await Store.dispatch(GetVouchNum());
    };
    fetchData();
  }, []);
  useEffect(() => {
    setData(Vdata?.jData || []);
  }, [Vdata, deleteBtn]);

  function handleRowDblClk(row, cell) {
    EditVoucherNum();
  }

  const table = useMantineReactTable({
    // data: Vdata ? data : [],
    data: data,
    columns,
    // enableRowSelection: true, //enable some features
    mantineTableProps: { withColumnBorders: true },
    enableColumnOrdering: true,
    enableGlobalFilter: true,
    enableDensityToggle: false,
    enableTopToolbar: false,
    initialState: {
      density: "xs",
      expanded: true,
    },
    state: {
      rowSelection,
      globalFilter: search,
    },

    enableRowVirtualization: true,
    mantineTableContainerProps: { sx: { maxHeight: "600px" } },
    enableSelectAll: false,
    enableMultiRowSelection: false,
    enableTableFooter: false,
    mantineTableBodyRowProps: ({ row, staticRowIndex, cell }) => ({
      onDoubleClick: () => {
        handleRowDblClk(row, cell);
      },
      onClick: () => {
        setActiveBtn(false);
        setEditData(row?.original?.FIELD00);
        setRowSelection((prev) => ({
          [row?.id]: !prev[row?.id],
        }));
      },

      selected: rowSelection[row?.id],
      sx: { cursor: "pointer" },
      height: 30,
    }),

    renderBottomToolbar: ({ table }) => (
      <Box>
        <Grid>
          <Grid.Col span={12}>
            <Group spacing={"xs"} position="center" mt={5} mb={5}>
              <Button
                size="xs"
                onClick={() => newVoucherNum()}
                // disabled={activeBtn ? false : true}
                variant="subtle"
                compact
              >
                New
              </Button>
              <Button
                size="xs"
                onClick={() => EditVoucherNum()}
                // disabled={activeBtn ? true : false} // discarded this logic because this is not in software 
                variant="subtle"
                // className={classes.button}
                compact
              >
                Edit
              </Button>
              <Button
                size="xs"
                onClick={() => DeleteVoucherNum()}
                // disabled={activeBtn ? true : false} // discarded this logic because this is not in software 
                // className={classes.button}
                variant="subtle"
                compact
              >
                Delete
              </Button>
            </Group>
          </Grid.Col>
          <Grid.Col span={12}>
            <Group
              spacing={"xs"}
              position="right"
              display={"flex"}
            >
              <MRT_TablePagination position="bottom" table={table} />
            </Group>
          </Grid.Col>
        </Grid>
      </Box>
    )
  });

  function newVoucherNum() {
    // console.log("OnNewBtn", obj);
    Store.dispatch(
      ModalFunction({
        MTitle: <Text fw={700}>Add</Text>,
        MAction: true,
        MSize: "30%",
        MBody: (i) => <VoucherNumberForm index={i} cAction="A" />,
        MClose: true,
        closeOnClickOutside: true,
        onclose: () => { },
      })
    );
  }
  function EditVoucherNum() {
    // console.log("OnNewBtn");
    Store.dispatch(
      ModalFunction({
        MTitle: <Text fw={700}>Edit</Text>,
        MAction: true,
        MSize: "30%",
        MBody: (i) => (
          <VoucherNumberForm idToEdit={editData} cAction="E" index={i} />
        ),
        MClose: true,
        onclose: () => { },
        closeOnClickOutside: true,
      })
    );
    setRowSelection(false);
  }
  function DeleteVoucherNum() {
    // if (window.confirm("Are you sure to delete selected data?")) {
    //   Store.dispatch(GetVouchNumField({ cAction: "D", cCode: editData }));
    // }
    GlobalClass.Confirm(
      "red",
      "Are you sure to delete selected data?",
      "It will be Deleted Forever and Cannot Be Recovered!!!",
      () => {
        Store.dispatch(GetVouchNumField({ cAction: "D", cCode: editData }));
      },
      () => { },
      "Cancel",
      "Confirm"
    )
    setRowSelection(false);
    return true;
  }

  useEffect(() => {
    if (Object.values(rowSelection)[0]) {
      setActiveBtn(false);
      // setClick(false)
    } else {
      setActiveBtn(true);
    }
  }, [rowSelection]);

  useEffect(() => {
    if (
      !isLoading &&
      VoucherNum &&
      VoucherNum?.oDCFrmt &&
      VoucherNum?.oDCFrmt?.aDCol
    ) {
      var cols = [];
      VoucherNum?.oDCFrmt?.aDCol?.map((v, i) => {
        cols.push(
          columnHelper.accessor(v.DispExpr, {
            header: v.ColCap,
            enableHiding: true,
            enableColumnFilter: v.lSearchCol,
            enableSorting: v.lColOrder,
            size: v.ColWidth,
            minSize: 0,
            maxSize: v.ColWidth,
            Cell: ({ cell }) => {
              var val = cell.getValue();
              return val;
            },
          })
        );
      });

      setColumns(cols);
    }
  }, [VoucherNum]);

  return (
    <>
      <Box>
        <Grid gutter={4}>
          {isLoading ? (
            GlobalClass.RenderLoader("dots")
          ) : (
            <>
              <Grid.Col span={4}>
                <PLTextBox
                  placeholder="Search here..."
                  value={search}
                  setEdit={(e) => {
                    setSearch(e.target.value);
                  }}
                />
              </Grid.Col>
              <Grid.Col span={12}>
                <MantineReactTable table={table} />
              </Grid.Col>

            </>
          )}
        </Grid>
      </Box>
    </>
  );
};

export default VoucherNumberGrid;
