import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GetCmpSetUpPopUp } from '../../utils/slices/CompanySetUpPopUpSlice'
import PLDataGrid from '../../PlusComponent/PLDataGrid'
import GlobalClass from '../../utils/GlobalClass'

const TickerDetail = (props) => {
    const { value, setEditSend, obj } = props
    // console.log('obj ticker=>>',props)
    const TickertDetailData = useSelector((state) => state.CompanySetupPopUp?.CmpSetUpPopUpData)
    // console.log('tickerData==>>', TickertDetailData)
    const { isLoading } = useSelector((state) => state.CompanySetupPopUp)
    const P_TICKERDT = useSelector((state) => state.CompanySetupPopUp.CmpSetUpPopUpData?.P_TICKERDT)
    const P_TICKERDTDT = useSelector((state) => state.CompanySetupPopUp.CmpSetUpPopUpData?.P_TICKERDTDT)

    const dispatch = useDispatch()

    useEffect(() => {
        if (obj) {
            dispatch(GetCmpSetUpPopUp(obj))
        }
    }, [obj])

    const seperate = (a) => {
        let val = a.split("#")
        return val.toString()
    }

    return (
        <div style={{ width: "100%" }}>
            {
                isLoading ? GlobalClass.RenderLoader("dots") :
                    TickertDetailData &&
                    <PLDataGrid
                    autoFocus={true}
                    openOnFocus={true}
                        dropdownPosition={true}
                        // width={"500px"}
                        dispexpr={"TICF02"}
                        valexpr={"TICF01"}
                        value={seperate(value)}
                        TmData={P_TICKERDT}
                        data={P_TICKERDTDT}
                        isMulti={true}
                        setEdit={(e) => {
                            // setEditSend(e.TICF01)
                            let val = [];
                            if (e?.length > 0) {
                                e?.map((m) => {
                                    val.push(m?.original?.TICF01)
                                })
                                // console.log('val=>', val);
                                const result = val.join("#")
                                // console.log("result",result)
                                setEditSend(result)
                            } else {
                                setEditSend("")
                            }
                        }}
                    />
            }
        </div>
    )
}

export default TickerDetail