import { Button, Grid, Group } from '@mantine/core';
import React, { useEffect, useState } from 'react'
import PLComboBox from '../../PlusComponent/PLComboBox';
import PLDataGrid from '../../PlusComponent/PLDataGrid';
import PLTextBox from '../../PlusComponent/PLTextBox';
import PLNumberBox from '../../PlusComponent/PLNumberBox';
import { GetAccountList, GetTMFormat } from '../../utils/slices/AccountListSlice';
import { useDispatch, useSelector } from 'react-redux';
import { ModalDelete } from '../../utils/slices/ModalSlice';

const SITableBatchUserField = (props) => {
    const { DTUFT11, T02ObjSend, setT02Objsend,PageObj } = props;

    const [batchUFObj, setBatchUFObj] = useState({})

    const dispatch = useDispatch();

    const { AccountListData: accList, TMFormatData: tmList, isLoading: AccNameisLoading } = useSelector((state) => state.AccountList)

    useEffect(() => {
        if (T02ObjSend) {
            setBatchUFObj(T02ObjSend)
        }
    }, [T02ObjSend])

    const seperate = (e) => {
        var paraArr = e?.split("~C~");
        console.log("paraArr=>", paraArr);
        return paraArr;
    };

    const map = new Map();
    const seperate1 = (e) => {
        var paraArr = e.split("_");
        // console.log("paraArr=>", paraArr);
        if (paraArr.length == 1) {
            paraArr[1] = paraArr[0];
        }
        return paraArr;
    };

    useEffect(() => {
        if (DTUFT11 && PageObj?.p0 == "E") {
            DTUFT11?.forEach((item) => {
                if (item?.S13F14 == "PLPOPUP") {
                    const key = seperate(item.S13F09)[1]; // Key
                    const value = batchUFObj?.[item.S13F02]; // Value
                    if (map.has(key)) {
                        // If it exists, add the value to the set/array of values for that key
                        map.get(key).add(value); // Use a Set to ensure uniqueness of values
                    } else {
                        map.set(key, new Set([value]));
                    }
                }
            });

            if (map?.size > 0) {
                map.forEach((value, key) => {
                    const dyValue = Array.from(value).length
                        ? "'" + Array.from(value).join("', '") + "'"
                        : "";
                    setTimeout(() => {
                        Promise.resolve(dispatch(
                            GetAccountList({
                                id: key,
                                name: "",
                                type: "A",
                                p0: key,
                                p1: key,
                                p2: "",
                                p3: "",
                                cWhere: `${seperate1(key)[1]}.FIELD01 IN (${dyValue})`,
                            })
                        )).then(() => {
                            dispatch(GetTMFormat({
                                "id": key,
                                "name": "",
                                "type": "A",
                                "p0": key,
                                "p1": key,
                                "p2": "TFORM0000001",
                                "p3": ""
                            }))
                        })
                    }, [500]);
                });
            }
        }
    }, [DTUFT11]);

    const BatchUserFieldRender = () => {
        let InputField;
        return (
            DTUFT11?.map((t) => {
                // console.log("t?.S13F14", t?.S13F14);
                // console.log([t?.S13F02], "UserFieldObj?.[t?.S13F02]")

                switch (t?.S13F14) {
                    case "NUMBOX":
                        InputField = <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
                            <Grid.Col span={4} style={{ fontSize: 12 }}>{t?.S13F07}</Grid.Col>
                            <Grid.Col span={8}>
                                <PLNumberBox
                                    value={batchUFObj?.[t?.S13F02]}
                                    setEdit={(e) => {
                                        setBatchUFObj({ ...batchUFObj, [t?.S13F02]: e })
                                    }}

                                />
                            </Grid.Col>
                        </Grid.Col>

                        break;
                    case "TEXTBOX":
                        InputField = <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
                            <Grid.Col span={4} style={{ fontSize: 12 }}>{t?.S13F07}</Grid.Col>
                            <Grid.Col span={8}>
                                <PLTextBox
                                    setOnFocus={() => {
                                            if (PageObj?.cSTID == "T41VLISTPPPP") {
                                                // ResolveObj = { ...e, ULO: e?.UBA }
                                                setBatchUFObj({ ...batchUFObj, ULO: T02ObjSend?.UBA })
                                            }
                                    }}
                                    value={batchUFObj?.[t?.S13F02]}
                                    setEdit={(e) => {
                                        setBatchUFObj({ ...batchUFObj, [t?.S13F02]: e.target.value })
                                    }}
                                />
                            </Grid.Col>
                        </Grid.Col>
                        break;
                    case "PLPOPUP":
                        InputField = <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
                            <Grid.Col span={4} style={{ fontSize: 12 }}>{t?.S13F07}</Grid.Col>
                            <Grid.Col span={8}>
                                <PLDataGrid
                                    // value={batchUFObj?.[t?.S13F02]}
                                    value={{
                                        value: batchUFObj?.[t?.S13F02],
                                        label: accList?.[seperate(t?.S13F09)[1]]?.filter((a) => a?.FIELD01 == batchUFObj?.[t?.S13F02])?.[0]?.[t?.S13F88 == "H" ? "FIELD01" : ("FIELD02" in (accList?.[seperate(t?.S13F09)[1]]?.filter((a) => a?.FIELD01 === batchUFObj?.[t?.S13F02])?.[0] || {}) ? "FIELD02":tmList[seperate(t?.S13F09)[1]]?.oS60?.cAssignFld)],
                                      }}
                                    setEdit={(e) => {
                                        console.log(e?.FIELD01, "e?.FIELD01")
                                        setBatchUFObj({ ...batchUFObj, [t?.S13F02]: e?.FIELD01 })
                                    }}
                                    p1={seperate(t?.S13F09)[1]}

                                />
                            </Grid.Col>
                        </Grid.Col>
                        break;
                    case "COMBOBOX":
                        InputField = <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
                            <Grid.Col span={4} style={{ fontSize: 12 }}>{t?.S13F07}</Grid.Col>
                            <Grid.Col span={8}>
                                <PLComboBox
                                    value={batchUFObj?.[t?.S13F02]}
                                    setEdit={(e) => {
                                        setBatchUFObj({ ...batchUFObj, [t?.S13F02]: e?.value })
                                    }}
                                />
                            </Grid.Col>
                        </Grid.Col>
                        break;
                    default:
                        InputField = null
                        break;
                }
                return InputField
            })
        )
    }

    const SaveBatchUserField = () => {
        Promise.resolve(setT02Objsend(batchUFObj)).then(() => dispatch(ModalDelete(props?.index)))
    }

    return (<>
        <Grid gutter={4}>
            {
                BatchUserFieldRender()
            }
            <Grid.Col span={12}>
                <Group spacing={'xs'} position='center'>
                    <Button
                        onKeyDown={(e) => {
                            if (e.key == "Enter") {
                                SaveBatchUserField()
                            }
                        }}
                        onClick={() => SaveBatchUserField()}>Save</Button>
                </Group>
            </Grid.Col>
        </Grid>

    </>)
}

export default SITableBatchUserField