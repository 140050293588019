import React from "react";
// import { ModalFunction } from '../utils/slices/ModalSlice';
import Store from "../../utils/store";
import AddUserField from "./AddUserField";
// import { GetM41UFList, deleteUserData } from '../utils/slices/CompanySetupSlice';
import { notifications } from "@mantine/notifications";
import { Text } from "@mantine/core";
// import UserMasterForm from '../../MyComponent/UserMaster/UserMasterForm';
// import UserMasterList from '../../MyComponent/UserMaster/UserMasterList';
import { ModalDelete, ModalFunction } from "../../utils/slices/ModalSlice";
import { PostM41UFEntStatus, deleteUserData } from "../../utils/slices/CompanySetupSlice";
import GlobalClass from "../../utils/GlobalClass";
import gensetting from "../../utils/gensetting";
import {
  GetUserMasterData,
  GetUserMasterFlag,
  GetUserMasterTableData,
  SetSaveLoading,
} from "../../utils/slices/UserMasterSlice";
import UserMasterForms from "../UserMaster/UserMasterForms";
import UserMasterListTable from "../UserMaster/UserMasterListTable";
import { DataSaveLoading } from "../../utils/slices/DataSaveLoadingSlice";

export const UserField = () => {
  return <div>UserField</div>;
};


//For User Field
export function M41UFAction(data, Skey) {
  const OBJ = JSON.parse(data);
  console.log("OBJJ M41UFAction", OBJ);

  if (OBJ.p0 === 'A') { // Add
    Store.dispatch(ModalFunction({
      MTitle: <Text fw={700}>{OBJ?.text}</Text>,
      MAction: true,
      MSize: 'xl',
      MBody: (i) => (<AddUserField obj={OBJ} index={i} />),
      MClose: true,
      onclose: () => {
        // Store.dispatch(ModalFunction({
        //     MTitle: '',
        //     MAction: false,
        //     MSize: 'md',
        //     MBody: () => (''),
        //     MClose: false
        // }))
      }
    }))

  } else if (OBJ.p0 === 'E' && OBJ?.M41UID) { // Edit

    Store.dispatch(ModalFunction({
      MTitle: <Text fw={700}>{OBJ?.text}</Text>,
      MAction: true,
      MSize: 'xl',
      MBody: (i) => (<AddUserField obj={OBJ} index={i} />),
      MClose: false,
      onclose: () => {
        // Store.dispatch(ModalFunction({
        //     MTitle: '',
        //     MAction: false,
        //     MSize: 'md',
        //     MBody: () => (''),
        //     MClose: false
        // }))
      }

    }))

  } else if (OBJ.p0 === 'D' && OBJ?.M41UID) { // Delete
    // if (window.confirm('Confirm to Delete ?')) {
    //   Promise.resolve(GlobalClass.Notify("info", "Info: " + "Delete Progress", "Please Wait Fetching Data")).then(() => Store.dispatch(deleteUserData({ action: OBJ.p0, code: OBJ.M41UID })))
    // }

    GlobalClass.Confirm(
      "red",
      "Are you sure to delete selected data?",
      "It will be Deleted Forever and Cannot Be Recovered!!!",
      () => {
        Promise.resolve(GlobalClass.Notify("info", "Info: " + "Delete Progress", "Please Wait Fetching Data")).then(() => Store.dispatch(deleteUserData({ action: OBJ.p0, code: OBJ.M41UID })))
      },
      () => { },
      "Cancel",
      "Confirm"
    )

  } else if (OBJ.p0 === 'N' && OBJ?.M41UID) { // Status (Enable & Disable)
    // if (window.confirm('Confirm to Status Change ?')) {
    //   Promise.resolve(GlobalClass.Notify("info", "Info: " + "Status Progress", "Please Wait Fetching Data")).then(()=>Store.dispatch(DataSaveLoading({["UserFieldSetup"]:true}))).then(() => Store.dispatch(PostM41UFEntStatus({ action: OBJ.p0, code: OBJ.M41UID, HEStatus:OBJ?.DataGridRow?.FIELD17 == "H" ? "E" : "H" })))
    // }

    GlobalClass.Confirm(
      "blue",
      "Confirm to Status Change ?",
      "Are you sure you want to proceed!!!",
      () => {
        Promise.resolve(GlobalClass.Notify("info", "Info: " + "Status Progress", "Please Wait Fetching Data")).then(() => Store.dispatch(DataSaveLoading({ ["UserFieldSetup"]: true }))).then(() => Store.dispatch(PostM41UFEntStatus({ action: OBJ.p0, code: OBJ.M41UID, HEStatus: OBJ?.DataGridRow?.FIELD17 == "H" ? "E" : "H" })))
      },
      () => { },
      "Cancel",
      "Confirm"
    )
  } else if (OBJ.p0 === 'H' && OBJ?.M41UID) { // Help
    // if (window.confirm('Confirm to Help Status Change ?')) {
    //   Promise.resolve(GlobalClass.Notify("info", "Info: " + "Help Status Progress", "Please Wait Fetching Data")).then(() => Store.dispatch(DataSaveLoading({ ["UserFieldSetup"]: true }))).then(() => Store.dispatch(PostM41UFEntStatus({
    //     action: OBJ.p0,
    //     code: OBJ.M41UID,
    //     HEStatus: OBJ?.DataGridRow?.FIELD11 == "N" ? "Y" : "N"
    //   })))
    // }

    GlobalClass.Confirm(
      "blue",
      "Confirm to Help Status Change ?",
      "Are you sure you want to proceed!!!",
      () => {
        Promise.resolve(GlobalClass.Notify("info", "Info: " + "Help Status Progress", "Please Wait Fetching Data")).then(() => Store.dispatch(DataSaveLoading({ ["UserFieldSetup"]: true }))).then(() => Store.dispatch(PostM41UFEntStatus({
          action: OBJ.p0,
          code: OBJ.M41UID,
          HEStatus: OBJ?.DataGridRow?.FIELD11 == "N" ? "Y" : "N"
        })))
      },
      () => { },
      "Cancel",
      "Confirm"
    )
  } else {
    return null
  }


}


//For User Master
export function M41UMAction(data, Skey) {
  const OBJ = JSON.parse(data);
  console.log("OBJJ M41UFAction user master", OBJ);
  if (OBJ.p0 === "A") { // Add (Usermaster & UserMasterList)
    Store.dispatch(
      ModalFunction({
        MTitle: <Text fw={700}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "50%",
        MBody: (i) => <UserMasterForms obj={OBJ} index={i} />,
        MClose: true,
        onclose: () => { },
      })
    );
  } else if (OBJ.p0 === "E" && OBJ.id) { // Edit (Usermaster & UsermasterList)
    Store.dispatch(
      ModalFunction({
        MTitle: <Text fw={700}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: OBJ.FormCode == "UMF" ? "50%" : "70%",
        MBody: (i) => <UserMasterForms obj={OBJ} index={i} />,
        MClose: true,
        onclose: () => { },
      })
    );
  } else if (OBJ.p0 === "Y") { // Double click to open Usermaster list
    Store.dispatch(
      ModalFunction({
        MTitle: <Text fw={700}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "70%",
        MBody: (i) => <UserMasterListTable obj={OBJ} index={i} />,
        MClose: true,
        onclose: () => { },
      })
    );
  } else if (OBJ.p0 === "D") { // Delete
    // console.log("delete OBJ==>", OBJ);
    // if (window.confirm("Confirm to Delete ?")) {
    //   GlobalClass.Notify(
    //     "info",
    //     "Deleting",
    //     "Please wait while we process your data"
    //   );
    //   let UserMasterTableData =
    //     Store.getState().UserMasterSlice.UserMasterTableData;
    //   if (OBJ.FormCode == "UMF") {
    //     let newArrayOne = UserMasterTableData?.M41UM.filter(
    //       (d) => d.FIELD01 != OBJ.DataGridRow.FIELD01
    //     );
    //     let newArrayTwo = UserMasterTableData?.M41UF.filter(
    //       (d) => d.FIELD01 != OBJ.DataGridRow.FIELD01
    //     );
    //     Promise.resolve(
    //       Store.dispatch(
    //         GetUserMasterTableData({ M41UM: newArrayOne, M41UF: newArrayTwo })
    //       )
    //     ).then(() =>
    //       GlobalClass.Notify("success", "Deleted", "Deleted successfully")
    //     );
    //   } else if (OBJ.FormCode == "UMLF") {
    //     let newArray = UserMasterTableData?.M41UF.filter(
    //       (d) => d.FIELD13 != OBJ.DataGridRow.FIELD13
    //     );
    //     Promise.resolve(
    //       Store.dispatch(
    //         GetUserMasterTableData({
    //           M41UM: [...UserMasterTableData.M41UM],
    //           M41UF: newArray,
    //         })
    //       )
    //     ).then(() =>
    //       GlobalClass.Notify("success", "Deleted", "Deleted successfully")
    //     );
    //   }
    // }
    // console.log("delete button click");

    GlobalClass.Confirm(
      "red",
      "Are you sure to delete selected data?",
      "It will be Deleted Forever and Cannot Be Recovered!!!",
      () => {
        GlobalClass.Notify(
          "info",
          "Deleting",
          "Please wait while we process your data"
        );
        let UserMasterTableData =
          Store.getState().UserMasterSlice.UserMasterTableData;
        if (OBJ.FormCode == "UMF") {
          let newArrayOne = UserMasterTableData?.M41UM.filter(
            (d) => d.FIELD01 != OBJ.DataGridRow.FIELD01
          );
          let newArrayTwo = UserMasterTableData?.M41UF.filter(
            (d) => d.FIELD01 != OBJ.DataGridRow.FIELD01
          );
          Promise.resolve(
            Store.dispatch(
              GetUserMasterTableData({ M41UM: newArrayOne, M41UF: newArrayTwo })
            )
          ).then(() =>
            GlobalClass.Notify("success", "Deleted", "Deleted successfully")
          );
        } else if (OBJ.FormCode == "UMLF") {
          let newArray = UserMasterTableData?.M41UF.filter(
            (d) => d.FIELD13 != OBJ.DataGridRow.FIELD13
          );
          Promise.resolve(
            Store.dispatch(
              GetUserMasterTableData({
                M41UM: [...UserMasterTableData.M41UM],
                M41UF: newArray,
              })
            )
          ).then(() =>
            GlobalClass.Notify("success", "Deleted", "Deleted successfully")
          );
        }
      },
      () => { },
      "Cancel",
      "Confirm"
  )
  } else if (OBJ.p0 === "W") { // User Master All Data Save
    // console.log("save button click");
    // if (window.confirm("Confirm to Save ?")) {
    //   Store.dispatch(SetSaveLoading(true));
    //   let PostObj = Store.getState().UserMasterSlice.UserMasterTableData;
    //   var data = {
    //     CorpID: gensetting.getcorpid(),
    //     cUser: gensetting.getcuser(),
    //     cPass: gensetting.getcpass(),
    //     CmpNo: gensetting.getcmpno(),
    //     cAction: "A",
    //     cCode: "",
    //     cOPara: "",
    //     cSData: JSON.stringify(PostObj),
    //     YrNo: localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
    //   };

    //   let param = JSON.stringify({
    //     CorpID: gensetting.getcorpid(),
    //     cUser: gensetting.getcuser(),
    //     cPass: gensetting.getcpass(),
    //     CmpNo: gensetting.getcmpno(),
    //     cAction: "A",
    //     cCode: "",
    //     cOPara: "",
    //   });
    //   const requestOptions = {
    //     method: "POST",
    //     headers: { "Content-Type": "application/json" },
    //     body: JSON.stringify(data),
    //   };
    //   fetch(
    //     GlobalClass.ApiUrl + GlobalClass.PostM41UMEnt + "?pa=" + param,
    //     requestOptions
    //   )
    //     .then((response) => response.json())
    //     .then((data) => {
    //       if (data.status == "SUCCESS") {
    //         Promise.resolve(
    //           GlobalClass.Notify(
    //             "success",
    //             "Success: " + "Save Data",
    //             "SuccessFully Save Data"
    //           )
    //         )
    //           .then(() => Store.dispatch(GetUserMasterData()))
    //           .then(() => Store.dispatch(ModalDelete(OBJ.ModalIndex)))
    //           .then(() => Store.dispatch(GetUserMasterFlag({ Flag1: true })))
    //           .then(() => Store.dispatch(SetSaveLoading(false)));
    //       } else if (data.status == "FAIL") {
    //         Promise.resolve(
    //           GlobalClass.Notify(
    //             "error",
    //             data?.status,
    //             data?.message
    //           )
    //         ).then(() => Store.dispatch(SetSaveLoading(false)));
    //       }
    //     })
    //     .catch((e) =>
    //       Promise.resolve(
    //         GlobalClass.Notify("error", "Error", `${e?.message}`)

    //       ).then(() => Store.dispatch(SetSaveLoading(false)))
    //     );
    // }

    GlobalClass.Confirm(
      "blue",
      "Confirm to Save ?",
      "Please confirm if you want to save the updates.",
      () => {
        Store.dispatch(SetSaveLoading(true));
        let PostObj = Store.getState().UserMasterSlice.UserMasterTableData;
        var data = {
          CorpID: gensetting.getcorpid(),
          cUser: gensetting.getcuser(),
          cPass: gensetting.getcpass(),
          CmpNo: gensetting.getcmpno(),
          cAction: "A",
          cCode: "",
          cOPara: "",
          cSData: JSON.stringify(PostObj),
          YrNo: localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
        };
  
        let param = JSON.stringify({
          CorpID: gensetting.getcorpid(),
          cUser: gensetting.getcuser(),
          cPass: gensetting.getcpass(),
          CmpNo: gensetting.getcmpno(),
          cAction: "A",
          cCode: "",
          cOPara: "",
        });
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        };
        fetch(
          GlobalClass.ApiUrl + GlobalClass.PostM41UMEnt + "?pa=" + param,
          requestOptions
        )
          .then((response) => response.json())
          .then((data) => {
            if (data.status == "SUCCESS") {
              Promise.resolve(
                GlobalClass.Notify(
                  "success",
                  "Success: " + "Save Data",
                  "SuccessFully Save Data"
                )
              )
                .then(() => Store.dispatch(GetUserMasterData()))
                .then(() => Store.dispatch(ModalDelete(OBJ.ModalIndex)))
                .then(() => Store.dispatch(GetUserMasterFlag({ Flag1: true })))
                .then(() => Store.dispatch(SetSaveLoading(false)));
            } else if (data.status == "FAIL") {
              Promise.resolve(
                GlobalClass.Notify(
                  "error",
                  data?.status,
                  data?.message
                )
              ).then(() => Store.dispatch(SetSaveLoading(false)));
            }
          })
          .catch((e) =>
            Promise.resolve(
              GlobalClass.Notify("error", "Error", `${e?.message}`)
  
            ).then(() => Store.dispatch(SetSaveLoading(false)))
          );
      },
      () => { },
      "Cancel",
      "Confirm"
  )
  }
}
