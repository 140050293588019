import React, { useEffect, useState } from "react";
import { Box, Button, Center, Divider, Grid, Text } from "@mantine/core";
import PLComboBox from "../../../../PlusComponent/PLComboBox";
import PLDataGrid from "../../../../PlusComponent/PLDataGrid";
import PLNumberBox from "../../../../PlusComponent/PLNumberBox";

export default function GraphFormat(props) {
  const { DrawerOBJ, setDrawerOBJ, GrpDT, ColDT, COPGPH, COPGPT, F14DICObj, setF14DICObj } = props
  const [graphObj, setGraphObj] = useState({
    COLRECIDX: "",
    DISPCAPX: "",
    COLRECIDY: "",
    DISPCAPY: "",
    Graphtypevalue: "",
    GrapthPositionvalue: "",
    width: "",
    Height: ""
  })

  useEffect(() => {
    if (F14DICObj?.S62_GRPH !== "") {
      let val = F14DICObj?.S62_GRPH?.split("~G~")
      console.log("separate_Val", val)
      setGraphObj({ ...graphObj, COLRECIDX: val[0], COLRECIDY: val[1], DISPCAPY: val[2], Graphtypevalue: val[3], GrapthPositionvalue: val[4], width: val[5], Height: val[6], DISPCAPX: val[7] })
    }
  }, [F14DICObj])

  const SaveGraph = () => {
    let value = `${graphObj.COLRECIDX}~G~${graphObj.COLRECIDY}~G~${graphObj.DISPCAPY}~G~${graphObj.Graphtypevalue}~G~${graphObj.GrapthPositionvalue}~G~${graphObj.width}~G~${graphObj.Height}~G~${graphObj.DISPCAPX}`;
    console.log("value", value)
    setF14DICObj({ ...F14DICObj, S62_GRPH: value })
    setDrawerOBJ({ ...DrawerOBJ, open: false })
  }
  return (
    <Box>
      <Grid gutter={4}>
        <Grid.Col
          span={12}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <Grid.Col span={4}>
            <Text style={{ fontSize: 12 }}>X-Axis Value</Text>
          </Grid.Col>
          <Grid.Col span={4}>
            <PLComboBox
              data={GrpDT}
              dispexpr="DISPCAP"
              valexpr="COLRECID"
              value={graphObj?.COLRECIDX}
              setEdit={(e) => {
                if (e) {
                  let val = ColDT?.find((v, i) => v.COLRECID == e)
                  console.log("val", val)
                  setGraphObj({ ...graphObj, COLRECIDX: e, DISPCAPX: val.DISPCAP })
                }
              }}
            />
          </Grid.Col>
        </Grid.Col>
        <Grid.Col
          span={12}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <Grid.Col span={4}>
            <Text style={{ fontSize: 12 }}>Y-Axis Value-1</Text>
          </Grid.Col>
          <Grid.Col span={4}>
            <PLComboBox
              data={ColDT}
              dispexpr="DISPCAP"
              valexpr="COLRECID"
              value={graphObj?.COLRECIDY}
              setEdit={(e) => {
                console.log("========>", e)
                if (e) {
                  let val = ColDT?.find((v, i) => v.COLRECID == e)
                  console.log("val", val)
                  setGraphObj({ ...graphObj, COLRECIDY: e, DISPCAPY: val.DISPCAP })
                }
              }}
            />
          </Grid.Col>
        </Grid.Col>
        <Grid.Col
          span={12}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <Grid.Col span={4}>
            <Text style={{ fontSize: 12 }}>Graph Type</Text>
          </Grid.Col>
          <Grid.Col span={4}>
            <PLComboBox
              data={COPGPH}
              dispexpr="COPF04"
              valexpr="COPF01"
              value={graphObj?.Graphtypevalue}
              setEdit={(e) => {
                setGraphObj({ ...graphObj, Graphtypevalue: e })
              }}
            />
          </Grid.Col>
        </Grid.Col>
        <Grid.Col
          span={12}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <Grid.Col span={4}>
            <Text style={{ fontSize: 12 }}>Position</Text>
          </Grid.Col>
          <Grid.Col span={4}>
            <PLComboBox
              data={COPGPT}
              dispexpr="COPF04"
              valexpr="COPF01"
              value={graphObj?.GrapthPositionvalue}
              setEdit={(e) => {
                setGraphObj({ ...graphObj, GrapthPositionvalue: e })
              }}
            />
          </Grid.Col>
        </Grid.Col>
        <Grid.Col span={6}>
          <Grid.Col
            span={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Grid.Col span={4}>
              <Text style={{ fontSize: 12 }}>Width%</Text>
            </Grid.Col>
            <Grid.Col span={6}>
              <PLNumberBox
                value={graphObj?.width}
                setEdit={(e) => {
                  setGraphObj({ ...graphObj, width: e })
                }}
              />
            </Grid.Col>
          </Grid.Col>
        </Grid.Col>
        <Grid.Col span={6}>
          <Grid.Col
            span={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Grid.Col span={4}>
              <Text style={{ fontSize: 12 }}>Height%</Text>
            </Grid.Col>
            <Grid.Col span={6}>
              <PLNumberBox
                value={graphObj?.Height}
                setEdit={(e) => {
                  setGraphObj({ ...graphObj, Height: e })
                }}
              />
            </Grid.Col>
          </Grid.Col>
        </Grid.Col>
        <Grid.Col
          span={12}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Button
            mr={4}
            size="xs"
            onClick={SaveGraph}
          >
            OK
          </Button>
          <Button size="xs">Remove</Button>
        </Grid.Col>
      </Grid>
    </Box>
  );
}
