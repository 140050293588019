import {
  ActionIcon,
  Box,
  Button,
  Center,
  Divider,
  Drawer,
  Flex,
  Grid,
  Group,
  Modal,
  Paper,
  Spoiler,
  Text,
  Textarea,
  Tooltip,
} from "@mantine/core";
import PLDateBox from "../../PlusComponent/PLDateBox";
import PLNumberBox from "../../PlusComponent/PLNumberBox";
import PLTextBox from "../../PlusComponent/PLTextBox";
import { MRT_TablePagination, MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { useEffect, useRef, useState } from "react";
import Store from "../../utils/store";
// import { setProcessName } from "../../utils/TransactionSlices/stockJournalSlice";
import { useDispatch, useSelector } from "react-redux";
import { createColumnHelper } from "@tanstack/react-table";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import PLComboBox from "../../PlusComponent/PLComboBox";
import PLDataGrid from "../../PlusComponent/PLDataGrid";
import GlobalClass from "../../utils/GlobalClass";
import { DataSaveLoading } from "../../utils/slices/DataSaveLoadingSlice";
import gensetting from "../../utils/gensetting";
import SITableUserField from "../SaleEntry/SITableUserField";
// import { modals } from "@mantine/modals";
import { ModalDelete } from "../../utils/slices/ModalSlice";
import {
  BatchLogAddRemoveFun,
  GetUserFieldObj,
  TransactionApi,
} from "../../utils/TransactionSlices/Transaction";
import PLNarration from "../../PlusComponent/PLNarration";
import {
  GetAccountList,
  GetTMFormat,
} from "../../utils/slices/AccountListSlice";
import PLTextarea from "../../PlusComponent/PLTextarea";
import SITableBatchUserField from "../SaleEntry/SITableBatchUserField";
import PLUserFieldBtn from "../../PlusComponent/PLUserFieldBtn";
import FormatListTable from "../SMSEmailWpBtn/FormatListTable";
import PLPrint from "../../FunctionsCall/Print/PLPrint";
import { ReverseCalCulation } from "../SaleEntry/EquationsFunctions";
import { Qty_CheckStk } from "../SaleEntry/QtyCheckStock";
import { TabRemove } from "../../utils/slices/TabList";
import PLColumnSelection from "../../PlusComponent/PLColumnSelection";

export default function StockJournalAddForm(props) {
  // console.log(props, "StockJournalAddForm props");
  const p0 = props.obj.p0;
  let dra = {
    title: "",
    size: "md",
    body: "",
    open: false,
    onClickCloseButton: () => { },
  };
  const TableContainer = useRef(null);
  const TableRef = useRef();
  const vouNoRef = useRef();
  let { index, obj } = props;
  const [group, setGroup] = useState({
    FIELD01: "IJPWZANWVPGV",
    FIELD02: "20240331",
    FIELD03: 0,
    FIELD04: "",
    FIELD05: "",
    FIELD06: "0",
    FIELD07: "0",
    FIELD08: 0,
    FIELD09: 0,
    FIELD10: "",
    FIELD11: "",
    FIELD12: "",
    FIELD13: "",
    FIELD14: "N",
    FIELD15: "",
    FIELD16: "",
    FIELD17: "",
    FIELD18: 0,
    FIELD21: "",
    FIELD22: "",
    FIELD23: "",
    FIELD30: "",
    FIELD31: "",
    FIELD32: "",
    FIELD33: "",
    FIELD70: "",
    FIELD71: "",
    FIELD75: "",
    FIELD99: "",
    FIELD72: "",
    FIELD20: 0,
    FIELD80: "",
    FIELD51: 0,
    FIELD34: "",
    FIELD35: "",
    FIELD36: "",
    FIELD37: "",
    FIELD38: "",
    FLDJIO: "",
    FIELD39: "",
    FIELD40: "",
    FIELD41: "",
    FIELD42: "",
    FIELD43: "",
    FIELD44: "",
    FIELD45: "",
    FIELD46: "",
    FIELD47: "",
    FIELD48: "",
    FIELD49: "",
    FIELD50: "",
    FIELD52: "",
    FIELD53: "",
    T41PVT: "IJ",
    FIELD54: "",
    T41VNO: "",
    CMID: 0,
    ADDID: 0,
    PRESCPATH: "",
    OSTATUS: "",
    DTYPE: "",
    DELURL: "",
    DELMBNO: "",
    FIELD55: "",
    FIELD56: "",
    T41POS: "",
    FLDUNQ: 0,
    FLDAED: "A",
    FLDBRC: "",
    UU73: "",
    EA00000001: "",
    EA00000002: "",
    EA00000003: "",
    EA00000004: "",
    EA00000005: "",
    EA00000006: "",
    EA00000007: "",
    EA00000008: "",
    EA00000009: "",
    EA00000010: "",
    EA00000011: "",
    EA00000012: "",
    EA00000013: "",
    EA00000014: "",
    EA00000015: "",
    ED00000001: 0,
    ED00000002: 0,
    ED00000003: 0,
    ED00000004: 0,
    ED00000005: 0,
    ED00000006: 0,
    ED00000007: 0,
    ED00000008: 0,
    ED00000009: 0,
    ED00000010: 0,
    ED00000011: 0,
    ED00000012: 0,
    ED00000013: 0,
    ED00000014: 0,
    ED00000015: 0,
    EP00000001: 0,
    EP00000002: 0,
    EP00000003: 0,
    EP00000004: 0,
    EP00000005: 0,
    EP00000006: 0,
    EP00000007: 0,
    EP00000008: 0,
    EP00000009: 0,
    EP00000010: 0,
    EP00000011: 0,
    EP00000012: 0,
    EP00000013: 0,
    EP00000014: 0,
    EP00000015: 0,
    PA000001: "",
    PA000002: "",
    PD000001: 0,
    PD000002: 0,
    UBILTO: "",
    UDISP: "",
    UEMAIL: "",
    UGSTR: "",
    ULEN: "",
    ULRD: "",
    UNAME: "",
    UOD: "",
    UODD: "",
    UODP: "",
    UOP: "",
    UPAYT: "",
    UPR: "",
    URA: "",
    UREMA: "",
    UREST6: "",
    USAL: 0,
    USALM: "",
    UTR: "",
    UTRA: "",
    UUAASS01: "",
    UUASWER: "",
    UUFR4: "",
    UUJA: "",
    UUJBWID: "",
    UUJWTRID: "",
    UUPRDID: "",
    UUPRODC: "",
    UUPRTR: "",
    UUSALM: "",
    UUT: "",
    UUT78: "",
    UUTPR: "",
    UUUASD09: "",
    UUUASWER: "",
    UUUJBOS: "",
    UUUJOB: "",
    UUUOS: "",
    UU05: "",
    UU87: "",
    UUISSUID: "",
    UUE: "",
    EA00000016: "",
    ED00000016: 0,
    EP00000016: 0,
    EA00000017: "",
    ED00000017: 0,
    EP00000017: 0,
    EA00000018: "",
    ED00000018: 0,
    EP00000018: 0,
    EA00000019: "",
    ED00000019: 0,
    EP00000019: 0,
    EA00000020: "",
    ED00000020: 0,
    EP00000020: 0,
    EA00000021: "",
    ED00000021: 0,
    EP00000021: 0,
    EA00000022: "",
    ED00000022: 0,
    EP00000022: 0,
    UUABCD: "",
    UUGFEJH: "",
    UUTEST56: "",
    EA00000023: "",
    ED00000023: 0,
    EP00000023: 0,
    UUSALESB: "",
    EA00000024: "",
    ED00000024: 0,
    EP00000024: 0,
    DSDATE: "2023-04-01T00:00:00+03:00",
    DEDATE: "2024-03-31T00:00:00+02:00",
    CACTION: "A",
    lInvType: false,
    lMTaxEnt: false,
    T92F00: "",
    FIELD04NM: "",
    CFRMTITLE: "Add Stock Journal",
    BTOBEFF: " ",
    M01BAL: "0",
    M01GRP: "",
    M01OPPGRP: "",
    M01CT: "",
    F07ACC: "",
    LBLF08AMT: "0.00   ",
    LBLF07AMT: "0.00   ",
    LBLF08SYMB: null,
    LBLF07SYMB: null,
  });
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 25,
  });
  const [t02Data, setT02Data] = useState([]);
  const [t02DataENT, setT02DataENT] = useState([]);
  const [columns, setColumns] = useState([]);
  const [receiptQty, setReceiptQty] = useState(0);
  const [issueQty, setIssueQty] = useState(0);
  const [issueAmt, setIssueAmt] = useState(0);
  const [receiptAmt, setReceiptAmt] = useState(0);
  const [editT41, setEditT41] = useState({});
  const [editT02, setEditT02] = useState({});
  const [BatchIssueObj, setBatchIssueObj] = useState(null);
  const [recIss, setRecIss] = useState("");
  const [LocationObj, setLocationObj] = useState(null);
  const [creatingModeOn, setCreatingModeOn] = useState(false);
  // const [productData, setselectedProductObj] = useState();
  const [selectedProductObj, setSelectedProductObj] = useState({});

  const [productDisable, setProductDisable] = useState(false);
  // const [flag, setFlag] = useState(false);
  const [t02Obj, setT02Obj] = useState({
    FIELD01: "",
    FIELD02: "",
    FIELD03: "",
    FIELD04: "",
    FIELD05: "",
    FIELD06: 0.0,
    FIELD07: 0.0,
    FIELD08: 0.0,
    FIELD09: 0.0,
    FIELD10: 0.0,
    FIELD11: "",
    FIELD12: "",
    FIELD56: "",
    FIELD13: "",
    FIELD14: "",
    FIELD15: "",
    FIELD16: "",
    FIELD17: "",
    FIELD18: "",
    FIELD19: "",
    FIELD20: "",
    FIELD21: 0.0,
    FIELD22: 0.0,
    FIELD23: 0.0,
    FIELD24: "",
    FIELD25: 0.0,
    FIELD26: "",
    FIELD27: "",
    FIELD28: false,
    FIELD29: 0.0,
    FIELD30: "",
    FIELD31: 0.0,
    FIELD32: 0.0,
    FIELD33: 0.0,
    FIELD41: "",
    FIELD50: 0.0,
    FIELD51: "",
    FIELD52: 0.0,
    FIELD71: "",
    FIELD81: 0.0,
    FIELD82: "",
    FIELD83: "",
    FIELD84: "",
    FIELD85: "",
    FIELD86: "",
    FIELD61: 0.0,
    FIELD62: 0.0,
    FIELD63: "",
    FIELD64: 0.0,
    FIELD65: "",
    FIELD66: 0.0,
    FIELD67: 0.0,
    FIELD72: "",
    FIELD68: "",
    FIELD69: "",
    FIELD53: "",
    FIELD54: 0.0,
    FIELD55: 0.0,
    FIELD091: 1.0,
    FIELD58: "",
    FIELD57: "",
    FIELD60: 0.0,
    T41PVT: "",
    FIELD87: 0.0,
    FIELD88: "",
    UGRO: 0.0,
    URATE: 0.0,
    UMUR: 0.0,
    UPE: 0.0,
    UAP: 0.0,
    URE: "",
    UISSUERA: 0.0,
    FLDUNQ: 0,
    FLDAED: "",
    FLDBRC: "",
    UUDIS: "",
    UUDISAAA: "",
    UUGROAAA: "",
    UUGR01: "",
    UUGRO: "",
    IA00000001: "",
    IA00000002: "",
    IA00000003: "",
    IA00000004: "",
    IA00000005: "",
    IA00000006: "",
    IA00000007: "",
    IA00000008: "",
    IA00000009: "",
    IA00000010: "",
    IA00000011: "",
    IA00000012: "",
    IA00000013: "",
    IA00000014: "",
    IA00000015: "",
    IA00000016: "",
    IA00000017: "",
    IA00000018: "",
    IA00000019: "",
    IA00000020: "",
    IA00000021: "",
    IA00000022: "",
    IA00000023: "",
    ID00000001: 0.0,
    ID00000002: 0.0,
    ID00000003: 0.0,
    ID00000004: 0.0,
    ID00000005: 0.0,
    ID00000006: 0.0,
    ID00000007: 0.0,
    ID00000008: 0.0,
    ID00000009: 0.0,
    ID00000010: 0.0,
    ID00000011: 0.0,
    ID00000012: 0.0,
    ID00000013: 0.0,
    ID00000014: 0.0,
    ID00000015: 0.0,
    ID00000016: 0.0,
    ID00000017: 0.0,
    ID00000018: 0.0,
    ID00000019: 0.0,
    ID00000020: 0.0,
    ID00000021: 0.0,
    ID00000022: 0.0,
    ID00000023: 0.0,
    IP00000001: 0.0,
    IP00000002: 0.0,
    IP00000003: 0.0,
    IP00000004: 0.0,
    IP00000005: 0.0,
    IP00000006: 0.0,
    IP00000007: 0.0,
    IP00000008: 0.0,
    IP00000009: 0.0,
    IP00000010: 0.0,
    IP00000011: 0.0,
    IP00000012: 0.0,
    IP00000013: 0.0,
    IP00000014: 0.0,
    IP00000015: 0.0,
    IP00000016: 0.0,
    IP00000017: 0.0,
    IP00000018: 0.0,
    IP00000019: 0.0,
    IP00000020: 0.0,
    IP00000021: 0.0,
    IP00000022: 0.0,
    IP00000023: 0.0,
    IT00000001: "",
    IT00000002: "",
    IT00000003: "",
    IT00000004: "",
    IT00000005: "",
    IT00000006: "",
    IT00000007: "",
    IT00000008: "",
    IT00000009: "",
    IT00000010: "",
    IT00000011: "",
    IT00000012: "",
    IT00000013: "",
    IT00000014: "",
    IT00000015: "",
    IT00000016: "",
    IT00000017: "",
    IT00000018: "",
    IT00000019: "",
    IT00000020: "",
    IT00000021: "",
    IT00000022: "",
    IT00000023: "",
    UDIS: 0.0,
    UUOS: "",
    UT76: "",
    USERFLD: "1",
    USERFLDB: "2",
    SRDETAIL: "",
    EXPDUMMY: " ",
    BARCODE: "",
    OLDPRD: "",
    OLDQTY: 0.0,
    OQTY2: 0.0,
    OLDRATE: 0.0,
    LADDNEW: "Y",
    PRICELIST: " ",
    _BATSTOCK: " ",
    _LOCWISE: " ",
    LDUALSTK: " ",
    SRNOSTK: " ",
    MRPSTK: " ",
    RORI: " ",
    ORDDUMMY: " ",
    JODUMMY: " ",
    SRIDUMMY: " ",
    SRRDUMMY: " ",
    SRIDUMMYF: " ",
    SRRDUMMYF: " ",
    SRISSCD: "",
    OLDSRNO: "",
    SCNOCL: "",
    SCNOCL1: "",
    MANUDATE: "",
    EXPDATE: "",
    _EXPTOTALAMT: 0.0,
  });
  const [FIELD01Value, setFIELD01value] = useState("");
  const [NarrationData, setNarrationData] = useState([]);
  const [SelectedNarrationData, setSelectedNarrationData] = useState([]);
  const [priceListParemeter, setPriceListParemeter] = useState({
    priceListCode: "",
    productCode: "",
    accountCode: "",
  });
  const [hasFocused, setHasFocused] = useState(false);
  const [BillSeriesHideShow, setBillSeriesHideShow] = useState({
    BillNoVal: "",
    TextInput: false,
    DataGridInput: true,
  });
  const [BillNoStatus, setBillNoStatus] = useState(false)
  const [GroupCatogaryProduct, setGroupCatogaryProduct] = useState({
    Group: "",
    Catogary: "",
    Product: "",
  });
  const [dayVal, setDayVal] = useState("");
  const [tableEditingRow, setTableEditingRow] = useState(null);
  const [enableOk, setEnableOk] = useState(false);


  const [DrawerObj, setDrawerObj] = useState(dra);
  const [rowSelection, setRowSelection] = useState({});
  const [f02, setF02] = useState(false);
  const [vDate, setVDate] = useState("");
  const [process, setProcess] = useState({});
  let editData = props.obj;
  let editId = editData?.id;
  // console.log(editId, "editData props");
  const dispatch = useDispatch();

  const columnHelper = createColumnHelper();
  const prCt = useRef(null);
  const prt = useRef(null);
  const grpRef = useRef(null);
  const {
    TransactionData: dataEnt,
    isLoading,
    hasError,
    ErrorMsg,
  } = useSelector((state) => state?.Transaction);
  const { isUserFieldLoading, UserField } = useSelector(
    (store) => store?.Transaction
  );
  const { oYear } = useSelector((state) => state?.Language?.General);
  const footerDate = useSelector((state) => state?.Language?.footerDate);
  // console.log("footerDate",footerDate);
  const formRef = useRef(null);
  const processRef = useRef();
  // const vouRef = useRef(null);
  const GetYearAndDate = useSelector(
    (state) => state?.UserList?.YearAndDateSend
  );
  const T41 = dataEnt?.T41;
  const P_M71DT = dataEnt?.P_M71DT?.jData;
  const T02UF = dataEnt?.T02UF;
  const dtUFT11 = dataEnt?.dtUFT11;
  const T92 = dataEnt?.T92;
  const T92DT = dataEnt?.T92DT;
  const UFT41H = dataEnt?.UFT41H;
  const lEditVou = dataEnt?.lEditVou;
  const PLDATA = dataEnt?.PLDATA;
  const OS11 = dataEnt?.OS11;
  const P_M71 = dataEnt?.P_M71;
  const OSV = dataEnt?.OSV
  // console.log(dataEnt, "dataEntt02Data---");
  const SaveLoading = useSelector(
    (state) => state?.DataSaveLoadingSlice?.SaveLoading
  );
  const {
    AccountListData: accList,
    TMFormatData: tmList,
    isLoading: AccNameisLoading,
  } = useSelector((state) => state.AccountList);
  const inputRef = useRef();
  const T02 = dataEnt?.T02;
  const map = new Map();
  // const { processName } = useSelector((state) => state.StockJournalData);
  let UFT41Hval = UFT41H?.filter((v) => v?.S13F87 == "HEADER");
  let UFT41Fval = UFT41H?.filter((v) => v?.S13F87 == "FOOTER");

  let stringOfNames;

  // console.log(rowIssQty, "rowQty", rowRecQty);
  const spoilerControlRef = useRef();
  useEffect(() => {
    if (SelectedNarrationData?.length > 0) {
      const stringifyArray = SelectedNarrationData?.map((a) => a.FIELD01);
      stringOfNames = stringifyArray.join(" ");
      console.log("stringOfNames", stringOfNames);
    }
    setGroup({
      ...group,
      FIELD15:
        group?.FIELD15 != ""
          ? group?.FIELD15 + " " + stringOfNames
          : stringOfNames,
    });
  }, [stringOfNames, SelectedNarrationData]);

  let ReceiptArray = t02Data?.filter((d, i) => d?.FIELD05 == "C");
  let IssueArray = t02Data?.filter((d, i) => d?.FIELD05 == "D");

  let recieptAmountSum = ReceiptArray?.reduce(
    (a, b) => (a = a + b?.FIELD08),
    0
  );
  let IssueAmountSum = IssueArray?.reduce((a, b) => (a = a + b?.FIELD08), 0);
  // console.log('recieptAmount==>>',recieptAmountSum)
  // console.log('IssueAmountSum=>>',IssueAmountSum)


  //pre filled fields

  useEffect(() => {
    if (Object.keys(process).length != 0 && obj?.p0 == "A" && group?.FIELD04 == "") {
      setGroup({
        ...group, FIELD04: process.FIELD01,
        FIELD04NM: process.FIELD02
      })
    }
  }, [process, group?.FIELD04])

  useEffect(() => {
    if (vDate.length > 0 && obj?.p0 == "A") {
      setGroup({ ...group, FIELD02: vDate })
    }
  }, [vDate, group?.FIELD02])

  // reciept amount & issue amount
  useEffect(() => {
    if (recieptAmountSum >= 0 && IssueAmountSum >= 0) {
      setGroup({
        ...group,
        FIELD06: recieptAmountSum,
        FIELD07: IssueAmountSum,
      });
    }
  }, [recieptAmountSum, IssueAmountSum]);

  // reciept Qty sum
  let ReceiptQtySum = ReceiptArray?.reduce((a, b) => (a = a + b?.FIELD33), 0);
  console.log("ReceiptQtySum", ReceiptQtySum);

  // issue Qty sum
  let IssueQtySum = IssueArray?.reduce((a, b) => (a = a + b?.FIELD33), 0);
  console.log("IssueQtySum", IssueQtySum);
  const rowSum = t02Data.reduce((a, b) => {
    if (b?.FIELD05 === "C") {
      a = a + (b?.FIELD08 || 0);
    } else if (b?.FIELD05 === "D") {
      a = a - (b?.FIELD08 || 0);
    }
    return a;
  }, 0);

  let QtySum = t02Data.reduce((a, b) => {
    // (a = a + b?.FIELD33)
    if (b?.FIELD05 === "C") {
      a = a + (b?.FIELD33 || 0);
    } else if (b?.FIELD05 === "D") {
      a = a - (b?.FIELD33 || 0);
    }
    return a;
  }, 0);

  useEffect(() => {
    // if (editData?.p0 == "E") {
    //   // console.log("Fetching Edit Data", p0);
    //   Store.dispatch(TransactionApi({ p0: p0, id: editId })).then((data) => {
    //     // console.log(data?.payload, "setEditData");
    //     setEditT41(data?.payload?.T41);
    //     setEditT02(
    //       data?.payload?.T02?.filter(
    //         (t) => t?.FIELD03 != "" && t?.FIELD03 != null
    //       )
    //     );
    //   });
    // } else {
    // console.log("EnterDispa");
    Promise.resolve(Store.dispatch(TransactionApi({ ...obj, p0: obj.p0 == "U" ? "E" : obj.p0 }))
      .then(() => dispatch(GetAccountList({ ...obj, id: "P_M71", p1: "P_M71", cWhere: `M71.FIELD03 = '' AND M71.FIELD06 = 'I' ` }))))
    // .then(() =>
    //   dispatch(
    //     GetAccountList({
    //       id: "T_M32C",
    //       name: "",
    //       type: "A",
    //       p0: "T_M32C",
    //       p1: "T_M32C",
    //       p2: "",
    //       p3: "",
    //     })
    //   )
    // )
    // .then(() =>
    //   dispatch(
    //     GetTMFormat({
    //       id: "T_M32C",
    //       name: "",
    //       type: "A",
    //       p0: "T_M32C",
    //       p1: "T_M32C",
    //       p2: "",
    //       p3: "",
    //     })
    //   )
    // );
    // });
    // .then(() =>
    //   dispatch(
    //     GetAccountList({
    //       id: "P_M21",
    //       name: "",
    //       type: "A",
    //       p0: "P_M21",
    //       p1: "P_M21",
    //       p2: "",
    //       p3: "",
    //     })
    //   )
    // )
    // .then(() =>
    //   dispatch(
    //     GetTMFormat({
    //       id: "P_M21",
    //       name: "",
    //       type: "A",
    //       p0: "P_M21",
    //       p1: "P_M21",
    //       p2: "",
    //       p3: "",
    //     })
    //   )
    // );
    // Store.dispatch(setProcessName());
    // }
  }, [obj]);

  useEffect(() => {
    if (UFT41H && p0 == "E") {


      UFT41H?.forEach((item) => {
        if (item?.S13F14 == "PLPOPUP") {
          const key = seperate(item.S13F09)[1]; // Key
          const value = T41?.[item.S13F02]; // Value
          // }
          if (map.has(key)) {
            // If it exists, add the value to the set/array of values for that key
            map.get(key).add(value); // Use a Set to ensure uniqueness of values

          }
          else {
            map.set(key, new Set([value]));
          }
        }

      })

      if (map?.size > 0) {
        map.forEach((value, key) => {
          const dyValue = Array.from(value).length ? ("'" + Array.from(value).join("', '") + "'") : '';
          setTimeout(() => {
            Promise.resolve(() => {
              dispatch(
                GetAccountList({
                  id: key,
                  name: "",
                  type: "A",
                  p0: key,
                  p1: key,
                  p2: "",
                  p3: "",
                  cWhere: `${seperate1(key)[1]}.FIELD01 IN (${dyValue})`,
                })
              );
            }).then(() => {
              dispatch(GetTMFormat({
                "id": key,
                "name": "",
                "type": "A",
                "p0": key,
                "p1": key,
                "p2": "TFORM0000001",
                "p3": ""
              }))
            })
          }, [500])
        })
      }




    }
  }, [UFT41H])

  useEffect(() => {
    if (hasError && !isLoading) {
      GlobalClass.Notify("error", "Error: " + ErrorMsg.code, ErrorMsg.message);
      // dispatch(ModalDelete(props.index))
    }
  }, [hasError, isLoading]);
  useEffect(() => {

    setGroup({
      ...dataEnt?.T41,
      FIELD02: vDate?.length > 0 && p0 == "A" ? vDate : dataEnt?.T41?.FIELD02,
      FIELD06: parseFloat(dataEnt?.T41?.FIELD06),
      FIELD07: parseFloat(dataEnt?.T41?.FIELD07),
    });
    setT02DataENT(dataEnt?.T02ENT);
  }, [dataEnt]);
  useEffect(() => {
    if (T02) {
      // setT02Data(T02?.filter((t) => t?.FIELD03 != "" && t?.FIELD03 != null));
      setT02Data(T02);
      if (props?.p0 == "E" && T02?.[T02?.length - 1]?.EXPDATE == "") {
        let { startDate } = DateToString(new Date());
        setT02Obj({ ...T02?.[T02?.length - 1], EXPDATE: startDate });
      } else {
        setT02Obj(T02?.[0]);
      }
    }
  }, [T02]);
  // useEffect(() => {
  //   // console.log(BatchIssueObj , "BatchIssueObj")
  //   // if (BatchIssueObj != null)
  //   setT02Obj({
  //     ...t02Obj,
  //     FIELD11: BatchIssueObj?.FIELD01 ?? t02Obj?.FIELD11,
  //     FIELD41: BatchIssueObj?.FIELD02 ?? "",
  //   });
  //   // else setT02Obj({ ...t02Obj, FIELD11: "" });
  // }, [BatchIssueObj, t02Obj?.FIELD11]);

  useEffect(() => {
    console.log(t02Obj?.FIELD11, "t02Obj-BatchIssueObj", BatchIssueObj)
    if (t02Obj?.FIELD05 == "D") {
      setT02Obj({
        ...t02Obj,
        FIELD11: BatchIssueObj?.FIELD01 ?? t02Obj?.FIELD11,
        FIELD41: BatchIssueObj?.FIELD02 ?? "",
      });
    } else {
      setT02Obj({
        ...t02Obj,
        FIELD11: BatchIssueObj?.FIELD01 ?? t02Obj?.FIELD11,
      });
    }
  }, [t02Obj?.FIELD05, BatchIssueObj, t02Obj?.FIELD11]);


  useEffect(() => {
    // if (p0 == "A") {
    Promise.resolve().then(() => {
      setT02Obj({
        ...t02Obj,
        FIELD03: selectedProductObj?.FIELD01 ?? t02Obj?.FIELD03,
        F03NM: selectedProductObj?.FIELD02 ?? t02Obj?.F03NM,
        FIELD22:
          selectedProductObj?.FIELD28 == 0
            ? 1
            : selectedProductObj?.FIELD28 ?? t02Obj?.FIELD28,
        FIELD07:
          recIss == "C"
            ? selectedProductObj?.FIELD21
            : recIss == "D"
              ? selectedProductObj?.FIELD21
              : selectedProductObj?.FIELD22,
      });
    });
    // }
  }, [recIss, selectedProductObj]);
  useEffect(() => {
    if (LocationObj !== null) {
      Promise.resolve().then(() => {
        setT02Obj({
          ...t02Obj,
          FIELD20: LocationObj?.FIELD01 ?? "",
          F20NM: LocationObj?.FIELD02,
        });
      });
    }
  }, [LocationObj, t02Obj?.FIELD20]);

  useEffect(() => {
    if (hasFocused) {
      inputRef?.current?.handlePopoverToggle();
      setHasFocused(false);
      // setHasFocused2(true);
    }
  }, [hasFocused]);

  const seperate = (e) => {
    var paraArr = e.split("~C~");
    console.log("paraArr=>", paraArr);
    if (paraArr.length == 1) {
      paraArr[1] = paraArr[0];
    }
    return paraArr;
  };

  const seperate1 = (e) => {
    var paraArr = e.split("_");
    // console.log("paraArr=>", paraArr);
    if (paraArr.length == 1) {
      paraArr[1] = paraArr[0];
    }
    return paraArr;
  };

  useEffect(() => {
    if (creatingModeOn == true) {
      // console.log(OS11?.S11F22, "OS11?.S11F22")
      setT02Obj({
        ...t02Obj,
        FIELD05: "R,C".includes(OS11?.S11F22) ? "C" : "D",
      });
    }
  }, [creatingModeOn, OS11?.FIELD22]);
  useEffect(() => {
    // console.log(OS11?.S11F01, "OS11?.S11F01=")
    if (OS11?.S11F01 && obj?.p0 == "U") {
      dispatch(GetUserFieldObj(OS11?.S11F01));
    }
  }, [OS11?.S11F01, obj]);
  function stringToDate(date) {
    let month = date.substring(4, 6);
    let year = date.substring(0, 4);
    let day = date.substring(6, 8);
    let modifyDate = day + "/" + month + "/" + year;
    return modifyDate;
  }

  function dateFormater(date) {
    let year = date.toLocaleString("en-IN", { year: "numeric" });
    let month = date.toLocaleString("en-IN", { month: "2-digit" });
    let day = date.toLocaleString("en-IN", { day: "2-digit" });
    let date1 = year + month + day;
    return date1;
  }

  const NagativeQtyPopUpFun = (e) => {
    GlobalClass.Notify("warning", "Warning", e)
  }
  let ExpenseObj = {
    OS11,
    OSV,
    oYear,
    ItemCode: selectedProductObj?.FIELD03,
    selectedProductObj,
    T02Obj: { ...t02Obj, VorC: group?.FIELD45 },
    group,
    t02Data
  }
  function renderValue(uID, v, c, cellid, index, column) {
    // if (column?.columnDef?.header == "Product Name" && p0 == "E") {
    //   return t02Obj?.F03NM;
    // }

    if (c?.S53F07 == 0) {
      // console.log(c?.S53F14, "c?.S53F14", c?.S53F03);
      return (
        <Button
          id={`T02Ent_${index}`}
          variant="default"
          onFocus={() => {
            document.activeElement.blur();
            // console.log("Test=>", `T02Ent_${index}`);
            HiddenButtonField(uID, v, c, cellid, index);
          }}
          onClick={() =>
            HiddenButtonField(uID, v, c, cellid, index)
          }
          // style={{ padding: "0px", margin: "0px" }}
          style={{ margin: "0px", padding: "0px" }}
        />
      );
    } else {
      let setInput;
      switch (c?.S53F14) {
        case "NUMBOX":
          if (c?.S53F02 == "E00FIELD08") {
            console.log(index, "AmountOO");
            return (
              <PLNumberBox
                decimalSeparator={true}
                precision={2}
                onKeyDown={(e) => {
                  console.log("Esc key Press", e);
                  if (e.key == "Enter" || e.key == "Tab") {
                    // console.log("Enter key Press",e)
                    if (tableEditingRow == null) {
                      // console.log("Add Product Call", t02Obj?.FIELD03);
                      if (t02Obj?.FIELD03 == null || t02Obj?.FIELD03 == "") {
                        GlobalClass.Notify(
                          "warning",
                          "Warning",
                          "Some Field Require to Enter"
                        );
                      } else {
                        handleCreateUser()
                        // .then(() => {

                        // });
                      }
                    } else {
                      console.log("Edit Product Call");
                      if (t02Obj?.FIELD03 == null || t02Obj?.FIELD03 == "") {
                        GlobalClass.Notify(
                          "warning",
                          "Warning",
                          "Some Field Require to Enter"
                        );
                      } else {
                        handleSaveUser()
                      }
                    }
                  }
                  // else if (e.key == "Escape") {
                  //   console.log("Esc key Press", e);
                  //   rowCancelHandler();
                  //   table.setCreatingRow(false);
                  // }
                }}
                id={`T02Ent_${index}`}
                value={t02Obj?.[c?.S53F04]}
                setEdit={(e) => {
                  setT02Obj({ ...t02Obj, [c?.S53F04]: e });
                }}
              />
            );
          } else {
            return (setInput = (
              <PLNumberBox
                setNumber={(e) => {
                  if (["E00FIELD21", "E00FIELD22", "E00FIELD33"].includes(c?.S53F02)) {
                    // console.log("ReverseCalCulation =>",[{
                    //   "oYear?.OSC?._REVSECOND":oYear?.OSC?._REVSECOND,
                    //   "e?.target?.value":e?.target?.value
                    // }]);
                    setTimeout(() => {
                      new Promise((resolve) => {
                        let rev = ReverseCalCulation(c, e?.target?.value, t02Obj, oYear?.OSC?._REVSECOND)
                        resolve(rev)
                      }).then((res) => {
                        console.log("ReverseCalCulation res", res);
                        setT02Obj(res)
                      })
                    }, 500)
                  }

                  if (c.S53F02 == "E00FIELD33" && ["D", "I"].includes(t02Obj?.FIELD05)) {
                    console.log("E00FIELD33 T02 QTY-1 =>", [{
                      "QTY-1": e?.target?.value,
                      "T02OBJ": t02Obj
                    }]);
                    new Promise((resolve) => {
                      let qt = Qty_CheckStk({ ...ExpenseObj, BatchIssue: BatchIssueObj, ProductAllData: accList["P_M21"] })
                      resolve(qt)
                    }).then((res) => {
                      console.log("E00000000010 T02 QTY-1 Response =>", [{
                        "res": res
                      }]);
                      if (res != "OK" && t02Obj?.FIELD33 != 0) {
                        Promise.resolve(NagativeQtyPopUpFun(res)).then(() => NextIndexFun(index - 1))
                        // NagativeQtyPopUpFun(res)
                      }
                    })
                  }
                }}
                id={`T02Ent_${index}`}
                decimalSeparator={true}
                disabled={
                  (c?.S53F02 == "E00FIELD21" || c?.S53F02 == "E00FIELD22") &&
                  selectedProductObj?.FIELD59 == "N"
                }
                value={t02Obj?.[c?.S53F04]}
                setEdit={(e) => {
                  // console.log(e, "c?.S53F04");
                  if (e != 0) setT02Obj({ ...t02Obj, [c?.S53F04]: e });
                  else setT02Obj({ ...t02Obj, [c?.S53F04]: 0 });
                }}
                onKeyDown={(e) => {
                  if (e.key == "Tab") {
                    let ind = index;
                    let ind1 = index - 1;
                    NextIndexFun(
                      c?.S53F02 == "E00FIELD81" ||
                        c?.S53F02 == "E00FIELD33" ||
                        c?.S53F02 == "E00FIELD07" ||
                        (c?.S53F02 == "E00FIELD21" && selectedProductObj?.FIELD59 !== "N") ||
                        c?.S53F02 == "E00FIELD22"
                        ? ind1
                        : ind
                    );
                  }
                }}
              />
            ));
          }

          // }
          break;
        case "TEXTBOX":
          return (setInput = (
            <PLTextBox
              id={`T02Ent_${index}`}
              value={t02Obj?.[c?.S53F04]}
              disabled={
                c?.S53F02 == "E00FIELD41" &&
                  (selectedProductObj?.FIELD51 == "N" ||
                    recIss == "D" ||
                    t02Obj?.FIELD05 == "D")
                  ? true
                  : c?.S53F02 == "E00000000013" &&
                    selectedProductObj?.FIELD51 == "N"
                    ? true
                    : false
              }
              onKeyDown={(e) => {
                if (e.key == "Tab") {
                  // let passInd = recIss == "C" ? index +1 : index
                  NextIndexFun(index);
                }
              }}
              setEdit={(e) => {
                setT02Obj({ ...t02Obj, [c?.S53F04]: e?.target?.value });
              }}
            // disabled={true}
            />
          ));
          // }

          break;
        case "PLPOPUP":
          if (c.S53F02 == "E00FIELD03") {
            //product name
            return (setInput = (
              <>
                <PLDataGrid
                  disabled={productDisable}
                  ref={prt}
                  id={`T02Ent_${index}`}
                  width={"500px"}
                  cWhere={`M21.FIELD11 LIKE '%${t02Obj?.GRPCD ?? ""
                    }%' AND M21.FIELD12 LIKE '%${t02Obj?.CATCD ?? ""}%'`}
                  value={{
                    value: t02Obj?.[c?.S53F04],
                    label: t02Obj?.F03NM,
                  }}
                  p1={seperate(c.S53F15)[1]}
                  setEdit={(e) => {
                    Promise.resolve(
                      // setSelectedProductObj(e),
                      setT02Obj({
                        ...t02Obj,
                        [c?.S53F04]: e?.FIELD01,
                        F03NM: e?.FIELD02,
                        FIELD22: e?.FIELD28 == 0 ? 1 : e?.FIELD28,
                      })
                    )
                      .then(() =>
                        setPriceListParemeter({
                          ...priceListParemeter,
                          productCode: e.FIELD01,
                        })
                      )
                      .then(() => setSelectedProductObj(e))
                      .then(() => setFIELD01value(e.FIELD01))
                      .then(() => NextIndexFun(index));
                  }}
                />
              </>
            ));
          } else {
            return (setInput = (
              <PLDataGrid
                id={`T02Ent_${index}`}
                width={"500px"}
                value={
                  c.S53F02 == "E00FIELD20" //Location
                    ? {
                      value: t02Obj?.[c?.S53F04],
                      label: t02Obj?.F20NM ?? "",
                    }
                    : t02Obj?.[c?.S53F04]
                }
                p1={seperate(c?.S53F15)[1]}
                setEdit={(e) => {
                  // Promise.resolve(
                  //   setT02Obj({ ...t02Obj, [c?.S53F04]: e?.FIELD01 })
                  // ).then(() => {
                  //   NextIndexFun(index);
                  // });
                  if (c.S53F02 == "E00FIELD20") {
                    // console.log("Location==>", e);
                    Promise.resolve(setLocationObj(e)).then(() => {
                      // let passInd = page == "03630342" ? index : index + 1;
                      NextIndexFun(index);
                    });
                  } else {
                    Promise.resolve(
                      setT02Obj({ ...t02Obj, [c?.S53F04]: e?.FIELD01 })
                    ).then(() => NextIndexFun(index));
                  }
                  // setProdCode(e?.FIELD01)
                }}
              />
            ));
          }
          break;
        case "COMBOBOX":
          if (c?.S53F02 === "E00FIELD05") {
            //Receipt/Issue
            // if (
            //   t02Obj?.FIELD03?.length > 0 ||
            //   selectedProductObj?.FIELD01?.length > 0
            // ) {
            //   console.log(index, "T02Ent_${index}");
            return (setInput = (
              <PLComboBox
                cmbid={`T02Ent_${index}`}
                value={t02Obj?.[c?.S53F04]}
                copno={seperate(c.S53F15)[1]}
                dispexpr={"DisplayMember"}
                valexpr={"ValueMember"}
                setEdit={(e) => {
                  Promise.resolve(
                    setT02Obj({
                      ...t02Obj,
                      [c?.S53F04]: e.value,
                      FIELD41: "",
                      FIELD11: "",
                    }),
                    setRecIss(e.value)
                  ).then(() => {
                    let passInd = T02UF?.length < 1 ? index + 1 : index;
                    NextIndexFun(passInd);
                  });
                }}
                dropdownPosition="Bottom"
              // defaultval={t02Obj?.[c?.S53F04]}
              />
            ));
            // } else {
            //   return (setInput = (
            //     <PLComboBox
            //       value={t02Obj?.[c?.S53F04]}
            //       copno={seperate(c?.S53F15)[1]}
            //       dispexpr={"DisplayMember"}
            //       valexpr={"ValueMember"}
            //       setEdit={(e) => {
            //         setT02Obj({ ...t02Obj, [c?.S53F04]: e?.value });
            //       }}
            //       dropdownPosition="Bottom"
            //       defaultval={t02Obj?.[c?.S53F04]}
            //       disabled={true}
            //     />
            //   ));
            // }
          } else {
            return (setInput = (
              <PLComboBox
                value={t02Obj?.[c?.S53F04]}
                copno={seperate(c?.S53F15)[1]}
                dispexpr={"DisplayMember"}
                valexpr={"ValueMember"}
                setEdit={(e) => {
                  setT02Obj({ ...t02Obj, [c?.S53F04]: e.value });
                }}
                dropdownPosition="Bottom"
                defaultval={t02Obj?.[c?.S53F04]}
              />
            ));
          }

          break;
        case "DATEPICKER":
          let { startDate } = DateToString(new Date());
          return (setInput = (
            <PLDateBox
              setEdit={(e) => {
                let { startDate } = DateToString(e);
                if (startDate?.length == 8) {
                  setT02Obj({ ...t02Obj, [c?.S53F04]: startDate });
                }
              }}
              defaultval={
                typeof t02Obj?.[c?.S53F04] == "string" &&
                  t02Obj?.[c?.S53F04] != "Invalid Date"
                  ? t02Obj?.[c?.S53F04] != ""
                    ? t02Obj?.[c?.S53F04]?.trim()
                    : startDate
                  : startDate
              }
              showClearButton={false}
              showDropDownButton={false}
              useMaskBehavior={true}
              dispformat="DD/MM/YYYY"
            />
          ));
          break;
        default:
          return (setInput = null);
          break;
      }
    }
  }
  const HiddenButtonField = (uID, v, c, cellid, index) => {
    switch (c.S53F02) {
      case "E00000000004": //Dummy
        NextIndexFun(index);
        // setDrawerObj({
        //   ...dra,
        //   title: <Text fw={700}>{c.S53F03}</Text>,
        //   body: (
        //     <>
        //       <Text>{c.S53F03}</Text>
        //       <div
        //         style={{
        //           display: "flex",
        //           alignItems: "center",
        //           justifyContent: "center",
        //           padding: "5px 0px",
        //         }}
        //       >
        //         <Button
        //           onClick={() => {
        //             // setDrawerObj({ ...DrawerObj, open: false })
        //             Promise.resolve(
        //               setDrawerObj({ ...DrawerObj, open: false })
        //             ).then(() => NextIndexFun(index));
        //           }}
        //         >
        //           Ok
        //         </Button>
        //       </div>
        //     </>
        //   ),
        //   open: true,
        //   size: "sm",
        //   position: "right",
        // });
        break;
      case "E00FIELD11": //Batch Issue
        // console.log(
        //   t02Obj?.FIELD03,
        //   "t02Obj?.FIELD03",
        //   selectedProductObj?.FIELD51,
        //   recIss,
        //   t02Obj?.FIELD05
        // );
        if (
          (selectedProductObj?.FIELD01 != "" || t02Obj?.FIELD03 != "") &&
          selectedProductObj?.FIELD51 != "N" &&
          (recIss == "D" || t02Obj?.FIELD05 == "D")
        ) {
          setDrawerObj({
            ...dra,
            title: <Text fw={700}>{c.S53F03}</Text>,
            onClickCloseButton: () => {
              // setDrawerObj(dra);
              Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
                () => NextIndexFun(index)
              );
            },
            onClose: () => {
              // setDrawerObj(dra);
              Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
                () => NextIndexFun(index)
              );
            },
            body: (
              <>
                <PLDataGrid
                  autoFocus={true}
                  // setBlur={()=>T02UF?.length > 0 ? TableUserFieldFun() : null}
                  id={`T02Ent_${index}`}
                  cWhere={`T11.FIELD08 LIKE '%${FIELD01Value ?? ""}%'`}
                  width={"500px"}
                  size={"0.4"}
                  value={t02Obj?.[c?.S53F04]}
                  p1={seperate(c.S53F15)[1]}
                  setEdit={(e) => {
                    Promise.resolve(setBatchIssueObj(e)).then(() => {
                      Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
                        () => NextIndexFun(index)
                      );
                    })
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "5px 0px",
                  }}
                >
                  <Button
                    onClick={() => {
                      // .then(() => setDrawerObj({ ...DrawerObj, open: false })).then(() => NextIndexFun(index))
                      Promise.resolve(
                        setDrawerObj({ ...DrawerObj, open: false })
                      ).then(() => NextIndexFun(index));
                    }}
                    onKeyDown={(e) => {
                      if (e.key == "Enter") {
                        Promise.resolve(
                          setDrawerObj({ ...DrawerObj, open: false })
                        ).then(() => NextIndexFun(index));
                      }
                    }}
                  >
                    OK
                  </Button>
                </div>
              </>
            ),
            open: true,
            size: "xl",
            position: "right",
          });
        } else {
          NextIndexFun(index);
        }
        break;
      case "E00000000006":
        console.log(T02UF?.length, "T02UF-", index);
        if (T02UF?.length > 0) {
          setDrawerObj({
            ...dra,
            title: <Text fw={700}>User Field</Text>,
            onClickCloseButton: () => {
              // setDrawerObj(dra);
              Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
                () => NextIndexFun(index)
              );
            },
            onClose: () => {
              // setDrawerObj(dra);
              Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
                () => NextIndexFun(index)
              );
            },
            body: (
              // TableUserFieldFun(c,index)
              <>
                <SITableUserField
                  T02UF={T02UF}
                  T02ObjSend={t02Obj}
                  setT02Objsend={(e) => {
                    setT02Obj(e);
                    Promise.resolve(setT02Obj(e)).then(() => {
                      if (selectedProductObj?.FIELD59 == "N") {
                        NextIndexFun(index + 1);
                      } else {
                        NextIndexFun(index);
                      }
                    });
                  }}
                  DrawerOBJ={DrawerObj}
                  setDrawerOBJ={(e) => {
                    // console.log("setDrawerOBJ=>", e);
                    setDrawerObj(e);
                  }}
                />
              </>
            ),
            open: true,
            size: "sm",
            position: "right",
          });
        } else {
          NextIndexFun(index);
        }
        break;
      case "E00000000006B":
        setTimeout(() => {
          if (
            dtUFT11 &&
            dtUFT11?.length > 0 &&
            selectedProductObj?.FIELD51 == "N"
          ) {
            console.log(
              selectedProductObj?.FIELD51,
              "selectedProductObj?.FIELD51"
            );
            setDrawerObj({
              ...dra,
              title: <Text fw={700}>{c.S53F03}</Text>,
              onClickCloseButton: () => {
                // setDrawerObj(dra);
                Promise.resolve(
                  setDrawerObj({ ...DrawerObj, open: false })
                ).then(() => NextIndexFun(index));
              },
              onClose: () => {
                // setDrawerObj(dra);
                Promise.resolve(
                  setDrawerObj({ ...DrawerObj, open: false })
                ).then(() => NextIndexFun(index));
              },
              body: (
                <>
                  <SITableBatchUserField
                    PageObj={obj}
                    DTUFT11={dtUFT11}
                    T02ObjSend={t02Obj}
                    setT02Objsend={(e) => {
                      Promise.resolve(setT02Obj(e)).then(() =>
                        setTimeout(() => {
                          NextIndexFun(index);
                        }, 500)
                      );
                    }}
                    DrawerOBJ={DrawerObj}
                    setDrawerOBJ={(e) => {
                      console.log("setDrawerOBJ=>", e);
                      setDrawerObj(e);
                    }}
                  />
                </>
              ),
              open: true,
              size: "sm",
              position: "right",
            });
          } else {
            NextIndexFun(index);
          }
        }, 400);

        break;
      case "E00000000015":
        if (PLDATA && selectedProductObj?.FIELD53 !== "N") {
          setDrawerObj({
            ...dra,
            title: <Text fw={700}>{c.S53F03}</Text>,
            onClickCloseButton: () => {
              // setDrawerObj(dra);
              Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
                () => NextIndexFun(index)
              );
            },
            onClose: () => {
              // setDrawerObj(dra);
              Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
                () => NextIndexFun(index)
              );
            },
            body: (
              <>
                <PLComboBox
                  initiallyOpened={true}
                  cmbid={`T02Ent_${index}`}
                  data={typeof PLDATA == "string" ? [{}] : PLDATA}
                  // value={t02Obj?.[c?.S53F04]}
                  value={t02Obj?.FIELD27}
                  dispexpr={"FIELD02"}
                  valexpr={"FIELD01"}
                  setEdit={(e) => {
                    // console.log("Pricelist OBJ=>", e);
                    Promise.resolve(
                      setT02Obj({ ...t02Obj, [c?.S53F04]: e, FIELD27: e })
                    )
                      .then(() => {
                        if (group.FIELD16 == "C") {
                          setPriceListParemeter({
                            ...priceListParemeter,
                            accountCode: group.FIELD31,
                            priceListCode: e,
                          });
                        } else {
                          setPriceListParemeter({
                            ...priceListParemeter,
                            accountCode: group.FIELD04,
                            priceListCode: e,
                          });
                        }
                      }).then(() => {
                        Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
                          () => NextIndexFun(index)
                        );
                      })
                      .then(() => setDrawerObj({ ...DrawerObj, open: false }))
                      .then(() => NextIndexFun(index));
                  }}
                  disabled={typeof PLDATA == "string"}
                />
              </>
            ),
            open: true,
            size: "sm",
            position: "right",
          });
        } else {
          NextIndexFun(index);
        }
        break;
      case "E00FIELD65":
        setDrawerObj({
          ...dra,
          title: <Text fw={700}>{c.S53F03}</Text>,
          onClickCloseButton: () => {
            // setDrawerObj(dra);
            Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
              () => NextIndexFun(index)
            );
          },
          onClose: () => {
            // setDrawerObj(dra);
            Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
              () => NextIndexFun(index)
            );
          },
          body: (
            <>
              <PLTextBox
                id={`T02Ent_${index}`}
                value={t02Obj?.[c?.S53F04]}
                disabled={
                  c?.S53F02 == "E00FIELD41" &&
                  selectedProductObj?.FIELD51 == "N"
                }
                // onKeyDown={(e) => {
                //   if (e.key == "Tab") {
                //     const nextInd = index;
                //     const nextInput = document.getElementById(
                //       `T02Ent_${nextInd}`
                //     );
                //     console.log(nextInput, "nextInput-");
                //     if (nextInput) {
                //       nextInput.focus({ focusVisible: true });
                //     }
                //   }
                // }}
                setEdit={(e) => {
                  setT02Obj({ ...t02Obj, [c?.S53F04]: e?.target?.value });
                }}
              // disabled={true}
              />
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "5px 0px" }}>
                <Button
                  onClick={() => {
                    // setDrawerObj({ ...DrawerObj, open: false })
                    Promise.resolve(
                      setDrawerObj({ ...DrawerObj, open: false })
                    ).then(() => NextIndexFun(index));
                  }}
                >
                  Ok
                </Button>
              </div>
            </>
          ),
          open: true,
          size: "sm",
          position: "right",
        });
        break;
      case "E00FIELD86":
        setDrawerObj({
          ...dra,
          title: <Text fw={700}>{c.S53F03}</Text>,
          onClickCloseButton: () => {
            // setDrawerObj(dra);
            Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
              () => NextIndexFun(index)
            );
          },
          onClose: () => {
            // setDrawerObj(dra);
            Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
              () => NextIndexFun(index)
            );
          },
          body: (
            <>
              {/* <Text>{c.S53F03}</Text>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  // justifyContent: "center",
                  // padding: "5px 0px",
                }}
              > */}
              <PLTextBox
                id={`T02Ent_${index}`}
                value={t02Obj?.[c?.S53F04]}
                // disabled={
                //   c?.S53F03 == "Batch Receipt" &&
                //   selectedProductObj?.FIELD51 == "N"
                // }
                // onKeyDown={(e) => {
                //   if (e.key == "Tab") {
                //     const nextInd = index;
                //     const nextInput = document.getElementById(
                //       `T02Ent_${nextInd}`
                //     );
                //     console.log(nextInput, "nextInput-");
                //     if (nextInput) {
                //       nextInput.focus({ focusVisible: true });
                //     }
                //   }
                // }}
                setEdit={(e) => {
                  setT02Obj({ ...t02Obj, [c?.S53F04]: e?.target?.value });
                }}
              // disabled={true}
              />
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "5px 0px" }}>
                <Button
                  onClick={() => {
                    // setDrawerObj({ ...DrawerObj, open: false })
                    Promise.resolve(
                      setDrawerObj({ ...DrawerObj, open: false })
                    ).then(() => {
                      // console.log(recIss, "recIss", t02Obj?.FIELD05)
                      // let passInd = recIss == "C" || t02Obj?.FIELD05 == "C" ? index + 1: index
                      NextIndexFun(index);
                    });
                  }}
                >
                  Ok
                </Button>
              </div>
            </>
          ),
          open: true,
          size: "sm",
          position: "right",
        });
        break;
      default:
        break;
    }
  };
  function DateToString(e) {
    // console.log(e, "Datetostring");
    let year = e.toLocaleString("en-IN", { year: "numeric" });
    let month = e.toLocaleString("en-IN", {
      month: "2-digit",
    });
    let day = e.toLocaleString("en-IN", { day: "2-digit" });

    let formatedStartDate = day + "/" + month + "/" + year;
    let startDate = year + month + day;
    return { formatedStartDate, startDate };
  }

  const RowDetailHandle = (e) => {
    console.log("RowDetailHandle =>", e);

    let productObjFind = {};
    let cStr = "";
    accList["P_M21"]?.forEach((p, i) => {
      if (p?.FIELD01 == e?.FIELD03) {
        productObjFind = { ...p }
      }
    })

    let FUnit = (e?.FIELD05 == 'I' || e?.FIELD05 == 'D') ? (productObjFind?.FIELD06 == "" ? oYear?.OSC?._UNIT1 : productObjFind?.FIELD06) : (productObjFind?.FIELD05 == "" ? oYear?.OSC?._UNIT1 : productObjFind?.FIELD05);

    // let cStr = cStr + FUnit + "  " + productObjFind.FIELD98 + "   ";

    cStr = cStr + FUnit + "  " + productObjFind?.FIELD98 + "   ";

    if (oYear?.OSC?._DUALSTOCK == "Y" && productObjFind?.FIELD59 == 'Y') {
      let SUnit = productObjFind?.FIELD27 == "" ? oYear?.OSC?._UNIT2 : productObjFind?.FIELD27
      return cStr + SUnit + "  " + productObjFind?.FIELD93
    } else {
      return cStr
    }

  }

  function handleCreateUser() {
    console.log(t02Obj, "t02Obj-");
    if (
      (t02Obj?.FIELD41 === "" && selectedProductObj?.FIELD51 == "Y") ||
      t02Obj?.FIELD03 === ""
    ) {
      GlobalClass.Notify(
        "warning",
        "Validation error",
        "Please fill out all the required fields"
      );
    } else {
      // setCreatingModeOn(true);
      Store.dispatch(
        BatchLogAddRemoveFun({
          PageCode: OS11?.S11F01,
          T41Obj: group,
          T02Data: t02Data,
          Row: t02Obj,
          OBJ: obj,
        })
      );

      // for (let i = 0; i < t02Data?.length; i++) {
      //   if (t02Obj?.FIELD05 == "D") {
      //     setIssueQty(issueQty + t02Obj?.FIELD33);

      //     setIssueAmt(issueAmt + t02Obj?.FIELD08);
      //   } else {
      //     setReceiptQty(receiptQty + t02Obj?.FIELD33);
      //     setReceiptAmt(receiptAmt + t02Obj?.FIELD08);
      //   }
      //   setGroup({ ...group, FIELD07: issueAmt, FIELD06: receiptAmt });
      // }
      let { formatedStartDate, startDate } = DateToString(t02Obj?.EXPDATE);
      let newObj = { ...t02Obj, EXPDATE: startDate };
      let nullFilter = t02Data?.filter((d) => d?.FIELD03 != "")
      setT02Data([...nullFilter, newObj, (obj.p0 == "E" ? T02?.[T02?.length - 1] : T02?.[0])]);
      setCreatingModeOn(false);
      setGroupCatogaryProduct({ Group: "", Catogary: "", Product: "" });
      if (p0 == "E") {
        setT02Obj({ ...T02?.[T02?.length - 1], FIELD05: OS11?.S11F21 });
      } else {
        setT02Obj({ ...T02?.[0], FIELD05: OS11?.S11F21 });
      }
      setProductDisable(false)

      setTableEditingRow(null);
      setSelectedProductObj(null);
      setBatchIssueObj(null);
      setRecIss("");
      setLocationObj(null);
      table.setCreatingRow(null);
      // exitCreatingMode();
    }
  }

  // useEffect(() => {
  //   if (p0 == "E") {
  //     let IssueSum = 0;
  //     let RecSum = 0;

  //     t02Data?.map((v) => {
  //       if (v?.FIELD05 == "D") {
  //         IssueSum += v?.FIELD08;
  //       } else if (v?.FIELD05 == "C") {
  //         RecSum += v?.FIELD08;
  //       } else {
  //       }
  //       setGroup({ ...group, FIELD07: IssueSum, FIELD06: RecSum });
  //     });
  //   }
  // }, [t02Data]);

  function handleSaveUser(props) {
    // setCreatingModeOn(true);
    Store.dispatch(
      BatchLogAddRemoveFun({
        PageCode: OS11?.S11F01,
        T41Obj: group,
        T02Data: t02Data,
        Row: t02Obj,
        OBJ: obj,
      })
    );
    // console.log(t02Obj , "t02Obj-")
    // for (let i = 0; i < t02Data?.length; i++) {
    //   let receiptAmt = 0,
    //     issueAmt = 0,
    //     receiptQty = 0,
    //     issueQty = 0;
    //   if (t02Obj?.FIELD05 == "D") {
    //     setIssueQty(issueQty + t02Obj?.FIELD33);

    //     setIssueAmt(issueAmt + t02Obj?.FIELD08);
    //     issueQty = 0;
    //   } else {
    //     setReceiptQty(receiptQty + t02Obj?.FIELD33);
    //     setReceiptAmt(receiptAmt + t02Obj?.FIELD08);
    //     receiptQty = 0;
    //   }
    //   setGroup({ ...group, FIELD07: issueAmt, FIELD06: receiptAmt });
    // }
    // let row = props?.row;
    let EditT02Data = [...t02Data];
    EditT02Data[tableEditingRow?.index] = t02Obj;
    let nullFilter = EditT02Data?.filter((d) => d?.FIELD03 != "")
    setT02Data([...nullFilter, (obj.p0 == "E" ? T02?.[T02?.length - 1] : T02?.[0])]);
    if (p0 == "E") {
      setT02Obj({ ...T02?.[T02?.length - 1], FIELD05: OS11?.S11F21 });
    } else {
      setT02Obj({ ...T02?.[0], FIELD05: OS11?.S11F21 })
    }
    setProductDisable(false)

    setCreatingModeOn(false);
    setGroupCatogaryProduct({ Group: "", Catogary: "", Product: "" });
    setSelectedProductObj(null);
    setBatchIssueObj(null);
    setRecIss("");
    setLocationObj(null);
    setTableEditingRow(null);
    table.setEditingRow(null);
    setRowSelection({ [t02Data?.length]: true })
    // props.exitEditingMode();
  }

  function rowCancelHandler() {
    setCreatingModeOn(false);
    if (p0 == "E") {
      setT02Obj({ ...T02?.[T02?.length - 1], FIELD05: OS11?.S11F21 });
    } else {
      setT02Obj({ ...T02?.[0], FIELD05: OS11?.S11F21 });
    }
    setProductDisable(false);
    setSelectedProductObj(null);
  }

  function rowEditCancelHandler(props) {
    // setCreatingModeOn(false);
    if (p0 == "E") {
      setT02Obj({ ...T02?.[T02?.length - 1], FIELD05: OS11?.S11F21 });
    } else {
      setT02Obj({ ...T02?.[0], FIELD05: OS11?.S11F21 });
    }
    setProductDisable(false);
    setSelectedProductObj(null);
    setTableEditingRow(null);

  }

  const Summary = (obj, i, tag) => {
    // let stype = "sum";
    var value = "";
    switch (obj?.S53F03) {
      // case "Product Name":
      //   value = <>Total Entry: {t02Data?.length} </>;

      //   break;
      // case "Amount":
      //   value = t02Data?.reduce((a, b) => (a = a + b?.FIELD08), 0);

      // break;
      case "Qty-2":
        value =
          // t02Data?.reduce((a, b) => (a = a + b?.FIELD21), 0);
          t02Data.reduce((a, b) => {
            // (a = a + b?.FIELD21)
            if (b?.FIELD05 === "C") {
              a = a + (b?.FIELD21 || 0);
            } else if (b?.FIELD05 === "D") {
              a = a - (b?.FIELD21 || 0);
            }
            return a;
          }, 0);

        break;
      case "Qty":
        // value = t02Data?.reduce((a, b) => (a = a + b?.FIELD33), 0);
        value = t02Data?.reduce((a, b) => (a = a + b?.FIELD33), 0);
        break;
      case "Amount":
        value = t02Data?.reduce((a, b) => (a = a + b?.FIELD08), 0);
        break;
      default:
        break;
    }
    return value;
  };
  const openDeleteConfirmModal = (row) => {
    // console.log("openDeleteConfirmModal", row);
    let editObj = row?.original
    if (editObj?.LVALID == "N") {
      GlobalClass.Notify("warning", "Bill Prepared for this entry, Cannot Delete entry");

    } else {
      // if (window.confirm('Confirm to Delete ?')) {
      //   let EditT02Data = [...t02Data];
      //   let newArray = EditT02Data.filter((d, i) => i != row.index);
      //   // setNarrationData(newArray)

      //   // EditT02Data[tableEditingRow?.index] = objChange;
      //   setT02Data(newArray);
      // }

      GlobalClass.Confirm(
        "red",
        "Are you sure to delete selected data?",
        "It will be Deleted Forever and Cannot Be Recovered!!!",
        () => {
          let EditT02Data = [...t02Data];
          let newArray = EditT02Data.filter((d, i) => i != row.index);
          setT02Data(newArray);
        },
        () => { },
        "Cancel",
        "Confirm"
      )
    }
  };
  const table = useMantineReactTable({
    data: t02Data ?? [],
    columns: columns,
    mantineTableProps: { withColumnBorders: true },
    enableFilters: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableTopToolbar: false,
    enableColumnActions: false,
    enableSorting: true,
    enableStickyFooter: true,
    enableStickyHeader: true,
    enableFilters: false,
    enableSelectAll: false,
    mantineTableContainerProps: (props) => ({
      sx: { maxHeight: "250px", minHeight: "300px" },
    }),
    enableEditing: true,
    onColumnVisibilityChange: (column) => { },
    enableHiding: false,
    createDisplayMode: "row",
    editDisplayMode: "row",
    initialState: {
      // columnVisibility: {
      //   production: false,
      //   "Purcahse Detail": false,
      //   "Quantity Detail": false,
      //   MRP: false,
      //   "MRP Sales Rate": false,
      //   "Serial No Issue": false,
      //   "serial no Receipt": false,
      //   "Sr No Auto (Entry Time)": false,
      //   "Serial No Issue(F.Qty)": false,
      //   "Serial No Receipt(F. Qty)": false,
      //   "F. Qty": false,
      //   Location: false,
      //   "Manufacture Date": false,
      //   "Batch User FIeld": false,
      // },
    },
    mantineTableBodyProps: {
      tabIndex: 0,
      ref: TableRef,
    },
    onPaginationChange: setPagination,
    mantineTableBodyRowProps: ({ row, staticRowIndex, table }) => ({
      ref: TableContainer,
      tabIndex: 0,
      onKeyUp: (e) => handleRowKeyDown(e, row),
      onClick: (e) => {
        // console.log([row?.id], "[row?.id]")
        setRowSelection((prev) => {
          if (!prev[row.id]) {
            return { [row.id]: true };
          }
          return prev;
        });
      },
      // onKeyDown: (e) => {
      //   console.log(e, "OnkeyDown");
      //   if (e.key == "ArrowDown") {
      //     console.log(props, "row?.id", props?.table?.getRow);
      //     setRowSelection((prev) => ({
      //       // [row?.id]: !prev[row?.id],
      //     }));
      //   }
      // },

      selected: rowSelection[row?.id],
      sx: { cursor: "pointer" },
    }),
    onCreatingRowSave: () => {
      handleCreateUser();
    },
    onEditingRowSave: () => {
      handleSaveUser();
    },
    onCreatingRowCancel: rowCancelHandler,
    onEditingRowCancel: rowEditCancelHandler,
    renderRowActions: ({ row, table }) => (
      <Flex gap="md">
        <Tooltip label="Edit">
          <ActionIcon
            onClick={() => {
              dispatch(GetAccountList({
                "id": "P_M21",
                "name": "",
                "type": "A",
                "p0": "P_M21",
                "p1": "P_M21",
                "p2": "",
                "p3": "",
                "cWhere": `M21.FIELD12 LIKE '%${row?.original?.CATCD ?? ""}%'`
                // "cWhere": ""
              }))
              let editObj = row?.original;
              if (editObj?.LVALID == "N") {
                GlobalClass.Notify(
                  "warning",
                  "Bill Prepared for this entry, Cannot edit entry"
                );
              } else {
                Promise.resolve(table.setCreatingRow(false))
                  .then(() => {
                    console.log(editObj, "editObj-");
                    if (
                      (editObj?.FIELD11?.length > 0 ||
                        editObj?.FIELD20?.length > 0) &&
                      p0 == "E"
                    ) {
                      console.log(editObj, "newObj-");
                      Promise.resolve(setProductDisable(true)).then(() => {
                        setTimeout(() => {
                          NextIndexFun(1);
                        }, 1000);
                      });
                    } else {
                      console.log(editObj, "newObj9-");

                      Promise.resolve(setProductDisable(false)).then(() => {
                        // prCt?.current?.handlePopoverToggle();

                      });
                    }
                  }).then(() => {
                    table?.setEditingRow(row);
                    setT02Obj(editObj);
                    setTableEditingRow(row);
                    // setEdModeOn(true);
                  });
              }
            }}
          >
            <IconEdit size={"20px"} />
          </ActionIcon>
        </Tooltip>
        {row?.original?.FIELD03 != "" &&
          <Tooltip label="Delete">
            <ActionIcon color="red" onClick={() => openDeleteConfirmModal(row)}>
              <IconTrash size={"20px"} />
            </ActionIcon>
          </Tooltip>
        }
      </Flex>
    ),
    positionToolbarAlertBanner: false,
    renderTopToolbarCustomActions: (props) => {
      // console.log(props, "renderTopToolbarCustomActions");
      // return p0 !== "E" ? (
      return (
        <Button
          onFocus={(e) => {
            if (
              e.type === "focus" &&
              e.relatedTarget &&
              tableEditingRow == null
            )
              Promise.resolve(table.setCreatingRow(true)).then(() => {
                setProductDisable(false);
                setTimeout(() => {
                  oYear.OSC._PRGROUP == "Y" ? grpRef?.current?.handlePopoverToggle() : oYear.OSC._PRCATEGOR == "Y" ?
                    prCt?.current?.handlePopoverToggle() : prt?.current?.handlePopoverToggle();
                  setCreatingModeOn(true);
                }, 500);
              });
          }}
          onClick={() => {
            // console.log(tableEditingRow, "setCreatingRow0");
            setRowSelection({});
            if (tableEditingRow == null) {
              Promise.resolve(table.setCreatingRow(true)).then(() => {
                {
                  setProductDisable(false);
                  setTimeout(() => {
                    oYear.OSC._PRGROUP == "Y" ? grpRef?.current?.handlePopoverToggle() : oYear.OSC._PRCATEGOR == "Y" ?
                      prCt?.current?.handlePopoverToggle() : prt?.current?.handlePopoverToggle();
                    setCreatingModeOn(true);
                  }, 500);
                }
              });
            } else {
              console.log(tableEditingRow, "setCreatingRow0");

              Promise.resolve(table.setCreatingRow(false)).then(() => {
                setCreatingModeOn(false);
              });
            }
          }}
          size="xs"
        >
          Add New
        </Button>
      );
      // ) : (
      //   ""
      // );
    },
    state: {
      density: "1.5px",
      rowSelection,
    },
    renderBottomToolbar: ({ table }) => (
      <Box>
        <Grid style={{ display: "flex", alignItems: "center" }}>
          <Grid.Col span={4}>
            <Text style={{ fontSize: 12, fontWeight: "bold" }}>
              {
                table?.getSelectedRowModel()?.flatRows.length > 0 && table?.getSelectedRowModel()?.flatRows[0]?.original?.FIELD03 != "" ? RowDetailHandle(table?.getSelectedRowModel()?.flatRows[0]?.original) : null
              }
            </Text>
          </Grid.Col>

          <Grid.Col span={4}>
            <Group spacing={"xs"} position="center">
              <Button size="xs" variant="subtle" compact onClick={() => {
                if (table?.getSelectedRowModel()?.flatRows.length > 0 && table?.getSelectedRowModel()?.flatRows[0]?.original?.FIELD03 != "") {
                  let newT02Data = t02Data?.filter((d) => d?.FIELD03 != "")
                  setT02Data([...newT02Data, table?.getSelectedRowModel()?.flatRows[0]?.original, (obj.p0 == "E" ? { ...T02?.[T02?.length - 1], FIELD05: OS11?.S11F21 } : { ...T02?.[0], FIELD05: OS11?.S11F21 })])
                }
              }}>
                Copy
              </Button>
              <Button size="xs" variant="subtle" compact>
                Serial No.
              </Button>
            </Group>
            <Center>
              <Button size="xs" variant="subtle" compact my={2}>
                Import
              </Button>
            </Center>
          </Grid.Col>

          <Grid.Col span={4}>
            <Group
              spacing={"xs"}
              position="right"
              display={"flex"}
            >
              <MRT_TablePagination position="bottom" table={table} />
            </Group>
          </Grid.Col>

        </Grid>
      </Box>
    )
  });

  const columnSelection = () => {
    setDrawerObj({
      ...dra,
      title: <Text fw={700}>Column selection</Text>,
      body: (
        <>
          <PLColumnSelection
            cCode={obj?.p0 == "A" ? "IJIJ" : group?.FIELD01}
            index={props?.index}
            DrawerOBJ={DrawerObj}
            setDrawerOBJ={(e) => {
              setDrawerObj(e);
            }}
          />
        </>
      ),
      open: true,
      size: "80%",
      position: "right",
    });
  };

  const handleRowKeyDown = (e, row) => {
    // if (e?.key == "Enter" && !creatingModeOn && tableEditingRow == null) {
    //   console.log("TABKEY", e.key, tab);
    //   // if(!batchTrue)
    //   {
    //     tab?.setEditingRow(row);
    //     setTableEditingRow(row);
    //     setCreatingModeOn(false);
    //     setT02Obj(row?.original);
    //   }
    //   //  else{
    //   //   tab?.setCreatingRow(true)
    //   // }
    // }

    console.log("handleRowKeyDown parameter =>", [{
      "e": e,
      "row": row,
      "tableEditingRow": tableEditingRow,
      "TableRef.current": TableRef.current
    }])
    const tabled = TableRef.current;
    const rows = tabled.querySelectorAll('tr');
    const currentRowIndex = Array.from(rows).indexOf(e.target);
    console.log("TABKEY", e.key);
    switch (e?.key) {
      case 'ArrowDown':
        // e.preventDefault();
        if (currentRowIndex < rows.length - 1) {
          e.preventDefault();
          rows[currentRowIndex + 1].focus();
          // rows[currentRowIndex + 1].scrollIntoView({ behavior: "smooth",block: "start"})

        } else if (
          table?.getPageCount() && table?.getPageCount() > 1 &&
          pagination?.pageIndex < table?.getPageCount() - 1
        ) {
          // Navigate to the next page
          table.nextPage()
          // setPagination((prev) => ({
          //   ...prev,
          //   pageIndex: prev.pageIndex + 1,
          // }));
          // rows[0].scrollIntoView({ behavior: "smooth",block: "start"})
          // rows[0].focus();
        }

        break;
      case 'ArrowUp':

        if (currentRowIndex > 0) {
          e.preventDefault();
          rows[currentRowIndex - 1].focus();
          // rows[currentRowIndex - 1].scrollIntoView({ behavior: "smooth",block: "end"})
        } else if (pagination.pageIndex > 0) {
          // Navigate to the previous page
          table.previousPage()
          // setPagination((prev) => ({
          //   ...prev,
          //   pageIndex: prev.pageIndex - 1,
          // }));
          // rows[0].scrollIntoView({ behavior: "smooth",block: "start"})
          // rows[0].focus();
        }

        break;
      case "Enter":
        if (row?.id == 'mrt-row-create') {
          return
        } else if (tableEditingRow) {
          return
        } else {
          table?.setEditingRow(row)
          setTableEditingRow(row)
          setT02Obj(row?.original)
          // if (OSV?._SPACCEACH == "N") {
          //   // setExpenseHideShowFlag("N")
          // }
        }
        break;
      default:
        if (e.ctrlKey && e.altKey && e.key == "F4") {
          columnSelection();
        }
        break;
    }
  };

  const TotalEntryCalc = () => {
    let Str = 'Total Entry :'
    let T02DataLen = [...t02Data.filter((d) => d?.FIELD03 != "")]
    let selectedRow = table?.getSelectedRowModel()?.flatRows
    let ReturnStr = ""
    if (T02DataLen.length < 0) {
      ReturnStr = ""
    } else if (T02DataLen.length > 0 && selectedRow.length > 0) {
      if (selectedRow?.[0]?.original?.FIELD03 != "") {
        ReturnStr = Str + (parseInt(selectedRow?.[0].index) + parseInt(1)) + '/' + T02DataLen.length
      } else {
        ReturnStr = Str + T02DataLen.length
      }
    } else if (T02DataLen.length > 0) {
      ReturnStr = Str + T02DataLen.length
    }

    return ReturnStr

  }

  useEffect(() => {
    document.addEventListener('keydown', (e) => {
      if (e.key == "Escape") {
        setTableEditingRow(null)
        Promise.resolve()
          .then(() => rowCancelHandler())
          .then(() => {
            setTimeout(() => {
              if (tableEditingRow == null) {
                table.setCreatingRow(false)
              } else {
                table.setEditingRow(null)
              }
            }, 500)
          })
      }
    })
  }, [tableEditingRow])

  // useEffect(() => {
  //   t02Data?.map((t, index) => {
  //     if (index in rowSelection) {
  //       //  onkeydown=((e)=>{
  //       document.addEventListener("keydown", (e) => {
  //         if (e.key == "Enter") {
  //           console.log("t of selected row", t);
  //           Promise.resolve(table.setCreatingRow(null)).then(() => {
  //             table.setEditingRow(table?.getSelectedRowModel()?.flatRows[0]);
  //             setT02Obj(t);
  //             setTableEditingRow(table?.getSelectedRowModel()?.flatRows[0]);
  //             setCreatingModeOn(false);
  //           });
  //         } else if (e.key == "Escape") {
  //           console.log(e.key, "e.key");
  //           rowCancelHandler();
  //           table.setCreatingRow(false);
  //         }
  //       });
  //       // })
  //     }
  //   });
  // }, [rowSelection, t02Data]);
  let compareTwoArrayOfObjects = (editT02, t02Data) => {
    return (
      editT02?.length === t02Data?.length &&
      editT02?.every((v) =>
        t02Data?.some((c) => Object.keys(v).every((key) => v[key] === c[key]))
      )
    );
  };

  function UFT41HFieldRenderer(e) {
    if (e == "Header") {
      return (
        UFT41H &&
        UFT41H?.length > 0 &&
        UFT41H.map((h) => {
          // console.log("User Field=>", h?.S13F14, h?.S13F07, h?.S13F02);
          if (h?.S13F87 == "HEADER") {
            //b?.S13F14
            //console.log("UFT41HRenderHeader==>",h);
            return (
              <>
                {h?.S13F14 == "TEXTBOX" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLTextBox
                        value={group?.[h?.S13F02]}
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [h?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h?.S13F14 == "NUMBOX" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLNumberBox
                        value={group?.[h?.S13F02]}
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}

                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [h?.S13F02]: e });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h?.S13F14 == "PLPOPUP" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLDataGrid
                        width={"500px"}
                        name={h.S13F02}
                        value={{
                          value: group?.[h?.S13F02],
                          label: accList?.[seperate(h.S13F09)[1]]?.filter((a) => a?.FIELD01 == group?.[h?.S13F02])?.[0]?.[h?.S13F88 == "H" ? "FIELD01" : ("FIELD02" in (accList?.[seperate(h.S13F09)[1]]?.filter((a) => a?.FIELD01 === group?.[h?.S13F02])?.[0] || {}) ? "FIELD02" : tmList[seperate(h?.S13F09)[1]]?.oS60?.cAssignFld)],
                        }}
                        p0={seperate(h.S13F09)[0]}
                        p1={seperate(h.S13F09)[1]}
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          //console.log(e);
                          setGroup({ ...group, [h?.S13F02]: e?.FIELD01 });
                          // M01handlechange({ id: item.S13F02, value: e })
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h?.S13F14 == "COMBOBOX" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLComboBox
                        value={group?.[h?.S13F02]}
                        copno={seperate(h?.S13F09)[1]}
                        dispexpr={"DisplayMember"}
                        valexpr={"ValueMember"}
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [h?.S13F02]: e?.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h?.S13F14 == "DATEPICKER" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLDateBox
                        defaultval={
                          group?.[h?.S13F02]?.trim() != ""
                            ? group?.[h?.S13F02]
                            : GetYearAndDate?.strtDate
                        }
                        dispformat="DD/MM/YYYY"
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          let { startDate } = DateToString(e);
                          if (startDate?.length == 8) {
                            setGroup({ ...group, [h?.S13F02]: e });
                          }
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h?.S13F14 == "TIMEPICKER" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={4}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLDateBox
                        // value={new Date(group?.[h?.S13F02]).getTime()}
                        dispformat="DD/MM/YYYY"
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [h?.S13F02]: e });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h.S13F14 === "MEMOBOX" && h.S13F21 === "N" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLTextBox
                        value={group?.[h?.S13F02]}
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [h?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h.S13F14 === "MEMOBOX" && h.S13F21 === "H" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLTextBox
                        value={group?.[h?.S13F02]}
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [h?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h.S13F14 === "FILEUPLOAD" && h.S13F21 === "I" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLTextBox
                        value={group?.[h?.S13F02]}
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [h?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h.S13F14 === "FILEUPLOAD" && h.S13F21 === "A" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLTextBox
                        value={group?.[h?.S13F02]}
                        onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [h?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}
              </>
            );
          }
        })
      );
    } else if (e == "Footer") {
      return (
        UFT41H &&
        UFT41H?.length > 0 &&
        UFT41H.map((f) => {
          if (f?.S13F87 == "FOOTER") {
            //b?.S13F14
            return (
              <>
                {f?.S13F14 == "TEXTBOX" && (
                  <>
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <PLTextBox
                          value={group?.[f?.S13F02]}
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            //console.log(e)
                            setGroup({ ...group, [f?.S13F02]: e.target.value });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  </>
                )}

                {f?.S13F14 == "NUMBOX" && (
                  <>
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <PLNumberBox
                          value={group?.[f?.S13F02]}
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            //console.log(e)
                            setGroup({ ...group, [f?.S13F02]: e });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  </>
                )}

                {f?.S13F14 == "PLPOPUP" && (
                  <>
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <PLDataGrid
                          width={"500px"}
                          value={{
                            value: group?.[f?.S13F02],
                            label: accList?.[seperate(f.S13F09)[1]]?.filter((a) => a?.FIELD01 == group?.[f?.S13F02])?.[0]?.[f?.S13F88 == "H" ? "FIELD01" : ("FIELD02" in (accList?.[seperate(f.S13F09)[1]]?.filter((a) => a?.FIELD01 === group?.[f?.S13F02])?.[0] || {}) ? "FIELD02" : tmList[seperate(f?.S13F09)[1]]?.oS60?.cAssignFld)],
                          }} p0={seperate(f.S13F09)[0]}
                          p1={seperate(f.S13F09)[1]}
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            //console.log(e)
                            setGroup({ ...group, [f?.S13F02]: e?.FIELD01 });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  </>
                )}

                {f?.S13F14 == "COMBOBOX" && (
                  <>
                    {/* <Grid.Col
                      span={4}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                     
                      <PLComboBox
                        value={group?.[f?.S13F02]}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [f?.S13F02]: e.value });
                        }}
                      />
                    </Grid.Col> */}
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <PLComboBox
                          value={group?.[f?.S13F02]}
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            //console.log(e)
                            setGroup({ ...group, [f?.S13F02]: e.value });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  </>
                )}

                {f?.S13F14 == "DATEPICKER" && (
                  <>
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <PLDateBox
                          defaultval={
                            group?.[f?.S13F02]?.trim() != ""
                              ? group?.[f?.S13F02]
                              : GetYearAndDate?.strtDate
                          }
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            let { startDate } = DateToString(e);
                            if (startDate?.length == 8) {
                              setGroup({ ...group, [f?.S13F02]: e });
                            }
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                    {/* <Grid.Col
                      span={4}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                    
                      <PLDateBox
                        // value={new Date(group?.[f?.S13F02]).getTime()}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [f?.S13F02]: e });
                        }}
                      />
                    </Grid.Col> */}
                  </>
                )}

                {f?.S13F14 == "TIMEPICKER" && (
                  <>
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <PLDateBox
                          // value={new Date(group?.[f?.S13F02]).getTime()}
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            //console.log(e)
                            setGroup({ ...group, [f?.S13F02]: e });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                    {/* <Grid.Col
                      span={4}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                     
                      <PLDateBox
                        // value={new Date(group?.[f?.S13F02]).getTime()}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [f?.S13F02]: e });
                        }}
                      />
                    </Grid.Col> */}
                  </>
                )}

                {f.S13F14 === "MEMOBOX" && f.S13F21 === "N" && (
                  <>
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <PLTextBox
                          value={group?.[f?.S13F02]}
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            //console.log(e)
                            setGroup({ ...group, [f?.S13F02]: e.target.value });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                    {/* <Grid.Col
                      span={4}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                 
                      <PLTextBox
                        value={group?.[f?.S13F02]}
                        setEdit={(e) => {
                          //console.log(e)
                          setGroup({ ...group, [f?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col> */}
                  </>
                )}

                {f.S13F14 === "MEMOBOX" && f.S13F21 === "H" && (
                  <>
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <PLTextBox
                          value={group?.[f?.S13F02]}
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            //console.log(e)
                            setGroup({ ...group, [f?.S13F02]: e.target.value });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  </>
                )}

                {f.S13F14 === "FILEUPLOAD" && f.S13F21 === "I" && (
                  <>
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <PLTextBox
                          value={group?.[f?.S13F02]}
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            //console.log(e)
                            setGroup({ ...group, [f?.S13F02]: e.target.value });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  </>
                )}

                {f.S13F14 === "FILEUPLOAD" && f.S13F21 === "A" && (
                  <>
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <PLTextBox
                          value={group?.[f?.S13F02]}
                          onKeyDown={(e) => {
                            GlobalClass.formNextInput(e, formRef);
                          }}
                          setEdit={(e) => {
                            //console.log(e)
                            setGroup({ ...group, [f?.S13F02]: e.target.value });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  </>
                )}
              </>
            );
          }
        })
      );
    }
  }
  function dispatchFunction() {
    Promise.resolve(Store.dispatch(DataSaveLoading({ [obj?.cSTID]: false })))
      .then(() => {
        // Store.dispatch(ModalDelete(index));
        // setTimeout(() => {
        if (p0 == "A") {
          Promise.resolve(Store.dispatch(TransactionApi(obj))).then(() => {
            setF02(false);
          })
        } else {
          if (String(props?.index).startsWith("modalForm")) {
            dispatch(TabRemove(props?.index))
          } else {
            dispatch(ModalDelete(props.index))
          }
        }

      })
      .then(() => {
        setBillSeriesHideShow({
          ...BillSeriesHideShow,
          BillNoVal: "",
        });

        setReceiptQty(0);
        setIssueQty(0);
        setIssueAmt(0);
        setReceiptAmt(0);
      });
    let PaginationObj =
      Store?.getState()?.DataSaveLoadingSlice?.PaginationObj;
    Promise.resolve(
      // Store.dispatch(
      //   GetAccountList({
      //     id: "03600342",
      //     name: "Stock Journal",
      //     p0: "MT",
      //     p1: "TXNVOULISTIJ",
      //     p2: "",
      //     p3: "",
      //     type: "A",
      //     pagination: PaginationObj["03600342"],
      //   })
      // )

      dispatch(GetAccountList({ ...obj?.BtnPnlObj, pagination: PaginationObj[obj?.cSTID] }))
    );
  }
  function OnPrint() {
    Promise.resolve(dispatch(DataSaveLoading({ [obj?.cSTID]: false }))).then(() => {
      setDrawerObj({
        ...dra,
        title: <Text fw={700}>Print Voucher</Text>,
        onClickCloseButton: () => {
          // setDrawerObj(dra);
          Promise.resolve(
            setDrawerObj({
              ...DrawerObj,
              open: false,
            })
          ).then(() => dispatchFunction());
        },
        onClose: () => {
          // setDrawerObj(dra);
          Promise.resolve(
            setDrawerObj({
              ...DrawerObj,
              open: false,
            })
          ).then(() => dispatchFunction());
        },
        body: (
          <>
            {/* <PLPrint data={obj} index={props?.index} /> */}
            <PLPrint data={obj} />
          </>
        ),
        open: true,
        size: "sm",
        position: "right",
      });
    });
  }

  function handleOkButton(e) {
    console.log(t02Data?.length, "t02Data?.length", group?.FIELD06);

    // if (t02Data?.length < 2) {
    //   GlobalClass.Notify(
    //     "warning",
    //     "",
    //     "Cannot Submit the form with incomplete Details"
    //   );
    // } else {

    // let newData = {
    //   ...group,
    //   FIELD06: group?.FIELD07 == 0 ? rowSum?.toFixed(2) : group?.FIELD07?.toFixed(2),
    //   // FIELD06: group?.FIELD06?.toFixed(2),
    //   FIELD07: group?.FIELD07?.toFixed(2),
    // }; //toFixed to convert int to float but string.
    // console.log(T41, "T41--", parseFloat(T41?.FIELD06));
    const sortedEditT41 = JSON.stringify({
      ...T41,
      FIELD06: parseFloat(T41?.FIELD06),
      FIELD07: parseFloat(T41?.FIELD07),
    });
    // const sortedGroup = JSON.stringify(newData);
    const sortedGroup = JSON.stringify(group);
    // console.log(newData, "newData", sortedGroup, editT41)
    let newT02 = T02?.filter((v, i) => v?.FIELD03 !== "");
    // console.log(sortedEditT41, "sortedGroup", sortedGroup);

    if (
      obj.p0 == "E" &&
      sortedEditT41 === sortedGroup &&
      compareTwoArrayOfObjects(newT02, t02Data)
    ) {
      Promise.resolve(dispatch(DataSaveLoading({ [obj?.cSTID]: false }))).then(() => {
        if (String(props?.index).startsWith("modalForm")) {
          dispatch(TabRemove(props?.index))
        } else {
          dispatch(ModalDelete(props.index))
        }
      }
      );
      return;
    } else {
      Promise.resolve(Store.dispatch(DataSaveLoading({ [obj?.cSTID]: true }))).then(() =>
        GlobalClass.Notify(
          "info",
          p0 == "A" ? "Adding" : "Editing",
          "Please wait while we process your data"
        )
      );

      let obj1 = accList?.P_M71?.[0]

      let FilterT02Data = t02Data?.filter((t) => t?.FIELD03 != "")
      let dataToSend = {
        T41: e ? e : {
          ...group, FIELD04: obj1?.FIELD01,
          FIELD04NM: obj1?.FIELD02,
        }, T02: [...FilterT02Data]
      };



      var data = {
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: props ? p0 : "A",
        cCode: p0 == "E" ? editId : "IJIJ",
        cSData: JSON.stringify(dataToSend),
        YrNo: localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
      };

      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: p0 == "E" ? p0 : "A",
        cCode: p0 == "E" ? editId : "IJIJ",
      });

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      };
      // console.log(dataToSend, "param Stock Journal");
      // try {
      fetch(
        GlobalClass.ApiUrl + GlobalClass.PostVouEnt + "?pa=" + param,
        requestOptions
      )
        .then((response) => {
          if (!response?.ok) {
            throw new Error(`HTTP error! Status: ${response?.status}`);
          } //This block give more detail info about error other then 2xx status codes.
          return response?.json();
        })
        .then((data) => {
          // console.log("api data=>", data);
          // console.log("api data status=>", data?.status);

          if (data?.status == "SUCCESS") {
            GlobalClass.Notify(
              "success",
              p0 == "A" ? "Added" : "Edited",
              p0 == "A" ? "Added successfully" : "Edited successfully"
            );

            if (OSV._ONLINEVOU == "Y" || OSV._ONLINEVOU == "D") {
              OnPrint()
            } else {
              dispatchFunction();
            }


          } else if (data?.status == "FAIL") {
            Promise.resolve(Store.dispatch(DataSaveLoading({ [obj?.cSTID]: false }))).then(() =>
              GlobalClass.Notify("error", data?.status, data?.message)
            );
          }
        })
        .catch((e) => {
          GlobalClass.Notify("error", "Error", `${e.message}`);
          Store.dispatch(DataSaveLoading({ [obj?.cSTID]: false }));
        });
    }
    // }
  }
  // useEffect(() => {
  //   if (BillSeriesHideShow.BillNoVal.length <= 0) {
  //     setBillSeriesHideShow({
  //       ...BillSeriesHideShow,
  //       DataGridInput: true,
  //       TextInput: false,
  //     });
  //   }
  // }, [BillSeriesHideShow.BillNoVal]);
  // useEffect(() => {
  //   document.addEventListener("keydown", (e) => {
  //     if (e?.key == "Escape") {
  //       if (tableEditingRow == null) {
  //         Promise.resolve()
  //           .then(() => rowCancelHandler())
  //           .then(() => table.setCreatingRow(null));
  //       } else {
  //         Promise.resolve()
  //           .then(() => rowEditCancelHandler())
  //           .then(() => table.setEditingRow(null));
  //       }
  //     }
  //   });
  // }, [tableEditingRow]);
  const NextIndexFun = (index) => {
    const nextInd = index + 1;
    // console.log("nextInd=>", nextInd);
    const nextInput = document.getElementById(`T02Ent_${nextInd}`);
    // console.log("nextInput=>", nextInput);

    if (nextInput) {
      nextInput.focus();
    }
  };
  useEffect(() => {

    console.log("accList", accList);

    if (t02DataENT && t02DataENT?.length > 0) {
      var cols = [];
      oYear.OSC._PRGROUP == "Y" && cols.push(
        columnHelper.accessor("GRPNM", {
          header: "Product Group",
          id: "Product_Group",
          size: 30,
          Edit: ({ cell, row, index }) => {
            let val = cell.getValue();
            // console.log("Cell =>>", cell);
            // return <><Text>Test</Text></>
            // console.log("cell?.id==>", cell?.id);

            // p0 == "A" ? (
            return (
              <PLDataGrid
                disabled={productDisable}
                ref={grpRef}
                width={"500px"}
                TmData={"P_M32G"}
                // data={accList["T_M32C"]}
                value={{ value: t02Obj?.GRPCD, label: t02Obj?.GRPNM }}
                setEdit={(e) => {
                  // console.log("Product Category Obj=>", e);
                  // setFlag(true);
                  setGroupCatogaryProduct({
                    ...GroupCatogaryProduct,
                    Group: e?.FIELD01,
                  });
                  Promise.resolve(
                    setT02Obj({
                      ...t02Obj,
                      GRPCD: e?.FIELD01,
                      GRPNM: e?.FIELD02,
                    })
                  ).then(() => {
                    setTimeout(() => {
                      oYear.OSC._PRCATEGOR == "Y" ? prCt?.current?.handlePopoverToggle() : prt?.current?.handlePopoverToggle();
                    }, 500);
                  });
                }}
              />
            );
            // )
            //  : (
            //   t02Obj?.CATNM
            // );
          },
          Cell: ({ cell, row }) => {
            let val = cell.getValue();
            return t02Data[row.id]?.GRPNM;
          },
        })
      );
      oYear.OSC._PRCATEGOR == "Y" &&
        cols.push(
          columnHelper.accessor("CATCD", {
            header: "Product Catogary",
            id: "Product_Catogary",
            size: 30,
            Edit: ({ cell, row, index }) => {
              let val = cell.getValue();

              //  p0 == "A" ? (
              return (
                <PLDataGrid
                  disabled={productDisable}
                  ref={prCt}
                  width={"500px"}
                  TmData={"P_M32C"}
                  // data={accList["T_M32C"]}
                  value={{ value: t02Obj?.CATCD, label: t02Obj?.CATNM }}
                  setEdit={(e) => {
                    // console.log("Product Category Obj=>", e);
                    // setFlag(true);

                    Promise.resolve(
                      setT02Obj({
                        ...t02Obj,
                        CATCD: e?.FIELD01,
                        CATNM: e?.FIELD02,
                      })
                    ).then(() => {
                      setTimeout(() => {
                        prt?.current?.handlePopoverToggle();
                      }, 500);
                    });
                  }}
                />
              );

              // : (
              //   t02Obj?.CATNM
              // );
            },
            Cell: ({ cell, row }) => {
              let val = cell.getValue();
              return t02Data[row.id]?.CATNM;
            },
          })
        );
      t02DataENT?.map((v, i) => {
        if (v?.S53F00 === "C" && v?.S53F02 !== "EP0000000026") {
          cols.push(
            columnHelper.accessor(v?.S53F04, {
              header: v.S53F07 == 0 ? "" : v.S53F03,
              size: v.S53F07,
              //  minSize: 0,
              // maxSize: v.ColWidth,
              enableGlobalFilter: v.S53F07 == 0 ? false : true,
              enableSorting: v.S53F07 == 0 ? false : v?.lColOrder,
              // v.S53F03 == "ItemWise Expense"
              //   ? ""
              //   : v.S53F03 == "User FIeld"
              //   ? ""
              //   : v.S53F03,
              // enableHiding: true,
              id: v?.S53UID,
              // size: v.S53F07 == 0 ? 0 : 150,
              // v.S53F03 == "ItemWise Expense"
              //   ? 0
              //   : v.S53F03 == "User FIeld"
              //   ? 0
              //   : 150,
              Edit: ({ cell, column, table, row }) => {
                let val = cell?.getValue();
                return renderValue(v?.S53UID, val, v, cell?.id, i, column);
              },
              Footer: () => (
                <div>
                  {v.S53F02 == "E00000000002"
                    ? TotalEntryCalc()
                    : Summary(v, i, "c")}
                </div>
              ),
              Cell: ({ cell, renderedCellValue, row }) => {
                let val = cell?.getValue();

                if (v?.S53F03 == "Expiry Date") {
                  let hasSlash = cell?.getValue()?.includes("/");

                  if (!hasSlash) {
                    if (typeof cell?.getValue() == "string") {
                      let dispExpDate = stringToDate(cell?.getValue());
                      return <>{dispExpDate}</>;
                    }
                  }
                } else if (v?.S53F02 == "E00FIELD20") {
                  //location
                  // console.log(t02Data[row.id]?.F20NM, "t02Data[row.id]?.F20NM")
                  return t02Data[row.id]?.F20NM ?? t02Data[row.id]?.FIELD20;
                } else if (v?.S53F02 == "E00FIELD03") {
                  // console.log(t02Data[row?.id]?.F03NM, "t02Data[row?.id]?.F03NM", t02Data[row.id]?.PrdNM)
                  return t02Data[row?.id]?.F03NM ?? t02Data[row.id]?.PrdNM;
                } else if (v?.S53F02 == "E00FIELD05") {
                  let ret = "";
                  if (val == "C") {
                    ret = "R";
                  } else if (val == "D") {
                    ret = "I";
                  }
                  console.log("ret value", ret);
                  return ret;
                }
                // return val;
                return v.S53F07 == 0 ? "" : val;
              },
            })
          );
        }
      });

      setColumns(cols);
    }
  }, [t02DataENT, t02Obj, T02, accList, tmList, t02Data]);
  // useEffect(() => {
  //   // if (t02Obj?.FIELD22 == 0) {
  //   //   setT02Obj((prevObj) => ({ ...prevObj, FIELD22: 1 }));
  //   // }
  //   // if (t02Obj?.FIELD21 > 0 && t02Obj?.FIELD22 > 0) {
  //     // let qty = t02Obj?.FIELD21 * t02Obj?.FIELD22;
  //     setT02Obj((prevObj) => ({ ...prevObj, FIELD33: t02Obj?.FIELD21 * t02Obj?.FIELD22 }));
  //   // }
  // }, [t02Obj?.FIELD22, t02Obj?.FIELD21]);

  // useEffect(() => {
  //   if (t02Obj?.FIELD33) {
  //     setT02Obj({ ...t02Obj, FIELD21: parseInt(t02Obj?.FIELD33) / parseInt(t02Obj?.FIELD22) })
  //   }
  // }, [t02Obj?.FIELD33])

  useEffect(() => {
    // if (t02Obj?.FIELD33 > 0 && t02Obj?.FIELD07 > 0) {
    // let amount = ;
    setT02Obj((prevObj) => ({ ...prevObj, FIELD08: t02Obj?.FIELD33 * t02Obj?.FIELD07 }));
    // }
  }, [t02Obj?.FIELD33, t02Obj?.FIELD07]);

  useEffect(() => {
    let date = f02 || p0 == "E" ? group?.FIELD02 : vDate?.length !== 0 ? vDate : dateFormater(footerDate);
    // console.log(date, "group?.FIELD02");
    if (date) {
      const year = parseInt(date.substr(0, 4));
      const month = parseInt(date.substr(4, 2)) - 1; // Month is zero-based in JavaScript Date
      const day = parseInt(date.substr(6, 2));
      const dateObject = new Date(year, month, day);
      // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
      const dayOfWeek = dateObject.toLocaleString("en-US", { weekday: "long" });
      setDayVal(dayOfWeek?.slice(0, 3));
    }
  }, [footerDate, vDate]);
  useEffect(() => {
    if (tableEditingRow == null && creatingModeOn) {
      setEnableOk(true);
    } else if (creatingModeOn == false && tableEditingRow != null) {
      setEnableOk(true);
    } else {
      console.log("creatingModeOn", creatingModeOn, tableEditingRow);
      setEnableOk(false);
    }
  }, [creatingModeOn, tableEditingRow]);

  useEffect(() => {
    const formElement = formRef.current;
    if (formElement) {
      formElement.addEventListener("keypress", (e) =>
        GlobalClass.formNextInput(e, formRef)
      );
    }
    return () => {
      if (formElement) {
        formElement.removeEventListener("keypress", (e) =>
          GlobalClass.formNextInput(e, formRef)
        );
      }
    };
  }, [formRef]);
  // let userField = t02DataENT.filter(v=>v.S53F03 == "User FIeld")
  console.log(
    isUserFieldLoading,
    "isUserFieldLoading",
    isLoading,
    AccNameisLoading["T_M32C"],
    SaveLoading
  );

  return (
    <>
      {hasError ? <>
        <Text>{ErrorMsg?.message}</Text>
        <Button onClick={() => {
          if (obj) {
            Promise.resolve(dispatch(TransactionApi({ ...obj, p0: obj.p0 == "U" ? "E" : obj.p0 }))).then(() => {
              dispatch(GetAccountList({
                "id": "P_M21",
                "name": "",
                "type": "A",
                "p0": "P_M21",
                "p1": "P_M21",
                "p2": "",
                "p3": ""
              }))
            }).then(() => {
              if (obj?.p0 == "U") {
                dispatch(GetUserFieldObj(OS11?.S11F01))
              }
            })
          }
        }}>Retry</Button>
      </> : SaveLoading[obj?.cSTID] == true ||
        isLoading ||
        isUserFieldLoading ? (
        GlobalClass.RenderLoader("dots")
      ) : obj?.p0 == "U" ? (
        <>
          <PLUserFieldBtn
            UserFieldArray={UserField?.UFTbl}
            T41={group}
            onChange={(e) => {
              // Promise.resolve(setGroup(e)).then(()=>
              handleOkButton(e);
              // )
            }}
          />
        </>
      ) : (
        <div ref={formRef} onKeyDown={(e) => {
          if (e?.key == "Escape") {
            if (window?.confirm("Are you sure to exit?")) {
              if (String(props?.index).startsWith("modalForm")) {
                dispatch(TabRemove(props?.index));
              } else {
                dispatch(ModalDelete(props.index));
              }
            }
          }
        }}>
          <Modal
            opened={DrawerObj?.open}
            // onClose={close}
            withCloseButton={true}
            fullScreen={false} //
            // overlayProps={{ opacity: 0.5, blur: 4 }}
            closeButtonProps={{
              onClick: (e) => {
                if (typeof DrawerObj?.onClickCloseButton == "function") {
                  DrawerObj?.onClickCloseButton();
                } else {
                  setDrawerObj(dra);
                }
              },
            }}
            title={DrawerObj?.title}
            size={DrawerObj?.size}
            // centered={.Centered}
            closeOnClickOutside={true}
            // overlayProps={m.Overlay}
            position={DrawerObj?.position ?? "bottom"}
            onClose={
              typeof DrawerObj?.onclose == "function"
                ? DrawerObj?.onclose
                : () => {
                  setDrawerObj(dra);
                }
            }
          // scrollAreaComponent={ScrollArea.Autosize}
          >
            {DrawerObj.body}
          </Modal>
          <Paper shadow="md" radius="md" p="xs" withBorder>
            <Grid gutter={4}>
              <Grid.Col
                span={12}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "0",
                  marginTop: "10",
                  marginBottom: "0",
                }}
              >
                <Grid.Col
                  span={5}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  <Grid.Col span={3}>
                    <Text style={{ fontSize: 12 }}>Date</Text>
                  </Grid.Col>
                  <Grid.Col span={7}>
                    <PLDateBox
                      // value={group?.FIELD02}
                      autoFocus={true}
                      onKeyDown={(e) => {
                        if (e?.key == "Tab") {
                          e?.preventDefault();
                          if (vouNoRef?.current) {
                            vouNoRef?.current?.handlePopoverToggle();
                          } else if (document?.getElementById("vouNoRef")) {
                            document?.getElementById("vouNoRef")?.focus();

                          } else {
                            processRef?.current?.handlePopoverToggle();
                          }
                        }
                      }}
                      style={{ margin: "0", padding: "0" }}
                      dispformat="DD/MM/YYYY"
                      setEdit={(e) => {
                        let { startDate } = DateToString(e);
                        if (startDate?.length == 8) {
                          setF02(true);
                          Promise.resolve(
                            setGroup({ ...group, FIELD02: startDate })
                          ).then(() => {
                            setVDate(startDate)
                          }).then(() => {
                            if (vouNoRef?.current) {
                              vouNoRef?.current?.handlePopoverToggle();
                            } else if (document?.getElementById("vouNoRef")) {
                              document?.getElementById("vouNoRef")?.focus();

                            } else {
                              processRef?.current?.handlePopoverToggle();
                            }
                          })
                        }
                      }}
                      defaultval={f02 || p0 == "E" ? group?.FIELD02 : vDate?.length !== 0 ? vDate : dateFormater(footerDate)}
                    />
                  </Grid.Col>
                  <Grid.Col span={1} style={{ color: "red" }}>
                    <Text size={12}> {dayVal} </Text>
                  </Grid.Col>
                </Grid.Col>

                <Grid.Col
                  span={6}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  <Grid.Col span={3}>
                    <Text style={{ fontSize: 12 }}>Process</Text>
                  </Grid.Col>
                  <Grid.Col span={9}>
                    {/* <PLComboBox
                      value={p0 == "E" ? group?.FIELD04 : processName}
                      style={{ fontSize: 12 }}
                      setEdit={(e) => {
                        Store.dispatch(setProcessName(e));
                        setGroup({ ...group, FIELD04: e });
                      }}
                      dispexpr="FIELD02"
                      valexpr="FIELD01"
                      data={P_M71DT}
                    /> */}
                    <PLDataGrid
                      ref={processRef}
                      width={"500px"}
                      // value={p0 == "E" ? group?.FIELD04 : processName}
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                      value={Object.keys(process).length == 0 ? { value: group?.FIELD04, label: group?.FIELD04NM } : { value: process?.FIELD04, label: process?.FIELD04NM }}
                      cWhere={`M71.FIELD03 = '' AND M71.FIELD06 = 'I' `}

                      TmData={P_M71}
                      // data={P_M71DT}
                      // setFocus={(e)=>{
                      //    processRef?.current?.handlePopoverToggle();
                      // }}
                      setEdit={(e) => {
                        console.log(e, "length-");
                        // Store.dispatch(setProcessName(e));
                        Promise.resolve(
                          setGroup({
                            ...group,
                            FIELD04: e.FIELD01,
                            FIELD04NM: e.FIELD02,
                          })).then(() => {
                            setProcess({
                              FIELD04: e.FIELD01,
                              FIELD04NM: e.FIELD02
                            })
                          })
                        // .then(() => {
                        //   console.log("document.get",document?.getElementById("vouNo"))
                        //   document?.getElementById("vouNoRef")?.focus();
                        //   vouNoRef?.current?.handlePopoverToggle();
                        // })
                      }}
                    />
                  </Grid.Col>
                </Grid.Col>
              </Grid.Col>
              <Grid.Col
                span={12}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "0",
                  marginTop: "0",
                  marginBottom: "0",
                }}
              >
                <Grid.Col
                  span={5}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  <Grid.Col span={3}>
                    <Text style={{ fontSize: 12 }}>Vou No.</Text>
                  </Grid.Col>
                  <Grid.Col span={9} >
                    {
                      obj?.p0 == "E" ?
                        <Grid.Col span={12}>
                          <PLTextBox
                            id="vouNoRef"
                            ref={vouNoRef}
                            onKeyDown={(e) => {
                              if (e?.key == "Tab") {
                                processRef?.current?.handlePopoverToggle();
                              }
                            }}
                            setText={(e) => {
                              if (e?.target?.value?.length > 0) {
                                if (accList[obj?.cSTID] && accList[obj?.cSTID].filter((a) => a?.FIELD12 == e?.target?.value)?.length > 0) {
                                  console.log("Duplicate Value log call");
                                  GlobalClass.Notify("warning", "Warning", "Voucher Number Already Present")
                                  setBillNoStatus(true)
                                } else {
                                  setBillNoStatus(false)
                                }
                              } else {
                                setBillNoStatus(false)
                              }
                            }}
                            value={group?.FIELD12}
                            setEdit={(e) => {
                              console.log("setEdit=>", e);
                              setGroup({ ...group, T92F00: "", FIELD12: e.target.value })
                            }}
                          />
                        </Grid.Col> :
                        <Grid.Col span={12}>
                          {
                            T92 ? <>
                              {
                                BillSeriesHideShow?.DataGridInput == true && <>
                                  {
                                    !group?.T92F00 ?
                                      <PLDataGrid
                                        id="vouNoRef"
                                        ref={vouNoRef}
                                        onKeyDown={(e) => {
                                          GlobalClass.formNextInput(e, formRef);
                                        }}
                                        width={"400px"}
                                        position={"unset"}
                                        valexpr={"FIELD00"}
                                        // width={350} 
                                        value={BillSeriesHideShow?.BillNoVal}
                                        TmData={T92}
                                        setFocus={(e) => {
                                          console.log("vouNoRef", vouNoRef)
                                          vouNoRef?.current?.handlePopoverToggle();
                                        }}
                                        // data={T92DT}
                                        setEdit={(e) => {
                                          console.log("Bill No.=>", e)
                                          // Promise.resolve(setGroup({ ...group, T92F00: e.FIELD01 })).then(()=>{
                                          //   setBillSeriesHideShow({...BillSeriesHideShow,DataGridInput:false,TextInput:true,BillNoVal:e.FIELD01})
                                          // })
                                          //"FIELD00"
                                          Promise.resolve(setGroup({ ...group, T92F00: e.FIELD00, FIELD12: "" })).then(() => {
                                            if (e) {
                                              var letr = e.FIELD02
                                              console.log("num=>", num);
                                              var num = parseInt(e.FIELD03) + 1
                                              setBillSeriesHideShow({ ...BillSeriesHideShow, DataGridInput: false, TextInput: true, BillNoVal: letr + num })
                                            }
                                          }).then(() => {
                                            processRef?.current?.handlePopoverToggle();
                                          })

                                        }}
                                      /> : <PLTextBox
                                        ref={vouNoRef}
                                        id="vouNoRef"
                                        setText={(e) => {
                                          // console.log("bill number setText 1", [{
                                          //   "e": e,
                                          //   "value": e?.target?.value
                                          // }]);
                                          if (e?.target?.value?.length > 0) {
                                            if (accList[obj?.cSTID] && accList[obj?.cSTID].filter((a) => a?.T41BNO == e?.target?.value)?.length > 0) {
                                              console.log("Duplicate Value log call");
                                              GlobalClass.Notify("warning", "Warning", "Voucher Number Already Present")
                                              setBillNoStatus(true)
                                            } else {
                                              setBillNoStatus(false)
                                            }
                                          } else {
                                            setBillNoStatus(false)
                                          }
                                        }}
                                        onKeyDown={(e) => {
                                          if (e?.key == "Tab") {
                                            processRef?.current?.handlePopoverToggle();
                                          }
                                        }}
                                        value={group?.FIELD12}
                                        // value={group?.T92F00}
                                        setEdit={(e) => {
                                          console.log("setEdit=>", e);
                                          // setBillSeriesHideShow({ ...BillSeriesHideShow, BillNoVal: e.target.value })
                                          setGroup({ ...group, T92F00: "", FIELD12: e.target.value })
                                        }}
                                      />
                                  }
                                </>
                              }
                              {BillSeriesHideShow?.TextInput == true && <PLTextBox
                                ref={vouNoRef}
                                id="vouNoRef"
                                setText={(e) => {
                                  // console.log("bill number setText 2", [{
                                  //   "e": e,
                                  //   "value": e?.target?.value
                                  // }]);
                                  if (e?.target?.value?.length > 0) {
                                    if (accList[obj?.cSTID] && accList[obj?.cSTID].filter((a) => a?.T41BNO == e?.target?.value)?.length > 0) {
                                      console.log("Duplicate Value log call");
                                      GlobalClass.Notify("warning", "Warning", "Voucher Number Already Present")
                                      setBillNoStatus(true)
                                    } else {
                                      setBillNoStatus(false)
                                    }
                                  } else {
                                    setBillNoStatus(false)
                                  }
                                  if (BillSeriesHideShow.BillNoVal.length <= 0) {
                                    setBillSeriesHideShow({ ...BillSeriesHideShow, DataGridInput: true, TextInput: false })
                                  }
                                }}
                                onKeyDown={(e) => {
                                  if (e?.key == "Tab") {
                                    processRef?.current?.handlePopoverToggle();
                                  }
                                }}
                                value={BillSeriesHideShow?.BillNoVal}
                                // value={group?.T92F00}
                                setEdit={(e) => {
                                  console.log("setEdit=>", e);
                                  setBillSeriesHideShow({ ...BillSeriesHideShow, BillNoVal: e.target.value })
                                  setGroup({ ...group, T92F00: "", FIELD12: e.target.value })
                                }}
                              />
                              }
                            </> :
                              < PLTextBox
                                id="vouNoRef"
                                ref={vouNoRef}
                                setText={(e) => {
                                  // console.log("bill number setText 3", [{
                                  //   "e": e,
                                  //   "value": e?.target?.value
                                  // }]);
                                  if (e?.target?.value?.length > 0) {
                                    if (accList[obj?.cSTID] && accList[obj?.cSTID].filter((a) => a?.FIELD12 == e?.target?.value)?.length > 0) {
                                      console.log("Duplicate Value log call");
                                      GlobalClass.Notify("warning", "Warning", "Voucher Number Already Present")
                                      setBillNoStatus(true)
                                    } else {
                                      setBillNoStatus(false)
                                    }
                                  } else {
                                    setBillNoStatus(false)
                                  }
                                }}
                                value={BillSeriesHideShow?.BillNoVal}
                                setEdit={(e) => {
                                  console.log("setEdit=>", e);
                                  setBillSeriesHideShow({ ...BillSeriesHideShow, BillNoVal: e.target.value })
                                  // setGroup({ ...group, T92F00: "", FIELD12: e?.target?.value })
                                  // if(EntryPages?.PurchaseEntry?.includes(obj?.page) || EntryPages?.PurchaseEntry?.includes(obj?.id)){
                                  //   setGroup({ ...group, T92F00: "", FIELD12: e.target.value, FIELD10: e.target.value })
                                  // }else{
                                  setGroup({ ...group, T92F00: "", FIELD12: e.target.value })
                                  // }
                                }}
                                onKeyDown={(e) => {
                                  if (e?.key == "Tab") {
                                    processRef?.current?.handlePopoverToggle();
                                  }
                                }}
                              />
                          }
                        </Grid.Col>
                    }
                  </Grid.Col>
                </Grid.Col>
              </Grid.Col>
            </Grid>
            {
              UFT41H?.length > 0 && UFT41Hval?.length > 0 &&
              <>
                <Divider
                  my="xs"
                  label={<Button
                    variant="subtle"
                    color="dark"
                    size="xs"
                    compact
                    onFocus={() => {
                      if (UFT41H?.filter((af) => af.S13HMF == "H")?.length > 3) {
                        spoilerControlRef.current.click()
                      }
                    }}
                  >User Fields</Button>}
                  labelPosition="center"
                  labelProps={{ component: "strong" }}
                />

                <Spoiler
                  // initialState={userFieldSpoiler}
                  // initialState={true}

                  maxHeight={40}
                  showLabel
                  hideLabel
                  controlRef={spoilerControlRef}
                >
                  <Grid gutter={2} onKeyDown={(e) => {
                    GlobalClass.formNextInput(e, formRef);
                  }}>{UFT41HFieldRenderer("Header")}</Grid>
                </Spoiler>
              </>
            }
            <Divider
              my="xs"
              label={<Button
                variant="subtle"
                color="dark"
                size="xs"
                compact
                onFocus={() => {
                  if (UFT41H?.filter((af) => af.S13HMF == "H")?.length > 3) {
                    spoilerControlRef.current.click()
                  }
                  TableContainer?.current?.focus()

                  // TableRef?.current?.focus()

                  setRowSelection({ 0: true })
                }}
              >Products</Button>}
              labelPosition="center"
              labelProps={{ component: "strong" }}
            />
            <Grid gutter={4}
            // onKeyUp={(e) => {
            //   GlobalClass.formNextInput(e, formRef);
            // }}
            >
              <Grid.Col span={12} my={7}>
                <MantineReactTable table={table} />
              </Grid.Col>
            </Grid>
            {/* <Group h={50} spacing={"xs"} position="center">
              <Button
                variant="light"
                compact
                onClick={() => {
                  if (table?.getSelectedRowModel()?.flatRows.length > 0) {
                    setT02Data([
                      ...t02Data,
                      table?.getSelectedRowModel()?.flatRows[0]?.original,
                    ]);
                  }
                }}
              >
                Copy
              </Button>
              <Button variant="light" compact>
                Serial No.
              </Button>
            </Group> */}
            {/* <Divider my={2} /> */}

            {/* <Center>
              <Button size="xs" my={2}>
                Import
              </Button>
            </Center> */}
            <Grid style={{ display: "flex", justifyContent: "flex-end" }}>
              {UFT41Fval?.length > 0 ? (
                <Grid.Col span={6}>{UFT41HFieldRenderer("Footer")}</Grid.Col>
              ) : (
                ""
              )}
              <Grid.Col span={6}>
                <Box
                  maw={500}
                  p="md"
                  mx="auto"
                  ta="center"
                // style={{  alignItems: "center" }}
                >
                  <Group grow wrap="nowrap" mb={2}>
                    <Text></Text>
                    <Text style={{ fontSize: 12 }}>Quantity</Text>
                    <Text style={{ fontSize: 12 }}>Amount</Text>
                  </Group>
                  <Group grow wrap="nowrap" mb={6}>
                    <Text style={{ fontSize: 12 }}>Input</Text>
                    <Text style={{ fontSize: 12 }}>{ReceiptQtySum}</Text>
                    <Text style={{ fontSize: 12 }}>{p0 == "A" ? recieptAmountSum : group?.FIELD06}</Text>
                    {/* <Text>{group?.FIELD06}</Text> */}
                  </Group>

                  <Group grow preventGrowOverflow={false} wrap="nowrap">
                    <Text style={{ fontSize: 12 }}>Output</Text>
                    <Text style={{ fontSize: 12 }}>{IssueQtySum}</Text>
                    <Text style={{ fontSize: 12 }}>{p0 == "A" ? IssueAmountSum : group?.FIELD07}</Text>
                    {/* <Text>{group?.FIELD07}</Text> */}
                  </Group>
                </Box>
              </Grid.Col>
            </Grid>
            <Grid span={12} style={{ display: "flex", alignItems: "flex-end" }}>
              <Grid.Col
                span={6}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                {/* <Grid.Col span={4}> */}
                <Grid.Col span={9}>
                  <Text size={12}>Narration</Text>
                  {/* </Grid.Col> */}

                  <PLTextarea
                    minRows={2}
                    value={group?.FIELD15}
                    onChange={(e) => {
                      // //console.log(e)
                      setGroup({ ...group, FIELD15: e.target.value });
                    }}
                    onKeyDown={(e) => {
                      // console.log("Narration=>", e.shiftKey);

                      // GlobalClass.formNextInput(e, formRef);
                      if (e.ctrlKey && e.key === "F1") {
                        setDrawerObj({
                          ...dra,
                          title: <Text fw={700}>Naration</Text>,
                          body: (
                            <PLNarration
                              NarrationArray={NarrationData}
                              Flag={
                                SelectedNarrationData?.length > 0 ? false : true
                              }
                              selectedNarrationArray={SelectedNarrationData}
                              // NarrationVal={group}
                              setNarrationArray={(e) => {
                                // setGroup(e)
                                // console.log("setNarrationArray", e);
                                setNarrationData(e.NarrationData);
                                setSelectedNarrationData(e.selectedNarration);
                              }}
                              DrawerOBJ={DrawerObj}
                              setDrawerOBJ={(e) => setDrawerObj(e)}
                            />
                          ),
                          open: true,
                          size: "md",
                          position: "right",
                        });

                        // modals.open({
                        //   title: "Naration",
                        //   size: "50%",
                        //   zIndex: 301,
                        //   children: (
                        //     <>
                        //       <PLNarration
                        //         NarrationArray={NarrationData}
                        //         selectedNarrationArray={SelectedNarrationData}
                        //         setNarrationArray={(e) => {
                        //           setNarrationData(e.NarrationData);
                        //           setSelectedNarrationData(e.selectedNarration);
                        //         }}
                        //       />
                        //     </>
                        //   ),
                        // });
                      }
                    }}
                  />
                </Grid.Col>
              </Grid.Col>
              <Grid.Col span={6}>
                <Box style={{ overflow: "hidden" }}>
                  <Box maw={300} p="md" mx="auto">
                    <Group grow preventGrowOverflow={false} wrap="nowrap">
                      <Button
                        style={{ height: "30px", width: "100px" }}
                        size="sm"
                        disabled={
                          // t02Data?.length < 1 ||
                          enableOk
                        }
                        onClick={() => BillNoStatus ? GlobalClass.Notify("warning", "Warning", "Voucher Number Already Present") : handleOkButton()}
                      >
                        Ok
                      </Button>
                      <Button
                        style={{ height: "30px", width: "100px" }}
                        size="sm"
                        onClick={OnPrint}>
                        Print
                      </Button>
                    </Group>
                  </Box>
                </Box>
              </Grid.Col>
            </Grid>
          </Paper>
          {lEditVou == false && <> {GlobalClass.Alert("info", props.index)}</>}
        </div>
      )}
    </>
  );
}
