import * as AccountLedgerProductLedgerForm from "../ReportComponent/Account Books/LedGer/AccLedger";
import * as ProductMaster from "../FunctionsCall/ProductList/ProductList";

export const PLED_MAIN = (itemOBJ) => {
    const OBJ = itemOBJ;
    console.log('executeFunction PLED_MAIN ===>', OBJ);
    switch (OBJ?.cSTID) {
        case "PRODLEDGER":
            console.log('executeFunction PLED_MAIN 1===>', OBJ);
            AccountLedgerProductLedgerForm.TM_ZINOUT(JSON.stringify(OBJ))
            break;
        case "PLED_MAIN":
            ProductMaster.M21AED(JSON.stringify(OBJ))
            break;
        default:
            break;
    }
}