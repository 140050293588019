import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import PLDataGrid from '../../PlusComponent/PLDataGrid'
// import * as AccountLedgerProductLedgerForm from "../ReportComponent/Account Books/LedGer/AccLedger";
import * as AccountLedgerProductLedgerForm from "../../ReportComponent/Account Books/LedGer/AccLedger";

const SingleLedgerVoucherListPopup = () => {

    // const dispatch = useDispatch()

    const [VoucherObj, setVoucherObj] = useState({})

    //    S11F01 = S11F00 AND S11FTY LIKE '%V%'

    return (<>
        <PLDataGrid
            position={"unset"}
            // width={"500px"}
            size={"0.6"}
            value={VoucherObj?.S11F02}
            // dispexpr={"T41BNO"}
            TmData={"P_S11"}
            cWhere={`S11.S11F01 = S11.S11F00 AND S11.S11FTY LIKE '%V%'`}
            setEdit={(e) => {
                console.log("SelectedVoucher obj=>", e)
                setVoucherObj(e)
                AccountLedgerProductLedgerForm.TM_ZINOUT(JSON.stringify({
                    p0: "A",
                    text: "Add",
                    DataGridRow: {
                        T41PVT: e?.S11F00
                    }
                }))
            }}
        />
    </>)
}

export default SingleLedgerVoucherListPopup