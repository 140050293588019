import { modals } from "@mantine/modals";
import { Text } from "@mantine/core";
import Store from "../../utils/store";
import { TransactionDeleteApi } from "../../utils/TransactionSlices/Transaction";
import TransactionForm from "./TransactionForm";
import { ModalFunction } from "../../utils/slices/ModalSlice";
import GlobalClass from "../../utils/GlobalClass";
import { GetInvTypeDropDown } from "../../utils/TransactionSlices/TSalesSlices";
import { TM_PRINTVF } from "../../components/PrintVoucherReport";
import PLRange from "../../PlusComponent/PLRange";
import SIDetailPage from "../../Transaction/SaleEntry/SIDetailPage";

export function VouEntAct(data) {
  const OBJ = JSON.parse(data);
  console.log("OBJ", OBJ);

  if (OBJ.p0 === "A") {
    Store.dispatch(
      ModalFunction({
        onClickCloseButton: () => {
          // Deval (For Company Setup)
          Store.dispatch(GetInvTypeDropDown(null))
        },
        onclose: (e) => { },
        MTitle: <Text fw={700}>{OBJ?.text} {OBJ?.BtnPnlObj?.name}</Text>,
        MAction: true,
        MSize: "80%",
        position: 'right',
        MBody: (i) => <TransactionForm OBJ={OBJ} index={i} />,
        MClose: true,
        tab: OBJ.page.startsWith("P_") ? false : true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        },
        // onclose: () => { },
        // MTitle: <Text fw={700}>{OBJ?.text}</Text>,
        // MAction: true,
        // MSize: "xl",
        // MBody: (i) => (
        //     <TransactionForm OBJ={OBJ} index={i} />
        // ),
        // MClose: true,
        // Overlay: {
        //     backgroundOpacity: 0.8,
        //     blur: 5,
        // }
      })
    )


    //====================================================
    // modals.open({
    //     title: <Text style={{ fontWeight: 700 }}>{OBJ?.text}</Text>,
    //     size: '100%',
    //     children:
    //         <>
    //             <TransactionForm OBJ={OBJ} />
    //         </>
    // })
  }

  else if (OBJ.id && OBJ.p0 === "E") {
    Store.dispatch(
      ModalFunction({
        onClickCloseButton: () => {
          // Deval (For Company Setup)
          Store.dispatch(GetInvTypeDropDown(null))
        },
        onclose: (e) => { },
        MTitle: <Text fw={700}>{OBJ?.text} {OBJ?.BtnPnlObj?.name}</Text>,
        MAction: true,
        MSize: "80%",
        position: 'right',
        MBody: (i) => <TransactionForm OBJ={OBJ} index={i} />,
        MClose: true,
        tab: OBJ.page.startsWith("P_") ? false : true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        },
      })
    )
    //====================================================
    // modals.open({
    //     title: <Text style={{ fontWeight: 700 }}>{OBJ?.text}</Text>,
    //     size: '100%',
    //     children:
    //         <>
    //             <TransactionForm OBJ={OBJ} />
    //         </>
    // })
  }
  else if (OBJ.id && OBJ.p0 === "D") {
    // const confirm = window.confirm("Are you sure ?");
    // if (confirm) {
    //   Store.dispatch(TransactionDeleteApi(OBJ))
    // }

    GlobalClass.Confirm(
      "red",
      "Are you sure to delete selected data?",
      "It will be Deleted Forever and Cannot Be Recovered!!!",
      () => {
        Store.dispatch(TransactionDeleteApi(OBJ))
      },
      () => { },
      "Cancel",
      "Confirm"
    )
  }
  else if (OBJ.id && OBJ.p0 === "P") {
    // eval('TM_PRINTVF('+OBJ+')')
    TM_PRINTVF(data);
  }
  else if (OBJ.p0 == "R") { //Range Button
    Store.dispatch(
      ModalFunction({
        onclose: () => { },
        MTitle: <Text fw={500}>Enter Voucher No. Range</Text>,
        MAction: true,
        MSize: "98%",
        position: "right",
        MBody: (i) => <PLRange obj={OBJ} index={i} />,
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        },
      })
    );
  }
  else if (OBJ?.p0 === 'U' && OBJ?.id) { // UserField
    Store.dispatch(ModalFunction({
      MTitle: <Text fw={700}>{OBJ?.text}</Text>,
      MAction: true,
      MSize: "50%",
      // position:'right',
      MBody: (i) => (<TransactionForm OBJ={OBJ} index={i} />),
      MClose: true,
      onclose: () => { },
      onClickCloseButton: () => {
        // Promise.resolve(Store.dispatch(GetInvTypeDropDown(null))).then(() => Store.dispatch(GetPendingQuotationDataNull([]))).then(() => Store.dispatch(GetSingleSelectedOrderRowNull([]))).then(() => Store.dispatch(GetPendingOrderDataNull([]))).then(() => Store.dispatch(GetSingleOrderRowNull([]))).then(() => Store.dispatch(GetExpenseDataNull([]))).then(() => Store.dispatch(GetNullTransactionData())).then(() => Store.dispatch(GetPreFillTransactionFormData(PreFillFormData)))
      }
    }))
  }
  else if (OBJ.p0 === 'VD' && OBJ?.id) { // Detail
    Store.dispatch(ModalFunction({
      MTitle: <Text fw={700}>{OBJ?.text}</Text>,
      MAction: true,
      MSize: "50%",
      MBody: (i) => (<SIDetailPage obj={OBJ} index={i} />),
      MClose: true,
      onclose: () => { }
    }))
  } else if (OBJ.p0 === 'T' && OBJ?.id) { //Audit
    // if (window.confirm('Confirm to Audit ?')) {
    //     Store.dispatch(TransactionDeleteApi(OBJ))
    // }

    GlobalClass.Confirm(
      "blue",
      "Confirm to Audit ?",
      "Are you sure you want to proceed!!!",
      () => {
        Store.dispatch(TransactionDeleteApi(OBJ))
      },
      () => { },
      "Cancel",
      "Confirm"
  )
  }

}