import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import gensetting from "../gensetting";
import axios from "axios";
import GlobalClass from "../GlobalClass";
// import { notifications } from "@mantine/notifications";
import { modals } from "@mantine/modals";
import Store from "../store";
import { GetAccountList } from "../slices/AccountListSlice";

let PageObjectcOPara = {
  "S!": "CTYPE~C~S!",
  "P!": "CTYPE~C~P!",
  "TXNVOULISTJC": "CTYPE~C~JC",
  "TXNVOULISTJD": "CTYPE~C~JD",
  "TXNVOULISTSJ": "CTYPE~C~J2",
  "J2": "CTYPE~C~J2",
  "TXN_GSTLSTNC": "CTYPE~C~EG",
  "TXNVOULISTEG": "CTYPE~C~EG",
  "TXN_GSTLSTND": "CTYPE~C~EG",
  "TXNVOULISTIG": "CTYPE~C~IG"
}

let PageObjectcCode = {
  "T41VLISTBPBP": "BPBP",
  "T41VLISTCPCP": "CPCP",
  "T41VLISTBRBR": "BRBR",
  "T41VLISTCRCR": "CRCR",
  "T41VLISTSSSS": "SSSS",
  "02230213": "SSWS", //unknown
  "02270213": "SRWR", //unknown
  "T41VLISTSRSR": "SRSR",
  "T41VLISTHSHS": "HSHS",
  "T41VLISTOSOS": "OSOS",
  "S!": "SSSS",
  "T41VLISTQTQT": "QTQT",
  "T41VLISTPPPP": "PPPP",
  "T41VLISTPRPR": "PRPR",
  "T41VLISTHPHP": "HPHP",
  "P!": "PPPP",
  "T41VLISTOPOP": "OPOP",
  "T41VLISTBCBC": "BCBC",
  "TXNVOULISTIN": "ININ", // Transaction > Production
  "QP": "ININ", // Transaction > Quick Production
  "TXNVOULISTJA": "JAJA",
  "TXNVOULISTHC": "HCHC",
  "TXNVOULISTHD": "HDHD",
  "TXNVOULISTHO": "HOHO",
  "TXNVOULISTIJ": "IJIJ",
  "T41VOULISTTS": "TSTS",
  "TXNVOULISTOJ": "OJOJ",
  "TXNVOULISTJI": "JIJI",
  "TXNVOULISTJO": "JOJO",
  "TXNVOULISTJJ": "JJJJ",
  "TXNVOULISTJX": "JXJX",
  "TXNVOULISTJW": "JWJW",
  "TXNVOULISTJY": "JYJY",
  "TXNVOULISTJZ": "JZJZ",
  "J1": "JZJZ", //jobwork in > bill from Challan
  "J!": "JJ", //jobwork out > bill from Challan
  "TXNVOULISTJC": "JXJX",
  "TXNVOULISTJD": "JYJY",
  "TXNVOULISTSJ": "JJJJ",
  "J2": "JJJJ", // subjobwork > bill from Challan
  "TXN_GSTLSTNC": "NCNC",
  "TXNVOULISTEG": "JRJR",
  "TXN_GSTLSTND": "NDND",
  "TXNVOULISTIG": "JRJR",
  "TXNVOULISTLD": "LDLD",
  "T41VLISTJRBP":"JRBP",
  "T41VLISTJRBR":"JRBR",
  "T41VLISTJRCP":"JRCP",
  "T41VLISTJRCR":"JRCR",
  "T41VLISTJRBC":"JRBC",
  "T41VLISTJRNC":"JRNC",
  "T41VLISTJRND":"JRND",
  "T41VLISTJRJR":"JRJR"
}

const getCoPara = (e) => {
  // console.log("getCoPara =>", PageObject[e?.page]);
  return (PageObjectcOPara[e?.cSTID] || "")
}
const getcCode = (e) => {
  // console.log("getCoPara =>", PageObject[e?.page]);
  return (PageObjectcCode[e?.cSTID] || "")
}

export const TransactionApi = createAsyncThunk(
  "Transaction/TransactionApi",
  async (OBJ) => {
    console.log('Transaction/TransactionApi==>', OBJ);

    try {
      var p = {
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: OBJ?.p0,
        cCode: ["E", "VD", "T"].includes(OBJ?.p0) ? OBJ?.id : OBJ?.cCode ? OBJ?.cCode : getcCode(OBJ),
        "cOPara": getCoPara(OBJ),
        "Yrno": localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
      };
      if (OBJ.lJournal) {
        p = { ...p, lJournal: OBJ.lJournal ?? "" };
      }
      let param = JSON.stringify(p);
      const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.GetVouEnt + "?pa=" + param);
      // console.log(response, "response");
      if (OBJ.cSTID === "T41VLISTBCBC" || OBJ?.isContraPage) {
        return [response.data.data, OBJ.cSTID]
      }
      else {
        return response.data.data;
      }
    } catch (error) {
      GlobalClass.Notify("error", "Error", error.message);
      // console.log("Transaction Api", error);
      return { error };
    }
  }
);

let ChallancCodePages = {
  "S!": "SSSS",
  "P!": "PPPP",
  "J!": "JJJJ",
  "J2": "JJJJ",
  "J1": "JZJZ"
}

let ChallancOParaPages = {
  "S!": "CTYPE~C~S!",
  "P!": "CTYPE~C~P!",
  "J!": "CTYPE~C~J!",
  "J2": "CTYPE~C~J2",
  "J1": "CTYPE~C~J1"
}

const getChallancCodePages = (e) => {
  return (ChallancCodePages[e?.cSTID] || "")
}

const getChallancOParaPages = (e) => {
  return (ChallancOParaPages[e?.cSTID] || "")
}
export const BillFormChallanPartyAc = createAsyncThunk(
  "Transaction/BillFormChallanPartyAc",
  async (OBJ) => {
    // console.log('Transaction/TransactionApi==>', OBJ);
    try {
      var p = {
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: OBJ.p0,
        cCode: getChallancCodePages(OBJ),
        cOPara: getChallancOParaPages(OBJ),
        "Yrno": localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
      };
      let param = JSON.stringify(p);
      const response = await axios.get(
        GlobalClass.ApiUrl + GlobalClass.GetVouPrtData + "?pa=" + param
      );
      console.log(response, "response");
      return response.data.data;
    } catch (error) {
      GlobalClass.Notify("error", "Error", error.message);
      // console.log("Transaction Api", error);
      return { error };
    }
  }
)

export const TransactionDeleteApi = createAsyncThunk(
  "Transaction/TransactionDeleteApi",
  async (OBJ) => {
    GlobalClass.Notify(
      "info",
      OBJ.p0 == "D" ? "Deleting" : "Audit",
      "Please wait while we process your data"
    );
    console.log("TransactionDeleteApi =>", OBJ);
    try {
      let p = {
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: OBJ.p0,
        cCode: OBJ.id,
        cOPara: "",
        "Yrno": localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
      };
      if (OBJ.lJournal) {
        p = { ...p, lJournal: OBJ.lJournal ?? "" };
      }
      let param = JSON.stringify(p);
      const response = await axios.get(
        GlobalClass.ApiUrl + GlobalClass.GetVouEnt + "?pa=" + param
      );
      // console.log(response.data);

      if (response.data.status === "SUCCESS") {
        modals.closeAll();
        GlobalClass.Notify("success", OBJ.p0 == "D" ? "Deleted" : "Audit", OBJ.p0 == "D" ? "Deleted successfully" : "Audit SuccessFull");



        let PaginationObj = Store.getState().DataSaveLoadingSlice.PaginationObj;
        // console.log("paginationObj",PaginationObj)

        Store.dispatch(GetAccountList({ ...OBJ?.BtnPnlObj, pagination: PaginationObj[OBJ?.cSTID] }))

       
      } else {
        GlobalClass.Notify(
          "error",
          response?.data?.status,
          response?.data?.message
        );
      }
    } catch (e) {
      GlobalClass.Notify("error", "Error", `${e?.message}`);
      return { e };
    }
  }
);

let BatchLogcOParaPagesObj = {
  "S!": "CTYPE~C~S!",
  "P!": "CTYPE~C~P!",
  "TXNVOULISTJC": "CTYPE~C~JC",
  "TXNVOULISTJD": "CTYPE~C~JD",
  "TXNVOULISTSJ": "CTYPE~C~J2",
  "J2": "CTYPE~C~J2",
  "TXN_GSTLSTNC": "CTYPE~C~EG",
  "TXNVOULISTEG": "CTYPE~C~EG",
  "TXN_GSTLSTND": "CTYPE~C~EG",
  "TXNVOULISTIG": "CTYPE~C~IG"
}

const getBatchLogcOParaPagesObj =(e)=>{
  return (BatchLogcOParaPagesObj[e?.cSTID] || "")
}

export const BatchLogAddRemoveFun = createAsyncThunk(
  "Transaction/BatchLogAddRemoveFun",
  async ({ PageCode, T41Obj, T02Data, Row, OBJ }) => {
    console.log("BatchLogAddRemove parameter =>", [{
      "T41Obj": T41Obj,
      "T02Data": T02Data,
      "Row": Row,
      "OBJ": OBJ
    }]);
    if (Array.isArray(Row)) {
      Row?.map((TObj) => {
        let PostObj = { T02: TObj }
        var data = {
          CorpID: gensetting.getcorpid(),
          cUser: gensetting.getcuser(),
          cPass: gensetting.getcpass(),
          CmpNo: gensetting.getcmpno(),
          cAction: "A",
          cCode: T41Obj?.FIELD01 == "" ? PageCode : T41Obj?.FIELD01,
          cOPara:getBatchLogcOParaPagesObj(OBJ),
          cSData: JSON.stringify(PostObj),
          YrNo:localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
        };

        let param = JSON.stringify({
          CorpID: gensetting.getcorpid(),
          cUser: gensetting.getcuser(),
          cPass: gensetting.getcpass(),
          CmpNo: gensetting.getcmpno(),
          cAction: "A",
          cCode: T41Obj?.FIELD01 == "" ? PageCode : T41Obj?.FIELD01,
          cOPara:getBatchLogcOParaPagesObj(OBJ),
        });
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        };
        fetch(
          GlobalClass.ApiUrl + GlobalClass.BatchLog + "?pa=" + param,
          requestOptions
        )
          .then((response) => response.json())
          .then((data) => {
            if (data.status == "SUCCESS") {
              GlobalClass.Notify(
                "success",
                "Success:",
                "Batch_Log Added Successfully"
              );
            } else if (data.status == "FAIL") {
              GlobalClass.Notify("error", data?.status, data?.message);
            }
          })
          .catch((e) => GlobalClass.Notify("error", "Error", `${e?.message}`))
      })


    } else {
      let PostObj = { T02: Row };

      console.log("BatchLogAddRemove PostObj=>", PostObj);
      let GetObj = T02Data.find((a, i) => a.FIELD01 == Row.FIELD01);

      if (Row.FIELD01 != "" && GetObj.FIELD01 == Row.FIELD01) {
        console.log("BatchLogAddRemove Update Call")
        var data = {
          CorpID: gensetting.getcorpid(),
          cUser: gensetting.getcuser(),
          cPass: gensetting.getcpass(),
          CmpNo: gensetting.getcmpno(),
          cAction: "R",
          cCode: T41Obj?.FIELD01 == "" ? PageCode : T41Obj?.FIELD01,
          // "cOPara": "",
          cSData: JSON.stringify(PostObj),
          YrNo:localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
        };

        let param = JSON.stringify({
          CorpID: gensetting.getcorpid(),
          cUser: gensetting.getcuser(),
          cPass: gensetting.getcpass(),
          CmpNo: gensetting.getcmpno(),
          cAction: "R",
          cCode: T41Obj?.FIELD01 == "" ? PageCode : T41Obj?.FIELD01,
          "Yrno": localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
          // "cOPara": "",
          // "cSData": JSON.stringify(PostObj)
        });
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        };
        fetch(GlobalClass.ApiUrl + GlobalClass.BatchLog + "?pa=" + param, requestOptions)
          .then((response) => response.json())
          .then((data) => {
            if (data.status == "SUCCESS") {
              // GlobalClass.Notify('success', 'Success:' + 'Successfull', 'Remove Successfully')
              var data = {
                CorpID: gensetting.getcorpid(),
                cUser: gensetting.getcuser(),
                cPass: gensetting.getcpass(),
                CmpNo: gensetting.getcmpno(),
                cAction: "A",
                cCode: T41Obj?.FIELD01 == "" ? PageCode : T41Obj?.FIELD01,
                // "cOPara": "",
                cSData: JSON.stringify(PostObj),
                YrNo:localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
              };

              let param = JSON.stringify({
                CorpID: gensetting.getcorpid(),
                cUser: gensetting.getcuser(),
                cPass: gensetting.getcpass(),
                CmpNo: gensetting.getcmpno(),
                cAction: "A",
                cCode: T41Obj?.FIELD01 == "" ? PageCode : T41Obj?.FIELD01,
                "Yrno": localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
                // "cOPara": "",
                // "cSData": JSON.stringify(PostObj)
              });
              const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(data),
              };
              fetch(GlobalClass.ApiUrl + GlobalClass.BatchLog + "?pa=" + param, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                  if (data.status == "SUCCESS") {
                    GlobalClass.Notify(
                      "success",
                      "Success:",
                      "Batch_Log Updated"
                    );
                  } else if (data.status == "FAIL") {
                    GlobalClass.Notify("error", data?.status, data?.message);
                  }
                })
                .catch((e) =>
                  GlobalClass.Notify("error", "Error", `${e?.message}`)
                );
            } else if (data.status == "FAIL") {
              GlobalClass.Notify("error", data?.status, data?.message);
            }
          })
          .catch((e) => GlobalClass.Notify("error", "Error", `${e?.message}`));
      } else {
        console.log("BatchLogAddRemove Add Call")

        var data = {
          CorpID: gensetting.getcorpid(),
          cUser: gensetting.getcuser(),
          cPass: gensetting.getcpass(),
          CmpNo: gensetting.getcmpno(),
          cAction: "A",
          cCode: T41Obj?.FIELD01 == "" ? PageCode : T41Obj?.FIELD01,
          cOPara:getBatchLogcOParaPagesObj(OBJ),
          cSData: JSON.stringify(PostObj),
          YrNo:localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
        };

        let param = JSON.stringify({
          CorpID: gensetting.getcorpid(),
          cUser: gensetting.getcuser(),
          cPass: gensetting.getcpass(),
          CmpNo: gensetting.getcmpno(),
          cAction: "A",
          cCode: T41Obj?.FIELD01 == "" ? PageCode : T41Obj?.FIELD01,
          cOPara:getBatchLogcOParaPagesObj(OBJ),
          "Yrno": localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
        });
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        };
        fetch(
          GlobalClass.ApiUrl + GlobalClass.BatchLog + "?pa=" + param,
          requestOptions
        )
          .then((response) => response.json())
          .then((data) => {
            if (data.status == "SUCCESS") {
              GlobalClass.Notify(
                "success",
                "Success:",
                "Batch_Log Added Successfully"
              );
            } else if (data.status == "FAIL") {
              GlobalClass.Notify("error", data?.status, data?.message);
            }
          })
          .catch((e) => GlobalClass.Notify("error", "Error", `${e?.message}`));
      }
    }

  }
);

export const GetUserFieldObj = createAsyncThunk(
  "TSalesSlices/GetUserFieldObj",
  async (cCode) => {
    try {
      let param = JSON.stringify({
        "CorpID": gensetting.getcorpid(),
        "cUser": gensetting.getcuser(),
        "cPass": gensetting.getcpass(),
        "CmpNo": gensetting.getcmpno(),
        "cAction": "A",
        "cCode": cCode,
        "cOPara": "T41",
        "Yrno": localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
      });
      const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.GetUserField + "?pa=" + param);
      return response?.data?.data;
    } catch (error) {
      console.error(error);
      return { error };
    }
  }
)


const Transaction = createSlice({
  name: "Transaction",
  initialState: {
    TransactionData: [],
    BillFormChallanPartyAcData: [],
    isLoading: false,
    hasError: false,
    ErrorMsg: {},
    AccountListObject: {},
    UserField: [],
    flag: true,
    invTypeData: [],
    isUserFieldLoading: false,
    Data:[]
  },
  reducers: {
    GetAccountListObject: (state, action) => {
      state.AccountListObject = action.payload;
    },
    GetNullTransactionData: (state, action) => {
      state.TransactionData = []
    } ,
    setData : (state,action) => {
      console.log("action.payload",action.payload);
      state.Data = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(TransactionApi.pending, (state, action) => {

        // console.log("action?.meta?.arg?",action?.meta?.arg);

        if (action?.meta?.arg?.cSTID === "T41VLISTBCBC" && state.flag) {
          state.isLoading = true;
          state.hasError = false;

        }
        else if (action?.meta?.arg?.isContraPage === true && !state.flag) {
          state.isLoading = false;
          state.hasError = false;
        }
        else {
          state.isLoading = true;
          state.hasError = false;
        }
      })
      .addCase(TransactionApi.fulfilled, (state, action) => {

        // console.log("action.payload",action?.payload);

        if (!action.payload?.error) {

          if (Array.isArray(action?.payload)) {
            if (state.flag && action?.payload[1] === "T41VLISTBCBC") {
              state.TransactionData = action.payload[0]
              state.isLoading = false;
              state.hasError = false;
              state.flag = false
            }
            else if (!state.flag) {
              state.isLoading = false;
              state.hasError = false;
              state.invTypeData[0] = action.payload[0]?.M45IDT
              state.invTypeData[1] = action.payload[0]?.M45I
            }

          } // Contra Page
          else {
            // state.flag = false
            state.TransactionData = action.payload;
            state.isLoading = false;
            state.hasError = false;
          }
        } else {
          state.isLoading = false;
          state.hasError = true;
          state.ErrorMsg = action.payload?.error;
        }
      })
      .addCase(TransactionApi.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(GetUserFieldObj.pending, (state, action) => {
        state.isUserFieldLoading = true;
        // state.hasError = false;
      })
      .addCase(GetUserFieldObj.fulfilled, (state, action) => {
        console.log("state.UserField =>", action.payload);
        state.UserField = action.payload;
        state.isUserFieldLoading = false;
        // state.hasError = false;
      })
      .addCase(GetUserFieldObj.rejected, (state, action) => {
        state.isUserFieldLoading = false;
        // state.hasError = true;
      })
      .addCase(BillFormChallanPartyAc.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(BillFormChallanPartyAc.fulfilled, (state, action) => {
        if (!action.payload?.error) {
          state.BillFormChallanPartyAcData = action.payload;
          state.isLoading = false;
          state.hasError = false;
        } else {
          state.isLoading = false;
          state.hasError = true;
          state.ErrorMsg = action.payload?.error;
        }
      })
      .addCase(BillFormChallanPartyAc.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      })
  },
});

export const { GetAccountListObject, GetNullTransactionData,setData } = Transaction.actions;
export default Transaction.reducer;