import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetUserDetail } from "../../../utils/slices/UserDetailSlice";
import { Button, Center, Grid, Text } from "@mantine/core";
import PLTextBox from "../../../PlusComponent/PLTextBox";
import GlobalClass from "../../../utils/GlobalClass";
import PLComboBox from "../../../PlusComponent/PLComboBox";
import { DataSaveLoading } from "../../../utils/slices/DataSaveLoadingSlice";
import gensetting from "../../../utils/gensetting";
import Store from "../../../utils/store";
import { ModalDelete, ModalFunction } from "../../../utils/slices/ModalSlice";
import { GetAccountList } from "../../../utils/slices/AccountListSlice";
import PLDataGrid from "../../../PlusComponent/PLDataGrid";
import UserSecurityNew from "../User Security/UserSecurityNew";

const UserLevelForm = (props) => {
  console.log("props", props);
  const { OBJ } = props;
  const dispatch = useDispatch();
  const formRef = useRef();
  const UserDetail = useSelector((state) => state?.UserDetail?.UserDetailData);
  const SaveLoading = useSelector(
    (state) => state?.DataSaveLoadingSlice?.SaveLoading
  );
  const isLoading = useSelector((state) => state.UserDetail.isLoading);
  const [userDetailData, setUserDetailData] = useState();
  // console.log("AccNameisLoading[props?.obj?.id]",AccNameisLoading)

  useEffect(() => {
    setUserDetailData(UserDetail?.User);
  }, [UserDetail]);

  const handleOkButton = () => {
    dispatch(DataSaveLoading({["UserLevel"]:true}));
    if (userDetailData.FIELD02.trim() === "") {
      GlobalClass.Notify(
        "warning",
        "Validation error",
        "Please fill out all the required fields"
      );
      dispatch(DataSaveLoading({["UserLevel"]:false}));
    } else {
      GlobalClass.Notify(
        "info",
        OBJ?.p0 == "A" ? "Adding" : "Editing",
        "Please wait while we process your data"
      );
      var data = {
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: OBJ?.p0,
        cCode: "",
        cSData: JSON.stringify({ ...userDetailData, FIELD06: "G" }),
        YrNo: localStorage.getItem("YrNo")?.replace(/[\\"]/g, ""),
      };
      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: OBJ?.p0,
        cCode: "",
        cSData: JSON.stringify({ ...userDetailData, FIELD06: "G" }),
      });

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      };

      fetch(
        GlobalClass.ApiUrl + GlobalClass.PostUserDet + "?pa=" + param,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          // console.log("api data", data);
          // console.log("api data status", data?.status);
          if (data.status === "SUCCESS") {
            // modals.closeAll();
            // props.ModalFunctionClose()
            // dispatch(ModalDelete(props.index))

            GlobalClass.Notify(
              "success",
              OBJ?.p0 == "A" ? "Added" : "Edited",
              OBJ?.p0 == "A" ? "Added successfully" : "Edited successfully"
            );
            let PaginationObj =
              Store.getState().DataSaveLoadingSlice?.PaginationObj;
            Promise.resolve(dispatch(DataSaveLoading({["UserLevel"]:false})))
              // .then(() => dispatch(ModalDelete(props.index)))
              .then(() => {
                if (props?.OBJ?.p0 == "A") {
                  Store.dispatch(
                    ModalFunction({
                      onclose: () => {},
                      onClickCloseButton: () => {
                        dispatch(ModalDelete(props.index));
                      },
                      MTitle: <Text fw={700}>{"Group Rights"}</Text>,
                      MAction: true,
                      MSize: "75%",
                      MBody: (i) => (
                        <UserSecurityNew
                          OBJ={{
                            id: "14161410",
                            name: "User Security",
                            p0: "U",
                            type: "A",
                          }}
                          index={i}
                        />
                      ),
                      MClose: true,
                      Overlay: {
                        backgroundOpacity: 0.8,
                        blur: 5,
                      },
                    })
                  );
                } else {
                  dispatch(ModalDelete(props.index));
                }
              });
          } else {
            GlobalClass.Notify("error", data?.status, data?.message);
            dispatch(DataSaveLoading({["UserLevel"]:false}));
          }
        })
        .catch((e) => {
          // console.log("inside catch", e);
          GlobalClass.Notify("error", "Error", `${e?.message}`);
          dispatch(DataSaveLoading({["UserLevel"]:false}));
        });
    }
  };

  return (
    <div ref={formRef}>
      {SaveLoading["UserLevel"] || isLoading ? (
        GlobalClass.RenderLoader("dots")
      ) : (
        <Grid gutter={4}>
          <Grid.Col
            span={12}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: "0",
            }}
          >
            <Grid.Col span={3}>
              <Text style={{ fontSize: "12px" }}>Group Name</Text>
            </Grid.Col>
            <Grid.Col span={9}>
              <PLTextBox
                autoFocus={true}
                onKeyDown={(e) => {
                  GlobalClass.formNextInput(e, formRef);
                }}
                value={userDetailData?.FIELD02}
                setEdit={(e) => {
                  setUserDetailData({
                    ...userDetailData,
                    FIELD02: e.target.value,
                  });
                }}
              />
            </Grid.Col>
          </Grid.Col>
          <Grid.Col>
            <Center>
              <Button
                style={{ width: "130px" }}
                onKeyDown={(e) => {
                  if (e.key == "Enter") {
                    e.preventDefault();
                    handleOkButton();
                  }
                }}
                onClick={handleOkButton}
              >
                Ok
              </Button>
            </Center>
          </Grid.Col>
        </Grid>
      )}
    </div>
  );
};

export default UserLevelForm;
