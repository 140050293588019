import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GetPLUSM471 } from '../../utils/slices/VoucherSetupPopUpSlice'
import PLComboBox from '../../PlusComponent/PLComboBox'

const PLUSM471 = (props) => {
    const { setEditSend, value } = props

    const PLUSM471Data = useSelector((state) => state.VoucherSetupPopUp?.PLUSM471Data)
    // console.log('PLUSM471Data', PLUSM471Data)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(GetPLUSM471())
    }, [])

    return (
        <div style={{ width: '100%' }}>
            {
                PLUSM471Data &&
                <PLComboBox
                autoFous={true}
                initiallyOpened={true}
                    data={PLUSM471Data}
                    value={value}
                    dispexpr="cDesc"
                    valexpr="cID"
                    setEdit={(e) => {
                        setEditSend(e)
                    }}
                />
            }
        </div>
    )
}

export default PLUSM471