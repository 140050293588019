import React, { useState } from "react";
import { Modal, TextInput, Button, Text } from "@mantine/core";
import { modals } from "@mantine/modals";
import AccountGroupForm from "./AccountGroupForm";
import { useSelector, useDispatch } from "react-redux";
import Store from "../../utils/store";
import { GetAccountGroup } from "../../utils/slices/AccountGroupSlice";
import { GetAccountList } from "../../utils/slices/AccountListSlice";
import { notifications } from "@mantine/notifications";
import { DeleteAccData } from "../../utils/slices/AccountGroupSlice";
import { ModalDelete, ModalFunction } from "../../utils/slices/ModalSlice";
import GlobalClass from "../../utils/GlobalClass";

export function MSAED(data, Skey) {
  const OBJ = JSON.parse(data);
  // console.log("MSAED", OBJ);

  if (OBJ.p0 === "A") {
    Store.dispatch(
      ModalFunction({
        onclose: (e) => { },
        MTitle: <Text fw={700}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "md",
        position: "right",
        MBody: (i) => <AccountGroupForm OBJ={OBJ} index={i} />,
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        },
      })
    );

    //===========================================
    // modals.open({
    //   title: <Text fw={700}>{OBJ?.text}</Text>,
    //   size: "50%",
    //   children: <AccountGroupForm OBJ={OBJ} />,
    // });
  } else if (OBJ.p0 === "E" && !OBJ.id == "") {
    Store.dispatch(
      ModalFunction({
        onclose: () => { },
        MTitle: <Text fw={700}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "md",
        position: "right",
        MBody: (i) => (
          // OBJ?.DataGridRow?.FIELD14 == "S" ? (
          //   GlobalClass.Alert("info", i)
          // ) : (
          <AccountGroupForm OBJ={OBJ} index={i} />
        ),
        // ),
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        },
      })
    );
    //=====================================================
    // modals.open({
    //   title: <Text fw={700}>{OBJ?.text}</Text>,
    //   size: "50%",
    //   children: <AccountGroupForm OBJ={OBJ} />,
    // });
  } else if (OBJ.p0 === "D" && !OBJ.id == "") {
    // if (window.confirm("Are you sure to delete selected data?")) {
    //   Store.dispatch(DeleteAccData({ cCode: OBJ.id, cAction: OBJ.p0 }));
    // } else {
    //   return null;
    // }

    GlobalClass.Confirm(
      "red",
      "Are you sure to delete selected data?",
      "It will be Deleted Forever and Cannot Be Recovered!!!",
      () => {
        Store.dispatch(DeleteAccData({ cCode: OBJ.id, cAction: OBJ.p0 }));
      },
      () => { },
      "Cancel",
      "Confirm"
    )
  }
}

export default MSAED;
