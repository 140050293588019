import { Button, Grid, Group, Text } from '@mantine/core'
import React, { useEffect, useRef, useState } from 'react'
import PLNumberBox from '../../PlusComponent/PLNumberBox'
import PLTextBox from '../../PlusComponent/PLTextBox'
import { useDispatch, useSelector } from 'react-redux'
import { SetAccountListData } from '../../utils/slices/AccountListSlice'
import { ModalDelete } from '../../utils/slices/ModalSlice'
import GlobalClass from '../../utils/GlobalClass'

const ProductLabel = (props) => {
    const { OBJ, index } = props;

    const [LabelObj, setLabelObj] = useState({
        "FIELDQTY": 0,
        "CDES": ""
    })

    const formRef = useRef(null);
    const dispatch = useDispatch();

    const { AccountListData } = useSelector((state) => state.AccountList);

    useEffect(() => {
        if (AccountListData?.[OBJ?.cSTID]) {
            console.log('ProductLabelData', AccountListData?.[OBJ?.cSTID]);
            let ProductLabelData = [...AccountListData?.[OBJ?.cSTID]]
            ProductLabelData?.forEach((d, i) => {
                if (OBJ?.id == d?.FIELD01) {
                    setLabelObj(d)
                }
            })

        }
    }, [AccountListData])

    useEffect(() => {
        const formElement = formRef.current;
       
        if (formElement) {
            formElement.addEventListener('keypress', (e) => GlobalClass.formNextInput(e, formRef));
        }

        return () => {
            if (formElement) {
                formElement.removeEventListener('keypress', (e) => GlobalClass.formNextInput(e, formRef));
            }
        };
    }, [formRef])

    useEffect(()=>{
        setTimeout(()=>{
            const LabelNoInput = document.getElementById("LabelNo")
            console.log("formRef", formRef);
            if (LabelNoInput) {
                console.log("LabelNoInput",LabelNoInput);
                LabelNoInput.focus()
            }
        },200)
    },[])

    const handleProductLabel = () => {
        new Promise((resolve) => {
            let ProductLabelData = [...AccountListData?.[OBJ?.cSTID]]
            ProductLabelData?.forEach((d, i) => {
                if (OBJ?.id == d?.FIELD01) {
                    ProductLabelData[i] = { ...d, FIELDQTY: (isNaN(LabelObj?.FIELDQTY) ? "0.00" : LabelObj?.FIELDQTY), CDES: LabelObj?.CDES }
                }
            })
            resolve(ProductLabelData)
        }).then((res) => {
            console.log("ProductLabelDataRes =>", res);
            dispatch(SetAccountListData({ ...AccountListData, [OBJ?.cSTID]: res }))
        }).then(() => {
            dispatch(ModalDelete(index))
        })

    }

    return (<>
        <Grid gutter={3} ref={formRef}>
            <Grid.Col span={12} style={{ display: "flex" }}>
                <Grid.Col span={3}>
                    <Text style={{ fontSize: 12 }}>No of Label</Text>
                </Grid.Col>
                <Grid.Col span={9}>
                    <PLNumberBox
                        id={"LabelNo"}
                        // autoFocus={true}
                        value={LabelObj?.FIELDQTY}
                        setEdit={(e) => {
                            console.log("labeNo", e);
                            setLabelObj({ ...LabelObj, FIELDQTY: e })
                        }}
                    />
                </Grid.Col>
            </Grid.Col>
            <Grid.Col span={12} style={{ display: "flex" }}>
                <Grid.Col span={3}>
                    <Text style={{ fontSize: 12 }}>Description</Text>
                </Grid.Col>
                <Grid.Col span={9}>
                    <PLTextBox
                        value={LabelObj?.CDES}
                        setEdit={(e) => {
                            // console.log("labelDesc", e.target.value);
                            setLabelObj({ ...LabelObj, CDES: e?.target?.value })
                        }}
                    />
                </Grid.Col>
            </Grid.Col>
            <Grid.Col span={12}>
                <Group spacing={"xs"} position="center">
                    <Button
                        style={{ height: "30px", width: "100px" }}
                        size="sm"
                        onKeyDown={(e) => {
                            if (e.key == "Enter") {
                                handleProductLabel()
                            }
                        }}
                        onClick={() => handleProductLabel()}
                    >
                        Ok
                    </Button>
                </Group>
            </Grid.Col>
        </Grid>
    </>)
}

export default ProductLabel