import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import gensetting from "../gensetting";
import axios from "axios";
import GlobalClass from "../GlobalClass";
import { AxiosError } from 'axios';

export const GetJWListData = createAsyncThunk(
  "GetJWList/GetJWListData",
  async (requestData) => {
    try {
      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: "",
        cCode: "",
        "Yrno":localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
        // cSData: requestData,
      });
      const response = await axios.post(
        GlobalClass.ApiUrl + GlobalClass.GetJWList + "?pa=" + param,
        requestData
      );
      // console.log(response?.data, "GetJWListData");
      if (response?.data?.status == "FAIL") {
        // console.log(response?.data?.status, "response?.data?.status");
        return response?.data;
      } else {
        return response?.data;
      }
    } catch (error) {
      console.log("error", error);
      return error;
    }
  }
);// Pending Issue 
export const GetJOrderData = createAsyncThunk(
  "GetJOrder/GetJOrderData",
  async (obj) => {
    console.log(obj, 'requestData-')
   
    try {
      let newObj = {
        "cPCode": obj?.cPCode,
        "cACode": obj?.cACode,
        "cDate": obj?.cDate,
        "cType": "",
      }

      let param = 
      // JSON.stringify(
        {
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: "A",
        cCode: obj?.cCode,
        cSData:JSON.stringify(newObj),
        YrNo:localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
      }
      // );
      


      const response = await axios.post(
        GlobalClass.ApiUrl + GlobalClass.GetJOrderData, param
        // requestData
      );
      // console.log(response?.data, "GetJOrderData");
      if (response?.data?.status == "FAIL") {
        // console.log(response?.data, "response?.data?.status");
        return response?.data;
      } else {
        // console.log(response?.data, "response?.data?.status");

        return response?.data?.data;
      }
    } catch (error) {
      console.log("error", error);
      return error;
    }
  }
);

const PendingIssueSlice = createSlice({
  name: "PendingIssue",
  initialState: {
    JWListData: [],
    JWOrderData: [],
    copyRow:{},
    isLoading: false,
    isLoadingOrder: false,
    hasErrorOder: false,
    hasError: false,
    errorMsgOrder: {},
    errorMsg: {},
  },
  reducers: {
    RefreshIssuePopUp : (state, action)=>{
      state.JWListData = action.payload;
    },
    RefreshIOrderPopUp: (state, action)=>{
      state.JWOrderData =action.payload;
    },
    GetCopyRowData:(state, action)=>{
      state.copyRow = action.payload;
    },
    GetNullPendingOrderData:(state, action) => {
      state.JWOrderData = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetJWListData.pending, (state, action) => {
        console.log(action, "pending");
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(GetJWListData.fulfilled, (state, action) => {
        console.log(action?.payload, "action?.payload?.error");
        if (action?.payload?.status == "FAIL") {
          console.log(action, "fulfilled");
          state.errorMsg = action.payload;
          state.isLoading = false;
          state.hasError = true;
        } else if (action.payload instanceof AxiosError) {
          const error = action.payload;
          console.error("Network error occurred:", error.message);

          state.errorMsg = error;
          state.isLoading = false;
          state.hasError = true;
        } else {
          state.JWListData = action.payload?.data;
          state.isLoading = false;
          state.hasError = false;
        }
      })
      .addCase(GetJWListData.rejected, (state, action) => {
        console.log(action, "rejected");
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(GetJOrderData.pending, (state, action) => {
        // console.log(action, "pending");
        state.isLoadingOrder = true;
        state.hasErrorOder = false;
      })
      .addCase(GetJOrderData.fulfilled, (state, action) => {
        console.log(action?.payload, "fulfilled");

        if (action?.payload?.status == "FAIL") {
          console.log(action?.payload, "fulfilled");

          state.errorMsgOrder = action.payload;
          state.isLoadingOrder = false;
          state.hasErrorOder = true;
        } else if (action.payload instanceof AxiosError) {
          const error = action.payload;
          console.error("Network error occurred:", error.message);

          state.errorMsgOrder = error;
          state.isLoadingOrder = false;
          state.hasErrorOder = true;
        } else {
          state.JWOrderData = action.payload;
          state.isLoadingOrder = false;
          state.hasErrorOder = false;
        }
      })
      .addCase(GetJOrderData.rejected, (state, action) => {
        // console.log(action, "rejected");
        state.hasErrorOder = true;
        state.isLoadingOrder = false;
      });
  },
});

export const {RefreshIssuePopUp, RefreshIOrderPopUp, GetCopyRowData, GetNullPendingOrderData} = PendingIssueSlice.actions;

export default PendingIssueSlice.reducer;
