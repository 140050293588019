import { Button, Center, Divider, Grid, Paper, Text } from "@mantine/core";
import React, { useEffect, useRef, useState } from "react";
import PLComboBox from "../../PlusComponent/PLComboBox";
import { useDispatch, useSelector } from "react-redux";
import { GetExpenseSetup } from "../../utils/slices/ExpenseSetupSlice";
import Localize from "../../utils/rc";
import { useLocalStorage, useScrollIntoView } from "@mantine/hooks";
import GlobalClass from "../../utils/GlobalClass";
import PLDataGrid from "../../PlusComponent/PLDataGrid";
import { DataSaveLoading } from "../../utils/slices/DataSaveLoadingSlice";
import { ModalDelete } from "../../utils/slices/ModalSlice";
import gensetting from "../../utils/gensetting";
import SessionSetting from "../../layout/SessionSetting";

const ExpenseSetupForm = (props) => {
  // console.log("props", props);

  const [oDic, setOdic] = useState();
  const [oDic1, setOdic1] = useState();
  const [focus, setFocus] = useState(false);
  const dg1 = useRef();
  const dg2 = useRef();
  const dg3 = useRef();
  const dg4 = useRef();
  const dg5 = useRef();
  const dg6 = useRef();
  const dg7 = useRef();
  const dg8 = useRef();
  const dg9 = useRef();
  const dg10 = useRef();
  const BtnRef = useRef();

 

  const ExpenseSetupData = useSelector(
    (state) => state?.ExpenseSetup?.ExpenseSetupData
  );

  const isLoading = useSelector((state) => state?.ExpenseSetup?.isLoading);

  const hasError = useSelector((state) => state?.ExpenseSetup?.hasError);

  const ErrorMsg = useSelector((state) => state?.ExpenseSetup?.ErrorMsg);

  const SaveLoading = useSelector(
    (state) => state?.DataSaveLoadingSlice?.SaveLoading
  );

  const [sessionSetting, setSessionSetting] = useLocalStorage({
    key: 'session_setting',
    defaultValue: {
        Primary_Color: 'blue',
        Font_Family: 'Arial',
        Font_Size: 12,
        Font_XS: 10,
        Font_SM: 12,
        Font_MD: 14,
        Font_LG: 16,
        Font_XL: 20

    },
    getInitialValueInEffect: true,
})

let theme = sessionSetting?.Primary_Color;
if (theme == "grape") {
  theme = "#be4bdb";
} else if (theme == "dark") {
  theme = "#25262b";
} else if (theme == "blue") {
  theme = "#228be6";
}

  const dispatch = useDispatch();
  let rc = new Localize().rc;
  // console.log(
  //   "ExpenseSetupData?.S11?.[0]?.GRPCAPID",
  //   ExpenseSetupData?.S11DT?.[0]?.GRPCAPID
  // );
  useEffect(() => {
    dispatch(GetExpenseSetup(props?.OBJ));
  }, []);

  useEffect(() => {
    if (ExpenseSetupData?.oDic) {
      setOdic(ExpenseSetupData?.oDic);
    }
    dg1?.current?.handlePopoverToggle();
  }, [ExpenseSetupData?.oDic]);

  // console.log(
  //   "oDic?.[ExpenseSetupData?.S11DT?.[0]?.CNAME+",
  //   oDic?.[ExpenseSetupData?.S11DT?.[1]?.CNAME + "IC"],
  //   "==>",
  //   ExpenseSetupData?.S11DT?.[1]?.CNAME + "IC"
  // );

  // console.log(
  //   "${localStorage.getItem",
  //   JSON.parse(localStorage.getItem("session_setting"))?.Primary_Color
  // );

  // console.log("oDic1", oDic1);

  const handleOkButton = () => {
    dispatch(DataSaveLoading({["ExpenseEntryForm"]:true}));
    GlobalClass.Notify(
      "info",
      "Adding",
      "Please wait while we process your data"
    );
    var data = {
      CorpID: gensetting.getcorpid(),
      cUser: gensetting.getcuser(),
      cPass: gensetting.getcpass(),
      CmpNo: gensetting.getcmpno(),
      cAction: "A",
      cCode: "",
      cSData: JSON.stringify({ oDic: oDic }),
      YrNo:localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
    };

    let param = JSON.stringify({
      CorpID: gensetting.getcorpid(),
      cUser: gensetting.getcuser(),
      cPass: gensetting.getcpass(),
      CmpNo: gensetting.getcmpno(),
      cAction: "A",
      cCode: "",
      cSData: JSON.stringify({ oDic: oDic }),
    });

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    fetch(
      GlobalClass.ApiUrl + GlobalClass.PostAutoTaxEnt + "?pa=" + param,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        // console.log("api data", data);
        // console.log("api data status", data?.status);
        if (data.status === "SUCCESS") {
          // modals.closeAll();
          // props.ModalFunctionClose();

          // dispatch(ModalDelete(props.index))

          GlobalClass.Notify("success", "Added", "Added successfully");
          Promise.resolve(dispatch(DataSaveLoading({["ExpenseEntryForm"]:false})))
            // .then(() => dispatch(ModalDelete(props.index)))
            .then(() => {
              dispatch(ModalDelete(props.index));
            });
        } else {
          GlobalClass.Notify("error", data?.status, data?.message);
          dispatch(DataSaveLoading({["ExpenseEntryForm"]:false}));
        }
      })
      .catch((e) => {
        GlobalClass.Notify("error", "Error", `${e.message}`);
        dispatch(DataSaveLoading({["ExpenseEntryForm"]:false}));
      });
  };

  return (
    <>
      {hasError ? (
        <>
          <Text>{ErrorMsg?.message}</Text>
          <Button
            onClick={() => {
              if (props?.OBJ) {
                dispatch(GetExpenseSetup(props?.OBJ));
              }
            }}
          >
            Retry
          </Button>
        </>
      ) : isLoading || SaveLoading["ExpenseEntryForm"] ? (
        GlobalClass.RenderLoader("dots")
      ) : (
        <Paper onKeyDown={(e)=>{
          if(e?.key=="Escape"){
            if(window?.confirm("Are you sure to exit?")){
            dispatch(ModalDelete(props?.index))}
          }
        }}>
          <Grid gutter={4}>
            <Grid.Col span={12}>
              <Divider
                my={"xs"}
                label={rc(ExpenseSetupData?.S11DT?.[0]?.GRPCAPID)}
                labelPosition="center"
              />
            </Grid.Col>
            <Grid.Col
              span={12}
              style={{ display: "flex", alignItems: "center", padding: 0 }}
            >
              <Grid.Col span={2}>
                <Text style={{ fontSize: 12 }}>
                  {rc(ExpenseSetupData?.S11DT?.[0]?.EXPCAPID)}
                </Text>
              </Grid.Col>
              <Grid.Col span={10}>
                <PLDataGrid
                  ref={dg1}
                  // autoFocus={true}
                  isMulti={true}
                  valexpr={"M45UID"}
                  dispexpr={"FIELD02"}
                  TmData={ExpenseSetupData?.S11DT?.[0]?.EXPPOPID}
                  value={{
                    value: oDic?.[ExpenseSetupData?.S11DT?.[0]?.CNAME + "E"],
                    label: oDic1?.labeldg01,
                  }}
                  cWhere={ExpenseSetupData?.S11DT?.[0]?.EXPFILTER}
                  setEdit={(e) => {
                    let saveField = ExpenseSetupData?.S11DT?.[0]?.CNAME + "E";

                    let val = [];
                    let val1 = [];
                    if (e?.length > 0) {
                      e?.map((m, i) => {
                        val.push(m?.original?.M45UID);
                        val1.push(m?.original?.FIELD02);
                      });
                    }
                    Promise.resolve(
                      setOdic({ ...oDic, [saveField]: val.join(",") })
                    )
                      .then(() => {
                        setOdic1({ ...oDic1, labeldg01: val1.join(",") });
                      })
                      .then(() => {
                        dg2.current?.handlePopoverToggle();
                      });
                  }}
                />
              </Grid.Col>
            </Grid.Col>
            <Grid.Col
              span={12}
              style={{ display: "flex", alignItems: "center", padding: 0 }}
            >
              <Grid.Col span={2}>
                <Text style={{ fontSize: 12 }}>
                  {rc(ExpenseSetupData?.S11DT?.[0]?.INVCAPID)}
                </Text>
              </Grid.Col>
              <Grid.Col span={10}>
                <PLDataGrid
                  ref={dg2}
                  isMulti={true}
                  valexpr={"TAXF03"}
                  dispexpr={"TAXF02"}
                  TmData={ExpenseSetupData?.S11DT?.[0]?.INVPOPID}
                  value={{
                    value: oDic?.[ExpenseSetupData?.S11DT?.[0]?.CNAME + "I"],
                    label: oDic1?.labeldg02,
                  }}
                  cWhere={ExpenseSetupData?.S11DT?.[0]?.INVFILTER}
                  setEdit={(e) => {
                    let saveField = ExpenseSetupData?.S11DT?.[0]?.CNAME + "I";

                    let val = [];
                    let val1 = [];
                    if (e?.length > 0) {
                      e?.map((m, i) => {
                        val.push(m?.original?.TAXF03);
                        val1.push(m?.original?.TAXF02);
                      });
                    }
                    Promise.resolve(
                      setOdic({ ...oDic, [saveField]: val.join(",") })
                    )
                      .then(() => {
                        setOdic1({ ...oDic1, labeldg02: val1.join(",") });
                      })
                      .then(() => {
                        dg3.current?.handlePopoverToggle();
                      });
                  }}
                />
              </Grid.Col>
            </Grid.Col>
            <Grid.Col span={12}>
              <Divider
                my={"xs"}
                label={rc(ExpenseSetupData?.S11DT?.[1]?.GRPCAPID)}
                labelPosition="center"
              />
            </Grid.Col>
            <Grid.Col
              span={12}
              style={{ display: "flex", alignItems: "center", padding: 0 }}
            >
              <Grid.Col span={2}>
                <Text style={{ fontSize: 12 }}>
                  {rc(ExpenseSetupData?.S11DT?.[1]?.EXPCAPID)}
                </Text>
              </Grid.Col>
              <Grid.Col span={10}>
                <PLDataGrid
                  ref={dg3}
                  isMulti={true}
                  valexpr={"M45UID"}
                  dispexpr={"FIELD02"}
                  TmData={ExpenseSetupData?.S11DT?.[1]?.EXPPOPID}
                  value={{
                    value: oDic?.[ExpenseSetupData?.S11DT?.[1]?.CNAME + "E"],
                    label: oDic1?.labeldg03,
                  }}
                  cWhere={ExpenseSetupData?.S11DT?.[1]?.EXPFILTER}
                  setEdit={(e) => {
                    let saveField = ExpenseSetupData?.S11DT?.[1]?.CNAME + "E";

                    let val = [];
                    let val1 = [];
                    if (e?.length > 0) {
                      e?.map((m, i) => {
                        val.push(m?.original?.M45UID);
                        val1.push(m?.original?.FIELD02);
                      });
                    }
                    Promise.resolve(
                      setOdic({ ...oDic, [saveField]: val.join(",") })
                    )
                      .then(() => {
                        setOdic1({ ...oDic1, labeldg03: val1.join(",") });
                      })
                      .then(() => {
                        dg4.current?.handlePopoverToggle();
                      });
                  }}
                />
              </Grid.Col>
            </Grid.Col>
            <Grid.Col
              span={12}
              style={{ display: "flex", alignItems: "center", padding: 0 }}
            >
              <Grid.Col span={2}>
                <Text style={{ fontSize: 12 }}>
                  {rc(ExpenseSetupData?.S11DT?.[1]?.INVCAPID)}
                </Text>
              </Grid.Col>
              <Grid.Col span={10}>
                <PLDataGrid
                  ref={dg4}
                  isMulti={true}
                  valexpr={"TAXF03"}
                  dispexpr={"TAXF02"}
                  TmData={ExpenseSetupData?.S11DT?.[1]?.INVPOPID}
                  value={{
                    value: oDic?.[ExpenseSetupData?.S11DT?.[1]?.CNAME + "I"],
                    label: oDic1?.labeldg04,
                  }}
                  cWhere={ExpenseSetupData?.S11DT?.[1]?.INVFILTER}
                  setEdit={(e) => {
                    let saveField = ExpenseSetupData?.S11DT?.[1]?.CNAME + "I";

                    let val = [];
                    let val1 = [];
                    if (e?.length > 0) {
                      e?.map((m, i) => {
                        val.push(m?.original?.TAXF03);
                        val1?.push(m?.original?.TAXF02);
                      });
                    }

                    Promise.resolve(
                      setOdic({ ...oDic, [saveField]: val.join(",") })
                    )
                      .then(() => {
                        setOdic1({ ...oDic1, labeldg04: val1.join(",") });
                      })
                      .then(() => {
                        ExpenseSetupData?.S11DT?.[1]?.RCMREQ == "Y"
                          ? dg5.current?.handlePopoverToggle()
                          : ExpenseSetupData?.S11DT?.[1]?.CGOODREQ == "Y"
                          ? dg6?.current?.handlePopoverToggle()
                          : ExpenseSetupData?.S11DT?.[2]
                          ? dg7?.current?.handlePopoverToggle()
                          : ExpenseSetupData?.S11DT?.[3]
                          ? dg9?.current?.handlePopoverToggle()
                          : BtnRef?.current.focus();
                      });
                  }}
                />
              </Grid.Col>
            </Grid.Col>
            {ExpenseSetupData?.S11DT?.[1]?.RCMREQ == "Y" && (
              <Grid.Col
                span={12}
                style={{ display: "flex", alignItems: "center", padding: 0 }}
              >
                <Grid.Col span={2}>
                  <Text style={{ fontSize: 12 }}>
                    {"RCM Inv. Type"}
                  </Text>
                </Grid.Col>
                <Grid.Col span={10}>
                  <PLDataGrid
                    ref={dg5}
                    isMulti={true}
                    valexpr={"TAXF03"}
                    dispexpr={"TAXF02"}
                    TmData={ExpenseSetupData?.S11DT?.[1]?.INVPOPID}
                    value={{
                      value: oDic?.[ExpenseSetupData?.S11DT?.[1]?.CNAME + "IR"],
                      label: oDic1?.labeldg05,
                    }}
                    cWhere={ExpenseSetupData?.S11DT?.[1]?.INVFILTER}
                    setEdit={(e) => {
                      let saveField =
                        ExpenseSetupData?.S11DT?.[1]?.CNAME + "IR";

                      let val = [];
                      let val1 = [];
                      if (e?.length > 0) {
                        e?.map((m, i) => {
                          val.push(m?.original?.TAXF03);
                          val1.push(m?.original?.TAXF02);
                        });
                      }
                      Promise.resolve(
                        setOdic({ ...oDic, [saveField]: val.join(",") })
                      )
                        .then(() => {
                          setOdic1({ ...oDic1, labeldg05: val1.join(",") });
                        })
                        .then(() => {
                          ExpenseSetupData?.S11DT?.[1]?.CGOODREQ == "Y"
                            ? dg6?.current?.handlePopoverToggle()
                            : ExpenseSetupData?.S11DT?.[2]
                            ? dg7?.current?.handlePopoverToggle()
                            : ExpenseSetupData?.S11DT?.[3]
                            ? dg9?.current?.handlePopoverToggle()
                            : BtnRef?.current.focus();
                        });
                    }}
                  />
                </Grid.Col>
              </Grid.Col>
            )}
            {ExpenseSetupData?.S11DT?.[1]?.CGOODREQ == "Y" && (
              <Grid.Col
                span={12}
                style={{ display: "flex", alignItems: "center", padding: 0 }}
              >
                <Grid.Col span={2}>
                  <Text style={{ fontSize: 12 }}>
                    {"Capital Goods"}
                  </Text>
                </Grid.Col>
                <Grid.Col span={10}>
                  <PLDataGrid
                    ref={dg6}
                    isMulti={true}
                    valexpr={"TAXF03"}
                    dispexpr={"TAXF02"}
                    TmData={ExpenseSetupData?.S11DT?.[1]?.INVPOPID}
                    value={{
                      value: oDic?.[ExpenseSetupData?.S11DT?.[1]?.CNAME + "IC"],
                      label: oDic1?.labeldg06,
                    }}
                    cWhere={ExpenseSetupData?.S11DT?.[1]?.INVFILTER}
                    setEdit={(e) => {
                      let saveField =
                        ExpenseSetupData?.S11DT?.[1]?.CNAME + "IC";

                      let val = [];
                      let val1 = [];
                      if (e?.length > 0) {
                        e?.map((m, i) => {
                          val.push(m?.original?.TAXF03);
                          val1.push(m?.original?.TAXF02);
                        });
                      }
                      Promise.resolve(
                        setOdic({ ...oDic, [saveField]: val.join(",") })
                      )
                        .then(() => {
                          setOdic1({ ...oDic1, labeldg06: val1.join(",") });
                        })
                        .then(() => {
                          ExpenseSetupData?.S11DT?.[2]
                            ? dg7?.current?.handlePopoverToggle()
                            : ExpenseSetupData?.S11DT?.[3]
                            ? dg9?.current?.handlePopoverToggle()
                            : BtnRef?.current.focus();
                        });
                    }}
                  />
                </Grid.Col>
              </Grid.Col>
            )}
            {ExpenseSetupData?.S11DT?.[2] && (
              <>
                <Grid.Col span={12}>
                  <Divider
                    my={"xs"}
                    label={rc(ExpenseSetupData?.S11DT?.[2]?.GRPCAPID)}
                    labelPosition="center"
                  />
                </Grid.Col>
                <Grid.Col
                  span={12}
                  style={{ display: "flex", alignItems: "center", padding: 0 }}
                >
                  <Grid.Col span={2}>
                    <Text style={{ fontSize: 12 }}>
                      {rc(ExpenseSetupData?.S11DT?.[2]?.EXPCAPID)}
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={10}>
                    <PLDataGrid
                      ref={dg7}
                      isMulti={true}
                      valexpr={"M45UID"}
                      dispexpr={"FIELD02"}
                      TmData={ExpenseSetupData?.S11DT?.[2]?.EXPPOPID}
                      value={{
                        value:
                          oDic?.[ExpenseSetupData?.S11DT?.[2]?.CNAME + "E"],
                        label: oDic1?.labeldg07,
                      }}
                      cWhere={ExpenseSetupData?.S11DT?.[2]?.EXPFILTER}
                      setEdit={(e) => {
                        let saveField =
                          ExpenseSetupData?.S11DT?.[2]?.CNAME + "E";

                        let val = [];
                        let val1 = [];
                        if (e?.length > 0) {
                          e?.map((m, i) => {
                            val.push(m?.original?.M45UID);
                            val1.push(m?.original?.FIELD02);
                          });
                        }
                        Promise.resolve(
                          setOdic({ ...oDic, [saveField]: val.join(",") })
                        )
                          .then(() => {
                            setOdic1({ ...oDic1, labeldg07: val1.join(",") });
                          })
                          .then(() => {
                            dg8?.current?.handlePopoverToggle();
                          });
                      }}
                    />
                  </Grid.Col>
                </Grid.Col>
                <Grid.Col
                  span={12}
                  style={{ display: "flex", alignItems: "center", padding: 0 }}
                >
                  <Grid.Col span={2}>
                    <Text style={{ fontSize: 12 }}>
                      {rc(ExpenseSetupData?.S11DT?.[2]?.INVCAPID)}
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={10}>
                    <PLDataGrid
                      ref={dg8}
                      isMulti={true}
                      valexpr={"TAXF03"}
                      dispexpr={"TAXF02"}
                      TmData={ExpenseSetupData?.S11DT?.[2]?.INVPOPID}
                      value={{
                        value:
                          oDic?.[ExpenseSetupData?.S11DT?.[2]?.CNAME + "I"],
                        label: oDic1?.labeldg08,
                      }}
                      cWhere={ExpenseSetupData?.S11DT?.[2]?.INVFILTER}
                      setEdit={(e) => {
                        let saveField =
                          ExpenseSetupData?.S11DT?.[2]?.CNAME + "I";

                        let val = [];
                        let val1 = [];
                        if (e?.length > 0) {
                          e?.map((m, i) => {
                            val.push(m?.original?.TAXF03);
                            val1.push(m?.original?.TAXF02);
                          });
                        }
                        Promise.resolve(
                          setOdic({ ...oDic, [saveField]: val.join(",") })
                        )
                          .then(() => {
                            setOdic1({ ...oDic1, labeldg08: val1.join(",") });
                          })
                          .then(() => {
                            ExpenseSetupData?.S11DT?.[3]
                              ? dg9?.current?.handlePopoverToggle()
                              : BtnRef?.current.focus();
                          });
                      }}
                    />
                  </Grid.Col>
                </Grid.Col>
              </>
            )}

            {ExpenseSetupData?.S11DT?.[3] && (
              <>
                <Grid.Col span={12}>
                  <Divider
                    my={"xs"}
                    label={rc(ExpenseSetupData?.S11DT?.[3]?.GRPCAPID)}
                    labelPosition="center"
                  />
                </Grid.Col>
                <Grid.Col
                  span={12}
                  style={{ display: "flex", alignItems: "center", padding: 0 }}
                >
                  <Grid.Col span={2}>
                    <Text style={{ fontSize: 12 }}>
                      {rc(ExpenseSetupData?.S11DT?.[3]?.EXPCAPID)}
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={10}>
                    <PLDataGrid
                      ref={dg9}
                      isMulti={true}
                      valexpr={"M45UID"}
                      dispexpr={"FIELD02"}
                      TmData={ExpenseSetupData?.S11DT?.[3]?.EXPPOPID}
                      value={{
                        value:
                          oDic?.[ExpenseSetupData?.S11DT?.[3]?.CNAME + "E"],
                        label: oDic1?.labeldg09,
                      }}
                      cWhere={ExpenseSetupData?.S11DT?.[3]?.EXPFILTER}
                      setEdit={(e) => {
                        let saveField =
                          ExpenseSetupData?.S11DT?.[3]?.CNAME + "E";

                        let val = [];
                        let val1 = [];
                        if (e?.length > 0) {
                          e?.map((m, i) => {
                            val.push(m?.original?.M45UID);
                            val1.push(m?.original?.FIELD02);
                          });
                        }
                        Promise.resolve(
                          setOdic({ ...oDic, [saveField]: val.join(",") })
                        )
                          .then(() => {
                            setOdic1({ ...oDic1, labeldg09: val1.join(",") });
                          })
                          .then(() => {
                            dg10?.current?.handlePopoverToggle();
                          });
                      }}
                    />
                  </Grid.Col>
                </Grid.Col>
                <Grid.Col
                  span={12}
                  style={{ display: "flex", alignItems: "center", padding: 0 }}
                >
                  <Grid.Col span={2}>
                    <Text style={{ fontSize: 12 }}>
                      {rc(ExpenseSetupData?.S11DT?.[3]?.INVCAPID)}
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={10}>
                    <PLDataGrid
                      ref={dg10}
                      isMulti={true}
                      valexpr={"TAXF03"}
                      dispexpr={"TAXF02"}
                      TmData={ExpenseSetupData?.S11DT?.[3]?.INVPOPID}
                      value={{
                        value:
                          oDic?.[ExpenseSetupData?.S11DT?.[3]?.CNAME + "I"],
                        label: oDic1?.labeldg10,
                      }}
                      cWhere={ExpenseSetupData?.S11DT?.[3]?.INVFILTER}
                      setEdit={(e) => {
                        let saveField =
                          ExpenseSetupData?.S11DT?.[3]?.CNAME + "I";

                        let val = [];
                        let val1 = [];
                        if (e?.length > 0) {
                          e?.map((m, i) => {
                            val.push(m?.original?.TAXF03);
                            val1.push(m?.original?.TAXF02);
                          });
                        }
                        Promise.resolve(
                          setOdic({ ...oDic, [saveField]: val.join(",") })
                        )
                          .then(() => {
                            setOdic1({ ...oDic1, labeldg10: val1.join(",") });
                          })
                          .then(() => {
                            // setTimeout(() => {
                            BtnRef?.current.focus();
                            // }, 300);
                          });
                      }}
                    />
                  </Grid.Col>
                </Grid.Col>
              </>
            )}
            <Grid.Col span={12}>
              <Center>
                <Button
                size="sm"
                  onFocus={() => setFocus(true)}
                  onBlur={() => setFocus(false)}
                  ref={BtnRef}
                  autoFocus={focus}
                  style={{
                    outline: focus ? `2px solid ${theme}` : "none",
                    outlineOffset: focus ? "2px" : "0",
                    height: "30px", width: "100px"
                  }}
                  onKeyDown={(e) => {
                    if (e.key == "Enter") {
                      e.preventDefault();
                      handleOkButton();
                    }
                  }}
                  onClick={handleOkButton}
                >
                  Ok
                </Button>
              </Center>
            </Grid.Col>
          </Grid>
        </Paper>
      )}
    </>
  );
};

export default ExpenseSetupForm;
