import { Text } from "@mantine/core";
import { ModalFunction } from "../../utils/slices/ModalSlice";
import Store from "../../utils/store";
import TransactionSalesForm from "./TransactionSalesForm";
import GlobalClass from "../../utils/GlobalClass";
import { deleteUserData } from "../../utils/slices/CompanySetupSlice";
import { GetNullTransactionData, TransactionDeleteApi } from "../../utils/TransactionSlices/Transaction";
import SIDetailPage from "./SIDetailPage";
import { GetExpenseDataNull, GetInvTypeDropDown, GetPendingOrderDataNull, GetPendingQuotationDataNull, GetPreFillTransactionFormData, GetSingleOrderRowNull, GetSingleSelectedOrderRowNull } from "../../utils/TransactionSlices/TSalesSlices";
import { useMediaQuery } from "@mantine/hooks";
import { TM_PRINTVF } from "../../components/PrintVoucherReport";
import EInvoice from "../EInvoiceAndEWayBill/EInvoice";
import EWayBill from "../EInvoiceAndEWayBill/EWayBill";
import PLRange from "../../PlusComponent/PLRange";


let PreFillFormData = {
    CashDebit: "",
    PartyAc: "",
    PartyAcName: "",
    DeliveryAt: "",
    DeliveryAtName: "",
    InvoiceType: "",
    BillDate: ""
}

export function VouEntAct(itemOBJ) {

    const OBJ = JSON.parse(itemOBJ);
    console.log('Transaction Sales Object===>', OBJ);
    if (OBJ.p0 === 'A') { // Add
        Store.dispatch(ModalFunction({
            MTitle: <Text fw={700}>{OBJ?.text} {OBJ?.BtnPnlObj?.name}</Text>,
            MAction: true,
            MSize: "98%",
            // position:'right',
            fullScreen: false,
            MBody: (i) => (<TransactionSalesForm obj={OBJ} index={i} />),
            MClose: true,
            tab: true,
            obj: OBJ,
            onclose: () => { },
            onClickCloseButton: () => {
                Promise.resolve(Store.dispatch(GetInvTypeDropDown(null))).then(() => Store.dispatch(GetPendingQuotationDataNull([]))).then(() => Store.dispatch(GetSingleSelectedOrderRowNull([]))).then(() => Store.dispatch(GetPendingOrderDataNull([]))).then(() => Store.dispatch(GetSingleOrderRowNull([]))).then(() => Store.dispatch(GetExpenseDataNull([]))).then(() => Store.dispatch(GetNullTransactionData())).then(() => Store.dispatch(GetPreFillTransactionFormData(PreFillFormData)))
                // GetPendingOrderDataNull, GetSingleOrderRowNull
            }
        }))
    } else if (OBJ.p0 === 'E' && OBJ?.id) { // Edit
        Store.dispatch(ModalFunction({
            MTitle: <Text fw={700}>{OBJ?.text} {OBJ?.BtnPnlObj?.name}</Text>,
            tab: true,
            MAction: true,
            MSize: "98%",
            // position:'right',
            MBody: (i) => (<TransactionSalesForm obj={OBJ} index={i} />),
            MClose: true,
            onclose: () => { },
            onClickCloseButton: () => {
                Promise.resolve(Store.dispatch(GetInvTypeDropDown(null))).then(() => Store.dispatch(GetPendingQuotationDataNull([]))).then(() => Store.dispatch(GetSingleSelectedOrderRowNull([]))).then(() => Store.dispatch(GetPendingOrderDataNull([]))).then(() => Store.dispatch(GetSingleOrderRowNull([]))).then(() => Store.dispatch(GetExpenseDataNull([]))).then(() => Store.dispatch(GetNullTransactionData())).then(() => Store.dispatch(GetPreFillTransactionFormData(PreFillFormData)))
            }
        }))
    } else if (OBJ.p0 === 'U' && OBJ?.id) { // UserField
        Store.dispatch(ModalFunction({
            MTitle: <Text fw={700}>{OBJ?.text}</Text>,
            MAction: true,
            MSize: "50%",
            // position:'right',
            MBody: (i) => (<TransactionSalesForm obj={OBJ} index={i} />),
            MClose: true,
            onclose: () => { },
            onClickCloseButton: () => {
                Promise.resolve(Store.dispatch(GetInvTypeDropDown(null))).then(() => Store.dispatch(GetPendingQuotationDataNull([]))).then(() => Store.dispatch(GetSingleSelectedOrderRowNull([]))).then(() => Store.dispatch(GetPendingOrderDataNull([]))).then(() => Store.dispatch(GetSingleOrderRowNull([]))).then(() => Store.dispatch(GetExpenseDataNull([]))).then(() => Store.dispatch(GetNullTransactionData())).then(() => Store.dispatch(GetPreFillTransactionFormData(PreFillFormData)))
            }
        }))
    } else if (OBJ.p0 === 'D' && OBJ?.id) { // Delete
        console.log("Delete Object==>", OBJ);
        // dispatch(TransactionApi(obj))
        // if (window.confirm('Confirm to Delete ?')) {
        //     Store.dispatch(TransactionDeleteApi(OBJ))
        // }

        // GlobalClass.Confirm(
        //     "red",
        //     "Are you Sure to Delete This voucher?",
        //     "It will be Deleted Forever and Cannot Be Recovered!!!",
        //     () => {
        //         Store.dispatch(TransactionDeleteApi(OBJ))
        //     },
        //     () => { })

        GlobalClass.Confirm(
            "red",
            "Are you sure to delete selected data?",
            "It will be Deleted Forever and Cannot Be Recovered!!!",
            () => {
                Store.dispatch(TransactionDeleteApi(OBJ))
            },
            () => { },
            "Cancel",
            "Confirm"
        )
    } else if (OBJ.p0 === 'VD' && OBJ?.id) { // Detail
        Store.dispatch(ModalFunction({
            MTitle: <Text fw={700}>{OBJ?.text}</Text>,
            MAction: true,
            MSize: "50%",
            MBody: (i) => (<SIDetailPage obj={OBJ} index={i} />),
            MClose: true,
            onclose: () => { }
        }))
    } else if (OBJ.p0 === 'T' && OBJ?.id) { //Audit
        // if (window.confirm('Confirm to Audit ?')) {
        //     Store.dispatch(TransactionDeleteApi(OBJ))
        // }
        // GlobalClass.Confirm(
        //     "red",
        //     "Are you Sure to Audit This voucher?",
        //     "",
        //     () => {
        //         Store.dispatch(TransactionDeleteApi(OBJ))
        //     },
        //     () => { })

        GlobalClass.Confirm(
            "blue",
            "Confirm to Audit ?",
            "Are you sure you want to proceed!!!",
            () => {
                Store.dispatch(TransactionDeleteApi(OBJ))
            },
            () => { },
            "Cancel",
            "Confirm"
        )
    } else if (OBJ.id && OBJ.p0 === "P") { // Print 
        // eval('TM_PRINTVF('+OBJ+')')
        TM_PRINTVF(itemOBJ);
    } else if (OBJ.id && OBJ.p0 === "I") { // E-invoice
        Store.dispatch(ModalFunction({
            MTitle: <Text fw={700}>{OBJ?.text}</Text>,
            MAction: true,
            MSize: "md",
            // position:'right',
            MBody: (i) => (<EInvoice obj={OBJ} index={i} />),
            MClose: true,
            onclose: () => { },
            onClickCloseButton: () => {

            }
        }))
    } else if (OBJ.id && (OBJ.p0 === "G" || OBJ.p0 === "W")) { // E-way bill
        Store.dispatch(ModalFunction({
            MTitle: <Text fw={700}>{OBJ?.text}</Text>,
            MAction: true,
            MSize: "xl",
            // position:'right',
            MBody: (i) => (
                <EWayBill obj={OBJ} index={i} />
            ),
            MClose: true,
            onclose: () => { },
            onClickCloseButton: () => {

            }
        }))
    } else if (OBJ.p0 == "R") { //Range Button
        Store.dispatch(
            ModalFunction({
                onclose: () => { },
                MTitle: <Text fw={700}>Enter Voucher No. Range</Text>,
                MAction: true,
                MSize: "lg",
                position: "right",
                MBody: (i) => <PLRange obj={OBJ} index={i} />,
                MClose: true,
                Overlay: {
                    backgroundOpacity: 0.8,
                    blur: 5,
                },
            })
        );
    }
}