import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import GlobalClass from '../../utils/GlobalClass';
import { Alert, Button, Grid, Group, Text } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';
import { PostUpdateBalance } from '../../utils/slices/UserListSlice';
import Store from '../../utils/store';
import PLComboBox from '../../PlusComponent/PLComboBox';

const UpdateBalanceForm = (props) => {

    const { yearRow, CmpRow } = props;

    console.log("UpdateBalanceForm=>", props);

    const [CSDataObj, setCSDataObj] = useState({
        "UpdtStk": false,
        "UpdtJB": false,
        "UpdtAccDet": false,
        "lUpdM01Det": false
    })

    const formRef = useRef(null);

    const dispatch = useDispatch();

    const GetGeneral = useSelector((state) => state?.Language?.General);

    const { addUpdateBalance, PostFinYearhasError, PostFinYearErrorMsg, PostFinYearisLoading,addFinRes } = useSelector((state) => state?.UserList)

    const GetYearDateData = useSelector((state) => state.CompanySetup?.GetYearDateData?.YearList);

    useEffect(() => {
        // console.log("GetGeneral ==>", GetGeneral);
        // console.log('UpdtStk==Out', document.getElementById("UpdtStk"));
        setTimeout(() => {
            if (document.getElementById("UpdtStk")) {
                // console.log('UpdtStk==in', document.getElementById("UpdtStk"));
                document.getElementById("UpdtStk").focus()
            }
        },300)
    }, [])

    useEffect(() => {
        const formElement = formRef.current;
        console.log("formRef", formRef);
    
        if (formElement) {
          formElement.addEventListener('keypress', (e) => GlobalClass.formNextInput(e, formRef));
        }
    
        return () => {
          if (formElement) {
            formElement.removeEventListener('keypress', (e) => GlobalClass.formNextInput(e, formRef));
          }
        };
      }, [formRef])

    // useEffect(() => {
    //     if (GetYearDateData) {
    //         console.log("GetYearDateData =>", [{
    //             GetYearDateData:GetYearDateData
    //         }]);
    //         new Promise((resolve) => {
    //             let GetYrNo = (yearRow ? yearRow?.YearNo : localStorage.getItem("YrNo"));
    //             let objFind = {};
    //             GetYearDateData?.find((d) => {
    //                 if (parseInt(d?.YearNo) == GetYrNo) {
    //                     objFind = { ...d }
    //                 }
    //             })
    //             resolve(objFind)
    //         }).then((res) => {
    //             console.log("GetYearDateData objFind", res);
    //             dispatch(PostUpdateBalance({
    //                 cmpNo:CmpRow?.cmpNo,
    //                 YearNo:yearRow?.YearNo,
    //                 From: res?.strtDate,
    //                 To: res?.endDate,
    //                 ModalIndex: props?.index,
    //                 PopUp: true
    //             }))
    //         })
    //     }
    // }, [GetYearDateData])

    useEffect(() => {
        // PostFinYearisLoading: false,
        // PostFinYearhasError: false,
        // PostFinYearErrorMsg:""

        if (PostFinYearhasError && PostFinYearErrorMsg) {

            // console.log('addFinReshasErrorErrorMsgisLoading =>', [{
            //     "addUpdateBalance": addUpdateBalance,
            //     "PostFinYearhasError": PostFinYearhasError,
            //     "PostFinYearErrorMsg": PostFinYearErrorMsg,
            //     "PostFinYearisLoading": PostFinYearisLoading,
            //     "addFinRes":addFinRes
            // }]);

            if (addFinRes) {
                GlobalClass.Notify("error", "Error: " + addFinRes, PostFinYearErrorMsg);
            }
        }
    }, [addFinRes, PostFinYearhasError, PostFinYearErrorMsg])

    const handleOkButton = () => {
        console.log("CSDataObj =>", CSDataObj);

        new Promise((resolve) => {
            let GetYrNo = (yearRow ? yearRow?.YearNo : localStorage.getItem("YrNo"));
            let objFind = {};
            GetYearDateData?.find((d) => {
                if (parseInt(d?.YearNo) == GetYrNo) {
                    objFind = { ...d }
                }
            })
            resolve(objFind)
        }).then((res) => {
            console.log("GetYearDateData objFind", res);
            dispatch(PostUpdateBalance({
                cmpNo: CmpRow?.cmpNo,
                YearNo: yearRow?.YearNo,
                From: res?.strtDate,
                To: res?.endDate,
                ModalIndex: props?.index,
                PopUp: true,
                FormObj: CSDataObj
            }))
        })
    }

    return (<>
        {
            PostFinYearhasError ? <>
                {
                    <Alert variant="light" color="red" title={`Error:${addFinRes}`} icon={<IconInfoCircle />}>
                        {PostFinYearErrorMsg}
                    </Alert>
                } </> : <>
                {
                    PostFinYearisLoading ? GlobalClass.RenderLoader('dots') :
                        <Grid gutter={2} ref={formRef}>
                            <Grid.Col span={12} style={{ display: "flex" }}>
                                <Grid.Col span={8}>
                                    <Text style={{ fontSize: "12px" }}>Update Product Stock ?</Text>
                                </Grid.Col>
                                <Grid.Col span={4}>
                                    <PLComboBox
                                        cmbid={"UpdtStk"}
                                        value={CSDataObj?.UpdtStk ? "Y" : "N"}
                                        copno={"1"}
                                        dispexpr={"DisplayMember"}
                                        valexpr={"ValueMember"}
                                        onKeyDown={(e) => {
                                            GlobalClass.formNextInput(e, formRef);
                                          }}
                                        setEdit={(e) => {
                                            setCSDataObj({ ...CSDataObj, UpdtStk: (e?.value == "Y" ? true : false) })
                                        }}
                                    />
                                </Grid.Col>
                            </Grid.Col>
                            {
                                GetGeneral?.oYear?.OSC?._JOBWRKTYP != "N" &&
                                <Grid.Col span={12} style={{ display: "flex" }}>
                                    <Grid.Col span={8}>
                                        <Text style={{ fontSize: "12px" }}>Forward Jobwork Pending Challan</Text>
                                    </Grid.Col>
                                    <Grid.Col span={4}>
                                        <PLComboBox
                                            value={CSDataObj?.UpdtJB ? "Y" : "N"}
                                            copno={"1"}
                                            dispexpr={"DisplayMember"}
                                            valexpr={"ValueMember"}
                                            onKeyDown={(e) => {
                                                GlobalClass.formNextInput(e, formRef);
                                              }}
                                            setEdit={(e) => {
                                                setCSDataObj({ ...CSDataObj, UpdtJB: (e?.value == "Y" ? true : false) })
                                            }}
                                        />
                                    </Grid.Col>
                                </Grid.Col>
                            }
                            <Grid.Col span={12} style={{ display: "flex" }}>
                                <Grid.Col span={8}>
                                    <Text style={{ fontSize: "12px" }}>Update Account Master Details ?</Text>
                                </Grid.Col>
                                <Grid.Col span={4}>
                                    <PLComboBox
                                        value={CSDataObj?.UpdtAccDet ? "Y" : "N"}
                                        copno={"1"}
                                        dispexpr={"DisplayMember"}
                                        valexpr={"ValueMember"}
                                        onKeyDown={(e) => {
                                            GlobalClass.formNextInput(e, formRef);
                                          }}
                                        setEdit={(e) => {
                                            setCSDataObj({ ...CSDataObj, UpdtAccDet: (e?.value == "Y" ? true : false) })
                                        }}
                                    />
                                </Grid.Col>
                            </Grid.Col>
                            {
                                CSDataObj?.UpdtAccDet ?
                                    <Grid.Col span={12} style={{ display: "flex" }}>
                                        <Grid.Col span={8}>
                                            <Text style={{ fontSize: "12px" }}>Update GSTIN/PAN/Aadhar No ?</Text>
                                        </Grid.Col>
                                        <Grid.Col span={4}>
                                            <PLComboBox
                                                value={CSDataObj?.lUpdM01Det ? "Y" : "N"}
                                                copno={"1"}
                                                dispexpr={"DisplayMember"}
                                                valexpr={"ValueMember"}
                                                onKeyDown={(e) => {
                                                    GlobalClass.formNextInput(e, formRef);
                                                  }}
                                                setEdit={(e) => {
                                                    setCSDataObj({ ...CSDataObj, lUpdM01Det: (e?.value == "Y" ? true : false) })
                                                }}
                                            />
                                        </Grid.Col>
                                    </Grid.Col> : null
                            }

                            <Grid.Col span={12}>
                                <Group spacing={"xs"} position="center">
                                    <Button
                                        style={{ height: "30px", width: "100px" }}
                                        size="sm"
                                        onClick={() => handleOkButton()}
                                        onKeyDown={(e)=>{
                                            if(e?.key == "Enter"){
                                                handleOkButton()
                                            }
                                        }}
                                    >
                                        Ok
                                    </Button>
                                </Group>
                            </Grid.Col>
                        </Grid>
                }
            </>
        }
    </>)
}

export default UpdateBalanceForm