import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GetItemwiseExpenseList } from '../../utils/slices/ItemwiseExpenseSlice';
import GlobalClass from '../../utils/GlobalClass';
import { Button, Grid, Text } from '@mantine/core';
import PLComboBox from '../../PlusComponent/PLComboBox';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import { createColumnHelper } from '@tanstack/react-table';
import useWindowDimensions from '../../utils/UseWindowDimensions';
import ItemWiseExpenseTableTwo from './ItemWiseExpenseTableTwo';
import { SetExpenseDropdown } from '../../utils/slices/ItemwiseExpenseEntSlice';
import Store from '../../utils/store';

const ItemWiseExpenseTableOne = () => {

    const [ExpenseDropDownVal, setExpenseDropDownVal] = useState()
    const [ExpenseDropDownObj, setExpenseDropDownObj] = useState()
    const [TableOneSelectedObj, setTableOneSelectedObj] = useState()
    const [columns, setColumns] = useState([])
    const [Data, setData] = useState([])
    const [rowSelection, setRowSelection] = useState({ 0: true })


    const dispatch = useDispatch();
    const { height } = useWindowDimensions();

    const ItemwiseExpenseListData = useSelector((state) => state?.ItemwiseExpenseListGroup?.ItemwiseExpenseListData?.data);

    const ExpenseDropdown = useSelector((state)=>state?.ItemwiseExpenseListEntGroup?.ExpenseDropdown)

    // const { COP26, M14, M21, M32C, M32G, M45, M46S11, S16 } = useSelector((state) => state?.ItemwiseExpenseListGroup?.ItemwiseExpenseListData?.data);

const { isLoading,hasError,ErrorMsg } = useSelector((state) => state?.ItemwiseExpenseListGroup)


    const SaveLoading = useSelector(
        (state) => state?.DataSaveLoadingSlice?.SaveLoading
      );


    useEffect(() => {
        dispatch(GetItemwiseExpenseList())
    }, [])


    useEffect(() => {
        if (ItemwiseExpenseListData && ItemwiseExpenseListData?.S16?.length > 0 && ExpenseDropdown?.DropdownValue == "") {
            // setExpenseDropDownVal(ItemwiseExpenseListData?.S16[0]?.S16F02)
            // setExpenseDropDownObj(ItemwiseExpenseListData?.S16[0])

            dispatch(SetExpenseDropdown({
                DropdownValue:ItemwiseExpenseListData?.S16[0]?.S16F02,
                DropdownObj:ItemwiseExpenseListData?.S16[0]
              }))

            console.log("ItemwiseExpenseListData=>", ItemwiseExpenseListData);
        }
    }, [ItemwiseExpenseListData,ExpenseDropdown])

    useEffect(() => {
        if (ItemwiseExpenseListData && ExpenseDropdown?.DropdownValue != "") {
            setData(ItemwiseExpenseListData?.[ExpenseDropdown?.DropdownValue]?.jData)
            // setTableOneSelectedObj(ItemwiseExpenseListData?.[ExpenseDropDownVal]?.jData[0])
        }
    }, [ItemwiseExpenseListData, ExpenseDropdown?.DropdownValue])

    // useEffect(() => {
    //     if (Data != [] && rowSelection) {
    //         let newObj={};
    //         Data?.map((d, i) => {
    //             if (i in rowSelection) {
    //                 newObj ={...d}
    //             }
    //         })
    //         console.log("newObj==>",newObj)
    //         setTableOneSelectedObj(newObj)
    //     }
    // }, [TableOneSelectedObj])

    // console.log("ExpenseDropDownObj=>", ExpenseDropDownObj);
    // console.log("ExpenseDropDownVal=>", ExpenseDropDownVal);
    // console.log("TableOneSelectedObj=>",TableOneSelectedObj);

    const columnHelper = createColumnHelper()

    useEffect(() => {
        if (!isLoading) {
            let col = [];
            col.push(
                columnHelper.accessor("FIELD02", {
                    header: ExpenseDropdown?.DropdownObj?.S16F35
                })
            )
            setColumns(col)
        }
    }, [isLoading, ExpenseDropdown?.DropdownObj?.S16F35])

    const table = useMantineReactTable({
        // data: ItemwiseExpenseListData?.M21 ? ItemwiseExpenseListData?.M21?.jData : [],
        data: Data ? Data : [],
        columns,
        mantineTableContainerProps: {
            sx: { height: height * 0.6 },
        },
        positionToolbarAlertBanner: "none",
        enableFilters: true,
        enableColumnFilters: false,
        enableFullScreenToggle: false,
        enableDensityToggle: false,
        enableTopToolbar: true,
        enableBottomToolbar: false,
        enableHiding: false,
        enableColumnActions: false,
        enablePagination: false,
        enableStickyHeader: true,
        enableMultiRowSelection: false,
        enableGlobalFilter:true,
        mantineTableProps:{withColumnBorders:true},
        initialState: {
            density: "0px"
        },
        state: {
            rowSelection
        },
        mantineTableBodyRowProps: ({ row, staticRowIndex }) => ({
            onClick: () => {
                setRowSelection(() => ({
                    [row.id]: row.id,
                }));
                // console.log("dispatch occured")
                // dispatch(getRowObj(row?.original));
                // setDefaultPageData({...DefaultPageData,PageIndexValue:row.id})
            },
            selected: rowSelection[row.id],
            sx: { cursor: "pointer" },
        })
    })

    const SelectedRowObj = table?.getSelectedRowModel()?.flatRows[0]?.original

    return (<>
        {
         hasError ?  <>
         <Text>{ErrorMsg?.message}</Text>
         <Button onClick={() => {
           dispatch(GetItemwiseExpenseList())
         }}>Retry</Button>
       </>   :isLoading || SaveLoading["ItemWiseExpense"] ? GlobalClass.RenderLoader("dots") :
                <Grid gutter={4}>
                    <Grid.Col span={12} style={{ display: "flex" }}>
                        <Grid.Col span={2}>
                            <Text style={{ fontSize: 12 }}>Expense For</Text>
                        </Grid.Col>
                        <Grid.Col span={4}>
                            <PLComboBox
                                data={ItemwiseExpenseListData?.S16}
                                dispexpr="S16F35"
                                valexpr="S16F03"
                                value={ExpenseDropdown?.DropdownValue}
                                setEdit={(e) => {
                                    console.log("e=>", e);
                                    // setExpenseDropDownVal(e)

                                    setRowSelection({ 0: true })
                                    ItemwiseExpenseListData?.S16?.map((s) => {
                                        if (s?.S16F03 == e) {
                                            // setExpenseDropDownObj(s)
                                            dispatch(SetExpenseDropdown({
                                                DropdownValue:e,
                                                DropdownObj:s
                                              }))
                                        }
                                    })
                                }}
                            />
                        </Grid.Col>
                    </Grid.Col>
                    <Grid.Col span={12} style={{ display: "flex" }}>
                        <Grid.Col span={2}></Grid.Col>
                        <Grid.Col span={3}>
                            <Text style={{ fontSize: 12 }}>Select {ExpenseDropdown?.DropdownObj?.S16F35}</Text>
                        </Grid.Col>
                        <Grid.Col span={1}></Grid.Col>
                        <Grid.Col span={4}>
                            <Text style={{ fontSize: 12, fontWeight: "bold" }}>{ExpenseDropdown?.DropdownObj?.S16F35} : {SelectedRowObj?.FIELD02}</Text>
                        </Grid.Col>
                    </Grid.Col>

                    <Grid.Col span={12} style={{ display: "flex" }}>
                        {/* <Grid.Col span={3} style={{height: height * 0.50 ,border:"2px solid red"}}> */}
                        <Grid.Col span={3}>
                            <MantineReactTable table={table} />
                        </Grid.Col>
                        <Grid.Col span={9}>
                            <ItemWiseExpenseTableTwo M46Field02={SelectedRowObj} S16F03={ExpenseDropdown?.DropdownValue} />
                        </Grid.Col>
                    </Grid.Col>
                </Grid>
        }
    </>)
}

export default ItemWiseExpenseTableOne