import { Button, Checkbox, ColorInput, Grid, Text } from '@mantine/core'
import React, { useEffect, useState } from 'react'
import PLComboBox from '../../../../PlusComponent/PLComboBox'

const AdvanceOptionBtn = (props) => {
    console.log("AdvanceOptionBtn", props)
    const { COPF70, updatedGrpRows, setUpdatedGrpRows, updatedColRows, setUpdatedColRows, rowSelection, grpData, setGrpData, colData, setColData,rowSelection1, tableNm, row, DrawerObj, setDrawerObj } = props
    const [formatChecked, setFormatChecked] = useState(false)
    const [printChecked, setPrintChecked] = useState(false)
    console.log("printChecked", printChecked)
    const [columnChecked, setColumnChecked] = useState(false)
    const [lockColumn, setLockColumn] = useState(false)
    const [searchCol, setSearchCol] = useState(false)
    const [foreColor, setForeColor] = useState(false)
    const [backColor, setBackColor] = useState(false)
    const [rowObj, setRowObj] = useState({})
    useEffect(() => {
        if (row) {
            setRowObj(row)
        }
    }, [row])

    useEffect(() => {
        if (formatChecked) {
            setRowObj({ ...rowObj, DISPFORMAT: "D" })
        }
    }, [formatChecked])

    useEffect(() => {
        if (rowObj?.DISPFORMAT == "D") {
            setFormatChecked(true)
        }
        if (rowObj?.LINPRINT === "Y") {
            console.log("run")
            setPrintChecked(true)
        }
        if (rowObj?.LINGRID == "Y") {
            setColumnChecked(true)
        }
        if (rowObj?.COLLOCK == "Y") {
            setLockColumn(true)
        }
        if (rowObj?.SEARCHCOL == "Y") {
            setSearchCol(true)
        }
        if (rowObj?.LFORECOLOR == "N") {
            setForeColor(true)
        }
        if (rowObj?.LBACKCOLOR == "N") {
            setBackColor(true)
        }
    }, [rowObj?.DISPFORMAT, rowObj?.LINPRINT, rowObj?.LINGRID, rowObj?.COLLOCK, rowObj?.SEARCHCOL, rowObj?.LFORECOLOR, rowObj?.LBACKCOLOR])

    const SaveAdvanceOpt = () => {
        console.log("rowObj", rowObj)
        if(tableNm === "GrpDT"){
            let updatedData = [...grpData]
            let rowIndex = Object.keys(rowSelection)[0]
            updatedData[rowIndex]={...rowObj}
            setGrpData(updatedData)
        } else {
            let updatedData = [...colData]
            let rowIndex = Object.keys(rowSelection1)[0]
            updatedData[rowIndex]={...rowObj}
            setColData(updatedData)
        }
        setDrawerObj({...DrawerObj, open:false})
    }

    return (
        <>
            {
                // rowObj &&
                <Grid gutter={4}>
                    <Grid.Col span={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Grid.Col span={4}>
                            <Text size={12}>Format</Text>
                        </Grid.Col>
                        <Grid.Col span={1}>
                            <Checkbox
                                size={"xs"}
                                checked={formatChecked}
                                onChange={(e) => {
                                    console.log("onChange", e)
                                    setFormatChecked(e.target.checked)
                                }}
                            />
                        </Grid.Col>
                        <Grid.Col span={3}>
                            <Text size={12}>Default</Text>
                        </Grid.Col>
                        <Grid.Col span={4}>
                        </Grid.Col>
                    </Grid.Col>
                    <Grid.Col span={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Grid.Col span={4}></Grid.Col>
                        <Grid.Col span={4}>
                            <Text size={12}>Font Case</Text>
                        </Grid.Col>
                        <Grid.Col span={4}>
                            <PLComboBox
                                data={COPF70}
                                dispexpr="COPF04"
                                valexpr="COPF01"
                                value={rowObj?.DISPFORMAT}
                                disabled={formatChecked == true ? true : false}
                                setEdit={(e) => {
                                    setRowObj({ ...rowObj, DISPFORMAT: e })
                                }}
                            />
                        </Grid.Col>
                    </Grid.Col>
                    <Grid.Col span={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Grid.Col span={4}>
                            <Text size={12}>Alignment</Text>
                        </Grid.Col>
                        <Grid.Col span={4}>
                            <Text size={12}>Header</Text>
                        </Grid.Col>
                        <Grid.Col span={4}>
                            <PLComboBox
                                data={COPF70}
                                dispexpr="COPF04"
                                valexpr="COPF01"
                                value={rowObj?.ALIGN_H}
                                setEdit={(e) => {
                                    setRowObj({ ...rowObj, ALIGN_H: e })
                                }}
                            />
                        </Grid.Col>
                    </Grid.Col>
                    <Grid.Col span={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Grid.Col span={4}>
                        </Grid.Col>
                        <Grid.Col span={4}>
                            <Text size={12}>Detail</Text>
                        </Grid.Col>
                        <Grid.Col span={4}>
                            <PLComboBox
                                data={COPF70}
                                dispexpr="COPF04"
                                valexpr="COPF01"
                                value={rowObj?.ALIGN_D}
                                setEdit={(e) => {
                                    setRowObj({ ...rowObj, ALIGN_D: e })
                                }}
                            />
                        </Grid.Col>
                    </Grid.Col>
                    <Grid.Col span={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Grid.Col span={4}>
                        </Grid.Col>
                        <Grid.Col span={4}>
                            <Text size={12}>Footer</Text>
                        </Grid.Col>
                        <Grid.Col span={4}>
                            <PLComboBox
                                data={COPF70}
                                dispexpr="COPF04"
                                valexpr="COPF01"
                                value={rowObj?.ALIGN_F}
                                setEdit={(e) => {
                                    setRowObj({ ...rowObj, ALIGN_F: e })
                                }}
                            />
                        </Grid.Col>
                    </Grid.Col>
                    <Grid.Col span={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Grid.Col span={4}>
                            <Text size={12}>Display In</Text>
                        </Grid.Col>
                        <Grid.Col span={1}>
                            <Checkbox
                                size={"xs"}
                                checked={printChecked}
                                onChange={(e) => {
                                    setPrintChecked(e.target.checked)
                                    if(e.target.checked == true){
                                        setRowObj({...rowObj, LINPRINT:"Y"})
                                    } else {
                                        setRowObj({...rowObj, LINPRINT:""})
                                    }
                                }}
                            />
                        </Grid.Col>
                        <Grid.Col span={3}>
                            <Text size={12}>Print</Text>
                        </Grid.Col>
                        <Grid.Col span={1}>
                            <Checkbox
                                size={"xs"}
                                checked={columnChecked}
                                onChange={(e) => {
                                    setColumnChecked(e.target.checked)
                                    if(e.target.checked == true){
                                        setRowObj({...rowObj, LINGRID:"Y"})
                                    } else {
                                        setRowObj({...rowObj, LINGRID:""})
                                    }
                                }}
                            />
                        </Grid.Col>
                        <Grid.Col span={3}>
                            <Text size={12}>Column</Text>
                        </Grid.Col>
                    </Grid.Col>
                    <Grid.Col span={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Grid.Col span={4}>
                            <Text size={12}>Lock Column</Text>
                        </Grid.Col>
                        <Grid.Col span={1}>
                            <Checkbox
                                size={"xs"}
                                checked={lockColumn}
                                onChange={(e) => {
                                    setLockColumn(e.target.checked)
                                    if(e.target.checked == true){
                                        setRowObj({...rowObj, COLLOCK:"Y"})
                                    } else {
                                        setRowObj({...rowObj, COLLOCK:"N"})
                                    }
                                }}
                            />
                        </Grid.Col>
                        <Grid.Col span={3}>
                            <Text size={12}>Yes</Text>
                        </Grid.Col>
                        <Grid.Col span={4}>
                        </Grid.Col>
                    </Grid.Col>
                    <Grid.Col span={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Grid.Col span={4}>
                            <Text size={12}>Searching Col.</Text>
                        </Grid.Col>
                        <Grid.Col span={1}>
                            <Checkbox
                                size={"xs"}
                                checked={searchCol}
                                onChange={(e) => {
                                    setSearchCol(e.target.checked)
                                    if(e.target.checked === true){
                                        setRowObj({...rowObj, SEARCHCOL:"Y"})
                                    } else {
                                        setRowObj({...rowObj, SEARCHCOL:"N"})
                                    }
                                }}
                            />
                        </Grid.Col>
                        <Grid.Col span={3}>
                            <Text size={12}>Yes</Text>
                        </Grid.Col>
                        <Grid.Col span={4}>
                        </Grid.Col>
                    </Grid.Col>
                    <Grid.Col span={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Grid.Col span={4}>
                            <Text size={12}>Forecolor</Text>
                        </Grid.Col>
                        <Grid.Col span={1}>
                            <Checkbox
                                size={"xs"}
                                checked={foreColor}
                                onChange={(e) => {
                                    setForeColor(e.target.checked)
                                    if(e.target.checked === true){
                                        setRowObj({...rowObj, LFORECOLOR:"N"})
                                    } else {
                                        setRowObj({...rowObj, LFORECOLOR:"Y"})
                                    }
                                }}
                            />
                        </Grid.Col>
                        <Grid.Col span={3}>
                            <Text size={12}>Default</Text>
                        </Grid.Col>
                        <Grid.Col span={4}>
                            <ColorInput

                            />
                        </Grid.Col>
                    </Grid.Col>
                    <Grid.Col span={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Grid.Col span={4}>
                            <Text size={12}>Backcolor</Text>
                        </Grid.Col>
                        <Grid.Col span={1}>
                            <Checkbox
                                size={"xs"}
                                checked={backColor}
                                onChange={(e) => {
                                    setBackColor(e.target.checked)
                                    if(e.target.checked === true){
                                        setRowObj({...rowObj, LBACKCOLOR:"N"})
                                    } else {
                                        setRowObj({...rowObj, LBACKCOLOR:"Y"})
                                    }
                                }}
                            />
                        </Grid.Col>
                        <Grid.Col span={3}>
                            <Text size={12}>Default</Text>
                        </Grid.Col>
                        <Grid.Col span={4}>
                            <ColorInput

                            />
                        </Grid.Col>
                    </Grid.Col>
                    <Grid.Col span={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Button
                            onClick={() => {
                                SaveAdvanceOpt()
                            }}
                        >OK</Button>
                    </Grid.Col>
                </Grid>
            }
        </>
    )
}

export default AdvanceOptionBtn