import { TabComponent, TabItemDirective, TabItemsDirective } from "@syncfusion/ej2-react-navigations";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import VoucherNumberGrid from "../SetupComponent/VoucherNumber/VoucherNumberGrid";
import { Paper } from "@mantine/core";
import SalesSetupNew from "../FunctionsCall/SalesAndPurchaseSetup/SalesSetupNew";
import { TDSMaster } from "../MyComponent/BindFunWithPara";
import ItemWiseExpenseTableOne from "../FunctionsCall/ItemWiseExpense/ItemWiseExpenseTableOne";
import { CashBank } from "../Transaction/QuickEntry/CashBank";
import MyComponent from "../MyComponent/MyComponent";
import DataGrid from "../components/DataGrid";
import StockRate from "../FunctionsCall/StockRate/StockRatePage";
import { TabRemove, UpdateSelected } from "../utils/slices/TabList";
import PLComboBox from "../PlusComponent/PLComboBox";
import { TabReference } from "../utils/RefSlice/RefSlice";
import Store from "../utils/store";
import useWindowDimensions from "../utils/UseWindowDimensions";
import DataGridSyncfusion from "../components/DataGridSyncfusion";


export default function TabviewFun(props) {
    // const TabRef = useRef(null)
    const { height, width } = useWindowDimensions()
    const TabList = useSelector((state) => state.TabList.value);
    // console.log("TabList", TabList)
    const selected = useSelector((state) => state.TabList.selected);
    // console.log("selected", selected)
    const TabRef = useSelector((state) => state.reference.TabRef)
    // console.log("TabRef", TabRef)
    const SaveAccountListArr = useSelector((store) => store?.AccountList.SaveAccountListArr); //Rathod kishan
    const BtnStatus = useSelector((store) => store?.DataSaveLoadingSlice.ButtonPannelSave); //rathod kishan
    const BtnStatusList = useSelector((store) => store?.DataSaveLoadingSlice.ButtonPannelList); //rathod kishan

    const { AccountListData: accList, TMFormatData: tmList } = useSelector((state) => state.AccountList); // rathod kishan
    const ReportIdArray = useSelector((state) => state.DataSaveLoadingSlice.ReportIdArray); // rathod kishan

    const dispatch = useDispatch();
    const [tabRenderKeys, setTabRenderKeys] = useState({});
    const { tabValue } = useParams();
    const [tabs, setTabs] = useState([]);
    const getTabRef = Store.getState().reference.TabRef
    // console.log("getTabRef",getTabRef)
    // console.log("TabRef", TabRef)

    // useEffect(() => {
    //     if (TabRef == null) {
    //         dispatch(TabReference(TabRef))
    //     }
    // }, [TabRef])

    // useEffect(() => {
    //     console.log("tabs", tabs)
    // }, [tabs])

    const TabSwitch = (tab, item, cSTID) => {
        const renderKey = tabRenderKeys[tab] || 0;
        console.log("TabSwitch =>", [{
            tab: tab,
            item: item,
            cSTID: cSTID,
            "item?.p0?.MBody": item?.p0?.MBody
        }])
        item = {
            ...item,
            cSTID: cSTID
        }
        // if (tab.includes("modalForm")) {
        //     return <>{item.p0.MBody(tab)}</>
        // }
        switch (cSTID) {
            case "T92":
                return <div id={"tab" + tab} key={renderKey}><VoucherNumberGrid tag="M" obj={item} /></div>;
                break;
            case "M21B":
                return <div id={"tab" + tab} key={renderKey}><StockRate tag="M" obj={item} /></div>;
                break;
            case "SS": //VAT/TDS > Sales Setup
            case "PP": //VAT/TDS > Purchase Setup
            case "JJ": //GST > J/W In Setup
            case "JZ": //GST > J/W Out Setup
                return <div id={"tab" + tab} key={renderKey}><SalesSetupNew tag="M" obj={item} /></div>;
                break;
            case "M_VTDS":
                return <div id={"tab" + tab} key={renderKey}><TDSMaster tag="M" obj={item} /></div>;
                break;
            case "M_OE":
                return <div id={"tab" + tab} key={renderKey}><ItemWiseExpenseTableOne obj={item} /></div>
                break;
            case "T_QC":
            case "T_QJ":
                return <div id={"tab" + tab} key={renderKey}><CashBank tag="M" obj={item} /></div>
                break;
            case "_MNU0114":
            case "_MNU0115":
                return <div id={"tab" + tab} key={renderKey}><MyComponent obj={item?.p0} /></div>
            case "Dash":
                return ""//<Dashboard/>;

            default:
                return <div id={"tab"+tab} key={renderKey}><DataGridSyncfusion tag="M" obj={item} IsMultiSelect={false}/></div>
                // return (<div id={"tab" + tab} style={{ padding: 5 }} key={renderKey}><DataGrid tag="M" IsMultiSelect={false} obj={item} /></div>)
                break;
        }
    };

    const TabSelected = (args) => {
        console.log("TabSelected", args.selectedItem.dataset.id)
        dispatch(UpdateSelected(args.selectedItem.dataset.id))
    }
    // const selecting = (args) => {
    //     console.log("selecting", args)
    //     let updatedTabVal = tabs.find((v, i) => args.selectedIndex == i)
    //     console.log("updatedTabVal", updatedTabVal)
    //     dispatch(UpdateSelected(updatedTabVal))
    // }
    // useEffect(() => {
    //     let notin = Object.keys(TabList).filter((i) => !tabs.includes(i));
    //     if (notin && notin?.length > 0) {
    //         console.log("notin", notin);
    //         notin.map((v, i) => {
    //             var item = TabList[v];
    //             console.log("TabList_item", item)
    //             const totalItems = document.querySelectorAll('#_tab_header_items .e-toolbar-item').length;
    //             console.log("totalItems", totalItems)
    //             TabRef?.current?.addTab([{
    //                 header: { text: item?.name }, id: notin[0], content: () => TabSwitch(notin[0], item, (item.id == "_MNU0114" || item.id == "_MNU0115" || item.id == "UM" ? item?.id :
    //                     item?.name == "Dashboard" ? "Dash" :
    //                         item?.p1))
    //             }], totalItems);
    //             // TabRef.current.select(totalItems+1)
    //             // console.log("TabRef.current.select(totalItems)",TabRef.current.select(totalItems))
    //             setTimeout(() => {
    //                 TabRef?.current?.selectTab(totalItems);
    //                 // console.log("Tab selected at index", totalItems - 1);
    //             }, 100);
    //             setTabs([...tabs, v]);
    //         })
    //     } else {

    //     }

    // }, [TabList])

    const RemoveTab = (e) => {
        if (e.name === "removing") {
            const removedIndex = e.removedIndex
            let findRemoveTab = tabs[removedIndex]
            console.log("findRemoveTab", findRemoveTab)
            let FilterTab = tabs?.filter((v, i) => i != removedIndex)
            console.log("FilterTab", FilterTab)
            if (findRemoveTab !== "Dash") {
                setTabs(FilterTab)
                dispatch(TabRemove(findRemoveTab))
            }
        }
    }

    return (
        <>
            <TabComponent
                showCloseButton={true}
                // width={`${width * 1.2}`}
                // style={{position:"sticky"}}
                // heightAdjustMode="Fill"
                ref={(ref) => {
                    dispatch(TabReference(ref))
                }}
                removing={(e) => {
                    console.log("remove", e);
                    if(e.name == "removing"){
                        if(e.removedItem.dataset.id =="Dashboard"){
                            e.cancel = true;
                        } else {
                            if(e.removedItem.dataset.id.startsWith("modalForm")){
                                Object.keys(TabList).map((i) => {
                                    var item = TabList[i];
                                    if (item?.p0?.onClickCloseButton && typeof item?.p0?.onClickCloseButton == "function") {
                                        item?.p0?.onClickCloseButton()
                                      }
                                })
                            }
                            dispatch(TabRemove(e.removedItem.dataset.id))
                        }
                    }
                }}
                
                selected={TabSelected}
                // selecting={selecting}
                // heightAdjustMode="Auto"
                // overflowMode="Scrollable"
                // loadOn="Dynamic"
                // enablePersistence={true}
                // loadOn='Init'
                swipeMode="None"
                
                
            >
                <TabItemsDirective>
                    
                                <TabItemDirective
                                    key={"Dashboard"}
                                    content={''}
                                    id="Dashboard"
                                    
                                    header={{ text: "Dashboard" }}
                                >
                                </TabItemDirective>
                </TabItemsDirective>
            </TabComponent>
        </>
    )
}