import React from 'react'
import Store from '../../../utils/store';
import { ModalFunction } from '../../../utils/slices/ModalSlice';
import UserFieldAutoNumForm from './UserFieldAutoNumForm';
import { Text } from '@mantine/core';
import GlobalClass from '../../../utils/GlobalClass';
import { DeleteUserFieldAutoNum } from '../../../utils/slices/CompanySetUpPopUpSlice';

export const UserFieldAutoNum = () => {
  return (
    <div>UserFieldAutoNum</div>
  )
}

// export default UserFieldAutoNum

export function T95AED(data) {
  const OBJ = JSON.parse(data);
  console.log('OBJ=>>',OBJ)

  if(OBJ.p0 == "A") {
    Store.dispatch(ModalFunction({
      MTitle: <Text fw={700}>{OBJ?.text}</Text>,
      MAction: true,
      MSize: 'lg',
      position:"right",
      MBody: (i) => (<UserFieldAutoNumForm obj={OBJ} index={i} />),
      MClose: true,
      onclose: () => { }
    }))
  } else if (OBJ.p0 === "E" && OBJ.id && OBJ.id !== "") {
    Store.dispatch(ModalFunction({
      MTitle: <Text fw={700}>{OBJ?.text}</Text>,
      MAction: true,
      MSize: 'lg',
      position:"right",
      MBody: (i) => (<UserFieldAutoNumForm obj={OBJ} index={i} />),
      MClose: true,
      onclose: () => { }
    }))
  } else if (OBJ.p0 === "D" && OBJ.id ) {
    // if(window.confirm('Confirm to Delete ?')) {
    //   Promise.resolve(GlobalClass.Notify("info", "Info: " + "Delete Progress", "Please Wait Fetching Data")).then(() => Store.dispatch(DeleteUserFieldAutoNum({action: OBJ.p0, code : OBJ.id})))
    // }

    GlobalClass.Confirm(
      "red",
      "Are you sure to delete selected data?",
      "It will be Deleted Forever and Cannot Be Recovered!!!",
      () => {
        Promise.resolve(GlobalClass.Notify("info", "Info: " + "Delete Progress", "Please Wait Fetching Data")).then(() => Store.dispatch(DeleteUserFieldAutoNum({action: OBJ.p0, code : OBJ.id})))
      },
      () => { },
      "Cancel",
      "Confirm"
  )
  }

}