import { Box, Button, Grid, Group, LoadingOverlay, Text } from '@mantine/core'
import { modals } from '@mantine/modals'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PLDataGrid from '../../PlusComponent/PLDataGrid'
import PLNumberBox from '../../PlusComponent/PLNumberBox'
import { AssignItemDefault, CalCFI, RecalcFun } from './AssignItemDefaultFun'
import { GetAccountList } from '../../utils/slices/AccountListSlice'
import GlobalClass from '../../utils/GlobalClass'
import { useDisclosure } from '@mantine/hooks'

const styles = {
    GridClass: { maxHeight: "300px", overflow: "hidden", overflowY: "scroll" }
}

const ExpenseEntryForm = (props) => {
    // console.log("ExpenseEntryForm====>", props)
    const { ExpenseObj, expenseArr, M01PData, M01Format, valueSaveObj, onChange, isExpense, TableExpenseArr, TableExpenseValue, ProductObj, expenseArrDef, saveBtnPara, DrawerOBJ, setDrawerOBJ, SendselectedTaxAcc, T41ObjSend, ExpenseHideShowFlag } = props;

    const [DtExpTableField, setDtExpTableField] = useState([])
    const [M01D, setM01D] = useState([])
    const [M01F, setM01F] = useState()
    const [FieldObj, SetFieldObj] = useState(null)
    const [T02UpdateArray, setT02UpdateArray] = useState([])
    const [FooterExpFlag, setFooterExpFlag] = useState(false)
    const [ExpenseForEachProductFlag, setExpenseForEachProductFlag] = useState(false)

    const formRef = useRef(null);
    const SalePurchaseAcc = useRef(null)


    const [visible, { open, close }] = useDisclosure(false);

    const dispatch = useDispatch()

    const [formValue, setFormValue] = useState(0)

    const InvTypeDropDown = useSelector((state) => state?.TSalesSlices?.InvTypeDropDown)
    const ExpenseData = useSelector((state) => state?.TSalesSlices?.ExpenseData)


    //for oYear Obj Get
    const GetGeneral = useSelector((state) => state?.Language?.General)
    // const { OS11, OSV } = useSelector((state) => state.Transaction?.TransactionData)
    // const { oYear } = useSelector((state) => state?.Language?.General)

    const OS11 = useSelector((state) => state.Transaction?.TransactionData?.OS11)
    const OSV = useSelector((state) => state.Transaction?.TransactionData?.OSV)
    const oYear = useSelector((state) => state?.Language?.General?.oYear)

    // const M01SP = useSelector((state) => state.Transaction?.TransactionData?.M01SP);
    // const M01SPDT = useSelector((state) => state.Transaction?.TransactionData?.M01SPDT);

    const dtM14DT = useSelector((state) => state.Transaction?.TransactionData?.dtM14);
    const dtM51DT = useSelector((state) => state.Transaction?.TransactionData?.dtM51);
    const dtM52DT = useSelector((state) => state.Transaction?.TransactionData?.dtM52);
    const dtM46DT = useSelector((state) => state.Transaction?.TransactionData?.dtM46);

    const { AccountListData: accList, TMFormatData: tmList, isLoading: AccNameisLoading } = useSelector((state) => state.AccountList)

    // const [calcfiPara,setcalcfiPara] =useState(null);

    // {
    //     name: "",
    //     type: "A",
    //     id: id ?? props?.TmData?.split("~C~")[0],
    //     p0: props.TmData?.split("~C~")[0],
    //     p1: props.TmData?.split("~C~")[0],
    //     p2: props.TmData?.split("~C~")[1],
    //   }


    // const TotalAmt = (ExpenseType == true ? T02ObjSend?.FIELD08 : T02ObjSend?.FIELD07);
    // console.log("TableExpenseArr ==>", TableExpenseArr);


    useEffect(() => {
        // console.log("FieldObj ONBLUR useEffect ", FieldObj);
        if (isExpense == true) {
            dispatch(GetAccountList({
                "id": "P_M21",
                "name": "",
                "type": "A",
                "p0": "P_M21",
                "p1": "P_M21",
                "p2": "",
                "p3": "",
                "cWhere": `M21.FIELD01 LIKE '%${valueSaveObj?.FIELD03}%'`
                // "cWhere": ""
            }))
        }
        //   "M21.FIELD12 LIKE '%CHVEAJP4%'"
    }, [isExpense])

    useEffect(() => {
        const formElement = formRef.current;
        // console.log("formRef", formRef);
    
        if (formElement) {
          formElement.addEventListener('keypress', (e) => GlobalClass.formNextInput(e, formRef));
        }
    
        return () => {
          if (formElement) {
            formElement.removeEventListener('keypress', (e) => GlobalClass.formNextInput(e, formRef));
          }
        };
      }, [formRef])

    //     useEffect(()=>{
    // console.log("AccNameisLoading status =>",AccNameisLoading);
    //     },[AccNameisLoading])

    // useEffect(() => {
    //     if (isExpense == true) {
    //         console.log("Expense component Product =>", [{
    //             "accList": accList["P_M21"],
    //             "valueSaveObj": valueSaveObj,
    //             "isExpense": isExpense
    //         }]);
    //     }
    // }, [isExpense, accList])



    useEffect(() => {
        // console.log("valueSaveObjConsole 1=>", valueSaveObj);
        if (isExpense && valueSaveObj) {
            SetFieldObj(valueSaveObj)
        } else {
            SetFieldObj(valueSaveObj)
        }
    }, [isExpense, valueSaveObj, TableExpenseArr])

    useEffect(() => {
        if (M01PData && M01Format) {
            setM01D(M01PData)
            setM01F(M01Format)
        }
    }, [M01PData, M01Format])

    useEffect(() => {
        if (isExpense) {
            setFormValue(valueSaveObj.FIELD08)
        } else {
            setFormValue(TableExpenseValue)
        }
    }, [isExpense, valueSaveObj, TableExpenseValue])

    useEffect(() => {

        // console.log("InvTypeDropDown", InvTypeDropDown);

        if (InvTypeDropDown && InvTypeDropDown != {}) {

            SetControls().then((dt) => {

                // console.log("dt", dt);

                let newArray = [...dt];

                if (!isExpense) {

                    // console.table("TableExpenseArr", TableExpenseArr);

                    newArray?.map((d, i) => {

                        let IDTotal = (TableExpenseArr?.reduce((a, b) => a = a + (b["ID" + d.FIELD54]), 0) * 100)

                        let FIELD07Total = TableExpenseArr?.reduce((a, b) => a = a + parseInt(b["FIELD08"]), 0)

                        // console.log(`IDTotal FIELD07Total ${d.FIELD02}=>`, [{
                        //     "IDTotal": IDTotal,
                        //     "FIELD07Total": FIELD07Total,
                        //     "IDTotal + FIELD07Total": IDTotal / FIELD07Total,
                        //     "TableExpenseArr": TableExpenseArr
                        // }]);

                        newArray[i] = {
                            ...d,
                            EXPPERC: IDTotal / FIELD07Total,
                            EXPAMT: TableExpenseArr?.reduce((a, b) => a = a + (b["ID" + d.FIELD54] * ((["R", "C"].includes(b["FIELD05"]) ? "C" : "D") == (OS11?.S11F21 == "D" ? "D" : "C") ? 1 : -1)), 0)
                        }
                    })
                    // console.table("newArray", newArray);
                }
                setDtExpTableField(newArray)
            })
        }
    }, [expenseArr, InvTypeDropDown, TableExpenseArr, isExpense])


    const SetControls = async () => {

        return (expenseArr?.length > 0 ? [...expenseArr] : [])
    }

    // const Recalc = async (d, index1, t, dr1, lFCalc = true) => {
    //     console.log("After newFieldObj=>", FieldObj);

    //     let oExp = d;
    //     let lItemWise = t
    //     let lFItemwise = false;
    //     // let TotalAmt = lItemWise ? d.FIELD08 : TableExpenseValue;
    //     let TotalAmt = parseFloat(formValue)
    //     let GstAmt = 0;
    //     let FreeAmt = 0;
    //     let TmpVal = 0;
    //     let nAmt = TotalAmt;
    //     let nShAmt = nAmt;
    //     let cVType = OS11?.S11F01;
    //     let EqnAmt = "";
    //     let sFQty = "";
    //     let sSQty = "";
    //     let dFQty = 0;
    //     let dSQty = 0;
    //     let nTemp = 0;
    //     let SurcVal = 0;
    //     let nType = 0;

    //     if (oYear?.OSC._GSTFREQTY == "Y") {
    //         // Check if FIELD08 is empty and FIELD81 is not empty
    //         if (lItemWise) {
    //             if (oExp.FIELD08 == "" && oExp.FIELD81 != "") {
    //                 FreeAmt = oExp.FIELD07 * oExp.FIELD81;
    //             }
    //             else if (oExp.FIELD33 != "" && oExp.FIELD81 != "") {
    //                 // Check if FIELD33 and FIELD81 are not empty
    //                 FreeAmt = oExp.FIELD08 / oExp.FIELD33 * oExp.FIELD81;
    //             }
    //         }
    //     };
    //     // let TmpVal = 0;
    //     //    let nT = MApp.pc.ToInt(dr["SeqNo"]); // Unknown
    //     console.log("TmpVal Amt", nAmt);
    //     let newarray = [...DtExpTableField];
    //     let newFieldObj = { ...FieldObj };
    //     DtExpTableField?.map((dr, index) => {

    //         console.table("ExpenseObj?.dtExp_I map =>", [{
    //             "index": index,
    //             "dr": dr
    //         }]);
    //         GstAmt = parseFloat(GstAmt);
    //         FreeAmt = parseFloat(FreeAmt);
    //         TmpVal = parseFloat(TmpVal);
    //         dFQty = parseFloat(dFQty);
    //         dSQty = parseFloat(dSQty);
    //         nTemp = parseFloat(nTemp);
    //         SurcVal = parseFloat(SurcVal);
    //         nType = parseFloat(nType);

    //         // let dr = aa[i]
    //         lFItemwise = !lFItemwise ? dr?.FIELD53 == "D" : lFItemwise;
    //         let nT = dr?.SeqNo;
    //         let lObjItemwise = dr?.FIELD53 == "I";  // Expense is Itemwise
    //         let lFItemExp = dr?.FIELD53 == "D";     // Fix-Itemwise
    //         let lSurcharge = dr?.FIELD06 == "U";    // Surcharge
    //         let lCum = dr?.FIELD06 == "C";          // Cummulative
    //         let lFQty = dr?.FIELD06 == "Q";         // First Qty
    //         let lSQty = dr?.FIELD06 == "S";         // Second Qty
    //         let cType = dr?.FIELD06 == "M" ? "F" : dr?.FIELD06;//Calc Type
    //         // let Perc =((isNaN(dr?.EXPPERC) || typeof dr?.EXPPERC == "string") ? 0 : dr?.EXPPERC || 0) * ((("R".includes(oExp["FIELD05"]) || "C".includes(oExp["FIELD05"])) ? "C" : "D") == (OS11?.S11F21 == "D" ? "D" : "C") ? 1 : -1)
    //         let Perc = dr?.EXPPERC && !isNaN(dr?.EXPPERC) ? (parseFloat(dr?.EXPPERC) * ((["R", "C"].includes(oExp["FIELD05"]) ? "C" : "D") == (ExpenseObj?.OS11?.S11F21 == "D" ? "D" : "C") ? 1 : -1)) : 0; //oExp['IP' + dr.FIELD54] //dr.EXPPERC   // Expense Percentage
    //         let Amt = dr?.EXPAMT && !isNaN(dr?.EXPAMT) ? parseFloat(dr?.EXPAMT) : 0;
    //         // let Amt = (!dr?.EXPAMT || typeof dr?.EXPAMT == "string" ? 0 : dr?.EXPAMT);   // Expense Amount // EXPAMT ma value set nathi ... FIELD07 ma set kr tyare aama pan thavi joi e ...
    //         // let Amt = lItemWise ? oExp.FIELD08 : oExp.FIELD07;
    //         let cVCO = dr?.FIELD03;                 // Expense Type
    //         let cRoundOff = dr?.FIELD75;            // Round off Type 
    //         //lRound = MApp.pc.InList(cRoundOff, "F", "C", "Y");  // Round Off - Yes/No             
    //         // let lRound = cRoundOff?.includes("F", "C", "Y");
    //         let lRound = ["F", "C", "Y"].includes(cRoundOff)
    //         let lAChange = false; //unknown

    //         console.table("Recalc map Values", [{
    //             "dr?.FIELD02": dr?.FIELD02,
    //             "dr?.EXPAMT": dr?.EXPAMT,
    //             "dr?.EXPPERC": dr?.EXPPERC,
    //             "TotalAmt": TotalAmt,
    //             "Amt": Amt,
    //             "perc": Perc,
    //             "lRound": lRound,
    //             "cRoundOff": cRoundOff
    //         }]);

    //         if (dr?.EXPPERDISP == "1" || dr?.EXPACCDISP == "1") {
    //             // d.EXPPERC = d.EXPPERC == null ? 0 : d.EXPPERC; //(EXPPERC- Expense%) Unknown
    //             if (lItemWise && !lObjItemwise && !lFItemExp) { //1
    //                 console.log("RecalcCondition 1");
    //                 return null
    //             } else if (nT < index) { //2
    //                 console.log("RecalcCondition 2");
    //                 TmpVal = Amt;
    //                 if (["3", "4"].includes(cVCO)) {
    //                     GstAmt = GstAmt + TmpVal;
    //                 }
    //             } else if (!lItemWise && lObjItemwise) { //3
    //                 console.log("RecalcCondition 3");
    //                 TmpVal = Amt;
    //                 if (["3", "4"].includes(cVCO)) {
    //                     GstAmt = GstAmt + TmpVal;
    //                 }
    //             } else if (lSurcharge) { // 4
    //                 console.log("RecalcCondition 4");
    //                 TmpVal = Amt;

    //                 if (dr.EXPPERC != null && !lAChange) {
    //                     let drArr = DtExpTableField.filter((a) => a.EXPSURSEQ >= 0);
    //                     drArr?.map((dtSur, dtSuri) => {
    //                         let nIndex = dtSur.EXPSURSEQ;

    //                         SurcVal = SurcVal + DtExpTableField[nIndex].EXPAMT ? 0 : DtExpTableField[nIndex].EXPAMT
    //                             * (DtExpTableField[nIndex].EXPDeduct == "-" ? -1 : 1) * (DtExpTableField[index].EXPDeduct == "-" ? -1 : 1);
    //                     })
    //                     TmpVal = Math.round(SurcVal * Perc / 100).toFixed(2);
    //                 }
    //                 // IF lRound the set Calculated value to roundoff According to Expense defination
    //                 if (lRound) {
    //                     switch (cRoundOff) {
    //                         case "Y":
    //                             TmpVal = Math.round(TmpVal).toFixed(0);
    //                             break;
    //                         case "C":
    //                             TmpVal = Math.ceil(TmpVal);
    //                             break;
    //                         case "F":
    //                             TmpVal = Math.floor(TmpVal);
    //                             break;
    //                     }
    //                 }

    //             } else if (EqnAmt != "" && !lAChange && ["E", "T"].includes(cVCO) || (["V", "C"].includes(cVCO) && oYear?.OSC?._STATE == "S02")) { // 5
    //                 console.log("RecalcCondition 5");
    //                 if (lRound) {
    //                     switch (cRoundOff) {
    //                         case "Y":
    //                             TmpVal = Math.round(TmpVal).toFixed(0);
    //                             break;
    //                         case "C":
    //                             TmpVal = Math.ceil(TmpVal);
    //                             break;
    //                         case "F":
    //                             TmpVal = Math.floor(TmpVal);
    //                             break;
    //                     }
    //                 }
    //                 //Equation Will be perform Here Pending this case right now
    //             } else if (dr?.FIELD06 == "R") { //6
    //                 console.log("RecalcCondition 6");
    //                 TmpVal = nAmt - Math.round(nAmt);
    //                 TmpVal = TmpVal == 0 ? 0 : (TmpVal * (TmpVal <= 0.50 ? -1 : 1));
    //             } else if (dr?.EXPPERREQ != "1" || lAChange) { //7
    //                 console.log("RecalcCondition 7");
    //                 TmpVal = Amt;
    //                 if (lRound) {
    //                     switch (cRoundOff) {
    //                         case "Y":
    //                             TmpVal = Math.round(TmpVal).toFixed(0);
    //                             break;
    //                         case "C":
    //                             TmpVal = Math.ceil(TmpVal);
    //                             break;
    //                         case "F":
    //                             TmpVal = Math.floor(TmpVal);
    //                             break;
    //                     }
    //                 }
    //                 if (["3", "4"].includes(cVCO)) {
    //                     GstAmt = GstAmt + TmpVal;
    //                 }
    //             } else if (lFQty || cType == "K") { //8
    //                 console.log("RecalcCondition 8");
    //                 if (cType == "K") {
    //                     TmpVal = (dr.EXPADDICESS ?? 0) * (oExp.FIELD06 ?? 0);
    //                 }
    //                 else {
    //                     sFQty = lItemWise ? oExp.FIELD06 : "";
    //                     dFQty = sFQty ?? 0;
    //                     TmpVal = Perc * dFQty; // Percentage Value & Quantity
    //                 }

    //             } else if (lSQty) { // 9
    //                 console.log("RecalcCondition 9");
    //                 sSQty = lItemWise ? d.FIELD21 : "";
    //                 dSQty = sSQty ?? 0;
    //                 TmpVal = Perc * dSQty; // Percentage Value & Quantity
    //             } else if (dr.EXPPERREQ == "1" && ["V", "D"].includes(cVCO) && cType == "A" && dr.M45F08) {//10
    //                 nTemp = nAmt - DtExpTableField[index - 1]?.EXPAMT;
    //                 TmpVal = Math.round(nTemp * (dr.EXPPERC / 100)).toFixed(2);
    //                 if (lRound) {
    //                     switch (cRoundOff) {
    //                         case "Y":
    //                             TmpVal = Math.round(TmpVal).toFixed(0);
    //                             break;
    //                         case "C":
    //                             TmpVal = Math.ceil(TmpVal);
    //                             break;
    //                         case "F":
    //                             TmpVal = Math.floor(TmpVal);
    //                             break;
    //                     }
    //                 }
    //             } else if (dr?.EXPPERREQ == "1" && cType != "A") { //11
    //                 console.log("RecalcCondition 10");
    //                 // console.log("Perc", Perc);
    //                 if (Perc == 0 && Amt != 0 && (!["V", "D", "3", "4"].includes(cVCO))) {
    //                     console.log("RecalcCondition 101");
    //                     if ((nType > 0 || dr.FIELD53 == "D") && cVType != "SH" && !lItemWise) {
    //                         TmpVal = 0;
    //                     } else {
    //                         TmpVal = Amt;
    //                     }
    //                 } else {
    //                     // console.log("RecalcCondition 102", [{
    //                     //     "cType": cType,
    //                     //     '"B".includes(cType)': "B".includes(cType),
    //                     //     '"D".includes(cType)': "D".includes(cType),
    //                     //     '"I".includes(cType)': "I".includes(cType),
    //                     //     '"J".includes(cType)': "J".includes(cType)
    //                     // }]);
    //                     // if (("B".includes(cType)) || ("D".includes(cType))|| ("I".includes(cType)) || ("J".includes(cType)))
    //                     if (["B", "D", "I", "J"].includes(cType)) {
    //                         console.log("RecalcCondition 1021")
    //                         nTemp = nAmt - GstAmt;
    //                         if (oYear.OSC._GSTFREQTY)//AND NOT This.oYear.lBlwThre
    //                         {
    //                             nTemp = nTemp + FreeAmt;
    //                         }
    //                     } else {
    //                         nTemp = lCum ? nAmt : TotalAmt;
    //                         console.log("RecalcCondition 1022=>", [{
    //                             "nTemp": nTemp,
    //                             "lCum": lCum,
    //                             "nAmt": nAmt,
    //                             "TotalAmt": TotalAmt
    //                         }])
    //                     }
    //                     TmpVal = parseFloat(Math.round(nTemp * (Perc / 100)).toFixed(2));

    //                 }

    //                 // console.log("RecalcCondition 10 parameter =>", [{
    //                 //     "lRound": lRound,
    //                 //     "cRoundOff": cRoundOff,
    //                 //     "cVCO": cVCO
    //                 // }]);

    //                 if (lRound) {
    //                     switch (cRoundOff) {
    //                         case "Y":
    //                             TmpVal = Math.round(TmpVal).toFixed(0);
    //                             break;
    //                         case "C":
    //                             TmpVal = Math.ceil(TmpVal);
    //                             break;
    //                         case "F":
    //                             TmpVal = Math.floor(TmpVal);
    //                             break;
    //                     }
    //                 }

    //                 if (["3", "4"].includes(cVCO)) {
    //                     if (oYear.OSC._GR1RTTAX && oExp.FIELD53 == "Y" && (["SS", "SR", "PP", "PR", "HC", "HD"].includes(cVType))) {
    //                         // oVou.cVType.includes("SS", "SR", "PP", "PR", "HC", "HD")) {

    //                         TmpVal = TmpVal * 0.65;
    //                     }
    //                     GstAmt = GstAmt + TmpVal;

    //                 }
    //             }
    //             // dr.EXPAMT = TmpVal; //Unknown
    //             //  oExp[dr.EXPAMTSRC] = dr.EXPAMT; // Unknown
    //             // Add To Total Only If Account effect Is Yes. Do Not Add In Case Of Account Effect No/Seperate

    //             newarray[index] = { ...dr, EXPAMT: (TmpVal * ((["R", "C"].includes(oExp["FIELD05"]) ? "C" : "D") == (ExpenseObj?.OS11?.S11F21 == "D" ? "D" : "C") ? 1 : -1)) }
    //             if (dr.FIELD61 == "Y") {
    //                 // Add calculated value to final total
    //                 // console.log("Add calculated value to final total =>", [{
    //                 //     "nAmt": nAmt,
    //                 //     "TmpVal": TmpVal,
    //                 //     "dr.EXPDEDUCT": dr.EXPDEDUCT,
    //                 //     'dr.EXPDEDUCT == "-"': (dr.EXPDEDUCT == "-" ? -1 : 1)
    //                 // }]);
    //                 nAmt = nAmt + (TmpVal * (dr.EXPDEDUCT == "-" ? -1 : 1));
    //                 //    nAmt = (nAmt + TmpVal);
    //                 console.log("nAmt", nAmt);
    //                 // return FieldObj['ID' + MObj?.FIELD54] = nAmt
    //                 if (lItemWise) {
    //                     // console.log("TmpVal==>", TmpVal);
    //                     newFieldObj = { ...newFieldObj, FIELD90: parseFloat(Math.abs(nAmt)), ['ID' + dr?.FIELD54]: parseFloat(Math.abs(TmpVal)) }
    //                     // newFieldObj = { ...newFieldObj, FIELD08: nAmt, ['ID' + dr?.FIELD54]: TmpVal, ExpAmount: nAmt }
    //                     // SetFieldObj({ ...FieldObj, FIELD08: nAmt, ['ID' + dr?.FIELD54]: TmpVal })
    //                     console.log("newFieldObj Expense entryform true =>", [{
    //                         "newFieldObj": newFieldObj,
    //                         "nAmt": parseFloat(Math.abs(nAmt)),
    //                         "TmpVal": parseFloat(Math.abs(TmpVal))
    //                     }]);
    //                 } else {
    //                     // console.log("namtnamt", nAmt);
    //                     // let FooterKey = (dr?.FIELD53 == "F" ? dr?.FIELD13 : ('ED' + dr?.FIELD54))
    //                     newFieldObj = { ...newFieldObj, FIELD06: parseFloat(Math.abs(nAmt)), [dr?.FIELD13]: parseFloat(Math.abs(TmpVal)) }
    //                     console.log("newFieldObjdr?.FIELD13", dr?.FIELD13, "#dr?.FIELD54", dr?.FIELD54, "#dr?.FIELD02", dr?.FIELD02);
    //                     // SetFieldObj({ ...FieldObj, FIELD07: nAmt, ['ID' + dr?.FIELD54]: TmpVal })
    //                     // console.log("newFieldObj Expense entryform false =>", [{
    //                     //     // "FooterKey":FooterKey,
    //                     //     "newFieldObj": newFieldObj,
    //                     //     "nAmt": parseInt(nAmt),
    //                     //     "TmpVal": parseInt(TmpVal),
    //                     //     "dr": dr
    //                     // }])
    //                 }
    //                 return nAmt
    //             } else {
    //                 if (lItemWise) {
    //                     newFieldObj = { ...newFieldObj, ['ID' + dr?.FIELD54]: parseFloat(Math.abs(TmpVal)) }
    //                     // SetFieldObj({ ...FieldObj, ['ID' + dr?.FIELD54]: TmpVal })
    //                 } else {
    //                     // let FooterKey = (dr?.FIELD53 == "F" ? dr?.FIELD13 : ('ED' + dr?.FIELD54))
    //                     newFieldObj = { ...newFieldObj, [dr?.FIELD13]: parseFloat(Math.abs(TmpVal)) }
    //                 }
    //             }
    //         } else {
    //             // dr.EXPAMT = 0;
    //             // oExp[dr.EXPAMTSRC] = dr.EXPAMT //(EXPAMTSRC - sourcefield name)
    //             // // console.log("oExp[dr.EXPAMTSRC]", oExp[dr.EXPAMTSRC]);
    //             // let newarray = [...DtExpTableField];
    //             // newarray[index] = { ...dr, EXPAMT: 0 }
    //             // // console.log("newarray", newarray);
    //             // setDtExpTableField(newarray)
    //         }

    //     })

    //     // console.log("RecalcFunFinalCalculaction =>", [{
    //     //     "newarray": newarray,
    //     //     "newFieldObj": newFieldObj
    //     // }]);
    //     // setDtExpTableField(newarray);
    //     // SetFieldObj(newFieldObj);

    //     // let FQty1 = (OSV?._FREEQTY == "N" ? false : true);
    //     // let FQty2 = (oYear?.OSC?._GSTFREQTY == "N" ? false : true);
    //     // let lFQtyOnGST = (FQty1 && FQty2 ? true : false);
    //     // // let lForce = false;
    //     // let YPara = (oYear?.OSC?._GR1RTTAX == "N" ? false : true)
    //     // let lFCalc;
    //     // if (lFQtyOnGST) {
    //     //     lFCalc = false
    //     // } else if (YPara) {
    //     //     lFCalc = true
    //     // } else {
    //     //     lFCalc = false
    //     // }
    //     console.log("Before newFieldObj=>", newFieldObj);




    //     if (lItemWise) {
    //         setDtExpTableField(newarray);
    //         SetFieldObj(newFieldObj);
    //     } else if (lFItemwise && !lItemWise && lFCalc) { 
    //         setDtExpTableField(newarray);
    //         SetFieldObj(newFieldObj);
    //         let para = { ...ExpenseObj, T41Obj: newFieldObj, dtExp: newarray, lItemWise: false, ProductAllData: accList["P_M21"] }
    //         let objectsArray = [...para?.T02Data]
    //         let updatedObjects = [];

    //         new Promise((resolve) => {
    //             objectsArray?.map(async (obj) => {
    //                 console.log('ExpenseObj?.T02Data 1', newarray);
    //                 let updatedObject2 = await CalCFI(para, obj); // Ensure each object is fully processed
    //                 console.log('ExpenseObj?.T02Data 2', updatedObject2);
    //                 updatedObjects.push(updatedObject2);
    //             })
    //             setTimeout(() => {
    //                 resolve(updatedObjects)
    //             }, 500)
    //         }).then((res) => {
    //             props.setT02Data(res)
    //             // setT02UpdateArray(updatedObjects)
    //             console.log("ExpenseObj?.T02Data CalCFI", [{"res":res,"updatedObjects":updatedObjects}])
    //         })


    //         // setDtExpTableField(newarray);
    //         // SetFieldObj(newFieldObj);
    //         //  updatedObjects;

    //         // processObjects(para, ExpenseObj?.T02Data).then((e) => console.log("ExpenseObj?.T02Data CalCFI", e))

    //     } else {
    //         setDtExpTableField(newarray);
    //         SetFieldObj(newFieldObj);
    //     }

    // }

    // useEffect(()=>{
    //     if(!ExpenseForEachProduct && isExpense){
    //         open()
    //         setTimeout(()=>{
    //             SaveExpenseDetail()
    //             close()
    //         },5000)
    //     }
    // },[ExpenseForEachProduct])

    useEffect(() => {
        if (ExpenseHideShowFlag == "N" && isExpense) {
            open()
            // Promise.resolve().then(()=>open()).then(()=>setTimeout(() => {
            //         SaveExpenseDetail()
            //         close()
            //     }, 5000))
        }
    }, [ExpenseHideShowFlag, isExpense])

    useEffect(() => {
        if (ExpenseForEachProductFlag) {
            close()
            if (FieldObj?.FIELD16) {
                SaveExpenseDetail()
            }
        }
    }, [ExpenseForEachProductFlag])

    const SaveExpenseDetail = async () => {
        if (isExpense) {
            Promise.resolve(onChange(FieldObj)).then(() => setDrawerOBJ({ ...DrawerOBJ, open: false }))
        } else {
            Promise.resolve(onChange({ ObjT41: FieldObj, ArrT02: T02UpdateArray })).then(() => setDrawerOBJ({ ...DrawerOBJ, open: false }))
        }
    }

    const nextIndFunction = (ind, e) => {
        // if (e == "one") {
        //     const nextInd = ind;
        //     const nextInput = document.getElementById(`arr2_${nextInd}`)
        //     if (nextInput) {
        //         nextInput.focus()
        //     }
        // }
        const nextInd = ind + 1;
        // console.log("nextInd==>", nextInd);
        if (nextInd < DtExpTableField?.length) {
            const nextInput = document.getElementById(`arr1_${nextInd}`)
            if (nextInput) {
                nextInput.focus()
            }
            else {
                return nextIndFunction(nextInd)
            }
        }
    }

    useEffect(() => {
        if (isExpense && ExpenseObj && valueSaveObj && expenseArr) {

            // console.log("valueSaveObj", [{
            //     "valueSaveObj": valueSaveObj,
            //     'accList["P_M21"]': accList["P_M21"]
            // }]);

            let productObjFind = {}
            let newObject = {}
            let dt;

            new Promise((resolve, reject) => {
                accList["P_M21"]?.map((p, i) => {
                    if (p.FIELD01 == valueSaveObj?.FIELD03) {
                        productObjFind = { ...p }
                    }
                })
                resolve(productObjFind)
            }).then((PObject) => {

                // console.log("PObject", PObject);
                let ExpArr = [...expenseArr]

                newObject = {
                    ...ExpenseObj,
                    dtExp_I: ExpArr?.map((e) => {
                        let updatedObj = { ...e };
                        Object.keys(valueSaveObj).map((v) => {
                            if (v == ("IP" + e?.FIELD54)) {
                                updatedObj.EXPPERC = valueSaveObj[v]
                            } else if (v == ("ID" + e?.FIELD54)) {
                                updatedObj.EXPAMT = valueSaveObj[v]
                            }
                        })
                        return updatedObj
                    }),
                    ItemCode: PObject?.FIELD03,
                    selectedProductObj: PObject,
                    T02Obj: valueSaveObj
                }

                // console.log("UseEffect Itemwise Expense Open =>", [{
                //     "newObject": newObject
                // }]);
                return newObject
            }).then((newobj) => {
                // console.log("newobj======>", newobj);
                dt = AssignItemDefault(newobj);
                return { dt, newobj }
            }).then((expdt) => {
                // let a = expdt.dt.dtExpArr;
                // let b = expdt.dt.T02Object;
                // let c = expdt.newobj
                // console.log("AssignItemDefault response =>", [{ "expdt.T02Object": expdt.T02Object,"expdt":expdt.dtExpArr }]);
                // console.log("expdt =>", expdt);
                return RecalcFun(expdt?.dt?.T02Object, "", true, "", { ...expdt.newobj, T02Obj: expdt?.dt?.T02Object, dtExp_I: expdt.dt.dtExpArr })
            }).then((res) => {
                // console.log("Response RecalcFun =>", res)
                Promise.resolve(SetFieldObj(res?.T02CalAarray)).then(() => setDtExpTableField(res?.ExpenseCalArray))
            }).then(() => {
                // setTimeout(()=>{
                if (ExpenseHideShowFlag == "N") {
                    // console.log("ExpenseHideShowFlagForm 2",ExpenseHideShowFlag);
                    // setTimeout(()=>{
                    setExpenseForEachProductFlag(true)
                    // },1000)
                }
                // },1000)
            })

            // console.table("isExpense,ExpenseObj,valueSaveObj =>", [{
            //     "isExpense": isExpense,
            //     "ExpenseObj": ExpenseObj,
            //     "valueSaveObj": valueSaveObj,
            //     // "newObject": newObject
            // }]);
        }
    }, [isExpense, ExpenseObj, valueSaveObj, expenseArr])

    useEffect(() => {
        // console.log("FooterExpFlag if out =>", FooterExpFlag);
        if (FooterExpFlag) {
            setTimeout(() => {
                SaveExpenseDetail()
                setFooterExpFlag(false)
            }, 500)
        }
    }, [FooterExpFlag])

    // useEffect(() => {
    //     // setTimeout(() => {
    //         console.log("SalePurchaseAcc =>", document?.getElementById('SalePurchaseAcc'));

    //         if (document?.getElementById('SalePurchaseAcc')) {
    //             console.log("SalePurchaseAcc 1=>", document?.getElementById('SalePurchaseAcc'));
    //             document?.getElementById('SalePurchaseAcc')?.focus()
    //         }
    //     // }, 1000)
    // }, [document?.getElementById('SalePurchaseAcc')])

    return (<>
        <Box maw={"100%"} pos="relative">
            <LoadingOverlay visible={visible} overlayBlur={2} />
            <>
                {
                    isExpense == true && AccNameisLoading["P_M21"] ? GlobalClass.RenderLoader('dots') : <>

                        <Grid gutter={4} ref={formRef}>

                            <>
                                {
                                    isExpense == true &&
                                    <>
                                        <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
                                            <Grid.Col span={3} ></Grid.Col>
                                            <Grid.Col span={3}>

                                            </Grid.Col>
                                            <Grid.Col span={1}>
                                            </Grid.Col>
                                            <Grid.Col span={2} style={{ fontSize: 12, fontWeight: 'bold' }}>
                                                Item Amount:
                                            </Grid.Col>
                                            <Grid.Col span={3}>
                                                {
                                                    isExpense == true ? parseFloat((Number(valueSaveObj?.FIELD08) || 0).toFixed(2)) : valueSaveObj?.FIELD07
                                                }
                                            </Grid.Col>
                                        </Grid.Col>
                                        <Grid.Col span={12} style={{ display: "flex", padding: 0, alignItems: 'center' }}>
                                            <Grid.Col span={3} style={{ fontSize: 12 }}>Sales A/C.</Grid.Col>
                                            <Grid.Col span={3}>
                                                <PLDataGrid
                                                    // ref={SalePurchaseAcc}
                                                    // id={'SalePurchaseAcc'}
                                                    width={'500px'}
                                                    // autoFocus
                                                    // disabled={FieldObj?.FIELD16 == "" ? false : true}
                                                    value={FieldObj?.FIELD16}
                                                    // TmData={"P_M01~C~TFORM0000001~C~M01.FIELD07 IN ('TP','JE','JP','JT')"}
                                                    valexpr="FIELD01"
                                                    dispexpr="FIELD02"
                                                    TmData={ExpenseData?.M01SP}
                                                    data={ExpenseData?.M01SPDT?.jData}
                                                    setEdit={(e) => {
                                                        // console.log(e)
                                                        // Promise.resolve()
                                                        // SetFieldObj({ ...FieldObj, FIELD16: e?.FIELD01 })
                                                        if (isExpense) {
                                                            // let FieldObjtemp = { ...FieldObj}
                                                            // DtExpTableField?.map((d, ind) => {
                                                            //     FieldObjtemp = { ...FieldObj, ['IA' + d?.FIELD54]: e?.FIELD01 }
                                                            //     // SetFieldObj({ ...FieldObj, ['IA' + d?.FIELD54]:FieldObj?.FIELD16 })
                                                            // })
                                                            // SetFieldObj({ ...FieldObjtemp, FIELD16: e?.FIELD01 })

                                                            SetFieldObj(prevFieldObj => {
                                                                // Use functional update to avoid overwriting previous state
                                                                return DtExpTableField?.reduce((updatedFieldObj, d) => {
                                                                    // Only update if the corresponding IA key exists in FieldObj
                                                                    // console.log("updatedFieldObj =>", updatedFieldObj);

                                                                    if (d?.FIELD54 && (updatedFieldObj['IA' + d.FIELD54] == "" || updatedFieldObj['IA' + d.FIELD54] == null)) {
                                                                        return { ...updatedFieldObj, ['IA' + d.FIELD54]: e?.FIELD01 };
                                                                    } else {
                                                                        return updatedFieldObj;
                                                                    }
                                                                }, { ...prevFieldObj, FIELD16: e?.FIELD01 }); // Spread previous state to avoid modifying it directly
                                                            });

                                                        } else {
                                                            SetFieldObj({ ...FieldObj, FIELD16: e?.FIELD01 })
                                                        }

                                                    }}
                                                />
                                            </Grid.Col>
                                            <Grid.Col span={1}>
                                            </Grid.Col>
                                            <Grid.Col span={2}>
                                            </Grid.Col>
                                            <Grid.Col span={3}>
                                            </Grid.Col>
                                        </Grid.Col>
                                    </>
                                }
                            </>
                            {
                                DtExpTableField?.map((d, ind) => {
                                    // console.log("d===>", d);
                                    return (<>

                                        <Grid.Col span={12} style={{ display: "flex", padding: 0, alignItems: 'center' }} key={ind}> {/* Account name */}
                                            <Grid.Col span={3} style={{ fontSize: 12 }}>{d?.FIELD02}</Grid.Col>
                                            <Grid.Col span={3}>
                                                <PLDataGrid
                                                    width={'500px'}
                                                    disabled={d?.EXPACCED}
                                                    // disabled={d?.FIELD05 == "V" ? false : true}
                                                    // value={d?.FIELD04}
                                                    value={isExpense ? (d?.FIELD04 == "" ? FieldObj?.['IA' + d?.FIELD54] : d?.FIELD04) : (d?.FIELD04 == "" ? FieldObj?.[d?.FIELD28] : d?.FIELD04)}
                                                    // value={isExpense ? (d?.FIELD04 == "" ? FieldObj?.['IA' + d?.FIELD54] : d?.FIELD04) : (d?.FIELD53 == "F" ? d?.FIELD28 : ('EA' + d?.FIELD54))}
                                                    valexpr="FIELD01"
                                                    dispexpr="FIELD02"
                                                    TmData={ExpenseData?.M01SP}
                                                    data={ExpenseData?.M01SPDT?.jData}
                                                    // TmData={M01F}
                                                    // data={M01D}
                                                    // TmData={"P_M01~C~TFORM0000001~C~M01.FIELD07 IN ('TP','JE','JP','JT')"}
                                                    setEdit={(e) => {
                                                        // console.log(e)
                                                        if (isExpense == true) {
                                                            SetFieldObj({ ...FieldObj, ['IA' + d?.FIELD54]: e?.FIELD01 })
                                                        } else {
                                                            // let FooterKey = (d?.FIELD53 == "F" ? d?.FIELD28 : ('EA' + d?.FIELD54))
                                                            SetFieldObj({ ...FieldObj, [d?.FIELD28]: e?.FIELD01 })
                                                        }
                                                    }}
                                                />
                                            </Grid.Col>
                                            <Grid.Col span={1}>
                                                {
                                                    d?.FIELD14 === 'D' && <Text style={{ fontSize: 12 }}>(-)</Text>
                                                }
                                            </Grid.Col>
                                            <Grid.Col span={2}> {/* Percentage */}
                                                {
                                                    d?.EXPPERDISP == "1" && <PLNumberBox
                                                        // disabled={d?.EXPPERED == "0"}
                                                        decimalSeparator={true}
                                                        precision={"3"}
                                                        // value={parseInt(d?.EXPPERC)}
                                                        value={isExpense ? FieldObj?.['IP' + d?.FIELD54] : FieldObj?.[d?.FIELD23]}
                                                        setNumber={async () => {

                                                            if (isExpense == false) {
                                                                new Promise((resolve) => {
                                                                    let newExpenseObj = {
                                                                        ...ExpenseObj,
                                                                        T41Obj: FieldObj,
                                                                        dtExp: DtExpTableField,
                                                                        perc: true,
                                                                    }
                                                                    let fun = RecalcFun(FieldObj, ind, isExpense, d, newExpenseObj)
                                                                    resolve(fun)
                                                                }).then((res) => {
                                                                    // console.log("RecalcFunResponse 11=>", res);
                                                                    setT02UpdateArray(res?.T02CalCFIData)
                                                                    setDtExpTableField(res?.ExpenseCalArray)
                                                                    SetFieldObj(res?.T02CalAarray)
                                                                }).then(() => {
                                                                    setTimeout(() => {
                                                                        setFooterExpFlag(true)
                                                                    }, 500)
                                                                })
                                                            } else {
                                                                // Recalc(FieldObj, ind, isExpense, d)
                                                                new Promise((resolve) => {
                                                                    let newExpenseObj = {
                                                                        ...ExpenseObj,
                                                                        T02Obj: FieldObj,
                                                                        dtExp_I: DtExpTableField,
                                                                        perc: true,
                                                                    }
                                                                    let fun = RecalcFun(FieldObj, ind, isExpense, d, newExpenseObj)

                                                                    resolve(fun)
                                                                }).then((res) => {
                                                                    // console.log("RecalcFunResponse 12=>", res);
                                                                    setDtExpTableField(res?.ExpenseCalArray)
                                                                    SetFieldObj(res?.T02CalAarray)
                                                                })
                                                            }

                                                        }}
                                                        id={`arr1_${ind}`}

                                                        setEdit={(e) => {
                                                            // console.log("d?.FIELD54", d?.FIELD54);

                                                            if (isExpense == true) {
                                                                SetFieldObj({ ...FieldObj, ['IP' + d?.FIELD54]: e })
                                                            } else {
                                                                // let FooterKey = (d?.FIELD53 == "F" ? d?.FIELD23 : ('EP' + d?.FIELD54))
                                                                SetFieldObj({ ...FieldObj, [d?.FIELD23]: e })
                                                            }
                                                            // setDtExpTableField([...DtExpTableField,{...d,EXPPERC:e}])

                                                            let newarray = [...DtExpTableField];
                                                            newarray[ind] = { ...d, EXPPERC: e }
                                                            setDtExpTableField(newarray)
                                                        }}
                                                    />
                                                }
                                            </Grid.Col>
                                            <Grid.Col span={3}> {/* Amount */}
                                                <PLNumberBox
                                                    disabled={d?.EXPAMTED == "0"}
                                                    decimalSeparator={true}
                                                    setNumber={async (e) => {

                                                        if (isExpense == false) {
                                                            new Promise(async (resolve) => {
                                                                let newExpenseObj = {
                                                                    ...ExpenseObj,
                                                                    T41Obj: FieldObj,
                                                                    dtExp: DtExpTableField
                                                                }
                                                                let fun = await RecalcFun(FieldObj, ind, isExpense, d, newExpenseObj)
                                                                resolve(fun)
                                                            }).then((res) => {
                                                                // console.log("RecalcFunResponse 21=>", res);
                                                                setT02UpdateArray(res?.T02CalCFIData)
                                                                setDtExpTableField(res?.ExpenseCalArray)
                                                                SetFieldObj(res?.T02CalAarray)
                                                            }).then(() => {
                                                                setTimeout(() => {
                                                                    setFooterExpFlag(true)
                                                                }, 500)
                                                            })
                                                        } else {
                                                            // Recalc(FieldObj, ind, isExpense, d)
                                                            new Promise(async (resolve) => {
                                                                let newExpenseObj = {
                                                                    ...ExpenseObj,
                                                                    T02Obj: FieldObj,
                                                                    dtExp_I: DtExpTableField
                                                                }
                                                                let fun = await RecalcFun(FieldObj, ind, isExpense, d, newExpenseObj)
                                                                resolve(fun)
                                                            }).then((res) => {
                                                                // console.log("RecalcFunResponse 22=>", res);
                                                                setDtExpTableField(res?.ExpenseCalArray)
                                                                SetFieldObj(res?.T02CalAarray)
                                                            })
                                                        }
                                                    }}
                                                    // value={d?.EXPAMT}
                                                    value={isExpense ? FieldObj?.['ID' + d?.FIELD54] : FieldObj?.[d?.FIELD13]}
                                                    id={`arr2_${ind}`}
                                                    setEdit={(e) => {
                                                        if (isExpense == true) {
                                                            SetFieldObj({ ...FieldObj, ['ID' + d?.FIELD54]: e })
                                                        } else {
                                                            // let FooterKey = (d?.FIELD53 == "F" ? d?.FIELD13 : ('ED' + d?.FIELD54))
                                                            SetFieldObj({ ...FieldObj, [d?.FIELD13]: e })
                                                        }
                                                        let newarray = [...DtExpTableField];
                                                        newarray[ind] = { ...d, EXPAMT: e }
                                                        setDtExpTableField(newarray)
                                                    }}
                                                />
                                            </Grid.Col>
                                        </Grid.Col></>)
                                })
                            }

                            {
                                isExpense == true &&
                                <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
                                    <Grid.Col span={3} ></Grid.Col>
                                    <Grid.Col span={3}>

                                    </Grid.Col>
                                    <Grid.Col span={1}>
                                    </Grid.Col>
                                    <Grid.Col span={2} style={{ fontSize: 12, fontWeight: 'bold' }}>
                                        Total Amount:
                                    </Grid.Col>
                                    <Grid.Col span={3}>
                                        {parseFloat((Number(FieldObj?.FIELD90) || 0).toFixed(2))}
                                        {/* {FieldObj?.FIELD90} */}
                                    </Grid.Col>
                                </Grid.Col>
                            }
                        </Grid>
                        {
                            isExpense == true &&
                            <Grid gutter={4}>
                                <Grid.Col span={12}>
                                    <Group spacing={"sm"} position='center'>
                                        <Button
                                            id='submitExpense'
                                            size='sm'
                                            onKeyDown={(e) => {
                                                // console.log("e.preventDefault()=>", e);
                                                e.preventDefault();
                                                if (e.key == "Enter" || e.key == " ") {
                                                    // field06
                                                    SaveExpenseDetail()
                                                }
                                            }}
                                            onClick={() => SaveExpenseDetail()}
                                        >
                                            Ok
                                        </Button>
                                    </Group>
                                </Grid.Col>
                            </Grid>
                        }
                    </>
                }

            </>
        </Box>
    </>)
}

export default ExpenseEntryForm