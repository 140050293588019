import {
  ActionIcon,
  Box,
  Button,
  Drawer,
  Flex,
  Grid,
  Group,
  Modal,
  Text,
  Tooltip,
} from "@mantine/core";
import React, { useEffect, useRef, useState } from "react";
import { MRT_TablePagination, MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { IconEdit, IconReceiptTax, IconTrash } from "@tabler/icons-react";
import PLComboBox from "../../PlusComponent/PLComboBox";
import PLDataGrid from "../../PlusComponent/PLDataGrid";
import PLTextBox from "../../PlusComponent/PLTextBox";
import PLNumberBox from "../../PlusComponent/PLNumberBox";
import GlobalClass from "../../utils/GlobalClass";
import Store from "../../utils/store";
import { BatchLogAddRemoveFun } from "../../utils/TransactionSlices/Transaction";
import PLDateBox from "../../PlusComponent/PLDateBox";
import SITableUserField from "../SaleEntry/SITableUserField";
import { createColumnHelper } from "@tanstack/react-table";
import ExpenseEntryForm from "../SaleEntry/ExpenseEntryForm";
import { useSelector } from "react-redux";
import {
  GetJOrderData,
  GetJWListData,
  getPendingIssueData,
} from "../../utils/TransactionSlices/PendingIssueSlice";
import gensetting from "../../utils/gensetting";
import PendingIssue from "../JobWorkOut/RIOJobOutForm/PendingIssue";
import PendingOrder from "../JobWorkOut/RIOJobOutForm/PendingOrder";
import AssignItemDefaultFun, {
  AssignItemDefault,
  MidCalc,
  RecalcFun,
  RefMe,
  RetTaxAcc,
} from "../../Transaction/SaleEntry/AssignItemDefaultFun";
import SITableBatchUserField from "../SaleEntry/SITableBatchUserField";
import { Qty_CheckStk } from "../SaleEntry/QtyCheckStock";
import { GetAccountList } from "../../utils/slices/AccountListSlice";
import { ReverseCalCulation } from "../SaleEntry/EquationsFunctions";
import PLColumnSelection from "../../PlusComponent/PLColumnSelection";
import { ModalDelete, ModalFunction } from "../../utils/slices/ModalSlice";

export default function T02Table(props) {
  let {
    accList,
    tmList,
    dataEnt,
    obj,
    setT02Data,
    t02Data,
    t02Obj,
    setT02Obj,
    t41: group,
    setGroup,
    // setTableExpenseVal,
    ExpenseData,
    InvTypeDropDown,
    selectedProductObj,
    setSelectedProductObj,
    // setHeaderExpenseFlag,
    // NarrationBox,
    // JWListData,
    // JWOrderData,
    ExpenseObj,
    footerButtonArray,
    TableRef,
    TableContainer,
    rowSelection,
    setRowSelection,
    isHandleCreateNeeded,
    setIsHandleCreateNeeded,
    pagecCode
  } = props;

  // console.log("t02tablelogprops of", props);

  const { oYear } = useSelector((state) => state?.Language?.General);
  // console.log("oYear", oYear)
  const { JWListData, JWOrderData, isLoading } = useSelector(
    (state) => state?.GetPendingIssue
  );
  const { editRowMsg, isLoadingItemEnt } = useSelector(
    (state) => state?.Transaction
  );

  //----Usestate Declaration--//
  // console.log("productObj", selectedProductObj);
  const [columns, setColumns] = useState([]);

  const [t02DataENT, setT02DataENT] = useState([]);
  const [batch, setBatch] = useState(false);
  const [tableEditingRow, setTableEditingRow] = useState(null);
  const [TableExpense, setTableExpense] = useState([]);
  const [FIELD01Value, setFIELD01value] = useState("");
  const [BatchIssueObj, setBatchIssueObj] = useState(null);
  const [orderPopUp, setOrderPopUp] = useState(false);
  const [flag, setFlag] = useState(false);
  const [priceListParemeter, setPriceListParemeter] = useState({
    priceListCode: "",
    productCode: "",
    accountCode: "",
  });
  const [LocationObj, setLocationObj] = useState(null);
  const [recIss, setRecIss] = useState("");
  const [pendingIssue, setPendingIssue] = useState([]);
  const [pendingOrder, setPendingOrder] = useState([]);
  const [cTypes, setcType] = useState("");
  const [orderArr, setOrderArr] = useState({
    productCode: "",
    partyCode: "",
    dateCode: "",
  });
  const [issueArr, setIssueArr] = useState({
    productCode: "",
    partyCode: "",
    processCode: "",
    dateCode: "",
  });
  const [ExpenseArrayT02, setExpenseArrayT02] = useState([]);
  let dra = {
    title: "",
    size: "md",
    body: "",
    open: false,
    onClickCloseButton: null,
  };
  const [DrawerObj, setDrawerObj] = useState(dra);
  // const [rowSelection, setRowSelection] = useState({});
  const [createFlag, setCreateFlag] = useState(false);
  const [GroupCatogaryProduct, setGroupCatogaryProduct] = useState({
    Group: "",
    Catogary: "",
    Product: "",
  });
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 25,
  });
  const [batchTrue, setBatchTrue] = useState(false);
  const [productDisable, setProductDisable] = useState(false);
  const [empty, setEmpty] = useState(false);
  const [ExpenseHideShowFlag, setExpenseHideShowFlag] = useState(null)

  const prt = useRef();
  const prCt = useRef();
  const grpRef = useRef();
  const hiddenBtnRef = useRef(null);
  const locationRef = useRef(null);
  // const TableRef = useRef();
  // const TableContainer = useRef(null);
  const spoilerControlRef = useRef(null);


  // const addBtnref = useRef(null)

  // const jw = useSelector((state) => state?.GetPendingIssue?.JWOrderData);
  //--Variable Declaration stateless --//
  const p0 = obj.p0;
  const billPage = obj.id;
  const T41Obj = dataEnt?.T41;
  const T02 = dataEnt?.T02;
  const T02UF = dataEnt?.T02UF;
  const dtUFT11 = dataEnt?.dtUFT11;
  const PLDATA = dataEnt?.PLDATA;
  const OSV = dataEnt?.OSV;
  const OS11 = dataEnt?.OS11;
  var jwOrder, jwIssue;

  const page =
    billPage == "04080399" || billPage == "20452041" || billPage == "04290417"
      ? billPage
      : obj?.page;
  // console.log("page=====>", page)

  const columnHelper = createColumnHelper();
  // useEffect(() => {
  //   if (T02) {
  //     const updatedT02 = T02?.filter((v, i) => v?.FIELD03 !== "");
  //     setT02Data(updatedT02);
  //     if (obj.p0 == "E") {
  //       setT02Obj(T02?.[T02?.length - 1]);
  //     } else {
  //       // console.log(T02?.[0], "T02?.[0]=-")
  //       setT02Obj(T02?.[0]);
  //     }
  //   }
  // }, [T02]);

  // useEffect(() => {
  //   if(!isLoading && !hasError){
  //     console.log(hasError, "hasError")
  //    Promise.resolve().then(()=>setOrderData(JWOrderData))
  //   }
  // }, [isLoading, hasError])

  function modeOn(arg) {
    if (
      props?.setCreatingModeOn &&
      typeof props?.setCreatingModeOn == "function"
    ) {
      props?.setCreatingModeOn(arg);
    }
  }
  function setIsVisible(arg) {
    if (props?.setIsVisible && typeof props?.setIsVisible == "function") {
      props?.setIsVisible(arg);
    }
  }
  const NagativeQtyPopUpFun = (e) => {
    GlobalClass.Notify("warning", "Warning", e)
  }


  const TotalEntryCalc = () => {
    let Str = 'Total Entry :'
    let T02DataLen = [...t02Data.filter((d) => d?.FIELD03 != "")]
    let selectedRow = table?.getSelectedRowModel()?.flatRows
    let ReturnStr = ""
    if (T02DataLen.length < 0) {
      ReturnStr = ""
    } else if (T02DataLen.length > 0 && selectedRow.length > 0) {
      if (selectedRow?.[0]?.original?.FIELD03 != "") {
        ReturnStr = Str + (parseInt(selectedRow?.[0].index) + parseInt(1)) + '/' + T02DataLen.length
      } else {
        ReturnStr = Str + T02DataLen.length
      }
    } else if (T02DataLen.length > 0) {
      ReturnStr = Str + T02DataLen.length
    }

    return ReturnStr

  }
  const Summary = (obj, i, tag) => {
    // let stype = "sum";
    var value = "";
    switch (obj?.S53F02) {
      // case "E00000000002":
      //   value = <>Total Entry: {t02Data?.length} </>;

      //   break;
      case "E00000000017":
        value = t02Data?.reduce((a, b) => (a = a + b?.FIELD08), 0);

        break;
      case "E00000000007":
        value = t02Data?.reduce((a, b) => (a = a + b?.FIELD21), 0);

        break;
      case "E00000000010":
        value = t02Data?.reduce((a, b) => (a = a + b?.FIELD33), 0);

        break;
      default:
        value = null;
        break;
    }
    return value;
  };
  const NextIndexFun = (index) => {
    // console.log("NextIndexFun==", index)
    const nextInd = index + 1;
    const nextInput = document.getElementById(`T02Ent_${nextInd}`);
    // console.log(nextInput, "nextInputd");
    if (nextInput) {
      nextInput?.focus();
    }
  };
  const seperate = (e) => {
    var paraArr = e.split("~C~");
    // console.log("paraArr=>", paraArr);
    if (paraArr.length == 1) {
      paraArr[1] = paraArr[0];
    }
    return paraArr;
  };
  function DateToString(e) {
    // console.log(e, "Datetostring");
    let year = e.toLocaleString("en-IN", { year: "numeric" });
    let month = e.toLocaleString("en-IN", {
      month: "2-digit",
    });
    let day = e.toLocaleString("en-IN", { day: "2-digit" });

    let formatedStartDate = day + "/" + month + "/" + year;
    let startDate = year + month + day;
    return { formatedStartDate, startDate };
  }
  function rowEditCancelHandler() {
    // modeOn(false);
    if (obj.p0 == "E") {
      setT02Obj({ ...T02?.[T02?.length - 1], FIELD05: OS11?.S11F21 });
    } else {
      setT02Obj({ ...T02?.[0], FIELD05: OS11?.S11F21 });
      if (setSelectedProductObj && typeof setSelectedProductObj == "function")
        setSelectedProductObj(null);
      setRecIss("");
      setBatchIssueObj(null);
      setLocationObj(null);
    }
    setProductDisable(false);
    setGroupCatogaryProduct(null);
    setTableEditingRow(null);
    table?.setEditingRow(null);
  }
  // useEffect(() => {
  //   if (
  //     (selectedProductObj?.FIELD51 == "Y" && t02Obj?.FIELD41?.trim() == "") ||
  //     t02Obj?.FIELD03 == ""
  //   )
  //     setBatchTrue(true);
  // }, [selectedProductObj, t02Obj?.FIELD41, t02Obj?.FIELD03]);
  useEffect(() => {
    if (page == "20400399") setBatchTrue(true);
  }, [page]);

  useEffect(() => {
    if (props?.flagCopy == true) {
      if (table?.getSelectedRowModel()?.flatRows.length > 0) {
        setT02Data([...t02Data, table?.getSelectedRowModel()?.flatRows[0]?.original])
        props?.setFlagCopy(false)
      }
    }
  }, [props?.flagCopy])

  const RowDetailHandle = (e) => {
    // console.log("RowDetailHandle =>", e);

    let productObjFind = {};
    let cStr = "";
    accList["P_M21"]?.forEach((p, i) => {
      if (p?.FIELD01 == e?.FIELD03) {
        productObjFind = { ...p }
      }
    })

    let FUnit = (e?.FIELD05 == 'I' || e?.FIELD05 == 'D') ? (productObjFind?.FIELD06 == "" ? oYear?.OSC?._UNIT1 : productObjFind?.FIELD06) : (productObjFind?.FIELD05 == "" ? oYear?.OSC?._UNIT1 : productObjFind?.FIELD05);

    // let cStr = cStr + FUnit + "  " + productObjFind.FIELD98 + "   ";

    cStr = cStr + FUnit + "  " + productObjFind?.FIELD98 + "   ";

    if (oYear?.OSC?._DUALSTOCK == "Y" && productObjFind?.FIELD59 == 'Y') {
      let SUnit = productObjFind?.FIELD27 == "" ? oYear?.OSC?._UNIT2 : productObjFind?.FIELD27
      return cStr + SUnit + "  " + productObjFind?.FIELD93
    } else {
      return cStr
    }

  }

  useEffect(() => {
    // console.log("isHandleCreateNeeded", isHandleCreateNeeded);
    if (isHandleCreateNeeded) {
      handleCreateUser("", isHandleCreateNeeded)
    }
  }, [isHandleCreateNeeded])

  function handleCreateUser(e, multi) {
    // console.log("handleCreateUser",e);
    // console.log("handleCreateUser", multi);

    //commented if condition because after selecting row in pending challan we are calling handleCreateUser in this case below conditon is not fulfilling and rather than adding rows in table it gives validation error (commented if as per instructed by kishanbhai)

    // if ((selectedProductObj?.FIELD51 == "Y" && t02Obj?.FIELD41?.trim() == "" && !batchTrue) || t02Obj?.FIELD03 == "") {
    //   GlobalClass.Notify("warning", "Validation error", "Please fill out all the required fields");
    //   return true;
    // } else {

    setGroupCatogaryProduct(null);
    let objChange = (e ? { ...e, FIELD09: parseInt(t02Data?.length) + 1 } : { ...t02Obj, FIELD09: parseInt(t02Data?.length) + 1 })

    // Below pages are containing pending challan 

    if ((obj?.id === "04080399" || obj?.page === "04110399" || obj?.id === "04290417" || obj?.page === "04320417")) {
      if (Array.isArray(multi)) {
        Store.dispatch(BatchLogAddRemoveFun({
          PageCode: pagecCode,
          T41Obj: group,
          T02Data: t02Data,
          Row: multi,
          OBJ: { ...obj, page: page },
        }))
      } else {
        Store.dispatch(BatchLogAddRemoveFun({
          PageCode: pagecCode,
          T41Obj: group,
          T02Data: t02Data,
          Row: objChange,
          OBJ: { ...obj, page: page },
        }))
      }
    } else {
      Store.dispatch(BatchLogAddRemoveFun({
        PageCode: pagecCode,
        T41Obj: group,
        T02Data: t02Data,
        Row: objChange,
        OBJ: { ...obj, page: page },
      }))
    }


    let nullFilter = t02Data?.filter((d) => d?.FIELD03 != "")

    if (Array.isArray(multi)) {
      // setT02Data([...T02Data, ...multi])
      setT02Data([...nullFilter, ...multi, (obj.p0 == "E" ? { ...T02?.[T02?.length - 1], FIELD05: OS11?.S11F21 } : { ...T02?.[0], FIELD05: OS11?.S11F21 })])
    } else {
      setT02Data([...nullFilter, { ...objChange }, (obj.p0 == "E" ? { ...T02?.[T02?.length - 1], FIELD05: OS11?.S11F21 } : { ...T02?.[0], FIELD05: OS11?.S11F21 })]);
    }


    if (obj.p0 == "E") {
      setT02Obj({ ...T02?.[T02?.length - 1], FIELD05: OS11?.S11F21 });
    } else {
      setT02Obj({ ...T02?.[0], FIELD05: OS11?.S11F21 });
    }


    // console.log("objChange", objChange);

    let newObj;
    // if (objChange?.FIELD08 <= 0) { // Amount
    //   newObj = {
    //     ...objChange,
    //     FIELD08: 0,
    //   };
    // }

    let tObj;

    if (page == "03630342" && objChange?.FIELD04 == "") {
      if (objChange?.FIELD08 <= 0) {
        tObj = {
          ...objChange,
          FIELD04: OS11?.S11F22 === "R" || OS11?.S11F22 === "C" ? "C" : "D",
          FIELD05: OS11?.S11F21,
          FIELD08: 0,
        };
      } else {
        tObj = {
          ...objChange,
          FIELD04: OS11?.S11F22 === "R" || OS11?.S11F22 === "C" ? "C" : "D",
          FIELD05: OS11?.S11F21,
        };
      }
    }

    if (page == "03630342") {
      setT02Data([
        ...t02Data,
        page == "03630342" ? tObj : t02Obj?.FIELD08 <= 0 ? newObj : objChange,
      ]);
    }

    props?.setHeaderExpenseFlag(true)
    setIsVisible(true);

    setTimeout(() => {
      table.setCreatingRow(null)
      setBatchIssueObj(null)
      setLocationObj(null)
      setSelectedProductObj(null)
      setRowSelection({})
      setProductDisable(false);
      setRecIss("");
      setCreateFlag(false);
      setIsHandleCreateNeeded("")
    }, 100)
    // }
  }

  function handleSaveUser(e) {
    // console.log("handleSaveUser", "t02Obj", t02Obj);
    // console.log("handleSaveUser", e);
    let objChange = e ? e : t02Obj;
    // console.log(t02Obj, "OBJcHANGE OF EDIT", objChange, e);
    // let row = props?.row;
    Promise.resolve(setT02Obj({ ...t02Obj, FIELD06: t02Obj?.FIELD33 + t02Obj?.FIELD81 })).then(() => {
      Store.dispatch(
        BatchLogAddRemoveFun({
          PageCode: pagecCode,
          T41Obj: group ?? {},
          T02Data: t02Data,
          Row: { ...objChange, FIELD06: objChange?.FIELD33 + objChange?.FIELD81 },
          OBJ: { ...obj, page: page },
        })
      )
    });


    let EditT02Data = [...t02Data];
    EditT02Data[tableEditingRow?.index] = objChange;

    // console.log("EditT02Data0", EditT02Data);

    let nullFilter = EditT02Data?.filter((d) => d?.FIELD03 != "")
    setT02Data([...nullFilter, (obj.p0 == "E" ? { ...T02?.[T02?.length - 1], FIELD05: OS11?.S11F21 } : { ...T02?.[0], FIELD05: OS11?.S11F21 })])
    props?.setHeaderExpenseFlag(true)

    if (obj.p0 == "E") {
      setT02Obj({ ...T02?.[T02?.length - 1], FIELD05: OS11?.S11F21 });
    } else {
      setT02Obj({ ...T02?.[0], FIELD05: OS11?.S11F21 })
    }

    setIsVisible(true);
    table.setEditingRow(null); //exit editing mode
    setGroupCatogaryProduct(null);
    setTableEditingRow(null);
    setBatchIssueObj(null);
    setLocationObj(null);
    setSelectedProductObj(null)
    setProductDisable(false);
    setRowSelection({ [t02Data?.length]: true })
    setRecIss("");
    props?.setEditingRow(null);

  }

  function rowCancelHandler() {
    // modeOn(false);
    if (p0 == "E") {
      setT02Obj({ ...T02?.[T02?.length - 1], FIELD05: OS11?.S11F21 });
    } else {
      // console.log(T02?.[0], "rowCancelHandler-");
      setT02Obj({ ...T02?.[0], FIELD05: OS11?.S11F21 });
      if (setSelectedProductObj && typeof setSelectedProductObj == "function")
        setSelectedProductObj(null);
      setRecIss("");
      setBatchIssueObj(null);
      setLocationObj(null);
      setGroupCatogaryProduct(null);
    }
    setProductDisable(false);
    setCreateFlag(false);
  }

  const HiddenButtonField = (uID, v, c, cellid, index, jwOrder, jwIssue, Expense) => {
    // console.log(c?.S53F02, "autoFocus-");
    switch (c?.S53F02) {
      case "E00000000004":
        NextIndexFun(index);
        // setDrawerObj({
        //   ...dra,
        //   title: <Text fw={700}>{c.S53F03}</Text>,
        //   body: (
        //     <>
        //       <Text>{c.S53F03}</Text>
        //       <div
        //         style={{
        //           display: "flex",
        //           alignItems: "center",
        //           justifyContent: "center",
        //           padding: "5px 0px",
        //         }}
        //       >
        //         <Button
        //           id={`T02Ent_${index}`}
        //           onClick={() => {
        //             // setDrawerObj({ ...DrawerObj, open: false })
        //             Promise.resolve(
        //               setDrawerObj({ ...DrawerObj, open: false })
        //             ).then(() => NextIndexFun(index));
        //           }}
        //         >
        //           Ok
        //         </Button>
        //       </div>
        //     </>
        //   ),
        //   open: true,
        //   size: "xs",
        //   position: "right",
        // });
        break;
      case "E00000000005": //Batch Issue
        // console.log(t02Obj?.FIELD05, "No Batch Issue", selectedProductObj);
        if (
          (selectedProductObj?.FIELD01 != "" || t02Obj?.FIELD03 != "") &&
          selectedProductObj?.FIELD51 != "N" &&
          t02Obj?.FIELD05 == "D"
        ) {
          // console.log("No Batch Issue3");

          Store.dispatch(
            ModalFunction({
              onclose: () => {},
              MTitle: <Text fw={700}>{c.S53F03} {}</Text>,  
              MAction: true,
              MSize: "md",
              fullSize:false,
              // position: "right",
              MBody: (i) => <PLDataGrid
              autoFocus={true}
              id={`T02Ent_${index}`}
              cWhere={`T11.FIELD08 LIKE '%${FIELD01Value ?? ""}%'`}
              size={"0.4"}
              // width={"500px"}
              value={t02Obj?.[c?.S53F04]}
              p1={seperate(c.S53F15)[1]}
              setEdit={(e) => {
                // setT02Obj({ ...T02Obj, [c?.S53F04]: e?.FIELD01 })
                Promise.resolve(setBatchIssueObj(e)).then(() => {
                    Store.dispatch(ModalDelete(i))
                  }).then(() => NextIndexFun(index));
                
              }}
            />,
              // MBody: (i) => <OpeningStockForm/>,
              onClickCloseButton: () => {NextIndexFun(index)},
              MClose: true,
              tab:false,
              Overlay: {
                backgroundOpacity: 0.8,
                blur: 5,
              },
            })
          );
        } else {
          // console.log("No Batch Issue");
          NextIndexFun(index);
        }
        break;
      case "E00000000006": //User Field
        // console.log(T02UF, "T02UF-");
        if (T02UF?.length > 0) {


          Store.dispatch(
            ModalFunction({
              onclose: () => {},
              MTitle: <Text fw={700}>User Field {}</Text>,  
              MAction: true,
              MSize: "xs",
              fullSize:false,
              // position: "right",
              MBody: (i) => <SITableUserField
              T02UF={T02UF}
              T02ObjSend={t02Obj}
              PageObj={obj}
              setT02Objsend={(e) => {
                // setT02Obj(e);
                Promise.resolve(setT02Obj(e)).then(() => {
                  // let passInd = t02Obj?.FIELD05 == "C" ? index : index +1
                  if (selectedProductObj?.FIELD59 == "N") {
                    NextIndexFun(index + 1);
                  } else {
                    NextIndexFun(index);
                  }
                });
              }}
              index={i}
            />,
              // MBody: (i) => <OpeningStockForm/>,
              onClickCloseButton: () => {NextIndexFun(index)},
              MClose: true,
              tab:false,
              Overlay: {
                backgroundOpacity: 0.8,
                blur: 5,
              },
            })
          );

          
        } else {
          NextIndexFun(index);
        }
        break;
      case "E00000000013":
        // setTimeout(() => {
        if (
          dtUFT11 &&
          dtUFT11?.length > 0 &&
          selectedProductObj?.FIELD51 == "N"
        ) {
          // console.log(
          //   selectedProductObj?.FIELD51,
          //   "selectedProductObj?.FIELD51"
          // );

          
          Store.dispatch(
            ModalFunction({
              onclose: () => {},
              MTitle: <Text fw={700}>{c.S53F03} {}</Text>,  
              MAction: true,
              MSize: "sm",
              fullSize:false,
              // position: "right",
              MBody: (i) => <SITableBatchUserField
              PageObj={obj}
              DTUFT11={dtUFT11}
              T02ObjSend={t02Obj}
              setT02Objsend={(e) => {
                Promise.resolve(setT02Obj(e)).then(() =>
                  setTimeout(() => {
                    NextIndexFun(index);
                  }, 500)
                );
              }}
              index={i}
            />,
              // MBody: (i) => <OpeningStockForm/>,
              onClickCloseButton: () => {NextIndexFun(index)},
              MClose: true,
              tab:false,
              Overlay: {
                backgroundOpacity: 0.8,
                blur: 5,
              },
            })
          );
        } else {
          NextIndexFun(index);
        }
        // }, 400);

        break;
      case "E00000000015": //Price List
        // console.log(typeof PLDATA !== "string", "index-");
        if (PLDATA && selectedProductObj?.FIELD53 !== "N") {
          setDrawerObj({
            ...dra,
            title: <Text fw={700}>{c.S53F03}</Text>,
            onClickCloseButton: () => {
              // setDrawerObj(dra);
              Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
                () => {
                  NextIndexFun(T02UF?.length == 0 ? index + 1 : index);
                }
              );
            },
            onClose: () => {
              // setDrawerObj(dra);
              Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
                () => NextIndexFun(T02UF?.length == 0 ? index + 1 : index)
              );
            },

            body: (
              <>
                <Grid>
                  <Grid.Col span={12}>
                    <PLComboBox
                      // onKeyDown={(e) => {
                      //   GlobalClass.formNextInput(e, formRef);
                      // }}
                      initiallyOpened={true}
                      cmbid={`T02Ent_${index}`}
                      data={typeof PLDATA == "string" ? [{}] : PLDATA}
                      value={t02Obj?.FIELD27}
                      dispexpr={"FIELD02"}
                      valexpr={"FIELD01"}
                      setEdit={(e) => {
                        // console.log("Pricelist OBJ=>", e);
                        Promise.resolve(
                          setT02Obj({ ...t02Obj, [c?.S53F04]: e })
                        ).then(() => {
                          if (group.FIELD16 == "C") {
                            setPriceListParemeter({
                              ...priceListParemeter,
                              accountCode: group.FIELD31,
                              priceListCode: e,
                            });
                          } else {
                            setPriceListParemeter({
                              ...priceListParemeter,
                              accountCode: group.FIELD04,
                              priceListCode: e,
                            });
                          }
                        }).then(() => {
                          Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
                            () => NextIndexFun(T02UF?.length == 0 ? index + 1 : index)
                          );
                        })

                        // Promise.resolve(setT02Obj({ ...T02Obj, [c?.S53F04]: e })).then(() => {
                        //   if (T41Obj.FIELD16 == "C") {
                        //     setPriceListParemeter({
                        //       ...priceListParemeter,
                        //       accountCode: T41Obj.FIELD31,
                        //       priceListCode: e,
                        //     });
                        //   } else {
                        //     setPriceListParemeter({
                        //       ...priceListParemeter,
                        //       accountCode: T41Obj.FIELD04,
                        //       priceListCode: e,
                        //     });
                        //   }
                        // })
                        // .then(() => setDrawerObj({ ...DrawerObj, open: false })).then(() => NextIndexFun(index))
                      }}
                    />
                  </Grid.Col>
                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      variant="filled"
                      size="sm"
                      onClick={() => {
                        Promise.resolve(
                          setDrawerObj({ ...DrawerObj, open: false })
                        ).then(() => {
                          if (selectedProductObj?.FIELD59 == "N" && T02UF?.length == 0) {
                            NextIndexFun(index + 3)
                          } else {
                            NextIndexFun(index)
                          }
                        });
                      }}
                      onKeyDown={(e) => {
                        if (e.key == "Enter") {
                          Promise.resolve(
                            setDrawerObj({ ...DrawerObj, open: false })
                          ).then(() => {
                            if (selectedProductObj?.FIELD59 == "N" && T02UF?.length == 0) {
                              NextIndexFun(index + 3)
                            } else {
                              NextIndexFun(index)
                            }
                          });
                        }
                      }}
                    >
                      Ok
                    </Button>
                  </Grid.Col>
                </Grid>
              </>
            ),
            open: true,
            size: "sm",
            position: "right",
          });
        } else {
          NextIndexFun(index);
        }
        break;
      case "E00000000018": // itemwise expense
        // console.log("InvTypeDropDownt02Obj", [{
        //   "InvTypeDropDown": InvTypeDropDown,
        //   "t02Obj": t02Obj
        // }])
        if (t02Obj?.FIELD08 > 0 && InvTypeDropDown != null) {
          // console.log("InvTypeDropDown from here")
          setTimeout(() => {
            setDrawerObj({
              ...dra,
              title: <Text fw={700}>Expense Entry</Text>,
              body: (
                <>
                  <ExpenseEntryForm
                    ExpenseHideShowFlag={Expense == "Y" ? "Y" : ExpenseHideShowFlag}
                    ExpenseObj={ExpenseObj}
                    ProductObj={selectedProductObj}
                    isExpense={true}
                    expenseArr={props?.ExpenseArrayT02 && props?.ExpenseArrayT02?.length > 0 ? props?.ExpenseArrayT02 : ExpenseData?.dtExp_I}
                    expenseArrDef={ExpenseData?.dtExpDef_I}
                    M01PData={ExpenseData?.M01SPDT?.jData}
                    M01Format={ExpenseData?.M01SP}
                    valueSaveObj={t02Obj}
                    TableExpenseArr={TableExpense}
                    onChange={(e) => {
                      // console.log("TableExpenseEntry Object=>", e);
                      // setSelectedTaxAcc(e.FIELD16)

                      if (tableEditingRow == null) {
                        // console.log("Add Product Call");
                        if (
                          t02Obj?.FIELD03 == null ||
                          t02Obj?.FIELD03 == "" ||
                          (selectedProductObj?.FIELD51 == "Y" &&
                            t02Obj?.FIELD41?.trim() == "")
                        ) {
                          GlobalClass.Notify(
                            "warning",
                            "Warning",
                            "Some Field Require to Enter"
                          );
                        } else {
                          Promise.resolve(
                            handleCreateUser(e))
                          // .then(() => table.setCreatingRow(true))
                        }
                      } else {
                        // console.log("Edit Product Call");
                        if (
                          t02Obj?.FIELD03 == null ||
                          t02Obj?.FIELD03 == "" ||
                          (selectedProductObj?.FIELD51 == "Y" &&
                            t02Obj?.FIELD41?.trim() == "")
                        ) {
                          GlobalClass.Notify(
                            "warning",
                            "Warning",
                            "Some Field Require to Enter"
                          );
                        } else {
                          Promise.resolve(handleSaveUser(e)).then(() => setExpenseHideShowFlag(null))
                        }
                      }

                      setGroup((prevFieldObj) => {
                        // Use functional update to avoid overwriting previous state
                        return ExpenseData?.dtExp?.reduce(
                          (updatedFieldObj, d) => {
                            // Only update if the corresponding IA key exists in FieldObj
                            if (d?.FIELD54) {
                              return {
                                ...updatedFieldObj,
                                ["EA" + d.FIELD54]: e?.FIELD16,
                              };
                            } else {
                              return updatedFieldObj;
                            }
                          },
                          { ...prevFieldObj }
                        ); // Spread previous state to avoid modifying it directly
                      });
                    }}
                    DrawerOBJ={DrawerObj}
                    setDrawerOBJ={(e) => setDrawerObj(e)}
                  />
                </>
              ),
              open: true,
              size: "50%",
              position: "right",
            });
          }, 1000)
        } else {
          NextIndexFun(index);
        }
        break;
      case "EJ0000000029": //Purchase Detail
        // console.log("hello from out if")
        if (
          // JWOrderData?.dtOrd?.length > 0 &&
          page === "04050399" ||
          page == "04110399" || (page == "04020399" && oYear?.OSC?._PARTYREQ == "Y") || page == "04260417" || page == "04230417" || page == "04290417" || page == "20422041" || page == "20432041" || page == "20442041"
        ) {
          // console.log("orderPopUp", orderPopUp);
          // console.log("jwIssue?.length",jwIssue);
          // console.log("isLoading",isLoading)
          if (jwIssue?.length > 0) {
            // console.log("inside if")
            // if(isLoading){
            //   setDrawerObj({
            //     ...dra,
            //     title: <Text fw={700}>Pending Issue</Text>,
            //     onClickCloseButton: () => {
            //       // setDrawerObj(dra);
            //       Promise.resolve(
            //         setDrawerObj({ ...DrawerObj, open: false })
            //       ).then(() => NextIndexFun(index));
            //     },
            //     onClose: () => {
            //       // setDrawerObj(dra);
            //       Promise.resolve(
            //         setDrawerObj({ ...DrawerObj, open: false })
            //       ).then(() => NextIndexFun(index));
            //     },
            //     body: (
            //       GlobalClass.RenderLoader("dots")
            //     ),
            //     open: true,
            //     size: "85%",
            //     position: "right",
            //   });
            // }else{
            Store.dispatch(GetAccountList({
              "id": "P_M21",
              "name": "",
              "type": "A",
              "p0": "P_M21",
              "p1": "P_M21",
              "p2": "",
              "p3": "",
              // "cWhere": ""
            }))
            setDrawerObj({
              ...dra,
              title: <Text fw={700}>Pending Issues</Text>,
              onClickCloseButton: () => {
                // setDrawerObj(dra);
                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
                  () => NextIndexFun(index)
                );
              },
              onClose: () => {
                // setDrawerObj(dra);
                Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
                  () => NextIndexFun(index)
                );
              },
              body: (
                <PendingIssue
                  // data={data?.payload}
                  setData={(e) => {
                    // console.log("setData e", e)
                    Promise.resolve(setPendingIssue(e)).then(() =>
                      setTimeout(() => {
                        NextIndexFun(index)
                      }, 500)
                    )
                  }
                  }
                  DrawerOBJ={DrawerObj}
                  setDrawerOBJ={(e) => {
                    setDrawerObj(e);
                    // Promise.resolve(setDrawerObj(e));
                  }}
                  setEmpty={(e) => setEmpty(e)}
                />
              ),
              open: true,
              size: "85%",
              position: "right",
            });

          } else {
            NextIndexFun(index);
          }
        } else {
          NextIndexFun(index);
        }
        // console.log(jwIssue, "here", page, productDisable);
        // if (selectedProductObj?.FIELD01 != "" || t02Obj?.FIELD03 != "") {
        // setDrawerObj({
        //   ...dra,
        //   title: <Text fw={700}>{c.S53F03}</Text>,
        //   onClickCloseButton: () => {
        //     Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => NextIndexFun(index))
        //   },
        //   body: <>
        //     <PLTextBox
        //       id={`T02Ent_${index}`}
        //       value={t02Obj[c?.S53F04]}
        //       setEdit={(e) => {
        //         setT02Obj({ ...t02Obj, [c?.S53F04]: e.target.value });
        //       }}
        //     />
        //     <div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "5px 0px" }}>
        //       <Button
        //         onClick={() => {
        //           // .then(() => setDrawerObj({ ...DrawerObj, open: false })).then(() => NextIndexFun(index))
        //           Promise.resolve(NextIndexFun(index))
        //             .then(() => {
        //               setDrawerObj({ ...DrawerObj, open: false })
        //             })
        //         }}
        //         onKeyDown={(e) => {
        //           if (e.key == "Enter" && c.S53F02 == "E00FIELD65") {
        //             Promise.resolve()
        //               .then(() => {
        //                 setDrawerObj({ ...DrawerObj, open: false })
        //               })
        //               .then(() => {
        //                 setTimeout(() => {
        // NextIndexFun(index)
        //                 }, 500)
        //               })
        //           }
        //         }}
        //       >OK</Button>
        //     </div>
        //   </>,
        //   open: true,
        //   size: "sm",
        //   position: "right"
        // })
        // }
        break;
      case "E00FIELD86":
        setDrawerObj({
          ...dra,
          title: <Text fw={700}>{c.S53F03}</Text>,
          onClickCloseButton: () => {
            // setDrawerObj(dra);
            Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
              () => {
                NextIndexFun(T02UF?.length == 0 ? index + 1 : index);
              }
            );
          },
          onClose: () => {
            // setDrawerObj(dra);
            Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
              () => NextIndexFun(T02UF?.length == 0 ? index + 1 : index)
            );
          },
          body: (
            <>
              <Text>{c.S53F03}</Text>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  // justifyContent: "center",
                  // padding: "5px 0px",
                }}
              >
                <PLTextBox
                  id={`T02Ent_${index}`}
                  value={t02Obj?.[c?.S53F04]}
                  setEdit={(e) => {
                    Promise.resolve(
                      setT02Obj({ ...t02Obj, [c?.S53F04]: e?.target?.value })).then(() => {
                        Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
                          () => NextIndexFun(T02UF?.length == 0 ? index + 1 : index)
                        );
                      })
                  }}
                // disabled={true}
                />
                <Button
                  onClick={() => {
                    // setDrawerObj({ ...DrawerObj, open: false })
                    Promise.resolve(
                      setDrawerObj({ ...DrawerObj, open: false })
                    ).then(() => NextIndexFun(index));
                  }}
                >
                  Ok
                </Button>
              </div>
            </>
          ),
          open: true,
          size: "xs",
          position: "right",
        });
        break;
      case "EJW000000001": //To Get List of JW Orders
        // console.log( "orderPopUp", orderData);

        if (
          // JWOrderData?.dtOrd?.length > 0 &&
          page === "04050399" ||
          page == "04110399"
        ) {
          // console.log("orderPopUp", orderPopUp);
          if (jwOrder?.length > 0) {
            Store.dispatch(GetAccountList({
              "id": "P_M21",
              "name": "",
              "type": "A",
              "p0": "P_M21",
              "p1": "P_M21",
              "p2": "",
              "p3": "",
              // "cWhere": ""
            }))
            setDrawerObj({
              ...dra,
              title: <Text fw={700}>Pending Order</Text>,
              onClickCloseButton: () => {
                // setDrawerObj(dra);
                Promise.resolve(
                  setDrawerObj({ ...DrawerObj, open: false })
                ).then(() => NextIndexFun(index));
              },
              onClose: () => {
                // setDrawerObj(dra);
                Promise.resolve(
                  setDrawerObj({ ...DrawerObj, open: false })
                ).then(() => NextIndexFun(index));
              },
              body: (
                <PendingOrder
                  // data={data?.payload}
                  setData={(e) => {
                    // console.log("setData e", e)
                    Promise.resolve(setPendingOrder(e)).then(() =>
                      setTimeout(() => {
                        NextIndexFun(index)
                      }, 500)
                    )
                  }
                  }
                  DrawerOBJ={DrawerObj}
                  setDrawerOBJ={(e) => {
                    setDrawerObj(e);
                    // Promise.resolve(setDrawerObj(e));
                  }}
                  setEmpty={(e) => setEmpty(e)}
                />
              ),
              open: true,
              size: "85%",
              position: "right",
            });
          } else {
            NextIndexFun(index);
          }
        } else {
          NextIndexFun(index);
        }
        break;
      case "E00FIELD65":
        if (jwIssue?.length > 0) {
          Store.dispatch(GetAccountList({
            "id": "P_M21",
            "name": "",
            "type": "A",
            "p0": "P_M21",
            "p1": "P_M21",
            "p2": "",
            "p3": "",
            // "cWhere": ""
          }))
          setDrawerObj({
            ...dra,
            title: <Text fw={700}>Pending Issue</Text>,
            onClickCloseButton: () => {
              // setDrawerObj(dra);
              Promise.resolve(
                setDrawerObj({ ...DrawerObj, open: false })
              ).then(() => NextIndexFun(index));
            },
            onClose: () => {
              // setDrawerObj(dra);
              Promise.resolve(
                setDrawerObj({ ...DrawerObj, open: false })
              ).then(() => NextIndexFun(index));
            },
            body: (
              <PendingIssue
                // data={data?.payload}
                setData={(e) => {
                  // console.log("setData e", e)
                  Promise.resolve(setPendingIssue(e)).then(() =>
                    setTimeout(() => {
                      NextIndexFun(index)
                    }, 500)
                  )
                }
                }
                DrawerOBJ={DrawerObj}
                setDrawerOBJ={(e) => {
                  setDrawerObj(e);
                  // Promise.resolve(setDrawerObj(e));
                }}
                setEmpty={(e) => setEmpty(e)}
              />
            ),
            open: true,
            size: "85%",
            position: "right",
          });
        } else {
          NextIndexFun(index);
        }
    }
  };

  const TableExpenseEntry = () => {
    setDrawerObj({
      ...dra,
      title: <Text fw={700}>Expense Entry</Text>,
      body: (
        <>
          <ExpenseEntryForm

            ProductObj={selectedProductObj}
            isExpense={true}
            expenseArr={ExpenseData?.dtExp_I}
            expenseArrDef={ExpenseData?.dtExpDef_I}
            M01PData={ExpenseData?.M01SPDT?.jData}
            M01Format={ExpenseData?.M01SP}
            valueSaveObj={t02Obj}
            TableExpenseArr={TableExpense}
            onChange={(e) => {
              // console.log("TableExpenseEntry Object=>", e);

              if (tableEditingRow == null) {
                // console.log(
                //   t02Obj?.FIELD41?.trim(),
                //   "Edit Product Call",
                //   selectedProductObj?.FIELD51
                // );

                if (
                  t02Obj?.FIELD03 == null ||
                  t02Obj?.FIELD03 == "" ||
                  (selectedProductObj?.FIELD51 == "Y" &&
                    t02Obj?.FIELD41?.trim() == "")
                ) {
                  GlobalClass.Notify(
                    "warning",
                    "Warning",
                    "Some Field Require to Enter"
                  );
                } else {
                  handleCreateUser(e);
                }
              } else {
                // console.log("Edit Product Call", selectedProductObj?.FIELD51);
                if (
                  t02Obj?.FIELD03 == null ||
                  t02Obj?.FIELD03 == "" ||
                  (selectedProductObj?.FIELD51 == "Y" &&
                    t02Obj?.FIELD41?.trim() == "")
                ) {
                  GlobalClass.Notify(
                    "warning",
                    "Warning",
                    "Some Field Require to Enter"
                  );
                } else {
                  handleSaveUser(e);
                }
              }
              setGroup((prevFieldObj) => {
                // Use functional update to avoid overwriting previous state
                return ExpenseData?.dtExp?.reduce(
                  (updatedFieldObj, d) => {
                    // Only update if the corresponding IA key exists in FieldObj
                    if (d?.FIELD54) {
                      return {
                        ...updatedFieldObj,
                        ["EA" + d.FIELD54]: e?.FIELD16,
                      };
                    } else {
                      return updatedFieldObj;
                    }
                  },
                  { ...prevFieldObj }
                ); // Spread previous state to avoid modifying it directly
              });
            }}
            DrawerOBJ={DrawerObj}
            setDrawerOBJ={(e) => setDrawerObj(e)}
          />
        </>
      ),
      open: true,
      size: "50%",
      position: "right",
    });
  };
  const openDeleteConfirmModal = (row) => {

    // if (window.confirm('Confirm to Delete ?')) {
    //   // let EditT02Data = [...t02Data];
    //   let newArray = t02Data.filter((d, i) => i != row.index);
    //   // setNarrationData(newArray)

    //   // EditT02Data[tableEditingRow?.index] = objChange;
    //   setT02Data(newArray);
    //   // props?.setFlagCopy(true)
    // }

    GlobalClass.Confirm(
      "red",
      "Are you sure to delete selected data?",
      "It will be Deleted Forever and Cannot Be Recovered!!!",
      () => {
        let newArray = t02Data.filter((d, i) => i != row.index);
        setT02Data(newArray);
      },
      () => { },
      "Cancel",
      "Confirm"
    )
  };

  //---------Useeffect Hooks-----//
  useEffect(() => {
    setT02DataENT(dataEnt?.T02ENT);
  }, [dataEnt]);
  useEffect(() => {
    if (page == "04020399") {
      if (t02Obj?.FIELD07 > 0) {
        // console.log(T02UF, "T02UF-");
        setT02Obj({ ...t02Obj, UISSUERA: t02Obj?.FIELD07 });
      }
    }
  }, [t02Obj?.FIELD07, page]);

  // useEffect(() => {
  //   // console.log(t02Obj?.FIELD22, "t02Obj?.FIELD22=");
  //   // if (t02Obj?.FIELD21 > 0 && t02Obj?.FIELD22 > 0) {
  //     // let qty = t02Obj?.FIELD21 * t02Obj?.FIELD22;
  //     // console.log("qty FIELD33", qty)
  //     setT02Obj((prevObj) => ({ ...prevObj, FIELD33: t02Obj?.FIELD21 * t02Obj?.FIELD22 }));
  //   // }
  //   //  else {
  //   //   if (t02Obj?.FIELD33 > 0) {
  //   //     setT02Obj((prevObj) => ({ ...prevObj, FIELD33: t02Obj?.FIELD33 }));
  //   //   } else setT02Obj((prevObj) => ({ ...prevObj, FIELD33: 0 }));
  //   // }
  // }, [t02Obj?.FIELD22, t02Obj?.FIELD21]);

  useEffect(() => {
    setT02Obj({ ...t02Obj, FIELD08: t02Obj?.FIELD33 * t02Obj?.FIELD07 });
  }, [t02Obj?.FIELD33, t02Obj?.FIELD07]);

  useEffect(() => {
    // console.log(t02Obj?.FIELD03, "t02Obj?.FIELD03");
    if (
      t02Obj?.FIELD03 != "" &&
      group?.FIELD04 != "" &&
      group?.FIELD34 != "" &&
      group?.FIELD02 != ""
    )
      setIssueArr({
        ...issueArr,
        // productCode: selectedProductObj?.FIELD01 ?? t02Obj?.FIELD03,
        productCode: t02Obj?.FIELD03,
        partyCode: group?.FIELD04,
        processCode: group?.FIELD34,
        dateCode: group?.FIELD02,
      });
  }, [
    t02Obj?.FIELD03,
    group?.FIELD04,
    group?.FIELD34,
    group?.FIELD02,
    // selectedProductObj,
  ]);
  useEffect(() => {
    if (priceListParemeter?.priceListCode) {
      Promise.resolve().then(() => {
        setT02Obj({
          ...t02Obj,
          PRICELIST: priceListParemeter?.priceListCode,
          FIELD27: priceListParemeter?.priceListCode,
        });
      });
    }
  }, [priceListParemeter?.priceListCode]);
  useEffect(() => {
    if (
      t02Obj?.FIELD03 != "" &&
      group?.FIELD04 != "" &&
      // group?.FIELD34 != "" &&
      group?.FIELD02 != ""
    )
      setOrderArr({
        ...orderArr,
        productCode: t02Obj?.FIELD03,
        partyCode: group?.FIELD04,
        // processCode: group?.FIELD34,
        dateCode: group?.FIELD02,
      });
  }, [t02Obj?.FIELD03, group?.FIELD04, group?.FIELD02]);

  useEffect(() => {
    // console.log(pendingIssue?.length, "pendingIssue", oYear);
    if (pendingIssue?.length > 0) {
      //  setTimeout(()=>{
      // console.log(OS11?.S11F01, "OS11?.S11F01");
      switch (OS11?.S11F01) {
        case "JJ":
        case "JZ":
          let newArr = pendingIssue?.map((v) => {
            return {
              ...t02Obj,
              FIELD33:
                oYear?.OSC?._JYSUBJOB !== "N"
                  ? v?.FIELD61 - v?.FIELD54
                  : v?.FIELD06 - v?.FIELD54,
              FIELD66:
                v?.FIELD66 == 0
                  ? oYear?.OSC?._JYSUBJOB !== "N"
                    ? v?.FIELD61 - v?.FIELD54
                    : v?.FIELD06 - v?.FIELD54
                  : v?.FIELD66,
              FIELD06:
                oYear?.OSC?._JYSUBJOB !== "N"
                  ? v?.FIELD61 - v?.FIELD54
                  : v?.FIELD06 - v?.FIELD54,
              FIELD02: t02Obj?.FIELD02 == "" ? v?.T41F02 : t02Obj?.FIELD02,
              FIELD21: v?.FIELD21 - v?.FIELD55,
              FIELD07: v?.FIELD07,
              FIELD64: v?.FIELD64 == 0 ? v?.FIELD21 - v?.FIELD55 : v?.FIELD64,
              // FIELD83: v?.FIELD01 + v?.FIELD09 != null ? v?.FIELD09 : 4,
              FIELD83: v?.FIELD01 + v?.FIELD09.toString().padStart(4, " "),
              FIELD88: v?.FIELD88,
              FIELD63: v?.FIELD63,
              FIELD65: OS11?.S11F01 == "JZ" ? v?.T41F12 : v?.T41F10,
              FIELD13:
                OS11?.S11F01 == "JJ" && v?.T41PVT == "JO"
                  ? v?.FIELD01 + v?.FIELD09 != null
                    ? v?.FIELD09
                    : 4
                  : t02Obj?.FIELD13,
            };
          });

          setT02Obj({ ...newArr[0] });
          let extraRow = newArr?.slice(1);
          setT02Data([...t02Data, ...extraRow]);
          break;
        case "JI":
        case "IN":
          // console.log("Its Here ", pendingIssue);
          let newArr2 = pendingIssue?.map((v) => {
            return {
              ...t02Obj,
              FIELD06:
                t02Obj?.FIELD06 == 0
                  ? v?.FIELD06 - v?.FIELD54
                  : t02Obj?.FIELD06,
              FIELD21:
                t02Obj?.FIELD06 == 0
                  ? v?.FIELD21 - v?.FIELD55
                  : t02Obj?.FIELD06,
              FIELD33: t02Obj?.FIELD06 == 0 ? v?.FIELD06 : t02Obj?.FIELD06,
              FIELD63: v?.FIELD63,
              FIELD83: v?.FIELD01 + v?.FIELD09 != null ? v?.FIELD09 : 4,
              FIELD82: v?.FIELD02,
              FIELD65: v?.T41F10,
              FIELD61: v?.FIELD06,
              FIELD62: v?.FIELD21,
              FIELD41: v?.FIELD41,
              FIELD72: v?.FIELD12,
            };
          });
          setT02Obj({ ...newArr2[0] });
          let extraRow2 = newArr2?.slice(1);
          setT02Data([...t02Data, ...extraRow2]);
          break;
        case "JO":
        case "JY":
        case "JW":
          // console.log("Its Here ", OS11?.S11F01, pendingIssue);
          let newArr3 = pendingIssue?.map((v) => {
            return {
              ...t02Obj,
              FIELD33:
                cTypes == "JD"
                  ? v?.FIELD33 - v?.FIELD54
                  : v?.FIELD61 - v?.FIELD54,
              FIELD21: cTypes == "JD" ? "" : v?.FIELD62 - v?.FIELD55,
              FIELD06: v?.FIELD06,
              // FIELD83: v?.FIELD01 + v?.FIELD09 != null ? v?.FIELD09 : 4,
              FIELD83: v?.FIELD01 + v?.FIELD09.toString().padStart(4, " "),
              FIELD82: v?.FIELD02,
              FIELD65: v?.T41F10,
              FIELD63: v?.FIELD63,
              FIELD53: "P",
              FIELD41: v?.FIELD41,
              FIELD07: cTypes == "JD" ? t02Obj?.FIELD07 : v?.FIELD07,
              FIELD19: OS11?.S11F01 == "JW" ? "R" : "",
            };
          });
          setT02Obj({ ...newArr3[0] });
          let extraRow3 = newArr3?.slice(1);
          setT02Data([...t02Data, ...extraRow3]);

          break;

        case "JX":
          // console.log(pendingIssue, "Its Here ", OS11?.S11F01);
          let newArr4 = pendingIssue?.map((v) => {
            // console.log(v, "pendingIssue4", t02Obj);
            if (oYear?.OSC?._JYSUBJOB == "Y") {
              // console.log(oYear, "oYear-");
              return {
                ...t02Obj,
                FIELD21: v?.FIELD21 - v?.FIELD55,
                FIELD33: v?.FIELD33 - v?.FIELD54,
                FIELD06: v?.FIELD33 - v?.FIELD54,
                // FIELD83: v?.FIELD01 + v?.FIELD09 != null ? v?.FIELD09 : 4,
                FIELD83: v?.FIELD01 + v?.FIELD09.toString().padStart(4, " "),
                FIELD82: v?.FIELD02,
                FIELD65: v?.T41F10,
                FIELD63: v?.FIELD63,
                FIELD07: v?.FIELD07,
                FIELD53: "P",
                FIELD41: v?.FIELD41,
              };
            }
          });
          // console.log(newArr4, "newArr4--");
          setT02Obj({ ...newArr4[0] });
          let extraRow4 = newArr4?.slice(1);
          setT02Data([...t02Data, ...extraRow4]);
          break;
        default:
          // console.log(oYear, "Its Here ", OS11?.S11F01);
          break;
        // }},1000)
      }
    }
  }, [pendingIssue]);
  useEffect(() => {
    if (page === "20432041") setcType("JC");
    else if (page === "20422041") setcType("JD");
    else if (page === "20442041") setcType("SJ");
    else setcType("");
  }, []);

  useEffect(() => {
    // console.log("t02Obj?.FIELD03-", oYear);
    // const pendingPopUp = async () => {
    if (oYear?.OSC?._JYSUBJOB === "Y") {
      if (
        page === "04050399" || //jwO rec
        page === "20422041" || //sbJw Iss
        page === "20432041" || //sbJw rec
        page === "04230417" || //jwI Pro
        page === "04260417" || //jwI Rec
        page === "04320417" || //Bill
        page === "20442041" ||
        page === "04110399" ||
        page == "04020399"
      ) {
        // console.log(page, "page--", issueArr, tableEditingRow);

        if (
          issueArr?.dateCode !== "" &&
          issueArr?.partyCode !== "" &&
          issueArr?.processCode !== "" &&
          issueArr?.productCode !== ""
          // &&
          // tableEditingRow == null
        ) {
          // console.log(issueArr, "t02Obj?.FIELD03");
          let data = {
            CorpID: gensetting.getcorpid(),
            cUser: gensetting.getcuser(),
            cPass: gensetting.getcpass(),
            CmpNo: gensetting.getcmpno(),
            cAction: "",
            cCode: OS11?.S11F01,
            cSData: JSON.stringify({
              cPCode: issueArr?.productCode,
              cACode: issueArr?.partyCode,
              cPrcCode: issueArr?.processCode,
              cDate: issueArr?.dateCode,
              cType: cTypes,
            }),
          };
          // console.log(issueArr, "issueArr", cTypes);
          Promise.resolve(Store.dispatch(GetJWListData(data)));
          // .then((data) => {
          //   // if (data?.payload?.T02JDT?.length > 0)
          //   // pendingIssuePopUpFn();
          // });
        } else {
          // console.log("pendingIssuePopUpFn");
        }
      }
    }

    // };
  }, [issueArr, OS11?.S11F01, page, cTypes, oYear]);

  useEffect(() => {
    // InvTypeDropDown
    if (page == "04080399" || page == "04290417" || page == "20452041") {
      if (t02Obj?.FIELD03) {
        // console.log(
        //   ExpenseObj, 'RetTaxAcc("TS", false, "FIELD16", 0, "", T02Obj, false, ExpenseObj) =>',
        //   RetTaxAcc("TS", false, "FIELD16", 0, "", t02Obj, false, ExpenseObj)
        // );

        // Promise.resolve().then(()=> {
        //   return RetTaxAcc("TS", false, "FIELD16", 0, "",  {...t02Obj, VorC:group?.FIELD45}, false, ExpenseObj)
        // }).then((res)=> console.log(res, "RetTaxAcc--"))

        new Promise((resolve) => {
          let ret = RetTaxAcc(
            "TS",
            false,
            "FIELD16",
            0,
            "",
            t02Obj,
            false,
            ExpenseObj
          )
          resolve(ret)

        }).then((res) => {
          setT02Obj({
            ...t02Obj,
            FIELD16: res,
          })
        })
      }
    }
  }, [t02Obj?.FIELD03, page]);

  useEffect(() => {
    var cols = [];

    // console.log(t02Obj, "t02Obj--rr");
    oYear.OSC._PRGROUP == "Y" && cols.push(
      columnHelper.accessor("GRPNM", {
        header: "Product Group",
        id: "Product_Group",
        size: 30,
        Edit: ({ cell, row, index }) => {
          let val = cell.getValue();
          // console.log("Cell =>>", cell);
          // return <><Text>Test</Text></>
          // console.log("cell?.id==>", cell?.id);

          // p0 == "A" ? (
          return (
            <PLDataGrid
              disabled={productDisable}
              // ref={grpRef}
              autoFocus={oYear.OSC._PRGROUP == "Y" ? true : false}
              width={"500px"}
              TmData={"P_M32G"}
              // data={accList["T_M32C"]}
              value={{ value: t02Obj?.GRPCD, label: t02Obj?.GRPNM }}
              setEdit={(e) => {
                // console.log("Product Category Obj=>", e);
                // setFlag(true);
                setGroupCatogaryProduct({
                  ...GroupCatogaryProduct,
                  Group: e?.FIELD01,
                });
                Promise.resolve(
                  setT02Obj({
                    ...t02Obj,
                    GRPCD: e?.FIELD01,
                    GRPNM: e?.FIELD02,
                  })
                ).then(() => {
                  setTimeout(() => {
                    oYear.OSC._PRCATEGOR == "Y" ? prCt?.current?.handlePopoverToggle() : prt?.current?.handlePopoverToggle();
                  }, 500);
                });
              }}
            />
          );
          // )
          //  : (
          //   t02Obj?.CATNM
          // );
        },
        Cell: ({ cell, row }) => {
          let val = cell.getValue();
          return t02Data[row.id]?.GRPNM;
        },
      })
    );
    oYear.OSC._PRCATEGOR == "Y" &&
      cols.push(
        columnHelper.accessor("CATCD", {
          header: "Product Catogary",
          id: "Product_Catogary",
          size: 30,
          Edit: ({ cell, row, index }) => {
            let val = cell.getValue();
            // console.log("Cell =>>", cell);
            // return <><Text>Test</Text></>
            // console.log("cell?.id==>", cell?.id);

            // p0 == "A" ? (
            return (
              <PLDataGrid
                disabled={productDisable}
                ref={prCt}
                autoFocus={oYear.OSC._PRCATEGOR == "Y" && oYear.OSC._PRGROUP !== "Y" ? true : false}
                width={"500px"}
                TmData={"P_M32C"}
                // data={accList["T_M32C"]}
                value={{ value: t02Obj?.CATCD, label: t02Obj?.CATNM }}
                setEdit={(e) => {
                  // console.log("Product Category Obj=>", e);
                  // setFlag(true);
                  setGroupCatogaryProduct({
                    ...GroupCatogaryProduct,
                    Group: e?.FIELD01,
                  });
                  Promise.resolve(
                    setT02Obj({
                      ...t02Obj,
                      CATCD: e?.FIELD01,
                      CATNM: e?.FIELD02,
                    })
                  ).then(() => {
                    setTimeout(() => {
                      prt?.current?.handlePopoverToggle();
                    }, 500);
                  });
                }}
              />
            );
            // )
            //  : (
            //   t02Obj?.CATNM
            // );
          },
          Cell: ({ cell, row }) => {
            let val = cell.getValue();
            return t02Data[row.id]?.CATNM;
          },
        })
      );
    if (t02DataENT && t02DataENT?.length > 0) {
      t02DataENT?.map((v, i) => {
        // console.log(v, "v?.S53F00");
        if (v?.S53F00 === "C" && v?.S53F02 !== "EP0000000026") {
          cols.push(
            columnHelper.accessor(v?.S53F04, {
              header: v.S53F07 == 0 ? "" : v.S53F03,
              // minSize: 0,
              // maxSize: v.ColWidth,
              enableGlobalFilter: v.S53F07 == 0 ? false : true,
              enableSorting: v.S53F07 == 0 ? false : v?.lColOrder,
              // 
              // v.S53F03 == "ItemWise Expense"
              //   ? ""
              //   : v.S53F03 == "User FIeld"
              //   ? ""
              //   : v.S53F03,
              id: v?.S53UID,
              size: v.S53F07,
              // v.S53F03 == "ItemWise Expense"
              //   ? 0
              //   : v.S53F03 == "User FIeld"
              //   ? 0
              //   : 150,
              Edit: ({ cell, column, table, row }) => {
                let val = cell.getValue();
                return renderValue(v?.S53UID, val, v, cell?.id, i, column);
              },
              Footer: () => (
                <div>
                  {v.S53F02 == "E00000000002"
                    ? TotalEntryCalc()
                    : Summary(v, i, "c")}
                </div>
              ),
              Cell: ({ cell, row }) => {
                let val = cell?.getValue();

                if (v?.S53F02 == "E00000000012") {
                  return t02Data[row.id]?.F20NM ?? t02Data[row.id]?.FIELD20;
                } else if (v?.S53F02 == "E00000000002") {
                  // return t02Data[row?.id]?.F03NM;

                  return t02Data[row?.id]?.F03NM ?? t02Data[row.id]?.PrdNM;
                } else if (v?.S53F02 == "E00000000003") {
                  let ret = "";
                  if (val == "C") {
                    ret = "R";
                  } else if (val == "D") {
                    ret = "I";
                  }
                  return ret;
                }
                return v.S53F07 == 0 ? "" : val;
                // return val;
              },
            })
          );
        }
      });
    }
    setColumns(cols);
  }, [t02DataENT, t02Obj, tmList, t02Data, JWListData, JWOrderData]);

  useEffect(() => {
    if (pendingOrder?.length > 0) {
      // console.log("pendingOrder", pendingOrder);
      let Field03Name = accList["P_M21"]?.filter((p, i) => p.FIELD01 == pendingOrder[0]?.FIELD03)
      // console.log("Field03Name", Field03Name)
      setSelectedProductObj(Field03Name[0])

      let newObj = { ...t02Obj }
      let createRow = pendingOrder.map((v) => {
        // console.log("v====>", v)
        if (t02Obj.FIELD06 == 0) {
          // console.log("if condition")
          return {
            ...newObj,
            FIELD81: v.FIELD81,
            // FIELD05:recIss,
            FIELD06: v.FIELD06 - v.FIELD50,
            FIELD21: v.FIELD21 - v.FIELD52,
            FIELD33: v?.FIELD06 - v?.FIELD81,
            FIELD07: v.FIELD07,
            FIELD22: t02Obj?.FIELD22 == 0 ? v.FIELD22 : t02Obj?.FIELD22,
            FIELD88: t02Obj?.FIELD01 + v.FIELD09.toString().padStart(4, " "),
          };
        } else {
          // console.log("else condition");
          return {
            ...newObj,
            FIELD06: v.FIELD06,
          };
        }
      });
      // console.log("createRow", createRow);
      setT02Obj({ ...createRow[0] });
      let extraRow = createRow?.slice(1);
      setT02Data([...t02Data, ...extraRow]);
    }
  }, [pendingOrder]);

  useEffect(() => {
    if (LocationObj !== null) {
      Promise.resolve().then(() => {
        setT02Obj({
          ...t02Obj,
          FIELD20: LocationObj?.FIELD01 ?? "",
          F20NM: LocationObj?.FIELD02,
        });
      });
    }
  }, [LocationObj, t02Obj?.FIELD20]);

  // useEffect(() => {
  //   if (t02Obj?.FIELD33) {
  //     setT02Obj({ ...t02Obj, FIELD21: parseInt(t02Obj?.FIELD33) / parseInt(t02Obj?.FIELD22) })
  //   }
  // }, [t02Obj?.FIELD33])
  // useEffect(() => { //Receipt CheckEnt
  //   if (
  //     tableEditingRow &&
  //     (page == "04050399" ||
  //       page == "04020399" ||
  //       page == "04200417" ||
  //       page == "04260417") &&
  //     p0 == "E"
  //   ) {
  //     console.log(tableEditingRow, "tableEditingRow-");

  //     let editObj = tableEditingRow?.original;
  //     const paddedField09 = String(editObj?.FIELD09).padStart(4, " ");

  //     let reqParameter = {
  //       cAction: "E",
  //       cCode: editObj?.FIELD01 + paddedField09,
  //     };
  //     Promise.resolve(Store.dispatch(CheckItemEnt(reqParameter))).then(
  //       (data) => {
  //         console.log(data, "dataStore-");
  //       }
  //     );
  //   }
  // }, [tableEditingRow]);

  useEffect(() => {
    if (
      p0 === "A"
      // && flag==true
    )
      Promise.resolve().then(() => {
        // console.log(t02Obj.FIELD03, "selectedPO", selectedProductObj);
        // setFlag(false)
        setT02Obj({
          ...t02Obj,
          FIELD03: selectedProductObj?.FIELD01 ?? t02Obj?.FIELD03,
          F03NM: selectedProductObj?.FIELD02 ?? t02Obj?.F03NM,
          FIELD22:
            selectedProductObj?.FIELD28 == 0
              ? 1
              : selectedProductObj?.FIELD28 ?? t02Obj?.FIELD22,
          FIELD07:
            recIss == "C"
              ? selectedProductObj?.FIELD21 ?? t02Obj?.FIELD07
              : recIss == "D"
                ? selectedProductObj?.FIELD21 ?? t02Obj?.FIELD07
                : selectedProductObj?.FIELD22 ?? t02Obj?.FIELD07,
        });
      });
  }, [
    selectedProductObj,
    t02Obj?.FIELD03,
    t02Obj?.F03NM,
    // t02Obj?.FIELD07,
    recIss,
    p0,
  ]);

  useEffect(() => {
    // console.log(t02Obj?.FIELD11, "t02Obj-BatchIssueObj", BatchIssueObj);
    if (t02Obj?.FIELD05 == "D") {
      setT02Obj({
        ...t02Obj,
        FIELD11: BatchIssueObj?.FIELD01 ?? t02Obj?.FIELD11,
        FIELD41: BatchIssueObj?.FIELD02 ?? "",
      });
    } else {
      setT02Obj({
        ...t02Obj,
        FIELD11: BatchIssueObj?.FIELD01 ?? t02Obj?.FIELD11,
      });
    }
  }, [t02Obj?.FIELD05, BatchIssueObj]);
  const [isClicked, setisClicked] = useState(false);
  useEffect(() => {
    if (
      (page === "04050399" || page == "04110399") &&
      // orderArr?.dateCode != "" &&
      dataEnt?.T41?.FIELD02 != "" &&
      orderArr?.partyCode != "" &&
      orderArr?.productCode != ""
    ) {
      // let data = {
      //   CorpID: gensetting.getcorpid(),
      //   cUser: gensetting.getcuser(),
      //   cPass: gensetting.getcpass(),
      //   CmpNo: gensetting.getcmpno(),
      //   cAction: "",
      //   cCode: OS11?.S11F01,
      //   cSData: JSON.stringify({
      //     cPCode: orderArr?.productCode,
      //     cACode: orderArr?.partyCode,
      //     // cPrcCode: group?.FIELD34,
      //     cDate: orderArr?.dateCode,
      //     cType: "",
      //   }),
      // };
      Promise.resolve(
        Store.dispatch(
          GetJOrderData({
            cPCode: orderArr?.productCode,
            cACode: orderArr?.partyCode,
            cDate: dataEnt?.T41?.FIELD02,
            cCode: OS11?.S11F01,
          })
        )
      );
      // .then((data) => {
      //   // console.log("first", data?.payload);
      //   if (data?.payload?.status == "SUCCESS") {
      //     setOrderPopUp(true);
      //   } else {
      //     setOrderPopUp(false);
      //   }
      // });
    }
  }, [orderArr, page, dataEnt?.T41]);

  const handleIconEdit = (row, tab) => {
    let editObj = row?.original;
    Store.dispatch(GetAccountList({
      "id": "P_M21",
      "name": "",
      "type": "A",
      "p0": "P_M21",
      "p1": "P_M21",
      "p2": "",
      "p3": "",
      "cWhere": `M21.FIELD12 LIKE '%${row?.original?.CATCD ?? ""}%'`
      // "cWhere": ""
    }))

    if (editObj?.LVALID == "N") {
      GlobalClass.Notify("warning", "Bill Prepared for this entry, Cannot edit entry");
    }
    else {
      Promise.resolve(table.setCreatingRow(false))
        .then(() => {
          table?.setEditingRow(row);
          setTableEditingRow(row);
          // props?.setEditingRow(row);
          setCreateFlag(false);
          // table.setCreatingRow(false);
          setT02Obj(editObj);
        })
        .then(() => {
          // To disable Product Category & Product Name 
          if ((editObj?.FIELD11?.length > 0 || editObj?.FIELD20?.length > 0) && p0 == "E") {
            Promise.resolve(setProductDisable(true)).then(() => {
              setTimeout(() => {
                NextIndexFun(1);
              }, 1000);
            });
          } else {
            oYear.OSC._PRGROUP == "Y" ? grpRef?.current?.handlePopoverToggle() : oYear.OSC._PRCATEGOR == "Y" ?
              prCt?.current?.handlePopoverToggle() : prt?.current?.handlePopoverToggle();
            setProductDisable(false);
          }
        });
    }
  };
  const table = useMantineReactTable({
    data: t02Data ? t02Data : [],
    columns: columns,
    mantineTableProps: { withColumnBorders: true },
    enableFilters: false,
    onPaginationChange: setPagination,
    // enableRowSelection: true,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableTopToolbar: false,
    enableColumnActions: false,
    enableSorting: true,
    enableStickyFooter: true,
    enableStickyHeader: true,
    enableFilters: false,
    enableSelectAll: false,
    enableEditing: true,
    positionToolbarAlertBanner: false,
    mantineTableBodyProps: {
      tabIndex: 0,
      ref: TableRef,
    },
    mantineTableContainerProps: {
      sx: { maxHeight: "350px", minHeight: "300px" },
    },
    mantineTableBodyRowProps: ({ row, staticRowIndex, table }) => ({
      ref: TableContainer,
      tabIndex: 0,
      onKeyUp: (e) => handleRowKeyDown(e, row, table),
      onClick: (e) => {
        // console.log([row?.id], "[row?.id]")
        setRowSelection((prev) => {
          // [row.id]: !prev[row.id],
          // console.log(prev, "[row?.id]", row.id)
          if (!prev[row.id]) {
            return { [row.id]: true };
          }
          return prev;
        });
      },
      // onKeyDown: (e) => handleRowKeyDown(e, row),
      // onKeyDown: (e) => {
      //   console.log(e, "OnkeyDown");
      //   if (e.key == "ArrowDown") {
      //     console.log(row, "row?.id", row);
      //     setRowSelection((prev) => ({
      //       // [row?.id]: !prev[row?.id],
      //     }));
      //   }
      // },

      selected: rowSelection[row?.id],
      sx: { cursor: "pointer" },
    }),
    onColumnVisibilityChange: (column) => { },
    enableHiding: false,
    createDisplayMode: "row",
    editDisplayMode: "row",
    initialState: {
      density: "0px",
    },
    onCreatingRowSave: () => {
      handleCreateUser();
      // console.log(t02Obj?.FIELD03, "Edit Product Call", selectedProductObj?.FIELD51)

      //Changed if condition because after selecting row in pending challan we are calling handleCreateUser in this case below conditon is not fulfilling and rather than adding rows in table it gives validation error (commented if as per instructed by kishanbhai)


      // if ((selectedProductObj?.FIELD51 == "Y" && t02Obj?.FIELD41?.trim() == "") || t02Obj?.FIELD03 == "") {
      if (t02Obj?.FIELD03 == "") {
        GlobalClass.Notify("warning", "Warning", "Some Fields are Require to Enter");
      } else {
        // console.log(t02Obj?.FIELD03, "Edit Product Call", selectedProductObj?.FIELD51)
        handleCreateUser();
      }

    },
    onEditingRowSave: () => {
      // if ((selectedProductObj?.FIELD51 == "Y" && t02Obj?.FIELD41?.trim() == "") || t02Obj?.FIELD03 == "") {
      if (t02Obj?.FIELD03 == "") {
        GlobalClass.Notify("warning", "Warning", "Some Fields are Require to Enter");
      } else {
        handleSaveUser();
      }
    },
    onCreatingRowCancel: rowCancelHandler,
    onEditingRowCancel: rowEditCancelHandler,
    renderRowActions: ({ row, table }) => (
      <Flex gap="md">
        <Tooltip label="Edit">
          <ActionIcon
            onClick={() => {
              Store.dispatch(GetAccountList({
                "id": "P_M21",
                "name": "",
                "type": "A",
                "p0": "P_M21",
                "p1": "P_M21",
                "p2": "",
                "p3": "",
                "cWhere": `M21.FIELD12 LIKE '%${row?.original?.CATCD ?? ""}%'`
                // "cWhere": ""
              }))
              if (OSV?._SPACCEACH == "N") {
                setExpenseHideShowFlag("N")
              }
              if (t02Obj?.LVALID == "N") {
                GlobalClass.Notify("warning", "Bill prepared for this entry, can not edit this entry")
              } else {
                // console.log("row.original =>", row?.original);
                table.setEditingRow(row);
                setTableEditingRow(row);
                setT02Obj(row.original);
              }
              handleIconEdit(row, table);
            }}
          >
            <IconEdit size={"20px"} />
          </ActionIcon>
        </Tooltip>
        {
          row?.original?.FIELD03 != "" &&
          <Tooltip label="Delete">
            <ActionIcon color="red" onClick={() => {
              // let newRow = {...row?.original,LVALID:"N"}
              if (row?.original?.LVALID == "N") {
                GlobalClass?.Notify("warning", "Warning", "Bill prepared for this entry, cannot edit entry")
              } else {
                openDeleteConfirmModal(row)
              }
              props?.setHeaderExpenseFlag(true)
            }}>
              <IconTrash size={'20px'} />
            </ActionIcon>
          </Tooltip>
        }
      </Flex>
    ),
    positionToolbarAlertBanner: false,
    renderTopToolbarCustomActions: (props) => {
      // console.log(props, "renderTopToolbarCustomActions");
      // return p0 !== "E" ?
      // let isClicked = false;

      //Old Changes
      // return (
      //   <Button
      //     // disabled={}
      //     // ref={addBtnref}
      //     onClick={(e) => {
      //       // setisClicked(true);
      //       console.log(props, "props?.setCreatingModeOn", e);
      //       setRowSelection({});
      //       if (tableEditingRow == null && e?.type == "click") {
      //         Promise.resolve().then(() => table.setCreatingRow(true)).then(() => {
      //           setTimeout(() => {
      //             setCreateFlag(true);
      //             prCt?.current?.handlePopoverToggle();
      //           }, 500);

      //           // setRecIss("R,C".includes(OS11?.S11F22) ? "C" : "D");
      //         });
      //         // .then(() => modeOn(true));
      //       } else {
      //         Promise.resolve(table.setCreatingRow(false)).then(() => {
      //           setCreateFlag(false);
      //           // setisClicked(false);
      //         });
      //       }
      //     }}
      //     onFocus={(e) => {
      //       // if (spoilerControlRef?.current?.innerText == "Hide") {
      //       //   spoilerControlRef.current.click();
      //       // }
      //       console.log(e, "tableEditingRow-", e.relatedTarget != null);
      //       if (
      //         e.type === "focus" &&
      //         e.relatedTarget &&
      //         tableEditingRow == null
      //       )
      //         Promise.resolve(table.setCreatingRow(true)).then(() => {
      //           setProductDisable(false);
      //           setTimeout(() => {
      //             setCreateFlag(true);
      //             prCt?.current?.handlePopoverToggle();
      //           }, 500);
      //         });
      //     }}
      //     size="xs"
      //   // compact
      //   >
      //     Add New
      //   </Button>
      // )


      //Kishanbhai changes

      //   return <Button
      //   disabled={tableEditingRow != null}
      //   onFocus={() => {
      //     if (spoilerControlRef?.current?.innerText == "Hide") {
      //       spoilerControlRef.current.click()
      //     }

      //     setTimeout(() => {
      //       if (obj?.id === "02700249" || obj?.id === "02340213") {
      //         if (PendingChallanData?.dtchln?.length > 0 && PendingChallanStatus) {
      //           Promise.resolve(PendingChallanFun()).then(() => setPendingChallanStatus(false))
      //         } else {
      //           Promise.resolve(table.setCreatingRow(true)).then(() => setT02Obj({ ...T02Obj, FIELD05: OS11?.S11F21 }))
      //         }
      //       } else {
      //         Promise.resolve(table.setCreatingRow(true)).then(() => setT02Obj({ ...T02Obj, FIELD05: OS11?.S11F21 })).then(() => {
      //           // CategoryRef?.current?.focus()
      //           CategoryRef?.current?.handlePopoverToggle()
      //         })
      //       }
      //     }, 500)
      //   }}
      //   onClick={() => {
      //     setTimeout(() => {
      //       if (obj?.id === "02700249" || obj?.id === "02340213") {
      //         if (PendingChallanData?.dtchln?.length > 0 && PendingChallanStatus) {
      //           // Promise.resolve(PendingChallanFun()).then(() => setPendingChallanStatus(false))
      //           return
      //         } else {
      //           table.setCreatingRow(true);
      //         }
      //       } else {
      //         Promise.resolve(table.setCreatingRow(true)).then(() => {
      //           // CategoryRef?.current?.focus()
      //           CategoryRef?.current?.handlePopoverToggle()
      //         })

      //       }
      //       TableRef?.current?.focus()
      //     }, 500)
      //   }}
      //   size="xs"
      // >
      //   Add New
      // </Button>

    },
    renderBottomToolbar: ({ table }) => (
      <Box>
        <Grid style={{ display: "flex", alignItems: "center" }}>
          <Grid.Col span={4}>
            <Text style={{ fontSize: 12, fontWeight: "bold" }}>
              {
                table?.getSelectedRowModel()?.flatRows.length > 0 && table?.getSelectedRowModel()?.flatRows[0]?.original?.FIELD03 != "" ? RowDetailHandle(table?.getSelectedRowModel()?.flatRows[0]?.original) : null
              }
            </Text>
          </Grid.Col>

          <Grid.Col span={4}>
            <Group spacing={"xs"} position="center">
              {/* {
                PendingQuotationData?.dtQuo && PendingQuotationData?.dtQuo?.length > 0 ?
                  <Button
                    size="xs"
                    variant="subtle"
                    compact
                    onClick={() => PendingQuotationFun()}
                  >
                    Quotation
                  </Button>
                  : null
              }
              {
                PendingOrderData?.dtOrd && PendingOrderData?.dtOrd?.length > 0 ?
                  <Button
                    size="xs"
                    variant="subtle"
                    compact
                    onClick={() => PendingOrderFun()}
                  >
                    Order
                  </Button> : null
              } */}

              {
                footerButtonArray.includes("Copy") &&
                <Button size="xs" variant="subtle" compact onClick={() => {
                  if (table?.getSelectedRowModel()?.flatRows.length > 0 && table?.getSelectedRowModel()?.flatRows[0]?.original?.FIELD03 != "") {
                    let newT02Data = t02Data?.filter((d) => d?.FIELD03 != "")
                    setT02Data([...newT02Data, table?.getSelectedRowModel()?.flatRows[0]?.original, (obj.p0 == "E" ? { ...T02?.[T02?.length - 1], FIELD05: OS11?.S11F21 } : { ...T02?.[0], FIELD05: OS11?.S11F21 })])
                    props?.setHeaderExpenseFlag && props?.setHeaderExpenseFlag(true)
                  }
                }}>
                  Copy
                </Button>
              }

              {
                footerButtonArray?.includes("Serial No.") &&
                <Button size="xs" variant="subtle" compact>
                  Serial No.
                </Button>
              }


            </Group>
          </Grid.Col>

          <Grid.Col span={4}>
            <Group
              spacing={"xs"}
              position="right"
              display={"flex"}
            >
              <MRT_TablePagination position="bottom" table={table} />
            </Group>
          </Grid.Col>

        </Grid>
      </Box>
    ),
    state: {
      density: "1.5px",
      rowSelection,
      pagination
    },
    initialState: {
      density: "1.5px",
      pagination
    },
  });
  // useEffect(() => {
  //   console.log(tableEditingRow?.original, "tableEditingRow3", p0,);
  //   if(tableEditingRow != null && p0 == "E"){
  //     let newObj = tableEditingRow?.original;
  //     if(newObj?.FIELD11 || newObj?.FIELD20 ){
  //       setProductDisable(true)
  //     } else{
  //       setProductDisable(false)

  //     }
  //   }
  // }, [tableEditingRow])

  const columnSelection = () => {
    setDrawerObj({
      ...dra,
      title: <Text fw={700}>Column selection</Text>,
      body: (
        <>
          <PLColumnSelection
            cCode={obj?.p0 == "A" ? pagecCode : group?.FIELD01}
            index={props?.index}
            DrawerOBJ={DrawerObj}
            setDrawerOBJ={(e) => {
              setDrawerObj(e);
            }}
          />
        </>
      ),
      open: true,
      size: "80%",
      position: "right",
    });
  };

  const handleRowKeyDown = (e, row, tab) => {
    // const table = tableRef.current;
    // const rows = table.querySelectorAll("tr");
    // const currentRowIndex = Array.from(rows).indexOf(e.target);
    // if (e?.key == "Enter" && !createFlag && tableEditingRow == null) {
    //   console.log(batchTrue, "TABKEY", e.key, tab);
    //   // if(!batchTrue)
    //   {
    //     Promise.resolve(tab?.setCreatingRow(null)).then(() => {
    //       tab?.setEditingRow(row);
    //       setTableEditingRow(row);
    //       tab?.setEditingRow(row);
    //       // editingRow(row);
    //       setCreateFlag(false);
    //       setT02Obj(row?.original);
    //     });
    //   }
    //   //  else{
    //   //   tab?.setCreatingRow(true)
    //   // }
    // }

    const tabled = TableRef.current;
    const rows = tabled.querySelectorAll('tr');
    const currentRowIndex = Array.from(rows).indexOf(e.target);
    // console.log("TABKEY", e.key);
    switch (e?.key) {
      case 'ArrowDown':
        // e.preventDefault();
        if (currentRowIndex < rows.length - 1) {
          e.preventDefault();
          rows[currentRowIndex + 1].focus();
          // rows[currentRowIndex + 1].scrollIntoView({ behavior: "smooth",block: "start"})

        } else if (
          table?.getPageCount() && table?.getPageCount() > 1 &&
          pagination?.pageIndex < table?.getPageCount() - 1
        ) {
          // Navigate to the next page
          table.nextPage()
          // setPagination((prev) => ({
          //   ...prev,
          //   pageIndex: prev.pageIndex + 1,
          // }));
          // rows[0].scrollIntoView({ behavior: "smooth",block: "start"})
          // rows[0].focus();
        }

        break;
      case 'ArrowUp':

        if (currentRowIndex > 0) {
          e.preventDefault();
          rows[currentRowIndex - 1].focus();
          // rows[currentRowIndex - 1].scrollIntoView({ behavior: "smooth",block: "end"})
        } else if (pagination.pageIndex > 0) {
          // Navigate to the previous page
          table.previousPage()
          // setPagination((prev) => ({
          //   ...prev,
          //   pageIndex: prev.pageIndex - 1,
          // }));
          // rows[0].scrollIntoView({ behavior: "smooth",block: "start"})
          // rows[0].focus();
        }

        break;
      case "Enter":
        if (row?.id == 'mrt-row-create') {
          return
        } else if (tableEditingRow) {
          return
        } else {
          table?.setEditingRow(row)

          oYear.OSC._PRGROUP == "Y" ? grpRef?.current?.handlePopoverToggle() : oYear.OSC._PRCATEGOR == "Y" ?
            prCt?.current?.handlePopoverToggle() : prt?.current?.handlePopoverToggle();
          setT02Obj(row?.original);
          setTableEditingRow(row)
          setT02Obj(row?.original)
          if (OSV?._SPACCEACH == "N") {
            setExpenseHideShowFlag("N")
          }
        }
        break;
      default:
        if (e.ctrlKey && e.altKey && e.key == "F4") {
          columnSelection();
        }
        break;
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', (e) => {
      if (e.key == "Escape") {
        setTableEditingRow(null)
        Promise.resolve()
          .then(() => rowCancelHandler())
          .then(() => {
            setTimeout(() => {
              if (tableEditingRow == null) {
                table.setCreatingRow(false)
              } else {
                table.setEditingRow(null)
              }
            }, 500)
          })
      }
    })
  }, [tableEditingRow])

  useEffect(() => {
    // console.log(tableEditingRow, "tableEditingRow", createFlag);
    if (tableEditingRow == null && createFlag) {
      modeOn(true);
    } else if (createFlag == false && tableEditingRow != null) {
      modeOn(true);
    } else {
      modeOn(false);
    }
  }, [tableEditingRow, createFlag]);

  // useEffect(() => {
  //   document.addEventListener("keydown", (e) => {
  //     if (e?.key == "Escape") {
  //       if (tableEditingRow == null) {
  //         Promise.resolve()
  //           .then(() => rowCancelHandler())
  //           .then(() => table.setCreatingRow(null));
  //       } else {
  //         Promise.resolve()
  //           .then(() => rowEditCancelHandler())
  //           .then(() => table.setEditingRow(null));
  //       }
  //     }
  //   });
  // }, [tableEditingRow]);

  function renderValue(uID, v, c, cellid, index, column) {
    // console.log(c?.S53F04, "renderValue");
    // if (c.S53F02 == "E00000000002" && p0 == "E") {
    //   // console.log(t02Obj?.F03NM, "t02Obj?.F03NM")
    //   return t02Obj?.F03NM;
    // }

    if (c?.S53F07 == 0) {
      jwOrder = JWOrderData?.dtOrd;
      jwIssue = JWListData?.T02JDT;
      if (c?.S53F02 == "E00000000018" && OSV?._SPACCEACH == "N") {
        return <div style={{ display: "flex" }}>
          <Button
            id={`T02Ent_${index}`}
            variant="default"
            onFocus={() => {
              document.activeElement.blur()
              // console.log("Test=>", `T02Ent_${index}`)
              HiddenButtonField(uID, v, c, cellid, index, jwOrder, jwIssue)
            }}
            // onClick={() => HiddenButtonField(uID, v, c, cellid, index)}
            style={{ padding: "0px", margin: "0px" }}
          />
          <ActionIcon
            color="blue"
            // id={`T02Ent_${index}`}
            // onFocus={(e) => {
            //   // document.activeElement.blur()
            //   console.log("Test=>", e)
            //   // HiddenButtonField(uID, v, c, cellid, index)
            // }}
            onClick={() => {
              Promise.resolve(HiddenButtonField(uID, v, c, cellid, index, jwOrder, jwIssue, "Y")).then(() => {
                // setExpenseHideShowFlag("Y")
              })
            }
            }
          >
            <IconReceiptTax size={"20px"} />
          </ActionIcon>
        </div>
      } else {
        return (
          <Button
            id={`T02Ent_${index}`}
            variant="default"
            onFocus={(e) => {
              document.activeElement.blur();
              // console.log(JWListData, "Test=>", jwIssue);
              // setTimeout(() => {
              HiddenButtonField(uID, v, c, cellid, index, jwOrder, jwIssue);
              // }, 1000);
            }}
            onClick={() => {
              // console.log(JWListData, "Test=>", jwIssue)

              // setTimeout(() => {
              HiddenButtonField(uID, v, c, cellid, index, jwOrder, jwIssue, "Y");
              // }, 1000);
            }}
            style={{ padding: "0px", margin: "0px" }}

          // style={{ height: 0, marginTop: 5, width: "0px", padding: "0px" }}
          />
        );
      }
    } else {
      switch (c?.S53F14) {
        case "NUMBOX":
          if (c?.S53F02 == "E00000000018") {
            return (
              <Button
                fullWidth
                // variant="default"
                style={{
                  height: 0,
                  marginTop: 5,
                  width: "0px",
                  padding: "0px",
                }}
                onFocus={() => {
                  document.activeElement.blur();
                  if (
                    t02Obj?.FIELD08 > 0 &&
                    InvTypeDropDown != null &&
                    InvTypeDropDown !== undefined
                  ) {
                    TableExpenseEntry();
                  }
                }}
                id={`T02Ent_${index}`}
                onClick={() => {
                  if (
                    t02Obj?.FIELD08 > 0 &&
                    InvTypeDropDown !== null &&
                    InvTypeDropDown !== undefined
                  ) {
                    TableExpenseEntry();
                  }
                }}
              >
                Expense Entry
              </Button>
            );
          }
          else if (c?.S53F02 == "E00000000017") {
            // console.log(index, "AmountOO");
            return (
              <PLNumberBox
                decimalSeparator={true}
                precision={2}
                onKeyDown={(e) => {
                  if (e.key == "Enter" || e.key == "Tab") {
                    // if (tableEditingRow == null) {
                    if (InvTypeDropDown !== null && ExpenseData?.dtExp_I && ExpenseData?.dtExp_I?.length > 0) {
                      if (page == "04080399" || page == "04110399" || page == "04290417" || page == "04320417" || page == "20442041" || page == "20452041")
                        setTimeout(() => {
                          NextIndexFun(index);
                        }, [500])

                      // });
                    }
                    else {
                      if (tableEditingRow == null) {
                        if (t02Obj?.FIELD03 == null || t02Obj?.FIELD03 == "") {
                          GlobalClass.Notify("warning", "Warning", "Some Field Require to Enter")
                        } else {
                          // Promise.resolve().
                          handleCreateUser()
                          // .then(() =>
                          // setTimeout(() => {
                          //   if (obj.p0 == "E") {
                          //     setT02Obj(T02?.[T02?.length - 1]);
                          //   } else {
                          //     setT02Obj(T02?.[0])
                          //   }
                          //   table.setCreatingRow(true)
                          // }, 500)
                          // )
                        }
                      } else {
                        if (t02Obj?.FIELD03 == null || t02Obj?.FIELD03 == "") {
                          GlobalClass.Notify("warning", "Warning", "Some Field Require to Enter")
                        } else {
                          // console.log("AmountOO ELSE");
                          handleSaveUser()
                          // handleIconEdit()
                          // Promise.resolve().then(() => handleIconEdit())
                          //   .then(() => table.setEditingRow(null))
                          //   .then(() => {
                          //     setTimeout(() => {
                          //       if (obj.p0 == "E") {
                          //         setT02Obj(T02?.[T02?.length - 1]);
                          //       } else {
                          //         setT02Obj(T02?.[0])
                          //       }
                          //       table.setCreatingRow(true)
                          //     }, 500)
                          //   })
                        }
                      }
                    }
                  }
                }}
                id={`T02Ent_${index}`}
                value={t02Obj?.[c?.S53F04]}
                setEdit={(e) => {
                  setT02Obj({ ...t02Obj, [c?.S53F04]: e });
                }}
              />
            );
          }// Amount
          else if (c?.S53F02 == "E00000000007") {
            return (
              <PLNumberBox
                setNumber={(e) => {
                  if (["E00000000007", "E00000000008", "E00000000010"].includes(c?.S53F02)) {
                    // console.log("ReverseCalCulation =>",[{
                    //   "oYear?.OSC?._REVSECOND":oYear?.OSC?._REVSECOND,
                    //   "e?.target?.value":e?.target?.value,
                    //   "c?.S53F02":c?.S53F02
                    // }]);
                    setTimeout(() => {
                      new Promise((resolve) => {
                        let rev = ReverseCalCulation(c, e?.target?.value, t02Obj, oYear?.OSC?._REVSECOND)
                        resolve(rev)
                      }).then((res) => {
                        // console.log("ReverseCalCulation res", res);
                        setT02Obj(res)
                      })
                    }, 500)
                  }

                  if (c.S53F02 == "E00000000010" && ["D", "I"].includes(t02Obj?.FIELD05)) {
                    // console.log("E00000000010 T02 QTY-1 =>", [{
                    //   "QTY-1": e,
                    //   "T02OBJ": t02Obj
                    // }]);
                    new Promise((resolve) => {
                      let qt = Qty_CheckStk({ ...ExpenseObj, BatchIssue: BatchIssueObj, ProductAllData: accList["P_M21"] })
                      resolve(qt)
                    }).then((res) => {
                      // console.log("E00000000010 T02 QTY-1 Response =>", [{
                      //   "res": res
                      // }]);
                      if (res != "OK" && t02Obj?.FIELD33 != 0) {
                        Promise.resolve(NagativeQtyPopUpFun(res)).then(() => NextIndexFun(index - 1))
                        // NagativeQtyPopUpFun(res)
                      }
                    })
                  }
                }}
                id={`T02Ent_${index}`}
                precision={2}
                disabled={selectedProductObj?.FIELD59 == "N"}
                decimalSeparator={true}
                value={t02Obj?.[c?.S53F04]}
                setEdit={(e) => {
                  // console.log(e, "c?.S53F04");
                  if (e != 0) setT02Obj({ ...t02Obj, [c?.S53F04]: e });
                  else setT02Obj({ ...t02Obj, [c?.S53F04]: 0 });
                }}
                onKeyDown={(e) => {
                  if (e.key == "Tab") {
                    // console.log("onKeyDown=>", index);
                    // let passInd = c?.S53F03 == "Rate" ? index : index
                    let ind1 = index - 1;
                    // let ind2 = index + 1;
                    // let ind = index;

                    NextIndexFun(ind1);
                  }
                }}
              />
            );
          }// E00000000007 = Qty 2
          else if (c?.S53F02 == "E00000000008") {
            return (
              <PLNumberBox
                id={`T02Ent_${index}`}
                setNumber={(e) => {
                  // console.log("c?.S53F02",c?.S53F02)
                  if (["E00000000007", "E00000000008", "E00000000010"].includes(c?.S53F02)) {
                    // console.log("ReverseCalCulation =>",[{
                    //   "oYear?.OSC?._REVSECOND":oYear?.OSC?._REVSECOND,
                    //   "e?.target?.value":e?.target?.value
                    // }]);
                    setTimeout(() => {
                      new Promise((resolve) => {
                        let rev = ReverseCalCulation(c, e?.target?.value, t02Obj, oYear?.OSC?._REVSECOND)
                        resolve(rev)
                      }).then((res) => {
                        // console.log("ReverseCalCulation res", res);
                        setT02Obj(res)
                      })
                    }, 500)
                  }

                  if (c.S53F02 == "E00000000010" && ["D", "I"].includes(t02Obj?.FIELD05)) {
                    // console.log("E00000000010 T02 QTY-1 =>", [{
                    //   "QTY-1": e,
                    //   "T02OBJ": t02Obj
                    // }]);
                    new Promise((resolve) => {
                      let qt = Qty_CheckStk({ ...ExpenseObj, BatchIssue: BatchIssueObj, ProductAllData: accList["P_M21"] })
                      resolve(qt)
                    }).then((res) => {
                      // console.log("E00000000010 T02 QTY-1 Response =>", [{
                      //   "res": res
                      // }]);
                      if (res != "OK" && t02Obj?.FIELD33 != 0) {
                        Promise.resolve(NagativeQtyPopUpFun(res)).then(() => NextIndexFun(index - 1))
                        // NagativeQtyPopUpFun(res)
                      }
                    })
                  }
                }}
                precision={2}
                disabled={selectedProductObj?.FIELD59 == "N"}
                decimalSeparator={true}
                value={t02Obj?.[c?.S53F04]}
                setEdit={(e) => {
                  // console.log(e, "c?.S53F04");
                  if (e != 0) setT02Obj({ ...t02Obj, [c?.S53F04]: e });
                  else setT02Obj({ ...t02Obj, [c?.S53F04]: 0 });
                }}
                onKeyDown={(e) => {
                  if (e.key == "Tab") {
                    // console.log("onKeyDown=>", index);
                    // let passInd = c?.S53F03 == "Rate" ? index : index
                    let ind1 = index - 1;
                    // let ind2 = index + 1;
                    // let ind = index;

                    NextIndexFun(ind1);
                  }
                }}
              />
            );
          } // E00000000008 = Conversion
          else if (c?.S53F02 == "E00000000010") {
            return (
              <PLNumberBox
                setNumber={(e) => {
                  // console.log("c?.S53F02",c?.S53F02)
                  if (["E00000000007", "E00000000008", "E00000000010"].includes(c?.S53F02)) {
                    // console.log("ReverseCalCulation =>",[{
                    //   "oYear?.OSC?._REVSECOND":oYear?.OSC?._REVSECOND,
                    //   "e?.target?.value":e?.target?.value
                    // }]);
                    setTimeout(() => {
                      new Promise((resolve) => {
                        let rev = ReverseCalCulation(c, e?.target?.value, t02Obj, oYear?.OSC?._REVSECOND)
                        resolve(rev)
                      }).then((res) => {
                        // console.log("ReverseCalCulation res", res);
                        setT02Obj(res)
                      })
                    }, 500)
                  }

                  if (c.S53F02 == "E00000000010" && ["D", "I"].includes(t02Obj?.FIELD05)) {
                    // console.log("E00000000010 T02 QTY-1 =>", [{
                    //   "QTY-1": e,
                    //   "T02OBJ": t02Obj
                    // }]);
                    new Promise((resolve) => {
                      let qt = Qty_CheckStk({ ...ExpenseObj, BatchIssue: BatchIssueObj, ProductAllData: accList["P_M21"] })
                      resolve(qt)
                    }).then((res) => {
                      // console.log("E00000000010 T02 QTY-1 Response =>", [{
                      //   "res": res
                      // }]);
                      if (res != "OK" && t02Obj?.FIELD33 != 0) {
                        Promise.resolve(NagativeQtyPopUpFun(res)).then(() => NextIndexFun(index - 1))
                        // NagativeQtyPopUpFun(res)
                      }
                    })
                  }
                }}
                id={`T02Ent_${index}`}
                precision={3}
                decimalSeparator={true}
                value={t02Obj?.[c?.S53F04]}
                setEdit={(e) => {
                  if (e != 0) setT02Obj({ ...t02Obj, [c?.S53F04]: e });
                  else setT02Obj({ ...t02Obj, [c?.S53F04]: 0 });


                }}
                onKeyDown={(e) => {

                  if (e.key == "Tab") {
                    if (obj.page == "04230417") { // Used Qty (JWI -> Production)
                      let ind1 = index - 1;
                      NextIndexFun(ind1);
                    }
                    else if (selectedProductObj?.FIELD51 == "N" || t02Obj?.FIELD05 == "D") { // Batch Receipt Disable (JWI -> Receipt)
                      locationRef?.current?.handlePopoverToggle()
                    }
                    else {
                      let ind1 = index - 1;
                      NextIndexFun(ind1);
                    }
                  }
                }}
              />
            );
          } // QTY
          else if (c?.S53F02 == "EJ0000000030") {
            return (
              <PLNumberBox
                id={`T02Ent_${index}`}
                precision={3}
                decimalSeparator={true}
                value={t02Obj?.[c?.S53F04]}
                setEdit={(e) => {
                  // console.log(e, "c?.S53F04");
                  if (e != 0) setT02Obj({ ...t02Obj, [c?.S53F04]: e });
                  else setT02Obj({ ...t02Obj, [c?.S53F04]: 0 });
                }}
                onKeyDown={(e) => {
                  if (e.key == "Tab") {
                    if (obj.page = "04230417") { // JWI -> Production
                      if (selectedProductObj?.FIELD51 == "N" || t02Obj?.FIELD05 == "D") { // Batch Receipt Disable (JWI -> Receipt)
                        locationRef?.current?.handlePopoverToggle()
                      }
                      else {
                        let ind1 = index - 1;
                        NextIndexFun(ind1);
                      }
                    }
                    // console.log("onKeyDown=>", index);
                    // let passInd = c?.S53F03 == "Rate" ? index : index
                    let ind1 = index - 1;
                    // let ind2 = index + 1;
                    // let ind = index;

                    NextIndexFun(ind1);
                  }
                }}
              />
            );
          } // Used QTY
          else {
            return (
              <PLNumberBox
                id={`T02Ent_${index}`}
                precision={3}
                decimalSeparator={true}
                value={t02Obj?.[c?.S53F04]}
                setEdit={(e) => {
                  // console.log(e, "c?.S53F04");
                  if (e != 0) setT02Obj({ ...t02Obj, [c?.S53F04]: e });
                  else setT02Obj({ ...t02Obj, [c?.S53F04]: 0 });
                }}
                onKeyDown={(e) => {
                  if (e.key == "Tab") {
                    // console.log("onKeyDown=>", index);
                    // let passInd = c?.S53F03 == "Rate" ? index : index
                    let ind1 = index - 1;
                    // let ind2 = index + 1;
                    // let ind = index;
                    // if (c?.S53F02 == "EJ0000000030" && c?.S53F02 == "E00000000010") {
                    //   NextIndexFun(ind1)
                    // } else if (c?.S53F02 == "E00000000010" &&
                    //   (selectedProductObj?.FIELD51 == "N" || t02Obj?.FIELD05 == "D")) {
                    //   locationRef?.current?.handlePopoverToggle()
                    // } else {
                    NextIndexFun(ind1);
                    // }
                  }
                }}
              />
            );
          }
          break;
        case "TEXTBOX":
          return (
            <PLTextBox
              id={`T02Ent_${index}`}
              disabled={
                c?.S53F02 == "E00000000011" && // Batch Receipt
                  (selectedProductObj?.FIELD51 == "N" || t02Obj?.FIELD05 == "D")
                  ? true
                  :
                  c?.S53F02 == "E00000000013" &&
                    selectedProductObj?.FIELD51 == "N"
                    ? true
                    : false
              }
              value={t02Obj?.[c?.S53F04]}
              onKeyDown={(e) => {
                if (e.key == "Tab") {

                  if (c?.S53F02 == "E00000000011") {
                    locationRef?.current?.handlePopoverToggle()
                  }
                  else {
                    let ind1 = index;
                    let ind2 = index + 1;
                    let ind = index - 1;
                    // c?.S53F02 == "E00000000011"
                    NextIndexFun(
                      c?.S53F02 == "E00000000011" ? ind : ind1
                      // page == "04020399"
                      //   ? page == "04260417"
                      //     ? index + 2
                      //     : ind2
                      //   : ind1
                    );
                  }
                }
              }}
              setEdit={(e) => {
                setT02Obj({ ...t02Obj, [c?.S53F04]: e?.target?.value });

              }}
            // disabled={true}
            />
          );
          break;
        case "PLPOPUP":
          if (c.S53F02 == "E00000000002") {
            //Product name
            // console.log(c.S53F02, "E00000000002");
            // console.log("value,label",t02Obj?.[c?.S53F04],"    ",t02Obj?.F03NM,)
            return (
              <>
                <PLDataGrid
                  autoFocus={oYear.OSC._PRCATEGOR !== "Y" && oYear?.OSC?._PRGROUP !== "Y" ? true : false}
                  disabled={productDisable}
                  ref={prt}
                  id={`T02Ent_${index}`}
                  width={"500px"}
                  cWhere={`M21.FIELD11 LIKE '%${t02Obj?.GRPCD ?? ""
                    }%' AND M21.FIELD12 LIKE '%${t02Obj?.CATCD ?? ""}%'`}
                  value={{
                    value: t02Obj?.[c?.S53F04],
                    label: t02Obj?.F03NM,
                  }}
                  // value={t02Obj?.[c?.S53F04]}
                  p1={seperate(c.S53F15)[1]}
                  setEdit={(e) => {
                    // console.log("product name", e);
                    Promise.resolve(

                      setSelectedProductObj(e)
                    )
                      .then(() => {
                        // if (
                        //   setSelectedProductObj &&
                        //   typeof setSelectedProductObj == "function"
                        // ) {
                        setT02Obj({
                          ...t02Obj,
                          [c?.S53F04]: e?.FIELD01,
                          F03NM: e?.FIELD02,
                          FIELD22: e?.FIELD28 === 0 ? 1 : e?.FIELD28,
                        })
                        setFlag(true);
                        // }
                      })
                      .then(() =>
                        setPriceListParemeter({
                          ...priceListParemeter,
                          productCode: e.FIELD01,
                        })
                      )
                      .then(() => setFIELD01value(e.FIELD01))
                      .then(() =>
                        setTimeout(() => {
                          NextIndexFun(index);
                        }, 1000)
                      );
                  }}
                />
              </>
            );
          }
          else if (c.S53F02 == "E00000000012") {
            return (
              <PLDataGrid
                ref={locationRef}
                id={`T02Ent_${index}`}
                width={"500px"}
                // ref={c.S53F02 == "E00000000012" ? locationRef : ""}
                // value={t02Obj?.[c?.S53F04]}
                value={
                  // c.S53F02 == "E00000000012" //Location
                  //   ?
                  {
                    value: t02Obj?.[c?.S53F04],
                    label: t02Obj?.F20NM ?? "",
                  }
                  // : t02Obj?.[c?.S53F04]
                }
                p1={seperate(c?.S53F15)[1]}
                setEdit={(e) => {
                  // if (c.S53F02 == "E00000000012") {
                  // console.log("Location==>", e);
                  Promise.resolve(setLocationObj(e)).then(() => {
                    // let passInd = page == "03630342" ? index : index + 1;
                    NextIndexFun(index);
                  });
                  // } else {
                  //   Promise.resolve(
                  //     setT02Obj({ ...t02Obj, [c?.S53F04]: e?.FIELD01 })
                  //   ).then(() => NextIndexFun(index + 1));
                  // }
                  // setProdCode(e?.FIELD01)
                }}
              />
            );
          } // Location
          else {
            // console.log(index, "index--");
            return (
              <PLDataGrid
                id={`T02Ent_${index}`}
                width={"500px"}
                // value={t02Obj?.[c?.S53F04]}
                value={
                  // c.S53F02 == "E00000000012" //Location
                  //   ? {
                  //     value: t02Obj?.[c?.S53F04],
                  //     label: t02Obj?.F20NM ?? "",
                  //   }
                  // :
                  t02Obj?.[c?.S53F04]
                }
                p1={seperate(c?.S53F15)[1]}
                setEdit={(e) => {
                  // if (c.S53F02 == "E00000000012") {
                  //   // console.log("Location==>", e);
                  //   Promise.resolve(setLocationObj(e)).then(() => {
                  //     // let passInd = page == "03630342" ? index : index + 1;
                  //     NextIndexFun(index);
                  //   });
                  // } else {
                  Promise.resolve(
                    setT02Obj({ ...t02Obj, [c?.S53F04]: e?.FIELD01 })
                  ).then(() => NextIndexFun(index + 1));
                  // }
                  // setProdCode(e?.FIELD01)
                }}
              />
            );
          }
          break;
        case "COMBOBOX":
          if (c?.S53F02 === "E00000000003") {
            //Receipt/Issue
            return (
              <PLComboBox
                cmbid={`T02Ent_${index}`}
                value={t02Obj?.[c?.S53F04]}
                copno={seperate(c?.S53F15)[1]}
                dispexpr={"DisplayMember"}
                valexpr={"ValueMember"}
                // onKeyDown={(e) => {
                //   GlobalClass.formNextInput(e, formRef);
                // }}
                setEdit={(e) => {
                  // console.log(e.value, "ValueMember");
                  // setBatchIssueObj(null)
                  Promise.resolve(
                    setT02Obj({
                      ...t02Obj,
                      [c?.S53F04]: e.value,
                      FIELD41: "",
                      FIELD11: "",
                    }),
                    setRecIss(e.value)
                  ).then(() => {
                    NextIndexFun(index);
                  });

                  // FIELD07: t02Obj.FIELD05 == "C" ? M21.FIELD21 : t02Obj.FIELD05 == "R" ? M21.FIELD21 : M21.FIELD22
                }}
                dropdownPosition="Bottom"
              // defaultval={t02Obj?.[c?.S53F04]}
              />
            );
          } else {
            return (
              <PLComboBox
                cmbid={`T02Ent_${index}`}
                value={t02Obj?.[c?.S53F04]}
                copno={seperate(c?.S53F15)[1]}
                dispexpr={"DisplayMember"}
                valexpr={"ValueMember"}
                setEdit={(e) => {
                  Promise.resolve(
                    setT02Obj({ ...t02Obj, [c?.S53F04]: e.value })
                  ).then(() => {
                    // let ind = index;
                    NextIndexFun(index);
                  });
                }}
                // onKeyDown={(e) => {
                //   GlobalClass.formNextInput(e, formRef);
                // }}
                dropdownPosition="Bottom"
                defaultval={t02Obj?.[c?.S53F04]}
              />
            );
          }

          break;
        case "DATEPICKER":
          let { startDate } = DateToString(new Date());
          return (
            <PLDateBox
              setEdit={(e) => {
                let { startDate } = DateToString(e);
                if (startDate?.length == 8) {
                  setT02Obj({ ...t02Obj, [c?.S53F04]: startDate });
                }
              }}
              defaultval={
                typeof t02Obj?.[c?.S53F04] == "string" &&
                  t02Obj?.[c?.S53F04] != "Invalid Date"
                  ? t02Obj?.[c?.S53F04] != ""
                    ? t02Obj?.[c?.S53F04]?.trim()
                    : startDate
                  : startDate
              }
              showClearButton={false}
              showDropDownButton={false}
              useMaskBehavior={true}
              dispformat="DD/MM/YYYY"
            />
          );

          break;
        default:
          return null;
          break;
      }
    }
  }
  return (
    <>
      <Modal
        opened={DrawerObj?.open}
        // onClose={close}
        withCloseButton={true}
        fullScreen={false} //
        // overlayProps={{ opacity: 0.5, blur: 4 }}
        closeButtonProps={{
          onClick: (e) => {
            if (typeof DrawerObj?.onClickCloseButton == "function") {
              DrawerObj?.onClickCloseButton();
            } else {
              setDrawerObj(dra);
            }
          },
        }}
        title={DrawerObj?.title}
        size={DrawerObj?.size}
        // centered={.Centered}
        closeOnClickOutside={true}
        // overlayProps={m.Overlay}
        position={DrawerObj?.position ?? "bottom"}
        onClose={
          typeof DrawerObj?.onclose == "function"
            ? DrawerObj?.onclose
            : () => {
              setDrawerObj(dra);
            }
        }
      // scrollAreaComponent={ScrollArea.Autosize}
      >
        {DrawerObj.body}
      </Modal>

      <Grid gutter={4}>
        <Grid.Col span={12}>
          <MantineReactTable table={table} />
        </Grid.Col>
        {/* <Text style={{ fontSize: 12, fontWeight: "bold" }}>
          {table?.getSelectedRowModel()?.flatRows.length > 0
            ? RowDetailHandle(
              table?.getSelectedRowModel()?.flatRows[0]?.original
            )
            : null}
        </Text> */}
      </Grid>
    </>
  );
}
