import * as ProductMaster from "../FunctionsCall/ProductList/ProductList";


export const PRODLABEL=(itemOBJ)=>{
    const OBJ = itemOBJ;
    console.log('executeFunction PRODLABEL ===>', OBJ);
    switch (OBJ?.cSTID) {
        case "PRODLABEL": //master > other info > product label
            ProductMaster.M21AED(JSON.stringify(OBJ))
            break;
    
        default:
            break;
    }
}