import React, { useState } from "react";
import { Text } from "@mantine/core";
import { modals } from "@mantine/modals";
import CityListForm from "./CityListForm";
import Store from "../../utils/store";
import { DeleteCityData } from "../../utils/slices/CityListSlice";
import { ModalFunction } from "../../utils/slices/ModalSlice";
import GlobalClass from "../../utils/GlobalClass";

export function MSAED(data, Skey) {
  const OBJ = JSON.parse(data);

  if (OBJ.p0 === "A") {
    Store.dispatch(
      ModalFunction({
        onclose: () => {},
        MTitle: <Text fw={700}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "30%",
        position:"right",
        MBody: (i) => (
          <CityListForm OBJ={OBJ} index={i} />
        ),
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        }
      })
    )
    //=========================================
    // modals.open({
    //   title: <Text fw={700}>{OBJ?.text}</Text>,
    //   size: "50%",
    //   children: <CityListForm OBJ={OBJ} />,
    // });
  } else if (OBJ.p0 === "E" && !OBJ.id == "") {
    Store.dispatch(
      ModalFunction({
        onclose: () => {},
        MTitle: <Text fw={700}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "30%",
        position:"right",
        MBody: (i) => (
          <CityListForm OBJ={OBJ} index={i} />
        ),
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        }
      })
    )
    //====================================================
    // modals.open({
    //   title: <Text fw={700}>{OBJ?.text}</Text>,
    //   size: "50%",
    //   children: <CityListForm OBJ={OBJ} />,
    // });
  } else if (OBJ.p0 === "D" && !OBJ.id == "") {
    // console.log("MSAED", OBJ.id);

    // if (window.confirm("Are you sure to delete selected data?")) {
    //   Store.dispatch(DeleteCityData({ cCode: OBJ.id, cAction: OBJ.p0 }));
    // } else {
    //   return null;
    // }

    GlobalClass.Confirm(
      "red",
      "Are you sure to delete selected data?",
      "It will be Deleted Forever and Cannot Be Recovered!!!",
      () => {
        Store.dispatch(DeleteCityData({ cCode: OBJ.id, cAction: OBJ.p0 }));
      },
      () => { },
      "Cancel",
      "Confirm"
  )
  }
}

export default MSAED;
