import { Text } from "@mantine/core";
import { ModalDelete, ModalFunction } from "../utils/slices/ModalSlice";
import Store from "../utils/store";
import TDSChallanEntryForm from "../ReportComponent/TDS/TDSChallanEntryForm";
import JournalForm from "../Transaction/JournalEntry/Journal/JournalForm";
import gensetting from "../utils/gensetting";
import { DataSaveLoading } from "../utils/slices/DataSaveLoadingSlice";
import GlobalClass from "../utils/GlobalClass";
import { GetAccountList } from "../utils/slices/AccountListSlice";
import { TM_PRINTVF } from "../components/PrintVoucherReport";
import PLPrint from "../FunctionsCall/Print/PLPrint";

export const TDSCHALLAN = (OBJ) => {
  console.log("executeFunction TM_SETUP ===> ItemOBJ", OBJ);

  // const OBJ = JSON.parse(ItemOBJ);
  console.log("executeFunction TM_SETUP ===>", OBJ);
  if (OBJ.p0 == "E" && OBJ?.id) {
    Store.dispatch(
      ModalFunction({
        MTitle: <Text fw={700}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "37%",
        position: "right",
        MBody: (i) => <TDSChallanEntryForm obj={OBJ} index={i} />,
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        },
      })
    );
  } else if (OBJ?.p0 == "R" && OBJ?.id) {
    console.log("over here", OBJ);
    // Store.dispatch(
    //   ModalFunction({
    //     onclose: (e) => { },
    //     MTitle: <Text fw={700}>{OBJ?.text} </Text>,
    //     MAction: true,
    //     MSize: "xl",
    //     position: "right",
    //     MBody: (i) => <PLPrint data={OBJ} index={i} />,
    //     MClose: true,
    //     Overlay: {
    //       backgroundOpacity: 0.8,
    //       blur: 5,
    //     },
    //   })
    // );
    TM_PRINTVF(JSON.stringify(OBJ))
    console.log("over here 1");
  } else if (OBJ?.p0 == "N") {
    // OBJ.p0="A"
    Store.dispatch(
      ModalFunction({
        MTitle: <Text fw={700}>{"New Journal"}</Text>,
        tab: false,
        MAction: true,
        MSize: "80%",
        fullScreen: false,
        MBody: (i) => <JournalForm obj={OBJ} index={i} />,
        MClose: true,
        onclose: () => {},
      })
    );
  } else if (OBJ?.p0 == "D" && OBJ?.id) {
    if (window.confirm("Are you sure to delete selected data?")) {
      let arrayOfObjects =
        typeof OBJ?.id == "object"
          ? Object.keys(OBJ?.DataGridRow).map((key) => {
              return OBJ?.DataGridRow[key];
            })
          : [OBJ?.DataGridRow];

      console.log("typeof OBJ?.id", typeof OBJ?.id);

      let certificateAvl = arrayOfObjects?.filter((obj) => obj.FIELD23 != "");
      console.log("certificateAvl", certificateAvl);


      if (certificateAvl.length != arrayOfObjects.length) {
        GlobalClass.Notify(
          "error",
          "",
          "certificate available of some entries, can not delete those entries"
        );
      } else if (certificateAvl.length > 0) {
        var data = {
          CorpID: gensetting.getcorpid(),
          cUser: gensetting.getcuser(),
          cPass: gensetting.getcpass(),
          CmpNo: gensetting.getcmpno(),
          cAction: "D",
          cSData: JSON.stringify({ DT: arrayOfObjects }),
          YrNo: localStorage.getItem("YrNo")?.replace(/[\\"]/g, ""),
        };
        let param = JSON.stringify({
          CorpID: gensetting.getcorpid(),
          cUser: gensetting.getcuser(),
          cPass: gensetting.getcpass(),
          CmpNo: gensetting.getcmpno(),
          cAction: "D",
          YrNo: localStorage.getItem("YrNo")?.replace(/[\\"]/g, ""),
        });

        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        };
        fetch(
          GlobalClass.ApiUrl + GlobalClass.PostChallanEntry + "?pa=" + param,
          requestOptions
        )
          .then((response) => response.json())
          .then((data) => {
            // console.log("api data", data);
            // console.log("api data status", data?.status);
            if (data.status === "SUCCESS") {
              // modals.closeAll();
              // props.ModalFunctionClose();

              // dispatch(ModalDelete(props.index))

              GlobalClass.Notify("success", "Deleted", "Deleted successfully");
              let PaginationObj =
                Store.getState().DataSaveLoadingSlice.PaginationObj;
              Promise.resolve(
                Store.dispatch(DataSaveLoading({ [OBJ?.cSTID]: false }))
              )
                // .then(() => dispatch(ModalDelete(props.index)))

                .then(() =>
                  Store.dispatch(
                    GetAccountList({
                      id: "TDSCHALLAN",
                      name: "",
                      p0: "MT",
                      p1: "TDSCHALLAN",
                      p2: "",
                      p3: "",
                      type: "A",
                      pagination: PaginationObj["TDSCHALLAN"],
                    })
                  )
                );
            } else {
              GlobalClass.Notify("error", data?.status, data?.message);
              Store.dispatch(DataSaveLoading({ [OBJ?.cSTID]: false }));
            }
          })
          .catch((e) => {
            GlobalClass.Notify("error", "Error", `${e.message}`);
            Store.dispatch(DataSaveLoading({ [OBJ?.cSTID]: false }));
          });
      }
    } else {
      return null;
    }
  }
};
