import { Button, Grid, Modal, Text } from '@mantine/core'
import React, { useEffect, useState } from 'react'
import PLTextBox from '../../PlusComponent/PLTextBox'
import PLComboBox from '../../PlusComponent/PLComboBox'
import gensetting from '../../utils/gensetting'
import GlobalClass from '../../utils/GlobalClass'
import { saveAs } from "file-saver";
import { GetAccountList } from '../../utils/slices/AccountListSlice'
import { useDispatch, useSelector } from 'react-redux'
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table'
import { createColumnHelper } from '@tanstack/react-table'
import JSZip from 'jszip'
import SyncFusionPLComboBox from '../../PlusComponent/SyncFusionComboBox'
import { ModalFunction } from '../../utils/slices/ModalSlice'
import Store from '../../utils/store'

const BackUpForm = (props) => {
    const { obj } = props

    const { TMFormatData: tmList } = useSelector((state) => state.AccountList);
    const {
        AccountListData: accList,
        TMFormatData: tmListData,
        isLoading: AccNameisLoading,
    } = useSelector((state) => state.AccountList);
    const [objSave, setObjSave] = useState({})
    const [value, setValue] = useState({
        email: [],
        mobile: [],
        supportEmail: ""
    })
    const [backupVal, setBackupVal] = useState("D")
    const [backupLoading, setIsBackupLoading] = useState(false)

    let dra = {
        title: "",
        size: "md",
        open: false,
        onClickCloseBtn: () => { },
    };
    const [DrawerObj, setDrawerObj] = useState(dra);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 25,
    });
    const [rowSelection, setRowSelection] = useState({});
    const [data, setData] = useState([])
    const [columns, setColumns] = useState([])
    const [modalClosed, setModalClosed] = useState();
    const [cmpVal, setCmpVal] = useState([])
    let column = [];
    let backupData = [
        {
            DisplayMember: "Download File",
            ValueMember: "D"
        },
        {
            DisplayMember: "E-Mail",
            ValueMember: "E"
        },
        {
            DisplayMember: "WhatsApp",
            ValueMember: "W"
        },
        {
            DisplayMember: "Send for Support",
            ValueMember: "S"
        }
    ]

    const dispatch = useDispatch();
    const columnHelper = createColumnHelper();

    useEffect(() => {
        let CmpListarr = localStorage.getItem("authUser")
        if (CmpListarr) {
            CmpListarr = JSON.parse(CmpListarr)
            let obj = {
                ...CmpListarr, cmpList: CmpListarr?.cmpList.map(({ cmpNo, cmpName, cmpGrp }) => ({
                    cmpNo,
                    cmpName: `${cmpNo} - ${cmpName}`,
                    cmpGrp,
                }))
            }
            setObjSave(obj);
        }
    }, [])

    useEffect(() => {
        setData(accList?.P_M02);
    }, [accList?.P_M02]);

    useEffect(() => {
        if (backupVal == "S") {
            setValue({ ...value, supportEmail: "support@aakashinfo.com" })
        }
    }, [backupVal])

    useEffect(() => {
        column?.push(
            columnHelper.accessor("FIELD61", {
                header: "Party Name",
                size: 100,
                enableEditing: false,
            })
        );
        column?.push(
            columnHelper.accessor("FIELD05", {
                header: "City",
                size: 100,
                enableEditing: false,
            })
        );
        if (backupVal == "E") {
            column?.push(
                columnHelper.accessor("FIELD43", {
                    header: "email id",
                    size: 100,
                    enableEditing: false,
                })
            );
        } else if (backupVal == "W") {
            column?.push(
                columnHelper.accessor("FIELD35", {
                    header: "Mobile",
                    size: 100,
                    enableEditing: false,
                })
            );
        }

        setColumns(column);
    }, [data, backupVal]);
    // console.log("objSave", objSave)
    let companyName = objSave?.cmpList?.filter((a) => a?.cmpNo == localStorage.getItem("cmp"))
    // console.log("companyName",companyName)

    const bookDataCalled = () => {
        // setbookDataCalledState(true);
        dispatch(
            GetAccountList({
                id: "P_M02",
                name: "",
                p0: "P_M02",
                p1: "P_M02",
                p2: "",
                p3: "",
                type: "A",
            })
        );
        // Store.dispatch(ModalFunction({
        //     MTitle: <Text fw={700}>Address Book</Text>,
        //     MAction: true,
        //     MSize:"xl",
        //     MBody: (i) => (<MantineReactTable table={table} />),
        //     onclose: () => { },
        //     onClickCloseButton: () => {
        //         setModalClosed(true);
        //     }
        // }))
        setDrawerObj({
            ...DrawerObj, title: <Text fw={700}>Address Book</Text>, open: true,
            size: "70%",
            position: "right",
        });
    };

    useEffect(() => {
        if (modalClosed == true) {
            let arr = {
                email: [],
                mobile: []
            }
            if (Object.keys(rowSelection)?.length > 0) {
                Array.from(Object.keys(rowSelection)).map((item) => {
                    arr?.email?.push(accList?.P_M02[item]?.FIELD43);
                    arr?.mobile?.push(accList?.P_M02[item]?.FIELD35);
                    setValue({ ...value, email: arr?.email, mobile: arr?.mobile })
                })
            } else {
                setValue({ ...value, email: [], mobile: [] });
            }
        }
    }, [modalClosed, rowSelection]);

    const handleFile = async (e, cOParaprops) => {

        if (props?.companyList) {
            for (let i = 0; i < cOParaprops.length; i++) {
                const coPara = cOParaprops[i];

                const data = {
                    CorpID: gensetting.getcorpid(),
                    cUser: gensetting.getcuser(),
                    cPass: gensetting.getcpass(),
                    CmpNo: gensetting.getcmpno(),
                    cOPara: coPara,
                    YrNo: localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
                };

                const param = JSON.stringify(data);
                const requestOptions = {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(data),
                };

                try {
                    const response = await fetch(
                        `${GlobalClass.ApiUrl + GlobalClass.DownloadFile}?pa=${param}`,
                        requestOptions
                    );

                    if (response.ok) {
                        const blob = await response.blob();
                        let path = coPara
                        const filename = path.split('\\').pop();
                        saveAs(blob, filename);
                    } else {
                        setIsBackupLoading(false)
                        GlobalClass.Notify("error", response?.status, response.message);
                    }
                } catch (error) {
                    setIsBackupLoading(false)
                    console.error("Error in handleFile:", error);
                }
            }
            setIsBackupLoading(false);
        } else {
            setIsBackupLoading(true)
            console.log("handleFile", [{ "backupVal": e, "cOPara": cOParaprops }])
            var data = {
                CorpID: gensetting.getcorpid(),
                cUser: gensetting.getcuser(),
                cPass: gensetting.getcpass(),
                CmpNo: gensetting.getcmpno(),
                cOPara: cOParaprops,
                YrNo: localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
            }
            let param = JSON.stringify({
                CorpID: gensetting.getcorpid(),
                cUser: gensetting.getcuser(),
                cPass: gensetting.getcpass(),
                CmpNo: gensetting.getcmpno(),
                cOPara: cOParaprops
            });
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(data),
            };
            fetch(
                GlobalClass.ApiUrl + GlobalClass.DownloadFile + "?pa=" + param,
                requestOptions
            )
                .then(async (response) => {
                    if (response.ok) {
                        let data = await response.blob()
                        console.log("response.blob()", data)
                        return data;
                    } else {
                        GlobalClass.Notify("error", response?.status, response.message)
                    }
                })
                .then((blob) => {
                    let path = cOParaprops
                    const filename = path.split('\\').pop();
                    console.log("filename", filename)
                    saveAs(blob, filename);
                    setIsBackupLoading(false)
                })
                .catch((error) => {
                    console.log("catch_error", error)
                    setIsBackupLoading(false)
                    GlobalClass.Notify("error", "Error", error.message);
                });
        }
    }

    const handleSubmitBackup = () => {
        if (props?.companyList) {
            console.log("if condition")
            if (cmpVal?.length == 0) {
                GlobalClass.Notify("warning", "Warning", "Plaase select company")
            } else {
                console.log("companyList exists:", props.companyList);
                let cOPara = []

                const submitBackupForCmpNo = async (cmpNo) => {
                    try {
                        const data = {
                            CorpID: gensetting.getcorpid(),
                            cUser: gensetting.getcuser(),
                            cPass: gensetting.getcpass(),
                            CmpNo: cmpNo,
                            YrNo: localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
                        };

                        const param = JSON.stringify({
                            CorpID: gensetting.getcorpid(),
                            cUser: gensetting.getcuser(),
                            cPass: gensetting.getcpass(),
                            CmpNo: cmpNo,
                        });

                        const requestOptions = {
                            method: "POST",
                            headers: { "Content-Type": "application/json" },
                            body: JSON.stringify(data),
                        };

                        const response = await fetch(
                            `${GlobalClass.ApiUrl + GlobalClass.BackupDB}?pa=${param}`,
                            requestOptions
                        );

                        const responseData = await response.json();
                        console.log("responseData", responseData);

                        if (responseData.status === "SUCCESS") {
                            cOPara.push(responseData?.message)

                        } else {
                            GlobalClass.Notify("error", responseData?.status, responseData?.message);
                        }
                    } catch (error) {
                        GlobalClass.Notify("error", "Error", error.message);
                    }
                };

                const processAllCmpNos = async () => {
                    setIsBackupLoading(true);
                    for (let i = 0; i < cmpVal.length; i++) {
                        await submitBackupForCmpNo(cmpVal[i]);
                    }
                    // setIsBackupLoading(false);
                    console.log("Final cOPara:", cOPara);
                    handleFile(backupVal, cOPara)
                };
                processAllCmpNos();
            }
        } else {
            console.log("else condition")
            setIsBackupLoading(true)
            var data = {
                CorpID: gensetting.getcorpid(),
                cUser: gensetting.getcuser(),
                cPass: gensetting.getcpass(),
                CmpNo: gensetting.getcmpno(),
                YrNo: localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
            }
            let param = JSON.stringify({
                CorpID: gensetting.getcorpid(),
                cUser: gensetting.getcuser(),
                cPass: gensetting.getcpass(),
                CmpNo: gensetting.getcmpno()
            });
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(data),
            };
            fetch(
                GlobalClass.ApiUrl + GlobalClass.BackupDB + "?pa=" + param,
                requestOptions
            )
                .then((response) => response.json())
                .then((data) => {
                    console.log("data", data)
                    if (data.status == "SUCCESS") {
                        setIsBackupLoading(false)
                        // setcOParaVal(data?.message)
                        handleFile(backupVal, data?.message)
                    } else {
                        setIsBackupLoading(false);
                        GlobalClass.Notify("error", data?.status, data?.message)
                    }
                })
                .catch((error) => {
                    setIsBackupLoading(false);
                    GlobalClass.Notify("error", "Error", error.message);
                });
        }
    }

    const table = useMantineReactTable({
        columns,
        data: data ? data : [],
        mantineTableContainerProps: {
            sx: { maxHeight: "350px", minHeight: "300px" },
        },
        enableTopToolbar: false,
        enablePagination: true,
        enableRowSelection: true,
        enableMultiRowSelection: true,
        onRowSelectionChange: setRowSelection,
        onPaginationChange: setPagination, //hoist pagination state to your state when it changes internally
        mantineTableBodyRowProps: ({ row, staticRowIndex }) => ({
            sx: { cursor: "pointer" },
        }),
        state: { pagination: pagination, showLoadingOverlay: AccNameisLoading?.P_M02, rowSelection: rowSelection },
        initialState: {
            density: "0px",
            pagination: pagination
        }
    });

    return (
        <>
            <Modal
                opened={DrawerObj?.open}
                // onClose={close}
                withCloseButton={true}
                fullScreen={false} //
                // overlayProps={{ opacity: 0.5, blur: 4 }}
                closeButtonProps={{
                    onClick: (e) => {
                        setModalClosed(true);
                        if (typeof DrawerObj?.onClickCloseButton == "function") {
                            DrawerObj?.onClickCloseButton();
                        } else {
                            setDrawerObj(dra);
                        }
                    },
                }}
                title={DrawerObj.title}
                size={DrawerObj.size}
                // centered={.Centered}
                closeOnClickOutside={false}
                // overlayProps={m.Overlay}
                position={DrawerObj?.position ?? "bottom"}
                onClose={
                    typeof DrawerObj?.onclose == "function"
                        ? DrawerObj?.onclose
                        : () => {
                            setDrawerObj(dra);
                        }
                }

            // scrollAreaComponent={ScrollArea.Autosize}
            >
                <MantineReactTable table={table} />
            </Modal>
            <Grid gutter={4}>
                <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Grid.Col span={3}>
                        <Text size={12}>Company Name</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                        {props?.companyList == true ?
                            <PLComboBox
                                valexpr="cmpNo"
                                dispexpr="cmpName"
                                multiple={true}
                                data={objSave?.cmpList}
                                value={cmpVal}
                                setEdit={(e) => {
                                    console.log("setEdit", e)
                                    setCmpVal(e)
                                }}
                            /> :
                            <PLTextBox
                                disabled
                                value={companyName?.length > 0 ? companyName[0]?.cmpName : ""}
                                setEdit={(e) => {

                                }}
                            />
                        }
                    </Grid.Col>
                </Grid.Col>

                <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Grid.Col span={3}>
                        <Text size={12}>Backup</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                        <PLComboBox
                            dispexpr="DisplayMember"
                            valexpr="ValueMember"
                            data={backupData}
                            value={backupVal}
                            setEdit={(e) => {
                                // console.log("combo value", e)
                                setBackupVal(e)
                            }}
                        />
                    </Grid.Col>
                </Grid.Col>
                <Grid.Col span={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Grid.Col span={3}></Grid.Col>
                    <Grid.Col span={backupVal == "S" ? 9 : 6}>
                        {backupVal == "D" ? "" :
                            <PLTextBox
                                disabled={backupVal == "S" ? true : false}
                                value={backupVal == "E" ? value.email : backupVal == "W" ? value.mobile : value.supportEmail}
                                setEdit={(e) => {
                                    backupVal == "E" ?
                                        setValue({ ...value, email: e.target.value }) :
                                        setValue({ ...value, mobile: e.target.value })
                                }}
                            />
                        }
                    </Grid.Col>
                    {
                        (backupVal == "E" || backupVal == "W") &&
                        <Grid.Col span={3}>
                            <Button
                                compact
                                onClick={bookDataCalled}
                            >
                                <Text size={12}> {backupVal == "E" ? "eMail Book" : "Phone Book"}</Text>
                            </Button>
                        </Grid.Col>
                    }
                </Grid.Col>
            </Grid>

            <Grid gutter={4}>
                <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Button
                        onClick={() => backupVal == "D" && handleSubmitBackup()}
                        loading={backupLoading}
                    >OK</Button>
                </Grid.Col>
            </Grid>
        </>
    )
}

export default BackUpForm