import { ActionIcon, Button, Divider, Drawer, Flex, Grid, Modal, Paper, Spoiler, Text, Tooltip } from '@mantine/core'
import React, { useEffect, useRef, useState } from 'react'
import PLDateBox from '../../PlusComponent/PLDateBox';
import PLDataGrid from '../../PlusComponent/PLDataGrid';
import { useDispatch, useSelector } from 'react-redux';
import PLTextBox from '../../PlusComponent/PLTextBox';
import PLNumberBox from '../../PlusComponent/PLNumberBox';
import GlobalClass from '../../utils/GlobalClass';
import { BatchLogAddRemoveFun, TransactionApi } from '../../utils/TransactionSlices/Transaction';
import { GetAccountList, GetTMFormat } from '../../utils/slices/AccountListSlice';
import PLComboBox from '../../PlusComponent/PLComboBox';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import { createColumnHelper } from '@tanstack/react-table';
import PRUserFieldEntry from './PRUserFieldEntry';
import PRUserField from './PRUserField';
import { IconEdit } from '@tabler/icons-react';
import ProductionT02Table from './ProductionT02Table';
import { DataSaveLoading } from '../../utils/slices/DataSaveLoadingSlice';
import { ModalDelete } from '../../utils/slices/ModalSlice';
import gensetting from '../../utils/gensetting';
import Store from '../../utils/store';

const ProductionQuickEntryForm = (props) => {
  const { obj } = props;
  console.log('props', props)
  const formRef = useRef();
  const { isLoading, hasError, ErrorMsg } = useSelector(
    (state) => state.Transaction
  );
  const ProductionData = useSelector(
    (state) => state.Transaction?.TransactionData
  );
  const OSV = useSelector((state) => state.Transaction?.TransactionData?.OSV);
  const P_M73DT = useSelector((state) => state.Transaction?.TransactionData?.P_M73DT);
  const OS11 = useSelector((state) => state.Transaction?.TransactionData?.OS11);
  const T41 = useSelector((state) => state.Transaction?.TransactionData?.T41);
  const T02 = useSelector((state) => state.Transaction?.TransactionData?.T02);
  const T02ENT = useSelector((state) => state.Transaction?.TransactionData?.T02ENT);
  const T02UF = useSelector((state) => state.Transaction?.TransactionData?.T02UF);
  const T92 = useSelector((state) => state.Transaction?.TransactionData?.T92);
  const T92DT = useSelector((state) => state.Transaction?.TransactionData?.T92DT);
  const P_M71 = useSelector((state) => state.Transaction?.TransactionData?.P_M71);
  const P_M71DT = useSelector((state) => state.Transaction?.TransactionData?.P_M71DT);
  const UFT41H = useSelector((state) => state.Transaction?.TransactionData?.UFT41H);
  const PLDATA = useSelector((state) => state.Transaction?.TransactionData?.PLDATA);
  const dtUFT11 = useSelector((state) => state.Transaction?.TransactionData?.dtUFT11);
  const lEditVou = useSelector((state) => state.Transaction?.TransactionData?.lEditVou);
  const { oYear } = useSelector((state) => state?.Language?.General);
  const {
    isLoading: AccountisLoading,
    AccountListData: accList,
    TMFormatData: tmList
  } = useSelector((state) => state.AccountList);
  const footerDate = useSelector((state)=>state?.Language?.footerDate);
  const [T02Data, setT02Data] = useState([]);
  // console.log('T02Data', T02Data)
  const [BillNoStatus, setBillNoStatus] = useState(false)
  const [columns, setColumns] = useState([]);
  const [selectedDay, setSelectedDay] = useState("");
  const [T41Obj, setT41Obj] = useState({});
  const [T02Obj, setT02Obj] = useState({
    CATNM: "",
    GRPNM: "",
    F03NM: "",
    F20NM: "",
    FIELD01: "",
    FIELD02: "",
    FIELD03: "",
    FIELD04: "",
    FIELD05: "",
    FIELD06: 0.0,
    FIELD07: 0.0,
    FIELD08: 0.0,
    FIELD09: 0.0,
    FIELD10: 0.0,
    FIELD11: "",
    FIELD12: "",
    FIELD56: "",
    FIELD13: "",
    FIELD14: "",
    FIELD15: "",
    FIELD16: "",
    FIELD17: "",
    FIELD18: "",
    FIELD19: "",
    FIELD20: "",
    FIELD21: 0.0,
    FIELD22: 0.0,
    FIELD23: 0.0,
    FIELD24: "",
    FIELD25: 0.0,
    FIELD26: "",
    FIELD27: "",
    FIELD28: false,
    FIELD29: 0.0,
    FIELD30: "",
    FIELD31: 0.0,
    FIELD32: 0.0,
    FIELD33: 0.0,
    FIELD41: "",
    FIELD50: 0.0,
    FIELD51: "",
    FIELD52: 0.0,
    FIELD71: "",
    FIELD81: 0.0,
    FIELD82: "",
    FIELD83: "",
    FIELD84: "",
    FIELD85: "",
    FIELD86: "",
    FIELD61: 0.0,
    FIELD62: 0.0,
    FIELD63: "",
    FIELD64: 0.0,
    FIELD65: "",
    FIELD66: 0.0,
    FIELD67: 0.0,
    FIELD72: "",
    FIELD68: "",
    FIELD69: "",
    FIELD53: "",
    FIELD54: 0.0,
    FIELD55: 0.0,
    FIELD091: 1.0,
    FIELD58: "",
    FIELD57: "",
    FIELD60: 0.0,
    T41PVT: "",
    FIELD87: 0.0,
    FIELD88: "",
    UGRO: 0.0,
    URATE: 0.0,
    UMUR: 0.0,
    UPE: 0.0,
    UAP: 0.0,
    URE: "",
    UISSUERA: 0.0,
    FLDUNQ: 0,
    FLDAED: "",
    FLDBRC: "",
    UUDIS: "",
    UUDISAAA: "",
    UUGROAAA: "",
    UUGR01: "",
    UUGRO: "",
    IA00000001: "",
    IA00000002: "",
    IA00000003: "",
    IA00000004: "",
    IA00000005: "",
    IA00000006: "",
    IA00000007: "",
    IA00000008: "",
    IA00000009: "",
    IA00000010: "",
    IA00000011: "",
    IA00000012: "",
    IA00000013: "",
    IA00000014: "",
    IA00000015: "",
    IA00000016: "",
    IA00000017: "",
    IA00000018: "",
    IA00000019: "",
    IA00000020: "",
    IA00000021: "",
    IA00000022: "",
    IA00000023: "",
    ID00000001: 0.0,
    ID00000002: 0.0,
    ID00000003: 0.0,
    ID00000004: 0.0,
    ID00000005: 0.0,
    ID00000006: 0.0,
    ID00000007: 0.0,
    ID00000008: 0.0,
    ID00000009: 0.0,
    ID00000010: 0.0,
    ID00000011: 0.0,
    ID00000012: 0.0,
    ID00000013: 0.0,
    ID00000014: 0.0,
    ID00000015: 0.0,
    ID00000016: 0.0,
    ID00000017: 0.0,
    ID00000018: 0.0,
    ID00000019: 0.0,
    ID00000020: 0.0,
    ID00000021: 0.0,
    ID00000022: 0.0,
    ID00000023: 0.0,
    IP00000001: 0.0,
    IP00000002: 0.0,
    IP00000003: 0.0,
    IP00000004: 0.0,
    IP00000005: 0.0,
    IP00000006: 0.0,
    IP00000007: 0.0,
    IP00000008: 0.0,
    IP00000009: 0.0,
    IP00000010: 0.0,
    IP00000011: 0.0,
    IP00000012: 0.0,
    IP00000013: 0.0,
    IP00000014: 0.0,
    IP00000015: 0.0,
    IP00000016: 0.0,
    IP00000017: 0.0,
    IP00000018: 0.0,
    IP00000019: 0.0,
    IP00000020: 0.0,
    IP00000021: 0.0,
    IP00000022: 0.0,
    IP00000023: 0.0,
    IT00000001: "",
    IT00000002: "",
    IT00000003: "",
    IT00000004: "",
    IT00000005: "",
    IT00000006: "",
    IT00000007: "",
    IT00000008: "",
    IT00000009: "",
    IT00000010: "",
    IT00000011: "",
    IT00000012: "",
    IT00000013: "",
    IT00000014: "",
    IT00000015: "",
    IT00000016: "",
    IT00000017: "",
    IT00000018: "",
    IT00000019: "",
    IT00000020: "",
    IT00000021: "",
    IT00000022: "",
    IT00000023: "",
    UDIS: 0.0,
    UUOS: "",
    UT76: "",
    USERFLD: "",
    USERFLDB: "2",
    SRDETAIL: "",
    EXPDUMMY: " ",
    BARCODE: "",
    OLDPRD: "",
    OLDQTY: 0.0,
    OQTY2: 0.0,
    OLDRATE: 0.0,
    LADDNEW: "Y",
    PRICELIST: " ",
    _BATSTOCK: " ",
    _LOCWISE: " ",
    LDUALSTK: " ",
    SRNOSTK: " ",
    MRPSTK: " ",
    RORI: " ",
    ORDDUMMY: " ",
    SRIDUMMY: " ",
    SRRDUMMY: " ",
    SRIDUMMYF: " ",
    SRRDUMMYF: " ",
    SRISSCD: "",
    OLDSRNO: "",
    SCNOCL: "",
    SCNOCL1: "",
    MANUDATE: "",
    EXPDATE: "",
    _EXPTOTALAMT: 0.0,
  });
  const [processVal, setProcessVal] = useState(null)
  const [BillSeriesHideShow, setBillSeriesHideShow] = useState({
    BillNoVal: "",
    TextInput: false,
    DataGridInput: true
  })
  const [userFieldHideShow, setUserFieldHideShow] = useState("")
  const [selectedProductObj, setSelectedProductObj] = useState({});
  const [GroupCatogaryProduct, setGroupCatogaryProduct] = useState({
    "Group": "",
    "Catogary": "",
    "Product": ""
  })
  const [tableEditingRow, setTableEditingRow] = useState(null)
  const [FIELD01Value, setFIELD01value] = useState("");
  let dra = {
    title: "",
    size: "md",
    body: "",
    open: false,
  }
  const [DrawerObj, setDrawerObj] = useState(dra);
  const [LocationObj, setLocationObj] = useState()
  const [BatchIssueObj, setBatchIssueObj] = useState()
  const [recieptIssueVal, setRecieptIssueVal] = useState()
  const [T02FilteredRow, setT02FilteredRow] = useState([])
  const [flag, setFlag] = useState(false)
  const [f02,setF02]=useState(false);
  const pr = useRef();
  const VoucherRef = useRef();
  const QtyRef = useRef()

  const dispatch = useDispatch();

  useEffect(() => {
    console.log('T41Obj', T41Obj)
    console.log("T02Obj",T02Obj)
  }, [T41Obj, T02Obj])

  useEffect(() => {
    if (hasError && !isLoading) {
      GlobalClass.Notify("error", "Error: " + ErrorMsg.code, ErrorMsg.message);
    }
  }, [hasError]);

  useEffect(() => {
    if (T02) {
      setT02Data(T02);
    }
  }, [T02]);

  useEffect(() => {

    const formElement = formRef.current;
    if (formElement) {
      formElement.addEventListener('keypress', (e) => GlobalClass.formNextInput(e, formRef));
    }
    return () => {
      if (formElement) {
        formElement.removeEventListener('keypress', (e) => GlobalClass.formNextInput(e, formRef));
      }
    };
  }, [formRef]);

  useEffect(() => {
    if (T41) {
      setT41Obj(T41)
    }
  }, [T41])

  useEffect(() => {
    if (P_M73DT && T41Obj?.FIELD04 !== "") {
      let FilteredArr = []
      P_M73DT?.filter((v) => v?.FIELD01 == T41Obj?.FIELD04).map((v) => {
        return FilteredArr.push(v)
      })
      // console.log("FilteredArr", FilteredArr)
      setT02FilteredRow(FilteredArr)
    }
  }, [P_M73DT, T41Obj?.FIELD04])

  let updatedData = [];
  useEffect(() => {
    if (T02FilteredRow && T02FilteredRow.length > 0 && T41Obj?.FIELD18 !== 0 && processVal !== null && obj?.p0 != "E") {

      setT02Data([]);
      let tempT02 = { ...T02Obj }
      T02FilteredRow.map((r) => {

        tempT02.FIELD03 = r?.FIELD04;
        tempT02.FIELD05 = r.FIELD07 === "O" ? "D" : "C";
        tempT02.FIELD14 = r.FIELD00;
        tempT02.FIELD19 = r.FIELD08;

        accList["P_M21"]?.map((p) => {

          if (r?.FIELD04 == p?.FIELD01) {

            console.log("match data==>", p);
            tempT02.FIELD07 = tempT02?.FIELD05 == "D" ? p?.FIELD21 : p?.FIELD22
            let RateConv = tempT02.FIELD05 == "D" ? p?.FIELD10 : p?.FIELD18
            let nResultRate = tempT02.FIELD07 / RateConv
            console.log('RateConv', RateConv)
            console.log('nResultRate', nResultRate)
            tempT02.FIELD22 = r.FIELD27 == 0 ? p.FIELD28 : r.FIELD27
            tempT02.FIELD06 = parseInt(((r.FIELD05 / processVal?.FIELD04) * T41Obj?.FIELD18).toFixed(0))
            tempT02.FIELD08 = parseInt((tempT02?.FIELD06 * nResultRate))
            tempT02.FIELD33 = parseInt(((r.FIELD05 / processVal?.FIELD04) * T41Obj?.FIELD18).toFixed(0))
            tempT02.FIELD21 = parseInt(((r.FIELD26 / processVal?.FIELD04) * T41Obj?.FIELD18).toFixed(0))

            updatedData.push({
              ...tempT02,
              F03NM: p?.FIELD02,
            })
          }
        })
      });
      console.log("updatedData==>", updatedData);
      setT02Data([...T02, ...updatedData]);
    }
  }, [T02FilteredRow, T41Obj?.FIELD18, accList["P_M21"], tmList, processVal, obj?.p0]);

  useEffect(() => {
    let date = f02 || props?.obj?.p0=="E" ? T41Obj?.FIELD02 : dateFormater(footerDate);
    if (date) {
      const year = parseInt(date.substr(0, 4));
      const month = parseInt(date.substr(4, 2)) - 1; // Month is zero-based in JavaScript Date
      const day = parseInt(date.substr(6, 2));
      const dateObject = new Date(year, month, day);
      // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
      const dayOfWeek = dateObject.toLocaleString("en-US", { weekday: "long" });
      setSelectedDay(dayOfWeek?.slice(0, 3));
    }
  }, [T41Obj?.FIELD02,footerDate]);

  function dateFormater(date){
    let year = date.toLocaleString("en-IN", { year: "numeric" });
        let month = date.toLocaleString("en-IN", { month: "2-digit" });
        let day = date.toLocaleString("en-IN", { day: "2-digit" });
        let date1 = year + month + day;
        return date1;
   }

  const objFun = (e) => {
    // TransactionApi(obj)
    // if (e?.p0 == "") {
      new Promise((resolve)=>{
        if(obj?.id === "QP"){
          dispatch(TransactionApi({ ...e, p0: "A",page:obj?.id,cSTID:obj?.p1 }))
        }else{
          dispatch(TransactionApi({ ...e, p0: "A",cSTID:obj?.p1  }))
        }
        resolve()
      }).then(() => {
        if (oYear?.OSC?._BATCHSTOC == "N") {
          Promise.resolve(dispatch(GetAccountList({
            "id": "P_M21",
            "name": "",
            "type": "A",
            "p0": "P_M21",
            "p1": "P_M21",
            "p2": "",
            "p3": ""
          }))).then(() => dispatch(GetTMFormat({
            "id": "P_M21",
            "name": "",
            "type": "A",
            "p0": "P_M21",
            "p1": "P_M21",
            "p2": "",
            "p3": ""
          })))
        }
      })
    // }
  }

  // useEffect(() => {
  //   if (BillSeriesHideShow.BillNoVal.length <= 0) {
  //     setBillSeriesHideShow({ ...BillSeriesHideShow, DataGridInput: true, TextInput: false })
  //   }
  // }, [BillSeriesHideShow.BillNoVal])

  useEffect(() => {
    if (obj) {
      // console.log("obj==>", obj);
      objFun(obj)
    }
  }, [obj]);

  let ReceiptArray = T02Data.filter((d, i) => d.FIELD05 == "C");
  let IssueArray = T02Data.filter((d, i) => d.FIELD05 == "D");

  let recieptAmountSum = ReceiptArray.reduce((a, b) => (a = a + b?.FIELD08), 0);
  let IssueAmountSum = IssueArray.reduce((a, b) => (a = a + b?.FIELD08), 0);
  // console.log('recieptAmount==>>',recieptAmountSum)
  // console.log('IssueAmountSum=>>',IssueAmountSum)

  // reciept amount & issue amount
  useEffect(() => {
    if (recieptAmountSum >= 0 && IssueAmountSum >= 0) {
      setT41Obj({
        ...T41Obj,
        FIELD06: recieptAmountSum,
        FIELD07: IssueAmountSum,
      });
    }
  }, [recieptAmountSum, IssueAmountSum]);

  // reciept Qty sum
  let ReceiptQtySum = ReceiptArray.reduce((a, b) => (a = a + b?.FIELD33), 0);
  console.log('ReceiptQtySum', ReceiptQtySum)

  // issue Qty sum
  let IssueQtySum = IssueArray.reduce((a, b) => (a = a + b?.FIELD33), 0);
  console.log('IssueQtySum', IssueQtySum)
  // reciept Qty - 2 sum
  let RecieptQty2Sum = ReceiptArray.reduce((a, b) => (a = a + b?.FIELD21), 0);

  // issue Qty - 2 sum
  let IssueQty2Sum = IssueArray.reduce((a, b) => (a = a + b?.FIELD21), 0);

  const T02TableFun = () => {
    return setDrawerObj({
      ...dra, title: <Text fw={700}>Product Entry</Text>, body: <>
        <ProductionT02Table
          OBJ={obj}
          flag={flag}
          setFlag={(e) => {
            setFlag(e)
          }}
          oYearSend={oYear}
          processValSend={processVal}
          ProductionDataSend={ProductionData}
          propsSend={props}
          T02ObjSend={T02Obj}
          T02DataSend={T02}
          T41ObjSend={T41Obj}
          DrawerObjSend={DrawerObj}
          setDrawerObjSend={(e) => {
            setDrawerObj(e)
          }}
        />
      </>, open: true, size: "80%", position: "right"
    })
  }

  const HandleSaveData = () => {
    dispatch(DataSaveLoading({[obj?.p1]:true}));
    GlobalClass.Notify(
      "info",
      "Adding",
      "Please wait while we process your data"
    );

    var JData = { T41: T41Obj, T02: T02Data };

    console.log("JData==>", JData);
    var data = {
      CorpID: gensetting.getcorpid(),
      cUser: gensetting.getcuser(),
      cPass: gensetting.getcpass(),
      CmpNo: gensetting.getcmpno(),
      cAction: "A",
      cCode: "ININ",
      cSData: JSON.stringify(JData),
      YrNo:localStorage.getItem("YrNo")?.replace(/[\\"]/g, '')
    };
    let param = JSON.stringify({
      CorpID: gensetting.getcorpid(),
      cUser: gensetting.getcuser(),
      cPass: gensetting.getcpass(),
      CmpNo: gensetting.getcmpno(),
      cAction: "A",
      cCode: "ININ",
    });
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    fetch(
      GlobalClass.ApiUrl + GlobalClass.PostVouEnt + "?pa=" + param,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        // console.log('api data', data);
        // console.log('api data status', data?.status);
        if (data.status == "SUCCESS") {
          let PaginationObj =
            Store.getState().DataSaveLoadingSlice.PaginationObj;
          Promise.resolve(dispatch(DataSaveLoading({[obj?.p1]:false})))
            .then(() => dispatch(ModalDelete(props.index)))
            .then(() =>
              dispatch(
                GetAccountList({
                  id: "TXNVOULISTIN",
                  name: "Production",
                  p0: "MT",
                  p1: "TXNVOULISTIN",
                  p2: "",
                  p3: "",
                  type: "A",
                  pagination: PaginationObj["TXNVOULISTIN"],
                })
              )
            )
            .then(() =>
              GlobalClass.Notify(
                "success",
                "Added",
                "Added successfully"
              )
            );
        } else {
          Promise.resolve(dispatch(DataSaveLoading({[obj?.p1]:false}))).then(() =>
            GlobalClass.Notify("error", data?.status, data?.message)
          );
        }
      })
      .catch((e) => {
        Promise.resolve(
          GlobalClass.Notify("error", "Error", `${e.message}`)
        ).then(() => dispatch(DataSaveLoading({[obj?.p1]:false})));

      });
  }

  useEffect(() => {
    if (T02) {
      // const updatedT02 = T02.slice(0, -1);
      // const updatedT02 = T02?.filter((v, i) => v?.FIELD03 !== "")
      // setT02Data(T02);
      // if (obj.p0 == "E") {
      //   setT02Obj(T02?.[T02?.length - 1]);
      // } else {
        setT02Obj(T02?.[0]);
      // }
    }
  }, [T02]);

  return (
    <div ref={formRef}>
      {
        hasError ? <>
          <Text>{ErrorMsg?.message}</Text>
          <Button onClick={() => {
            if (obj && obj.id != "") {
              Promise.resolve(dispatch(TransactionApi({ ...obj, p0: obj.p0 == "U" ? "E" : obj.p0 }))).then(() => {
                dispatch(GetAccountList({
                  "id": "P_M21",
                  "name": "",
                  "type": "A",
                  "p0": "P_M21",
                  "p1": "P_M21",
                  "p2": "",
                  "p3": ""
                }))
              })
            }
          }}>Retry</Button>
        </> :
          isLoading ? GlobalClass.RenderLoader("dots") :
            <>
              <Modal
                opened={DrawerObj?.open}
                // onClose={close}
                withCloseButton={() => {

                }}
                fullScreen={false}
                closeButtonProps={{
                  onClick: () => {
                    setDrawerObj(dra);
                  },
                }}
                title={DrawerObj.title}
                size={DrawerObj.size}
                // centered={.Centered}
                closeOnClickOutside={true}
                position={DrawerObj?.position ?? 'bottom'}
                onClose={typeof DrawerObj?.onclose == "function" ? DrawerObj?.onclose : () => { setDrawerObj(dra); }}
              >
                {DrawerObj.body}
              </Modal>
              <Paper shadow="md" radius="md" p="xs" withBorder>
                <Grid gutter={4}>
                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Grid.Col span={1}>
                      <Text size={12}>Date :</Text>
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <PLDateBox
                        autoFocus={true}
                        dispformat="DD/MM/YYYY"
                        setEdit={(e) => {
                          let year = e.toLocaleString("en-IN", {
                            year: "numeric",
                          });
                          let month = e.toLocaleString("en-IN", {
                            month: "2-digit",
                          });
                          let day = e.toLocaleString("en-IN", {
                            day: "2-digit",
                          });

                          let Date = year + month + day;

                          if (Date?.length == 8) {
                            setF02(true)
                            setT41Obj({ ...T41Obj, FIELD02: Date });
                            // pr?.current?.handlePopoverToggle();
                          }
                          // setSelectedDay(e);
                        }}
                        defaultval={f02 || props?.obj?.p0=="E" ? T41Obj?.FIELD02 : dateFormater(footerDate)}
                      />
                    </Grid.Col>

                    <Grid.Col span={1}>
                      <Text size={12}>
                        {selectedDay}
                      </Text>
                    </Grid.Col>

                    <Grid.Col span={1}>
                      <Text size={12}>Process :</Text>
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <PLDataGrid
                        ref={pr}
                        width={"500px"}
                        value={T41Obj?.FIELD04}
                        TmData={P_M71}
                        data={P_M71DT?.jData}
                        setEdit={(e) => {
                          console.log('Process datagrid value=>>', e)
                          Promise.resolve(setT41Obj({ ...T41Obj, FIELD04: e?.FIELD01 }))
                            .then(() => setProcessVal(e))
                            .then(() => setFlag(true))
                            .then(() => {
                              VoucherRef?.current?.handlePopoverToggle();
                            });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                </Grid>
                <Grid gutter={4}>
                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Grid.Col span={1}>
                      <Text size={12}>Vou No :</Text>
                    </Grid.Col>
                    {
                                  obj?.p0 == "E" ?
                                    <Grid.Col span={4}>
                                      <PLTextBox
                                        //  onKeyDown={(e)=>{
                                        //   GlobalClass.formNextInput(e,formRef);
                                        // }}
                                        setText={(e) => {
                                          if (e?.target?.value?.length > 0) {
                                            if (accList[obj?.page] && accList[obj?.page].filter((a) => a?.T41BNO == e?.target?.value)?.length > 0) {
                                              console.log("Duplicate Value log call");
                                              GlobalClass.Notify("warning", "Warning", "Voucher Number Already Present")
                                              setBillNoStatus(true)
                                            } else {
                                              setBillNoStatus(false)
                                            }
                                          } else {
                                            setBillNoStatus(false)
                                          }
                                        }}
                                        value={T41Obj?.FIELD12}
                                        setEdit={(e) => {
                                          console.log("setEdit=>", e);
                                          setT41Obj({ ...T41Obj, T92F00: "", FIELD12: e.target.value })
                                        }}
                                      />
                                    </Grid.Col> :
                                    <Grid.Col span={4}>
                                      {
                                        T92 ? <>
                                          {
                                            BillSeriesHideShow?.DataGridInput == true && <>
                                              {
                                                !T41Obj?.T92F00 ?
                                                  <PLDataGrid
                                                    // onKeyDown={(e)=>{
                                                    //   GlobalClass.formNextInput(e,formRef);
                                                    // }}
                                                    ref={VoucherRef}
                                                    width={"400px"}
                                                    position={"unset"}
                                                    valexpr={"FIELD00"}
                                                    // width={350} 
                                                    value={BillSeriesHideShow?.BillNoVal}
                                                    TmData={T92}
                                                    // data={T92DT}
                                                    setEdit={(e) => {
                                                      console.log("Bill No.=>", e)
                                                      // Promise.resolve(setT41Obj({ ...T41Obj, T92F00: e.FIELD01 })).then(()=>{
                                                      //   setBillSeriesHideShow({...BillSeriesHideShow,DataGridInput:false,TextInput:true,BillNoVal:e.FIELD01})
                                                      // })
                                                      //"FIELD00"
                                                      Promise.resolve(setT41Obj({ ...T41Obj, T92F00: e.FIELD00, FIELD12: "" })).then(() => {
                                                        if (e) {
                                                          var letr = e.FIELD02
                                                          console.log("num=>", num);
                                                          var num = parseInt(e.FIELD03) + 1
                                                          setBillSeriesHideShow({ ...BillSeriesHideShow, DataGridInput: false, TextInput: true, BillNoVal: letr + num })
                                                        }
                                                      }).then(() => document.getElementById("quantity").focus())
                                                    }}
                                                  /> : <PLTextBox
                                                    // ref={pr}
                                                    value={T41Obj?.FIELD12}
                                                    // value={T41Obj?.T92F00}
                                                    setText={(e) => {
                                                      // console.log("bill number setText 1", [{
                                                      //   "e": e,
                                                      //   "value": e?.target?.value
                                                      // }]);
                                                      if (e?.target?.value?.length > 0) {
                                                        if (accList[obj?.page] && accList[obj?.page].filter((a) => a?.T41BNO == e?.target?.value)?.length > 0) {
                                                          console.log("Duplicate Value log call");
                                                          GlobalClass.Notify("warning", "Warning", "Voucher Number Already Present")
                                                          setBillNoStatus(true)
                                                        } else {
                                                          setBillNoStatus(false)
                                                        }
                                                      } else {
                                                        setBillNoStatus(false)
                                                      }
                                                    }}
                                                    setEdit={(e) => {
                                                      console.log("setEdit=>", e);
                                                      // setBillSeriesHideShow({ ...BillSeriesHideShow, BillNoVal: e.target.value })
                                                      setT41Obj({ ...T41Obj, T92F00: "", FIELD12: e.target.value })
                                                    }}
                                                  />
                                              }
                                            </>
                                          }
                                          {BillSeriesHideShow?.TextInput == true && <PLTextBox
                                               setText={(e) => {
                                                // console.log("bill number setText 2", [{
                                                //   "e": e,
                                                //   "value": e?.target?.value
                                                // }]);
                                                if (e?.target?.value?.length > 0) {
                                                  if (accList[obj?.page] && accList[obj?.page].filter((a) => a?.T41BNO == e?.target?.value)?.length > 0) {
                                                    console.log("Duplicate Value log call");
                                                    GlobalClass.Notify("warning", "Warning", "Voucher Number Already Present")
                                                    setBillNoStatus(true)
                                                  } else {
                                                    setBillNoStatus(false)
                                                  }
                                                } else {
                                                  setBillNoStatus(false)
                                                }
                                                if (BillSeriesHideShow.BillNoVal.length <= 0) {
                                                  setBillSeriesHideShow({ ...BillSeriesHideShow, DataGridInput: true, TextInput: false })
                                                }
                                              }}
                                            value={BillSeriesHideShow?.BillNoVal}
                                            // value={T41Obj?.T92F00}
                                            setEdit={(e) => {
                                              console.log("setEdit=>", e);
                                              setBillSeriesHideShow({ ...BillSeriesHideShow, BillNoVal: e.target.value })
                                              setT41Obj({ ...T41Obj, T92F00: "", FIELD12: e.target.value })
                                            }}
                                          />
                                          }
                                        </> :
                                          < PLTextBox
                                          setText={(e) => {
                                            // console.log("bill number setText 3", [{
                                            //   "e": e,
                                            //   "value": e?.target?.value
                                            // }]);
                                            if (e?.target?.value?.length > 0) {
                                              if (accList[obj?.page] && accList[obj?.page].filter((a) => a?.T41BNO == e?.target?.value)?.length > 0) {
                                                console.log("Duplicate Value log call");
                                                GlobalClass.Notify("warning", "Warning", "Voucher Number Already Present")
                                                setBillNoStatus(true)
                                              } else {
                                                setBillNoStatus(false)
                                              }
                                            } else {
                                              setBillNoStatus(false)
                                            }
                                          }}
                                          value={BillSeriesHideShow?.BillNoVal}
                                          setEdit={(e) => {
                                            console.log("setEdit=>", e);
                                            setBillSeriesHideShow({ ...BillSeriesHideShow, BillNoVal: e.target.value })
                                            // setT41Obj({ ...T41Obj, T92F00: "", FIELD12: e?.target?.value })
                                            // if(EntryPages?.PurchaseEntry?.includes(obj?.page) || EntryPages?.PurchaseEntry?.includes(obj?.id)){
                                            //   setT41Obj({ ...T41Obj, T92F00: "", FIELD12: e.target.value, FIELD10: e.target.value })
                                            // }else{
                                            setT41Obj({ ...T41Obj, T92F00: "", FIELD12: e.target.value })
                                            // }
                                          }}
                                          />
                                      }
                                    </Grid.Col>
                                }

                    <Grid.Col span={1}></Grid.Col>

                    <Grid.Col span={1}>
                      <Text size={12}>Quantity :</Text>
                    </Grid.Col>
                    <Grid.Col span={3}>
                      <PLNumberBox
                        id="quantity"
                        disabled={obj.p0 == "E" ? true : false}
                        setNumber={(e) => {
                          if (T41Obj?.FIELD18 == 0) {
                            GlobalClass.Notify("warning", "Production Quantity cannot be Zero")
                          }
                        }}
                        precision={3}
                        decimalSeparator={true}
                        value={T41Obj?.FIELD18}
                        setEdit={(e) => {
                          Promise.resolve(setT41Obj({ ...T41Obj, FIELD18: e }))
                            .then(() => setFlag(true))
                        }}
                      />
                    </Grid.Col>

                    <Grid.Col span={1}>
                      <Button
                        variant="light"
                        size="sm"
                        style={{ height: "30px", width: "100px" }}
                      // onClick={RefreshInput}
                      >
                        Refresh
                      </Button>
                    </Grid.Col>
                  </Grid.Col>
                </Grid>
                {
                  oYear?.OSC?._BATCHSTOC == "N" && oYear?.OSC?._LOCATIONW == "N" &&
                  <Grid gutter={4}>
                    <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Grid.Col span={6}>
                        <Paper radius="md" p="xs" withBorder>
                          <Grid gutter={4}>
                            <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center' }}>
                              <Grid.Col span={6}>
                                <Grid.Col
                                  span={12}
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <Grid.Col span={5}>
                                    <Text size={12}>Quantity</Text>
                                  </Grid.Col>
                                  <Grid.Col span={5}>
                                    <Text size={12}>Amount</Text>
                                  </Grid.Col>
                                </Grid.Col>

                                <Grid.Col span={12} style={{ display: "flex" }}>
                                  <Grid.Col span={2}>
                                    <Text size={12}>Input</Text>
                                  </Grid.Col>
                                  <Grid.Col span={5}>
                                    <PLNumberBox disabled value={ReceiptQtySum} />
                                  </Grid.Col>
                                  <Grid.Col span={5}>
                                    <PLNumberBox disabled value={T41Obj?.FIELD06} />
                                  </Grid.Col>
                                </Grid.Col>

                                <Grid.Col span={12} style={{ display: "flex" }}>
                                  <Grid.Col span={2} >
                                    <Text size={12}>Output</Text>
                                  </Grid.Col>
                                  <Grid.Col span={5}>
                                    <PLNumberBox disabled value={IssueQtySum} />
                                  </Grid.Col>
                                  <Grid.Col span={5}>
                                    <PLNumberBox disabled value={T41Obj?.FIELD07} />
                                  </Grid.Col>
                                </Grid.Col>
                              </Grid.Col>
                            </Grid.Col>
                          </Grid>
                        </Paper>
                      </Grid.Col>
                    </Grid.Col>
                  </Grid>
                }
                <Grid gutter={4}>
                  <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Button
                      style={{ height: "30px", width: "100px" }}
                      onClick={() => {
                        if (oYear?.OSC?._BATCHSTOC == "Y" || oYear?.OSC?._LOCATIONW == "Y") {
                          BillNoStatus ? GlobalClass.Notify("warning", "Warning", "Voucher Number Already Present") : T02TableFun()
                        } else if (oYear?.OSC?._BATCHSTOC == "N" && oYear?.OSC?._LOCATIONW == "N") {
                          BillNoStatus ? GlobalClass.Notify("warning", "Warning", "Voucher Number Already Present") : HandleSaveData()
                        }
                      }}
                      onKeyDown={(e) => {
                        T02ENT.map((v) => {
                          if (e.key == "Enter" && (oYear?.OSC?._BATCHSTOC == "Y" || oYear?.OSC?._LOCATIONW == "Y")) {
                            return BillNoStatus ? GlobalClass.Notify("warning", "Warning", "Voucher Number Already Present") : T02TableFun()
                          } else if (e.key == "Enter" && oYear?.OSC?._BATCHSTOC == "N" && oYear?.OSC?._LOCATIONW == "N") {
                            BillNoStatus ? GlobalClass.Notify("warning", "Warning", "Voucher Number Already Present") : HandleSaveData()
                          }
                        })

                      }}
                    >
                      OK
                    </Button>
                  </Grid.Col>
                </Grid>

              </Paper>
            </>
      }
    </div>
  )
}

export default ProductionQuickEntryForm