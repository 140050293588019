import React, { useEffect, useState } from 'react'
import PLDataGrid from '../../PlusComponent/PLDataGrid'
import { useDispatch, useSelector } from 'react-redux'
import { GetCmpSetUpPopUp } from '../../utils/slices/CompanySetUpPopUpSlice'
import GlobalClass from '../../utils/GlobalClass'

const AuditUser = (props) => {
    const { obj, setEditSend, value } = props
    // console.log('value',value);
    // console.log('setEditSend',setEditSend)
    const AuditUserData = useSelector((state) => state.CompanySetupPopUp?.CmpSetUpPopUpData)
    const { isLoading } = useSelector((state) => state.CompanySetupPopUp)
    const P_M81 = useSelector((state) => state.CompanySetupPopUp?.CmpSetUpPopUpData?.P_M81)
    const P_M81DT = useSelector((state) => state.CompanySetupPopUp?.CmpSetUpPopUpData?.P_M81DT)
    const dispatch = useDispatch()

    const [multiSelectedVal, setMultiSelectedVal] = useState([])
    console.log('multiSelectedVal', multiSelectedVal)

    useEffect(() => {
        if (multiSelectedVal && multiSelectedVal.length > 0) {
            let Arr = [];
            Arr = multiSelectedVal.map((m) => {
                // console.log('========>',m.FIELD01)
                return m.FIELD01
            })
            console.log('arr==>', Arr.toString());
            return setEditSend(Arr.toString())
        }
    }, [multiSelectedVal])

    useEffect(() => {
        if (obj) {
            dispatch(GetCmpSetUpPopUp(obj))
        }
    }, [obj])

    return (
        <div style={{ width: "100%" }}>
            {
                isLoading ? GlobalClass.RenderLoader("dots") :
                    AuditUserData &&
                    <PLDataGrid
                    autoFocus={true}
                    openOnFocus={true}
                        dropdownPosition={true}
                        // width={"500px"}
                        TmData={P_M81}
                        data={P_M81DT?.jData}
                        isMulti={true}
                        setEdit={(e) => {
                            // console.log("e=>>",e)    
                            let val = [];
                            if (e?.length > 0) {
                                e?.map((m) => {
                                    val.push(m.original)
                                })
                                // console.log('val=>', val);
                                return setMultiSelectedVal(val)
                            }
                            // setEditSend(e.FIELD01)
                            // seperate(e)
                        }}
                        value={value}
                    />
            }
        </div>
    )
}

export default AuditUser