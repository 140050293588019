import { useEffect } from "react";
import Store from "../../utils/store";
import { TabAdd } from "../../utils/slices/TabList";
import { Text } from "@mantine/core";
import { ModalFunction } from "../../utils/slices/ModalSlice";
import DateRange from "./DateRange";
import { getCurrentDate } from "../../utils/slices/AccountListSlice";
import DataGrid from "../../components/DataGrid";
import GlobalClass from "../../utils/GlobalClass";

export function SLED(d) {
    const data = JSON.parse(d);
    console.log("SLED data=>", [{
        "data": data,
        'data.p0[0]': data.p0[0],
        'data.p0[0] + "CODE~K~" + data.DataGridRow.FIELD01': data.p0[0] + "CODE~K~" + data.DataGridRow.FIELD01

    }]);
    // console.log("data", data.DataGridRow.FIELD01);

    let obj;
    obj = {
        name: data.DataGridRow.FIELD02,
        type: "A",
        id: data.id,
        p0: data.p0,
        p1: data.p0,
        p2: "TFORM0000001",
        OParam: data.p0[0] + "CODE~K~" + data.DataGridRow.FIELD01,
        StrtDt: Store?.getState().AccountList.StrtEndDateAry?.[data?.BtnPnlObj.id]?.StrtDt, //Yasvi Patel
        EndDt: Store?.getState().AccountList.StrtEndDateAry?.[data?.BtnPnlObj.id]?.EndDt,
    }
    // console.log("SLED data 2=>",obj);
    // switch (data?.p0) {

    //     case "PRODLEDGER":
    //         obj = {...obj,OParam: "PCODE~K~" + data.DataGridRow.FIELD01}
    //         break;
    //     case "ASLEDGER":
    //         obj = {
    //             ...obj,
    //             OParam: "ACODE~K~" + data.DataGridRow.FIELD01
    //         }
    //         break;
    //     default:
    //         obj = {}
    //         //  {
    //         //     name: data.DataGridRow.FIELD02,
    //         //     type: "A",
    //         //     id: data.id,
    //         //     p0: data.p0,
    //         //     p1: data.p0,
    //         //     p2: "TFORM0000001",
    //         //     OParam: "ACODE~K~" + data.DataGridRow.FIELD01
    //         // }
    //         break;
    // }
    // const OBJ = JSON.parse(data);
    // Store.dispatch(TabAdd(obj));
    Promise.resolve(
        Store?.dispatch(
            getCurrentDate({
              date: { From: new Date(Store.getState()?.Language?.General?.dYSDate), To: new Date(Store.getState()?.Language?.General?.dYEDate) },
              id: obj?.id,
              flag: true,
            })
          )).then(()=>{
            // console.log("Store?.getState()?.AccountList?.TMFormatData?.[obj?.id]?.oDTR?.lDTAskPreRange",Store?.getState()?.AccountList?.TMFormatData?.[obj?.id]?.oDTR?.lDTAskPreRange)
            // console.log("data",data,"obj",obj)
            if(Store.getState()?.Language?.General?.oYear?.OSC?._TMASKDATE=="Y" && Store?.getState()?.AccountList?.TMFormatData?.[obj?.id]?.oDTR?.lDTAskPreRange==true){
                Store.dispatch(
                    ModalFunction({
                      onclose: (e) => {},
                      MTitle: <Text fw={700}>{`Report Date : ${obj?.name}`}</Text>,
                      MAction: true,
                      MSize: "sm",
                      position: "right",
                      MBody: (i) => <DateRange obj={obj} index={i}/>,
                      MClose: true,
                      Overlay: {
                        backgroundOpacity: 0.8,
                        blur: 5,
                      },
                    })
                  );
            }else{
                // Store.dispatch(TabAdd(obj));
                GlobalClass.addTab(obj, () => <div id={"tab" + obj.id} style={{ padding: 5,backgroundColor:"#fff" }} ><DataGrid tag="M" IsMultiSelect={false} obj={obj} /></div>)
            }
          })
    
}

export function TM_ZINOUT(d) {
    const data = JSON.parse(d);
    console.log("TM_ZINOUT data=>", [{
        "data": data,
        'data.p0[0]': data.p0[0],
        'data.p0[0] + "CODE~K~" + data.DataGridRow.FIELD01': data.p0[0] + "CODE~K~" + data.DataGridRow.FIELD01

    }]);

    let obj={};
    // let p0 = data.id.charAt(0).toLowerCase().includes("p")?'PRODLEDGER':"ASLEDGER";
    let p0 = String(data.id).startsWith("P") ? "PRODLEDGER" : String(data.id).startsWith("A") ? "ASLEDGER" : data.id;
    if (String(data.id).startsWith("P") || String(data.id).startsWith("A")) {
        obj = {
            ...obj,
            OParam: (String(data.id).startsWith("P") ? "P" : "A") + "CODE~K~" + data.DataGridRow.FIELD01
        }
    }
    // OParam: (data.id.charAt(0).toLowerCase().includes("p") ? "P" : "A") + "CODE~K~" + data.DataGridRow.FIELD01,
    
    obj = {
        ...obj,
        name:data?.DataGridRow?.FIELD02,
        type: "A",
        id: data?.id,
        p0: p0,
        p1: p0,
        p2: "TFORM0000001",
        StrtDt: Store.getState()?.Language?.General?.dYSDate, //Yasvi Patel
        EndDt: Store.getState()?.Language?.General?.dYEDate,
    }
    // console.log("new Date(Store.getState()?.Language?.General?.dYSDate)",new Date(Store.getState()?.Language?.General?.dYSDate))
    Promise.resolve(
    Store?.dispatch(
        getCurrentDate({
          date: { From: new Date(Store.getState()?.Language?.General?.dYSDate), To: new Date(Store.getState()?.Language?.General?.dYEDate) },
          id: obj?.id,
          flag: true,
        })
      )).then(()=>{
        // console.log("Store?.getState()?.AccountList?.TMFormatData?.[obj?.id]?.oDTR?.lDTAskPreRange",Store?.getState()?.AccountList?.TMFormatData?.[obj?.id]?.oDTR?.lDTAskPreRange,"==>",Store.getState()?.Language?.General?.oYear?.OSC?._TMASKDATE)
        // console.log("data",data,"obj",obj)
        if(Store.getState()?.Language?.General?.oYear?.OSC?._TMASKDATE=="Y" && Store?.getState()?.AccountList?.TMFormatData?.[obj?.id]?.oDTR?.lDTAskPreRange==true){
            Store.dispatch(
                ModalFunction({
                  onclose: (e) => {},
                  MTitle: <Text fw={700}>{`Report Date : ${obj?.name}`}</Text>,
                  MAction: true,
                  MSize: "sm",
                  position: "right",
                  MBody: (i) => <DateRange obj={obj} index={i}/>,
                  MClose: true,
                  Overlay: {
                    backgroundOpacity: 0.8,
                    blur: 5,
                  },
                })
              );
        }else{
            // Store.dispatch(TabAdd(obj));
            GlobalClass.addTab(obj, () => <div id={"tab" + obj.id} style={{ padding: 5,backgroundColor:"#fff" }}><DataGrid tag="M" IsMultiSelect={false} obj={obj} /></div>)
}
      })

    
    

}

export function PLED_MAIN(d) {
    const data = JSON.parse(d);
    console.log("PLED_MAIN data=>", [{
        "data": data,
        'data.p0[0]': data.p0[0],
        'data.p0[0] + "CODE~K~" + data.DataGridRow.FIELD01': data.p0[0] + "CODE~K~" + data.DataGridRow.FIELD01

    }]);
    // console.log("data", data.DataGridRow.FIELD01);

    let obj;
    obj = {
        name: data.DataGridRow.FIELD02,
        type: "A",
        id: data.id,
        p0: 'PRODLEDGER',
        p1: 'PRODLEDGER',
        p2: "TFORM0000001",
        OParam: "PCODE~K~" + data.DataGridRow.FIELD01,
        StrtDt: Store?.getState().AccountList.StrtEndDateAry?.[data?.BtnPnlObj.id]?.StrtDt, //Yasvi Patel
        EndDt: Store?.getState().AccountList.StrtEndDateAry?.[data?.BtnPnlObj.id]?.EndDt,
    }
    // obj = {
    //     name: data.DataGridRow.FIELD02,
    //     type: "A",
    //     id: data.id,
    //     p0: data?.BtnPnlObj?.p0,
    //     p1: data?.BtnPnlObj?.p1,
    //     p2: data?.BtnPnlObj?.p2,
    //     OParam: data?.BtnPnlObj?.p0 + "CODE~K~" + data.id,
    //     StrtDt: Store?.getState().AccountList.StrtEndDateAry?.[data?.BtnPnlObj.id]?.StrtDt, //Yasvi Patel
    //     EndDt: Store?.getState().AccountList.StrtEndDateAry?.[data?.BtnPnlObj.id]?.EndDt,
    // }
    // console.log("SLED data 2=>",obj);
    // switch (data?.p0) {

    //     case "PRODLEDGER":
    //         obj = {...obj,OParam: "PCODE~K~" + data.DataGridRow.FIELD01}
    //         break;
    //     case "ASLEDGER":
    //         obj = {
    //             ...obj,
    //             OParam: "ACODE~K~" + data.DataGridRow.FIELD01
    //         }
    //         break;
    //     default:
    //         obj = {}
    //         //  {
    //         //     name: data.DataGridRow.FIELD02,
    //         //     type: "A",
    //         //     id: data.id,
    //         //     p0: data.p0,
    //         //     p1: data.p0,
    //         //     p2: "TFORM0000001",
    //         //     OParam: "ACODE~K~" + data.DataGridRow.FIELD01
    //         // }
    //         break;
    // }
    // const OBJ = JSON.parse(data);
    // Store.dispatch(TabAdd(obj));
    Promise.resolve(
        Store?.dispatch(
            getCurrentDate({
              date: { From: new Date(Store.getState()?.Language?.General?.dYSDate), To: new Date(Store.getState()?.Language?.General?.dYEDate) },
              id: obj?.id,
              flag: true,
            })
          )).then(()=>{
            // console.log("Store?.getState()?.AccountList?.TMFormatData?.[obj?.id]?.oDTR?.lDTAskPreRange",Store?.getState()?.AccountList?.TMFormatData?.[obj?.id]?.oDTR?.lDTAskPreRange)
            // console.log("data",data,"obj",obj)
            if(Store.getState()?.Language?.General?.oYear?.OSC?._TMASKDATE=="Y" && Store?.getState()?.AccountList?.TMFormatData?.[obj?.id]?.oDTR?.lDTAskPreRange==true){
                Store.dispatch(
                    ModalFunction({
                      onclose: (e) => {},
                      MTitle: <Text fw={700}>{`Report Date : ${obj?.name}`}</Text>,
                      MAction: true,
                      MSize: "sm",
                      position: "right",
                      MBody: (i) => <DateRange obj={obj} index={i}/>,
                      MClose: true,
                      Overlay: {
                        backgroundOpacity: 0.8,
                        blur: 5,
                      },
                    })
                  );
            }else{
                // Store.dispatch(TabAdd(obj));
                GlobalClass.addTab(obj, () => <div id={"tab" + obj.id} style={{ padding: 5,backgroundColor:"#fff" }}><DataGrid tag="M" IsMultiSelect={false} obj={obj} /></div>)
            }
          })
   
}

